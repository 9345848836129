import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import '../styles.scss'
import { StreamwiseNodeData } from '../../types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
//import {faCircleTrash, faCircleInfo} from '@fortawesome/pro-solid-svg-icons'

const PORT_POSITIONS = [17.5, 50, 82.5, 50]
const PORT_LABELS = ['COM1', 'AIR OUT', 'COM2', 'AIR IN']

export default memo(
  ({ id, data }: { id: string; data: StreamwiseNodeData }) => {
    return (
      <div>
        <div style={{ height: "20px" }} className="expansionButtonContainer">
          <div className="infoButton">
            {/*<FontAwesomeIcon icon={faCircleInfo} />
          */}</div>
          <div
            className="trashButton"
            onClick={() => {
              if (data.onDelete) data.onDelete(id);
            }}
          >
            {/*<FontAwesomeIcon icon={faCircleTrash} />
          */}</div>
        </div>
        <div>Air cleaning</div>
        <div className="handleLabel" style={{ left: PORT_POSITIONS[0] }}>
          {PORT_LABELS[0]}
        </div>
        <div className="handleLabel" style={{ left: PORT_POSITIONS[1] }}>
          {PORT_LABELS[1]}
        </div>
        <div className="handleLabel" style={{ left: PORT_POSITIONS[2] }}>
          {PORT_LABELS[2]}
        </div>
        <div className="handleLabelTop" style={{ left: PORT_POSITIONS[3] }}>
          {PORT_LABELS[3]}
        </div>
        <Handle
          type="target"
          position="left"
          id={PORT_LABELS[0]}
          style={{ top: 43, background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={false}
        />
        <Handle
          type="source"
          position="bottom"
          id={PORT_LABELS[1]}
          style={{ left: PORT_POSITIONS[1], background: "#5dc7f5" }}
          isConnectable={false}
        />
        <Handle
          type="source"
          position="right"
          id={PORT_LABELS[2]}
          style={{ top: 43, background: "#555" }}
          isConnectable={false}
        />
        <Handle
          type="target"
          position="top"
          id={PORT_LABELS[1]}
          style={{ left: PORT_POSITIONS[1], background: "#5dc7f5" }}
          isConnectable={false}
        />
      </div>
    );
  }
);