import { Chart, registerables} from 'chart.js';
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(...registerables);
Chart.register(zoomPlugin);
// Chart.register(annotationPlugin);

const StreamwiseScatterChart = (function () {
    const defaultYAxisId = 'DefaultYAxisId';
    const defaultAxisFontSize = 12;
    const defaultMaxTicksLimit = 5;
    var scatter_chart = [];

    const defaultYAxis = {
        id: defaultYAxisId,
        type: 'linear',
        position: 'left',
        ticks: {
            fontSize: defaultAxisFontSize,
            maxTicksLimit: defaultMaxTicksLimit,
            beginAtZero: false
        },
        title: {
            display: true,
            fontSize: defaultAxisFontSize,
            padding: 4,
            text: ''
        }
    };

    const defaultXAxis = {
        id: 'x',
        position: 'bottom',
        type: 'linear',
        ticks: {
            fontSize: defaultAxisFontSize,
            maxTicksLimit: defaultMaxTicksLimit,
            beginAtZero: false
        },
        title: {
            display: true,
            fontSize: defaultAxisFontSize,
            padding: 4,
            text: ''
        }
    };


    const assignYAxes = function (datasets, axisOverride) {
        if (axisOverride) {
            datasets.forEach(function (dataset) {
                dataset.yAxisID = defaultYAxisId;
            });
        }

        return datasets;
    };

    const deepCopy = function (objectToGoDeepOn) {
        return JSON.parse(JSON.stringify(objectToGoDeepOn));
    };

    const createAxes = function (chartConfig) {
        const axes = {};
        if (chartConfig.chartYAxisIdOverride) {
            var singleYAxis = deepCopy(defaultYAxis);
            singleYAxis.title.text = chartConfig.chartYAxisIdOverride;
            axes[defaultYAxisId] = singleYAxis;
        } else {
            chartConfig.chartDataSets.forEach(function (dataset) {
                var newYAxis = deepCopy(defaultYAxis);
                newYAxis.id = dataset.yAxisID + "y";
                newYAxis.title.text = dataset.label;
                axes[dataset.yAxisID + "y"] = newYAxis;
            });
        }

        if (chartConfig.chartXAxisIdOverride) {
            var singleXAxis = deepCopy(defaultXAxis);
            singleXAxis.title.text = chartConfig.chartXAxisIdOverride;
            axes['x'] = singleXAxis;
        } else {
            chartConfig.chartDataSets.forEach(function (dataset) {
                var newXAxis = deepCopy(defaultXAxis);
                newXAxis.id = dataset.yAxisID + "x";
                newXAxis.title.text = dataset.label;
                axes[dataset.yAxisID + "x"] = newXAxis;
            });
        }

        return axes;
    };



    const createChartOptions = function (chartConfig) {
        const chartAxes = createAxes(chartConfig);

        return {
            type: 'scatter',
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: true,
                labels: {
                    boxWidth: 20,
                    fontSize: 9,
                }
            },
            tooltips: {
                mode: 'nearest',
                intersect: false,
                callbacks: {
                    label: function (tooltipItem, data) {
                        const tooltip = data.datasets[tooltipItem.datasetIndex];
                        const value1 = Math.round(tooltip.data[tooltipItem.index]['x'] * 100) / 100;
                        const value2 = Math.round(tooltip.data[tooltipItem.index]['y'] * 100) / 100;
                        return value2 === 0 ? null : tooltip.label + ': (' + value1 + ', ' + value2 + ')';
                    }
                }
            },
            scales: {
                ...chartAxes
            },
            plugins: {
                zoom: {
                    limits:{
                        x : {min:null,
                            max:null},
                        "DefaultYAxisId" : 
                            {min:null,
                            max:null}
                    },
                    pan: {
                        enabled: true,
                        mode: 'xy',
                        modifierKey: 'ctrl',

                        onPan: function (chart) {
                            //const internalchart = chart.chart;
                            //Chart.helpers.each(Chart.instances, function (instance) {
                            //    if (instance.id != internalchart.id) {
                            //        instance.options.scales.xAxes[0].time.min = internalchart.scales['x-axis-0'].min;
                            //        instance.options.scales.xAxes[0].time.max = internalchart.scales['x-axis-0'].max;
                            //        instance.update();

                            //    }
                            //})
                        }
                    },
                    zoom: {
                        enabled: true,
                        mode: 'xy',
                        speed: 0.05,

                        wheel: {
                            enabled: true,
                            modifierKey: 'ctrl',
                        },
                        drag: {
                            enabled: true,
                            backgroundColor: 'rgba(225,225,225,0.7)'
                        },

                        onZoom: function (chart) {
                            //const internalchart = chart.chart;
                            //Chart.helpers.each(Chart.instances, function (instance) {
                            //    if (instance.id != internalchart.id) {
                            //        instance.options.scales.xAxes[0].time.min = internalchart.scales['x-axis-0'].min;
                            //        instance.options.scales.xAxes[0].time.max = internalchart.scales['x-axis-0'].max;
                            //        instance.update();

                            //    }
                            //})
                            //console.log(chartConfig.ChartIDs + 'I was zoomed!!!');
                        }
                    }
                }
            }
        };
    }

    const createChartData = function (chartConfig) {
        var datasets = chartConfig.chartDataSets;
        datasets = assignYAxes(datasets, chartConfig.chartYAxisIdOverride);

        return {
            //labels: chartConfig.chartLabels,
            datasets: datasets
        };
    };

    const limitZoomAndPan = function (chart) {
        chart.options.plugins.zoom.limits['x'].min = chart.scales['x'].min.valueOf();
        chart.options.plugins.zoom.limits['x'].max = chart.scales['x'].max.valueOf();

        chart.options.plugins.zoom.limits[defaultYAxisId].min= chart.scales[defaultYAxisId].min.valueOf();
        chart.options.plugins.zoom.limits[defaultYAxisId].max = chart.scales[defaultYAxisId].max.valueOf();
    };

    const initChartNoData = function (elementId, noData) {
        if (noData) {
            const chart = document.getElementById(elementId + '-Scatter');
            if (chart !== null){
                chart.setAttribute('class', 'col row no-data-blur-effect');
                //chart.style.width = '100%';
            }

            const noDataLabel = document.getElementById(elementId + '-ScatterNoData');
            if (noDataLabel !== null){
                noDataLabel.removeAttribute('hidden');
            }
        } else {
            const chart = document.getElementById(elementId + '-Scatter');
            if (chart !== null){
                chart.setAttribute('class', 'col row');
            }

            const noDataLabel = document.getElementById(elementId + '-ScatterNoData');
            if (noDataLabel !== null){
                noDataLabel.setAttribute('hidden', '');
            }
        }
    };

    const createChart = function (chartConfig, noData) {
        initChartNoData(chartConfig.chartId, noData);

        const canvasElement = document.getElementById(chartConfig.chartId + '-ScatterCanvas')
        if (canvasElement !== null){
            const ctx = canvasElement.getContext('2d');
            ctx.canvas.className = "col" // add back column styles
            if (scatter_chart[chartConfig.chartId]) {
                scatter_chart[chartConfig.chartId].destroy();
            }
    
            var chartData = createChartData(chartConfig);
            var chartOptions = createChartOptions(chartConfig);
            scatter_chart[chartConfig.chartId] = new Chart(ctx,
                {
                    type: 'scatter',
                    data: chartData,
                    options: chartOptions
                });
    
            limitZoomAndPan(scatter_chart[chartConfig.chartId]);
    
            return scatter_chart[chartConfig.chartId];
        }
        return {};
    };

    return {
        create: createChart
    };
}());

export default StreamwiseScatterChart; 