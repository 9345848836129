import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import Masonry from 'masonry-layout';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-moment';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Cookies from 'cookies-js';
import DatePicker from 'react-date-picker';
import Dropzone from "dropzone";
import NoAccessPage from './NoAccessPage';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css'
import HelpButton from './HelpButton';
import YouTubeVideo from './YouTubeVideo';

Chart.register(...registerables);

//Show info modal if updated since last visit
var SiteDetailsTabVersion = Cookies.get('SiteDetailsTabVersion');
var CurrentSiteDetailsTabVersion = 1;

var Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

var $grid;

class DocumentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            documents: props.documents,
            refreshfiles: false,
            carouselindex: 0
        };
    }


    static getDerivedStateFromProps(props, state) {
        if (props.documents !== state.documents) {
            return {
                documents: props.documents,
                carouselindex: Math.floor((props.documents.length - 1) / 5)
            };
        }

        // Return null if the state hasn't changed
        return null;
    }

    componentDidMount() {
        this.setState({
            carouselindex: Math.floor((this.state.documents.length - 1) / 5)
        })

    }

    setCarouselIndex = (event) => {
        this.setState({ carouselindex: event })
    }

    NextCarouselIndex = () => {
        this.setState({ carouselindex: Math.min((Math.max(0, Math.floor((this.state.documents.length - 1) / 5))), this.state.carouselindex + 1) })
    }

    PreviousCarouselIndex = () => {
        this.setState({ carouselindex: Math.max(0, this.state.carouselindex - 1) })
    }

    render() {
        var documents = [];

        for (var i = 0; i < this.state.documents.length; i++) {
            documents.push(<tr key={"document" + this.state.documents[i].SiteDocumentID}>
                <td>
                    <a href={"/api/SiteDetailsAPI/GetDocument?id=" + this.state.documents[i].SiteDocumentID}>{this.state.documents[i].FileName}</a>
                </td>
                <td>
                    {moment.utc(this.state.documents[i].UploadDate).local().format('LLLL')}
                </td>
            </tr >);
        }

        var tables = [];
        for (var j = 0; j < documents.length; j = j + 5) {
            var intermediatedocuments = [];
            for (var k = j; k < Math.min(j + 5, documents.length); k++) {
                intermediatedocuments.push(documents[k])
            }
            tables.push(
                <div key={"tabitem" + j}>
                    <div className="col-md-12 p-0">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        File Name
                                    </th>
                                    <th>
                                        Upload Date
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {intermediatedocuments}
                            </tbody>
                        </table>
                    </div >
                </div>)
        }

        return (tables.length > 0 ? <div className="container">
            <Carousel className="row" value={this.state.carouselindex} draggable={false} onChange={this.setCarouselIndex}>
                {tables}
            </Carousel>
            <div className="row justify-content-between m-2">
                <div className="col-4 text-left">
                    <i onClick={this.PreviousCarouselIndex} className="fad fa-chevron-double-left"></i>
                </div>
                <div className="col-4 text-center">
                    Page {this.state.carouselindex + 1} of {Math.max(1, Math.floor((this.state.documents.length - 1) / 5) + 1)}
                </div>
                <div className="col-4 text-right">
                    <i onClick={this.NextCarouselIndex} className="fad fa-chevron-double-right"></i>
                </div>
            </div>
        </div> : "");
    }
}

function SignalComplianceDetails(props) {
    const [sitedetails, setSiteDetails] = useState([])
    const [signals, setSignals] = useState([])
    const [showaddsitedetailsmodal, setShowAddSiteDetailsModal] = useState(false)
    const [newupperlimit, setNewUpperLimit] = useState(null)
    const [newlowerlimit, setNewLowerLimit] = useState(null)
    const [newideal, setNewIdeal] = useState(null)
    const [selectedsignal, setSelectedSignal] = useState(null)

    function handleClose() {
        setShowAddSiteDetailsModal(false)
    }

    function ShowAddSiteDetailsModal() {
        setShowAddSiteDetailsModal(true)
    }

    function DeleteLatestComplianceDetail(SignalID) {
        var fd = {
            "SignalID": SignalID
        };

        $.ajax({
            url: '/api/SiteDetailsAPI/DeleteSiteDetail',
            type: 'post',
            data: JSON.stringify(fd),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
                UpdateSiteDetails();
            },
        });
    }

    useEffect(() => {
        $.ajax({
            url: '/api/SiteDetailsAPI/GetSiteSignals',
            type: 'get',
            data: { 'SiteGUID': props.SiteGUID },
            success: function (response) {
                response = JSON.parse(response);
                setSignals(response)
            }
        });

        UpdateSiteDetails();
    }, [props.SiteGUID]);

    function UpdateSiteDetails() {
        $.ajax({
            url: '/api/SiteDetailsAPI/GetSiteDetails',
            type: 'get',
            data: { 'SiteGUID': props.SiteGUID },
            success: function (response) {
                response = JSON.parse(response);
                var uniquesignals = [];
                var newsitedetails = [];
                for (var i = 0; i < response.length; i++) {
                    if (!uniquesignals.includes(response[i].Signal.SignalID)) {
                        uniquesignals.push(response[i].Signal.SignalID)
                        newsitedetails.push(response[i]);
                    }
                }
                setSiteDetails(newsitedetails)
            }
        });
    }

    function SubmitSiteDetails() {
        setShowAddSiteDetailsModal(false)

        var fd = {
            "SignalID": selectedsignal,
            "Ideal": newideal,
            "LowerComplianceLimit": newlowerlimit,
            "UpperComplianceLimit": newupperlimit,
        };

        $.ajax({
            url: '/api/SiteDetailsAPI/SubmitSiteDetail',
            type: 'post',
            data: JSON.stringify(fd),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
                props.layout(true);
                UpdateSiteDetails();
            },
        });
    }

    return (
        <div className="p-0 col-md-12" >
            <div className="m-2 shadow-sm card" >
                <div className="card-header">
                    <div className="row align-items-center me-0">
                        <div className="col">Signal Compliance Details</div>
                        <div className="me-auto col-auto"><div onClick={ShowAddSiteDetailsModal} className="btn btn-primary text-light">Add</div></div>
                    </div>
                </div>
                <div className="card-body p-3">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>
                                    Time Last Edited
                                </th>
                                <th>
                                    Signal
                                </th>
                                <th>
                                    Ideal Value
                                </th>
                                <th>
                                    Lower Compliance Limit
                                </th>
                                <th>
                                    Upper Compliance Limit
                                </th>
                                {props.ComplianceDetailsAccess > 1 ? <th></th> : ""}
                            </tr>
                        </thead>
                        <tbody>
                            {sitedetails.map((item) =>
                                <tr key={"item" + item.Signal.SignalID}>
                                    <td>
                                        {moment.utc(item.Time).local().format('LLL')}
                                    </td>
                                    <td>
                                        {item.Signal.NiceName}
                                    </td>
                                    <td>
                                        {item.Reference}
                                    </td>
                                    <td>
                                        {item.LowerLimit}
                                    </td>
                                    <td>
                                        {item.UpperLimit}
                                    </td>
                                    {props.ComplianceDetailsAccess > 1 ? <td><OverlayTrigger
                                        placement="top"
                                        trigger="click"
                                        overlay={
                                            <Tooltip>
                                                <div className="align-items-center">Delete latest: <div className="btn btn-danger" value={item.Signal.SignalID} onClick={() => DeleteLatestComplianceDetail(item.Signal.SignalID)}><i className="fad fa-trash"></i></div></div>
                                            </Tooltip>
                                        }>
                                        <div className="me-n2 float-right"><button className="btn btn-danger text-light"><i className="far fa-trash-alt"></i></button></div>
                                    </OverlayTrigger></td> : ""}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal show={showaddsitedetailsmodal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLongTitle">Add Signal Compliance Detail</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className="control-label" for="SignalID">Signal</label>
                        <select className="form-control" id="SignalID" name="SignalID" onChange={(e) => setSelectedSignal(e.target.value)}>
                            {signals.map((signal) => <option key={"signal" + signal.SignalID} value={signal.SignalID}>{signal.NiceName}</option>)}
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="control-label" for="Reference">Ideal Value</label>
                        <input className="form-control" type="number" data-val="true" data-val-number="The field Ideal Value must be a number." id="Reference" name="Reference" onChange={(e) => setNewIdeal(e.target.value)} value={newideal} />
                    </div>
                    <div className="form-group">
                        <label className="control-label" for="UpperLimit">Upper Compliance Limit</label>
                        <input className="form-control" type="number" data-val="true" data-val-number="The field Upper Compliance Limit must be a number." id="UpperLimit" name="UpperLimit" onChange={(e) => setNewUpperLimit(e.target.value)} value={newupperlimit} />
                    </div>
                    <div className="form-group">
                        <label className="control-label" for="LowerLimit">Lower Compliance Limit</label>
                        <input className="form-control" type="number" data-val="true" data-val-number="The field Lower Compliance Limit must be a number." id="LowerLimit" name="LowerLimit" onChange={(e) => setNewLowerLimit(e.target.value)} value={newlowerlimit} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={SubmitSiteDetails}>
                        Create
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>)
}

function MetricHistory(props) {
    const [expanded, setExpanded] = useState(0)
    const [month, setMonth] = useState(new Date().getMonth())
    const [year, setYear] = useState(new Date().getYear() + 1900)
    const [cost, setCost] = useState()
    const [value, setValue] = useState()
    const [chart, setChart] = useState()


    useEffect(() => {
        var datafound = false;
        for (var i = 0; i < props.charges.length; i++) {
            if (props.charges[i] != null) {
                datafound = true;
                break;
            }
        }
        if (datafound) {
            var data = [];
            var labels = [];
            for (var i = 0; i < props.charges.length; i++) {
                labels.push(Months[props.charges[i].Month - 1] + ' ' + props.charges[i].Year);
                data.push(props.charges[i].Cost);
            }
            var barChartData = {
                backgroundColor: 'rgba(0, 44, 79, 0.8)',
                borderColor: 'rgba(20, 168, 222, 0.8)',
                labels: labels,
                datasets: [{
                    backgroundColor: 'rgba(20, 168, 222, 0.8)',
                    borderColor: 'rgba(0, 44, 79, 0.8)',
                    label: props.CostPlaceholderText,
                    borderWidth: 1,
                    data: data
                }]

            };

            var ctx = document.getElementById(props.APIURL + "canvas").getContext('2d');
            if (chart) {
                chart.destroy();
            }
            setChart(new Chart(ctx, {
                type: 'bar',
                data: barChartData,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        position: 'top',
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            })
            );
        }
    }, [props.charges]);

    function costChangeHandler(event) {
        if (!isNaN(event.target.value)) {
            setCost(event.target.value);
        }
    }

    function valueChangeHandler(event) {

        if (!isNaN(event.target.value)) {
            setValue(event.target.value);
        }
    }

    function addRecord(event) {
        props.layout(false)
        setExpanded(1)
    }

    function handleSubmission() {
        var fd = {
            "SiteGUID": props.SiteGUID,
            "Month": month + 1,
            "Year": year,
            "Cost": cost,
            "Value": value,
        };

        $.ajax({
            url: '/api/DigitalTwinAPI/' + props.APIURL,
            type: 'post',
            data: JSON.stringify(fd),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
                props.layout(true);
            },
        });

    }

    function onDateChange(event) {
        setMonth(event.getMonth())
        setYear(event.getYear() + 1900)
    }


    if (props.charges.length > 0) {
        return (
            <div className={"grid-item p-0 " + props.columnWidth} >

                <div className="m-2 shadow-sm card" >
                    <div className="card-header">
                        {props.CardTitle}
                    </div>
                    <div className="card-body p-3 d-flex justify-content-center">
                        <div className={props.MetricHistoryAccess > 1 ? "col-6" : "col"}>
                            <canvas className="sitedetailsbargraph" id={props.APIURL + "canvas"} height="200px"></canvas>
                        </div>
                        {props.MetricHistoryAccess > 1 ? <div className="verticalline"></div> : ''}
                        {props.MetricHistoryAccess > 1 ?
                            <div className="col-6">
                                <div className="input-group mb-3">
                                    <input type="number" className="form-control" placeholder={props.CostPlaceholderText} aria-label={props.CostPlaceholderText} aria-describedby="basic-addon2" onChange={costChangeHandler} />
                                </div>
                                <div className="input-group mb-3">
                                    <input type="number" className="form-control" placeholder={props.ValuePlaceholderText} aria-label={props.ValuePlaceholderText} aria-describedby="basic-addon2" onChange={valueChangeHandler} />
                                </div>
                                <div className="input-group mb-3">
                                    <DatePicker className="form-control"
                                        maxDetail={'year'}
                                        onChange={onDateChange}
                                        value={new Date(year, month, 1)}
                                    />
                                </div>

                                <button className="btn btn-primary" onClick={handleSubmission}>Log</button>
                            </div>
                            : ''}
                    </div>
                </div>
            </div >
        );
    }
    else if (props.MetricHistoryAccess) {
        if (expanded == 0) {
            return (<div className="grid-item col-md-2 p-0" >

                <div className="m-2 shadow-sm card" >
                    <div className="card-header">
                        {props.CardTitle}
                    </div>
                    <div className="card-body p-3">
                        <div className="d-flex justify-content-center"><button className="btn btn-primary" onClick={addRecord}><i className="fas fa-2x fa-plus-circle"></i></button></div>
                        <div className="d-flex justify-content-center">Add</div>
                    </div>
                </div>
            </div >);
        }
        else {
            return (
                <div className="grid-item col-md-3 p-0" >

                    <div className="m-2 shadow-sm card" >
                        <div className="card-header">
                            {props.CardTitle}
                        </div>
                        <div className="card-body p-3">
                            <div className="input-group mb-3">
                                <input type="text" className="form-control" placeholder={props.CostPlaceholderText} aria-label={props.CostPlaceholderText} aria-describedby="basic-addon2" onChange={costChangeHandler} />
                            </div>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control" placeholder={props.ValuePlaceholderText} aria-label={props.CostPlaceholderText} aria-describedby="basic-addon2" onChange={valueChangeHandler} />
                            </div>
                            <div className="input-group mb-3">
                                <DatePicker className="form-control"
                                    maxDetail={'year'}
                                    onChange={onDateChange}
                                    value={new Date(year, month, 1)}
                                />
                            </div>

                            <button className="btn btn-primary" onClick={handleSubmission}>Log</button>

                        </div>
                    </div>
                </div >)
        }
    }
    else {
        return ('');
    }
}

export function SiteDetails(props) {
    const { id } = useParams();
    const history = useHistory();
    const [sitecharges, setSiteCharges] = useState([])
    const [showversioncontrolmodal, setShowVersionControlModal] = useState(false)
    const [TSSData, setTSSData] = useState([])
    const [pHData, setpHData] = useState([])
    const [BODData, setBODData] = useState([])
    const [SolubleBODData, setSolubleBODData] = useState([])
    const [CODData, setCODData] = useState([])
    const [FOGData, setFOGData] = useState([])
    const [NitrogenData, setNitrogenData] = useState([])
    const [PhosphorusData, setPhosphorusData] = useState([])
    const [TemperatureData, setTemperatureData] = useState([])
    const [SludgeData, setSludgeData] = useState([])
    const [EnergyData, setEnergyData] = useState([])
    const [AssetData, setAssetData] = useState([])
    const [ManualWastewaterData, setManualWastewaterData] = useState([])
    const [documents, setDocuments] = useState([])

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id), [id]);

    function handleClose() {
        setShowVersionControlModal(false)
    }

    function RefreshDocuments() {
        var fd = {
            "SiteGUID": id
        };
        console.log("Refreshing documents")
        $.ajax({
            url: '/api/SiteDetailsAPI/GetDocuments/',
            type: 'get',
            data: fd,
            success: function (response) {
                response = JSON.parse(response);
                console.log(response)
                setDocuments(response)
            }
        });
    }

    function CheckVersion() {
        if (SiteDetailsTabVersion < CurrentSiteDetailsTabVersion) {
            //Show modal and update cost breakdown tab version for user so they don't see the message on other devices
            var fd = {
                "SiteDetailsTabVersion": CurrentSiteDetailsTabVersion
            };
            $.ajax({
                url: '/api/VersionControlAPI/SetSiteDetailsTabVersion',
                data: fd,
                type: 'get',
                success: function (response) {
                    SiteDetailsTabVersion = CurrentSiteDetailsTabVersion;
                    Cookies.set('SiteDetailsTabVersion', CurrentSiteDetailsTabVersion, { expires: 7999 });

                }
            });
            setShowVersionControlModal(true)
        }
    }

    useEffect(() => {
        // Version control modal
        CheckVersion()
        //First run
        if (SiteDetailsTabVersion == undefined || SiteDetailsTabVersion < CurrentSiteDetailsTabVersion) {
            //Get version from server
            $.ajax({
                url: '/api/VersionControlAPI',
                type: 'get',
                success: function (response) {
                    console.log(response);

                    SiteDetailsTabVersion = response.siteDetailsTabVersion;
                    Cookies.set('HomeTabVersion', response.homeTabVersion, { expires: 7999 });
                    Cookies.set('AnalyticsTabVersion', response.analyticsTabVersion, { expires: 7999 });
                    Cookies.set('HealthSummaryTabVersion', response.healthSummaryTabVersion, { expires: 7999 });
                    Cookies.set('CostBreakdownTabVersion', response.costBreakdownTabVersion, { expires: 7999 });
                    Cookies.set('DeepDiveTabVersion', response.deepDiveTabVersion, { expires: 7999 });
                    Cookies.set('MaintenanceTabVersion', response.maintenanceTabVersion, { expires: 7999 });
                    Cookies.set('BillingTabVersion', response.billingTabVersion, { expires: 7999 });
                    Cookies.set('AlarmsTabVersion', response.alarmsTabVersion, { expires: 7999 });
                    Cookies.set('ControlTabVersion', response.controlTabVersion, { expires: 7999 });
                    Cookies.set('CalibrationsTabVersion', response.calibrationsTabVersion, { expires: 7999 });
                    Cookies.set('AirCleaningTabVersion', response.airCleaningTabVersion, { expires: 7999 });
                    Cookies.set('ReportsTabVersion', response.reportsTabVersion, { expires: 7999 });
                    Cookies.set('SiteDetailsTabVersion', response.siteDetailsTabVersion, { expires: 7999 });
                    Cookies.set('DownloadsTabVersion', response.downloadsTabVersion, { expires: 7999 });

                    CheckVersion();
                }
            });
        }
    }, []);


    useEffect(() => {
        var tsscosts = [];
        var pHcosts = [];
        var bodcosts = [];
        var solublebodcosts = [];
        var codcosts = [];
        var fogcosts = [];
        var nitrogencosts = [];
        var phosphoruscosts = [];
        var temperaturecosts = [];
        var sludgecosts = [];
        var energycosts = [];
        var assetcosts = [];
        var manualwastewatercosts = [];
        for (var i = 0; i < sitecharges.length; i++) {
            if (sitecharges[i].TSSCost != null) {
                tsscosts.push({ Cost: sitecharges[i].TSSCost, Month: sitecharges[i].Month, Year: sitecharges[i].Year })
            }
            if (sitecharges[i].pHCost != null) {
                pHcosts.push({ Cost: sitecharges[i].pHCost, Month: sitecharges[i].Month, Year: sitecharges[i].Year })
            }
            if (sitecharges[i].BODCost != null) {
                bodcosts.push({ Cost: sitecharges[i].BODCost, Month: sitecharges[i].Month, Year: sitecharges[i].Year })
            }
            if (sitecharges[i].SolubleBODCost != null) {
                solublebodcosts.push({ Cost: sitecharges[i].SolubleBODCost, Month: sitecharges[i].Month, Year: sitecharges[i].Year })
            }
            if (sitecharges[i].CODCost != null) {
                codcosts.push({ Cost: sitecharges[i].CODCost, Month: sitecharges[i].Month, Year: sitecharges[i].Year })
            }
            if (sitecharges[i].FOGCost != null) {
                fogcosts.push({ Cost: sitecharges[i].FOGCost, Month: sitecharges[i].Month, Year: sitecharges[i].Year })
            }
            if (sitecharges[i].NitrogenCost != null) {
                nitrogencosts.push({ Cost: sitecharges[i].NitrogenCost, Month: sitecharges[i].Month, Year: sitecharges[i].Year })
            }
            if (sitecharges[i].PhosphorusCost != null) {
                phosphoruscosts.push({ Cost: sitecharges[i].PhosphorusCost, Month: sitecharges[i].Month, Year: sitecharges[i].Year })
            }
            if (sitecharges[i].TemperatureCost != null) {
                temperaturecosts.push({ Cost: sitecharges[i].TemperatureCost, Month: sitecharges[i].Month, Year: sitecharges[i].Year })
            }
            if (sitecharges[i].SludgeCost != null) {
                sludgecosts.push({ Cost: sitecharges[i].SludgeCost, Month: sitecharges[i].Month, Year: sitecharges[i].Year })
            }
            if (sitecharges[i].EnergyCost != 0) {
                energycosts.push({ Cost: sitecharges[i].EnergyCost, Month: sitecharges[i].Month, Year: sitecharges[i].Year })
            }
            if (sitecharges[i].AssetCost != 0) {
                assetcosts.push({ Cost: sitecharges[i].AssetCost, Month: sitecharges[i].Month, Year: sitecharges[i].Year })
            }
            if (sitecharges[i].ManualWastewaterVolumeCost != 0) {
                manualwastewatercosts.push({ Cost: sitecharges[i].ManualWastewaterVolumeCost, Month: sitecharges[i].Month, Year: sitecharges[i].Year })
            }
        }
        setTSSData(tsscosts);
        setpHData(pHcosts);
        setBODData(bodcosts);
        setSolubleBODData(solublebodcosts);
        setCODData(codcosts);
        setFOGData(fogcosts);
        setNitrogenData(nitrogencosts);
        setPhosphorusData(phosphoruscosts);
        setTemperatureData(temperaturecosts);
        setSludgeData(sludgecosts);
        setEnergyData(energycosts);
        setAssetData(assetcosts);
        setManualWastewaterData(manualwastewatercosts);

        if ($grid) {
            masonryLayout(false)
        }
        else {
            InitialiseMasonry()
        }
    }, [sitecharges]);

    function InitialiseMasonry() {
        var masonryOptions = {
            itemSelector: '.grid-item',
            animate: true,
            stagger: 50,
            columnWidth: '.grid-sizer',
            percentPosition: true
        };

        var elem = document.querySelector('#equipmentcards');
        $grid = new Masonry(elem, masonryOptions);

        try {
            $grid.reloadItems();
            $grid.layout();
        } catch (exception) {
            console.log(props.auth)
            console.log(props.ComplianceDetailsAccess)
            console.log(props.MetricHistoryAccess)
            setTimeout(function () {
                InitialiseMasonry();
            }, 500)
        }
    }

    function masonryLayout(requiresitechargerefresh) {
        setTimeout(function () {
            try {
                $grid.reloadItems();
                $grid.layout();
            } catch (exception) {
                console.error(exception)
                InitialiseMasonry();
            }
        }, 500)
        if (requiresitechargerefresh) {
            $.ajax({
                url: '/api/DigitalTwinAPI/GetSiteCharges',
                type: 'get',
                data: { 'SiteGUID': id },
                success: function (response) {
                    response = JSON.parse(response);

                    setSiteCharges(response)
                }
            });
        }
    }

    useEffect(() => {
        $.ajax({
            url: '/api/DigitalTwinAPI/GetSiteCharges',
            type: 'get',
            data: { 'SiteGUID': id },
            success: function (response) {
                response = JSON.parse(response);

                setSiteCharges(response)
            }
        });
        InitialiseDropZone();
        RefreshDocuments();

    }, []);

    function InitialiseDropZone() {
        setTimeout(function () {
            try {
                let dropzone = new Dropzone(".dropzone");
                dropzone.on("addedfile", file => {
                    console.log("File added")
                    RefreshDocuments()
                });
            }
            catch (exception) {
                InitialiseDropZone();
            }
        }, 1000);
    }


    var energycostsavailable = props.MetricHistoryAccess > 0;
    var assetmanagementcostsavailable = props.MetricHistoryAccess > 0;

    return (
        props.auth !== undefined ?
            props.auth > 0 ?
                <>
                    <HelpButton showHelpModal={setShowVersionControlModal} />
                    <Button className="m-2" onClick={() => history.push("/Configurator/index/" + id)}>Hardware Configurator</Button>
                    <div className="container-fluid p-0 m-0" style={{ marginTop: '40px' }}>
                        {props.ComplianceDetailsAccess > 0 ? <>
                            <div className="p-0 col-md-12" >
                                <div className="m-2 shadow-sm card" >
                                    <div className="card-header">
                                        Compliance Documents
                                    </div>
                                    <div className="card-body p-3">
                                        {props.ComplianceDetailsAccess > 1 ? <form action={"/document-upload/" + id}
                                            className="dropzone"
                                            id={id + "-pdfs"}>
                                        </form> : ""}
                                        <DocumentList SiteGUID={id} documents={documents} />
                                    </div>
                                </div>
                            </div>
                            <SignalComplianceDetails SiteGUID={id} layout={masonryLayout} ComplianceDetailsAccess={props.ComplianceDetailsAccess} /></> : ""}
                        {props.MetricHistoryAccess > 0 ? <>
                            <h1>Compliance History</h1>
                            <div id="equipmentcards" className="container-fluid p-0">
                                <div className="grid-sizer grid-item col-md-1"></div>
                                <MetricHistory MetricHistoryAccess={props.MetricHistoryAccess} charges={TSSData} CardTitle="TSS History" SiteGUID={id} layout={masonryLayout} columnWidth="col-md-6" CostPlaceholderText="TSS Cost ($)" ValuePlaceholderText="TSS Value (kg)" APIURL="SubmitTSS" />
                                <MetricHistory MetricHistoryAccess={props.MetricHistoryAccess} charges={pHData} CardTitle="pH History" SiteGUID={id} layout={masonryLayout} columnWidth="col-md-6" CostPlaceholderText="pH Cost ($)" ValuePlaceholderText="pH Value" APIURL="SubmitpH" />
                                <MetricHistory MetricHistoryAccess={props.MetricHistoryAccess} charges={BODData} CardTitle="BOD History" SiteGUID={id} layout={masonryLayout} columnWidth="col-md-6" CostPlaceholderText="BOD Cost ($)" ValuePlaceholderText="BOD Value (kg)" APIURL="SubmitBOD" />
                                <MetricHistory MetricHistoryAccess={props.MetricHistoryAccess} charges={SolubleBODData} CardTitle="Soluble BOD History" SiteGUID={id} layout={masonryLayout} columnWidth="col-md-6" CostPlaceholderText="Soluble BOD Cost ($)" ValuePlaceholderText="Soluble BOD Value (kg)" APIURL="SubmitSolubleBOD" />
                                <MetricHistory MetricHistoryAccess={props.MetricHistoryAccess} charges={CODData} CardTitle="COD History" SiteGUID={id} layout={masonryLayout} columnWidth="col-md-6" CostPlaceholderText="COD Cost ($)" ValuePlaceholderText="COD Value (kg)" APIURL="SubmitCOD" />
                                <MetricHistory MetricHistoryAccess={props.MetricHistoryAccess} charges={FOGData} CardTitle="FOG History" SiteGUID={id} layout={masonryLayout} columnWidth="col-md-6" CostPlaceholderText="FOG Cost ($)" ValuePlaceholderText="FOG Value (kg)" APIURL="SubmitFOG" />
                                <MetricHistory MetricHistoryAccess={props.MetricHistoryAccess} charges={NitrogenData} CardTitle="Nitrogen History" SiteGUID={id} layout={masonryLayout} columnWidth="col-md-6" CostPlaceholderText="Nitrogen Cost ($)" ValuePlaceholderText="Nitrogen Value (kg)" APIURL="SubmitNitrogen" />
                                <MetricHistory MetricHistoryAccess={props.MetricHistoryAccess} charges={PhosphorusData} CardTitle="Phosphorus History" SiteGUID={id} layout={masonryLayout} columnWidth="col-md-6" CostPlaceholderText="Phosphorus Cost ($)" ValuePlaceholderText="Phosphorus Value (kg)" APIURL="SubmitPhosphorus" />
                                <MetricHistory MetricHistoryAccess={props.MetricHistoryAccess} charges={TemperatureData} CardTitle="Temperature History" SiteGUID={id} layout={masonryLayout} columnWidth="col-md-6" CostPlaceholderText="Temperature Cost ($)" ValuePlaceholderText="Temperature Value (C)" APIURL="SubmitTemperature" />
                                <MetricHistory MetricHistoryAccess={props.MetricHistoryAccess} charges={SludgeData} CardTitle="Sludge History" SiteGUID={id} layout={masonryLayout} columnWidth="col-md-6" CostPlaceholderText="Sludge Cost ($)" ValuePlaceholderText="Sludge Value (kg)" APIURL="SubmitSludge" />
                                <MetricHistory MetricHistoryAccess={props.MetricHistoryAccess} charges={ManualWastewaterData} CardTitle="Wastewater Volume History" SiteGUID={id} layout={masonryLayout} columnWidth="col-md-6" CostPlaceholderText="Wastewater Volume Discharge Cost ($)" ValuePlaceholderText="Wastewater Volume (kL)" APIURL="SubmitManualWastewater" />
                                {energycostsavailable ? <h1 className="heading grid-item w-100">Energy Costs</h1> : ""}
                                <MetricHistory MetricHistoryAccess={props.MetricHistoryAccess} charges={EnergyData} CardTitle="Energy History" SiteGUID={id} layout={masonryLayout} columnWidth="col-md-12" CostPlaceholderText="Energy Cost ($)" ValuePlaceholderText="Energy Value (kWh)" APIURL="SubmitEnergy" />
                                {assetmanagementcostsavailable ? <h1 className="heading grid-item w-100">Asset Management Costs</h1> : ""}
                                <MetricHistory MetricHistoryAccess={props.MetricHistoryAccess} charges={AssetData} CardTitle="Asset History" SiteGUID={id} layout={masonryLayout} columnWidth="col-md-12" CostPlaceholderText="Asset Management Cost ($)" ValuePlaceholderText="Asset Management Value (hr)" APIURL="SubmitAsset" />
                            </div>
                        </> : ""}
                        <Modal show={showversioncontrolmodal} onHide={handleClose} size="lg">
                            <Modal.Header closeButton>
                                <h5 className="modal-title" id="exampleModalLongTitle">What's New?</h5>
                            </Modal.Header>
                            <Modal.Body className="text-center">
                                <YouTubeVideo VideoTag="SiteDetails" />
                                <p className="text-start">The updated "Site Details" tab allows you to see compliance information about your wastewater treatment plant.</p>
                                <p><img className="w-100" src="/images/HowTos/sitedetails1.png" alt="Document Upload" /></p>
                                <p><img className="w-100" src="/images/HowTos/sitedetails2.png" alt="Document Upload" /></p>
                                <p><img className="w-100" src="/images/HowTos/sitedetails3.png" alt="Document Upload" /></p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
                :
                <NoAccessPage />
            : <></>);
};
