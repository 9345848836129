import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import CreateFailSafeModal from './FailSafeComponents/CreateFailSafeModal';
import FailSafeCard from './FailSafeComponents/FailSafeCard';
import NoAccessPage from './NoAccessPage';

export function FailSafes(props) {
    const { id } = useParams();
    const [signals, setSignals] = useState([])

    const [loadingsignals, setLoadingSignals] = useState(true)
    const [failSafes, setFailSafes] = useState([]);
    const [viewState, setViewState] = useState(false);
    // Init modal data
    const [selectedOptions, setSelectedOptions] = useState({});

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id) ,[id]);

    const getFailSafes = () => {
        $.ajax({
            url: '/api/FailSafesAPI/GetFailSafes',
            type: "GET",
            data: { siteGUID: id },
            success: res => {
                let failSafeEntries = JSON.parse(res);
                setFailSafes(failSafeEntries.map(element =>
                    <FailSafeCard
                        key={element.FailSafeGUID}
                        data={element}
                        editSelf={_handleEdit}
                        deleteSelf={_handleDelete} />));
                setLoadingSignals(false);
            },
            error: () => setLoadingSignals(false)
        });
    }

    useEffect(() => {
        $.ajax({
            url: '/api/DownloadsAPI/GetSignals',
            type: 'get',
            data: { SiteGUID: id },
            type: 'get',
            success: function (response) {
                response = JSON.parse(response)
                setSignals(response)
            }
        });

        getFailSafes();
    }, [id]);

    useEffect(() => {

    }, []);

    const _handleCreate = () => {
        setViewState(false);
        getFailSafes();
    }

    // Pass info from card to create modal
    const _handleEdit = (data) => {
        console.log(data);
        setSelectedOptions(data);
        setViewState(true);
    }

    const _handleDelete = (failSafeGUID, deviceID) => {
        $.ajax({
            url: '/api/FailSafesAPI/DeleteFailSafe',
            type: "GET",
            data: { failSafeGUID: failSafeGUID, deviceID: deviceID },
            success: () => getFailSafes()
        });
    }

    return (
        props.auth !== undefined ?
            props.auth > 0 ?
            <div>
                <h1>Fail Safes</h1>
                    <div>
                        <Button className="ms-2" variant='primary' onClick={() => { setViewState(true); }}>Create New</Button>
                        <CreateFailSafeModal
                            siteGUID={id}
                            showState={viewState}
                            closeSelf={setViewState}
                            create={_handleCreate}
                            selected={selectedOptions} />

                        {/* Cards for all created fail safes */}
                        {loadingsignals ?
                            <div className="grid-item col-12 p-0">
                                <div className="m-2 shadow p-0 card" >
                                    <div className="card-header">Fetching fail safes...</div>
                                    <div className="card-body p-3">
                                        Fetching fail safes...
                                    <img style={{ height: '24px', width: '24px' }} src="/lib/ajax-loader.gif" />
                                    </div>
                                </div>
                            </div> :
                            (failSafes.length > 0 ?
                                <div className="d-flex">{failSafes}</div> :
                                <div className="grid-item col-12 p-0">
                                    <div className="m-2 shadow p-0 card" >
                                        <div className="card-header">No Fail Safes</div>
                                        <div className="card-body p-3">
                                            No fail safes found.
                                        </div>
                                    </div>
                                </div>)}
                    </div>
                </div>
                :
                <NoAccessPage />
        : <></>);
};
