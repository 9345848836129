import { StreamwiseEdge } from "../types";
import "./styles.scss";
import Cable from "./Cable";
import ShortCable from "./ShortCable";
import AirLine from "./AirLine";
import ShortAirLine from "./ShortAirLine";
import WirelessConnection from './WirelessConnection';
import ShortWirelessConnection from './ShortWirelessConnection';

export const edgeTypes = {
  cable: Cable,
  shortCable: ShortCable,
  airLine: AirLine,
  shortAirLine: ShortAirLine,
  wirelessConnection: WirelessConnection,
  shortWirelessConnection: ShortWirelessConnection,
};

export const SW_EDGE_LIST: StreamwiseEdge[] = [
  {
    id: "",
    type: "cable",
    source: '',
    target: '',
    zIndex: 1003,
    data: { 
      name: "Cable", 
      sku: "SWDI-CABLE", 
      segmentLength: 20,
    },
  },
  {
    id: "",
    type: "shortCable",
    source: '',
    target: '',
    zIndex: 1003,
    data: { 
      name: "Cable", 
      sku: "NA", 
      segmentLength: 20},
  },
  {
    id: "",
    type: "airLine",
    source: '',
    target: '',
    zIndex: 1003,
    animated: true,
    data: { 
      name: "Air line", 
      sku: "CLEAN-KIT", 
      segmentLength: 20},
  },
  {
    id: "",
    type: "shortAirLine",
    source: '',
    target: '',
    zIndex: 1003,
    animated: true,
    data: { 
      name: "Air line", 
      sku: "NA", 
      segmentLength: 20},
  },
  {
    id: "",
    type: "wirelessConnection",
    source: '',
    target: '',
    zIndex: 1003,
    animated: true,
    data: { 
      name: "Wireless connection", 
      sku: "SW123", 
      segmentLength: 1000},
  },
  {
    id: "",
    type: "shortWirelessConnection",
    source: '',
    target: '',
    zIndex: 1003,
    animated: true,
    data: { 
      name: "Wireless connection", 
      sku: "SW123", 
      segmentLength: 9999},
  },
];
