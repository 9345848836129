import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import {
  StreamwiseNode,
  StreamwiseEdge,
  BOM,
  BOM_item,
} from "./types";

class UniqueSKUSet extends Set {
    constructor(values) {
        super(values);

        const skus = [];
        for (let value of this) {
            if (skus.includes(value.sku)) {
                this.delete(value);
            } else {
                skus.push(value.sku);
            }
        }
    }
}

export function BillOfMaterials({
  nodes,
  edges,
}: {
  nodes: StreamwiseNode[];
  edges: StreamwiseEdge[];
}) {
    const [BOM, setBOM] = useState({items: []} as BOM)
    useEffect(() => {
        let bom_items = [];
        // Push modules
        let nodesData = nodes.map(n => n.data);
        // Remove nodes without SKU (e.g. Location)
        nodesData = nodesData.filter(d => d.sku && d.sku !== 'N/A');
        let uniqueNodes = [...new UniqueSKUSet(nodesData)];
        let uniqueModules = uniqueNodes.map(
          (un) =>
            ({
              name: un.name,
              sku: un.sku,
              qty: nodesData.filter((n) => n.sku === un.sku).length,
            } as BOM_item)
        );
        bom_items.push(...uniqueModules);

        // Push cables/air lines
        let edgesData = edges
          .filter((e) => e.type === "cable" || e.type === "airLine")
          .map((e) => e.data);
        let uniqueEdges = [...new UniqueSKUSet(edgesData.map(e => ({name: e.name, sku: e.sku, qty: 0} as BOM_item)))];
        edgesData.forEach(d => {
            uniqueEdges.find(e => e.sku === d.sku).qty += d.length ? Math.ceil((d.length / d.segmentLength)) : 0;
        })
        bom_items.push(...uniqueEdges);
        setBOM({items: bom_items});
    }, [nodes, edges])
  return (
    <div
      className="overflow-auto"
      style={{
        width: "100%",
        height: "100%",
        border: "2px solid black",
        borderRadius: "5px",
      }}
    >
      <h5 style={{ textAlign: "center", paddingTop: "5px" }}>
        BILL OF MATERIALS
      </h5>

      <Table striped bordered hover style={{fontSize: '0.7rem'}}>
        <thead>
          <tr>
            <th>SKU</th>
            <th>Item name</th>
            <th>Qty</th>
          </tr>
        </thead>

        <tbody >
          {BOM.items.map((i) => (
            <tr>
              <td>{i.sku}</td>
              <td>{i.name}</td>
              <td>{i.qty}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
