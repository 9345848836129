import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import ReactFlow, {
    ReactFlowProvider,
    Controls,
    Background,
    useReactFlow,
} from "react-flow-renderer";
import { Col, Row, Container, Spinner } from "react-bootstrap";
import NoAccessPage from "../NoAccessPage";
// import { nodes as initialNodes, edges as initialEdges } from './initial-elements';
import { nodeTypes } from "./Nodes";
import { edgeTypes } from "./Edges";
import { useConfigurator } from "./useConfigurator";
import { Signal, IControlChain, StreamwiseNode, StreamwiseEdge, StreamwiseNodeData } from "./types";
import { ModalManager } from "./ModalManager";
import {
    nodes as initialNodes,
    edges as initialEdges,
} from "./example-configuration";

function ControlChainContent(props) {
    const { SiteGUID, ChainID } = useParams();
    const [chain, setChain] = useState<IControlChain>();
    const [deviceID, setDeviceID] = useState<string>();
    // const [showQuestionnaire, setShowQuestionnaire] = useState(true);
    const [deviceSignals, setDeviceSignals] = useState<Signal[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const {
        loadConfig,
        loadControlChain,
        elements,
        onNodesChange,
        onEdgesChange,
        nodeID,
        currentModal,
        setCurrentModal,
        addControlLink,
        deleteLastBlendedBranch,
        getData,
        saveSettings,
        sourceID,
        targetID
    } = useConfigurator();

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(SiteGUID), [SiteGUID]);

    const { fitView } = useReactFlow();

    const closeModal = () => { setCurrentModal(''); }

    //useEffect(() => {
    //}, [currentModal, nodeID, sourceID, targetID]);

    useEffect(() => {
        fitView({ duration: 800 });
    }, [elements]);

    // load in configuration
    useEffect(() => {
        fetch("/api/ControlChainsAPI/GetControlChainData/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            // body: JSON.stringify('224aeb30-8b42-495c-83fe-c5f660208c74'),
            body: JSON.stringify(ChainID),
        })
            .then((response) => response.json())
            .then((controlChain: IControlChain) => {
                setChain(controlChain);
                setDeviceID(controlChain.deviceID);
                fetch("/api/ControlChainsAPI/GetSignals/", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(controlChain.deviceID),
                })
                    .then((response) => response.json())
                    .then((signals: Signal[]) => {
                        setDeviceSignals(signals);
                        fetch("/api/ControlChainsAPI/GetControlChain/", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            // body: JSON.stringify({ChainID: '224aeb30-8b42-495c-83fe-c5f660208c74'}),
                            body: JSON.stringify({ ChainID: ChainID }),
                        })
                            .then((response) => response.json())
                            .then((controlChain: StreamwiseNodeData[]) => {
                                if (controlChain.length > 0) {
                                    loadControlChain(controlChain, signals);
                                }
                                else {
                                    loadConfig({ edges: initialEdges, nodes: initialNodes });
                                    // Create the initial blocks on edge
                                    var chain = [] as StreamwiseNodeData[];
                                    let j: any;
                                    for (j in initialNodes) {
                                        const link = initialNodes[j].data;
                                        link.onEdit = undefined;
                                        link.onDelete = undefined;
                                        chain = [...chain, link];
                                    }

                                    fetch("/api/ControlChainsAPI/SaveControlChain/", {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({ ChainID: ChainID, Links: chain }),
                                    });
                                }
                                setLoading(false);
                            })
                            .catch((error) => {
                                console.error(error);
                                throw 'Error fetching control chain';
                            });
                    })
                    .catch((error) => {
                        console.error(error);
                        throw 'Error fetching signals';
                    }
                    );
            })
            .catch((error) => {
                console.error(error);
                throw 'Error fetching control chain data';
            }
            );




    }, []);

    const submitControlChain = () => {
        if (props.auth > 1) {
            var chain = [] as StreamwiseNodeData[];
            let j: any;
            for (j in elements.nodes) {
                const link = elements.nodes[j].data;
                link.onEdit = undefined;
                link.onDelete = undefined;
                chain = [...chain, link];
            }

            fetch("/api/ControlChainsAPI/SaveControlChain/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                // body: JSON.stringify({ChainID: 'e239397f-e213-44d9-b489-d19f1c5777cd',Links:chain}),
                body: JSON.stringify({ ChainID: ChainID, Links: chain }),
            });
        }
    };

    const submitControlLink = (link: StreamwiseNodeData) => {
        if (props.auth > 1) {

            fetch("/api/ControlChainsAPI/SaveControlChain/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ ChainID: ChainID, Links: [link] }),
            });
        }
    }

    return props.auth !== undefined ? (
        props.auth > 0 ? (
            <>
                <ModalManager
                    close={closeModal}
                    modal={currentModal}
                    signals={deviceSignals}
                    nodeID={nodeID}
                    sourceID={sourceID}
                    targetID={targetID}
                    addControlLink={addControlLink}
                    getData={getData}
                    saveSettings={saveSettings}
                    deleteLastBlendedBranch={deleteLastBlendedBranch}
                    submitControlChain={submitControlChain}
                    submitControlLink={submitControlLink}
                    deviceID={deviceID}
                    controlChainID={ChainID}
                />
                <div>
                    <Container
                        style={{
                            height: "65vh",
                            marginTop: "10px",
                            maxWidth: "1400px",
                            width: "90vw",
                        }}
                    >
                        <>
                            <Row className="mb-3" style={{ height: "95%" }}>
                                <Col xs={12}>
                                    <div
                                        style={{
                                            height: "100%",
                                            border: "2px solid black",
                                            borderRadius: "5px",
                                            width: "100%",
                                        }}
                                    >
                                        {error ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    height: "100%",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                Error occurred while loading control chain
                                            </div>
                                        ) : loading ? (
                                            <div style={{
                                                display: "flex",
                                                height: "100%",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: 'column',
                                            }}>
                                                <Spinner animation="border" />
                                                <br />
                                                <div>
                                                    Control chart loading...
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <h5 style={{ textAlign: "center", paddingTop: "5px" }}>
                                                    {chain?.name.toUpperCase()} (Device: {chain?.deviceName}
                                                    )
                                                </h5>
                                                <div style={{ height: "calc(100% - 40px)" }}>
                                                    <ReactFlow
                                                        nodes={elements?.nodes}
                                                        edges={elements?.edges}
                                                        onNodesChange={onNodesChange}
                                                        onEdgesChange={onEdgesChange}
                                                        fitView
                                                        nodeTypes={nodeTypes}
                                                        edgeTypes={edgeTypes}
                                                        elementsSelectable={true}
                                                        nodesConnectable={false}
                                                        nodesDraggable={false}
                                                        attributionPosition="bottom-right"
                                                    >
                                                        <Controls />
                                                        <Background color="#aaa" gap={16} />
                                                    </ReactFlow>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            {/*<Row style={{ height: "5%" }}>*/}
                            {/*    <Col>*/}
                            {/*        <div style={{ height: "100%", width: "100%", borderRadius: "5px" }}>*/}
                            {/*            <Button*/}
                            {/*                style={{ width: "100%" }}*/}
                            {/*                onClick={() => setCurrentModal("save-modal")}*/}
                            {/*                disabled={error || loading || props.auth < 2}*/}
                            {/*            >*/}
                            {/*                SAVE CONTROL CHAIN*/}
                            {/*            </Button>*/}
                            {/*        </div>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </>
                    </Container>
                </div>
                <br/>
                <br/>
            </>
        ) : (
            <NoAccessPage />
        )
    ) : (
        <></>
    );
}

export function ControlChain(props) {
    const [finishStatus, setfinishStatus] = useState(false);
    const { SiteGUID } = useParams();

    const history = useHistory();

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!finishStatus) {
            if (window.confirm("Any unsaved changes will be lost, are you sure you want to go back?")) {
                setfinishStatus(true)
                // your logic
                history.push("/ControlChains/index/" + SiteGUID);
            } else {
                window.history.pushState(null, null, window.location.pathname);
                setfinishStatus(false)
            }
        }
    }

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);

    return (

        <ReactFlowProvider>
            <ControlChainContent {...props} />
        </ReactFlowProvider>
    );
}