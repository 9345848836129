import React, {useState, useRef, useEffect} from 'react';
import { getSmoothStepPath, getEdgeCenter, getMarkerEnd } from 'react-flow-renderer';
import { Button } from 'react-bootstrap';
import { usePointsOnPath } from './usePointsOnPath';

import './styles.scss';

const foreignObjectHeight = 20;
const foreignObjectWidth = 100;

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}) {
  const [length, setLength] = useState<number>(data.length);
  const pathRef = useRef<SVGPathElement>(null);
  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const center = 0.5;
  const {
    points: [centerPoint],
  } = usePointsOnPath([center], pathRef, edgePath);

  return (
    <>
      <path
        ref={pathRef}
        id={id}
        style={{
          ...style,
          filter: length ? "none" : "drop-shadow(0px 0px 2px rgb(255 0 0))",
          stroke: '#5dc7f5'
        }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {centerPoint && 
        <foreignObject
          width={foreignObjectWidth}
          height={foreignObjectHeight}
          x={centerPoint!!.x - foreignObjectWidth / 2}
          y={centerPoint!!.y - foreignObjectHeight / 2}
          className="cable-foreignobject"
          requiredExtensions="http://www.w3.org/1999/xhtml"
          style={{overflow: 'unset'}}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              filter: length ? "none" : "drop-shadow(0px 0px 2px rgb(255 0 0))",
            }}
          >
            <input
              style={{
                fontSize: 8,
                textAlign: "right",
                paddingRight: '10px',
                width: "28px",
              }}
              type="number"
              min={0}
              max={999}
              value={length}
              onChange={(e) => {
                let val = parseInt(e.target.value);
                console.log({val});
                setLength(val);
                data.onLengthChange(id, val);
              }}
            />
            <span style={{fontSize: 8, marginLeft:'-11px', marginTop: '2px'}}>m</span>
          </div>
          {!length && 
            <div style={{fontSize:8, color: 'red', overflow: 'unset', textAlign: 'center'}}>
              LENGTH MISSING
            </div>
          }
        </foreignObject> 
      }
    </>
  );
}
