export interface Settings {
    edgeAlarm: EdgeAlarmModel;
    edgeAlarmThreshold?: EdgeAlarmThresholdModel;
    failSafe?: FailSafeModel;
    edgeAlarmInstability?: EdgeAlarmInstabilityModel;
    failSafeConstant?: FailSafeConstantModel;
    actuators?: Actuator[];
    isEdgeAlarm: boolean;
}

// EdgeModels
export interface EdgeAlarmModel {
    edgeAlarmID: number;
    failSafeGUID?: string;
    signalID: string;
    failSafeTriggerID?: number;
    trigger: boolean;
}

export interface EdgeAlarmThresholdModel {
    edgeAlarmID: number;
    setTime: number;
    resetTime: number;
    upperThreshold: number;
    lowerThreshold: number;
}

export interface EdgeAlarmInstabilityModel {
    edgeAlarmID: number;
    frequency: number;
}

export interface FailSafeModel {
    failSafeGUID?: string;
    terminalID: string;
    failSafeID: number;
}

export interface FailSafeConstantModel {
    failSafeGUID?: string;
    constantValue: number;
    scale: boolean;
}

export interface Actuator {
    signalID: string;
    niceName: string;
}

export const enum TRIGGERS{
    Freeze = 0,
    Threshold = 1,
    Instability = 2,
    NoData = 4
}

export const enum FAILSAFETYPE
{
    Stop = 0,
    Constant = 1,
    MovingAverage = 2,
    Intelligent = 3
}
