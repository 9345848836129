export type SupportTicketsProps = {
  auth: number;
  SiteGUIDSetter: (id: string) => void;
};

export type TicketDetailsProps = {
    ticketData: SupportTicket;
    editTicket: (newTicket: SupportTicket) => void;
    addComment: (ticketId: string, comment: string, name: string, siteGUID: string) => void;
    setSelectedTicket: (ticketId:string | undefined) => void;
    addAttachment: (ticketId: string, file: File[]) => void;
};

export type PriorityBadgeProps = {
    classes?: string;
    data: SupportTicket;
    editTicket: (newTicket: SupportTicket) => void;
};

export type StatusBadgeProps = {
    classes?: string;
    data: SupportTicket;
    editTicket: (newTicket: SupportTicket) => void;
};

export type AttachmentBadgeProps = {
}


export type NewTicketRowProps = {
    siteGUID: string;
    createTicket: (name: string, desc: string, siteGUID: string) => void;
    setNewRow: (value: boolean) => void;
}

export type SupportTicketRowProps = {
    data: SupportTicket;
    editTicket: (newTicket: SupportTicket) => void;
    addComment: (ticketId: string, comment: string, name: string, siteGUID: string) => void;
    setSelectedTicket: (id: string) => void;
    edit?: boolean;
}

export type ModalProps = {
    data: SupportTicket;
    editTicket: (newTicket: SupportTicket) => void;
    addComment: (ticketId: string, comment: string, name: string, siteGUID: string) => void;
    show: boolean;
    onHide: () => void;
}

export enum Priorities {
    Urgent = 1,
    High = 2,
    Normal = 3,
    Low = 4,
    None = 100
}

export enum Statuses {
    Logged = 0,
    Assigned = 1,
    InProgress = 2,
    Closed = 3,
    None = 100,
}

export type SupportTicket = {
    attachments: Attachment[]
    comments: Comment[];        // List of comments for this ticket
    createDate: Date;
    description: string;        // Short summary of ticket
    id: string;                 // ticket number (unique to site)
    lastUpdatedDate: Date;  
    name: string;               // title of the ticket
    priority: Priorities;       // Current priority
    siteGUID: string;
    ticketStatus: Statuses;     // Current status
    userID: string;
    user?: User;
};

export type Comment = {
    text: string;
    createDate: Date;
    user?: User;     
};

export type Attachment = {
    id: string;
    url: string;
    fileName: string;
    createDate: Date;
}

export type User = {
    name: string;
    email: string;
    isSWDI?: boolean;
}