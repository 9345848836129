import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import { StreamwiseNodeData } from '../types';
import './styles.scss'
import { Button } from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash, faCircleInfo, faEllipsis} from '@fortawesome/free-solid-svg-icons'


export default memo(
  ({
    id,
    data,
  }: {
    id: string;
    data: StreamwiseNodeData;
  }) => {
    return (
      <>
        <div className="handleLabelTop" style={{ left: 40 }}>
        </div>
        {/* {data.blended?.map((val, index) => <Handle
          type="target"
          position="left"
          id={"INPUT" + (index+1)}
          style={{ top: 35, background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={false}
        />)} */}

        <Handle
          type="target"
          position="left"
          id={"INPUT1"}
          style={{ top: 35, background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={false}
        />
        <Handle
          type="target"
          position="bottom"
          id={"INPUT2"}
          style={{ left: 40, background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={false}
        />
        <Handle
          type="target"
          position="bottom"
          id={"INPUT3"}
          style={{ left: 40, background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={false}
        />
        <Handle
          type="target"
          position="bottom"
          id={"INPUT4"}
          style={{ left: 40, background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={false}
        />
        
        <Handle
          type="source"
          position="right"
          id="OUTPUT"
          style={{ top: 35, background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={false}
        />
        <div className="nodeText">{data.name}</div>
        <div style={{height: '20px'}}>
                <div className="infoButton" onClick={() => { if (data.onEdit) data.onEdit(id) }}>
                    <FontAwesomeIcon icon={faEllipsis} />
          </div>
                <div className="trashButton" onClick={() => { if (data.onDelete) data.onDelete(id) }}>
                    <FontAwesomeIcon icon={faTrash} />
          </div>
        </div>
      </>
    );
  }
);