import { useState, useEffect, useRef } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';
import * as signalR from '@microsoft/signalr';

const AqualaboDetails = ({ deviceID, slaveID, show, setShow }) => {
    const [newSlave, setNewSlave] = useState(slaveID);
    const [sensorParams, setSensorParams] = useState([]);
    const paramRef = useRef(sensorParams);

    var connection = new signalR.HubConnectionBuilder()
        .withUrl("/messageHub")
        .withAutomaticReconnect()
        .build();

    useEffect(() => {
        if (show) {
            console.log(slaveID);
            let tag = "";
            connection.stop();
            // Begin signal R
            connection.start().then(() => {
                tag = "ModalStream" + connection.connectionId;
                console.log(tag);

                fetch('/api/EdgeDiagnosticsAPI/GetAqualaboDetails/?deviceID=' + deviceID
                    + '&slaveID=' + slaveID + '&signalRTag=' + tag)
                    .then(res => {
                        if (res.ok) { return res.json(); }
                        throw new Error("Could not fetch Aqualabo info");
                    })
                    .then(params =>
                    {
                        for (var i = 0; i < params.length; i++) {
                            params[i].ParamValue = params[i].ParamValue.toFixed(2);
                        }
                        setSensorParams(params);
                        paramRef.current = params;
                        console.log(params);
                    })
                    .catch(error => console.error(error));

                connection.on(tag, msg => {
                    let entry = JSON.parse(msg);
                    console.log(entry);
                    let newParams = [...paramRef.current];
                    console.log(newParams);
                    for (var i = 0; i < entry.length; i++) {
                        newParams[i].ParamValue = entry[i].toFixed(2);
                    }
                    setSensorParams(newParams);
                });
            }).catch(e => console.log('Scan connection failed: ', e));
        }
    }, [show])

    useEffect(() => {
        setNewSlave(slaveID);
    }, [slaveID])

    const changeSlaveID = () => {
        fetch("/api/EdgeDiagnosticsAPI/ChangeAqualaboSlaveID/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                DeviceID: deviceID,
                CurSlaveID: slaveID,
                NewSlaveID: Number(newSlave)
            })
        }).then(res => {
            if (res.ok) {
                slaveID = Number(newSlave);
                alert("Slave ID changed!");
            }
            else {
                alert("Failed to change slave ID :(");
            }
        }).catch();
    }

    const _handleModalClose = () => {
        // disconnect signalR
        fetch("/api/EdgeDiagnosticsAPI/StopSignalStreamAqualabo/?deviceID=" + deviceID)
            .then(res => {
                if (res.ok) {
                    setShow(false);
                }
            });
    }

    return (
        <Modal show={show} onHide={_handleModalClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>PONSEL Sensor</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">
                <Row>
                    <Col>
                        <InputGroup>
                            <InputGroup.Text>Slave ID</InputGroup.Text>
                            <Form.Control
                                value={newSlave}
                                onChange={e => setNewSlave(e.target.value)}
                                style={{ width: "60px" }} />
                            <Button onClick={changeSlaveID}>
                                <i className="fas fa-check"></i>
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Parameter</th>
                                    <th>Value</th>
                                    <th>Unit</th>
                                    <th>Status</th>
                                    <th>Range</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sensorParams.length > 0 ?
                                    sensorParams.map(p =>
                                        <tr key={p.ParamName + p.ParamUnit}>
                                            <td>{p.ParamName}</td>
                                            <td>{p.ParamValue}</td>
                                            <td>{p.ParamUnit}</td>
                                            <td>{p.ParamStatus}</td>
                                            <td>{p.ParamRange}</td>
                                        </tr>)
                                    : <tr>
                                        <td colSpan={5} style={{ textAlign: "center" }}>
                                            <Spinner animation="border" variant="primary" />
                                        </td>
                                    </tr>
                                }
                            </tbody> 
                        </Table>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
        );
}

export default AqualaboDetails;