import { useState } from 'react';
import * as signalR from '@microsoft/signalr';

export interface IDataList {
    [index: string]: number;
}

export const useSignalR = (deviceID?:string): [Function, Function, IDataList] => {
    const [latestValues, setLatestValues] = useState<IDataList>({});
    const [connection, setConnection] = useState<signalR.HubConnection>(new signalR.HubConnectionBuilder().withUrl("/messageHub").build());
    const [listenerTag, setListenerTag] = useState<string>();

    const startSignalR = () => {
        console.log("Starting connection");
        if (connection.state !== 'Connected') {
            connection.start().then(() => {
                const tag = "DoSomething" + connection.connectionId;
                setListenerTag(tag);
                // Send connection listener tag to edge via API call
                console.log("Sending streaming command to device " + deviceID)
                fetch("/api/SharedCommandsAPI/ToggleSignalR?" +
                    new URLSearchParams({
                        deviceID: deviceID!!,
                        listenerTag: tag,
                        state: 'true'
                    })
                );

                connection.on(tag, message => {
                    const curLiveData = JSON.parse(message);
                    setLatestValues(curLiveData);
                });
            }).catch(function (err) {
                console.error("444: " + err.toString());
            }).finally(function () {
                console.log("Couldn't handle signalR connection")
            });
        }
    }

    const stopSignalR = () => {
        if (connection.state === "Connected") {
            // Stop signal streaming on unmount
            fetch("/api/SharedCommandsAPI/ToggleSignalR?" +
                new URLSearchParams({
                    deviceID: deviceID!!,
                    listenerTag: listenerTag!!,
                    state: 'false',
                })
            );
            connection.stop();
        }
    }

    return [startSignalR, stopSignalR, latestValues];
}