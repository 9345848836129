import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import { StreamwiseNodeData } from '../types';
import './styles.scss'

const PORT_POSITIONS = [20, 25]

export default memo(
  ({
    id,
    data,
  }: {
    id: string;
    data: StreamwiseNodeData;
  }) => {
    return (
      <>
      {data.controlLinkData.signalID === undefined || data.controlLinkData.signalID.length === 0 ? 
      <div onClick={() => {if (data.onEdit) data.onEdit(id)}}>
        <div className="nodeText">Select Input Signal</div>
        <Handle
          type="source"
          position="right"
          id="OUTPUT"
          style={{ top: PORT_POSITIONS[1], background: "#5dc7f5" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={false}
        />
      </div> : 
      <div onClick={() => {if (data.onEdit) data.onEdit(id)}}>
        <div className="nodeText">
          {data.controlLinkData.nicename !== undefined ? 
            data.controlLinkData.nicename
            :
            "Input Signal"
          }
          <div style={{height: '20px'}}><div className="nodeSubText">Click to edit</div></div>
        </div>
        <Handle
          type="source"
          position="right"
          id="OUTPUT"
          style={{ top: PORT_POSITIONS[1], background: "#5dc7f5" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={false}
        />
      </div>}
      </>
    );
  }
);