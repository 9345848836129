import { useState, useEffect } from "react";
import { SupportTicket, Statuses, Priorities } from "./types";

export const castToStatus = (statusID: number): Statuses => {
    switch (statusID) {
        case 0:
            return Statuses.Logged;
        case 1:
            return Statuses.Assigned;
        case 2:
            return Statuses.InProgress;
        case 3:
            return Statuses.Closed;
        default:
            return Statuses.None;
    }
}

export const castToPriority = (priorityInt: number): Priorities => {
    switch (priorityInt) {
        case 1:
            return Priorities.Urgent
        case 2:
            return Priorities.High;
        case 3:
            return Priorities.Normal;
        case 4:
            return Priorities.Low;
        default:
            return Priorities.None;
    }
}

function useTickets(SiteGUID: string) {
    const [tickets, setTickets] = useState<SupportTicket[]>([]);
    const [loading, setLoading] = useState(true);

    // Tasks on load
    useEffect(() => {
        fetch(`/api/TicketingSystemAPI/GetTickets/?siteGUID=${SiteGUID}`)
            .then(res => res.json())
            .then(tks => {
                console.log(tks);
                let currentTks: SupportTicket[] = [];
                for (let i = 0; i < tks.length; i++) {
                    currentTks.push({
                        attachments: tks[i].attachments,
                        comments: tks[i].comments,
                        createDate: new Date(tks[i].createDate),
                        description: tks[i].description,
                        id: tks[i].id,
                        lastUpdatedDate: new Date(tks[i].lastUpdatedDate),
                        name: tks[i].name,
                        priority: castToPriority(tks[i].priority),
                        siteGUID: tks[i].siteGUID,
                        ticketStatus: castToStatus(tks[i].ticketStatus),
                        userID: tks[i].userID,
                        user: tks[i].user
                    })
                }
                console.log(currentTks)
                setTickets([...tickets, ...currentTks]);
                setLoading(false);
            });
    }, []);

    const editTicket = (newTicket: SupportTicket) => {
        setLoading(true);

        let updatedTickets = [...tickets];
        const idx = updatedTickets.findIndex(tk => tk.id === newTicket.id);

        updatedTickets[idx].name = newTicket.name;
        updatedTickets[idx].description = newTicket.description;
        updatedTickets[idx].priority = newTicket.priority;
        updatedTickets[idx].ticketStatus = newTicket.ticketStatus;
        
        fetch('/api/TicketingSystemAPI/EditTicket/', {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedTickets[idx])
        }).then(res => {
            if (res.ok) { setLoading(false) }
        });

        setTickets(updatedTickets);
    }

    const addComment = (ticketId: string, comment: string, name:string, siteGUID:string) => {
        const idx = tickets.findIndex(tk => tk.id === ticketId);

        tickets[idx].comments = [
            { createDate: new Date(), text: comment },
            ...tickets[idx].comments
        ];

        fetch("/api/TicketingSystemAPI/AddComment/", {
            method: 'post',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                ticketID: ticketId,
                comment: comment,
                name: name,
                siteGUID: siteGUID
            })
        }).then(res => {
            if (res.ok) {
                setTickets(tickets);
            }
        });
    }

    const addAttachment = (ticketId: string, files: File[]) => {
        const idx = tickets.findIndex(tk => tk.id === ticketId);
        files.forEach((file:File) => {
            let formData = new FormData();
            formData.append('ticketId', ticketId);
            formData.append('fileName', file.name);
            formData.append('file', file);
        
            fetch("/api/TicketingSystemAPI/AddAttachment/", {
                method: "POST",
                body: formData,
            })
            .then(res => res.json())
            .then(doc => {
                tickets[idx].attachments = [doc, ...tickets[idx].attachments];
            });
        });
    }


    const createTicket = (name: string, desc: string, siteGUID: string) => {
        setLoading(true);

        fetch(`api/TicketingSystemAPI/CreateTicket/?siteGUID=${siteGUID}&name=${name}&description=${desc}`)
            .then(res => res.json())
            .then(tk => {
                console.log(tk);
                const newTicket: SupportTicket = {
                    id: tk.id,
                    name: tk.name,
                    description: tk.description,
                    siteGUID: siteGUID,
                    ticketStatus: tk.ticketStatus,
                    priority: tk.priority,
                    createDate: tk.createDate,
                    lastUpdatedDate: tk.lastUpdatedDate,
                    userID: tk.userID,
                    user:tk.user,
                    attachments: [],
                    comments: []
                }
                setTickets(ts => [...ts, newTicket]);
                setLoading(false);
            }
        )

    }

    return {
        loading,
        tickets,
        createTicket,
        editTicket,
        addComment,
        addAttachment
    }
}

export { useTickets }