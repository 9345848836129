export const ROOT_Y= 0;
export const EXPANSION_Y = -5;
export const EXPANSION_SPACING = 50;

export const LOCATION_WIDTH = 130;
export const LOCATION_X = 190;
export const LOCATION_Y = 130;
export const LOCATION_SPACING = 50;

export const SENSOR_WIDTH = 100;
export const ACTUAL_SENSOR_WIDTH = 152;
export const ACTUAL_SENSOR_WIDTH2 = 80;
export const SENSOR_Y = 95;
export const SENSOR_SPACING = 20;

export const SPLITTER_Y = 50;
export const SPLITTER_X = 10;
export const SPLITTER_SPACING = 50;

export const PORT_SPLITTER_X_A = 250;
export const PORT_SPLITTER_X_B = 290;
export const PORT_SPLITTER_Y = 80;

export const AIR_SPLITTER_Y = 10;
export const AIR_SPLITTER_X = 50;
export const AIR_SPLITTER_SPACING = 70;

export const PRIMARY_AIR_SPLITTER_X = 40;
export const PRIMARY_AIR_SPLITTER_X_C = 20;

export const AIR_PORT_SPLITTER_Y = 60;
