import { useState, useEffect, useContext } from 'react';
import { Accordion, Button, Card, Modal, InputGroup, FormControl, Row, Col } from 'react-bootstrap';
import $ from 'jquery';
import { ReportContext } from './ReportContext';
import { Collapse } from 'react-bootstrap';

const WidgetCard = ({ data, equations, handleDelete, auth }) => {
    const context = useContext(ReportContext);
    const [name, setName] = useState(data.EquationName);
    const [eqnID, setEqnID] = useState(data.EquationID);
    const [inputs, setInputs] = useState([{}, {}]);

    // Retrieve the inputs
    useEffect(() => {
        $.ajax({
            url: '/api/ReportsAPI/GetWidgetInputs',
            type: 'get',
            data: { widgetID: data.WidgetID },
            success: (res) => {
                let input = JSON.parse(res);
                if (input.length == 1) {
                    input = [...input, {}]
                }
                setInputs(input);
            }
        });
    }, []);

    const _handleEdit = () => {
        console.log(inputs);
        $.ajax({
            url: '/api/ReportsAPI/EditWidgets',
            type: 'post',
            data: JSON.stringify({
                Widget: { widgetID: data.WidgetID, equationID: eqnID, equationName: name },
                Inputs: inputs
            }),
            contentType: "application/json"
        });
    }

    const _handleSignalEdit = (inputID, signalID) => {
        let newInputs = [...inputs];
        for (var i = 0; i < newInputs.length; i++) {
            if (newInputs[i].WidgetInputsID == inputID) {
                newInputs[i].SignalID = signalID;
            }
        }
        setInputs(newInputs);
    }

    return (
        <Card className="m-2" style={{ width: "400px" }}>
            <Card.Header>{name}</Card.Header>
            <Card.Body className="p-3">
                <Row>
                    <Col>
                        <InputGroup className="justify-content-center mb-2">
                            <InputGroup.Text>Widget name: </InputGroup.Text>
                            <FormControl
                                aria-describedby="basic-addon3"
                                onChange={e => setName(e.target.value)}
                                value={name}>
                            </FormControl>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup className="justify-content-center mb-2">
                            <InputGroup.Text>Metric: </InputGroup.Text>
                            <FormControl as="select"
                                aria-describedby="basic-addon3"
                                onChange={e => setEqnID(e.target.value)}
                                value={eqnID}>
                                {equations}
                            </FormControl>
                        </InputGroup>
                    </Col>
                </Row>

                {(eqnID != 4) ?
                    <InputGroup className="justify-content-center mb-3">
                        <InputGroup.Text>Signal of Interest</InputGroup.Text>
                        <FormControl as="select"
                            aria-describedby="basic-addon3"
                            onChange={e => _handleSignalEdit(inputs[0].WidgetInputsID, e.target.value)}
                            value={inputs[0].SignalID} >
                            {context.signals.map(signal => <option key={signal.SignalID} value={signal.SignalID}>{signal.NiceName}</option>)}
                        </FormControl>
                    </InputGroup> :

                    <div>
                        <Row>
                            <Col>
                                <InputGroup className="justify-content-center mb-2">
                                    <InputGroup.Text>Inlet signal: </InputGroup.Text>
                                    <FormControl as="select"
                                        aria-describedby="basic-addon3"
                                        onChange={e => _handleSignalEdit(inputs[0].WidgetInputsID, e.target.value)}
                                        value={inputs[0].SignalID}
                                    >
                                        {context.signals.map(signal => <option key={signal.SignalID} value={signal.SignalID}>{signal.NiceName}</option>)}
                                    </FormControl>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup className="justify-content-center mb-2">
                                    <InputGroup.Text>Outlet signal: </InputGroup.Text>
                                    <FormControl as="select"
                                        aria-describedby="basic-addon3"
                                        onChange={e => _handleSignalEdit(inputs[1].WidgetInputsID, e.target.value)}
                                        value={inputs[1].SignalID}
                                    >
                                        {context.signals.map(signal => <option key={signal.SignalID} value={signal.SignalID}>{signal.NiceName}</option>)}
                                    </FormControl>
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                }

                {auth > 1 &&
                    <Row>
                        <Col>
                            <Button variant="danger" onClick={() => handleDelete(data)}>
                                Delete Widget  <i className="fas fa-trash-alt"></i>
                            </Button>
                        </Col>
                        <Col>
                            <Button className="float-right" variant="primary" onClick={_handleEdit}>
                                Save settings  <i className="fas fa-save"></i>
                            </Button>
                        </Col>
                    </Row>
                }
            </Card.Body>
        </Card>
    );
}

const WidgetModal = ({ show, siteGUID, auth }) => {
    const context = useContext(ReportContext);
    const [charts, setCharts] = useState([]);
    const [equations, setEquations] = useState([])

    // New chart state
    const [newName, setNewName] = useState("");
    const [newEqnID, setNewEqnID] = useState("");
    const [signalID1, setSignalID1] = useState("");
    const [signalID2, setSignalID2] = useState("");

    useEffect(() => {
        // Get equations
        console.log("Get equations - Widget");
        $.ajax({
            url: '/api/ReportsAPI/GetEquationList',
            type: 'get',
            success: (res) => {
                let eqnList = JSON.parse(res);
                setEquations([...eqnList.map(item => <option key={item.EquationID} value={item.EquationID}>{item.EquationDescription}</option>)])
            }
        });
    }, []);

    const retrieveCharts = () => {
        $.ajax({
            url: '/api/ReportsAPI/GetWidgets',
            type: 'get',
            data: { siteGUID: siteGUID },
            success: (res) => {
                console.log("retrieving charts")
                let chartSettings = JSON.parse(res);
                setCharts([...chartSettings.map(chart =>
                    <WidgetCard key={chart.WidgetID} data={chart} equations={equations} handleDelete={_handleChartDelete} auth={auth} />)]);
            }
        });
    }

    useEffect(() => {
        if (show) {
            retrieveCharts();
        }
    }, [show]);

    const _handleChartCreate = () => {
        $.ajax({
            url: '/api/ReportsAPI/CreateWidgets',
            type: 'post',
            data: JSON.stringify({
                Widget: { SiteGUID: siteGUID, EquationID: newEqnID, EquationName: newName },
                Inputs: [{ SignalID: signalID1 }, { SignalID: signalID2 }]
            }),
            contentType: "application/json",
            success: () => {
                retrieveCharts();
                // Clear filled fields
                setNewName("");
                setNewEqnID("");
                setSignalID1("");
                setSignalID2("");
            }
        });
    }

    const _handleChartDelete = (chart) => {
        $.ajax({
            url: '/api/ReportsAPI/DeleteWidgets',
            type: 'post',
            data: JSON.stringify({ WidgetID: chart.WidgetID }),
            contentType: 'application/json',
            success: () => { retrieveCharts() }
        });
    }

    return (
        <Collapse
            in={show}
        >
            <div>
                <div className="d-flex flex-wrap">
                    <Accordion>
                        <Accordion.Item className="m-2" eventKey="0" style={{ width: "400px" }}>
                            <Accordion.Header>
                                Add New Widget
                            </Accordion.Header>
                            <Accordion.Body className="p-3">
                                <Row>
                                    <Col>
                                        <InputGroup className="justify-content-center mb-2">
                                            <InputGroup.Text>Widget name: </InputGroup.Text>
                                            <FormControl
                                                aria-describedby="basic-addon3"
                                                value={newName}
                                                onChange={e => setNewName(e.target.value)}>
                                            </FormControl>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputGroup className="justify-content-center mb-2">
                                            <InputGroup.Text>Metric: </InputGroup.Text>
                                            <FormControl as="select"
                                                aria-describedby="basic-addon3"
                                                value={newEqnID}
                                                onChange={e => setNewEqnID(e.target.value)}>
                                                <option></option>
                                                {equations}
                                            </FormControl>
                                        </InputGroup>
                                    </Col>
                                </Row>

                                {/* Signal selections */}
                                {(newEqnID != 4) ?
                                    <Row>
                                        <Col>
                                            <InputGroup className="justify-content-center mb-2">
                                                <InputGroup.Text>Signal of interest: </InputGroup.Text>
                                                <FormControl as="select"
                                                    aria-describedby="basic-addon3"
                                                    value={signalID1}
                                                    onChange={e => setSignalID1(e.target.value)}>
                                                    {context.signals.map(signal => <option key={signal.SignalID} value={signal.SignalID}>{signal.NiceName}</option>)}
                                                </FormControl>
                                            </InputGroup>
                                        </Col>
                                    </Row> :
                                    <div>
                                        <Row>
                                            <Col>
                                                <InputGroup className="justify-content-center mb-2">
                                                    <InputGroup.Text>Inlet signal: </InputGroup.Text>
                                                    <FormControl as="select"
                                                        aria-describedby="basic-addon3"
                                                        value={signalID1}
                                                        onChange={e => setSignalID1(e.target.value)}>
                                                        {context.signals.map(signal => <option key={signal.SignalID} value={signal.SignalID}>{signal.NiceName}</option>)}
                                                    </FormControl>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <InputGroup className="justify-content-center mb-2">
                                                    <InputGroup.Text>Outlet signal: </InputGroup.Text>
                                                    <FormControl as="select"
                                                        aria-describedby="basic-addon3"
                                                        value={signalID2}
                                                        onChange={e => setSignalID2(e.target.value)}>
                                                        {context.signals.map(signal => <option key={signal.SignalID} value={signal.SignalID}>{signal.NiceName}</option>)}
                                                    </FormControl>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                }

                                {auth > 1 && <Button variant="primary" onClick={_handleChartCreate}>Create</Button>}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    {charts}
                </div>
            </div>
        </Collapse>

    )
}

export default WidgetModal;