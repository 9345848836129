import { useState, useContext } from 'react';
import ClickableSignalSelect from "./ClickableSignalSelect"
import {
    InputGroup,
    FormControl,
    Button,
    Modal,
    Container,
    Col,
    Row,
    ToggleButton,
    Collapse,
} from "react-bootstrap";
import { ConfigContext } from "./LineChart"
import HelpTooltip from "../HelpTooltip";

const colorPickerColours = ['#14a8de', '#a0512d', '#944ba3', '#069416', '#002b4f', '#f47920', '#101820'];

const DeleteChartModal = ({ show, onHide, onRemove, chartID }) => {
    const handleDelete = () => {
        var url = '/api/LineChartAPI/RemoveLineChart/';
        fetch(url, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(chartID)
        })
            .then(res => {
                if (res.ok) {
                    onRemove(chartID);
                    onHide();
                }
            })
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Are you sure you want to delete this chart?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Deleting a chart cannot be undone.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col>
                            <Button variant="primary" >Cancel</Button>
                        </Col>
                        <Col>
                            <Button className="float-end" variant="danger" onClick={handleDelete}>Confirm</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

const SaveChartModal = ({ show, onHide, config, updateConfig }) => {
    const handleSave = () => {
        let newConfig = { ...config };
        if (newConfig.LineChartInputs) {
            // If no ID it is a new LCI, set to 0 so API accepts payload
            newConfig.LineChartInputs.forEach(l => {
                if (!l.LineChartInputID) { l.LineChartInputID = 0 }
                else { l.LineChartInputID = l.LineChartInputID }
            })
        }
        else {
            newConfig.LineChartInputs = [];
        }

        fetch('/api/LineChartAPI/SaveLineChartSettings/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(newConfig)
        })
        .then(response => response.json())
        .then(data => {
            updateConfig(data)
            onHide()
        });
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Are you sure you want to save changes?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    This will overwrite previous settings and cannot be undone.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col>
                            <Button variant="primary" >Cancel</Button>
                        </Col>
                        <Col>
                            <Button className="float-end" variant="success" onClick={handleSave}>Save</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

const ChartSettings = (props) => {
    const {
        config,
        updateLeftYAxisLabel,
        updateRightYAxisLabel,
        updateYRangeLow,
        updateYRangeHigh,
        updateHomeChecked,
        updateAnalyticsChecked,
        updateReportsChecked,
        updateDailyDataLogsChecked,
        updateConfig
    } = useContext(ConfigContext);

    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [saveModalShow, setSaveModalShow] = useState(false);
    // const [addFilter, setAddFilter] = useState(true);

    return config ? (
        <>
            {/* Signal input component container */}
            <InputGroup className="justify-content-center mb-2">
                <InputGroup.Text id="inputGroup-sizing-default">
                    <HelpTooltip
                        placement="bottom"
                        helptext="Press + to add more signals, or click on a signal to change its colour and style. Click the x to remove a signal. Drag and drop signals to rearrange."
                    />
                    &nbsp; Signals:
                </InputGroup.Text>
                <div style={{ width: "calc(100% - 130px)" }}>
                    <ClickableSignalSelect
                        options={props.signals}
                        value={props.signals.filter((signal) => signal.isSelected)}
                        colorPickerColours={colorPickerColours}
                    // addFilter={addFilter}
                    />
                </div>
            </InputGroup>

            {/* Axis labels */}
            <Container>
                <Row>
                    <Col>
                        <InputGroup className="justify-content-center mb-2">
                            <InputGroup.Text>Left Y-Axis Label: </InputGroup.Text>
                            <FormControl
                                aria-describedby="basic-addon3"
                                defaultValue={config.YAxisTitle}
                                onChange={e => {
                                    updateLeftYAxisLabel(e.target.value);
                                }}
                            ></FormControl>
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="justify-content-center mb-2">
                            <InputGroup.Text>Right Y-Axis Label: </InputGroup.Text>
                            <FormControl
                                aria-describedby="basic-addon3"
                                defaultValue={config.RightYAxisTitle}
                                onChange={e => {
                                    updateRightYAxisLabel(e.target.value);
                                }}
                            ></FormControl>
                        </InputGroup>
                    </Col>
                </Row>
            </Container>

            {/* Where you want the same chart to appear */}
            <Container>
                <Row>
                    <Col>
                        <InputGroup className="mb-2">
                            <InputGroup.Text>Chart appears in: </InputGroup.Text>
                            {[
                                {
                                    label: "Home",
                                    value: 0,
                                    checked: config.HomePage || false,
                                    updateChecked: updateHomeChecked,
                                },
                                {
                                    label: "Analytics",
                                    value: 1,
                                    checked: config.AnalyticsPage || false,
                                    updateChecked: updateAnalyticsChecked,
                                },
                                {
                                    label: "Reports",
                                    value: 2,
                                    checked: config.Reports || false,
                                    updateChecked: updateReportsChecked,
                                },
                                {
                                    label: "Daily data logs",
                                    value: 3,
                                    checked: config.DailyDataLogs || false,
                                    updateChecked: updateDailyDataLogsChecked,
                                },
                            ].map(c => (
                                <ToggleButton
                                    key={"radio" + c.label + '-' + config.LineChartID}
                                    id={"radio" + c.label}
                                    type="radio"
                                    variant="outline-secondary"
                                    name={"radio" + c.label + '-' + config.LineChartID}
                                    checked={c.checked}
                                    onClick={() => c.updateChecked(!c.checked)}
                                >
                                    {c.label}
                                </ToggleButton>
                            ))}
                        </InputGroup>
                    </Col>
                </Row>
            </Container>

            {/* Min-Max hidden settings for other chart locations */}
            <Collapse in={config.Reports || config.DailyDataLogs}>
                <Container>
                    <Row>
                        <Col>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>
                                    <HelpTooltip
                                        placement="bottom"
                                        helptext="This setting is only applied to reports charts and daily data logs."
                                    />
                                    &nbsp; Y-Axis Min:
                                </InputGroup.Text>
                                <FormControl
                                    type="number"
                                    aria-describedby="basic-addon3"
                                    defaultValue={config.YRangeLow}
                                    onChange={e => {
                                        updateYRangeLow(e.target.value);
                                    }}
                                ></FormControl>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>
                                    <HelpTooltip
                                        placement="bottom"
                                        helptext="This setting is only applied to reports charts and daily data logs."
                                    />
                                    &nbsp; Y-Axis Max:
                                </InputGroup.Text>
                                <FormControl
                                    type="number"
                                    aria-describedby="basic-addon3"
                                    defaultValue={config.YRangeHigh}
                                    onChange={e => {
                                        updateYRangeHigh(e.target.value);
                                    }}
                                ></FormControl>
                            </InputGroup>
                        </Col>
                    </Row>
                </Container>
            </Collapse>

            {/* Footer - Delete/Save buttons */}
            <Container>
                <Row>
                    <Col>
                        <Button variant="danger" onClick={() => setDeleteModalShow(true)}>
                            Delete chart <i className="fas fa-trash-alt"></i>
                        </Button>
                        <DeleteChartModal
                            show={deleteModalShow}
                            onHide={() => { setDeleteModalShow(false); props.hideSettings(); }}
                            onRemove={props.onremove}
                            chartID={config.LineChartID}
                        />
                    </Col>
                    <Col>
                        <Button
                            className="float-end"
                            variant="primary"
                            onClick={() => setSaveModalShow(true)}
                        >
                            Save settings <i className="fas fa-save"></i>
                        </Button>
                        <SaveChartModal
                            show={saveModalShow}
                            onHide={() => { setSaveModalShow(false); props.hideSettings(); }}
                            config={config}
                            updateConfig={updateConfig}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    ) : (
        "Loading..."
    );
}

export default ChartSettings;