import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import {
    Table, Form, InputGroup, Modal, Button, OverlayTrigger, Tooltip
} from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import Cookies from 'cookies-js';
import TextareaAutosize from 'react-textarea-autosize';
import NoAccessPage from './NoAccessPage';
import HelpButton from './HelpButton';
import LogMaintenance from './Maintenance/LogMaintenance';
import YouTubeVideo from './YouTubeVideo';

//Show info modal if updated since last visit
var AlarmsTabVersion = Cookies.get('AlarmsTabVersion');
var CurrentAlarmsTabVersion = 2;

const Comment = ({ data }) => {
    const [comment, setComment] = useState(data.Comments);
    const [editState, setEditState] = useState(false);
    const [editIcon, setEditIcon] = useState("fas fa-pencil-alt");

    const toggleCommentEdit = () => {
        let newState = !editState;
        setEditState(newState);
        // Change edit icon
        if (newState) {
            setEditIcon("fas fa-save");
        }
        else {
            setEditIcon("fas fa-pencil-alt");
            // Save the comment
            $.ajax({
                url: "/api/AlarmsAPI/UpdateComment",
                type: "post",
                data: JSON.stringify({ AlarmID: data.AlarmID, Comments: comment }),
                contentType: 'application/json'
            })
        }
    }

    return (<>
        <td>
            {editState ?
                <TextareaAutosize
                    className="p-2 form-control"
                    value={comment}
                    onChange={e => setComment(e.target.value)} /> :
                <div>{comment}</div>}
        </td>
        <td style={{ width: '50px' }}>
            <a className={"btn btn-outline-primary fas fa-2x " + editIcon} onClick={toggleCommentEdit}></a>
        </td></>);
}

function SendAlarmModal(props) {

    const [alarmname, setAlarmName] = useState("")
    const [comments, setComments] = useState("")
    const [EquipmentID, setEquipmentID] = useState(null)
    const [equipmentlist, setEquipmentList] = useState([])


    useEffect(() => {
        // API call to get access level and signals list
        $.ajax({
            url: '/api/DigitalTwinAPI/GetEquipmentSimple/',
            type: 'get',
            data: { SiteGUID: props.SiteGUID },
            contentType: "application/json; charset=utf-8",
            success: function (response) {
                response = JSON.parse(response)
                console.log(response)
                setEquipmentList(response)

            }

        });
    }, [props.SiteGUID]);


    function SendAlarm() {
        var newAlarm = {
            SiteGUID: props.SiteGUID,
            AlarmName: alarmname,
            Comments: comments,
            EquipmentID: EquipmentID
        }

        // API call to get access level and signals list
        $.ajax({
            url: '/api/AlarmsAPI/SendAlarm/',
            type: 'post',
            data: JSON.stringify(newAlarm),
            contentType: "application/json; charset=utf-8",
            success: function () {
                props.Update();
            }.bind(this)

        });
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            size="m"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Log an event
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="justify-content-center mb-3">
                    <InputGroup.Text>Event Name</InputGroup.Text>
                    <Form.Control
                        aria-describedby="basic-addon3"
                        onChange={e => setAlarmName(e.target.value)}>
                    </Form.Control>
                </InputGroup>

                <InputGroup className="justify-content-center mb-3">
                    <InputGroup.Text>Comments</InputGroup.Text>
                    <Form.Control
                        rows={3}
                        as='textarea'
                        type='textarea'

                        
                        //aria-describedby="basic-addon3"
                        onChange={e => setComments(e.target.value)}>
                    </Form.Control>
                </InputGroup>

                {props.SAPConnected ? <div class="form-group">
                    <label asp-for="Equipment" class="control-label">Equipment</label>
                    <select class="form-control" name="EquipmentID" value={this.state.selectedID} onChange={this.EquipmentChangeHandler}>
                        <option value="null">Select</option>
                        {equipmentlist.map((item) => <option key={"equipmentoption" + item.EquipmentID} value={item.EquipmentID}>{item.NiceName}</option>)}
                    </select></div> : ""}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => { SendAlarm(); props.onHide(); }} data-toggle="modal" data-dismiss="modal">Log Event</Button>
            </Modal.Footer>
        </Modal>
    );
}

function SendAlarm(props) {
    const [showSendAlarm, setShowSendAlarm] = useState(false);

    return (
        <>
            <Button variant="primary" onClick={() => setShowSendAlarm(true)}>
                Log Event
            </Button>
            <SendAlarmModal
                show={showSendAlarm}
                onHide={() => {
                    setShowSendAlarm(false);
                }}
                SiteGUID={props.SiteGUID}
                Update={props.Update}
                SAPConnected={false}
            />
        </>
    )
}

export function Alarms(props) {
    const { id } = useParams();
    const [activeAlarms, setActiveAlarms] = useState([]);
    const [previousAlarms, setPreviousAlarms] = useState([]);
    const [isActiveAlarms, setIsActiveAlarms] = useState(false);
    const [showversioncontrolmodal, setShowVersionControlModal] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [ispreviousAlarms, setIsPreviousAlarms] = useState(false);
    const [deleteAlarmID, setDeleteAlarmID] = useState();
    

    //Used for alarm delete modals
    const handleShowDeleteModal = (AlarmID) => {
        setDeleteAlarmID(AlarmID);
        setShowDeleteModal(true);
    };
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const deleteHelper = () => {
        DeleteAlarmWithID();
        handleCloseDeleteModal();
    }
    

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id), [id]);

    useEffect(() => {
        UpdateAllAlarms(id);

    }, [id]);

    function handleClose() {
        setShowVersionControlModal(false)
    }

    function CheckVersion() {
        if (AlarmsTabVersion < CurrentAlarmsTabVersion) {
            //Show modal and update cost breakdown tab version for user so they don't see the message on other devices
            var fd = {
                "AlarmsTabVersion": CurrentAlarmsTabVersion
            };
            $.ajax({
                url: '/api/VersionControlAPI/SetAlarmsTabVersion',
                data: fd,
                type: 'get',
                success: function (response) {
                    AlarmsTabVersion = CurrentAlarmsTabVersion;
                    Cookies.set('AlarmsTabVersion', CurrentAlarmsTabVersion, { expires: 7999 });

                }
            });
            setShowVersionControlModal(true)
        }
    }

    useEffect(() => {
        // Version control modal
        CheckVersion()
        //First run
        if (AlarmsTabVersion == undefined || AlarmsTabVersion < CurrentAlarmsTabVersion) {
            //Get version from server
            $.ajax({
                url: '/api/VersionControlAPI',
                type: 'get',
                success: function (response) {
                    console.log(response);

                    AlarmsTabVersion = response.alarmsTabVersion;
                    Cookies.set('HomeTabVersion', response.homeTabVersion, { expires: 7999 });
                    Cookies.set('AnalyticsTabVersion', response.analyticsTabVersion, { expires: 7999 });
                    Cookies.set('HealthSummaryTabVersion', response.healthSummaryTabVersion, { expires: 7999 });
                    Cookies.set('CostBreakdownTabVersion', response.costBreakdownTabVersion, { expires: 7999 });
                    Cookies.set('DeepDiveTabVersion', response.deepDiveTabVersion, { expires: 7999 });
                    Cookies.set('MaintenanceTabVersion', response.maintenanceTabVersion, { expires: 7999 });
                    Cookies.set('BillingTabVersion', response.billingTabVersion, { expires: 7999 });
                    Cookies.set('AlarmsTabVersion', response.alarmsTabVersion, { expires: 7999 });
                    Cookies.set('ControlTabVersion', response.controlTabVersion, { expires: 7999 });
                    Cookies.set('CalibrationsTabVersion', response.calibrationsTabVersion, { expires: 7999 });
                    Cookies.set('AirCleaningTabVersion', response.airCleaningTabVersion, { expires: 7999 });
                    Cookies.set('ReportsTabVersion', response.reportsTabVersion, { expires: 7999 });
                    Cookies.set('SiteDetailsTabVersion', response.siteDetailsTabVersion, { expires: 7999 });
                    Cookies.set('DownloadsTabVersion', response.downloadsTabVersion, { expires: 7999 });

                    CheckVersion();
                }
            });
        }
    }, []);

    const UpdateAllAlarms = (id) => {
        var d = new Date();
        var offset = (d.getTimezoneOffset() * -1) / 60;
        $.ajax({
            url: '/api/AlarmsAPI/GetPreviousAlarms',
            type: 'get',
            data: { SiteGUID: id, offset: offset },
            type: 'get',
            success: function (response) {
                response = JSON.parse(response);
                if (response.length > 0) {
                    setIsPreviousAlarms(true);
                } else {
                    setIsPreviousAlarms(false);
                }
                setPreviousAlarms([
                    ...response.map((item) =>
                        <tr key={item.AlarmID} >
                            <td>
                                {item.Name}
                            </td>
                            <td>
                                {item.Time}
                            </td>
                            <td>
                                {item.TimeResolved}
                            </td>
                            <Comment key={item.AlarmID} data={item} />
                            <td>
                                <a className="btn btn-outline-danger far fa-trash-alt fa-2x" onClick={() => DeleteAlarm(item)} ></a>
                            </td>
                        </tr>)
                ]);
            }
        })

        $.ajax({
            url: '/api/AlarmsAPI/GetActiveAlarms',
            type: 'get',
            data: { SiteGUID: id, offset: offset },
            type: 'get',
            success: function (response) {
                response = JSON.parse(response);
                if (response.length > 0) {
                    setIsActiveAlarms(true);
                } else {
                    setIsActiveAlarms(false);
                }
                setActiveAlarms([
                    ...response.map((item) => {
                        if (item.AlarmType == 1) {
                            return <tr key={item.AlarmID} >
                                <td>
                                    {item.Name}
                                </td>
                                <td>
                                    {item.Time}
                                </td>
                                <td>
                                    {item.Creator}
                                </td>
                                <Comment key={item.AlarmID} data={item} />
                                <td>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip>Deactivate</Tooltip>
                                        }>
                                        <a className="btn btn-outline-primary fas fa-ban fa-2x" onClick={() => DeactivateAlarm(item)}></a>
                                    </OverlayTrigger>
                                </td>
                                <td>
                                    <a className="btn btn-outline-danger far fa-trash-alt fa-2x" onClick={() => handleShowDeleteModal(item.AlarmID)} ></a>
                                </td>
                            </tr>
                        }
                        else if (item.AlarmType == 2) {
                            return <tr key={item.AlarmID} >
                                <td>
                                    {item.Name}
                                </td>
                                <td>
                                    {item.Time}
                                </td>
                                <td>
                                    {item.Creator}
                                </td>
                                <Comment key={item.AlarmID} data={item} />
                                { item.CanDeactivate ?
                                    <td>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip>Deactivate Failsafe & Non-Threshold Alarm</Tooltip>
                                            }>
                                            <a className="btn btn-outline-primary fas fa-ban fa-2x" onClick={() => DeactivateEdgeAlarm(item)}></a>
                                        </OverlayTrigger>
                                    </td> : 
                                    <td></td>
                                }
                                <td>
                                </td>
                            </tr>
                        }
                        else if (item.AlarmType == 3) {
                            return <tr key={item.AlarmID} >
                                <td>
                                    {item.Name}
                                </td>
                                <td>
                                    {item.Time}
                                </td>
                                <td>
                                    {item.Creator}
                                </td>
                                <Comment key={item.AlarmID} data={item} />
                                <td>
                                    <LogMaintenance MaintenanceType={item.MaintenanceConfig.MaintenanceTypeID} AssetID={item.MaintenanceConfig.AssetID} />
                                </td>
                                <td>
                                </td>
                            </tr>
                        }
                        else if (item.AlarmType == 4) {
                            return <tr key={item.AlarmID} >
                                <td>
                                    {item.Name}
                                </td>
                                <td>
                                    {item.Time}
                                </td>
                                <td>
                                    {item.Creator}
                                </td>
                                <Comment key={item.AlarmID} data={item} />
                                <td>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip>Request to Close</Tooltip>
                                        }>
                                        <a className="btn btn-outline-warning far fa-times-circle fa-4x" onClick={() => requestToClose(item.TicketID)}></a>
                                    </OverlayTrigger>

                                </td>
                                <td>
                                </td>
                            </tr>
                        }
                    })
                ]);
            }
        })


    }

    const requestToClose = (item) => {
        $.ajax({
            url: "/api/AlarmsAPI/RequestToCloseTicket",
            type: "POST",
            data: JSON.stringify({ TicketID: item }),
            contentType: 'application/json',
            success: function (data) {
                setShowModal(true);
            }.bind(this)
        })
    }

    const DeactivateAlarm = (item) => {
        $.ajax({
            url: "/api/AlarmsAPI/DeactivateAlarm",
            type: "get",
            data: { id: item.AlarmID },
            success: function (data) { UpdateAllAlarms(id); }.bind(this)
        })
    }

    const DeactivateEdgeAlarm = (item) => {
        $.ajax({
            url: "/api/AlarmsAPI/DeactivateEdgeAlarm",
            type: "get",
            data: { id: item.AlarmID },
            success: function (data) { UpdateAllAlarms(id); }.bind(this)
        })
    }

    const DeleteAlarm = (item) => {
        $.ajax({
            url: "/api/AlarmsAPI/DeleteAlarm",
            type: "POST",
            data: JSON.stringify({ AlarmID: item.AlarmID }),
            contentType: 'application/json',
            success: function (data) {
                UpdateAllAlarms(id);
            }.bind(this)
        })
    }

    //Delete alarm directly using alarm id.
    const DeleteAlarmWithID = () => {
        $.ajax({
            url: "/api/AlarmsAPI/DeleteAlarm",
            type: "POST",
            data: JSON.stringify({ AlarmID: deleteAlarmID }),
            contentType: 'application/json',
            success: function (data) {
                UpdateAllAlarms(id);
            }.bind(this)
        })
    }

    return (
        props.auth !== undefined ?
            props.auth > 0 ?
                <div>
                    <HelpButton showHelpModal={setShowVersionControlModal} />
                    <h1>Alarms</h1>
                    <SendAlarm SiteGUID={id} Update={() => UpdateAllAlarms(id)} /> | <Link to={"/AlarmSetup/" + id}><Button onClick={() => { }}>Alarm Config</Button></Link>
                    <br /><br />
                    {isActiveAlarms ?
                        <Table>
                            <thead>
                                <tr>
                                    <th className="col-2">
                                        Active Alarms
                                    </th>
                                    <th className="col-3">
                                        Time Triggered
                                    </th>
                                    <th className="col-3">
                                        Created By
                                    </th>
                                    <th className="col-4">
                                        Comments
                                    </th>
                                    <th style={{ width: '50px' }}></th>
                                    <th style={{ width: '50px' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {activeAlarms}
                            </tbody>
                        </Table> : ""}
                    <Modal
                        show={showModal}
                        onHide={() => {
                            setShowModal(false);
                        }}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        backdrop="static"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Confirmation
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            A request to close this alarm has been sent to Streamwise D. I.
                        </Modal.Body>
                    </Modal>

                    
                    <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Deleting Alarm!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Please note: Once the alarm is deleted it permanently disappears.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseDeleteModal}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={deleteHelper}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    

                    {ispreviousAlarms ?
                        <div><h2>Previous Alarms</h2>
                            <Table>
                                <thead>
                                    <tr>
                                        <th className="col-2">
                                            Alarm Name
                                        </th>
                                        <th className="col-3">
                                            Time Triggered
                                        </th>
                                        <th className="col-3">
                                            Time Resolved
                                        </th>
                                        <th className="col-4">
                                            Comments
                                        </th>
                                        <th style={{ width: '50px' }}></th>

                                        <th style={{ width: '50px' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {previousAlarms}
                                </tbody>
                            </Table></div> : ""}
                    <Modal show={showversioncontrolmodal} onHide={handleClose} size="lg">
                        <Modal.Header closeButton>
                            <h5 class="modal-title" id="exampleModalLongTitle">What's New?</h5>
                        </Modal.Header>
                        <Modal.Body className="text-center">
                            <YouTubeVideo VideoTag="Alarms" />
                            <p className="text-start">You can configure and view past alarms here.</p>
                            <p><img className="w-100" src="/images/HowTos/alarms2.png" alt="Document Upload" /></p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                :
                <NoAccessPage />
            : <> </>);
};
