import StreamwiseScatterChart from "../waterwerx-scatter-chart";
import $ from 'jquery';
import moment from 'moment';

const InsightScatterChart = (function () {
    const createChartConfig = function (data) {

        const chartID = data.ChartID;

        var chartDatasets = [];
        const bgcolorsarray = ['rgba(20, 168, 222, 0.3)', 'rgba(160,82,45, 0.3)', 'rgba(148, 75, 163, 0.3)', 'rgba(6, 148, 22, 0.3)', 'rgba(0, 44, 79, 0.3)', 'rgba(244, 121, 32, 0.3)', 'rgba(16, 24, 32, 0.3)'];
        const colorsarray = ['rgba(20, 168, 222, 0.8)', 'rgba(160,82,45, 0.8)', 'rgba(148, 75, 163, 0.8)', 'rgba(6, 148, 22, 0.8)', 'rgba(0, 44, 79, 0.8)', 'rgba(244, 121, 32, 0.8)', 'rgba(16, 24, 32, 0.8)'];
        //6, 148, 22

        var plotData = [];
        var i;
        for (i = 0; i < data.scatterPoints.length; i++) {
            plotData.push({ x: data.scatterPoints[i][0], y: data.scatterPoints[i][1] });
        }

        const signalNiceName = data.XAxisTitle + ' - ' + data.YAxisTitle;

        var PointColors = [];
        var k;
        for (k = 0; k < plotData.length; k++) {
            //if (upperLimit != null && plotData[k] > upperLimit) {
            //    PointColors.push('rgba(217, 14, 0, 1)');
            //}
            //else if (lowerLimit != null && plotData[k] < lowerLimit && plotData[k] > 0) {
            //    PointColors.push('rgba(217, 14, 0, 1)');
            //}
            //else {
            PointColors.push(colorsarray[data.ColorID]);
            //}
        }

        chartDatasets.push({
            label: signalNiceName,
            data: plotData,
            pointRadius: 2,
            pointHoverRadius: 2,
            fill: false,
            borderWidth: 1,
            backgroundColor: bgcolorsarray[data.ColorID],
            borderColor: colorsarray[data.ColorID],
            pointBorderColor: PointColors
        });

        const chartConfig = {
            chartId: chartID,
            //chartLabels: labels,
            chartDataSets: chartDatasets,
            chartYAxisIdOverride: data.YAxisTitle,
            chartXAxisIdOverride: data.XAxisTitle
        };
        return chartConfig;
    };


    const initChart = function (ChartID, start, end, onLoad) {

        $.ajax({
            url: '/../api/InsightScatterChartAPI/' + ChartID + '&' + encodeURIComponent(start) + '&' + encodeURIComponent(end),
            type: 'get',
            success: function (response) {
                //console.log(response);
                response = JSON.parse(response);


                const noData = !response || response.scatterPoints.length === 0;
                const chartConfig = createChartConfig(response);

                StreamwiseScatterChart.create(chartConfig, noData);
                onLoad();
            }
        });

    };

    return {
        init: initChart
    };
}());

export default InsightScatterChart;