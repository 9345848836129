import { useState, useCallback, useRef, ReactElement } from "react";
import { addEdge, applyNodeChanges, isNode } from "react-flow-renderer";
import {
    StreamwiseNode, StreamwiseEdge,
    ControlChainData, PIDControlParameters, LEQControlParameters, BlendedControlParameters, PumpControlParameters,
    StreamwiseControlLinkData, StreamwiseNodeData, Signal
} from "./types";
import useUndoable from "use-undoable";
import { v4 as uuid } from 'uuid';

import { SW_EDGE_LIST } from "./Edges";
import { SW_NODE_LIST } from "./Nodes";
import React from "react";
import Control from "react-select/dist/declarations/src/components/Control";

const ADD_Y = 90;
const ADD_X = 200;

const OUTPUT_HANDLE = "OUTPUT";
const INPUT_HANDLE = "INPUT";

const enum NODE_TYPE{
    SOURCE = "source",
    PID = "pid",
    LEQ = "linearEquation",
    PUMP = "pump",
    BLENDED = "blended"
}

const enum CONTROL_TYPE{
    SOURCE = 0,
    PID = 1,
    LEQ = 2,
    PUMP = 3,
    BLENDED = -1
}


function useConfigurator() {
    const [elements, setElements, { undo, redo, reset, canUndo, canRedo }] =
        useUndoable({
            nodes: [] as StreamwiseNode[],
            edges: [] as StreamwiseEdge[],
            // nodes: initialNodes,
            // edges: initialEdges,
        });

    const elementsRef = useRef({
        nodes: [] as StreamwiseNode[],
        edges: [] as StreamwiseEdge[],
    })

    const [application, setApplication] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [currentModal, setCurrentModal] = useState<string>('');
    const [nodeID, setNodeID] = useState<string>('');
    const [sourceID, setSourceID] = useState<string>('');
    const [targetID, setTargetID] = useState<string>('');

    const getData = async (id: string) => {
        let node = elementsRef.current.nodes.find((n) => n.id === id);
        if (node){
            let data = node.data;
            if (node?.data.controlType === CONTROL_TYPE.BLENDED){
                for (var i in node.data.blended){
                    const blendedbranch = node.data.blended[parseInt(i)].InputSignalID;
                    let source = elementsRef.current.nodes.find((n) => n.data.controlLinkData.signalID === blendedbranch);
                    if (source && source.data.name && source.data.name.length > 0){
                        node.data.blended[parseInt(i)].NiceName = source.data.name;
                    }
                    else {
                        node.data.blended[parseInt(i)].NiceName = "Signal " + (parseInt(i)+1);
                    }
                }
            }
            return data;
        }
        else
            return null;
    }

    const saveSettings = (id: string, newData: StreamwiseNodeData) => {
        let node = elementsRef.current.nodes.find((n) => n.id === id);
        if (node !== undefined){
            node.data = newData;

            // update input signal id when source signal is updated
            if (node.data.controlType === CONTROL_TYPE.SOURCE){
                const targetedge = elementsRef.current.edges.find(e => e.source === id);
                let targetnode = elementsRef.current.nodes.find((n) => n.id === targetedge?.target);
                if (targetnode && targetnode.data.controlType !== CONTROL_TYPE.BLENDED){
                    targetnode.data.controlLinkData.inputBlock = node.data.controlLinkData.signalID;
                }
                // update the blended branch leading to the updated source
                else if (targetnode && targetnode.data.controlType === CONTROL_TYPE.BLENDED){
                    if (targetedge && targetedge.targetHandle && targetnode.data.blended){
                        var index = parseInt(targetedge?.targetHandle?.slice(-1));
                        targetnode.data.blended[index-1] = {...targetnode.data.blended[index-1], InputSignalID: node.data.controlLinkData.signalID};
                    }
                }
            }
            // update the branches for blended control (add/delete)
            else if (node.data.controlType === CONTROL_TYPE.BLENDED && node.data.blended){
                let i,j;
                let edgesToAdd = [] as StreamwiseEdge[];
                let nodesToAdd = [] as StreamwiseNode[];
                let oldEdges = elementsRef.current.edges.filter(e => e.target === id);
                for (i in node.data.blended) {
                    let handle:Number = parseInt(i) + 1;
                    // adding sources for newly added branches
                    if (!oldEdges.find(e => e.targetHandle === INPUT_HANDLE+handle)){
                        let source = createControlBlock(NODE_TYPE.SOURCE);
                        source.id = createNodeId(NODE_TYPE.SOURCE);
                        let linkData = { signalID: "", controlType: CONTROL_TYPE.SOURCE } as StreamwiseControlLinkData;
                        source.data.controlLinkData = linkData;
                        source.position.x = 0;
                        source.position.y = ADD_Y * (i);
                        nodesToAdd.push(source);

                        let connectionToSource = createEdge();
                        connectionToSource.target = node.id;
                        connectionToSource.targetHandle = INPUT_HANDLE+handle;
                        connectionToSource.source = source.id;
                        connectionToSource.sourceHandle = OUTPUT_HANDLE;
                        connectionToSource.id = createEdgeId(connectionToSource);
                        edgesToAdd.push(connectionToSource);
                    }
                    else {
                        oldEdges = oldEdges.filter(e => e.targetHandle !== INPUT_HANDLE+handle);
                    }
                }
                // removing branches that have been deleted
                for (j in oldEdges){
                    deleteBlendedBranch(oldEdges[j].source);
                    removeEdge(oldEdges[j].id);
                }
                elementsRef.current.nodes = [...elementsRef.current.nodes, ...nodesToAdd];
                elementsRef.current.edges = [...elementsRef.current.edges, ...edgesToAdd];
            }
        }
        triggerUpdateAllElements2();
    }

    const loadControlChain = (configData: StreamwiseNodeData[], signals: Signal[]) => {
        let i, j: any;
        let nodes = [] as StreamwiseNode[];
        let edges = [] as StreamwiseEdge[];

        for (j in configData) {
            // checks if the input signal to control link is also the input signal for the entire chain
            const link = configData.find((obj) => {
                return obj.controlLinkData.signalID === configData[j].controlLinkData?.inputBlock;
            });
            let type: string;
            type = '';
            
            switch (configData[j].controlType) {
                case CONTROL_TYPE.PID: type = NODE_TYPE.PID; break;
                case CONTROL_TYPE.LEQ: type = NODE_TYPE.LEQ; break;
                case CONTROL_TYPE.PUMP: type = NODE_TYPE.PUMP; break;
                case CONTROL_TYPE.BLENDED: type = NODE_TYPE.BLENDED; break;
            }
            
            let newLink = createControlBlock(type);
            newLink.id = createNodeId(type);
            newLink.data.controlLinkData = configData[j].controlLinkData;
            switch (configData[j].controlType) {
                case CONTROL_TYPE.PID: newLink.data.pid = configData[j].pid; break;
                case CONTROL_TYPE.LEQ: newLink.data.leq = configData[j].leq; break;
                case CONTROL_TYPE.PUMP: newLink.data.pump = configData[j].pump; break;
                case CONTROL_TYPE.BLENDED: newLink.data.blended = configData[j].blended; break; 
            }

            // adding sources for blended control
            for (i in newLink.data.blended){
                let inputSignal;
                if (newLink.data.blended !== undefined && newLink.data.blended[i] !== undefined){
                    inputSignal = newLink.data.blended[i].InputSignalID;
                
                    const blendedlink = configData.find((obj) => {
                        return obj.controlLinkData.signalID === inputSignal;
                    });
                    if (blendedlink === undefined) {
                        let source = createControlBlock(NODE_TYPE.SOURCE);
                        source.id = createNodeId(NODE_TYPE.SOURCE);
                        var signal = signals.filter(s => s.id === inputSignal);
                        const niceName = signal[0] !== null ? "No Selection" : signal[0].nicename;
                        let linkData = { signalID: inputSignal, controlType: CONTROL_TYPE.SOURCE, nicename: niceName } as StreamwiseControlLinkData;
                        source.data.controlLinkData = linkData;
                        source.position.x = 0;
                        source.position.y = ADD_Y * (i);
                        nodes.push(source);
                    }
                }
            }

            // adding source node
            if (link === undefined && configData[j].controlType !== CONTROL_TYPE.BLENDED) {
                let source = createControlBlock(NODE_TYPE.SOURCE);
                source.id = createNodeId(NODE_TYPE.SOURCE);
                var signal = signals.filter(s => s.id === configData[j].controlLinkData?.inputBlock);
                console.log(signal);
                let linkData = { signalID: configData[j].controlLinkData?.inputBlock, controlType: CONTROL_TYPE.SOURCE, nicename: signal[0]?.nicename } as StreamwiseControlLinkData;
                source.data.controlLinkData = linkData;
                source.position.x = 0;
                source.position.y = 0;
                nodes.push(source);
                // newLink.parentNode = source.id;

                let connectionToSource = createEdge();
                connectionToSource.target = newLink.id;
                connectionToSource.targetHandle = INPUT_HANDLE;
                connectionToSource.source = source.id;
                connectionToSource.sourceHandle = OUTPUT_HANDLE;
                connectionToSource.id = createEdgeId(connectionToSource);
                edges.push(connectionToSource);
            }
            nodes.push(newLink);
        }


        // creates edges
        nodes.forEach((node: StreamwiseNode) => {
            if (node.data.controlLinkData?.inputBlock !== undefined && node.data.controlLinkData.inputBlock.length > 0) {
                // checks if link has an input block
                const link = nodes.find((obj) => {
                    return obj.data.controlLinkData?.signalID === node.data.controlLinkData?.inputBlock;
                });
                if (link) {
                    const edge = edges.find(e => e.source === link.id && e.target === node.id);
                    if (!edge) {
                        let connectionToSource = createEdge();
                        connectionToSource.target = node.id;
                        connectionToSource.targetHandle = INPUT_HANDLE;
                        connectionToSource.source = link.id;
                        connectionToSource.sourceHandle = OUTPUT_HANDLE;
                        connectionToSource.id = createEdgeId(connectionToSource);
                        edges.push(connectionToSource);
                        // node.parentNode = link.id;
                    }
                }
            }
            // add edges for every edge in blended control
            if (node.data.controlType === CONTROL_TYPE.BLENDED){
                for (i in node.data.blended){
                    let inputSignal;
                    if (node.data.blended !== undefined && node.data.blended[i] !== undefined){
                        inputSignal = node.data.blended[i].InputSignalID;
                        let link = nodes.find((obj) => {
                            return obj.data.controlLinkData?.signalID === inputSignal;
                        });
                        
                        if (link) {
                            let handleNumber: Number = parseInt(i) + 1;
                            let connectionToSource = createEdge();
                            connectionToSource.target = node.id;
                            connectionToSource.targetHandle = INPUT_HANDLE+handleNumber;
                            connectionToSource.source = link.id;
                            connectionToSource.sourceHandle = OUTPUT_HANDLE;
                            connectionToSource.id = createEdgeId(connectionToSource);
                            edges.push(connectionToSource);

                        }
                        //positioning blended branches on y-axis
                        while (link){
                            const newedge = edges.find(e => e.target === link?.id);
                            if (link.data.controlType === CONTROL_TYPE.SOURCE){
                                link.position.y = ADD_Y * i;
                            }
                            else{
                                link.position.y = (ADD_Y * i) - 10;
                            }
                            if (newedge){
                                link = nodes.find(n => n.id === newedge.source);
                            }
                            else{
                                break;
                            }
                        }
                    }
                }
                
            }
        });

        elementsRef.current.edges = edges;
        elementsRef.current.nodes = nodes;

        // Inject in onDelete/onEdit for nodes
        elementsRef.current.nodes.forEach(n => {
            n.data.onDelete = deleteControlBlock;
            n.data.onEdit = editControlBlock;
        })

        elementsRef.current.edges.forEach(n => {
            n.data.onNewLink = addLink;
        })

        // positioning links in chain
        const sourceNodes = nodes.filter((node) => { return node.type === NODE_TYPE.SOURCE; });
        sourceNodes.forEach((sourceNode) => {
            const edge = elementsRef.current.edges.find(e => e.source === sourceNode.id);
            if (edge)
                cascadePositions(edge.target);
        });

        triggerUpdateAllElements();
    }


    const loadConfig = (configData: ControlChainData) => {
        elementsRef.current.edges = configData.edges;
        elementsRef.current.nodes = configData.nodes;

        // Inject in onDelete for nodes
        elementsRef.current.nodes.forEach(n => {
            n.data.onDelete = deleteControlBlock;
            n.data.onEdit = editControlBlock;
            if (n.data.controlType !== 0){
                n.data.controlLinkData.signalID = uuid();
            }
        })

        elementsRef.current.edges.forEach(n => {
            n.data.onNewLink = addLink;
        })

        triggerUpdateAllElements2();
    }

    const getConfigData = (): string => {
        return JSON.stringify(elements);
    }

    const triggerUpdate = useCallback(
        (t, v) => {
            // To prevent a mismatch of state updates,
            // we'll use the value passed into this
            // function instead of the state directly.
            setElements((e) => ({
                nodes: t === "nodes" ? v : e.nodes,
                edges: t === "edges" ? v : e.edges,
            }));
            if (t === "nodes") {
                elementsRef.current.nodes = v;
            } else if (t === 'edges') {
                elementsRef.current.edges = v;
            }
        },
        [setElements]
    );

    // Clears elements before rendering
    const triggerUpdateAllElements = useCallback(
        () => {
            setElements({
                nodes: [],
                edges: [],
            });
            setTimeout(() => {
                setElements({
                    nodes: elementsRef.current.nodes,
                    edges: elementsRef.current.edges,
                });
            }, 0);
        },
        [setElements]
    );

    // Does not clear elements before rendering
    const triggerUpdateAllElements2 = useCallback(
        () => {
            setElements({
                nodes: elementsRef.current.nodes,
                edges: elementsRef.current.edges,
            });
        },
        [setElements]
    );

    const onNodesChange = useCallback(
        (changes) => {
            triggerUpdate("nodes", applyNodeChanges(changes, elements.nodes));
        },
        [triggerUpdate, elements.nodes]
    );

    const onEdgesChange = useCallback(
        (connection) => {
            triggerUpdate("edges", addEdge(connection, elements.edges));
        },
        [triggerUpdate, elements.edges]
    );

    const addLink = (sourceId: string, targetId: string) => {
        //
        setTimeout(() => {
            setSourceID(sourceId);
            setTargetID(targetId);
        }, 0)

        setCurrentModal('addLink-modal');
    }

    const removeNode = (nodeId: string) => {
        let j = 0;
        elementsRef.current.nodes.forEach((e, i) => {
            if (e.id !== nodeId) {
                if (i !== j) elementsRef.current.nodes[j] = e;
                j++
            }
        })
        elementsRef.current.nodes.length = j;
    }

    const removeEdge = (edgeId: string) => {
        let j = 0;
        elementsRef.current.edges.forEach((e, i) => {
            if (e.id !== edgeId) {
                if (i !== j) elementsRef.current.edges[j] = e;
                j++
            }
        })
        elementsRef.current.edges.length = j;
    }

    const createEdge = () => {
        // Init new edge
        let new_edge = JSON.parse(
            JSON.stringify(SW_EDGE_LIST.find((e) => e.type === "connector"))
        ) as StreamwiseEdge;
        new_edge.data.onNewLink = addLink;
        return new_edge;
    }

    const createControlBlock = (controlType: string) => {
        let new_node = JSON.parse(
            JSON.stringify(SW_NODE_LIST.find((n) => n.type === controlType))
        ) as StreamwiseNode;

        new_node.data.onDelete = deleteControlBlock;
        new_node.data.onEdit = editControlBlock;

        // Initialise default values for fields
        new_node.data.controlLinkData.signalID = uuid();
        new_node.data.controlLinkData.updateFrequency = 3;
        switch (controlType) {
            case NODE_TYPE.SOURCE:
                new_node.data.controlType = CONTROL_TYPE.SOURCE;
                break;
            case NODE_TYPE.PUMP:
                new_node.data.controlType = CONTROL_TYPE.PUMP;
                break;
            case NODE_TYPE.PID:
                new_node.data.controlType = CONTROL_TYPE.PID;
                new_node.data.pid = {
                    P: 0,
                    I: 0,
                    D: 0,
                    SetPoint: 0,
                    Minimum: 0,
                    Maximum: 100
                } as PIDControlParameters
                break;
            case NODE_TYPE.LEQ:
                new_node.data.controlType = CONTROL_TYPE.LEQ;
                new_node.data.leq = {
                    Gradient: 0,
                    Intercept: 0,
                    Minimum: 0,
                    Maximum: 1
                } as LEQControlParameters
                break;
            case NODE_TYPE.BLENDED:
                new_node.data.controlType = CONTROL_TYPE.BLENDED;
                break;
            default:
                new_node.data.controlType = 0;
                break;
        }
        return new_node;
    }

    const editControlBlock = (id: string) => {
        setNodeID(id);
        let block = elementsRef.current.nodes.find(n => n.id === id);
        
        if (block) {
            switch (block.type) {
                case NODE_TYPE.PID: {
                    setCurrentModal('pid-modal'); break;
                }
                case NODE_TYPE.LEQ: {
                    setCurrentModal('leq-modal'); break;
                }
                case NODE_TYPE.PUMP: {
                    setCurrentModal('pump-modal'); break;
                }
                case NODE_TYPE.BLENDED: {
                    setCurrentModal('blended-modal'); break;
                }
                case NODE_TYPE.SOURCE: {
                    setCurrentModal('source-modal'); break;
                }
            }

        }
    }

    const deleteControlBlock = (id: string) => {
        let i:any;
        let block = elementsRef.current.nodes.find(n => n.id === id);
        if (block) {
            let edgesToAdd = [] as StreamwiseEdge[];

            // delete branches in blended control block
            if (block.data.controlType === CONTROL_TYPE.BLENDED){
                for (i in block.data.blended){
                    // skip the first branch
                    if (parseInt(i) > 0){
                        let handle:Number = parseInt(i) + 1;
                        const branch = elementsRef.current.edges.find(e => e.target === id && e.targetHandle === INPUT_HANDLE+handle);
                        if (branch){
                            deleteBlendedBranch(branch.source);
                            removeEdge(branch.id);
                        }
                    }
                }
            }

            let connectorToSource = elementsRef.current.edges.find(e => e.target === id);
            let connectorToTarget = elementsRef.current.edges.find(e => e.source === id);

            const targetID = connectorToTarget?.target;

            // create new edge and updates inputsignalid for target
            if (connectorToSource !== undefined && connectorToTarget !== undefined) {
                let newConnector = createEdge();
                newConnector.target = connectorToTarget.target;
                newConnector.targetHandle = connectorToTarget.targetHandle;
                newConnector.source = connectorToSource.source;
                newConnector.sourceHandle = connectorToSource.sourceHandle;
                newConnector.id = createEdgeId(newConnector);
                edgesToAdd.push(newConnector);

                let sourceNode = elementsRef.current.nodes.find((n) => n.id === connectorToSource?.source);
                let targetNode = elementsRef.current.nodes.find((n) => n.id === connectorToTarget?.target);

                if (targetNode) {
                    targetNode.data.controlLinkData.inputBlock = sourceNode?.data.controlLinkData.signalID;
                }

                removeEdge(connectorToSource.id);
                removeEdge(connectorToTarget.id);
            }
            // Remove sensor node
            removeNode(id);

            elementsRef.current.edges = [...elementsRef.current.edges, ...edgesToAdd];

            if (targetID) cascadePositions(targetID);

            triggerUpdateAllElements();
        }
    }

    const deleteBlendedBranch = (id: string) => {
        let block = elementsRef.current.nodes.find(n => n.id === id);
        if (block) {
            let connectorToSource = elementsRef.current.edges.find(e => e.target === id);

            if (connectorToSource !== undefined) {
                deleteBlendedBranch(connectorToSource.source);
                removeEdge(connectorToSource.id);
            }
            // Remove sensor node
            removeNode(id);

            triggerUpdateAllElements();
        }
    }

    const deleteLastBlendedBranch = (id: string) => {
        let block = elementsRef.current.nodes.find(n => n.id === id);
        if (block && block.data.controlType === CONTROL_TYPE.BLENDED && block.data.blended && block.data.blended.length > 2){
            let handle = INPUT_HANDLE+(block.data.blended.length);
            let branch = elementsRef.current.edges.find(e => e.target === id && e.targetHandle === handle);

            if (branch){
                deleteBlendedBranch(branch.source);
                removeEdge(branch.id);
            }
            
            triggerUpdateAllElements();
        }
    }


    const addControlLink = (sourceId: string, targetId: string, controlType: string, controlChainID: string) => {
        let sourceNode = elementsRef.current.nodes.find((n) => n.id === sourceId);
        let targetNode = elementsRef.current.nodes.find((n) => n.id === targetId);
        if (sourceNode !== undefined && targetNode !== undefined) {
            let nodesToAdd = [] as StreamwiseNode[];
            let edgesToAdd = [] as StreamwiseEdge[];

            let edge = elementsRef.current.edges.find(e => e.source === sourceId && e.target === targetId);

            // Node
            let newLink = createControlBlock(controlType);
            newLink.id = createNodeId(controlType);
            newLink.position.x = sourceNode.position.x + ADD_X;
            newLink.position.y = sourceNode.data.controlType === CONTROL_TYPE.SOURCE ? sourceNode.position.y - 10 : sourceNode.position.y;
            controlType === NODE_TYPE.BLENDED ? newLink.data.blended = [
                { InputSignalID: sourceNode.data.controlLinkData.signalID, Gradient: 0 },
                { InputSignalID: "", Gradient: 0 }
            ] : newLink.data.controlLinkData.inputBlock = sourceNode.data.controlLinkData.signalID;
            nodesToAdd.push(newLink);

            if (targetNode && targetNode.data.controlType !== CONTROL_TYPE.BLENDED){
                targetNode.data.controlLinkData.inputBlock = newLink.data.controlLinkData.signalID;
            }
            // update the blended branch leading to the updated source
            else if (targetNode && targetNode.data.controlType === CONTROL_TYPE.BLENDED){
                if (edge && edge.targetHandle && targetNode.data.blended){
                    var index = parseInt(edge?.targetHandle?.slice(-1));
                    targetNode.data.blended[index-1] = {...targetNode.data.blended[index-1], InputSignalID: newLink.data.controlLinkData.signalID};
                }
            }

            // Want to update the new node and the target node to link up the input signal IDs
            // Create link on edge immediately
            fetch("/api/ControlChainsAPI/SaveControlChain/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ ChainID: controlChainID, Links: [newLink.data, targetNode.data] }),
            });

            // Edge
            let connectionToSource = createEdge();
            connectionToSource.target = newLink.id;
            controlType === NODE_TYPE.BLENDED ? connectionToSource.targetHandle = "INPUT1" : connectionToSource.targetHandle = INPUT_HANDLE;
            connectionToSource.source = sourceId;
            connectionToSource.sourceHandle = edge?.sourceHandle;
            connectionToSource.id = createEdgeId(connectionToSource);
            edgesToAdd.push(connectionToSource);

            let connectionToTarget = createEdge();
            connectionToTarget.target = targetId;
            connectionToTarget.targetHandle = edge?.targetHandle;
            connectionToTarget.source = newLink.id;
            connectionToTarget.sourceHandle = OUTPUT_HANDLE;
            connectionToTarget.id = createEdgeId(connectionToTarget);
            edgesToAdd.push(connectionToTarget);

            // add second source to link to blended control
            if (controlType === NODE_TYPE.BLENDED){
                let newSource = createControlBlock(NODE_TYPE.SOURCE);
                newSource.id = createNodeId(NODE_TYPE.SOURCE);
                let linkData = { signalID: "", controlType: CONTROL_TYPE.SOURCE } as StreamwiseControlLinkData;
                newSource.data.controlLinkData = linkData;
                newSource.position.x = 0;
                newSource.position.y = ADD_Y;
                nodesToAdd.push(newSource);

                let connectionToNewSource = createEdge();
                connectionToNewSource.target = newLink.id;
                connectionToNewSource.targetHandle = "INPUT2";
                connectionToNewSource.source = newSource.id;
                connectionToNewSource.sourceHandle = OUTPUT_HANDLE;
                connectionToNewSource.id = createEdgeId(connectionToNewSource);
                edgesToAdd.push(connectionToNewSource);
            }
            
            if (edge !== undefined) {
                removeEdge(edge.id);
            }

            elementsRef.current.nodes = [...elementsRef.current.nodes, ...nodesToAdd];
            elementsRef.current.edges = [...elementsRef.current.edges, ...edgesToAdd];

            cascadePositions(targetId);

            triggerUpdateAllElements();
        }
    };

    const cascadePositions = (link: string) => {
        let node = elementsRef.current.nodes.find((n) => n.id === link);
        let edge = elementsRef.current.edges.find(e => e.source === link);

        // finding the position of the closest block
        let sources = elementsRef.current.edges.filter(e => e.target === link);
        let largestX = -Number.MAX_SAFE_INTEGER;
        sources.forEach((value) => {
            let sourceNode = elementsRef.current.nodes.find((n) => n.id === value.source);
            if (sourceNode && sourceNode?.position.x > largestX){
                largestX = sourceNode.position.x;
            }
        });

        if (node !== undefined) {
            node.position.x = largestX + ADD_X;
            if (edge !== undefined) {
                cascadePositions(edge.target);
            }
        }
    };

    const createEdgeId = (edge: StreamwiseEdge) => {
        return (
            edge.source +
            "-" +
            edge.target +
            "-" +
            uuid()
        );
    };

    const createNodeId = (nodeType: string | undefined) => {
        return (nodeType || 'node') + "-" + uuid();
    };

    return {
        loadControlChain,
        loadConfig,
        getConfigData,
        elements,
        application,
        setApplication,
        name,
        setName,
        onNodesChange,
        onEdgesChange,
        undo,
        canUndo,
        redo,
        canRedo,
        nodeID,
        currentModal,
        setCurrentModal,
        addControlLink,
        deleteLastBlendedBranch,
        triggerUpdateAllElements,
        getData,
        saveSettings,
        sourceID,
        targetID
    };
}

export { useConfigurator };

