import React, { useState, useEffect } from 'react';
import { Button, Modal, Row, Col, Container, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css'
import { SignalCard } from '../Calibrations';
import * as signalR from '@microsoft/signalr'
import $ from 'jquery';

function LogMaintenanceModal(props) {
    const [maintenanceDate, setMaintenanceDate] = useState(moment().utc().startOf('day'));
    const [routine, setRoutine] = useState(true);

    const handleConfirm = (logMaintenancePayload) => {
        var url = '/api/MaintenanceAPI/LogMaintenance/';
        // console.log({logMaintenancePayload})
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(logMaintenancePayload)
        }).then(response => response.status)
            .then(status => {
                props.onHide()
            });
    }

    const handleDatetimerangeUpdate = (start, end, label) => {
        setMaintenanceDate(start.utc().startOf('day'));
    }

    const payload = {
        maintenanceDate: maintenanceDate,
        type: props.MaintenanceType,
        sensorID: props.AssetID,
        routine: routine,
    }

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm maintenance details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <InputGroup className="mb-3">
                <InputGroup.Text>Select maintenance date: </InputGroup.Text>
                <DateRangePicker
                    onCallback={handleDatetimerangeUpdate}
                    initialSettings={{
                        alwaysShowCalendars: true,
                        autoApply: true,
                        singleDatePicker: true,
                        alwaysShowCalendars: true,
                        maxDate: maintenanceDate,
                        startDate: maintenanceDate,
                        endDate: maintenanceDate,
                        opens: "center",
                        locale: {
                            "format": "DD/MM/YYYY",
                        }
                    }}
                >
                    <input type="text" className="form-control" name="daterange" />
                </DateRangePicker>
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Text>Routine replacement: </InputGroup.Text>
                <InputGroup.Checkbox checked={routine} onClick={() => setRoutine(!routine)} aria-label="Is this a outine replacement?" />
            </InputGroup>
        </Modal.Body>
        <Modal.Footer>
        <Container>
            <Row>
                <Col>
                    <Button variant="primary" onClick={props.onHide}>Cancel</Button>
                </Col>
                <Col>
                    <Button className="float-right" variant="success" onClick={() => handleConfirm(payload)}>Confirm</Button>
                </Col>
            </Row>
        </Container>
        </Modal.Footer>
      </Modal>
    );
}

function LogCalibrationModal(props) {
    const [signal, setSignal] = useState({});
    const [latestvalues, setLatestValues] = useState([]);
    const [deviceID, setDeviceID] = useState('');
    const [loadingsignals, setLoadingSignals] = useState(true)
    const [loadingSignalFailed ,setLoadingSignalFailed] = useState(false)

    useEffect(() => {
        // Retrieve signal object (includes deviceID)
        var url = '/api/MaintenanceAPI/GetDeviceId/';
        var assetPayload = {
            MaintenanceType: props.MaintenanceType,
            AssetID: props.AssetID
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(assetPayload)
        }).then(response => {
            if (!response.ok){
                throw new Error("Could not retrieve this signal");
            } else {
                return response.json();
            }
        }).then((data) => {
            console.log({signal: data});
            setDeviceID(data.SignalDevice.DeviceID);
            $.ajax({
                url: '/api/CalibrationsAPI/GetSignals/',
                type: 'get',
                data: { DeviceID: data.SignalDevice.DeviceID },
                success: function (response) {
                    try {
                        if (response.length > 0){
                            var thisSignal = response.find(signal => signal.signalID == data.SignalID)
                            if (thisSignal == undefined){
                                setLoadingSignalFailed(true)
                            } else {
                                setSignal(thisSignal)
                            }
                            setLoadingSignals(false)
                        } else {
                            setLoadingSignalFailed(true)
                        }
                    }
                    catch (exception) {
                    }
                },
            })
        }).catch(error =>
            console.warn(error.message)
        );
    }, [props.MaintenanceType, props.AssetID])

    useEffect(() => {
        if (deviceID !== ''){
            // Establish Signal R connection for data
            if (props.show) {
                console.log("Starting connection")
                console.log(connection)
                if (connection.connectionState == "Connected") {
                    connection.stop().then(function () {
                        connection.start().then(function () {
                            listenertag = "DoSomething" + connection.connectionId;
                            // Send connection listener tag to edge via API call
                            console.log("Sending streaming command to device " + deviceID)
                            $.ajax({
                                url: "/api/SharedCommandsAPI/ToggleSignalR",
                                type: "get",
                                data: { deviceID: deviceID, listenerTag: listenertag, state: true }
                            });

                            connection.on(listenertag, function (message) {
                                setLatestValues(JSON.parse(message))
                            });
                        }).catch(function (err) {
                            console.error("444: " + err.toString());

                        }).finally(function () {
                            console.log("Couldn't handle signalR connection")
                        });
                    })
                }
                else {
                    connection.start().then(function () {
                        listenertag = "DoSomething" + connection.connectionId;
                        // Send connection listener tag to edge via API call
                        console.log("Sending streaming command to device " + deviceID)
                        $.ajax({
                            url: "/api/SharedCommandsAPI/ToggleSignalR",
                            type: "get",
                            data: { deviceID: deviceID, listenerTag: listenertag, state: true }
                        });

                        connection.on(listenertag, function (message) {
                            setLatestValues(JSON.parse(message))
                        });
                    }).catch(function (err) {
                        console.error("444: " + err.toString());

                    }).finally(function () {
                        console.log("Couldn't handle signalR connection")
                    });
                }
            }
            else {
                // Stop signal streaming on unmount
                $.ajax({
                    url: "/api/SharedCommandsAPI/ToggleSignalR",
                    type: "get",
                    data: { deviceID: deviceID, listenerTag: listenertag, state: false }
                });
            }
        }
    }, [props.show, deviceID])

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Log a calibration
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {loadingSignalFailed ?
                <div>Signal failed to load. The edge device may be offline, or the signal may not be configured correctly.</div>
                :
                loadingsignals ? 
                    <div>Signal is loading...</div>
                    :
                    <SignalCard
                    key={signal.signalID + "signalcard"}
                    latestvalues={latestvalues}
                    deviceId={deviceID}
                    signal={signal} 
                    />
                
            }
        </Modal.Body>
        <Modal.Footer>
        <Container>
            <Row>
                <Col>
                    <Button variant="primary" onClick={props.onHide}>Cancel</Button>
                </Col>
            </Row>
        </Container>
        </Modal.Footer>
      </Modal>
    );
}

function LogMaintenance(props){ 
    const [btnText, setBtnText] = useState("");
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (props.MaintenanceType == 0){
            setBtnText("Log sensor replacement");
        } else if (props.MaintenanceType == 1){
            setBtnText("Log sensor calibration")
        } else {
            setBtnText("Log Maintenance")
        }
    },[props.MaintenanceType])

    return (
        <>
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip>{btnText}</Tooltip>
                }>
                {props.MaintenanceType == 1 ? 
                    <a className="btn btn-outline-primary fas fa-thermometer-half fa-2x" onClick={()=>setShowModal(true)}></a>
                    :
                    <a className="btn btn-outline-primary fas fa-tools fa-2x" onClick={()=>setShowModal(true)}></a>
                }
            </OverlayTrigger>
            {props.MaintenanceType == 0 && showModal &&
                <LogMaintenanceModal
                    {...props}
                    show={showModal}
                    onHide={() => {
                        setShowModal(false);
                    }}
                />
            }
            {props.MaintenanceType == 1 && showModal &&
                <LogCalibrationModal
                    {...props}
                    show={showModal}
                    onHide={() => {
                        setShowModal(false);
                    }}
                />
            }
        </>
    )
}

export default LogMaintenance;

//Signal R live data streaming code
var connection = new signalR.HubConnectionBuilder().withUrl("/messageHub").build();
var listenertag;
