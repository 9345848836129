import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import moment from 'moment';
import * as signalR from '@microsoft/signalr'
import VisibilitySensor from 'react-visibility-sensor';


class Message extends React.Component {
    constructor(props) {
        super(props);

        this.state = { read: props.read };
    }

    onChange = (isVisible) => {
        if (!this.state.read && isVisible) {
            var thiscomponent = this;
            $.ajax({
                url: '/api/ChatAPI/UpdateLastSeen/',
                type: 'get',
                data: {
                    SiteGUID: this.props.SiteGUID,
                    LastSeen: this.props.timestamp.format()
                },
                success: function (response) {
                    // console.log(thiscomponent.props.message);
                    thiscomponent.props.unreadMessagesHandler(response);
                    thiscomponent.setState({ read: true });
                }
            });
        }
        // console.log('Message is now %s', isVisible ? 'visible' : 'hidden');
    }

    render() {
        return (<div className="border p-2">
            <div className={this.props.isadmin ? "text-light p-1 m-1 rounded bg-info" : "text-secondary p-1 m-1 rounded border"} style={{ fontSize: '12px', 'display': 'inline-block' }}>{this.props.name}</div>
            <VisibilitySensor onChange={this.onChange}>
                <div className="m-1">{this.props.message}</div>
            </VisibilitySensor>
            <div className="text-secondary text-right m-1" style={{ fontSize: '12px' }}>{this.props.timestamp.local().format('LLL')}</div>
        </div>);
    }
}

export function Chats(props) {
    const [expanded, setExpanded] = useState(false)
    const [message, setMessage] = useState('')
    const [connection, setConnection] = useState(new signalR.HubConnectionBuilder().withUrl("/chatHub").build())
    const [signalrconnected, setSignalRConnected] = useState(false)
    const [messages, setMessages] = useState([])
    const [newmessage, setNewMessage] = useState()
    const [unreadmessages, setUnread] = useState(0)
    const [sitename, setSiteName] = useState('')
    const [CurrentCompanySiteRelationGUID, setCurrentCompanySiteRelationGUID] = useState()


    useEffect(() => {
        if (props.SiteGUID && props.SiteGUID != null) {
            $.ajax({
                url: '/api/ChatAPI/GetInitialMessages/',
                type: 'get',
                data: {
                    SiteGUID: props.SiteGUID
                },
                success: function (response) {
                    response = JSON.parse(response);
                    // console.log(response);
                    var messages = [];
                    var unread = 0;
                    for (var i = 0; i < response.length; i++) {
                        console.log(response);
                        if (!response[i].Read) {
                            unread++;
                        }
                        messages.push({ timestamp: moment.utc(response[i].DateSubmitted), read: response[i].Read, message: response[i].Message, name: response[i].Name, isadmin: response[i].IsAdmin })
                    }
                    setMessages(messages)
                    setUnread(unread)
                }
            });

            $.ajax({
                url: '/api/ChatAPI/GetSiteName/',
                type: 'get',
                data: {
                    SiteGUID: props.SiteGUID
                },
                success: function (response) {
                    response = JSON.parse(response);
                    setSiteName(response.SiteName)
                }
            });

            $.ajax({
                url: '/api/ChatAPI/GetCompanySiteRelationGUID/',
                type: 'get',
                data: {
                    SiteGUID: props.SiteGUID
                },
                success: function (response) {
                    response = JSON.parse(response);
                    setCurrentCompanySiteRelationGUID(response.CompanySiteRelationGUID)
                    setSignalRConnected(false)
                }
            });
        }
    }, [props.SiteGUID]);

    useEffect(() => {
        if ($('.chatmessages').length > 0) {
            $('.chatmessages').stop().animate({
                scrollTop: $('.chatmessages')[0].scrollHeight
            }, 800);
        }
    }, [messages]);

    useEffect(() => {
        if (newmessage) {
            setMessages([...messages, { timestamp: moment().utc(), read: false, message: newmessage.message, name: newmessage.username, isadmin: newmessage.isadmin }])
        }
    }, [newmessage]);

    useEffect(() => {
        if (!signalrconnected) {
            connection.on("ReceiveMessage" + CurrentCompanySiteRelationGUID, function (username, message, isadmin) {
                setNewMessage({ username: username, message: message, isadmin: isadmin });
            });

            console.log('chat signal R connecting...... ' + CurrentCompanySiteRelationGUID)
            ConnectSignalR();
        }
    }, [signalrconnected, CurrentCompanySiteRelationGUID])

    function ConnectSignalR() {
        connection.start().then(function () {
            setSignalRConnected(true)
        }).catch(function (err) {
            return console.error(err.toString());
        });
    }

    function unreadMessagesHandler(unread) {
        setUnread(unread)
    }

    function expansionHandler() {
        if (!expanded) {
            setTimeout(function () {
                if ($('.chatmessages').length > 0) {
                    $('.chatmessages').stop().animate({
                        scrollTop: $('.chatmessages')[0].scrollHeight
                    }, 800);
                }
            }, 100)
        }

        setExpanded(!expanded)
    }

    function changeHandler(event) {
        setMessage(event.target.value)
    }

    function _handleKeyDown(e) {
        if (e.key === 'Enter') {

            connection.invoke("SendSiteMessage", message, CurrentCompanySiteRelationGUID).catch(function (err) {
                console.log("error during send")
                ConnectSignalR()
                return console.error(err.toString());
            });
            setMessage('')
        }
    }
    if (CurrentCompanySiteRelationGUID) {
        return (<div>
            {expanded ? <div className="chatbox border shadow" style={{zIndex:1065}}>
                <div className="chatboxheadline p-2 border" onClick={expansionHandler}>{sitename}</div>
                <div className="chatmessages" style={{ 'height': '560px', overflowY: 'scroll', marginTop: '42px' }}>
                    {messages.map((item) =>
                        <Message key={item.timestamp + "message"} message={item.message} name={item.name} isadmin={item.isadmin}
                            timestamp={item.timestamp} SiteGUID={props.SiteGUID} read={item.read} unreadMessagesHandler={unreadMessagesHandler} />)}
                </div>
                <input className="w-100 chatboxinput" onChange={changeHandler} onKeyPress={_handleKeyDown} value={message} />
            </div> : ""}
            <div className="chatbutton border text-center" onClick={expansionHandler} style={{ 'height': '61px', 'width': '61px', zIndex: 999 }}>
                {unreadmessages > 0 ? <div style={{ position: "absolute", top: "5px", right: "10px", fontSize: "12px" }} className="bg-warning text-light rounded-pill p-1">{unreadmessages}</div> : ""}
                <i className="far fa-2x fa-comments" style={{ marginTop: '25%' }}></i>
            </div>
        </div>);
    }
    else {
        return "";
    }
}
