import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import '../Nodes/styles.scss'
import { StreamwiseNodeData } from '../types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash, faCircleInfo} from '@fortawesome/free-solid-svg-icons'

export default memo(  
  ({
  id,
  data,
}: {
  id: string;
  data: StreamwiseNodeData;
}) => {
  return (
    <>
      <Handle
        type="target"
        position="top"
        id="WIRELESS_IN"
        style={{ background: "#555" }}
        isConnectable={false}
      />
      <Handle
        type="source"
        position="top"
        id="WIRELESS_OUT"
        style={{ background: "#555" }}
        isConnectable={false}
      />
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          width: "100%",
        }}
      >
        {data.label}
        <div
          className="trashButton"
          style={{ bottom: "-2px" }}
          onClick={() => {
            if (data.onDelete) data.onDelete(id);
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </div>
      </div>
    </>
  );
});