import React from 'react'
import { Alert } from 'react-bootstrap'

function NoAccessPage(){
    return(
        <Alert variant='danger' className="m-3">
            Contact your administrator for access to this page.
        </Alert>
    )
}
export default NoAccessPage;