import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Button, Form, InputGroup, Row, Col, Table, Container } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import NoAccessPage from './NoAccessPage';
import * as signalR from '@microsoft/signalr';

import AqualaboDetails from './EdgeDiagnostics/AqualaboDetails';
import TekdisAIDetails from './EdgeDiagnostics/TekdisAIDetails';
import TekdisAODetails from './EdgeDiagnostics/TekdisAODetails';
import YottaDetails from './EdgeDiagnostics/YottaDetails';

export const EdgeDiagnostics = props => {
    const { id } = useParams();  // siteGUID
    useEffect(() => props.SiteGUIDSetter(id), [id]);

    const [devices, setDevices] = useState([]);
    const [portIsOpen, setPortIsOpen] = useState(false);       // Whether edge has com port access
    const [diagPortOpen, setDiagPortOpen] = useState(false);  // Whether Diagnostics module has com port access
    const [restarting, setRestarting] = useState(false);

    const [selDevice, setSelDevice] = useState();
    const [selSlaveID, setSelSlaveID] = useState();

    const [showAqualabo, setShowAqualabo] = useState(false);
    const [showAdvantech, setShowAdvantech] = useState(false);
    const [showTekdisAI, setShowTekdisAI] = useState(false);
    const [showTekdisAO, setShowTekdisAO] = useState(false);
    const [showYotta, setShowYotta] = useState(false);


    // ScanDevices states
    const [scannedDevices, setScannedDevices] = useState([]);
    const [scanProgress, setScanProgress] = useState(0);
    const [showScanProgress, setShowScanProgress] = useState(false);

    const baudRates = [1200, 2400, 4800, 9600, 14400, 19200, 28800, 38400, 57600, 115200, 230400, 460800, 921600];
    const parities = [{ Name: 'None', Value: 0 }, { Name: 'Odd', Value: 1 }, { Name: 'Even', Value: 2 }];
    const stopbits = [{ Name: 'None', Value: 0 }, { Name: '1', Value: 1 }, { Name: '2', Value: 2 }, { Name: '1.5', Value: 3 }]

    const [minSlave, setMinSlave] = useState(1);
    const [maxSlave, setMaxSlave] = useState(247);
    // Comport settings
    const [portName, setPortName] = useState("COM1");
    const [baudRate, setBaudRate] = useState(9600);
    const [dataBits, setDataBits] = useState(8);
    const [parity, setParity] = useState(0)
    const [stopBits, setStopBits] = useState(1)

    var connection = new signalR.HubConnectionBuilder()
        .withUrl("/messageHub")
        .withAutomaticReconnect()
        .build();

    useEffect(() => {
        // Get all connected devices
        fetch('/api/SharedCommandsAPI/GetDevices/?siteGUID=' + id)
            .then(res => res.json())
            .then(data => {
                console.log(data);
                setDevices(data);
            }).catch();
    }, [id]);

    const chooseCurrentDevice = deviceID => {
        setSelDevice(deviceID);
        getEdgeState(deviceID);
        getDiagnosticState(deviceID);
    }

    // Retrieve whether comport is open or closed on edge software
    const getEdgeState = deviceID => {
        fetch('/api/EdgeDiagnosticsAPI/GetEdgeState/?deviceID=' + deviceID)
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw "fetch failed";
                }
            })
            .then(data => { setPortIsOpen(data.State); })
            .catch();
    }

    const getDiagnosticState = deviceID => {
        fetch('/api/EdgeDiagnosticsAPI/GetDiagnosticState/?deviceID=' + deviceID)
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw "fetch failed";
                }
            })
            .then(data => { setDiagPortOpen(data.State); })
            .catch();
    }

    const restartModule = () => {
        setRestarting(true);
        fetch('/api/EdgeDiagnosticsAPI/RestartEdgeModule/?deviceID=' + selDevice)
            .then(() => setRestarting(false))
            .catch(() => setRestarting(false))
    }

    // Open/close comport for iotedge
    const toggleComportEdge = () => {
        fetch('/api/EdgeDiagnosticsAPI/ControlComPortAccess/?deviceID=' + selDevice + '&portIsOpen=' + portIsOpen)
            .then(res => {
                if (res.ok)
                {
                    setPortIsOpen(!portIsOpen)
                }
            });
    }

    // EdgeDiagnostics commands
    const toggleComportDiagnostics = () => {
        let configObj = {
            PortName: portName,
            BaudRate: baudRate,
            Parity: parity,
            DataBits: dataBits,
            StopBits: stopBits
        }
        console.log(configObj);

        if (diagPortOpen) {
            fetch('/api/EdgeDiagnosticsAPI/DisconnectComport/?deviceID=' + selDevice)
                .then(res => {
                    if (res.ok) {
                        setDiagPortOpen(false);
                    }
                });
        }
        else {
            fetch('/api/EdgeDiagnosticsAPI/ConnectComport/?deviceID=' + selDevice + '&portConfig=' + JSON.stringify(configObj))
                .then(res => {
                    if (res.ok) {
                        setDiagPortOpen(true);
                    }
                });
        }
    }

    const ScanDevices = () => {
        setScannedDevices([]);
        let tag = "";
        connection.stop();
        // Begin signal R
        connection.start().then(() => {
            setShowScanProgress(true);
            tag = "DiagScan" + connection.connectionId;
            console.log(tag);

            fetch('/api/EdgeDiagnosticsAPI/ScanAllDevices/?deviceID=' + selDevice
                + '&signalRTag=' + tag
                + '&startSlave=' + minSlave
                + '&endSlave=' + maxSlave)
                .then(res => {
                    if (!res.ok) {
                        setShowScanProgress(false);
                        alert("Failed, please review settings and try again.")
                    }
                });

            let newEntries = [];
            connection.on(tag, msg => {
                let entry = JSON.parse(msg);
                // loading bar
                setScanProgress(entry.SlaveID);
                if (entry.ModuleName != null) {
                    console.log(entry);
                    newEntries = [...newEntries, entry];
                    setScannedDevices(newEntries);
                }
                if (entry.SlaveID == maxSlave) {
                    setShowScanProgress(false);
                }
            });
        }).catch(e => console.log('Scan connection failed: ', e));
    }

    const stopScan = () => {
        fetch('/api/EdgeDiagnosticsAPI/StopScan/?deviceID=' + selDevice)
            .then(res => {
                if (res.ok) {
                    setScanProgress(0);
                    setShowScanProgress(false);
                }
            })
    }

    const _handleEntryClick = (slaveID, moduleType) => {
        setSelSlaveID(slaveID);
        if (moduleType === 0) {
            setShowAqualabo(true);
        }
        else if (moduleType === 1) {
            setShowAdvantech(true);
        }
        else if (moduleType === 2) {
            setShowYotta(true);
        }
        else if (moduleType === 3) {
            setShowTekdisAI(true);
        }
        else if (moduleType === 4) {
            setShowTekdisAO(true);
        }
    }

    return (
        props.auth !== undefined ?
            props.auth > 0 ?
                <div>
                    <h1>Edge Diagnostics</h1>
                    <Modal show={showScanProgress} onHide={stopScan}>
                        <Modal.Header closeButton>
                            <Modal.Title>Scanning: {scanProgress}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ProgressBar animated now={(scanProgress - minSlave) / (maxSlave - minSlave) * 100} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='danger' onClick={stopScan}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    <Container>
                        <Row>
                            <Col>
                                <Row className="mb-3">
                                    <Button variant='danger' onClick={restartModule}>Restart Edge <i className={"fad fa-undo" + (restarting ? " fa-spin icon-spin-reverse" : "")}></i></Button>
                                </Row>
                                {/* Device selection for edge control */}
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Select onChange={e => chooseCurrentDevice(e.target.value)}>
                                            <option>Select your device</option>
                                            {devices.map(dev =>
                                                <option key={dev.DeviceID + "-option"} value={dev.DeviceID}>
                                                    {dev.NiceName}
                                                </option>)}
                                        </Form.Select>
                                    </Col>
                                </Row>
                                {/* Edge COM port access */}
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Label>Edge COM Status</Form.Label>
                                        <Form.Check
                                            type="switch"
                                            label={portIsOpen ? <Badge bg='success'>Connected</Badge> : <Badge bg='danger'>Disconnected</Badge>}
                                            checked={portIsOpen}
                                            onChange={toggleComportEdge}
                                        />
                                    </Col>
                                    <Col>
                                        <Button onClick={ScanDevices}>Scan</Button>
                                    </Col>
                                </Row>
                                {/* Diagnostics Tool COM port access */}
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Label>Diagnostics COM Status</Form.Label>
                                        <Form.Check
                                            type='switch'
                                            label={diagPortOpen ? <Badge bg='success'>Connected</Badge> : <Badge bg='danger'>Disconnected</Badge>}
                                            checked={diagPortOpen}
                                            onChange={toggleComportDiagnostics}
                                            />
                                    </Col>
                                    <Col>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>Start Adress</InputGroup.Text>
                                            <Form.Control
                                                type='number'
                                                min={1}
                                                max={maxSlave}
                                                value={minSlave}
                                                onChange={e => setMinSlave(e.target.value)}
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>End Address</InputGroup.Text>
                                            <Form.Control
                                                type='number'
                                                min={minSlave}
                                                max={247}
                                                value={maxSlave}
                                                onChange={e => setMaxSlave(e.target.value)}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Col>

                            <Col>
                                <Row>
                                    <Col>
                                        <Form.Label>COM Port Name</Form.Label>
                                        <Form.Control placeholder="COM#" value={portName} onChange={e => setPortName(e.target.value)} />

                                        <Form.Label>Data Bits</Form.Label>
                                        <Form.Control type='number' value={dataBits} onChange={e => setDataBits(e.target.value)} />

                                        <Row>
                                            <Col>
                                                <Form>
                                                    <Form.Label>Parity</Form.Label>
                                                    {
                                                        parities.map(parityOption =>
                                                            <Form.Check
                                                                key={"parity-" + parityOption.Value}
                                                                id={parityOption.Name + "-parity"}
                                                                type='radio'
                                                                name={parityOption.Value}
                                                                label={parityOption.Name}
                                                                checked={parity === parityOption.Value}
                                                                onChange={e => setParity(Number(e.target.name))} />
                                                        )
                                                    }
                                                </Form>
                                            </Col>

                                            <Col>
                                                <Form>
                                                    <Form.Label>Stop Bits</Form.Label>
                                                    {
                                                        stopbits.map(stopbitOption =>
                                                            <Form.Check
                                                                key={"stopbits-" + stopbitOption.Name}
                                                                id={stopbitOption.Name + "-stopbits"}
                                                                type='radio'
                                                                name={stopbitOption.Value}
                                                                label={stopbitOption.Name}
                                                                checked={stopBits === stopbitOption.Value}
                                                                onChange={e => setStopBits(Number(e.target.name))} />
                                                        )
                                                    }
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col>
                                        <Form.Label>Baud Rate</Form.Label>
                                        <Form className="d-flex flex-wrap" >
                                            {baudRates.map(rateOpt =>
                                                <Form.Check className='m-2'
                                                    key={"baurate-" + rateOpt}
                                                    id={rateOpt.toString() + "-baudrate"}
                                                    type='radio'
                                                    name={rateOpt}
                                                    label={rateOpt}
                                                    checked={baudRate === rateOpt}
                                                    onChange={e => setBaudRate(Number(e.target.name))} />)}
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                    <br />
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Slave ID</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {scannedDevices.map(dev =>
                                <tr key={"module-entry-" + dev.SlaveID}>
                                    <td>{dev.ModuleName}</td>
                                    <td>{dev.SlaveID}</td>
                                    <td><i className="fad fa-eye" onClick={() => _handleEntryClick(dev.SlaveID, dev.ModuleType)}></i></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    {/* Edit Modals for different sensors / modules */}
                    <AqualaboDetails
                        deviceID={selDevice}
                        slaveID={selSlaveID}
                        show={showAqualabo}
                        setShow={setShowAqualabo} />

                    {/*<AdvantechDetails*/}
                    {/*    deviceID={selDevice}*/}
                    {/*    slaveID={selSlaveID}*/}
                    {/*    show={showAdvantech}*/}
                    {/*    setShow={setShowAdvantech} />*/}

                    <TekdisAIDetails
                        deviceID={selDevice}
                        slaveID={selSlaveID}
                        show={showTekdisAI}
                        setShow={setShowTekdisAI} />

                    <TekdisAODetails
                        deviceID={selDevice}
                        slaveID={selSlaveID}
                        show={showTekdisAO}
                        setShow={setShowTekdisAO} />

                    <YottaDetails
                        deviceID={selDevice}
                        slaveID={selSlaveID}
                        show={showYotta}
                        setShow={setShowYotta} />

                </div> :
                <NoAccessPage /> :
            <></>
    );
}
