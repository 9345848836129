import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import LineChartContainer from "./LineCharts/LineChartContainer"
import GaugesContainer from "./Insights/GaugesContainer"
import WidgetsContainer from "./Insights/WidgetsContainer"
import ScatterChartsContainer from "./Insights/ScatterChartsContainer"
import BarChartsContainer from "./Insights/BarChartsContainer"
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css'
import YouTubeVideo from './YouTubeVideo';
import Cookies from 'cookies-js';
import NoAccessPage from './NoAccessPage';
import HelpButton from './HelpButton';

const AuthContext = React.createContext();

//Show info modal if updated since last visit
var AnalyticsTabVersion = Cookies.get('AnalyticsTabVersion');
var CurrentAnalyticsTabVersion = 8;

export function Analytics(props) {
    const { id } = useParams();
    const [siteAddress, setSiteAddress] = useState("Site address")
    const [start, setStart] = useState(moment().subtract(7, 'days').utc().format());
    const [end, setEnd] = useState(moment().utc().format())
    const [showversioncontrolmodal, setShowVersionControlModal] = useState(false)
    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id), [id]);

    useEffect(() => {
        $.ajax({
            url: '/api/DownloadsAPI/GetSiteAddress',
            type: 'get',
            data: { SiteGUID: id },
            type: 'get',
            success: function (response) {
                setSiteAddress(response)
            }
        })
        Annotations(id, start, end);
    }, [id]);

    const handleDatetimerangeUpdate = (start, end, label) => {
        setStart(start.utc().format());
        setEnd(end.utc().format());
        Annotations(id, start.utc().format(), end.utc().format());
    }

    var Annotations = function (SiteGUID, start, end) {
        $.ajax({
            url: '/../api/LineChartAPI/LineChartAnnotationsAPI/',
            type: 'get',
            data: { SiteGUID: SiteGUID, StartTime_str: encodeURIComponent(start), EndTime_str: encodeURIComponent(end) },
            success: function (response) {
                response = JSON.parse(response);

                $('#insightchartannotationstable').attr('hidden', true);
                $('#insightchartannotationsbody').html('');

                if (response.length > 0) {
                    $('#insightchartannotationstable').removeAttr('hidden');
                }
                var i;
                for (i = 0; i < response.length; i++) {
                    $('#insightchartannotationsbody').append('<tr><td>' + response[i].annotationID + '</td><td>' + moment(response[i].datetime).format('LLLL') + '</td><td>' + response[i].tag + '</td></tr>');
                }
            }
        });
    }

    function handleClose() {
        setShowVersionControlModal(false)
    }

    function CheckVersion() {
        if (AnalyticsTabVersion < CurrentAnalyticsTabVersion) {
            //Show modal and update analytics tab version for user so they don't see the message on other devices
            var fd = {
                "AnalyticsTabVersion": CurrentAnalyticsTabVersion
            };
            $.ajax({
                url: '/api/VersionControlAPI/SetAnalyticsTabVersion',
                data: fd,
                type: 'get',
                success: function (response) {
                    AnalyticsTabVersion = CurrentAnalyticsTabVersion;
                    Cookies.set('AnalyticsTabVersion', CurrentAnalyticsTabVersion, { expires: 7999 });

                }
            });
            setShowVersionControlModal(true)
        }
    }

    useEffect(() => {
        // Version control modal
        CheckVersion()
        //First run
        if (AnalyticsTabVersion == undefined || AnalyticsTabVersion < CurrentAnalyticsTabVersion) {
            //Get version from server
            $.ajax({
                url: '/api/VersionControlAPI',
                type: 'get',
                success: function (response) {
                    console.log(response);

                    AnalyticsTabVersion = response.analyticsTabVersion;
                    Cookies.set('HomeTabVersion', response.homeTabVersion, { expires: 7999 });
                    Cookies.set('AnalyticsTabVersion', response.analyticsTabVersion, { expires: 7999 });
                    Cookies.set('HealthSummaryTabVersion', response.healthSummaryTabVersion, { expires: 7999 });
                    Cookies.set('CostBreakdownTabVersion', response.costBreakdownTabVersion, { expires: 7999 });
                    Cookies.set('DeepDiveTabVersion', response.deepDiveTabVersion, { expires: 7999 });
                    Cookies.set('MaintenanceTabVersion', response.maintenanceTabVersion, { expires: 7999 });
                    Cookies.set('BillingTabVersion', response.billingTabVersion, { expires: 7999 });
                    Cookies.set('AlarmsTabVersion', response.alarmsTabVersion, { expires: 7999 });
                    Cookies.set('ControlTabVersion', response.controlTabVersion, { expires: 7999 });
                    Cookies.set('CalibrationsTabVersion', response.calibrationsTabVersion, { expires: 7999 });
                    Cookies.set('AirCleaningTabVersion', response.airCleaningTabVersion, { expires: 7999 });
                    Cookies.set('ReportsTabVersion', response.reportsTabVersion, { expires: 7999 });
                    Cookies.set('SiteDetailsTabVersion', response.siteDetailsTabVersion, { expires: 7999 });
                    Cookies.set('DownloadsTabVersion', response.downloadsTabVersion, { expires: 7999 });

                    CheckVersion();
                }
            });
        }

    }, []);

    return (
        props.auth !== undefined ?
            props.auth > 0 ?
                <div>
                    <AuthContext.Provider value={{ auth: props.auth }}>
                        <HelpButton showHelpModal={setShowVersionControlModal} />
                        <h1>Analytics</h1>
                        <div class="row d-flex align-items-center">
                            <div class="col-md-6">
                                <div class="card mb-3">
                                    <div class="card-body p-0">
                                        <div class="input-group">
                                            <div class="d-none d-lg-block d-xl-block float-left p-3 " style={{ backgroundColor: '#002c4e' }}>
                                                <i class="text-light fas fa-2x fa-map-marker-alt"></i>
                                            </div>
                                            <div class="p-2 d-flex align-items-center">
                                                <div id="siteAddress" class="h5 text-dark">{siteAddress}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 ms-auto">
                                <div id="insightDateRangePickerContainer" class="card mb-3">
                                    <div class="card-body p-0">
                                        <div class="input-group">
                                            <div class="d-none d-lg-block d-xl-block p-3 " style={{ backgroundColor: '#002c4e' }}><i class="far fa-2x fa-calendar-alt" style={{ "color": "white" }}></i></div>
                                            <DateRangePicker
                                                onCallback={handleDatetimerangeUpdate}
                                                initialSettings={{
                                                    timePicker: true,
                                                    autoApply: true,
                                                    ranges: {
                                                        'Last 24 hours': [moment().subtract(1, 'days'), moment()],
                                                        'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                                                        'Last 7 Days': [moment().subtract(7, 'days'), moment()],
                                                        'Last 30 Days': [moment().subtract(30, 'days'), moment()],
                                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                                    },
                                                    alwaysShowCalendars: true,
                                                    maxDate: moment(),
                                                    startDate: moment().subtract(7, 'days'),
                                                    endDate: moment(),
                                                    opens: "center",
                                                    locale: {
                                                        "format": "DD/MM/YYYY",
                                                    }
                                                }}
                                            >
                                                <input type="text" className="m-3 form-control" name="daterange" />
                                            </DateRangePicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <GaugesContainer
                            key={start + "-" + end + "-Gauges"}
                            siteGUID={id}
                            start={start}
                            end={end}
                        />

                        <WidgetsContainer
                            key={start + "-" + end + "-Widgets"}
                            siteGUID={id}
                            start={start}
                            end={end}
                        />

                        <ScatterChartsContainer
                            key={start + "-" + end + "-ScatterCharts"}
                            siteGUID={id}
                            start={start}
                            end={end}
                        />

                        <BarChartsContainer
                            key={start + "-" + end + "-BarCharts"}
                            siteGUID={id}
                            start={start}
                            end={end}
                        />

                        <div class="d-flex fixed-buttons">
                            <div class="ms-2">
                                <button id="resetzoombutton" class="btn btn-primary bg-primary d-flex justify-content-center">Reset graph zoom</button>
                            </div>
                        </div>

                        <LineChartContainer
                            key={start + "-" + end + "-LineCharts"}
                            siteGUID={id}
                            linecharttype={1}
                            start={start}
                            end={end}
                            auth={props.auth}
                        />

                        <table class="table" id="insightchartannotationstable" hidden>
                            <thead>
                                <tr>
                                    <th>
                                        Annotation
                                    </th>
                                    <th>
                                        Time
                                    </th>
                                    <th>
                                        Comment
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody id="insightchartannotationsbody">
                            </tbody>
                        </table>

                        <Modal show={showversioncontrolmodal} onHide={handleClose} size="lg">
                            <Modal.Header closeButton>
                                <h5 class="modal-title" id="exampleModalLongTitle">What's New?</h5>
                            </Modal.Header>
                            <Modal.Body className="text-center">
                                <YouTubeVideo VideoTag="Analytics" />
                                {/*<p className="text-start">View historic data through gauges, widgets, different types of charts and annotations. The last 7 days of data is shown by default but can be changed using the date range picker.</p>*/}
                                {/*<p><img className="w-100" src="/images/HowTos/analytics1.png" alt="Date range selection" /></p>*/}
                                {/*<p className="text-start">Gauges can be customised on the Analytics page. </p>*/}
                                {/*<p><img className="w-100" src="/images/HowTos/analytics2.png" alt="Gauges" /></p>*/}
                                {/*<p className="text-start">Widgets can be customised on the Analytics page.</p>*/}
                                {/*<p><img className="w-100" src="/images/HowTos/analytics3.png" alt="Widgets" /></p>*/}
                                {/*<p className="text-start">Scatter charts can be customised on the Analytics page.</p>*/}
                                {/*<p><img className="w-100" src="/images/HowTos/analytics4.png" alt="Scatter charts" /></p>*/}
                                {/*<p className="text-start">Bar charts can be customised on the Analytics page.</p>*/}
                                {/*<p><img className="w-100" src="/images/HowTos/analytics5.png" alt="Bar charts" /></p>*/}
                                {/*<p className="text-start">Line charts can be customised on the Analytics page.</p>*/}
                                {/*<p><img className="w-100" src="/images/HowTos/analytics6.png" alt="Line charts" /></p>*/}
                                {/*<p><img className="w-100" src="/images/HowTos/analytics7.png" alt="Line charts and annotations configuration" /></p>*/}
                                <p><img className="w-100" src="/images/HowTos/analytics8.png" alt="Line charts and annotations configuration" /></p>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </AuthContext.Provider>
                </div>
                :
                <NoAccessPage />
            :
            <></>
    );
};

export { AuthContext }

