import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AdvantechSignalModel, SignalModel } from '../components/SignalSetup/types';

interface AdvantechSignal {
    Signal: SignalModel;
    Advantech: AdvantechSignalModel;
}

interface AdvantechSignalState {
    loading: boolean;
    signals: AdvantechSignal[];
    error: string | null;
}

const initialState: AdvantechSignalState = {
    loading: false,
    signals: [],
    error: ""
}

export const fetchAdvSignals = createAsyncThunk<AdvantechSignalState, string, { rejectValue: string }>(
    "advSignal/fetchSignals",
    async (siteGUID, thunkAPI) => {
        try {
            const response = await fetch(`/api/SignalSetupAPI/FetchAdvantechSignals/?siteGUID=${siteGUID}`);
            const data = await response.json();
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue("Failed to fetch advantech signals.");
        }
    }
);

export const advSignalSlice = createSlice({
    name: "advantechSignals",
    initialState,
    reducers: {
        addAdvantech: (state, action: PayloadAction<AdvantechSignal>) => {
            state.signals = [...state.signals, action.payload];
        },
        editAdvantech: (state, action: PayloadAction<AdvantechSignal>) => {
            state.signals = state.signals
                .map(s => s.Signal.SignalID === action.payload.Signal.SignalID ? action.payload : s);
        },
        deleteAdvantech: (state, action: PayloadAction<string>) => {
            state.signals = state.signals.filter(s => s.Signal.SignalID !== action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAdvSignals.pending, (state) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(fetchAdvSignals.fulfilled, (state, action) => {
                state.loading = false;
                state.signals = action.payload.signals;
            })

            .addCase(fetchAdvSignals.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Something went wrong";
            })
    }
});

export const {
    addAdvantech,
    editAdvantech,
    deleteAdvantech } = advSignalSlice.actions
export default advSignalSlice.reducer