import React, { useState, useEffect } from "react";
import {Card, Col, Row, Button, CardGroup} from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { IConfiguration, IConfigurationPayload } from "./types";
import { APPLICATION_OPTIONS } from "./StaticData";

const EXAMPLE_CONFIGS: IConfiguration[] = [
  {
    SiteGUID: '23b19e4922654a5d84b2ad5db7675470',
    ConfigID: 0,
    name: 'DAF 1',
    application: 'DAF',
    dateCreated: new Date("2022-04-16"),
    dateLastModified: new Date("2022-05-27"),
  },
  {
    SiteGUID: '23b19e4922654a5d84b2ad5db7675470',
    ConfigID: 1,
    name: 'Food manufacturing plant',
    application: 'Trade waste monitoring',
    dateCreated: new Date("2022-04-15"),
    dateLastModified: new Date("2022-05-25"),
  },
]

export function ConfiguratorSplashPage(props) {
    const { id } = useParams();
    const history = useHistory();
    const [configurations, setConfigurations] = useState<IConfiguration[]>([])

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id), [id]);

    useEffect(() => {
      // Get existing configurations for this site
      fetch("/api/ConfigurationsAPI/GetConfigurationList/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(id),
      })
        .then((response) => response.json())
        .then((body: IConfigurationPayload[]) => {
          let configs: IConfiguration[] = body.map(c => {
            let config: IConfiguration = {
              SiteGUID: c.SiteGUID,
              ConfigID: c.ConfigID,
              name: c.name,
              application:  c.application,
              dateCreated: new Date(c.dateCreated),
              dateLastModified: new Date(c.dateLastModified),
              createdByUserID: c.createdByUserID,
              lastModifiedByUserID: c.lastModifiedByUserID,
            };
            return config;
          })
          setConfigurations(configs);
        });
    }, [])

    return (
      <div className="mt-4">
        <CardGroup>
          {configurations.map((c) => (
            <>
              <Card
                border="primary"
                className="text-center m-4"
                style={{ width: "22rem" }}
              >
                <Card.Header>{c.name}</Card.Header>
                <Card.Body className="m-2">
                  <Card.Text>
                    Application:{" "}
                    {APPLICATION_OPTIONS.find((a) => a.value === c.application)
                      ? APPLICATION_OPTIONS.find(
                          (a) => a.value === c.application
                        )!!.label
                      : c.application}
                  </Card.Text>
                  <Card.Text>
                    Date created: {c.dateCreated.toDateString()}
                  </Card.Text>
                  <Card.Text>
                    Date modified: {c.dateLastModified.toDateString()}
                  </Card.Text>
                  <Button
                    onClick={() =>
                      history.push(
                        "/Configurator/index/" + c.SiteGUID + "/" + c.ConfigID
                      )
                    }
                  >
                    Go to configuration
                  </Button>
                </Card.Body>
              </Card>
              <br />
            </>
          ))}
          <>
            <Card
              border="primary"
              className="text-center m-4"
              style={{ width: "22rem" }}
            >
              <Card.Body
                onClick={() =>
                  history.push(
                    "/Configurator/index/" +
                      id +
                      "/" +
                      (configurations.length > 0
                        ? Math.max(...configurations.map((c) => c.ConfigID)) + 1
                        : 0)
                  )
                }
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="mx-auto p-3">
                  <div className="text-muted text-center">
                    <i className="fas fa-plus"></i>
                    <div>Add new</div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <br />
          </>
        </CardGroup>
      </div>
    );
  }