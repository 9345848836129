import { useState, useEffect } from 'react';
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import { Card, Carousel, OverlayTrigger, Tooltip, InputGroup, Form,Spinner } from 'react-bootstrap';
import Masonry from 'masonry-layout';
import RolePermissions from './RolePermissions';
import NoAccessPage from './NoAccessPage';

var $grid;

const Role = (props) => {
    const [carouselState, setCarouselState] = useState(0);
    const [promptDeleteConfirmation, setPromptDeleteConfirmation] = useState(false);
    const [role, setRole] = useState(props.role);
    const [userList, setUserList] = useState([]);
    const [usersInRole, setUsersInRole] = useState([]);
    const [restoring, setRestoring] = useState(false);
    const [deleteAccess, setDeleteAccess] = useState(false);
    const [newUserEmail, setNewUserEmail] = useState("");

    useEffect(() => {
        getUsers();
        getDeleteAccess();
    }, [])
    const getUsers = () => {
        $.ajax({
            url: '/api/RoleManagementAPI/GetUsers',
            type: 'get',
            data: { RoleID: role.Id },
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: response => {
                setUserList(FilterUsersforAdding(response.AllUsers));
                setUsersInRole(response.UsersInRole);
                setTimeout(() => {
                    $grid.reloadItems();
                    $grid.layout();
                }, 500);
            }
        });
    }

    const getDeleteAccess = () => {
        $.ajax({
            url: '/api/RoleManagementAPI/GetDeleteAccess',
            type: 'get',
            data: { RoleID: role.Id },
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: response => {
                setDeleteAccess(response);
                setTimeout(() => {
                    $grid.reloadItems();
                    $grid.layout();
                }, 500);
            }
        });
    }

    const FilterUsersforAdding = (allUsers) => {
        let filteredList = [];
        for (let j = 0; j < allUsers.length; j++) {
            filteredList.push(
                <option key={allUsers[j].Id} value={allUsers[j].Id}>
                    {allUsers[j].Name} ({allUsers[j].Email})
                </option>
            );
        }
        return filteredList;
    }

    const AddUserToRole = (event) => {
        $.ajax({
            url: '/api/RoleManagementAPI/AddUsertoRole',
            type: 'post',
            data: JSON.stringify({
                RoleId: this.state.role.Id,
                UserId: event.target.value
            }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: response => {
                setUserList(FilterUsersforAdding(response.AllUsers));
                setUsersInRole(response.UsersInRole);
                setTimeout(() => {
                    $grid.reloadItems();
                    $grid.layout();
                }, 500);
            }
        });
    }

    const AddByEmail = (event) => setNewUserEmail(event.target.value);
    const MessageStringChange = (event) => setRole({ ...role, MessageString: event.target.value });

    const EnterDetector = (event) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            // Submit new user
            $.ajax({
                url: '/api/RoleManagementAPI/AddUsertoRoleByEmail',
                type: 'post',
                data: JSON.stringify({
                    RoleId: role.Id,
                    UserId: newUserEmail
                }),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: response => {
                    setUsersInRole(response.UsersInRole);
                    setTimeout(() => {
                        $grid.reloadItems();
                        $grid.layout();
                    }, 500);
                }
            });
        }
    }

    const DeleteUserFromRole = (userID) => {
        // API call to get access level and signals list
        $.ajax({
            url: '/api/RoleManagementAPI/DeleteUserFromRole/',
            type: 'post',
            data: JSON.stringify({
                RoleId: role.Id,
                UserId: userID
            }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: response => {
                setUserList(FilterUsersforAdding(response.AllUsers));
                setUsersInRole(response.UsersInRole);
                setTimeout(() => {
                    $grid.reloadItems();
                    $grid.layout();
                }, 500);
            }
        });
    }

    const SignalSaveHandler = () => {
        // API call to get access level and signals list
        $.ajax({
            url: '/api/RoleManagementAPI/UpdateRole/',
            type: 'post',
            data: JSON.stringify({ MessageString: role }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: response => {
                console.log(response);
                setCarouselState(0);
            }
        });
    }

    const onSlideHandler = () => $grid.layout();

    const PromptDeleteConfirmation = () => setPromptDeleteConfirmation(!promptDeleteConfirmation);

    const DeleteConfirmation = () => {
        // API call to get access level and signals list
        $.ajax({
            url: '/api/RoleManagementAPI/DeleteRole/',
            type: 'post',
            data: JSON.stringify({ roleID: role.Id }),
            contentType: "application/json; charset=utf-8",
            success: () => {
                console.log("Role Deleted");
                setPromptDeleteConfirmation(false);
                props.DeleteRole();
            }
        });
    }

    const ReturnToStartHandler = () => {
        setRestoring(true);
        $.ajax({
            url: '/api/RoleManagementAPI/GetRole/',
            type: 'get',
            data: { RoleID: role.Id },
            success: response => {
                response = JSON.parse(response);
                console.log(response);
                setRole(response);
                setCarouselState(0);
                setRestoring(false);

                setTimeout(() => {
                    $grid.reloadItems();
                    $grid.layout();
                }, 600);
            }
        });
    }

    return (
        <div className="grid-item col-md-4 p-0" id={role.Id + "-Card"}>
            <Card className="m-2 shadow p-0">
                <Card.Header>{role.ClassificationName}</Card.Header>
                <Card.Body className="p-3">
                    <Carousel
                        controls={false}
                        indicators={false}
                        interval={null}
                        activeIndex={carouselState}
                        onSlide={onSlideHandler}
                        onSlid={onSlideHandler}
                    >
                        <Carousel.Item style={{ background: 'white' }}>
                            <div className="row m-1 align-items-center">
                                <RolePermissions role={role} />
                            </div>
                            <hr />
                            <InputGroup>
                                {props.superadmin ?
                                    <Form.Control
                                        className="m-1"
                                        type="email"
                                        as='input'
                                        placeholder="Add user to role by submitting their email"
                                        value={newUserEmail}
                                        onChange={AddByEmail}
                                        onKeyPress={EnterDetector}
                                    /> :
                                    <Form.Select className="m-1" onChange={AddUserToRole}>
                                        <option value="null">Add User to Role</option>
                                        {userList}
                                    </Form.Select>
                                }
                            </InputGroup>

                            {usersInRole.map(user =>
                                <div key={user.Id} className="row m-1 align-items-center">
                                    {user.Name}
                                    {deleteAccess &&
                                        <div className="btn btn-outline-primary col-auto ms-auto" onClick={() => DeleteUserFromRole(user.Id)}>
                                            <i className="fad fa-trash fa-swap-opacity"></i>
                                        </div>
                                    }
                                </div>)
                            }
                            <div className="row m-1 align-items-center">
                                {deleteAccess &&
                                    <OverlayTrigger
                                            placement="top"
                                            show={promptDeleteConfirmation}
                                            overlay={
                                                <Tooltip>
                                                    <div className="align-items-center">
                                                        Confirm delete: <div className="btn btn-danger" onClick={DeleteConfirmation}>
                                                            <i className="fad fa-trash"></i>
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            }>
                                            <div className="btn btn-outline-danger col-auto ms-auto" onClick={PromptDeleteConfirmation}>
                                                <i className="fad fa-trash"></i>
                                            </div>
                                    </OverlayTrigger>
                                }
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </Card.Body>
            </Card>
        </div>
    );
}


// Add role UI is here!!!!
function RolesList(props) {
    const [roles, setRoles] = useState([]);
    const [roleElmts, setRoleElmts] = useState([]);
    const [newrolename, setNewRoleName] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [isStreamwise, setIsStreamwise] = useState(false);
    const [hiddenRole, setHiddenRole] = useState(false);

    const [loading, setLoading] = useState(true);

    const renderRoles = (rolesList) => {
        setLoading(true);
        let newRender = [];
        for (let i = 0; i < rolesList.length; i++) {
            if (!rolesList[i].IsHidden || (rolesList[i].IsHidden && isStreamwise)) {
                newRender.push(
                    <Role
                        key={rolesList[i].Id + "cardkey"}
                        superadmin={props.superadmin}
                        role={rolesList[i]}
                        roles={rolesList}
                        DeleteRole={() => DeleteRole(rolesList[i].Id)}
                        editaccess={true}
                    />
                );
            }
        }
        setRoleElmts(newRender);
        SetAdminPrivileges();
        setLoading(false);

        setTimeout(() => {
            $grid.reloadItems();
            $grid.layout();
        }, 1000);
    }

    useEffect(() => {
        var masonryOptions = {
            itemSelector: '.grid-item',
            animate: true,
            stagger: 50,
            percentPosition: true
        };

        var elem = document.querySelector('#cards');
        $grid = new Masonry(elem, masonryOptions);
        $grid.reloadItems();
        $grid.layout();
    }, []);

    useEffect(() => renderRoles(props.roles), [props.roles]);
    useEffect(() => renderRoles(roles), [roles]);

    // Reshuffle the cards when roles UI is re-rendered
    useEffect(() => {
        setTimeout(function () {
            $grid.reloadItems();
            $grid.layout();
        }, 1000);
    }, [roleElmts]);

    function SetAdminPrivileges() {
        // API call to get access level and signals list
        $.ajax({
            url: '/api/AuthorizationAPI/CheckIfAdmin/',
            type: 'get',
            data: { siteGUID: props.SiteGUID },
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
                setIsAdmin(response);
            }.bind(this)
        });

        $.ajax({
            url: "/api/AuthorizationAPI/CheckIfStreamwise/",
            type: "get",
            success: res => setIsStreamwise(res)
        })
    }

    function AddRole() {
        let newRole = {
            ClassificationName: newrolename,
            SiteGUID: props.SiteGUID,
            IsHidden: hiddenRole
        }

        // API call to get access level and signals list
        $.ajax({
            url: '/api/RoleManagementAPI/CreateRole/',
            type: 'post',
            data: JSON.stringify(newRole),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: roleReturn => {
                setRoles([...roles, roleReturn]);
                setNewRoleName("");
            }
        });
    }

    function DeleteRole(RoleID) {
        console.log("Deleting " + RoleID);
        let newRoles = roles.filter(obj => obj.Id !== RoleID);
        console.log(newRoles);
        setRoles(newRoles);
        setTimeout(() => {
            $grid.reloadItems();
            $grid.layout();
        }, 1000);
    }

    function RoleNamePressHandler(event) {
        if (event.key == "Enter") {
            AddRole();
        }
    }

    return (
        <div>
            {loading ?
                <div className="grid-item col-md-4 p-0">
                    <Card className="m-2 shadow p-0">
                        <Card.Header style={{ textAlign: 'center' }}>
                            <Spinner size='sm' animation="border" role="status" />
                        </Card.Header>
                        <Card.Body className="p-3" style={{ textAlign: 'center' }}>
                           <Spinner style={{ marginTop: 10 }} animation="border" role="status" />
                        </Card.Body>
                    </Card>
                </div> : roleElmts
                
            }
            {isAdmin &&
                <div className="grid-item col-md-4 p-0">
                    <div className="m-2 shadow p-0 card" >
                        <div className="card-body p-3 text-center">
                            <label>Create Role</label>
                            <InputGroup>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={newrolename}
                                    onChange={e => setNewRoleName(e.target.value)}
                                    onKeyPress={RoleNamePressHandler} placeholder="Role name"
                                />
                                <a className="btn btn-outline-primary" onClick={AddRole}><i className="far fa-plus-circle"></i></a>
                            </InputGroup>
                            {props.superadmin &&
                                <InputGroup className="mt-1">
                                    <Form.Label className="me-2">Hidden Role</Form.Label>
                                    <Form.Check type='checkbox' onClick={() => setHiddenRole(!hiddenRole)} />
                                </InputGroup>
                            }
                            <br></br>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}


export function RoleManagement(props) {
    const { id } = useParams();
    const [roles, setRoles] = useState([]);
    const [isSuperadmin, setIsSuperadmin] = useState(false);

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id), [id]);

    useEffect(() => {
        $.ajax({
            url: 'api/AuthorizationAPI/IsSuperAdmin',
            type: 'get',
            data: {SiteGUID: id},
            success: response => {
                response = JSON.parse(response);
                setIsSuperadmin(response);

                $.ajax({
                    url: 'api/RoleManagementAPI/GetRoles',
                    type: 'get',
                    data: {SiteGUID: id},
                    success: innerresponse => {
                        innerresponse = JSON.parse(innerresponse);
                        setRoles(innerresponse);
                        setTimeout(() => $grid.layout(), 1000);
                    }
                });
            }
        });
    }, [id]);

    return (
        props.auth !== undefined && (props.auth > 0 ?
            <div>
                <div id="cards" className="container-fluid p-0">
                    <div className="grid-item col-1"></div>
                    <RolesList superadmin={isSuperadmin} SiteGUID={id} roles={roles} />
                </div>
            </div> :
            <NoAccessPage />
        )
    );
};
