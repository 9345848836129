import React, { useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import ScatterSettingsContainer from "./ScatterSettingsContainer"
import $ from "jquery";
import { Button, Spinner } from 'react-bootstrap'
import InsightScatterChart from './scatter-chart'
import { AuthContext } from '../Analytics';

const ConfigContext = React.createContext();

const DragHandle = sortableHandle(() =>
    <div className="btn d-flex align-items-center btn-group-vertical ms-auto" style={{ height: "100%", border: "1px solid rgb(230 230 230)" }}>
        <i className="fal fa-angle-up" style={{ color: '#002c4e78', display: 'block' }}></i>
        <i className="fal fa-grip-lines" style={{ display: 'block' }}></i>
        <i className="fal fa-angle-down" style={{ color: '#002c4e78', display: 'block' }}></i>
    </div>
);

function ScatterChart(props) {
    const [config, setConfig] = useState(null);
    const [signals, setSignals] = useState([]);
    const [options, setOptions] = useState({});
    const [noData, setNoData] = useState(true);
    const [loading, setLoading] = useState(true);
    const [canEdit, setCanEdit] = useState(false);
    const { auth } = useContext(AuthContext);

    useEffect(() => {
        setLoading(true);
        var url = '/api/AnalyticsAPI/GetScatter?id=' + props.scatterID;
        fetch(url).then(response => response.json())
            .then(data => {
                getSignals(data.SiteGUID)
                updateConfig(data)
                var acID = data.AccessControlID;
                var url2 = '/api/AuthorizationAPI/GetAccessLevel?accessControlID=' + acID;
                fetch(url2).then(response => response.json())
                    .then(data => {
                        setCanEdit(data === 2);
                    });
            });

    }, [props.scatterID]);

    const getSignals = (SiteGUID) => {
        var url = '/api/LineChartAPI/GetSiteSignals/'

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(SiteGUID)
        }).then(response => response.json())
            .then(data => {
                var siteSignals = data.value;
                setSignals(data.value);
            });
    }

    function updateXSignal(xSignal) {
        var newConfig = JSON.parse(JSON.stringify(config));
        newConfig.XSignalID = xSignal;
        setConfig(newConfig);
    }

    function updateXDivideSignal(xDivideSignal) {
        var newConfig = JSON.parse(JSON.stringify(config));
        newConfig.XDivideSignalID = xDivideSignal;
        setConfig(newConfig);
    }

    function updateYSignal(ySignal) {
        var newConfig = JSON.parse(JSON.stringify(config));
        newConfig.YSignalID = ySignal;
        setConfig(newConfig);
    }

    function updateYDivideSignal(yDivideSignal) {
        var newConfig = JSON.parse(JSON.stringify(config));
        newConfig.YDivideSignalID = yDivideSignal;
        setConfig(newConfig);
    }

    function updateColour(newColour) {
        var newConfig = JSON.parse(JSON.stringify(config))
        newConfig.ColorID = newColour;
        setConfig(newConfig);
    }

    function updateConfig(data) {
        setConfig(data);
        InsightScatterChart.init(props.scatterID, props.start, props.end, () => setLoading(false))
    }

    return (
        <ConfigContext.Provider
            value={{
                config,
                updateXSignal,
                updateXDivideSignal,
                updateYSignal,
                updateYDivideSignal,
                updateColour,
                updateConfig
            }}>
            <div class="card border-primary mb-3">
                <div class="card-body p-0 d-flex">
                    {auth == 2 ?
                    <div className="btn-group-vertical ms-auto">
                        <div style={{ height: "100%", cursor: 'grab' }}>
                            <DragHandle />
                        </div>
                    </div>
                        :
                        <></>
                    }
                    <div id={props.scatterID + '-Scatter'} class="col row" style={{height:"350px"}}>
                        {loading ? 
                            <Spinner animation="border" role="status" className="position-absolute" style={{top: '50%', left: '50%'}}/>
                        :
                            <></>
                        }
                        <canvas id={props.scatterID + '-ScatterCanvas'} class="col" height={350}></canvas>
                        <div style={{ width: "40px", zIndex: "100", top: "0px", position: "absolute" }} className="h-100"></div>
                    </div>
                    <div className="btn-group-vertical ms-auto">
                        <div id={"scattersettingsroot-" + props.scatterID} style={{ height: "100%" }}>
                            {canEdit ?
                                <ScatterSettingsContainer
                                    chartModelId={props.scatterID}
                                    ready={config}
                                    signals={signals.map(s => <option value={s.value}>{s.label}</option>)}
                                    onremove={props.onremove} />
                                :
                                <></>
                            }
                        </div>
                    </div>
                </div>
                <div id={props.scatterID + '-ScatterNoData'} class="mx-auto no-data-text" hidden>
                    <div className="text-muted text-center">
                        <i className="fas fa-info-circle"></i>
                        <div>No Data</div>
                    </div>
                </div>
            </div>
        </ConfigContext.Provider>)
}

export { ConfigContext };
export default ScatterChart