import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import {
    Table, Form, InputGroup, Modal, Button, OverlayTrigger, Tooltip, Row, Col
} from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import "react-step-progress-bar/styles.css";

export function SiteSetup(props) {
    const { id } = useParams();
    const [sitename, setSiteName] = useState(null);
    const [sitelocation, setSiteLocation] = useState('');
    const [timezone, setTimeZone] = useState('');
    const [siteoptions, setSiteOptions] = useState([]);
    const [showcreate, setShowCreate] = useState(true);
    const [selectedsite, setSelectedSite] = useState('null');
    const [timezones, setTimeZones] = useState([]);

    useEffect(() => {

        $.ajax({
            url: '/api/DeviceSetupAPI/GetTimeZones/',
            type: 'get',
            data: {},
            success: function (response) {
                response = JSON.parse(response);
                var tzs = [];
                for (var j = 0; j < response.length; j++) {
                    tzs.push(
                        <option key={"TimeZones" + response[j].Key} value={response[j].Key}>{response[j].Value == null ? "Unnamed" : response[j].Value}</option>)
                }
                setTimeZones(tzs);
            }
        });
    }, []);
    
    useEffect(() => {
        var sites = [];
        for (var j = 0; j < props.sitelist.length; j++) {
            sites.push(
                <option key={"Site" + props.sitelist[j].SiteGUID} value={props.sitelist[j].SiteGUID}>{props.sitelist[j].SiteName == null ? "Unnamed" : props.sitelist[j].SiteName}</option>)
        }
        setSiteOptions(sites);
    }, [props.sitelist]);

    useEffect(() => {
        if (selectedsite === 'null') {
            setShowCreate(true);
            props.setSite(null);
        } else {
            setShowCreate(false);
            props.setSite(props.sitelist.find(s => s.SiteGUID === selectedsite));
        }
    }, [selectedsite]);


    function NewSite() {
        if (selectedsite === 'null') {
            var site = {
                SiteName: sitename,
                IANATimeZone: timezone,
                SiteLocation: sitelocation
            }
            props.setSite(site);
        }
    }

    return (
        
            <>
            {props.sitelist.length > 0 ?
                <><select className="m-1 form-control" value={selectedsite} onChange={(e) => setSelectedSite(e.target.value)}>
                    <option value="null">Create New Site</option>
                    {siteoptions}
                </select>  <br/> <br/></>: <></>}
            {showcreate ?
                <Form>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                        Site Name
                    </Form.Label>
                    <Col sm={3}>
                            <Form.Control required type="text" placeholder="Site Name" value={sitename} onChange={(e) => setSiteName(e.target.value)} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                        Site Location
                    </Form.Label>
                    <Col sm={3}>
                            <Form.Control required type="text" placeholder="Site Location" value={sitelocation} onChange={(e) => setSiteLocation(e.target.value)} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                        Time Zone
                    </Form.Label>
                    <Col sm={3}>
                            <select className="m-1 form-control" required value={null} onChange={(e) => setTimeZone(e.target.value)}>
                                <option value="null">Select TimeZone</option>
                                {timezones}
                            </select>
                    </Col>
                </Form.Group>
                </Form> : <></>}
            <Button type="submit" onClick={() => { NewSite(); props.next(); }}>Review and Confirm</Button>
        </>);
};
