import { StreamwiseEdge, StreamwiseNode } from './types';

export const nodes: StreamwiseNode[] = [
  {
    id: "source",
    type: "source",
    position: { x: 0, y: 0 },
    data: {
      name: "Source",
      controlType: 0, 
      controlLinkData: {
        signalID: "",
        inputBlock: ""
      } },
  },
  // {
  //   id: "pid",
  //   type: "pid",
  //   position: { x: 400, y: 0 },
  //   data: {
  //     name: "PID",
  //     controlType: "pid", 
  //     controlLinkData: {
  //       signalID: "",
  //       inputBlock: ""
  //     } },
  // },
  {
    id: "pump",
    type: "pump",
    position: { x: 200, y: -10 },
    data: {
      name: "Pump",
      controlType: 3, 
      controlLinkData: {
        signalID: "",
        inputBlock: ""
      },
      pump: {
        UpPumpNeedMeasureCmd: 0,
        DownPumpNeedMeasureCmd: 0,
      }  },
  },
];

export const edges: StreamwiseEdge[] = [
  { 
    id: 'conn1', 
    source: 'source', 
    sourceHandle: 'OUTPUT', 
    target: 'pump', 
    targetHandle: 'INPUT', 
    type: 'connector',
    data: {name: 'Cable'},
  },
  // { 
  //   id: 'conn2', 
  //   source: 'pid', 
  //   sourceHandle: 'OUTPUT', 
  //   target: 'pump', 
  //   targetHandle: 'INPUT', 
  //   type: 'connector',
  //   data: {name: 'Cable'},
  // },
];
