import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, InputGroup, Row, Col, Form, Table } from 'react-bootstrap';
import * as signalR from '@microsoft/signalr';
import Spinner from 'react-bootstrap/Spinner';

const YottaDetails = ({ show, setShow, deviceID, slaveID }) => {
    // First list is different analog channels and the inner one are values
    const [sensorParams, setSensorParams] = useState([]);
    const [coilStatus, setCoilStatus] = useState([false, false]);

    var connection = new signalR.HubConnectionBuilder()
        .withUrl("/messageHub")
        .withAutomaticReconnect()
        .build();

    useEffect(() => {
        if (show) {
            let tag = "";
            connection.stop();
            // Begin signal R
            connection.start().then(() => {
                tag = "ModalStream" + connection.connectionId;
                console.log(tag);

                fetch("/api/EdgeDiagnosticsAPI/GetYottaDetails/?deviceID=" + deviceID
                    + "&slaveID=" + slaveID + "&signalRTag=" + tag)
                    .then(res => {
                        if (res.ok) {
                            let coils = JSON.parse(res.json());
                            console.log(coils);
                            setCoilStatus(coils.CoilStatus);
                        }
                    }).catch();

                connection.on(tag, msg => {
                    let entry = JSON.parse(msg);
                    let liveData = processLiveData(entry);
                    setSensorParams(liveData);
                });
            }).catch(e => console.log('Scan connection failed: ', e));
        }
    }, [show])

    const processLiveData = dataArray => {
        let dataStrings = dataArray;
        for (var i = 1; i < dataArray.length; i++) {
            if (i === dataArray.length - 1) {
                dataStrings[i] = dataArray[i] + "%";
            } else {
                dataStrings[i] = dataArray[i] + "mA";
            }
        }
        return dataStrings;
    }

    const toggleCoil = index => {
        const data = {
            DeviceID: deviceID,
            SlaveID: slaveID,
            Address: index + 16,
            State: !coilStatus[index]
        }
        console.log(data);
        fetch("/api/EdgeDiagnosticsAPI/WriteYottaDigitalInput/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.ok) {
                let newStatus = [...coilStatus];
                newStatus[index] = !newStatus[index];
                setCoilStatus(newStatus);
            }
        });
    }

    const _handleModalClose = () => {
        // disconnect signalR
        fetch("/api/EdgeDiagnosticsAPI/StopSignalStreamYotta/?deviceID=" + deviceID)
            .then(res => {
                if (res.ok) {
                    setShow(false);
                }
            });
    }

    return (
        <Modal show={show} onHide={_handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Yotta IO Module</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">
                <Row>
                    <Col>
                        <InputGroup>
                            <InputGroup.Text>Slave ID</InputGroup.Text>
                            <Form.Control disabled readOnly type="number" value={slaveID} />
                        </InputGroup>
                    </Col>
                    <Col>
                        <Button className="px-1 py-0 mr-1" variant="secondary" onClick={() => toggleCoil(0)}>
                            <i className="fa-2x fad fa-siren" style={{ color: (coilStatus[0] ? "red" : "") }}></i>
                        </Button>
                        Address: 17
                    </Col>
                    <Col>
                        <Button className="px-1 py-0 mr-1" variant="secondary" onClick={() => toggleCoil(1)}>
                            <i className="fa-2x fad fa-siren" style={{ color: (coilStatus[1] ? "red" : "")}}></i>
                        </Button>
                        Address: 18
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>40001</th>
                                    <th>40097</th>
                                    <th>40113</th>
                                    <th>40129</th>
                                    <th>40145</th>
                                    <th>40161</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sensorParams.length > 0 ?
                                    <tr>
                                        <td>AI0</td>
                                        {sensorParams.map((val, j) =>
                                            <td key={"value-" + j}>
                                                {val}
                                            </td>)}
                                    </tr> :
                                    <tr>
                                        <td colSpan={7} style={{textAlign:"center"}}>
                                            <Spinner animation="border" variant="primary" />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
        );
}

export default YottaDetails;