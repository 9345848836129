import React, { useState, useContext } from "react";
import { OverlayTrigger, Popover, Modal, Button } from "react-bootstrap";
import BarSettings from "./BarSettings";
import PermissionsSettings from "../PermissionsSettings";
import { ConfigContext } from "./BarChart";
import YouTubeVideo from "../YouTubeVideo";

function BarSettingsContainer(props) {
  const [showSettings, setShowSettings] = useState(false);
  const [showHelpModal, setHelpModal] = useState(false);
  const { config } = useContext(ConfigContext);

  function handleClose() {
    setHelpModal(false);
  }

  return props.ready ? (
    <>
      <OverlayTrigger
        trigger='click'
        placement='left'
        show={showSettings}
        overlay={
          <Popover style={{ maxWidth: "85vw", zIndex: 1020 }}>
            <Popover.Header
              as='h3'
              className='d-flex justify-content-between align-items-center'
              style={{ minHeight: "50px" }}
            >
              <span>Bar Chart Settings</span>
              <div className='d-flex gap-2'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='ms-2'
                  style={{ float: "right" }}
                  onClick={() => setHelpModal(true)}
                >
                  Help <i className='fas fa-question-circle'></i>
                </Button>
                <PermissionsSettings
                  accessControlID={config.AccessControlID}
                  siteGUID={config.SiteGUID}
                />
              </div>
            </Popover.Header>
            <Popover.Body>
              <div
                className='d-flex flex-column justify-content-center align-items-center'
                style={{ minHeight: "150px" }}
              >
                <BarSettings
                  {...props}
                  hideSettings={() => setShowSettings(false)}
                />
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <Button
          title='Chart Settings'
          onClick={() => setShowSettings(!showSettings)}
          className='btn btn-primary d-flex align-items-center'
          style={{ height: "100%", maxWidth: "42px" }}
        >
          <i style={{ color: "rgb(255, 255, 255)" }} className='fal fa-cog'></i>
        </Button>
      </OverlayTrigger>
      <Modal show={showHelpModal} onHide={handleClose} size='lg' centered>
        <Modal.Header closeButton>
          <h5 className='modal-title' id='exampleModalLongTitle'>
            Help
          </h5>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <YouTubeVideo VideoTag='BarCharts' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
}

export default BarSettingsContainer;
