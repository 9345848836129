import React from 'react';
import Cookies from 'cookies-js';
import { Modal } from 'react-bootstrap';
import $ from 'jquery';
import moment from 'moment';

export default class EULAHandler extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showState: false,
            EULAAcceptanceDate: Cookies.get('EULAAcceptanceDate'),
            EULALastUpdated: moment("2021-08-23T00:00:00.000Z")
        }
    }

    componentDidMount() {
        //First run
        if (this.state.EULAAcceptanceDate == undefined || moment(this.state.EULAAcceptanceDate) < this.state.EULALastUpdated || this.state.EULAAcceptanceDate == "null") {
            //Get version from server
            $.ajax({
                url: '/api/EULAController/',
                type: 'get',
                success: function (response) {
                    this.setState({ EULAAcceptanceDate: response.eulaAcceptanceDate });
                    Cookies.set('EULAAcceptanceDate', response.eulaAcceptanceDate, { expires: 7999 });
                    if (moment(response.eulaAcceptanceDate) < this.state.EULALastUpdated || response.eulaAcceptanceDate == null) {
                        this.setState({ showState: true })
                    }
                }.bind(this)
            });
        }


    }

    AcceptEULA = () => {
        //Show modal and update home tab version for user so they don't see the message on other devices
        $.ajax({
            url: '/api/EULAController/SetEULADate',
            type: 'get',
            success: function (response) {
                this.setState({
                    EULAAcceptanceDate: response.eulaAcceptanceDate,
                    showState: false
                })
                Cookies.set('EULAAcceptanceDate', response.eulaAcceptanceDate, { expires: 7999 });

            }.bind(this)
        });
    }

    Decline = () => {
        //Show modal and update home tab version for user so they don't see the message on other devices
        $.ajax({
            url: '/api/EULAController/LogOff',
            type: 'get',
            success: function (response) {
                this.setState({
                    showState: false
                })
                window.location.reload();
            }.bind(this)
        });
    }

    closeSelf = () => {
        this.Decline();
    }

    render() {
        return (<Modal show={this.state.showState} onHide={this.closeSelf}
            size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>End User License Agreement</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <iframe src="/files/Streamwise D.I. - EULA - 25 August 2021.pdf" style={{ border: "none" }} width="100%" height="500px">
                </iframe>
            </Modal.Body>
            <Modal.Footer>
                <div class="btn btn-primary" onClick={this.AcceptEULA}>Accept</div>
                <div class="btn btn-danger" onClick={this.Decline}>Decline and log off</div>
            </Modal.Footer>
        </Modal>)
    }
}