import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Gauge as CanvasGauge, Donut } from 'gaugeJS/dist/gauge.min';
// import { Gauge as CanvasGauge, Donut } from './gauge.min'

/**
 * React wrapper for GaugeJS.
 * @param {*} props
 * @return {Object} React element
 */
function Gauge(props) {
    const canvas = useRef();
    const span = useRef();
    const gauge = useRef();

    useEffect(() => {
        // Observe the span node
        const config = {
            characterData: true,
            attributes: true,
            childList: true,
            subtree: true,
        };
        const observer = new MutationObserver((mutationsList, observer) => {
            props.textChangeHandler(span.current.innerText);
        });
        observer.observe(span.current, config);

        return () => {
            observer.disconnect();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        gauge.current = (
            props.donut ? new Donut(canvas.current) : new CanvasGauge(canvas.current)
        );
        gauge.current.setTextField(span.current);
        gauge.current.setOptions(props.options);
        gauge.current.maxValue = props.maxValue;
        gauge.current.setMinValue(props.minValue);
        gauge.current.animationSpeed = props.animationSpeed;
        gauge.current.set(50);
    }, [props.donut]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        gauge.current.setOptions(props.options);
    }, [props.options]);

    useEffect(() => {
        gauge.current.maxValue = props.maxValue;
    }, [props.maxValue]);

    useEffect(() => {
        gauge.current.setMinValue(props.minValue);
    }, [props.minValue]);

    useEffect(() => {
        gauge.current.animationSpeed = props.animationSpeed;
    }, [props.animationSpeed]);

    useEffect(() => {
        if (props.value == 0){
            gauge.current.set(0.2)
        } else if (props.value >= 100){
            gauge.current.set(99.9)
        } else {
            gauge.current.set(props.value)
        }
    }, [props.value]);

    /* eslint-disable no-unused-vars */
    const {
        maxValue,
        minValue,
        animationSpeed,
        options,
        donut,
        value,
        textChangeHandler,
        ...passThroughProps
    } = props;
    /* eslint-enable no-unused-vars */

    return (
        <div>
            <canvas ref={canvas} {...passThroughProps}></canvas>
            <span ref={span} style={{ display: 'none' }}></span>
        </div>
    );
}

Gauge.defaultProps = {
    maxValue: 3000,
    minValue: 0,
    animationSpeed: 32,
    options: {
        angle: 0, // The span of the gauge arc
        lineWidth: 0.29, // The line thickness
        radiusScale: 0.82, // Relative radius
        pointer: {
            length: 0.4, // // Relative to gauge radius
            strokeWidth: 0.02, // The thickness
            color: '#000000' // Fill color
        },
        limitMax: true,     // If false, max value increases automatically if value > maxValue
        limitMin: true,     // If true, the min value of the gauge will be fixed
        generateGradient: true,
        highDpiSupport: true,     // High resolution support
        staticZones: [
            { strokeStyle: "#F03E3E", min: 1, max: 2 }, // Red from 100 to 130
            { strokeStyle: "#FFDD00", min: 2, max: 3 }, // Yellow
            { strokeStyle: "#30B32D", min: 3, max: 4 }, // Green
            { strokeStyle: "#FFDD00", min: 4, max: 5 }, // Yellow
            { strokeStyle: "#F03E3E", min: 5, max: 6 }  // Red
        ],
    },
    donut: false,
    textChangeHandler: () => { },
};

Gauge.propTypes = {
    maxValue: PropTypes.number.isRequired,
    minValue: PropTypes.number.isRequired,
    animationSpeed: PropTypes.number.isRequired,
    options: PropTypes.object.isRequired,
    donut: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
    textChangeHandler: PropTypes.func.isRequired,
};

export function GaugeComponent(props) {
    const [gauge, setGauge] = useState(null)
    const [options, setOpts] = useState({
        angle: 0, // The span of the gauge arc
        lineWidth: 0.29, // The line thickness
        radiusScale: 0.82, // Relative radius
        pointer: {
            length: 0.4, // // Relative to gauge radius
            strokeWidth: 0.02, // The thickness
            color: '#000000' // Fill color
        },
        limitMax: true,     // If false, max value increases automatically if value > maxValue
        limitMin: true,     // If true, the min value of the gauge will be fixed
        generateGradient: true,
        highDpiSupport: true,     // High resolution support
    })
    const [textoutput, setTextOutput] = useState(null)

    function handleResultTextChange(value) {
        setTextOutput(value)
    }

    useEffect(() => {

        var start = Math.max(0, Math.min(props.value, Number(props.target)) - (Number(props.benchmark) - Math.min(props.value, Number(props.target))) * 0.1);
        var end = Math.max(props.value, Number(props.benchmark) + (Number(props.benchmark) - Number(props.target)) * 0.1);

        var opts = {
            angle: 0, // The span of the gauge arc
            lineWidth: 0.29, // The line thickness
            radiusScale: 0.82, // Relative radius
            pointer: {
                length: 0.4, // // Relative to gauge radius
                strokeWidth: 0.02, // The thickness
                color: '#000000' // Fill color
            },
            limitMax: true,     // If false, max value increases automatically if value > maxValue
            limitMin: true,     // If true, the min value of the gauge will be fixed
            generateGradient: true,
            highDpiSupport: true,     // High resolution support
            staticZones: [
                { strokeStyle: "rgba(53, 237, 40, 1)", min: start, max: start + 0.2 * (end - start), height: 1 }, //background
                { strokeStyle: "rgba(198, 237, 40, 1)", min: start + 0.2 * (end - start), max: start + 0.4 * (end - start), height: 1 }, //background
                { strokeStyle: "rgba(237, 198, 40, 1)", min: start + 0.4 * (end - start), max: start + 0.6 * (end - start), height: 1 }, //background
                { strokeStyle: "rgba(237, 148, 40, 1)", min: start + 0.6 * (end - start), max: start + 0.8 * (end - start), height: 1 }, //background
                { strokeStyle: "rgba(237, 40, 40, 1)", min: start + 0.8 * (end - start), max: end, height: 1 }, //background
                { strokeStyle: "rgba(0, 44, 79, 1)", min: Math.max(0, Number(props.target) - (Number(props.benchmark) * 1.1 - Number(props.target) * 0.9) * 0.05), max: Number(props.target) + (Number(props.benchmark) * 1.1 - Number(props.target) * 0.9) * 0.05, height: 1.3 }, //target region
            ]
        };
        var labels = [];
        if (typeof props.target !== 'undefined') {
            labels.push(Number(props.target));
        }
        if (typeof props.benchmark !== 'undefined') {
            labels.push(Number(props.benchmark));
        }
        if (labels.length > 0) {
            opts.staticLabels = {
                font: "16px sans-serif",  // Specifies font
                labels: labels,  // Print labels at these values
                color: "#000000",  // Optional: Label text color
                fractionDigits: 1  // Optional: Numerical precision. 0=round off.
            };
        };

        setOpts(opts)

    }, [props.value, props.target, props.benchmark]);

    return (
        <div align="center" class="gaugecomponent">
            <div class="row w-100" style={{ 'position': 'absolute', 'margin-top': '5%', 'font-size': '80%' }}>
                <div class="col-5 text-left d-none d-xl-block">Streamwise D.I. Optimization Target: ${props.target.toFixed(2)}/kL</div>
                <div class="col-2"></div>
                <div class="col-5 text-right d-none d-xl-block">OPEX Before Streamwise D.I. Optimization: ${props.benchmark.toFixed(2)}/kL</div>
            </div>
            <Gauge
                value={props.value}
                minValue={Math.max(0, Math.min(props.value * 0.9, Number(props.target) - (Number(props.benchmark) - Number(props.target)) * 0.1))}
                maxValue={Math.max(Number(props.benchmark) + (Number(props.benchmark) - Number(props.target)) * 0.1, props.value)}
                animationSpeed={32}
                options={options}
                textChangeHandler={handleResultTextChange}

                // any other props are passed through to the canvas element
                className='deltagaugecanvas'
            />
            <h4 class="d-flex justify-content-center mt-n3">&nbsp;${props.value.toFixed(2)}/kL</h4>
        </div>);
}

export { Gauge } 
export default GaugeComponent;