import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CustomGraphRootElement from './LineCharts/DataExploration';
import LineChartContainer from './LineCharts/LineChartContainer';
import EULAHandler from './eulahandler';
import $ from 'jquery';
import { Button, Modal } from 'react-bootstrap';
import Cookies from 'cookies-js';
import YouTubeVideo from './YouTubeVideo';
import NoAccessPage from './NoAccessPage';
import HelpButton from './HelpButton';

//Show info modal if updated since last visit
var HomeTabVersion = Cookies.get('HomeTabVersion');
var CurrentHomeTabVersion = 3;

export function Home(props) {
    const { id } = useParams();
    const [showversioncontrolmodal, setShowVersionControlModal] = useState(false)

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id), [id]);

    function handleClose() {
        setShowVersionControlModal(false)
    }

    function CheckVersion() {
        if (HomeTabVersion < CurrentHomeTabVersion) {
            //Show modal and update home tab version for user so they don't see the message on other devices
            var fd = {
                "HomeTabVersion": CurrentHomeTabVersion
            };
            $.ajax({
                url: '/api/VersionControlAPI/SetHomeTabVersion',
                data: fd,
                type: 'get',
                success: function (response) {
                    HomeTabVersion = CurrentHomeTabVersion;
                    Cookies.set('HomeTabVersion', CurrentHomeTabVersion, { expires: 7999 });

                }
            });
            setShowVersionControlModal(true)
        }
    }

    useEffect(() => {
        // Check access
        if (props.auth > 0) {
            // Version control modal
            CheckVersion()
            //First run
            if (HomeTabVersion == undefined || HomeTabVersion < CurrentHomeTabVersion) {
                //Get version from server
                $.ajax({
                    url: '/api/VersionControlAPI',
                    type: 'get',
                    success: function (response) {
                        console.log(response);

                        HomeTabVersion = response.homeTabVersion;
                        Cookies.set('HomeTabVersion', response.homeTabVersion, { expires: 7999 });
                        Cookies.set('AnalyticsTabVersion', response.analyticsTabVersion, { expires: 7999 });
                        Cookies.set('HealthSummaryTabVersion', response.healthSummaryTabVersion, { expires: 7999 });
                        Cookies.set('CostBreakdownTabVersion', response.costBreakdownTabVersion, { expires: 7999 });
                        Cookies.set('DeepDiveTabVersion', response.deepDiveTabVersion, { expires: 7999 });
                        Cookies.set('MaintenanceTabVersion', response.maintenanceTabVersion, { expires: 7999 });
                        Cookies.set('BillingTabVersion', response.billingTabVersion, { expires: 7999 });
                        Cookies.set('AlarmsTabVersion', response.alarmsTabVersion, { expires: 7999 });
                        Cookies.set('ControlTabVersion', response.controlTabVersion, { expires: 7999 });
                        Cookies.set('CalibrationsTabVersion', response.calibrationsTabVersion, { expires: 7999 });
                        Cookies.set('AirCleaningTabVersion', response.airCleaningTabVersion, { expires: 7999 });
                        Cookies.set('ReportsTabVersion', response.reportsTabVersion, { expires: 7999 });
                        Cookies.set('SiteDetailsTabVersion', response.siteDetailsTabVersion, { expires: 7999 });
                        Cookies.set('SignalSetupTabVersion', response.siteDetailsTabVersion, { expires: 7999 });
                        Cookies.set('DownloadsTabVersion', response.downloadsTabVersion, { expires: 7999 });

                        CheckVersion();
                    }
                });
            }
        }

    }, []);


    return (
        props.auth !== undefined ?
            props.auth > 0 ?
                <div>
                    <HelpButton showHelpModal={setShowVersionControlModal} />
                    <div className="d-flex fixed-buttons">
                        <div className="ms-2" id="dataexplorationroot">
                            <CustomGraphRootElement siteGUID={id} />
                        </div>
                        <div className="ms-2">
                            <button id="resetzoombutton" className="btn btn-primary d-flex justify-content-center">Reset graph zoom</button>
                        </div>
                    </div>
                    <LineChartContainer
                        siteGUID={id}
                        linecharttype={0}
                        auth={props.auth}
                    />

                    <Modal show={showversioncontrolmodal} onHide={handleClose} size="lg">
                        <Modal.Header closeButton>
                            <h5 className="modal-title" id="exampleModalLongTitle">What's New?</h5>
                        </Modal.Header>
                        <Modal.Body className="text-center">
                            {/* <YouTubeVideo VideoTag="Home" />
                            <p className="text-start">The new Streamwise D.I. portal brings new functionality and increased speed.</p>
                            <p><img className="w-100" src="/images/HowTos/home4.png" alt="Document Upload" /></p>
                            <p className="text-start">On the home page, you can create custom graphs.</p>
                            <p><img className="w-100" src="/images/HowTos/home1.png" alt="Document Upload" /></p> */}
                            <p><img className="w-100" src="/images/HowTos/home-v3.png" alt="Document Upload" /></p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <EULAHandler />
                </div>
                :
                <NoAccessPage />
            : <></>);
};

