import React, { useState, useEffect } from 'react';

function YouTubeVideo(props) {
    const [HelpVideoID, setHelpVideoID] = useState("")

    useEffect(() => {
        fetch(
            "/api/VideoLibraryAPI/GetYouTubeID", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ VideoTag: props.VideoTag })
        })
            .then((response) => response.text())
            .then((data) => {
                console.log(data)
                setHelpVideoID(data);
            });
    }, []);

    return (
        <iframe width="560" height="315" src={"https://www.youtube.com/embed/" + HelpVideoID} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    )
}

export default YouTubeVideo;