import React, { useState, useRef, useEffect } from 'react';
import { getSmoothStepPath } from 'react-flow-renderer';
import { usePointsOnPath } from "./usePointsOnPath";
import { Button } from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
//import {faCirclePlus} from '@fortawesome/pro-solid-svg-icons'

import './styles.scss';

const foreignObjectHeight = 20;
const foreignObjectWidth = 20;

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
  source,
  target,
}) {
  const pathRef = useRef<SVGPathElement>(null);
  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const center = 0.5;
  const {
    points: [centerPoint],
  } = usePointsOnPath([center], pathRef, edgePath);
  return (
    <>
      <path
        ref={pathRef}
        id={id}
        style={{...style, stroke: '#5dc7f5'}}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {centerPoint && (
        <foreignObject
          width={foreignObjectWidth}
          height={foreignObjectHeight}
          x={centerPoint!!.x - foreignObjectWidth / 2}
          y={centerPoint!!.y - foreignObjectHeight / 2}
          className="cable-foreignobject"
          requiredExtensions="http://www.w3.org/1999/xhtml"
          style={{overflow: 'unset'}}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
            }}
          >
            <div onClick={() => {data.onNewLink(source,target);}}>
            <FontAwesomeIcon style={{background: "white"}} icon="fas fa-plus" />
          </div>
          </div>
        </foreignObject>
      )}
    </>
  );
}
