import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function HelpTooltip(props) {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props.helptext}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement={props.placement}
            delay={{ show: 150, hide: 300 }}
            overlay={renderTooltip(props)}
        >
            <div className={props.className}>
                <i className="fas fa-question-circle" ></i>
            </div>
        </OverlayTrigger>
    )
}

export default HelpTooltip;