import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

interface SignalTypeSelectionProps {
    typeValue: number;
    setTypeValue: (value: number) => void;
}
export const SignalTypeSelection = ({ typeValue, setTypeValue }: SignalTypeSelectionProps) => {
    
    return (
        <ToggleButtonGroup name='signalTypeOptions' type='radio' value={typeValue} onChange={() => setTypeValue}>
            <ToggleButton value={0} className="fas fa-wave-triangle"></ToggleButton>
            <ToggleButton value={1} className="fas fa-wind"></ToggleButton>
            <ToggleButton value={2} className="fas fa-water-rise"></ToggleButton>
        </ToggleButtonGroup>
    );
}