import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import { StreamwiseNodeData } from '../types';
import './styles.scss'
import { Button } from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash, faCircleInfo, faCirclePlus} from '@fortawesome/free-solid-svg-icons'


export default memo(
  ({
    id,
    data,
  }: {
    id: string;
    data: StreamwiseNodeData;
  }) => {
    return (
      <>
        <div className="nodeText">{data.name}</div>
        <div style={{height: '20px'}}>
                <div onClick={() => { if (data.addLink) data.addLink() }}>
                    <FontAwesomeIcon icon={faCirclePlus} />
          </div>
        </div>
      </>
    );
  }
);