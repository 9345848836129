import React, { memo } from 'react';

import { Handle } from 'react-flow-renderer';
import '../styles.scss'

const PORT_POSITIONS = [30, 13, 30]

export default memo(({ data, isConnectable }) => {
  return (
    <>
      <div>
        Splitter
      </div>
      <Handle
          type="target"
          position="top"
          id='IN'
          style={{ left: PORT_POSITIONS[0], background: '#5dc7f5' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
      />
      <Handle
          type="source"
          position="left"
          id='OUT1'
          style={{ top: PORT_POSITIONS[1], background: '#5dc7f5' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
      />
      <Handle
          type="source"
          position="bottom"
          id='OUT2'
          style={{ left: PORT_POSITIONS[2], background: '#5dc7f5' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
      />
    </>
  );
});