import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import NoAccessPage from './NoAccessPage';
import HelpTooltip from './HelpTooltip';
import {AlarmSettingsModal} from './Alarms/AlarmSettingsModal';

import { Carousel, OverlayTrigger, Tooltip, Button, FormControl, InputGroup } from 'react-bootstrap';
import Masonry from 'masonry-layout';
import Modal from 'react-bootstrap/Modal';

var $grid;

class Alarm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            carouselstate: 0,
            promptdeleteconfirmation: false,
            alarm: props.alarm,
            signals: props.signals,
            restoring: false,
            notificationsetup: {},
            itemdeleteconfirmation: {}
        };
    }

    componentDidMount() {

    }

    NameEditHandler = () => {
        this.setState({ carouselstate: 1 })
    }

    ThresholdEditHandler = () => {
        this.setState({ carouselstate: 2 })
    }

    NextStepForNotificationSetup = () => {
        this.setState({ carouselstate: this.state.carouselstate + 1 })
    }

    PreviousStepForNotificationSetup = () => {
        this.setState({ carouselstate: this.state.carouselstate - 1 })
    }

    FilterUsersforAdding = (allUsers, existingUsers) => {
        var filteredUsers = allUsers;
        var filteredList = [];
        var j;
        for (j = 0; j < filteredUsers.length; j++) {
            filteredList.push(<option key={filteredUsers[j].Id} value={filteredUsers[j].Id}>{filteredUsers[j].Name}</option>)
        }
        return filteredList;
    }

    ExistingUsersList = (users, deleteFunction) => {
        var list = [];
        var i;
        for (i = 0; i < users.length; i++) {
            const user = users[i];
            list.push(<div key={users[i].UserID} className="row m-1 align-items-center">
                <div className="col-auto">{users[i].User.Name}</div>
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip>Remove {users[i].User.Name}</Tooltip>
                    }>
                    <div className="btn btn-outline-primary col-auto ms-auto" onClick={() => deleteFunction(user)}>
                        <i className="fad fa-trash fa-swap-opacity"></i>
                    </div>
                </OverlayTrigger></div>);
        }
        return list;
    }

    NotificationSetupHandler = () => {
        $.ajax({
            url: '/api/AlarmSetupAPI/NotificationSetup',
            type: 'get',
            data: {
                AlarmID: this.state.alarm.AlarmID
            },
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
                var notificationsetup = {
                    SetEmailUsers: this.ExistingUsersList(response.SetEmailUsers, this.DeleteUserforSetEmail),
                    SetEmailUserList: this.FilterUsersforAdding(response.AllUsers, response.SetEmailUsers),
                    SetSMSUsers: this.ExistingUsersList(response.SetSMSUsers, this.DeleteUserforSetSMS),
                    SetSMSUserList: this.FilterUsersforAdding(response.AllUsers, response.SetSMSUsers),
                    ResetEmailUsers: this.ExistingUsersList(response.ResetEmailUsers, this.DeleteUserforResetEmail),
                    ResetEmailUserList: this.FilterUsersforAdding(response.AllUsers, response.ResetEmailUsers),
                    ResetSMSUsers: this.ExistingUsersList(response.ResetSMSUsers, this.DeleteUserforResetSMS),
                    ResetSMSUserList: this.FilterUsersforAdding(response.AllUsers, response.ResetSMSUsers)
                }
                this.setState({ carouselstate: 3 })
                this.setState({ notificationsetup: notificationsetup })
                setTimeout(function () {
                    $grid.reloadItems();
                    $grid.layout();

                }.bind(this), 250);
            }.bind(this)
        });

    }

    AddUserforSetEmail = (event) => {
        // API call to get access level and signals list
        $.ajax({
            url: '/api/AlarmSetupAPI/AddAlarmSetEmail',
            type: 'post',
            data: JSON.stringify({
                AlarmID: this.state.alarm.AlarmID,
                UserID: event.target.value
            }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {

                var newnotificationsetup = {
                    SetEmailUsers: this.ExistingUsersList(response.SetEmailUsers, this.DeleteUserforSetEmail),
                    SetEmailUserList: this.FilterUsersforAdding(response.AllUsers, response.SetEmailUsers),
                    SetSMSUsers: this.state.notificationsetup.SetSMSUsers,
                    SetSMSUserList: this.state.notificationsetup.SetSMSUserList,
                    ResetEmailUsers: this.state.notificationsetup.ResetEmailUsers,
                    ResetEmailUserList: this.state.notificationsetup.ResetEmailUserList,
                    ResetSMSUsers: this.state.notificationsetup.ResetSMSUsers,
                    ResetSMSUserList: this.state.notificationsetup.ResetSMSUserList
                };
                this.setState({ notificationsetup: newnotificationsetup });
                setTimeout(function () {
                    $grid.reloadItems();
                    $grid.layout();

                }.bind(this), 250);
            }.bind(this)
        });
    }

    DeleteUserforSetEmail = (user) => {
        // API call to get access level and signals list
        $.ajax({
            url: '/api/AlarmSetupAPI/DeleteAlarmSetEmail/',
            type: 'post',
            data: JSON.stringify(user),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {

                var newnotificationsetup = {
                    SetEmailUsers: this.ExistingUsersList(response.SetEmailUsers, this.DeleteUserforSetEmail),
                    SetEmailUserList: this.FilterUsersforAdding(response.AllUsers, response.SetEmailUsers),
                    SetSMSUsers: this.state.notificationsetup.SetSMSUsers,
                    SetSMSUserList: this.state.notificationsetup.SetSMSUserList,
                    ResetEmailUsers: this.state.notificationsetup.ResetEmailUsers,
                    ResetEmailUserList: this.state.notificationsetup.ResetEmailUserList,
                    ResetSMSUsers: this.state.notificationsetup.ResetSMSUsers,
                    ResetSMSUserList: this.state.notificationsetup.ResetSMSUserList
                }
                this.setState({ notificationsetup: newnotificationsetup })
                setTimeout(function () {
                    $grid.reloadItems();
                    $grid.layout();

                }.bind(this), 250);
            }.bind(this)
        });
    }

    AddUserforSetSMS = (event) => {
        // API call to get access level and signals list
        $.ajax({
            url: '/api/AlarmSetupAPI/AddAlarmSetSMS/',
            type: 'post',
            data: JSON.stringify({
                AlarmID: this.state.alarm.AlarmID,
                UserID: event.target.value
            }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {

                var newnotificationsetup = {
                    SetEmailUsers: this.state.notificationsetup.SetEmailUsers,
                    SetEmailUserList: this.state.notificationsetup.SetEmailUserList,
                    SetSMSUsers: this.ExistingUsersList(response.SetSMSUsers, this.DeleteUserforSetSMS),
                    SetSMSUserList: this.FilterUsersforAdding(response.AllUsers, response.SetSMSUsers),
                    ResetEmailUsers: this.state.notificationsetup.ResetEmailUsers,
                    ResetEmailUserList: this.state.notificationsetup.ResetEmailUserList,
                    ResetSMSUsers: this.state.notificationsetup.ResetSMSUsers,
                    ResetSMSUserList: this.state.notificationsetup.ResetSMSUserList
                }
                this.setState({ notificationsetup: newnotificationsetup })
                setTimeout(function () {
                    $grid.reloadItems();
                    $grid.layout();

                }.bind(this), 250);
            }.bind(this)
        });
    }

    DeleteUserforSetSMS = (user) => {
        // API call to get access level and signals list
        $.ajax({
            url: '/api/AlarmSetupAPI/DeleteAlarmSetSMS/',
            type: 'post',
            data: JSON.stringify(user),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {

                var newnotificationsetup = {
                    SetEmailUsers: this.state.notificationsetup.SetEmailUsers,
                    SetEmailUserList: this.state.notificationsetup.SetEmailUserList,
                    SetSMSUsers: this.ExistingUsersList(response.SetSMSUsers, this.DeleteUserforSetSMS),
                    SetSMSUserList: this.FilterUsersforAdding(response.AllUsers, response.SetSMSUsers),
                    ResetEmailUsers: this.state.notificationsetup.ResetEmailUsers,
                    ResetEmailUserList: this.state.notificationsetup.ResetEmailUserList,
                    ResetSMSUsers: this.state.notificationsetup.ResetSMSUsers,
                    ResetSMSUserList: this.state.notificationsetup.ResetSMSUserList
                }
                this.setState({ notificationsetup: newnotificationsetup })
                setTimeout(function () {
                    $grid.reloadItems();
                    $grid.layout();

                }.bind(this), 250);
            }.bind(this)
        });
    }

    AddUserforResetEmail = (event) => {
        // API call to get access level and signals list
        $.ajax({
            url: '/api/AlarmSetupAPI/AddAlarmResetEmail/',
            type: 'post',
            data: JSON.stringify({
                AlarmID: this.state.alarm.AlarmID,
                UserID: event.target.value
            }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {

                var newnotificationsetup = {
                    SetEmailUsers: this.state.notificationsetup.SetEmailUsers,
                    SetEmailUserList: this.state.notificationsetup.SetEmailUserList,
                    ResetEmailUsers: this.ExistingUsersList(response.ResetEmailUsers, this.DeleteUserforResetEmail),
                    ResetEmailUserList: this.FilterUsersforAdding(response.AllUsers, response.ResetEmailUsers),
                    ResetSMSUsers: this.state.notificationsetup.ResetSMSUsers,
                    ResetSMSUserList: this.state.notificationsetup.ResetSMSUserList
                }
                this.setState({ notificationsetup: newnotificationsetup })
                setTimeout(function () {
                    $grid.reloadItems();
                    $grid.layout();

                }.bind(this), 250);
            }.bind(this)
        });
    }

    DeleteUserforResetEmail = (user) => {
        // API call to get access level and signals list
        $.ajax({
            url: '/api/AlarmSetupAPI/DeleteAlarmResetEmail/',
            type: 'post',
            data: JSON.stringify(user),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {

                var newnotificationsetup = {
                    SetEmailUsers: this.state.notificationsetup.SetEmailUsers,
                    SetEmailUserList: this.state.notificationsetup.SetEmailUserList,
                    SetSMSUsers: this.state.notificationsetup.SetSMSUsers,
                    SetSMSUserList: this.state.notificationsetup.SetSMSUserList,
                    ResetEmailUsers: this.ExistingUsersList(response.ResetEmailUsers, this.DeleteUserforResetEmail),
                    ResetEmailUserList: this.FilterUsersforAdding(response.AllUsers, response.ResetEmailUsers),
                    ResetSMSUsers: this.state.notificationsetup.ResetSMSUsers,
                    ResetSMSUserList: this.state.notificationsetup.ResetSMSUserList
                }
                this.setState({ notificationsetup: newnotificationsetup })
                setTimeout(function () {
                    $grid.reloadItems();
                    $grid.layout();

                }.bind(this), 250);
            }.bind(this)
        });
    }

    AddUserforResetSMS = (event) => {
        // API call to get access level and signals list
        $.ajax({
            url: '/api/AlarmSetupAPI/AddAlarmResetSMS/',
            type: 'post',
            data: JSON.stringify({
                AlarmID: this.state.alarm.AlarmID,
                UserID: event.target.value
            }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {

                var newnotificationsetup = {
                    SetEmailUsers: this.state.notificationsetup.SetEmailUsers,
                    SetEmailUserList: this.state.notificationsetup.SetEmailUserList,
                    SetSMSUsers: this.state.notificationsetup.SetSMSUsers,
                    SetSMSUserList: this.state.notificationsetup.SetSMSUserList,
                    ResetEmailUsers: this.state.notificationsetup.ResetEmailUsers,
                    ResetEmailUserList: this.state.notificationsetup.ResetEmailUserList,
                    ResetSMSUsers: this.ExistingUsersList(response.ResetSMSUsers, this.DeleteUserforResetSMS),
                    ResetSMSUserList: this.FilterUsersforAdding(response.AllUsers, response.ResetSMSUsers)
                }
                this.setState({ notificationsetup: newnotificationsetup })
                setTimeout(function () {
                    $grid.reloadItems();
                    $grid.layout();

                }.bind(this), 250);
            }.bind(this)
        });
    }

    DeleteUserforResetSMS = (user) => {
        // API call to get access level and signals list
        $.ajax({
            url: '/api/AlarmSetupAPI/DeleteAlarmResetSMS/',
            type: 'post',
            data: JSON.stringify(user),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {

                var newnotificationsetup = {
                    SetEmailUsers: this.state.notificationsetup.SetEmailUsers,
                    SetEmailUserList: this.state.notificationsetup.SetEmailUserList,
                    SetSMSUsers: this.state.notificationsetup.SetSMSUsers,
                    SetSMSUserList: this.state.notificationsetup.SetSMSUserList,
                    ResetEmailUsers: this.state.notificationsetup.ResetEmailUsers,
                    ResetEmailUserList: this.state.notificationsetup.ResetEmailUserList,
                    ResetSMSUsers: this.ExistingUsersList(response.ResetSMSUsers, this.DeleteUserforResetSMS),
                    ResetSMSUserList: this.FilterUsersforAdding(response.AllUsers, response.ResetSMSUsers)
                }
                this.setState({ notificationsetup: newnotificationsetup })
                setTimeout(function () {
                    $grid.reloadItems();
                    $grid.layout();

                }.bind(this), 250);
            }.bind(this)
        });
    }

    SignalChange = (event) => {
        console.log(event.target.value);
        var alarm = this.state.alarm;
        alarm.SignalID = event.target.value;
        var i;
        for (i = 0; i < this.props.signals.length; i++) {
            if (this.props.signals[i].SignalID == event.target.value) {
                console.log("Filter signal match found");
                alarm.SignalID = this.props.signals[i];
                this.setState({ alarm: alarm });
                break;
            }
        }
    }

    MessageStringChange = (event) => {
        var alarm = this.state.alarm;
        alarm.MessageString = event.target.value;
        this.setState({ alarm: alarm })
    }

    ClearedStringChange = (event) => {
        var alarm = this.state.alarm;
        alarm.ClearedString = event.target.value;
        this.setState({ alarm: alarm })
    }

    NoDataClickHandler = (event) => {
        var alarm = this.state.alarm;
        alarm.NoDataAlarm = !this.state.alarm.NoDataAlarm;
        this.setState({ alarm: alarm })
    }

    NameSaveHandler = () => {
        // API call to get access level and signals list
        $.ajax({
            url: '/api/AlarmSetupAPI/UpdateAlarm/',
            type: 'post',
            data: JSON.stringify(this.state.alarm),
            contentType: "application/json; charset=utf-8",
            success: function (response) {
                console.log(response);
                this.setState({ carouselstate: 0 });
            }.bind(this)
        });
    }


    UpperThresholdChange = (event) => {
        var alarm = this.state.alarm;
        alarm.UpperThreshold = event.target.value;
        this.setState({ alarm: alarm })
    }

    LowerThresholdChange = (event) => {
        var alarm = this.state.alarm;
        alarm.LowerThreshold = event.target.value;
        this.setState({ alarm: alarm })
    }

    SetTimeChange = (event) => {
        var alarm = this.state.alarm;
        alarm.SetTime = event.target.value;
        this.setState({ alarm: alarm })
    }

    ResetTimeChange = (event) => {
        var alarm = this.state.alarm;
        alarm.ResetTime = event.target.value;
        this.setState({ alarm: alarm })
    }

    ThresholdSaveHandler = () => {
        // API call to get access level and signals list
        $.ajax({
            url: '/api/AlarmSetupAPI/UpdateThresholds/',
            type: 'post',
            data: JSON.stringify(this.state.alarm),
            contentType: "application/json; charset=utf-8",
            success: (response) => {
                console.log(response);
                this.setState({ carouselstate: 0 })
                this.ReturnToStartHandler();
            }
        });
    }

    NameChangeHandler = (event) => {
        var signal = this.state.signal;
        signal.NiceName = event.target.value;
        this.setState({ signal: signal })
    }

    onSlideHandler = () => {
        $grid.layout();
    }

    PromptDeleteConfirmation = () => {
        this.setState({ promptdeleteconfirmation: !this.state.promptdeleteconfirmation })
    }

    DeleteConfirmation = () => {
        // API call to get access level and signals list
        console.log(this.state.alarm.AlarmID)
        $.ajax({
            url: '/api/AlarmSetupAPI/DeleteAlarm/',
            type: 'post',
            data: JSON.stringify({
                AlarmID: this.state.alarm.AlarmID
            }),
            contentType: "application/json; charset=utf-8",
            success: function () {
                this.setState({ promptdeleteconfirmation: false });
                this.props.DeleteAlarm(this.state.alarm.AlarmID);
                setTimeout(function () {
                    $grid.reloadItems();
                    $grid.layout();

                }.bind(this), 10);
            }.bind(this)
        });
    }

    ReturnToStartHandler = () => {
        this.setState({ restoring: true })
        $.ajax({
            url: '/api/AlarmSetupAPI/GetAlarm/',
            type: 'get',
            data: {
                AlarmID: this.state.alarm.AlarmID
            },
            success: function (response) {
                response = JSON.parse(response);
                console.log(response);
                this.setState({
                    alarm: response,
                    carouselstate: 0,
                    restoring: false
                })

                setTimeout(function () {
                    $grid.reloadItems();
                    $grid.layout();

                }.bind(this), 60);
            }.bind(this)
        })
    }

    render() {
        var signals = [];
        var i;
        for (i = 0; i < this.props.signals.length; i++) {
            signals.push(<option value={this.props.signals[i].SignalID}>{this.props.signals[i].NiceName}</option>)
        }

        return (<div className="grid-item col-md-4 p-0" id={this.state.alarm.AlarmID + "-Card"}>
            <div className="m-2 shadow p-0 card" >
                <div className="card-header" style={{ 'height': '64px' }}>
                    <div className="align-items-center" style={{ position: 'absolute' }}>{this.state.alarm.MessageString} {this.props.editaccess && this.state.carouselstate != 1 ? <div className="btn btn-outline-primary p-0 ps-1 pe-1" onClick={this.NameEditHandler}><i className="fad fa-edit fa-swap-opacity"></i></div> : ""}</div>
                </div>
                <div className="card-body p-3">
                    <Carousel controls={false} indicators={false} interval={null} activeIndex={this.state.carouselstate} onSlide={this.onSlideHandler} onSlid={this.onSlideHandler}>
                        <Carousel.Item style={{ background: 'white' }}>
                            <div className="row m-1 align-items-center">
                                Signal: {this.state.alarm.Signal.NiceName}
                            </div>
                            <hr />
                            <div className="row m-1 align-items-center">
                                <HelpTooltip className="col-md-auto" placement="bottom" helptext="Update settings for the alarm trigger thresholds and timers for triggering and resetting alarms." />
                                <Button className="col" onClick={() => this.props.OpenSettings(this.state.alarm.AlarmID)}>Alarm Settings</Button>
                            </div>
                            <hr />
                            <div className="row m-1 align-items-center" style={{ float: 'center' }}>
                                <HelpTooltip className="col-md-auto" placement="bottom" helptext="Allow users to be notified when an alarm is triggered or reset." />
                                <Button className="col" onClick={this.NotificationSetupHandler}>Notification Setup</Button>
                            </div>
                            <hr />
                            <div className="row m-1 align-items-center">
                                {this.props.editaccess ? <OverlayTrigger
                                    placement="top"
                                    show={this.state.promptdeleteconfirmation}
                                    overlay={
                                        <Tooltip>
                                            <div className="align-items-center">Confirm delete: <div className="btn btn-danger" onClick={this.DeleteConfirmation}><i className="fad fa-trash"></i></div></div>
                                        </Tooltip>
                                    }>
                                    <div className="btn btn-outline-danger ms-auto" onClick={this.PromptDeleteConfirmation}><i className="fad fa-trash"></i></div>
                                </OverlayTrigger> : ""}
                            </div>
                        </Carousel.Item>
                        <Carousel.Item style={{ background: 'white' }}>
                            <div className="btn btn-outline-primary" onClick={this.ReturnToStartHandler}> <i className="fad fa-chevron-double-left"></i></div>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>Alarm Triggered Message: </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={this.state.alarm.MessageString}
                                    onChange={this.MessageStringChange}>
                                </FormControl>
                            </InputGroup>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>Alarm Cleared Message:   </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={this.state.alarm.ClearedString}
                                    onChange={this.ClearedStringChange}>
                                </FormControl>
                            </InputGroup>
                            <div className="btn btn-primary" onClick={this.NameSaveHandler}>Save</div>
                        </Carousel.Item>
                        <Carousel.Item style={{ background: 'white' }}>
                            <div className="mb-2 row ms-0">
                                <div className="btn col-md-auto btn-outline-primary float-left" onClick={this.ReturnToStartHandler}> <i className="fad fa-chevron-double-left"></i></div>
                                <div className="input-group col justify-content-end" onClick={this.NoDataClickHandler}>
                                    <div className="input-group-text">
                                        <input className="form-check-input" defaultChecked={this.state.alarm.NoDataAlarm} type="checkbox" aria-label="No Data Alarm" />
                                    </div>
                                    <span class="input-group-text">No Data Alarm</span>
                                </div>
                            </div>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>
                                    <HelpTooltip placement="top" helptext="The amount of time the signal value needs to be outside the thresholds before the alarm is triggered." />
                                    &nbsp;
                                    Set Time (s):
                                </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={this.state.alarm.SetTime}
                                    type="number"
                                    onChange={this.SetTimeChange}>
                                </FormControl>
                            </InputGroup>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>
                                    <HelpTooltip placement="top" helptext="The amount of time the signal value needs to be within the thresholds before an active alarm is reset." />
                                    &nbsp;
                                    Reset Time (s):
                                </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={this.state.alarm.ResetTime}
                                    type="number"
                                    onChange={this.ResetTimeChange}
                                    disabled={this.state.alarm.NoDataAlarm}>
                                </FormControl>
                            </InputGroup>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>Upper threshold: </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={this.state.alarm.UpperThreshold}
                                    type="number"
                                    onChange={this.UpperThresholdChange}
                                    disabled={this.state.alarm.NoDataAlarm}>
                                </FormControl>
                            </InputGroup>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>Lower threshold: </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={this.state.alarm.LowerThreshold}
                                    type="number"
                                    onChange={this.LowerThresholdChange}
                                    disabled={this.state.alarm.NoDataAlarm}>
                                </FormControl>
                            </InputGroup>
                            <div className="btn btn-primary" onClick={this.ThresholdSaveHandler}>Save</div>
                        </Carousel.Item>
                        <Carousel.Item style={{ background: 'white' }}>
                            <div className="mb-1">
                                <div className="btn btn-outline-primary float-left" onClick={this.ReturnToStartHandler}> <i className="fad fa-chevron-double-left"></i></div>
                            </div>
                            <div className="text-center">Set Email Users</div>
                            <div className="input-group">
                                <select className="m-1 form-control" onChange={this.AddUserforSetEmail} placeholder="Set Email" value="Set Email">
                                    <option value="null"></option>
                                    {this.state.notificationsetup.SetEmailUserList}
                                </select>
                            </div>
                            {this.state.notificationsetup.SetEmailUsers}
                            <hr />
                            <div className="text-center">Set SMS Users</div>
                            <div className="input-group">
                                <select className="m-1 form-control" onChange={this.AddUserforSetSMS} value="Add User" placeholder="Set SMS">
                                    <option value="null"></option>
                                    {this.state.notificationsetup.SetSMSUserList}
                                </select>
                            </div>
                            {this.state.notificationsetup.SetSMSUsers}
                            <hr />
                            <div className="text-center">Reset Email Users</div>
                            <div className="input-group">
                                <select className="m-1 form-control" onChange={this.AddUserforResetEmail} placeholder="Reset Email">
                                    <option value="null"></option>
                                    {this.state.notificationsetup.ResetEmailUserList}
                                </select>
                            </div>
                            {this.state.notificationsetup.ResetEmailUsers}
                            <hr />
                            <div className="text-center">Reset SMS Users</div>
                            <div className="input-group">
                                <select className="m-1 form-control" onChange={this.AddUserforResetSMS} placeholder="Reset SMS">
                                    <option value="null"></option>
                                    {this.state.notificationsetup.ResetSMSUserList}
                                </select>
                            </div>
                            {this.state.notificationsetup.ResetSMSUsers}
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
        </div >)
    }
}

function AlarmsList(props) {
    const [signals] = useState(props.signals)
    const [alarms, setAlarms] = useState(props.alarms)
    const [newalarmname, setNewAlarmName] = useState("")
    const [signalid, setSignalID] = useState("")
    const [renderalarms, setrenderalarms] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [selected, setSelected] = useState(false)

    const handleClose = () => setShowModal(false);

    const RenderAlarms = (alarmsInfo) => {
        let newrenderalarms = [];
        for (let i = 0; i < alarmsInfo.length; i++) {
            newrenderalarms.push(
                <Alarm
                    key={alarmsInfo[i].AlarmID + "cardkey"}
                    alarm={alarmsInfo[i]}
                    signals={signals}
                    DeleteAlarm={DeleteAlarm}
                    alarms={alarmsInfo}
                    editaccess={true}
                    OpenSettings={props.OpenSettings} />
            );
        }
        setrenderalarms(newrenderalarms);

        // Reshuffle alarm cards
        setTimeout(function () {
            $grid.reloadItems();
            $grid.layout();
        }, 100);
    }

    useEffect(() => {
        var masonryOptions = {
            itemSelector: '.grid-item',
            animate: true,
            stagger: 50,
            percentPosition: true
        };

        var elem = document.querySelector('#cards');
        $grid = new Masonry(elem, masonryOptions);

        $grid.reloadItems();
        $grid.layout();
    }, []);

    useEffect(() => {
        setAlarms(props.alarms);
    }, [props.alarms]);

    useEffect(() => {
        RenderAlarms(alarms);

        setTimeout(function () {
            $grid.reloadItems();
            $grid.layout();
        }, 100);
    }, [signals, alarms]);


    function AddAlarm() {
        if (!selected) {
            setShowModal(true);
            return;
        }
        var newAlarm = {
            MessageString: newalarmname,
            SignalID: signalid
        }
        setSelected(!selected);
        // API call to get access level and signals list
        $.ajax({
            url: '/api/AlarmSetupAPI/CreateAlarm/',
            type: 'post',
            data: JSON.stringify(newAlarm),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
                console.log(response);
                var newalarms = alarms;
                var alarmReturn = response;
                newalarms.push(alarmReturn);
                setAlarms(newalarms);
                var newrenderalarms = [];
                for (var i = 0; i < newalarms.length; i++) {
                    newrenderalarms.push(<Alarm key={newalarms[i].AlarmID + "cardkey"} alarm={newalarms[i]} signals={signals} DeleteAlarm={DeleteAlarm} OpenSettings={props.OpenSettings} alarms={newalarms} editaccess={true} />);
                }
                setrenderalarms(newrenderalarms);
                setNewAlarmName("");
                setSignalID("");
                props.OpenSettings(alarmReturn.AlarmID);
                setTimeout(function () {
                    $grid.reloadItems();
                    $grid.layout();

                }.bind(this), 50);
            }.bind(this)
        });
    }

    function DeleteAlarm(AlarmID) {
        console.log("Deleting " + AlarmID);
        //let oldalarms = [...alarms];
        let newalarms = alarms.filter(obj => obj.AlarmID !== AlarmID);
        setAlarms(newalarms);
    }

    function AlarmNamePressHandler(event) {
        if (event.key == "Enter") {
            AddAlarm();
        }
    }

    function AlarmNameChangeHandler(event) {
        setNewAlarmName(event.target.value);
    }

    function SignalIDChangeHandler(event) {
        if (event.target.value != null) {
            setSelected(true)
        }
        setSignalID(event.target.value);
    }

    var signalslist = [];
    var i;
    for (i = 0; i < props.signals.length; i++) {
        signalslist.push(<option key={props.signals[i].SignalID} value={props.signals[i].SignalID}>{props.signals[i].NiceName}</option>);
    }
    return (<div>
        {renderalarms}
        <div className="grid-item col-md-4 p-0">
            <div className="m-2 shadow p-0 card" >
                <div className="card-body p-3 text-center">
                    <label>Create Alarm</label>
                    <div className="input-group">
                        <input type="text" className="form-control" value={newalarmname} onChange={AlarmNameChangeHandler} onKeyPress={AlarmNamePressHandler} placeholder="Alarm name" aria-label="Alarm name" aria-describedby="basic-addon2" />
                    </div>
                    <br></br>

                    <div className="input-group">
                        <select className="form-select" onChange={SignalIDChangeHandler} value={signalid}>
                            <option value="null"></option>
                            {signalslist}
                        </select>
                        <a className="btn btn-outline-primary" onClick={AddAlarm} ><i className="far fa-plus-circle"></i></a>

                        <Modal show={showModal} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Error</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Please select a signal.</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </div>
                </div>
            </div>
        </div>
    </div>)
}


export function AlarmSetup(props) {
    const { id } = useParams();
    const [alarms, setAlarms] = useState([])
    const [signals, setSignals] = useState([])
    const [frequency, setFrequency] = useState(1800)
    const [showSettings, setShowSettings] = useState(false)
    const [currentAlarmID, setCurrentAlarmID] = useState(0)

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id), [id]);

    useEffect(() => {
        $.ajax({
            url: '/api/AlarmSetupAPI/GetAlarms/',
            type: 'get',
            data: {
                SiteGUID: id
            },
            success: function (response) {
                response = JSON.parse(response);
                console.log(response);

                setAlarms(response.Alarms);
                setSignals(response.Signals);

                setTimeout(function () {
                    $grid.layout();

                }, 100);
            }.bind(this)
        });

    }, [id]);

    function OpenSettings(AlarmID) {
        setCurrentAlarmID(AlarmID);
        setShowSettings(true);
    }

    return (
        props.auth !== undefined ?
            props.auth > 0 ?
                <div>
                    <div id="cards" className="container-fluid p-0">
                        <div className="grid-item col-1"></div>
                        <AlarmsList SiteGUID={id} signals={signals} alarms={alarms} OpenSettings={OpenSettings} />
                    </div>
                    <AlarmSettingsModal alarmID={currentAlarmID} display={showSettings} closeModal={() => setShowSettings(false)}  />
                </div>
                :
                <NoAccessPage />
            : <></>);
};

