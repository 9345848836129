import React, { useEffect, useState, useContext } from 'react';
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import $ from 'jquery';
import { Fade, Button } from 'react-bootstrap'
import BarChart from './BarChart'
import { AuthContext } from '../Analytics';

const SortableItem = sortableElement(({ index, value, onremove, start, end, i, valueslabel }) => {
    return <BarChart
        index={i}
        barID={value}
        onremove={onremove}
        start={start}
        end={end}
        valueslabel={valueslabel}
    />
});

const SortableContainer = sortableContainer(({ children }) => {
    return <div>{children}</div>;
});

function BarChartsContainer(props) {
    const [barCharts, setBarCharts] = useState([]);
    const [ready, setReady] = useState(false);
    const { auth } = useContext(AuthContext);

    useEffect(() => {
        // Bar Charts
        var url = '/api/AnalyticsAPI/GetBarCharts/';
        $.ajax({
            url: url,
            type: 'get',
            data: { SiteGUID: props.siteGUID },
            error: function () {
                console.log("Get bar charts api call failed")
            },
            success: function (response) {
                var data = JSON.parse(response)
                var newBarCharts = []
                data.forEach(d => newBarCharts.push({ id: d.InsightBarChartID, idx: d.Order }));
                newBarCharts.sort((a, b) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0));
                setBarCharts(newBarCharts);
                setReady(true);
            }
        });
    }, [props.siteGUID])

    const onSortStart = () => (document.body.style.cursor = 'grabbing')

    const onSortEnd = ({ oldIndex, newIndex }) => {
        document.body.style.cursor = 'default';
        var newArray = arrayMoveImmutable(barCharts, oldIndex, newIndex);
        setBarCharts(newArray)
        var url = '/api/AnalyticsAPI/SetBarChartOrder/';
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newArray.map(i => i.id))
        })
    };

    const addBarChart= () => {
        var url = '/api/AnalyticsAPI/AddBarChart/';
        $.ajax({
            url: url,
            type: 'get',
            data: { SiteGUID: props.siteGUID },
            error: function () {
                console.log("Add bar chart api call failed")
            },
            success: function (response) {
                var data = JSON.parse(response)
                var newBarCharts = []
                data.forEach(d => newBarCharts.push({ id: d.InsightBarChartID, idx: d.Order }));
                newBarCharts.sort((a, b) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0));
                setBarCharts(newBarCharts);
            }
        });
    }

    const removeBarChart= (barChartID) => {
        var newBarCharts = JSON.parse(JSON.stringify(barCharts));
        newBarCharts.splice(newBarCharts.findIndex(lc => lc.id == barChartID), 1)
        setBarCharts(newBarCharts);
    }

    return (
        <>
            <Fade appear={true} in={ready}> 
                <div>
                    {auth == 2 ?
                        <Button className="btn-primary mb-3" onClick={addBarChart}>
                            Add bar chart
                        </Button>
                        :
                        <></>
                    }
                    <SortableContainer onSortEnd={onSortEnd} onSortStart={onSortStart} useDragHandle>
                        <div id="insightBarCharts" class="w-100">
                            {barCharts.map((value, index) =>
                                (
                                    <SortableItem
                                        key={`BarChart-${value.id}`}
                                        index={index}
                                        value={value.id}
                                        onremove={removeBarChart}
                                        start={props.start}
                                        end={props.end}
                                        i={index}
                                        />
                                        ))}
                        </div>
                    </SortableContainer>
                </div>
            </Fade>
        </>)
}

export default BarChartsContainer;