import React, { useEffect, useState, useContext } from 'react';
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import $ from 'jquery';
import { Fade, Button } from 'react-bootstrap';
import Widget from './Widget'
import { AuthContext } from '../Analytics';

const SortableItem = sortableElement(({ index, value, onremove, start, end, i, name }) => {
    return <Widget
        index={i}
        widgetID={value}
        widgetName={name}
        onremove={onremove}
        start={start}
        end={end}
    />
});

const SortableContainer = sortableContainer(({ children }) => {
    return <div>{children}</div>;
});

function WidgetsContainer(props) {
    const [widgets, setWidgets] = useState([]);
    const [ready, setReady] = useState(false);
    const { auth } = useContext(AuthContext);

    useEffect(() => {
        var url = '/api/AnalyticsAPI/GetWidgets/';
        $.ajax({
            url: url,
            type: 'get',
            data: { SiteGUID: props.siteGUID },
            error: function () {
                console.log("Get widgets api call failed")
            },
            success: function (response) {
                var data = JSON.parse(response)
                var newWidgets = []
                data.forEach(d => newWidgets.push({ id: d.InsightWidgetID, idx: d.Order, name: d.InsightWidgetName}));
                newWidgets.sort((a, b) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0));
                setWidgets(newWidgets);
                setReady(true);
            }
        });
    }, [props.siteGUID])

    const onSortStart = () => (document.body.style.cursor = 'grabbing')

    const onSortEnd = ({ oldIndex, newIndex }) => {
        document.body.style.cursor = 'default';
        var newArray = arrayMoveImmutable(widgets, oldIndex, newIndex);
        setWidgets(newArray)
        var url = '/api/AnalyticsAPI/SetWidgetOrder/';
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newArray.map(i => i.id))
        })
    };

    const addWidget = () => {
        var url = '/api/AnalyticsAPI/AddWidget/';
        $.ajax({
            url: url,
            type: 'get',
            data: { SiteGUID: props.siteGUID },
            error: function () {
                console.log("Add widget api call failed")
            },
            success: function (response) {
                var data = JSON.parse(response)
                var newWidgets = []
                data.forEach(d => newWidgets.push({ id: d.InsightWidgetID, idx: d.Order, name: d.InsightWidgetName}));
                newWidgets.sort((a, b) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0));
                setWidgets(newWidgets);
            }
        });
    }

    const removeWidget = (widgetID) => {
        var newWidgets = JSON.parse(JSON.stringify(widgets));
        newWidgets.splice(newWidgets.findIndex(lc => lc.id == widgetID), 1)
        setWidgets(newWidgets);
    }

    return (
        <Fade appear={true} in={ready}>
            <div>
                {auth == 2 ?
                    <Button className="btn-primary" onClick={addWidget}>
                        Add widget
                    </Button>
                    :
                    <></>
                }
                <SortableContainer
                    onSortEnd={onSortEnd}
                    onSortStart={onSortStart}
                    axis={"xy"}
                    useDragHandle
                >
                    <div style={{ textAlign: "center" }}>
                        {widgets.map((value, index) =>
                            (
                                <SortableItem
                                    key={`Widget-${value.id}`}
                                    index={index}
                                    value={value.id}
                                    onremove={removeWidget}
                                    start={props.start}
                                    end={props.end}
                                    i={index}
                                    name={value.name}
                                />
                            ))}
                    </div>
                </SortableContainer>
            </div>
        </Fade>
        )
}



export default WidgetsContainer;