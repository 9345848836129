import { useState, useEffect } from "react";
import {
    Button,
    ButtonGroup,
    Form,
    InputGroup,
    Modal,
    OverlayTrigger,
    Spinner,
    Tab,
    Tabs,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Row,
    Col,
    Alert,
} from "react-bootstrap";
import {
    DeviceModel,
    SignalModel,
    SensorTypesModel,
    SiteEquipmentModel,
    EdgeSignalModel,
    EdgeAirCleaningModel,
    EdgeTwincatModel,
    EdgeEthernetModel,
    EdgeLoraModel,
    EdgeModbusModel,
    TotaliserModel,
    EdgeTotaliserModel,
} from "./types";
import { useSelector } from "react-redux";
import MaintenanceAlarmSetup from "../Maintenance/MaintenanceAlarmSetup";
import PermissionsSettings from "../PermissionsSettings";
import { AppDispatch, RootState, useAppDispatch } from "../../redux";
import {
    EthernetSignal,
    LoraSignal,
    ModbusSignal,
    TwincatSignal,
    addEthernet,
    addLora,
    addModbus,
    addTwincat,
    deleteLora,
    deleteModbus,
    deleteTwincat,
    deleteEthernet,
    fetchEdgeSignals,
    EdgeSignalsModel,
} from "../../redux/EdgeSignalReducer";
import { toast, Bounce } from 'react-toastify';
import { edges } from "../Configurator/example-configuration";

const PROTOCOL_NAME_MAP: { [key: number]: string } = {
    0: "Twincat",
    1: "Modbus",
    2: "LoRa",
    4: "Ethernet",
};

// Convert keys to optional. For example: if SignalID does not exist and needs to be created
// Does NOT mean attribute is nullable in db models. Solely exist on frontend to account for create/edit new signals
type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

type basicSettingsDataType = {
    Signal: Optional<Pick<EdgeSignalModel, "SignalID" | "SolenoidID" | "Protocol">, "SignalID" | "SolenoidID">;
    Solenoid?: Optional<EdgeAirCleaningModel, "SolenoidID" | "FilterSignalID">;
    Protocol: Optional<EdgeTwincatModel | EdgeEthernetModel | EdgeLoraModel | EdgeModbusModel, "SignalID">;
    SignalType: number;
    DeviceID?: string;
    TCTotaliser?: Optional<EdgeTotaliserModel, "SignalID">;
    ModbusPulseCounter?: Optional<TotaliserModel, "SignalID">;
};

interface SettingsModalType {
    show: boolean;
    setShow: (showSettingsModal: boolean) => void;
    signal: SignalModel | undefined;
    siteGUID: string;
    protocolID: number;
    devices: DeviceModel[];
    signals: SignalModel[];
}
export const SettingsModal = ({ show, setShow, signal, siteGUID, protocolID, devices, signals }: SettingsModalType) => {
    const dispatch: AppDispatch = useAppDispatch();

    const [saveLoading, setSaveLoading] = useState(false);
    const [delLoading, setDelLoading] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const [activeKey, setActiveKey] = useState("basic-setup");

    const [sensorTypes, setSensorTypes] = useState([]);
    const [sensorTypesLoading, setSensorTypesLoading] = useState(false);

    // #region Basic setttings states
    const [newSignalNiceName, setNewSignalNiceName] = useState<string | undefined>();
    const [basicSettingsData, setBasicSettingsData] = useState<basicSettingsDataType | undefined>();
    const [isCollectBasicSettingsDataComplete, setIsCollectBasicSettingsDataComplete] = useState(false);
    // #endregion
    // Sensor settings states
    const [sensorNiceName, setSensorNiceName] = useState("");
    const [sensorSignalID, setSensorSignalID] = useState<string | undefined>();
    const [sensorPosition, setSensorPosition] = useState<number | undefined>();
    const [sensorType, setSensorType] = useState<number>(0);
    const [equipment, setEquipment] = useState<string | undefined>();
    // Adv settings states
    const [filterSignalID, setFilterSignalID] = useState<string | undefined>();
    const [lowerFilterThreshold, setLowerFilterThreshold] = useState<number | undefined>();
    const [upperFilterThreshold, setUpperFilterThreshold] = useState<number | undefined>();

    const getSensorTypes = () => {
        setSensorTypesLoading(true);
        fetch("/api/SignalSetupAPI/GetSensorTypes")
            .then((response) => response.json())
            .then((data) => {
                setSensorTypes(data);
                setSensorTypesLoading(false);
            });
    };

    const resetModalState = () => {
        setSaveLoading(false);
        setIsCollectBasicSettingsDataComplete(false);
        setBasicSettingsData(undefined);
        setShow(false);
    };

    // Triggers useEffect which calls the APIs
    const _handleSaveSignal = () => {
        setSaveLoading(true);
    };

    const _handleDeleteSignal = () => {
        if (signal) {
            setDelLoading(true);
            fetch("/api/SignalSetupAPI/DeleteSignal/?signalID=" + signal.SignalID)
                .then(() => {
                    setDelLoading(false);
                    switch (protocolID) {
                        case 0:
                            dispatch(deleteTwincat(signal.SignalID));
                            break;
                        case 1:
                            dispatch(deleteModbus(signal.SignalID));
                            break;
                        case 2:
                            dispatch(deleteLora(signal.SignalID));
                            break;
                        case 4:
                            dispatch(deleteEthernet(signal.SignalID));
                            break;
                    }
                    resetModalState();
                    setShowDelete(false);
                });
        }
    };

    useEffect(() => {
        getSensorTypes();
    }, []);

    useEffect(() => {
        if (show && signal) {
            // Populate adv settings
            setFilterSignalID(signal.FilterSignalID);
            setLowerFilterThreshold(signal.LowerFilterThreshold);
            setUpperFilterThreshold(signal.UpperFilterThreshold);

            // Populate sensor settings
            if (signal.SensorID) {
                fetch(`/api/SignalSetupAPI/GetSensor/?sensorID=${signal.SensorID}`)
                    .then((res) => res.json())
                    .then((sensor) => {
                        setSensorNiceName(sensor.NiceName);
                        setSensorSignalID(sensor.SignalID);
                        setSensorPosition(sensor.PositionID);
                        setSensorType(sensor.SensorTypeID);
                        setEquipment(sensor.EquipmentID);
                    });
            } else {
                setSensorNiceName("");
                setSensorSignalID(undefined);
                setSensorPosition(undefined);
                setSensorType(0);
                setEquipment(undefined);
            }
        } else {
            setShowDelete(false);
            setFilterSignalID(undefined);
            setLowerFilterThreshold(undefined);
            setUpperFilterThreshold(undefined);

            setSensorNiceName("");
            setSensorSignalID(undefined);
            setSensorPosition(undefined);
            setSensorType(0);
            setEquipment(undefined);
        }
    }, [show, signal]);

    useEffect(() => {
        if (isCollectBasicSettingsDataComplete && basicSettingsData) {
            const upsertSignalEdgeData = (signalData: basicSettingsDataType | undefined) => {
                if (signalData) {
                    switch (sensorType) {
                        case 20:
                            if (protocolID === 0) {
                                const { TCTotaliser, ...edgeSignalData } = signalData;
                                return fetch("/api/SignalSetupAPI/UpsertTwincatTotaliser", {
                                    method: "POST",
                                    headers: { "Content-Type": "application/json" },
                                    body: JSON.stringify({ edgeSignalData, TCTotaliser }),
                                });
                            } else if (protocolID === 1) {
                                const { ModbusPulseCounter, ...edgeSignalData } = signalData;
                                return fetch("/api/SignalSetupAPI/UpsertModbusPulseCounter", {
                                    method: "POST",
                                    headers: { "Content-Type": "application/json" },
                                    body: JSON.stringify({ edgeSignalData, ModbusPulseCounter }),
                                });
                            } else {
                                return fetch("/api/SignalSetupAPI/UpsertEdgeSignal", {
                                    method: "POST",
                                    headers: { "Content-Type": "application/json" },
                                    body: JSON.stringify(signalData),
                                });
                            }
                        case 21:
                            return fetch("/api/SignalSetupAPI/UpsertEdgeSolenoid", {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify(signalData),
                            });
                        default:
                            return fetch("/api/SignalSetupAPI/UpsertEdgeSignal", {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify(signalData),
                            });
                    }
                }
            };

            const upsertSensor = (newSignalID: string | undefined = undefined) => {
                if (signal) {
                    return fetch("/api/SignalSetupAPI/SaveSensor", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            SensorID: signal.SensorID,
                            SensorTypeID: sensorType === 0 ? null : sensorType,
                            EquipmentID: equipment,
                            PositionID: sensorPosition,
                            SignalID: sensorSignalID,
                            NiceName: sensorNiceName,
                        }),
                    });
                }
            };

            const upsertCloudSignal = (signalID: string | undefined = undefined) => {
                return fetch("/api/SignalSetupAPI/UpsertCloudSignal", {
                    method: "post",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        SignalID: signalID,
                        NiceName: newSignalNiceName,
                        FilterSignalID: filterSignalID,
                        LowerFilterThreshold: lowerFilterThreshold,
                        UpperFilterThreshold: upperFilterThreshold,
                    }),
                });
            };

            if (signal) {
                Promise.allSettled([
                    upsertSensor(signal.SignalID),
                    upsertSignalEdgeData(basicSettingsData),
                    upsertCloudSignal(signal.SignalID)
                ])
                    .then((res) => {
                        console.log(res);
                        dispatch(fetchEdgeSignals(siteGUID));
                        resetModalState();
                    })
                    .catch((err) => console.error(err));
            } else {
                fetch("/api/SignalSetupAPI/SubmitNewSignal", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        SiteGUID: siteGUID,
                        NiceName: newSignalNiceName,
                    }),
                })
                    .then((response) => response.json())
                    .then((data: SignalModel) => {
                        const newSignalData: basicSettingsDataType = {
                            ...basicSettingsData,
                            Signal: {
                                ...basicSettingsData?.Signal,
                                SignalID: data.SignalID,
                            },
                            Protocol: {
                                ...basicSettingsData?.Protocol,
                                SignalID: data.SignalID,
                            },
                        };
                        if (newSignalData?.Solenoid) {
                            newSignalData.Solenoid = {
                                ...newSignalData?.Solenoid,
                                SolenoidID: data.SignalID,
                            };
                        }
                        if (newSignalData?.TCTotaliser) {
                            newSignalData.TCTotaliser = {
                                ...newSignalData?.TCTotaliser,
                                SignalID: data.SignalID,
                            };
                        }
                        if (newSignalData?.ModbusPulseCounter) {
                            newSignalData.ModbusPulseCounter = {
                                ...newSignalData?.ModbusPulseCounter,
                                SignalID: data.SignalID,
                            };
                        }

                        Promise.allSettled([
                            upsertSensor(data.SignalID),
                            upsertSignalEdgeData(newSignalData),
                            upsertCloudSignal(data.SignalID),
                        ])
                            .then((res) => {
                                console.log(res);
                                dispatch(fetchEdgeSignals(siteGUID));
                                resetModalState();
                            })
                            .catch((err) => console.error(err));
                    })
                    .catch((err) => console.error(err));
            }
        }
    }, [isCollectBasicSettingsDataComplete]);

    return (
        <Modal show={show} onHide={() => setShow(false)} centered size="lg">
            <Modal.Header closeButton>
                <div className="container p-0 d-flex align-items-center">
                    <div className="me-auto">
                        <h5 className="modal-title">Signal Setup</h5>
                    </div>
                    <PermissionsSettings accessControlID={signal?.AccessControlID} siteGUID={siteGUID} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    activeKey={activeKey}
                    onSelect={k => k !== null ? setActiveKey(k) : setActiveKey("basic-setup")}
                    id="settings-modal-tab"
                    className="mb-3 justify-content-center"
                    variant="tabs"
                >
                    <Tab eventKey="basic-setup" title="Basic Setup">
                        <BasicSetupTabContent
                            signal={signal}
                            signals={signals}
                            protocolID={protocolID}
                            devices={devices}
                            sensorTypes={sensorTypes}
                            sensorTypesLoading={sensorTypesLoading}
                            handleManageSensor={() => setActiveKey("sensor-settings")}
                            sensorType={sensorType}
                            setSensorType={setSensorType}
                            saveLoading={saveLoading}
                            setBasicSettingsData={setBasicSettingsData}
                            setIsCollectBasicSettingsDataComplete={setIsCollectBasicSettingsDataComplete}
                            setNewSignalNiceName={setNewSignalNiceName}
                        />
                    </Tab>
                    <Tab eventKey="sensor-settings" title="Sensor Settings" className="bg-white">
                        <SensorSettingsTabContent
                            sensorID={signal ? signal.SensorID : undefined}
                            signals={signals}
                            siteGUID={siteGUID}
                            sensorNiceName={sensorNiceName}
                            setSensorNiceName={setSensorNiceName}
                            sensorPosition={sensorPosition}
                            setSensorPosition={setSensorPosition}
                            sensorSignalID={sensorSignalID}
                            setSensorSignalID={setSensorSignalID}
                            sensorType={sensorType}
                            setSensorType={setSensorType}
                            sensorTypes={sensorTypes}
                            equipment={equipment}
                            setEquipment={setEquipment}
                        />
                    </Tab>
                    <Tab eventKey="advanced-settings" title="Advanced Settings" className="bg-white">
                        <AdvancedSettingsTabContent
                            signals={signals}
                            filterSignalID={filterSignalID}
                            setFilterSignalID={setFilterSignalID}
                            lowerFilterThreshold={lowerFilterThreshold}
                            setLowerFilterThreshold={setLowerFilterThreshold}
                            upperFilterThreshold={upperFilterThreshold}
                            setUpperFilterThreshold={setUpperFilterThreshold}
                        />
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <div className="container p-0">
                    <Button variant="secondary" className="float-start" onClick={() => setShow(false)}>
                        Close
                    </Button>
                    <ButtonGroup className="float-end">
                        <Button variant="primary" onClick={_handleSaveSignal}>
                            {saveLoading ? 
                                <img style={{ height: "24px", width: "24px" }} src="/lib/ajax-loader.gif" alt="loading" />
                                : "Save"
                            }
                        </Button>

                        {signal !== undefined && (
                            <OverlayTrigger
                                placement="right"
                                show={showDelete}
                                overlay={
                                    <Tooltip>
                                        <div className="align-items-center">
                                            Confirm delete:{" "}
                                            <Button variant="danger" onClick={_handleDeleteSignal}>
                                                {delLoading ? (
                                                    <img
                                                        style={{ height: "24px", width: "24px" }}
                                                        src="/lib/ajax-loader.gif"
                                                        alt="loading"
                                                    />
                                                ) : (
                                                    <i className="fad fa-trash"></i>
                                                )}
                                            </Button>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <Button
                                    variant="danger"
                                    className="fad fa-trash"
                                    onClick={() => setShowDelete(!showDelete)}
                                ></Button>
                            </OverlayTrigger>
                        )}
                    </ButtonGroup>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

interface BasicSetupTabContentProps {
    signal: SignalModel | undefined;
    signals: SignalModel[];
    protocolID: number;
    devices: DeviceModel[];
    sensorTypes: any[];
    sensorTypesLoading: boolean;
    handleManageSensor: () => void;
    sensorType: number;
    setSensorType: (value: number) => void;
    saveLoading: boolean;
    setBasicSettingsData: (value: basicSettingsDataType | undefined) => void;
    setIsCollectBasicSettingsDataComplete: (value: boolean) => void;
    setNewSignalNiceName: (value: string | undefined) => void;
}
const BasicSetupTabContent = ({
    signal,
    signals,
    protocolID,
    sensorTypes,
    sensorTypesLoading,
    sensorType,
    setSensorType,
    saveLoading,
    setBasicSettingsData,
    setIsCollectBasicSettingsDataComplete,
    setNewSignalNiceName,
}: BasicSetupTabContentProps) => {
    const { devices, signals: edgeSignals } = useSelector((state: RootState) => state.edgeSignal);
    // #region Basic Setup Fields
    const [signalNiceName, setSignalNiceName] = useState<string | undefined>(undefined);
    const [signalDeviceID, setSignalDeviceID] = useState<string | undefined>(undefined);
    // #endregion
    // #region Comms Protocol Fields
    // Twincat
    const [twincatVariableName, setTwincatVariableName] = useState<string | undefined>(undefined);
    const [twincatVariableLocation, setTwincatVariableLocation] = useState<string | undefined>(undefined);
    // Modbus
    const [modbusSlaveID, setModbusSlaveID] = useState<number | undefined>(undefined);
    const [modbusAddress, setModbusAddress] = useState<number | undefined>(undefined);
    const [modbusVariableType, setModbusVariableType] = useState<string | undefined>(undefined);
    const [modbusAqualabo, setModbusAqualabo] = useState(false);
    // LoRa
    const [loraDeviceEUI, setLoraDeviceEUI] = useState<string | undefined>(undefined);
    const [loraChannelNumber, setLoraChannelNumber] = useState<number | undefined>(undefined);
    // Ethernet
    const [ethernetTag, setEthernetTag] = useState<string | undefined>(undefined);
    const [ethernetVariableType, setEthernetVariableType] = useState<number | undefined>(undefined);
    // #endregion

    // #region Air Cleaning
    // all times in seconds
    const [filterSignalID, setFilterSignalID] = useState<string | undefined>(undefined);
    const [filterUpperLimit, setFilterUpperLimit] = useState(9999);
    const [filterLowerLimit, setFilterLowerLimit] = useState(1);
    const [cleanPeriod, setCleanPeriod] = useState(15);
    const [holdTime, setHoldTime] = useState(450);
    const [noFlowCleanPeriod, setNoFlowCleanPeriod] = useState(15);
    const [noFlowHoldTime, setNoFlowHoldTime] = useState(600);

    // #endregion

    // #region Totalisers
    const [totaliserType, setTotaliserType] = useState<string | undefined>(undefined);
    const [isLoadingTotaliser, setIsLoadingTotaliser] = useState(true);
    // Pulse Counter fields
    const [totaliserInitialValue, setTotaliserInitialValue] = useState(0);
    const [totaliserOverflowCycleCount, setTotaliserOverflowCycleCount] = useState(0);
    const [totaliserVolumePerPulse, setTotaliserVolumePerPulse] = useState(0);
    const [totaliserLatestValue, setTotaliserLatestValue] = useState(0);
    const [totaliserOutputSignalID, setTotaliserOutputSignalID] = useState<string | undefined>(undefined);
    const [totaliserMaxPulseAmount, setTotaliserMaxPulseAmount] = useState(0);
    // #endregion

    const handleNameChange = (newName: string) => {
        // Auto populate protocol params based on defaults
        if (!modbusAddress && !modbusVariableType && !modbusAqualabo && !modbusSlaveID) {
            if (newName.toLowerCase().includes("tempe")) {
                setModbusAddress(83)
                setModbusVariableType("FloatingPointHolding")
                setModbusAqualabo(true)
                setSensorType(3)
                UpdateToast("Prefilled information.");
            }
            else if (newName.includes("mV")) {
                setModbusSlaveID(20)
                setModbusAddress(89)
                setModbusVariableType("FloatingPointHolding")
                setModbusAqualabo(true)
                setSensorType(11)
                UpdateToast("Prefilled information.");
            }
            else if (newName.includes("pH")) {
                setModbusSlaveID(20)
                setModbusAddress(85)
                setModbusVariableType("FloatingPointHolding")
                setModbusAqualabo(true)
                setSensorType(1)
                UpdateToast("Prefilled information.");
            }
            else if (newName.includes("TSS")) {
                setModbusSlaveID(50)
                setModbusAddress(87)
                setModbusVariableType("FloatingPointHolding")
                setModbusAqualabo(true)
                setSensorType(2)
                UpdateToast("Prefilled information.");
            }
            else if (newName.toLowerCase().includes("condu")) {
                setModbusSlaveID(30)
                setModbusAddress(85)
                setModbusVariableType("FloatingPointHolding")
                setModbusAqualabo(true)
                setSensorType(6)
                UpdateToast("Prefilled information.");
            }
            else if (newName.toLowerCase().includes("redox")) {
                setModbusSlaveID(20)
                setModbusAddress(87)
                setModbusVariableType("FloatingPointHolding")
                setModbusAqualabo(true)
                setSensorType(10)
                UpdateToast("Prefilled information.");
            }
            else if (newName.toLowerCase().includes("salin")) {
                setModbusSlaveID(30)
                setModbusAddress(87)
                setModbusVariableType("FloatingPointHolding")
                setModbusAqualabo(true)
                setSensorType(13)
                UpdateToast("Prefilled information.");
            }
            else if (newName.toLowerCase().includes("tds")) {
                setModbusSlaveID(30)
                setModbusAddress(89)
                setModbusVariableType("FloatingPointHolding")
                setModbusAqualabo(true)
                setSensorType(14)
                UpdateToast("Prefilled information.");
            }
        }
        setSignalNiceName(newName);
    }

    const UpdateToast = (message:string) => {
        toast.info(message, {
            position: "bottom-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            transition: Bounce
        });
    }

    useEffect(() => {
        if (signal) {
            setSignalNiceName(signal?.NiceName);
            setSignalDeviceID(signal?.SignalDevice?.DeviceID);
            let signalObject;
            switch (protocolID) {
                case 0:
                    signalObject = edgeSignals.TwincatSignals?.find(
                        (item) => item.Protocol.SignalID === signal.SignalID
                    );
                    setTwincatVariableName(signalObject?.Protocol.VariableName);
                    setTwincatVariableLocation(signalObject?.Protocol.VariableLocation);
                    break;
                case 1:
                    signalObject = edgeSignals.ModbusSignals?.find(
                        (item) => item.Protocol.SignalID === signal.SignalID
                    );
                    setModbusSlaveID(signalObject?.Protocol.SlaveID);
                    setModbusAddress(signalObject?.Protocol.Address);
                    setModbusVariableType(signalObject?.Protocol.VariableType);
                    setModbusAqualabo(signalObject?.Protocol.NeedMeasureCmd === 1);
                    break;
                case 2:
                    signalObject = edgeSignals?.LoraSignals?.find((item) => item.Protocol.SignalID === signal.SignalID);
                    setLoraDeviceEUI(signalObject?.Protocol.DeviceEUI);
                    setLoraChannelNumber(signalObject?.Protocol.ChannelNumber);
                    break;
                case 4:
                    signalObject = edgeSignals?.EthernetSignals?.find(
                        (item) => item.Protocol.SignalID === signal.SignalID
                    );
                    setEthernetTag(signalObject?.Protocol.Tag);
                    setEthernetVariableType(signalObject?.Protocol.VariableType);
                    break;
            }
            if (signalObject !== undefined && signalObject.EdgeSolenoid !== undefined) {
                if (signalObject.EdgeSolenoid !== null) {
                    setFilterSignalID(signalObject.EdgeSolenoid.FilterSignalID);
                    setFilterLowerLimit(signalObject.EdgeSolenoid.FilterLowerLimit);
                    setFilterUpperLimit(signalObject.EdgeSolenoid.FilterUpperLimit);
                    setCleanPeriod(signalObject.EdgeSolenoid.CleaningPeriod / 1000);
                    setHoldTime(signalObject.EdgeSolenoid.HoldTime / 1000);
                    setNoFlowCleanPeriod(signalObject.EdgeSolenoid.NoFlowCleaningPeriod / 1000);
                    setNoFlowHoldTime(signalObject.EdgeSolenoid.NoFlowHoldTime / 1000);
                }
            }
        } else {
            setSensorType(0);
            setSignalNiceName(undefined);
            setSignalDeviceID(undefined);

            setTwincatVariableName(undefined);
            setTwincatVariableLocation(undefined);

            setModbusSlaveID(undefined);
            setModbusAddress(undefined);
            setModbusVariableType(undefined);
            setModbusAqualabo(false);

            setLoraDeviceEUI(undefined);
            setLoraChannelNumber(undefined);

            setEthernetTag(undefined);
            setEthernetVariableType(undefined);
        }
    }, [signal]);

    useEffect(() => {
        if (saveLoading) {
            setNewSignalNiceName(signalNiceName);

            // Protocol
            let Protocol;
            let edgeSignal: EdgeSignalModel | undefined = {};
            switch (protocolID) {
                case 0:
                    Protocol = {
                        SignalID: signal?.SignalID,
                        VariableLocation: twincatVariableLocation,
                        VariableName: twincatVariableName,
                    };
                    edgeSignal = edgeSignals?.TwincatSignals?.find(item => item.Protocol.SignalID === signal?.SignalID)?.EdgeSignal;
                    break;
                case 1:
                    Protocol = {
                        SignalID: signal?.SignalID,
                        SlaveID: modbusSlaveID,
                        Address: modbusAddress,
                        VariableType: modbusVariableType,
                        NeedMeasureCmd: modbusAqualabo ? 1 : 0,
                    };
                    edgeSignal = edgeSignals?.ModbusSignals?.find(item => item.Protocol.SignalID === signal?.SignalID)?.EdgeSignal;
                    break;
                case 2:
                    Protocol = {
                        SignalID: signal?.SignalID,
                        DeviceEUI: loraDeviceEUI,
                        ChannelNumber: loraChannelNumber,
                    };
                    edgeSignal = edgeSignals?.LoraSignals?.find(item => item.Protocol.SignalID === signal?.SignalID)?.EdgeSignal;
                    break;
                case 4:
                    Protocol = {
                        SignalID: signal?.SignalID,
                        Tag: ethernetTag,
                        VariableType: ethernetVariableType,
                    };
                    edgeSignal = edgeSignals?.EthernetSignals?.find(item => item.Protocol.SignalID === signal?.SignalID)?.EdgeSignal;
                    break;
            }

            let newBasicSettingsData: basicSettingsDataType = {
                Signal: {
                    ...edgeSignal,
                    SignalID: signal?.SignalID,
                    Protocol: protocolID,
                },
                Protocol: { ...Protocol },
                SignalType: 0,
                DeviceID: signalDeviceID,
            };

            // Signal type
            switch (true) {
                case sensorType === 21:
                    newBasicSettingsData.Solenoid = {
                        SolenoidID: signal?.SignalID,
                        Protocol: protocolID,
                        FilterSignalID: filterSignalID,
                        FilterLowerLimit: filterLowerLimit,
                        FilterUpperLimit: filterUpperLimit,
                        CleaningPeriod: cleanPeriod * 1000,
                        HoldTime: holdTime * 1000,
                        NoFlowCleaningPeriod: noFlowCleanPeriod * 1000,
                        NoFlowHoldTime: noFlowHoldTime * 1000,
                    };
                    break;
                case protocolID === 0 && sensorType === 20:
                    newBasicSettingsData.TCTotaliser = {
                        SignalID: signal?.SignalID,
                        InitialValue: totaliserInitialValue,
                        ValuePerPulse: totaliserVolumePerPulse,
                        LatestValue: totaliserLatestValue,
                    };
                    break;
                case protocolID === 1 && sensorType === 20:
                    if (totaliserType === "Pulse Counter") {
                        newBasicSettingsData.ModbusPulseCounter = {
                            SignalID: signal?.SignalID,
                            InitValue: totaliserInitialValue,
                            VolumePerPulse: totaliserVolumePerPulse,
                            MaxPulseAmount: totaliserMaxPulseAmount,
                            OverflowCycleCount: totaliserOverflowCycleCount,
                            LatestValue: totaliserLatestValue,
                            OutputSignalID: totaliserOutputSignalID,
                        };
                    }
                    break;
            }

            setBasicSettingsData(newBasicSettingsData);
            setIsCollectBasicSettingsDataComplete(true);
        }
    }, [saveLoading]);

    useEffect(() => {
        if (sensorType === 20) {
            setIsLoadingTotaliser(true);
            if (signal) {
                switch (protocolID) {
                    case 0:
                        const edgeSignal = edgeSignals.TwincatSignals?.find(
                            (item) => item.Protocol.SignalID === signal.SignalID
                        );
                        if (edgeSignal?.EdgeTotaliser && edgeSignal.EdgeTotaliser !== null) {
                            setTotaliserInitialValue(edgeSignal.EdgeTotaliser.InitialValue);
                            setTotaliserVolumePerPulse(edgeSignal.EdgeTotaliser.ValuePerPulse);
                            setTotaliserLatestValue(edgeSignal.EdgeTotaliser.LatestValue);
                        }
                        setIsLoadingTotaliser(false);
                        break;
                    case 1:
                        fetch(`api/SignalSetupAPI/GetTotaliserSignal/?signalID=${signal.SignalID}`, {
                            method: "GET",
                            headers: { "Content-Type": "application/json" },
                        })
                            .then((res) => res.json())
                            .then((data: TotaliserModel) => {
                                if (data?.SignalID) {
                                    setTotaliserType("Pulse Counter");
                                    setTotaliserInitialValue(data.InitValue);
                                    setTotaliserOverflowCycleCount(data.OverflowCycleCount);
                                    setTotaliserVolumePerPulse(data.VolumePerPulse);
                                    setTotaliserLatestValue(data.LatestValue);
                                    setTotaliserOutputSignalID(data.OutputSignalID);
                                    setTotaliserMaxPulseAmount(data.MaxPulseAmount);
                                    setIsLoadingTotaliser(false);
                                } else {
                                    setTotaliserType("Direct");
                                    setIsLoadingTotaliser(false);
                                }
                            })
                            .catch((err) => console.log(err));
                        break;
                    default:
                }
            } else {
                setTotaliserType("Direct");
                setIsLoadingTotaliser(false);
            }
        } else {
            setTotaliserType(undefined);
            setTotaliserInitialValue(0);
            setTotaliserOverflowCycleCount(0);
            setTotaliserMaxPulseAmount(0);
            setTotaliserVolumePerPulse(0);
            setTotaliserLatestValue(0);
            setTotaliserOutputSignalID(undefined);
        }
    }, [sensorType]);

    // #region Helper functions to render fields
    const createProtocolFields = () => {
        switch (protocolID) {
            case 0:
                return (
                    <div className="d-flex justify-content-between gap-3 mb-3">
                        <InputGroup>
                            <InputGroup.Text>Variable Name</InputGroup.Text>
                            <Form.Control
                                value={twincatVariableName}
                                onChange={(e) => setTwincatVariableName(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text>Variable Location</InputGroup.Text>
                            <Form.Control
                                value={twincatVariableLocation}
                                onChange={(e) => setTwincatVariableLocation(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                );
            case 1:
                return (
                    <>
                        <div className="d-flex justify-content-between gap-3 mb-3">
                            <InputGroup>
                                <InputGroup.Text>Slave ID</InputGroup.Text>
                                <Form.Control
                                    value={modbusSlaveID}
                                    onChange={(e) => setModbusSlaveID(Number(e.target.value))}
                                />
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text>Address</InputGroup.Text>
                                <Form.Control
                                    value={modbusAddress}
                                    onChange={(e) => setModbusAddress(Number(e.target.value))}
                                />
                            </InputGroup>
                        </div>
                        <div className="d-flex justify-content-between gap-3 mb-3">
                            <InputGroup>
                                <InputGroup.Text>Variable Type</InputGroup.Text>
                                <Form.Select
                                    value={modbusVariableType}
                                    onChange={(e) => setModbusVariableType(e.target.value)}
                                >
                                    <option value={undefined}></option>
                                    <option value={"Boolean"}>Boolean</option>
                                    <option value={"FloatingPointHolding"}>Floating Point Holding</option>
                                    <option value={"FloatingPointInput"}>Floating Point Input</option>
                                    <option value={"WordHolding"}>Word Holding</option>
                                    <option value={"WordInput"}>Word Input</option>
                                    <option value={"WordInputUShort"}>Word Input Unsigned Short</option>
                                    <option value={"WordHoldingDoubleRegister"}>Word Holding Double Register</option>
                                </Form.Select>
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text>Aqualabo</InputGroup.Text>
                                <InputGroup.Checkbox
                                    checked={modbusAqualabo}
                                    aria-label="Checkbox for Aqualabo"
                                    onChange={(e: any) => setModbusAqualabo(e.target.checked)}
                                />
                            </InputGroup>
                        </div>
                    </>
                );
            case 2:
                return (
                    <div className="d-flex justify-content-between gap-3 mb-3">
                        <InputGroup>
                            <InputGroup.Text>Device EUI</InputGroup.Text>
                            <Form.Control value={loraDeviceEUI} onChange={(e) => setLoraDeviceEUI(e.target.value)} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text>Channel Number</InputGroup.Text>
                            <Form.Control
                                value={loraChannelNumber}
                                onChange={(e) => setLoraChannelNumber(Number(e.target.value))}
                            />
                        </InputGroup>
                    </div>
                );
            case 4:
                return (
                    <div className="d-flex justify-content-between gap-3 mb-3">
                        <InputGroup>
                            <InputGroup.Text>Tag</InputGroup.Text>
                            <Form.Control value={ethernetTag} onChange={(e) => setEthernetTag(e.target.value)} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text>Variable Type</InputGroup.Text>
                            <Form.Select
                                value={ethernetVariableType}
                                onChange={(e) => setEthernetVariableType(Number(e.target.value))}
                            >
                                <option value={undefined}></option>
                                <option value={0}>Boolean</option>
                                <option value={1}>Int 8</option>
                                <option value={2}>Int 16</option>
                                <option value={3}>Int 32</option>
                                <option value={4}>Int 64</option>
                                <option value={5}>Float</option>
                                <option value={6}>String</option>
                            </Form.Select>
                        </InputGroup>
                    </div>
                );
            default:
                return <></>;
        }
    };

    const createSensorRequiredFields = () => {
        switch (sensorType) {
            case 20:
                if (protocolID === 0) return createTwincatTotaliserFields();
                if (protocolID === 1) return createModbusTotaliserFields();
                return (
                    <Alert variant="info">Totaliser creation is not supported under current protocol as of now.</Alert>
                );
            case 21:
                return createAirSolenoidFields();
            default:
                return <></>;
        }
    };

    const createAirSolenoidFields = () => {
        return (
            <>
                <hr />
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>No Flow Cleaning Time (s)</InputGroup.Text>
                            <input
                                className="form-control"
                                type="number"
                                min={1}
                                value={noFlowCleanPeriod}
                                onChange={(e) => setNoFlowCleanPeriod(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Now Flow Hold Time (s)</InputGroup.Text>
                            <input
                                className="form-control"
                                type="number"
                                min={1}
                                value={noFlowHoldTime}
                                onChange={(e) => setNoFlowHoldTime(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="auto">
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Filter Signal</InputGroup.Text>
                            <Form.Select value={filterSignalID} onChange={(e) => setFilterSignalID(e.target.value)}>
                                <option value={undefined}>Select Signal</option>
                                {signals.map((signal) => (
                                    <option key={signal.SignalID} value={signal.SignalID}>{signal.NiceName}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </Col>
                    <Col md={2}>
                        <input
                            className="form-control"
                            type="number"
                            min={0}
                            value={filterLowerLimit}
                            onChange={(e) => setFilterLowerLimit(e.target.value)}
                        />
                    </Col>
                    <Col md={2}>
                        <input
                            className="form-control"
                            type="number"
                            min={filterLowerLimit}
                            max={filterUpperLimit}
                            value={filterUpperLimit}
                            onChange={(e) => setFilterUpperLimit(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Cleaning Time (s)</InputGroup.Text>
                            <input
                                className="form-control"
                                type="number"
                                min={1}
                                value={cleanPeriod}
                                onChange={(e) => setCleanPeriod(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Hold Time (s)</InputGroup.Text>
                            <input
                                className="form-control"
                                type="number"
                                min={1}
                                value={holdTime}
                                onChange={(e) => setHoldTime(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </>
        );
    };

    const createTwincatTotaliserFields = () => {
        return isLoadingTotaliser ? (
            <div className="d-flex w-100 justify-content-center">
                <Spinner animation="border" role="status" />
            </div>
        ) : (
            <>
                <hr />
                <div className="d-flex justify-content-between gap-3 mb-3">
                    <InputGroup>
                        <InputGroup.Text>Initial Value</InputGroup.Text>
                        <Form.Control
                            value={totaliserInitialValue}
                            onChange={(e) => setTotaliserInitialValue(Number(e.target.value))}
                        />
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Text>Volume Per Pulse</InputGroup.Text>
                        <Form.Control
                            value={totaliserVolumePerPulse}
                            onChange={(e) => setTotaliserVolumePerPulse(Number(e.target.value))}
                        />
                    </InputGroup>
                </div>
                <InputGroup>
                    <InputGroup.Text>Latest Value</InputGroup.Text>
                    <Form.Control
                        value={totaliserLatestValue}
                        onChange={(e) => setTotaliserLatestValue(Number(e.target.value))}
                    />
                </InputGroup>
            </>
        );
    };

    const createModbusTotaliserFields = () => {
        return isLoadingTotaliser ? (
            <div className="d-flex w-100 justify-content-center">
                <Spinner animation="border" role="status" />
            </div>
        ) : (
            <>
                <hr />
                <InputGroup className="mb-3">
                    <InputGroup.Text>Totaliser Type</InputGroup.Text>
                    <ToggleButtonGroup
                        type="radio"
                        name="totaliser-type-options"
                        value={totaliserType}
                        onChange={(e) => setTotaliserType(e)}
                    >
                        <ToggleButton id="radio-totaliser-type-0" variant="outline-secondary" value="Direct">
                            Direct
                        </ToggleButton>
                        <ToggleButton id="radio-totaliser-type-1" variant="outline-secondary" value="Pulse Counter">
                            Pulse Counter
                        </ToggleButton>
                    </ToggleButtonGroup>
                </InputGroup>
                {totaliserType === "Pulse Counter" ? (
                    <>
                        <div className="d-flex justify-content-between gap-3 mb-3">
                            <InputGroup>
                                <InputGroup.Text>Initial Value</InputGroup.Text>
                                <Form.Control
                                    value={totaliserInitialValue}
                                    onChange={(e) => setTotaliserInitialValue(Number(e.target.value))}
                                />
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text>Latest Value</InputGroup.Text>
                                <Form.Control
                                    value={totaliserLatestValue}
                                    onChange={(e) => setTotaliserLatestValue(Number(e.target.value))}
                                />
                            </InputGroup>
                        </div>
                        <div className="d-flex justify-content-between gap-3 mb-3">
                            <InputGroup>
                                <InputGroup.Text>Max Pulse Amount</InputGroup.Text>
                                <Form.Control
                                    value={totaliserMaxPulseAmount}
                                    onChange={(e) => setTotaliserMaxPulseAmount(Number(e.target.value))}
                                />
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text>Volume Per Pulse</InputGroup.Text>
                                <Form.Control
                                    value={totaliserVolumePerPulse}
                                    onChange={(e) => setTotaliserVolumePerPulse(Number(e.target.value))}
                                />
                            </InputGroup>
                        </div>
                        <div className="d-flex justify-content-between gap-3 mb-3">
                            <InputGroup>
                                <InputGroup.Text>Overflow Cycle Count</InputGroup.Text>
                                <Form.Control
                                    value={totaliserOverflowCycleCount}
                                    onChange={(e) => setTotaliserOverflowCycleCount(Number(e.target.value))}
                                />
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text>Output Signal</InputGroup.Text>
                                <Form.Select
                                    value={totaliserOutputSignalID}
                                    onChange={(e) => setTotaliserOutputSignalID(e.target.value)}
                                >
                                    <option value={undefined}>Select Signal</option>
                                        {signals.map((signal) => (
                                            <option key={signal.SignalID}  value={signal.SignalID}>{signal.NiceName}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </>
        );
    };
    // #endregion

    return (
        <div className="bg-body">
            <div className="d-flex justify-content-between gap-3 mb-3">
                <InputGroup className="flex-grow-1">
                    <InputGroup.Text>Name</InputGroup.Text>
                    <Form.Control value={signalNiceName} onChange={(e) => handleNameChange(e.target.value)} />
                </InputGroup>
                {protocolID !== undefined ? (
                    <InputGroup>
                        <InputGroup.Text>Protocol</InputGroup.Text>
                        <Form.Control disabled value={PROTOCOL_NAME_MAP[protocolID]} />
                    </InputGroup>
                ) : (
                    <></>
                )}
            </div>

            <InputGroup className="mb-3">
                <InputGroup.Text>Device</InputGroup.Text>
                {signal ? (
                    <Form.Control value={signal.SignalDevice.Device.NiceName} disabled />
                ) : (
                    <Form.Select
                        value={signalDeviceID}
                        onChange={(e) => {
                            setSignalDeviceID(e.target.value);
                        }}
                    >
                        {devices.length > 0 && <option value={undefined}>Select Device</option>}
                        {devices.map((device) => (
                            <option key={`device-${device.DeviceID}`} value={device.DeviceID}>
                                {device.NiceName}
                            </option>
                        ))}
                    </Form.Select>
                )}
            </InputGroup>
            <div className="mb-3">{createProtocolFields()}</div>

            {/* Sensor Type Selection */}
            <InputGroup className="mb-3">
                <InputGroup.Text>
                    Sensor Type
                    {sensorTypesLoading && (
                        <img className="ml-2" style={{ height: "24px", width: "24px" }} src="/lib/ajax-loader.gif" />
                    )}
                </InputGroup.Text>
                <Form.Select value={sensorType} onChange={(e) => setSensorType(Number(e.target.value))}>
                    <option value={0}>Select Sensor</option>
                    {sensorTypes?.map((type) => (
                        <option key={"type-" + type.SensorTypeID} value={type.SensorTypeID}>
                            {type?.Units ? `${type.NiceName} - ${type.Units}` : type.NiceName}
                        </option>
                    ))}
                </Form.Select>
            </InputGroup>
            <div className="mb-3">{createSensorRequiredFields()}</div>
        </div>
    );
};

interface SensorManageModalProps {
    sensorID: number | undefined;
    siteGUID: string;
    signals: SignalModel[];
    // Editable fields
    sensorNiceName: string;
    setSensorNiceName: (value: string) => void;
    sensorSignalID: string | undefined;
    setSensorSignalID: (value: string) => void;
    sensorPosition: number | undefined;
    setSensorPosition: (value: number) => void;
    sensorType: number;
    setSensorType: (value: number) => void;
    sensorTypes: SensorTypesModel[];
    equipment: string | undefined;
    setEquipment: (value: string) => void;
}

const SensorSettingsTabContent = ({
    sensorID,
    siteGUID,
    signals,
    sensorNiceName,
    setSensorNiceName,
    sensorSignalID,
    setSensorSignalID,
    sensorPosition,
    setSensorPosition,
    sensorType,
    setSensorType,
    sensorTypes,
    equipment,
    setEquipment,
}: SensorManageModalProps) => {
    const [delLoading, setDelLoading] = useState(false);
    const [eqmtOptions, setEqmtOptions] = useState<SiteEquipmentModel[]>([]);

    const _handleDeleteSensor = () => {
        setDelLoading(true);
        fetch(`/api/SignalSetupAPI/DeleteSensor/?id=${sensorID}`).then((res) => {
            if (res.ok) {
                setDelLoading(false);
            }
        });
    };

    useEffect(() => {
        fetch(`/api/SignalSetupAPI/GetEquipments/?siteGUID=${siteGUID}`)
            .then((res) => res.json())
            .then((data) => setEqmtOptions(data));
    }, []);

    return (
        <>
            <InputGroup className="mb-2">
                <InputGroup.Text>Name</InputGroup.Text>
                <Form.Control value={sensorNiceName} onChange={(e) => setSensorNiceName(e.target.value)} />
            </InputGroup>

            <InputGroup className="mb-2">
                <InputGroup.Text>Equipment</InputGroup.Text>
                <Form.Select value={equipment} onChange={(e) => setEquipment(e.target.value)}>
                    <option value={undefined}></option>
                    {eqmtOptions.map((item) => (
                        <option key={`equip-${item.EquipmentID}`} value={item.EquipmentID}>
                            {item.NiceName}
                        </option>
                    ))}
                </Form.Select>
            </InputGroup>

            {equipment !== undefined && (
                <InputGroup className="mb-2">
                    <InputGroup.Text>Position</InputGroup.Text>
                    <Form.Select value={sensorPosition} onChange={(e) => setSensorPosition(e.target.value)}>
                        <option value={undefined}></option>
                        <option value={0}>Inlet</option>
                        <option value={1}>Outlet</option>
                    </Form.Select>
                </InputGroup>
            )}

            <InputGroup className="mb-2">
                <InputGroup.Text>Primary Signal</InputGroup.Text>
                <Form.Select value={sensorSignalID} onChange={e => { console.log(e.target.value); setSensorSignalID(e.target.value) }}>
                    <option value={undefined}></option>
                    {signals.map((item) => (
                        <option key={`sensor-${item.SignalID}`} value={item.SignalID}>
                            {item.NiceName}
                        </option>
                    ))}
                </Form.Select>
            </InputGroup>

            <InputGroup className="mb-2">
                <InputGroup.Text>Sensor Type</InputGroup.Text>
                <Form.Select value={sensorType} onChange={e => { console.log(e.target.value); setSensorType(e.target.value) }}>
                    <option value={0}>Select Sensor</option>
                    {sensorTypes?.map(type => (
                        <option key={"type-" + type.SensorTypeID} value={type.SensorTypeID}>
                            {type?.Units ? `${type.NiceName} - ${type.Units}` : type.NiceName}
                        </option>
                    ))}
                </Form.Select>
            </InputGroup>

            <div className="d-flex justify-content-center">
                <MaintenanceAlarmSetup className="m-2" MaintenanceType={0} AssetID={sensorID} />
                <MaintenanceAlarmSetup className="m-2" MaintenanceType={1} AssetID={sensorID} />
                <Button variant="danger" className="m-2" onClick={_handleDeleteSensor}>
                    {delLoading ? (
                        <img style={{ height: "24px", width: "24px" }} src="/lib/ajax-loader.gif" />
                    ) : (
                        <i className="fad fa-trash"></i>
                    )}
                </Button>
            </div>
        </>
    );
};

interface AdvancedSettingsModalProps {
    signals: SignalModel[];
    filterSignalID: string | undefined;
    setFilterSignalID: (value: string | undefined) => void;
    lowerFilterThreshold: number | undefined;
    setLowerFilterThreshold: (value: number) => void;
    upperFilterThreshold: number | undefined;
    setUpperFilterThreshold: (value: number) => void;
}

export const AdvancedSettingsTabContent = ({
    signals,
    filterSignalID,
    setFilterSignalID,
    lowerFilterThreshold,
    setLowerFilterThreshold,
    upperFilterThreshold,
    setUpperFilterThreshold,
}: AdvancedSettingsModalProps) => {
    return (
        <>
            <InputGroup className="mb-2">
                <InputGroup.Text>Context Signal</InputGroup.Text>
                <Form.Select value={filterSignalID} onChange={(e) => setFilterSignalID(e.target.value)}>
                    <option value={undefined}>Select Signal</option>
                    {signals?.map((signal) => (
                        <option key={`filter-sig-${signal.SignalID}`} value={signal.SignalID}>
                            {signal.NiceName}
                        </option>
                    ))}
                </Form.Select>
            </InputGroup>
            <InputGroup className="mb-2">
                <InputGroup.Text>Upper Threshold</InputGroup.Text>
                <Form.Control
                    value={upperFilterThreshold}
                    onChange={e => {
                        const newVal = Number(e.target.value);
                        if (Number.isFinite(newVal)) {
                            setUpperFilterThreshold(Number(e.target.value));
                        }
                    }}
                />
            </InputGroup>
            <InputGroup>
                <InputGroup.Text>Lower Threshold</InputGroup.Text>
                <Form.Control
                    value={lowerFilterThreshold}
                    onChange={e => {
                        const newVal = Number(e.target.value);
                        if (Number.isFinite(newVal)) {
                            setLowerFilterThreshold(Number(e.target.value));
                        }
                    }}
                />
            </InputGroup>
        </>
    );
};
