import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { NotificationPill } from './notifications';
import { AlarmPill } from './AlarmHeader';
import { SitePicker } from './sitepicker';
import { Badge } from 'react-bootstrap'

export function NavMenu(props) {
    const history = useHistory();
    const location = useLocation();
    const [Name, setName] = useState("Manage Account")
    const [role, setRole] = useState();
    const [alarms, setAlarms] = useState();
    const [AlarmsTabVisible, setAlarmsTabVisible] = useState(false);
    const [AlarmsPresent, setAlarmsPresent] = useState(false);
    const [NSites, setNSites] = useState(1);

    useEffect(() => {
        //Fetch name
        $.ajax({
            url: '/api/NavBarAPI/GetName',
            type: 'get',
            success: function (response) {
                setName(response)
            },
            error: function (response) {
                if (location.pathname == '/' || location.pathname.includes('Login')) {
                    window.location = "/Identity/Account/Login?returnUrl="
                }
                else {
                    window.location = "/Identity/Account/Login?returnUrl=" + encodeURI(location.pathname)
                }
            }
        })
    }, []);

    useEffect(() => {
        // Fetch role
        var url = "/api/AuthorizationAPI/GetRoleLabel?siteGUID=" + props.SiteGUID;
        if (props.SiteGUID) {
            fetch(url).then(response => response.json())
                .then(data => {
                    setRole(data);
                });
        }

        // Fetch alarms
        $.ajax({
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            type: 'POST',
            data: JSON.stringify({ "SiteGUID": props.SiteGUID }),
            url: '/api/NavBarAPI/Post',
            success: function (data) {
                setAlarmsTabVisible(data.alarmsTabActive);
                setAlarmsPresent(data.alarmsPresent);
            }
        });

        var d = new Date();
        var offset = (d.getTimezoneOffset() * -1) / 60;
        $.ajax({
            url: '/api/AlarmsAPI/GetActiveAlarms',
            type: 'get',
            data: { SiteGUID: props.SiteGUID, offset: offset },
            success: function (response) {
                response = JSON.parse(response);
                setAlarms(response);

            }
        });


    }, [props.SiteGUID])

    function ChangeSitePicker(newSiteGUID) {
        if (location.pathname === '/') {
            history.push('/' + newSiteGUID)
        } else if (location.pathname.includes('ControlChains')) {
            history.push('/ControlChains/index/' + newSiteGUID);
        } 
        else {
            history.push(location.pathname.replace('/' + props.SiteGUID, '/' + newSiteGUID))
        }
        props.setSiteGUID(newSiteGUID);
    }

    return (
        <>
            <header>
                <Navbar className="fixed-top navbar-expand-sm navbar-toggleable-sm bg-white border-bottom box-shadow mb-3" light>
                    <Container className="themed-container" fluid={true}>
                        <NavbarBrand tag={Link} to={NSites > 1 ? "/" : ("/" + props.SiteGUID)}>
                            <img className="navbar-brand p-0" alt="Home" width="320" height="80" src="/images/Streamwise-Corporate-Identity-Horizontal-Colour-SVG.svg" />
                        </NavbarBrand>
                    </Container>
                    <NavbarToggler onClick={props.mobileCollapse} className="me-2" />
                    <SitePicker setNSites={setNSites} SiteChangeHandler={ChangeSitePicker} SiteGUID={props.SiteGUID} />
                    {AlarmsTabVisible && alarms !== undefined ?
                        <AlarmPill SiteGUID={props.SiteGUID} alarms={alarms} />
                        :
                        <></>
                    }
                    <NotificationPill SiteGUID={props.SiteGUID} />
                    <div>
                        <div>
                            <a tag={Link} className="text-dark" href="/Identity/Account/Manage">{Name}</a>
                        </div>
                        <div>
                            {role && 
                                <Badge bg={role.variant} >{role.name}</Badge>
                            }
                        </div>
                    </div>
                    {Name == "Manage Account" ? "" : <a tag={Link} style={{ marginTop: 0, marginBottom: 0 }} className="nav-link" href="/Identity/Account/Logout">
                        <i className="fas text-dark fa-2x fa-sign-out-alt"></i>
                    </a>}
                </Navbar>
            </header>
        </>
    );
}
