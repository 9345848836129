import { useEffect, useState } from 'react';
import { Modal, Row, Col, InputGroup, Button, Form, Table, Spinner } from 'react-bootstrap';
import * as signalR from '@microsoft/signalr';

const TekdisAIDetails = ({ deviceID, slaveID, show, setShow }) => {
    const [newSlave, setNewSlave] = useState(slaveID);
    const [sensorParams, setSensorParams] = useState([]);
    const [inputType, setInputType] = useState();
    const [modProtocol, setModProtocol] = useState(true); // DCON vs Modbus RTU

    var connection = new signalR.HubConnectionBuilder()
        .withUrl("/messageHub")
        .withAutomaticReconnect()
        .build();

    useEffect(() => {
        console.log(slaveID);
        if (show) {
            // Retrieve details
            let tag = "";
            connection.stop();
            // Begin signal R
            connection.start().then(() => {
                tag = "ModalStream" + connection.connectionId;
                console.log(tag);

                // Start live data stream
                fetch("/api/EdgeDiagnosticsAPI/GetTekdisAIDetails/?deviceID=" + deviceID
                    + "&slaveID=" + slaveID + "&signalRTag=" + tag)
                    .then(res => res.json())
                    .then(payload => {
                        console.log(payload)
                        setInputType(payload.inputType);
                        setModProtocol(payload.mode);
                    })
                    .catch();

                connection.on(tag, msg => {
                    let entry = JSON.parse(msg);
                    console.log(entry);
                    setSensorParams(entry);
                });
            }).catch(e => console.log('Scan connection failed: ', e));
        }
    }, [show]);

    useEffect(() => {
        setNewSlave(slaveID);
    }, [slaveID]);

    const changeModuleProtocol = () => {
        // to be do later no use now
    }

    const changeSlaveID = () => {
        const data = {
            DeviceID: deviceID,
            CurSlaveID: slaveID,
            NewSlaveID: newSlave
        };
        console.log(data);
        fetch("/api/EdgeDiagnosticsAPI/ChangeSlaveIDTekdis/", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.ok) {
                alert("Slave ID Changed!");
            }
            else {
                alert("Failed to change slave ID :(");
            }
        })
    }

    const changeInputRange = () => {
        fetch("/api/EdgeDiagnosticsAPI/ChangeInputTypeTekdis/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                DeviceID: deviceID,
                SlaveID: newSlave,
                Address: 486,
                Value: inputType
            })
        }).then(res => {
            if (res.ok) {
                alert("Changed Input Type!");
            }
            else {
                alert("Failed to change Input Type.");
            }
        })
    }

    const _handleModalClose = () => {
        fetch("/api/EdgeDiagnosticsAPI/StopSignalStreamTekdis/?deviceID=" + deviceID)
            .then(res => {
                if (res.ok) {
                    setShow(false);
                }
            });
    }

    return (
        <Modal show={show} onHide={_handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Tekdis Analog Input Module</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text>Slave ID: </InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        value={newSlave}
                                        onChange={e => setNewSlave(Number(e.target.value))}
                                        style={{ width: "60px" }} />
                                    <Button onClick={changeSlaveID}>
                                        <i className="fas fa-check"></i>
                                    </Button>
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text>Protocol: </InputGroup.Text>
                                    <Form.Select
                                        disabled
                                        value={modProtocol}
                                        onChange={e => setModProtocol(e.target.value)}>
                                        <option value={false}>DCON</option>
                                        <option value={true}>Modbus RTU</option>
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text>Input Type: </InputGroup.Text>
                                    <Form.Select
                                        value={inputType}
                                        onChange={e => setInputType(e.target.value)}>
                                        <option>Select</option>
                                        <option value={7}>4 ~ 20mA</option>
                                        <option value={8}>+/- 10V</option>
                                        <option value={9}>+/- 5V</option>
                                        <option value={10}>+/- 1V</option>
                                        <option value={11}>+/- 500mV</option>
                                        <option value={12}>+/- 150mV</option>
                                        <option value={13}>+/- 20mA</option>
                                        <option value={26}>0 ~ 20mA</option>
                                    </Form.Select>
                                    <Button onClick={changeInputRange}>Set</Button>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Register Address</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sensorParams.length > 0 ?
                                    sensorParams.map((value, i) =>
                                        <tr key={"analog-" + i}>
                                        <td>{i+1}</td>
                                        <td>{value}</td>
                                    </tr>)
                                    : <tr>
                                        <td colSpan={3} style={{ textAlign: "center" }}>
                                            <Spinner animation="border" variant="primary" />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
        );
}

export default TekdisAIDetails;