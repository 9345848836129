import React, { memo } from 'react';

import { Handle } from 'react-flow-renderer';
import '../Nodes/styles.scss'
import { StreamwiseNodeData } from '../types';

const PORT_POSITIONS = [17.5, 40, 60, 82.5]
const PORT_LABELS = ['PWR', 'A', 'B', 'COM']

export default memo(({ data }: {data: StreamwiseNodeData}) => {
    return (
    <div style={{width: data.width, height: data.height}}>
        <div>
            Edge device
        </div>
        <div className="handleLabel" style={{left: PORT_POSITIONS[0]}}>{PORT_LABELS[0]}</div>
        <div className="handleLabel" style={{left: PORT_POSITIONS[1]}}>{PORT_LABELS[1]}</div>
        <div className="handleLabel" style={{left: PORT_POSITIONS[2]}}>{PORT_LABELS[2]}</div>
        <div className="handleLabel" style={{left: PORT_POSITIONS[3]}}>{PORT_LABELS[3]}</div>
        <Handle
            type="target"
            position="left"
            id={PORT_LABELS[0]}
            style={{ top: 38, background: '#555' }}
            onConnect={(params) => console.log('handle onConnect', params)}
            isConnectable={false}
        />
        <Handle
            type="source"
            position="bottom"
            id={PORT_LABELS[1]}
            style={{ left: PORT_POSITIONS[1], background: '#555' }}
            isConnectable={false}
        />
        <Handle
            type="source"
            position="bottom"
            id={PORT_LABELS[2]}
            style={{ left: PORT_POSITIONS[2], background: '#555' }}
            isConnectable={false}
        />
        <Handle
            type="source"
            position="right"
            id={PORT_LABELS[3]}
            style={{ top: 38, background: '#555' }}
            isConnectable={false}
        />
    </div>
  );
});