import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import {
    Table, Form, InputGroup, Modal, Button, OverlayTrigger, Tooltip, Row, Col
} from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import "react-step-progress-bar/styles.css";


export function ReviewDeviceSetup(props) {
    const { id } = useParams();
    const [devicename, setDeviceName] = useState('');

    function DeviceUpdate() {
        var device = {
            ...props.device,
            NiceName: devicename
        }
        props.setDevice(device);
    }

    return (
            <>
                <Form>
                <h2>Device Details</h2>
                <Form.Group as={Row} className="mb-3" >
                    <Form.Label column sm={2}>
                        Serial Number
                    </Form.Label>
                    <Col sm={3}>
                        {props.device.DeviceID}
                    </Col>
                </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={2}>
                            Device Name
                        </Form.Label>
                        <Col sm={3}>
                        <Form.Control required type="text" placeholder="Device Name" value={devicename} onChange={(e) => { setDeviceName(e.target.value); DeviceUpdate();}} />
                        </Col>
                    </Form.Group>
                    <h2>Company Details</h2>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={2}>
                            ACN
                        </Form.Label>
                        <Col sm={3}>
                            {props.company.ACN}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={2}>
                            Company Name
                        </Form.Label>
                        <Col sm={3}>
                            {props.company.CompanyName}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={2}>
                            Trading As
                        </Form.Label>
                        <Col sm={3}>
                            {props.company.TradingAs}
                        </Col>
                    </Form.Group>
                    <h2>Site Details</h2>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={2}>
                            Site Name
                        </Form.Label>
                        <Col sm={3}>
                            {props.site.SiteName}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={2}>
                            Site Location
                        </Form.Label>
                        <Col sm={3}>
                            {props.site.SiteLocation}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={2}>
                            Time Zone
                        </Form.Label>
                        <Col sm={3}>
                            {props.site.IANATimeZone}
                        </Col>
                    </Form.Group>
                <Button type="submit" onClick={() => { DeviceUpdate(); props.completeSetup(); }}>Confirm Details</Button>
                </Form>
            </>);
};
