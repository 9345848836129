import React, {useState, useEffect} from 'react';
import { Form, Modal, Container, Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import $ from 'jquery';
import CreatableSelect from 'react-select/creatable';

function DeleteAnnotationModal(props) {
    const handleDelete = () => {
        props.onremove(props.id)
        props.onHide();
    }
    
    return (
        <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Are you sure you want to remove this annotation? 
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>
            Removing an annotation cannot be undone.
        </p>
        </Modal.Body>
        <Modal.Footer>
            <Container>
                <Row>
                    <Col>
                        <Button variant="primary" onClick={props.onHide}>Cancel</Button>
                    </Col>
                    <Col>
                        <Button className="float-right" variant="danger" onClick={() => handleDelete()}>Delete</Button>
                    </Col>
                </Row>
            </Container>
        </Modal.Footer>
        </Modal>
    );
}

function AnnotationTableItem(props){
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        $('input[name="'+props.data.CommentID+'-datetime"]').daterangepicker(
            {
                "timePicker": true,
                "autoApply": true,
                "singleDatePicker": true,
                minYear: 2020,
                maxYear: parseInt(moment().format('YYYY'),10),
                startDate: moment.utc(props.data.DateTime).local(),
                "alwaysShowCalendars": true,
                "opens": "center",
                "locale": {
                    "format": "DD/MM/YYYY hh:mm A",
                }
            },
            function (start, end, label) {
                props.ondatetimechange(props.id, start)
            }
        );
    }, []);


    return(
        <tr >
            <td style={{ verticalAlign: "middle" }}>{props.id}</td>
            <td style={{verticalAlign:"middle", minWidth: "450px"}}>
                <CreatableSelect
                    onChange={e => props.oncommentchange(props.id, e.label)}
                    options={props.pastAnnotations}
                    defaultValue={props.pastAnnotations.find(e => e.label == props.data.Comment)}
                    placeholder="Type comment or select one..."
                />
            </td>
            <td style={{verticalAlign:"middle"}}>
                <input type="text" className="form-control" style={{fontSize:"inherit", width:"auto"}} name={props.data.CommentID + "-datetime"} />
            </td>
            <td style={{verticalAlign:"middle"}}>
                <i type="button" className="fas fa-trash-alt" style={{color:"#dc3545"}} onClick={()=>{setShowDeleteModal(true)}}/>
                <DeleteAnnotationModal
                    show={showDeleteModal}
                    onHide={() => {
                        setShowDeleteModal(false);
                        props.setShowAnnotations(false);
                    }}
                    {...props}
                />
            </td>
        </tr>
    )
}

export default AnnotationTableItem;