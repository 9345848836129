import React from 'react'
import { CirclePicker } from 'react-color';

function SWColourPicker(props) {
    return (
            <CirclePicker
                className="p-2 justify-content-center"
                color={props.color}
                width='315px'
                circleSpacing={14}
                colors={props.colorPickerColours}
                onChangeComplete={props.handleColourChange}
            />
        )
}

export default SWColourPicker;
