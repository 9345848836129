import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import moment from 'moment';
import Cookies from 'cookies-js';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Show info modal if updated since last visit
var HideNPSForever = Cookies.get('HideNPSForever');
var LastNPSDate = Cookies.get('LastNPSDate');

function CloseButton() {
    function HideForever() {
        Cookies.set('HideNPSForever', "true", { expires: 7999 });
        $.ajax({
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            type: 'POST',
            url: '/api/FeedbackAPI/HideNPSForever'
        });
    }

    return (
        <div className="justify-content-center d-flex" onClick={HideForever}>
            <i class="fal fa-times"></i>
        </div>)
}

function NPSObject(props) {
    const [hoverrating, setHoverRating] = useState(0)

    function submitHoverRating(rating) {
        Cookies.set('LastNPSDate', moment().utc().format(), { expires: 7999 });

        $.ajax({
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            type: 'POST',
            data: JSON.stringify({ Rating: rating }),
            url: '/api/FeedbackAPI/SubmitRating'
        });
    }

    return (
        <div>
            From 1 to 10, how likely are you to recommend Streamwise D.I.?
            <div className="text-warning d-flex text-end">
                <div className="col"
                    onMouseEnter={() => setHoverRating(1)}
                    onMouseLeave={() => setHoverRating(0)}
                    onClick={() => submitHoverRating(1)}>
                    {hoverrating >= 1 ? <i class="fas fa-star"></i> : <i class="far fa-star"></i>}
                </div>
                <div className="col"
                    onMouseEnter={() => setHoverRating(2)}
                    onMouseLeave={() => setHoverRating(0)}
                    onClick={() => submitHoverRating(2)}>
                    {hoverrating >= 2 ? <i class="fas fa-star"></i> : <i class="far fa-star"></i>}
                </div>
                <div className="col"
                    onMouseEnter={() => setHoverRating(3)}
                    onMouseLeave={() => setHoverRating(0)}
                    onClick={() => submitHoverRating(3)}>
                    {hoverrating >= 3 ? <i class="fas fa-star"></i> : <i class="far fa-star"></i>}
                </div>
                <div className="col"
                    onMouseEnter={() => setHoverRating(4)}
                    onMouseLeave={() => setHoverRating(0)}
                    onClick={() => submitHoverRating(4)}>
                    {hoverrating >= 4 ? <i class="fas fa-star"></i> : <i class="far fa-star"></i>}
                </div>
                <div className="col"
                    onMouseEnter={() => setHoverRating(5)}
                    onMouseLeave={() => setHoverRating(0)}
                    onClick={() => submitHoverRating(5)}>
                    {hoverrating >= 5 ? <i class="fas fa-star"></i> : <i class="far fa-star"></i>}
                </div>
                <div className="col"
                    onMouseEnter={() => setHoverRating(6)}
                    onMouseLeave={() => setHoverRating(0)}
                    onClick={() => submitHoverRating(6)}>
                    {hoverrating >= 6 ? <i class="fas fa-star"></i> : <i class="far fa-star"></i>}
                </div>
                <div className="col"
                    onMouseEnter={() => setHoverRating(7)}
                    onMouseLeave={() => setHoverRating(0)}
                    onClick={() => submitHoverRating(7)}>
                    {hoverrating >= 7 ? <i class="fas fa-star"></i> : <i class="far fa-star"></i>}
                </div>
                <div className="col"
                    onMouseEnter={() => setHoverRating(8)}
                    onMouseLeave={() => setHoverRating(0)}
                    onClick={() => submitHoverRating(8)}>
                    {hoverrating >= 8 ? <i class="fas fa-star"></i> : <i class="far fa-star"></i>}
                </div>
                <div className="col"
                    onMouseEnter={() => setHoverRating(9)}
                    onMouseOut={() => setHoverRating(0)}
                    onClick={() => submitHoverRating(9)}>
                    {hoverrating >= 9 ? <i class="fas fa-star"></i> : <i class="far fa-star"></i>}
                </div>
                <div className="col"
                    onMouseOver={() => setHoverRating(10)}
                    onMouseLeave={() => setHoverRating(0)}
                    onClick={() => submitHoverRating(10)}>
                    {hoverrating >= 10 ? <i class="fas fa-star"></i> : <i class="far fa-star"></i>}
                </div>
            </div>
            <div className="text-muted" style={{ fontSize: '8px' }}>Responding will hide this message for six months. Click on <i class="fal fa-times"></i> to hide forever.</div>
        </div>
    );
}

export function NPSHandler(props) {
    useEffect(() => {
        // Don't have enough information locally - fetch from server, or...
        // If it's been more than a year on this browser since last submission, check to see if there's been a more recent submission from another browser
        if (HideNPSForever == undefined || LastNPSDate == undefined ||
            (HideNPSForever == "false" && (LastNPSDate == "null" || moment(LastNPSDate) < moment().add(-6, 'months')))) {

            //This ajax request actually only gets posted if there is local information and the local information is outdated by a year
            $.ajax({
                url: '/api/FeedbackAPI/GetNPSSettings',
                type: 'get',
                success: function (response) {
                    console.log(response)
                    Cookies.set('LastNPSDate', response.lastSubmitted, { expires: 7999 });
                    Cookies.set('HideNPSForever', response.hideNPSForever, { expires: 7999 });
                    HideNPSForever = response.hideNPSForever;
                    LastNPSDate = response.lastSubmitted;

                    // Check the new information and only show toast with 2% probability
                    if (HideNPSForever == false && (LastNPSDate == null || moment(LastNPSDate) < moment().add(-6, 'months')) && Math.random() < 0.02) {
                        toast.info(<NPSObject />, {
                            pauseOnHover: true,
                            progress: undefined
                        });
                    }
                }
            });
        }
    }, []);

    return (
        <ToastContainer
            position="bottom-center"
            autoClose={6000}
            hideProgressBar={false}
            newestOnTop={false}
            icon={false}
            transition={Flip}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            closeOnClick
            draggable
            pauseOnHover
            closeButton={CloseButton}
        />
    )
};
