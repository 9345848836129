import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';
import { Button, Form, InputGroup, Modal, Spinner } from 'react-bootstrap';
import Cookies from 'cookies-js';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import NoAccessPage from './NoAccessPage';
import HelpButton from './HelpButton';
import YouTubeVideo from './YouTubeVideo';

//Show info modal if updated since last visit
var DownloadsTabVersion = Cookies.get('DownloadsTabVersion');
var CurrentDownloadsTabVersion = 1;

export function Downloads(props) {
    const { id } = useParams();
    const [signals, setSignals] = useState([])
    const [starttime, setStartTime] = useState(moment.utc().add(-7, 'days').format())
    const [endtime, setEndTime] = useState(moment.utc().format())
    const [siteAddress, setSiteAddress] = useState("Site address")
    const [frequency, setFrequency] = useState(1800)
    const [showversioncontrolmodal, setShowVersionControlModal] = useState(false)
    const [raw, setRaw] = useState(false);
    const [checkboxValues, setCheckboxValues] = useState({});
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    useEffect(() => props.SiteGUIDSetter(id), [id]);


    function handleClose() {
        setShowVersionControlModal(false)
    }

    function CheckVersion() {
        if (DownloadsTabVersion < CurrentDownloadsTabVersion) {
            //Show modal and update downloads tab version for user so they don't see the message on other devices
            var fd = {
                "DownloadsTabVersion": CurrentDownloadsTabVersion
            };
            $.ajax({
                url: '/api/VersionControlAPI/SetDownloadsTabVersion',
                data: fd,
                type: 'get',
                success: function (response) {
                    DownloadsTabVersion = CurrentDownloadsTabVersion;
                    Cookies.set('DownloadsTabVersion', CurrentDownloadsTabVersion, { expires: 7999 });

                }
            });
            setShowVersionControlModal(true)
        }
    }

    useEffect(() => {
        // Version control modal
        CheckVersion()
        //First run
        if (DownloadsTabVersion == undefined || DownloadsTabVersion < CurrentDownloadsTabVersion) {
            //Get version from server
            $.ajax({
                url: '/api/VersionControlAPI',
                type: 'get',
                success: function (response) {
                    console.log(response);

                    DownloadsTabVersion = response.downloadsTabVersion;
                    Cookies.set('HomeTabVersion', response.homeTabVersion, { expires: 7999 });
                    Cookies.set('AnalyticsTabVersion', response.analyticsTabVersion, { expires: 7999 });
                    Cookies.set('HealthSummaryTabVersion', response.healthSummaryTabVersion, { expires: 7999 });
                    Cookies.set('CostBreakdownTabVersion', response.costBreakdownTabVersion, { expires: 7999 });
                    Cookies.set('DeepDiveTabVersion', response.deepDiveTabVersion, { expires: 7999 });
                    Cookies.set('MaintenanceTabVersion', response.maintenanceTabVersion, { expires: 7999 });
                    Cookies.set('BillingTabVersion', response.billingTabVersion, { expires: 7999 });
                    Cookies.set('AlarmsTabVersion', response.alarmsTabVersion, { expires: 7999 });
                    Cookies.set('ControlTabVersion', response.controlTabVersion, { expires: 7999 });
                    Cookies.set('CalibrationsTabVersion', response.calibrationsTabVersion, { expires: 7999 });
                    Cookies.set('AirCleaningTabVersion', response.airCleaningTabVersion, { expires: 7999 });
                    Cookies.set('ReportsTabVersion', response.reportsTabVersion, { expires: 7999 });
                    Cookies.set('SiteDetailsTabVersion', response.siteDetailsTabVersion, { expires: 7999 });
                    Cookies.set('DownloadsTabVersion', response.downloadsTabVersion, { expires: 7999 });

                    CheckVersion();
                }
            });
        }
    }, []);

    const handleDatetimerangeUpdate = (start, end, label) => {
        setStartTime(start.utc().format());
        setEndTime(end.utc().format());
    }

    useEffect(() => {
        $.ajax({
            url: '/api/DownloadsAPI/GetSignals',
            type: 'get',
            data: { SiteGUID: id },
            type: 'get',
            success: function (response) {
                response = JSON.parse(response);
                setSignals(response);
            }
        });

        $.ajax({
            url: '/api/DownloadsAPI/GetSiteAddress',
            type: 'get',
            data: { SiteGUID: id },
            type: 'get',
            success: function (response) {
                setSiteAddress(response);
            }
        })
    }, [id]);
    
    const selectAll = () => {
        const allCheckboxValues = signals.reduce((prev, signal) => {
            prev[signal.SignalID] = !selectAllChecked;
            return prev;
        }, {});
        setCheckboxValues(allCheckboxValues);
        setSelectAllChecked(!selectAllChecked);
    }

    var signalelements = [
        <Button onClick={selectAll} className="mb-2 me-2">{selectAllChecked ? "Deselect All" : "Select All"}</Button>        
    ];
    for (let i = 0; i < signals.length; i++) {
        signalelements.push(
            <InputGroup className="mb-2 me-2" style={{width:"auto"}} key={signals[i].SignalID + "listitem"}>
                <InputGroup.Checkbox
                    id={"checkbox" + signals[i].SignalID}
                    type="checkbox"
                    name="checkedsignal"
                    aria-label={`Checkbox for ${signals[i].NiceName}`}
                    value={signals[i].SignalID}
                    checked={checkboxValues[signals[i].SignalID]}
                    onChange={e => {
                        const isChecked = e.target.checked;
                        setCheckboxValues(prevState => ({
                            ...prevState,
                            [signals[i].SignalID]: isChecked
                        }));
                    }}
                />
                <InputGroup.Text readOnly htmlFor={"checkbox" + signals[i].SignalID} aria-label={signals[i].NiceName} >
                    {signals[i].NiceName}
                </InputGroup.Text>
            </InputGroup>
        )
    }

    return (
        props.auth !== undefined && (props.auth > 0 ?
            <div>
                <HelpButton showHelpModal={setShowVersionControlModal} />
                
                <h1>Download Data</h1>
                <div class="row d-flex align-items-center">
                    <div class="col-md-6">
                        <div class="card mb-3">
                            <div class="card-body p-0">
                                <div class="input-group">
                                    <div class="d-none d-lg-block d-xl-block float-left p-3 " style={{ backgroundColor: '#002c4e' }}>
                                        <i class="text-light fas fa-2x fa-map-marker-alt"></i>
                                    </div>
                                    <div class="p-2 d-flex align-items-center">
                                        <div id="siteAddress" class="h5 text-dark">{siteAddress}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 ms-auto">
                        <div id="insightDateRangePickerContainer" class="card mb-3">
                            <div class="card-body p-0">
                                <div class="input-group">
                                    <div class="d-none d-lg-block d-xl-block p-3 " style={{ backgroundColor: '#002c4e' }}><i class="far fa-2x fa-calendar-alt" style={{ "color": "white" }}></i></div>
                                    <DateRangePicker
                                        onCallback={handleDatetimerangeUpdate}
                                        initialSettings={{
                                            timePicker: true,
                                            autoApply: true,
                                            ranges: {
                                                'Last 24 hours': [moment().subtract(1, 'days'), moment()],
                                                'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                                                'Last 7 Days': [moment().subtract(7, 'days'), moment()],
                                                'Last 30 Days': [moment().subtract(30, 'days'), moment()],
                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                            },
                                            alwaysShowCalendars: true,
                                            maxDate: moment(),
                                            startDate: moment().subtract(7, 'days'),
                                            endDate: moment(),
                                            opens: "center",
                                            locale: {
                                                "format": "DD/MM/YYYY",
                                            }
                                        }}
                                    >
                                        <input type="text" className="m-3 form-control" name="daterange" />
                                    </DateRangePicker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p>Please select the signals you would like to download for the time selected.</p>
               {/* <Button onClick={selectAll} style={{ marginBottom: '10px' }} >{selectAllChecked ? "Deselect All" : "Select All"}</Button>*/}
                <form method="post" action="/api/DownloadsAPI/Submit">
                    {/* Signal Selection block for downloads */}
                    <div className="d-flex flex-wrap">
                        {signals.length > 0 ?
                            signalelements :
                            <Spinner
                                animation="border"
                                role="status" />
                        }
                    </div>                   
                    <div className="w-50">
                        <input type="text" hidden id="starttimefield" name="starttimestring" class="form-control" aria-label="Start Time" value={starttime} />
                        <input type="text" hidden id="endtimefield" name="endtimestring" class="form-control" aria-label="End Time" value={endtime} />
                        <input type="number" hidden id="timezoneoffsetfield" name="timezoneoffset" class="form-control" aria-label="Time zone offset" value={new Date().getTimezoneOffset()} />
                        <div class="form-group" >
                            <label class="control-label">Data Frequency (Seconds)</label>
                            <input disabled={raw} type="number" name="frequency" class="form-control" onChange={event => setFrequency(Number(event.target.value))} aria-label="Data frequency" min="1" value={frequency} />
                        </div>
                        <div class="error" id="error" hidden>
                            <i class="text-danger">Please select at least one signal!</i>
                        </div>

                        <div class="form-group">
                            <div class="mb-2" onClick={e => setRaw(!raw)}>
                                <Form.Check
                                    type="checkbox"
                                    name="raw"
                                    value={raw}
                                    label="Download in most raw form"
                                    checked={raw}
                                    onChange={e => setRaw(!raw)} />
                            </div>
                            <input type="submit" value="Download" class="btn btn-primary" />
                        </div>
                        <i>Note: Data will be interpolated if the requested frequency is faster than the rate at which the data is measured. Downloading a large data set may take several minutes.</i>
                    </div>
                </form>


                <Modal show={showversioncontrolmodal} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <h5 class="modal-title" id="exampleModalLongTitle">What's New?</h5>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <p className="text-start">The "Downloads" tab allows you to download your data in CSV format.</p>
                        <YouTubeVideo VideoTag="Downloads" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div> :
            <NoAccessPage />
        )
    );
};
