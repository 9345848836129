import React, { Suspense, useState, useEffect } from 'react';
import $ from 'jquery';
import { Popover, OverlayTrigger, Modal } from 'react-bootstrap';

const TankStockoutModalContent = React.lazy(() => import('./TankStockoutModalContent'));
const ChemEffAnomalyModalContent = React.lazy(() => import('./ChemEffAnomalyModalContent'));

function TankStockoutNotification(props) {
    const [modalShow, setModalShow] = useState(false);
    const [lineChartData, setLineChartData] = useState(null);


    useEffect(() => {
        $.ajax({
            url: '/api/NotificationsAPI/GetStockoutInformation',
            type: 'get',
            data: { ChemicalTankID: props.notification.ID, EventTime: props.notification.EventTime },
            success: function (response) {
                var lineChartData = [];
                for (var i = 0; i < response.length; i++) {
                    lineChartData.push({ x: response[i].t, y: response[i].v })
                }
                setLineChartData(lineChartData);
            }
        });
    }, [])

    const ShowModal = () => {
        setModalShow(true);
    }

    const HideModal = () => {
        setModalShow(false);
    }

    return (
        <>
            <div>
                <div className={(props.lastone ? "" : "border-bottom") + " p-2 row notificationrow " + (props.notification.Read ? "" : "unreadnotification")} onClick={ShowModal}>
                    <div className={"p-1 rounded border"} style={{ 'fontSize': '12px', 'display': 'inline-block' }}>{props.notification.SiteName}</div>
                    Tank stockout detected.</div>
            </div>

            <ModalWrapper
                key={props.notification.ID+'-modal'}
                showmodal={modalShow}
                onHide={HideModal}
                notification={props.notification}
                heading={"Tank stockout detected."}
                modalcontent={
                    <div>
                        <Suspense fallback={<div>Loading...</div>}>
                            <TankStockoutModalContent
                                notification={props.notification}
                                lineChartData={lineChartData}
                            />
                        </Suspense>
                    </div>
                }
            />
        </>
    );
}

// Modal wrapper component
class ModalWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: props.showmodal,
            modalcontent: props.modalcontent
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.showmodal != state.show ||
            props.modalcontent != state.modalcontent) {
            return {
                show: props.showmodal,
                modalcontent: props.modalcontent
            };
        }

        // Return null if the state hasn't changed
        return null;
    }

    render() {
        return (
            <Modal
                show={this.state.show}
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.props.heading}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.modalcontent}
                </Modal.Body>
            </Modal>)
    }
}

// Chemical Efficiency Anomaly Notification Component
class ChemEffNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShow: false,
            expectedtssremoval: 100,
            tssremoval: 0,
            expectedchemicalusage: 100,
            chemicalusage: 0
        };
    }

    componentDidMount() {
        $.ajax({
            url: '/api/NotificationsAPI/GetChemEffAnomalyInformation',
            type: 'get',
            data: { AlertID: this.props.notification.ID, EventTime: this.props.notification.EventTime },
            error: function () {
                console.log("Chem eff api call failed")
            },
            success: function (response) {
                this.setState({
                    expectedtssremoval: response.ExpectedTSSRemoval,
                    tssremoval: response.TSSRemoval,
                    expectedchemicalusage: response.ExpectedChemicalUsage,
                    chemicalusage: response.ChemicalUsage
                });
            }.bind(this)
        });
    }

    ShowModal = () => {
        this.props.hidePopover();
        this.setState({ modalShow: true });
    }

    HideModal = () => {
        this.setState({ modalShow: false });
    }

    render() {
        var heading;
        if (this.props.notification.NotificationType == 8) {
            heading = "Low TSS removal detected.";
        } else if (this.props.notification.NotificationType == 9) {
            heading = "High chemical usage detected.";
        } else if (this.props.notification.NotificationType == 10) {
            heading = "Low TSS removal & high chemical usage detected."
        } else {
            heading = "Chemical efficiency anomaly detected."
        }
        return (
            <>
                <div>
                    <div className={(this.props.lastone ? "" : "border-bottom") + " p-2 row notificationrow " + (this.props.notification.Read ? "" : "unreadnotification")} onClick={this.ShowModal}>
                        <div className={"p-1 rounded border"} style={{ 'font-size': '12px', 'display': 'inline-block' }}>{this.props.notification.SiteName}</div>
                        {heading}
                    </div>
                </div>

                <ModalWrapper
                    showmodal={this.state.modalShow}
                    onHide={this.HideModal}
                    heading={heading}
                    modalcontent={
                        <div>
                            <Suspense fallback={<div>Loading...</div>}>
                                <ChemEffAnomalyModalContent
                                    notification={this.props.notification}
                                    expectedtssremoval={this.state.expectedtssremoval}
                                    tssremoval={this.state.tssremoval}
                                    expectedchemicalusage={this.state.expectedchemicalusage}
                                    chemicalusage={this.state.chemicalusage}
                                    heading={heading}
                                />
                            </Suspense>
                        </div>
                    }
                />
            </>
        );
    }
}

class Notification extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        var notification;
        var notificationType = this.props.notification.NotificationType;
        if (notificationType == 0) { //SavingsOpportunity
            notification = <div className={(this.props.lastone ? "" : "border-bottom") + " p-2 row notificationrow " + (this.props.notification.Read ? "" : "unreadnotification")}>New saving available</div>;
        }
        else if (notificationType == 1) {//InvoiceDue
            notification = <div className={(this.props.lastone ? "" : "border-bottom") + " p-2 row notificationrow " + (this.props.notification.Read ? "" : "unreadnotification")}>New invoice is available</div>;
        }
        else if (notificationType == 2) {//QBRInsight
            notification = <div className={(this.props.lastone ? "" : "border-bottom") + " p-2 row notificationrow " + (this.props.notification.Read ? "" : "unreadnotification")}>QBR insight is available</div>;
        }
        else if (this.props.notification.NotificationType == 3) {   // Weekly Report
            notification = <a href={"/Reports/" + this.props.notification.ID} className={(this.props.lastone ? "" : "border-bottom") + " p-2 row notificationrow " + (this.props.notification.Read ? "" : "unreadnotification")}>
                <div className={"p-1 rounded border"} style={{ fontSize: '12px', 'display': 'inline-block' }}>{this.props.notification.SiteName}</div>
                Weekly report is available
            </a>;
        }
        else if (notificationType == 4) {//JarTest
            notification = <a href={"/DeepDive/" + this.props.notification.ID} className={(this.props.lastone ? "" : "border-bottom") + " p-2 row notificationrow " + (this.props.notification.Read ? "" : "unreadnotification")}>New jar test is available</a>;
        }
        else if (notificationType == 5) {//NewFeature
            notification = <div className={(this.props.lastone ? "" : "border-bottom") + " p-2 row notificationrow " + (this.props.notification.Read ? "" : "unreadnotification")}>New feature available on the portal</div>;
        }
        else if (this.props.notification.NotificationType == 6) {   // Chemical Order
            notification = <a href={"/DeepDive/" + this.props.notification.ID} className={(this.props.lastone ? "" : "border-bottom") + " p-2 row notificationrow " + (this.props.notification.Read ? "" : "unreadnotification")}>
                <div className={"p-1 rounded border"} style={{ fontSize: '12px', 'display': 'inline-block' }}>{this.props.notification.SiteName}</div>
                Chemical order was placed
            </a>;
        }
        else if (notificationType == 7) {
            notification = <TankStockoutNotification ChemicalTankID={this.props.notification.ID} lastone={this.props.lastone} notification={this.props.notification} hidePopover={this.props.hidePopover} />;
        }
        else if (notificationType == 8 || notificationType == 9 || notificationType == 10) {//ChemicalEfficiencyAnomaly
            notification = <ChemEffNotification AlertType={notificationType} AlertID={this.props.notification.ID} lastone={this.props.lastone} notification={this.props.notification} hidePopover={this.props.hidePopover} />;
        }
        else {
            notification = <div className={(this.props.lastone ? "" : "border-bottom") + " p-2 row notificationrow " + (this.props.notification.Read ? "" : "unreadnotification")}>"Unknown notification type"</div>;
        }
        return (notification);
    }
}

export class NotificationPill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            NumberUnread: 0,
            SiteSpecific: true,
            Notifications: [],
            NotificationRenderObjects: [],
            showpopover: false,
            SiteGUID: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.SiteGUID != state.SiteGUID) {
            return {
                SiteGUID: props.SiteGUID
            };
        }

        // Return null if the state hasn't changed
        return null;
    }

    componentDidMount() {
        $.ajax({
            url: '/api/NotificationsAPI/GetNotifications',
            type: 'get',
            success: function (response) {
                response = JSON.parse(response);
                var notificationRenderObjects = [];
                var i;
                for (i = 0; i < response.Notifications.length; i++) {
                    notificationRenderObjects.push(<Notification key={i + "notification"} lastone={i == (response.Notifications.length - 1)} render={this.Render} hidePopover={this.HidePopover} showPopover={this.ShowPopover} notification={response.Notifications[i]} />);
                }

                this.setState({
                    SiteSpecific: response.SiteSpecific,
                    Notifications: response.Notifications,
                    NotificationRenderObjects: notificationRenderObjects,
                    NumberUnread: response.NumberUnread
                });

            }.bind(this)
        });

    }

    SiteSpecificChangeHandler = () => {
        var thiscomponent = this;
        $.ajax({
            url: '/api/NotificationsAPI/SetSiteSpecific',
            type: 'get',
            data: { SiteSpecific: !this.state.SiteSpecific },
            success: function () {

                $.ajax({
                    url: '/api/NotificationsAPI/GetNotifications',
                    type: 'get',
                    data: { SiteGUID: this.state.SiteSpecific ? "" : this.state.SiteGUID },
                    success: function (response) {
                        response = JSON.parse(response);

                        var notificationRenderObjects = [];
                        var i;
                        for (i = 0; i < response.Notifications.length; i++) {
                            notificationRenderObjects.push(<Notification lastone={i == (response.Notifications.length - 1)} render={this.Render} hidePopover={this.HidePopover} showPopover={this.ShowPopover} notification={response.Notifications[i]} />);
                        }

                        thiscomponent.setState({
                            SiteSpecific: response.SiteSpecific,
                            Notifications: response.Notifications,
                            NotificationRenderObjects: notificationRenderObjects,
                            NumberUnread: response.NumberUnread
                        });

                    }.bind(this)
                });
            }.bind(this)
        });

        this.setState({ SiteSpecific: !this.state.SiteSpecific });

    }

    TogglePopover = () => {
        console.log('Toggle popover')
        if (!this.state.showPopover) {
            $.get('/api/NotificationsAPI/NotificationsSeen/');
        }
        this.setState({ showpopover: !this.state.showpopover });
    }

    Render = () => {
        this.setState({ NumberUnread: this.state.NumberUnread });
    }

    HidePopover = () => {
        console.log("Hide popover...")
        this.setState({ showpopover: false });
    }

    ShowPopover = () => {
        this.setState({ showpopover: true });
    }

    CloseModal = () => {
        console.log("Close modal?");
    }

    render() {
        return (<div>
            <OverlayTrigger
                trigger="click"
                placement="auto"
                rootClose={true}
                overlay={
                    <Popover style={{ display: 'block', zIndex: 1050 }}>
                        <Popover.Header as="h3">
                            <div className="row">
                                <div className="col pe-0">{this.state.NumberUnread} New Notification{this.state.NumberUnread == 1 ? "" : "s"}</div>
                                <div className="col ps-0 text-right">
                                    <input onClick={this.SiteSpecificChangeHandler} type="range" min="0" max="1" value={this.state.SiteSpecific ? 1 : 0} className="sitespecificnotificationslider" />
                                    <div>Current Site Only</div>
                                </div>
                            </div>
                        </Popover.Header>
                        <Popover.Body style={{ overflowY: 'scroll', 'height': '400px' }}>
                            <div className="col">
                                {this.state.NotificationRenderObjects}
                            </div>
                        </Popover.Body>
                    </Popover>
                }>
                <div className="border rounded-pill p-2 d-flex justify-content-center notificationpill me-3" id="notificationspopover" onClick={this.TogglePopover}>
                    <i className="far fa-2x fa-lightbulb ms-2 mt-1" aria-hidden="true"></i>
                    <div className="h4 mt-2 ms-2 me-1" id="NotificationNumber">{this.state.NumberUnread}</div>
                </div>
            </OverlayTrigger>
        </div >
        );
    }
}