'use strict';
import StreamwiseTrendChart from "../LineCharts/waterwerx-trend-chart";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

const TrendChart = (function () {
    var charts = new Array();

    const createChartModel = function (data) {
        const chartModel = data.chartDataPoints.map(function (t) {
            return {
                t: new Date(t.t),
                s: t.s
            };
        });

        chartModel.inputType = data.inputType;
        chartModel.signalNiceName = data.signalNiceName;
        chartModel.colorID = data.colorID;
        chartModel.axisSide = data.axisSide;

        return chartModel;
    };

    const createChartConfig = function (data) {

        const chartID = data.ChartID;
        var labels = [];

        var chartDatasets = [];
        var i = 0;
        const bgcolorsarray = ['rgba(124, 210, 233, 0.3)', 'rgba(160,82,45, 0.3)', 'rgba(148, 75, 163, 0.3)', 'rgba(6, 148, 22, 0.3)', 'rgba(0, 44, 79, 0.3)', 'rgba(244, 121, 32, 0.3)', 'rgba(16, 24, 32, 0.3)'];
        const colorsarray = ['rgba(20, 168, 222, 0.8)', 'rgba(160,82,45, 0.8)', 'rgba(148, 75, 163, 0.8)', 'rgba(6, 148, 22, 0.8)', 'rgba(0, 44, 79, 0.8)', 'rgba(244, 121, 32, 0.8)', 'rgba(16, 24, 32, 0.8)'];
        
        //6, 148, 22
        var averageslist = document.getElementById(chartID + '-Averages');
        if (averageslist !== null){
            averageslist.innerHTML = "";
        }

        // Get latest times for chart lines
        // Get array of latest datetimes
        var chartLinesCopy = JSON.parse(JSON.stringify(data.chartLines));
        let validDataSets = chartLinesCopy.filter(c => c.chartDataPoints && c.chartDataPoints.length > 0 && c.inputType == 1);
        var latestTimes = validDataSets.map(c => moment.utc(c.chartDataPoints[c.chartDataPoints.length-1].t))
        // Check if mins ago match for all valid datasets 
        let timeAgosMismatch = false;
        if (latestTimes && latestTimes.length > 0){
            let minAgo = moment().utc().diff(latestTimes[0], 'minutes');
            for (let i = 1; i<latestTimes.length; i++){
                if (moment().utc().diff(latestTimes[i], 'minutes') !== minAgo){
                    timeAgosMismatch = true;
                }
            }
        }        

        for (i = 0; i < data.chartLines.length; i++) {
            const chartModel = createChartModel(data.chartLines[i]);

            labels = chartModel.map(function (e) {
                return e.t;
            });

            var plotData = [];
            var inCompliance = [];
            var j;
            for (j = 0; j < data.chartLines[i].chartDataPoints.length; j++) {
                plotData.push({
                    x: data.chartLines[i].chartDataPoints[j].t,
                    y: data.chartLines[i].chartDataPoints[j].s,
                });
                inCompliance.push(data.chartLines[i].chartDataPoints[j].c);
            }

            var upperLimit = data.chartLines[i].UpperValue;
            var lowerLimit = data.chartLines[i].LowerValue;
            var reference = data.chartLines[i].Reference;

            if(averageslist !== null){
                // Values to display in 'Latest' or 'Averages'
                if (data.chartLines[i].inputType == 1 && plotData.length > 0) {
                    var div = document.createElement("div");   // Create a <div> element
                    div.className = "d-flex mt-2 justify-content-center";
                    div.style.color = colorsarray[chartModel.colorID];
                    div.style.fontWeight = 'bold';
                    div.innerHTML = Math.round(plotData[plotData.length - 1].y * 100) / 100;
                    averageslist.appendChild(div);

                    // Time since signal updated 
                    if (timeAgosMismatch){
                        let divId = uuidv4(); // Randomly generated UUID
                        let latestTime = moment.utc(data.chartLines[i].chartDataPoints[data.chartLines[i].chartDataPoints.length-1].t);
                        if (latestTime.isValid()){
                            let minAgo = moment().utc().diff(latestTime, 'minutes');

                            const interval = setInterval(() => {
                                minAgo++;
                                let divTimeSince = document.getElementById(divId);
                                if (divTimeSince !== null){
                                    divTimeSince.innerHTML = minAgo + "m ago";
                                }
                            }, 60000);
    
                            var divTimeSince = document.createElement("div");   // Create a <div> element
                            divTimeSince.id = divId;
                            divTimeSince.className = "d-flex justify-content-center";
                            divTimeSince.style.color = colorsarray[chartModel.colorID];
                            divTimeSince.style.fontSize = '12px';
                            divTimeSince.innerHTML = minAgo + "m ago";
                            averageslist.appendChild(divTimeSince);
                        }
                    }
                }
                else if (data.chartLines[i].inputType == 1) {
                    var div = document.createElement("div");   // Create a <div> element
                    div.className = "p-1 d-flex justify-content-center";
                    div.style.color = colorsarray[chartModel.colorID];
                    div.style.fontWeight = 'bold';
                    div.innerHTML = "No Data";
                    averageslist.appendChild(div);
                }
            }
            
            /////////////////////////////////////////////////////

            var yAxisID = 'left';
            if (chartModel.axisSide == 1){ // Assign to right axis
                yAxisID = 'right';
            } else { // Assign to left axis
                yAxisID = 'left';
            }

            const signalNiceName = chartModel.signalNiceName;

            if (chartModel.inputType == 1){ //  Line chart
                chartDatasets.push({
                    yAxisID: yAxisID,
                    label: signalNiceName,
                    data: plotData,
                    pointRadius: inCompliance.map(c => (c ? 1 : 3)),
                    pointHoverRadius: 1.2,
                    pointHitRadius: 5,
                    fill: false,
                    borderWidth: 1,
                    backgroundColor: bgcolorsarray[chartModel.colorID],
                    borderColor: colorsarray[chartModel.colorID],
                    pointBorderColor: inCompliance.map(c => (c ? colorsarray[chartModel.colorID] : "rgba(255, 0, 0, 1)")),
                    pointStyle: inCompliance.map(c => (c ? 'circle' : 'crossRot')),
                    pointBorderWidth: 0.5,
                });
            } else { // Backdrop
                chartDatasets.push({
                    yAxisID: yAxisID,
                    label: signalNiceName,
                    data: plotData,
                    type: 'line',
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    steppedLine: 'middle',
                    backgroundColor: bgcolorsarray[chartModel.colorID],
                    borderColor: 'rgba(0, 0, 0, 0)',
                    fill: true
                });
            }
            
            if (upperLimit != null) {
                var upperPlotData = [];
                var j;
                for (j = 0; j < plotData.length; j++) {
                    upperPlotData.push({x:plotData[j]['x'],y:upperLimit});
                }
                chartDatasets.push({
                    yAxisID: yAxisID,
                    label: signalNiceName + ' Upper Limit',
                    data: upperPlotData,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    fill: false,
                    borderWidth: 1,
                    backgroundColor: bgcolorsarray[chartModel.colorID],
                    borderColor: colorsarray[chartModel.colorID],
                    weightedAverage: chartModel.weightedAverage,
                    borderDash: [5, 3]
                });
            }

            if (lowerLimit != null) {
                var lowerPlotData = [];
                var j;
                for (j = 0; j < plotData.length; j++) {
                    lowerPlotData.push({x:plotData[j]['x'],y:lowerLimit});
                }

                chartDatasets.push({
                    yAxisID: yAxisID,
                    label: signalNiceName + ' Lower Limit',
                    data: lowerPlotData,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    fill: false,
                    borderWidth: 1,
                    backgroundColor: bgcolorsarray[chartModel.colorID],
                    borderColor: colorsarray[chartModel.colorID],
                    weightedAverage: chartModel.weightedAverage,
                    borderDash: [5, 3]
                });
            }

            if (reference != null) {
                var referencePlotData = [];
                var j;
                for (j = 0; j < plotData.length; j++) {
                    referencePlotData.push({x:plotData[j]['x'],y:reference});
                }

                chartDatasets.push({
                    yAxisID: yAxisID,
                    label: signalNiceName + ' Setpoint',
                    data: referencePlotData,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    fill: false,
                    borderWidth: 1,
                    backgroundColor: bgcolorsarray[chartModel.colorID],
                    borderColor: colorsarray[chartModel.colorID],
                    weightedAverage: chartModel.weightedAverage,
                    borderDash: [1, 5]
                });
            }
            ////////////////////////////////////////////////////
        }

        const chartConfig = {
            chartId: chartID,
            chartLabels: labels,
            chartDataSets: chartDatasets,
            chartYAxisIdOverride: data.YAxisTitle,
            chartRightYAxisIdOverride: data.RightYAxisTitle,
            ChartIDs: data.ChartIDs
        };
        return chartConfig;
    };

    const clearChart = function () {
        var i;
        for (i = 0; i < charts.length; i++) {
            if (charts[i]) {
                charts[i].destroy();
            }
        }
    }

    const initChart = function (data, onLoad) {
        const noData = !data || !data.chartLines || data.chartLines.length === 0 || (data.chartLines.every((c) => c.chartDataPoints.length == 0));
        const chartConfig = createChartConfig(data);
        charts.push(StreamwiseTrendChart.create(chartConfig, noData));
        onLoad();
    };

    const refreshChart = function (data) {
        const noData = !data || data.chartLines.length === 0 || (data.chartLines.every((c) => c.chartDataPoints.length == 0));
        const chartConfig = createChartConfig(data);
        for (let i = 0; i < charts.length; i++) {
            StreamwiseTrendChart.refresh(charts[i], chartConfig, noData);
        }
    };

    return {
        init: initChart,
        refresh: refreshChart,
        clear: clearChart
    };
}());

export default TrendChart; 