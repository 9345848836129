import React, { useState, useEffect, useRef } from 'react';
import {
  ButtonGroup,
  Button,
  Card,
  Form,
  Row,
  Col,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Collapse,
} from "react-bootstrap";
import { Line } from 'react-chartjs-2'
import RangeSlider from 'react-bootstrap-range-slider';
import $ from 'jquery';
import HelpTooltip from '../HelpTooltip';
import moment from 'moment';

const PumpCalibLogCard = ({ values, time }) => {
    return (
        <div className="border p-2">
            <Row>
                <Col>Gradient: {values.Gradient}</Col>
                <Col>Intercept: {values.Intercept}</Col>
            </Row>
            <Row>
                <Col>Min: {values.Minimum}</Col>
                <Col>Max: {values.Maximum}</Col>
            </Row>
            <Row>
                <Col>Raw Min: {values.RawMinimum}</Col>
                <Col>Raw Max: {values.RawMaximum}</Col>
            </Row>
            <div className="d-flex justify-content-between">
                <div className="text-secondary text-left m-1" style={{ fontSize: '12px' }}>
                    {values.UserID}
                </div>
                <div className="text-secondary text-right m-1" style={{ fontSize: '12px' }}>
                    {moment.utc(time).local().format('LLL')}
                </div>
            </div>
        </div>);
}

const PumpManualLogCard = ({ data }) => {
    return (
        <div className="border p-2">
            <Row>
                <Col>Duration (s): {data.Duration}</Col>
                <Col>Pump Rate (%): {data.Value}</Col>
            </Row>
            <Row><Col>Annotation: {data.Comments}</Col></Row>
            <div className="d-flex justify-content-between">
                <div className="text-secondary text-left m-1" style={{ fontSize: '12px' }}>
                    {data.UserID}
                </div>
                <div className="text-secondary text-right m-1" style={{ fontSize: '12px' }}>
                    {moment.utc(data.ManualControlTime).local().format('LLL')}
                </div>
            </div>
        </div>)
};

const PumpCard = ({ data, deviceID, newData, $grid }) => {
    const [sliderVal, setSliderVal] = useState(50);
    const [gradient, setGradient] = useState(data.Gradient);
    const [intercept, setIntercept] = useState(data.Intercept);
    const [engMin, setEngMin] = useState(data.EngMin);
    const [engMax, setEngMax] = useState(data.EngMax);
    const [rawMin, setRawMin] = useState(data.RawMin);
    const [rawMax, setRawMax] = useState(data.RawMax);
    const [comments, setComments] = useState("");

    const [calibrationlog, setCalibrationLog] = useState([]);
    const [calibrationlogopen, setCalibrationLogOpen] = useState(false);
    const [manualControlLog, setManualControlLog] = useState([]);
    const [manualControlLogOpen, setManualControlLogOpen] = useState(false);

    // Manual timer states
    const [countdown, setCountdown] = useState(data.Time);
    const countRef = useRef(countdown);
    countRef.current = countdown;
    const [timeVal, setTimeVal] = useState(0);
    // Chart data
    const [datapoints, setDatapoints] = useState([]);

    const chartState = {
        //labels: timestamps,
        datasets: [{
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: datapoints
        }]
    };
    const chartOptions = {
        scales: {
            x: { type: 'time' },
            y: {
                title: {
                    display: true,
                    text: "(L/hr)"
                }
            }
        },
        plugins: { legend: { display: false } },
        title: { display: false }
    }

    useEffect(() => {
        // Retrieve pump calibrations
        $.ajax({
            type: "GET",
            url: "/api/ControlAPI/GetPumpCalibrations",
            data: { pumpID: data.PumpID },
            success: response => {
                response = JSON.parse(response);
                console.log(response);
                setCalibrationLog(response)
            }
        });

        // Retrieve pump manual control instances
        $.ajax({
            type: "GET",
            url: "/api/ControlAPI/GetPumpManualControl",
            data: { pumpID: data.PumpID },
            success: response => {
                response = JSON.parse(response);
                console.log(response);
                setManualControlLog(response)
            }
        });

        setTimeout(function () {
            try {
                $grid.reloadItems();
                $grid.layout();
            }
            catch (exception) {
            }
        }, 1000);
    }, []);

    // Update chart data
    useEffect(() => {
        let curTime = new Date();
        let newDatapoints = [...datapoints];
        // Remove data points > 2 min
        var dt = (curTime - datapoints[0]) / 1000 / 60;
        if (dt >= 2) {
            newDatapoints = newDatapoints.slice(1);
        }
        newDatapoints.push({ x: curTime, y: newData[data.PumpID] });
        setDatapoints(newDatapoints);
    }, [newData]);

    // Handle countdown timer
    useEffect(() => {
        if (countRef.current > 0) {
            const timer = setTimeout(() => setCountdown(countRef.current - 1), 1000);
            return () => { clearTimeout(timer) }
        } else {
            setCountdown(0);
        }
    }, [countdown]);

    const ToggleManualPumpControl = () => {
        let manualControlObject = {
            Data: {
                SignalID: data.PumpID,
                Time: timeVal,
                Value: sliderVal,
                DeviceID: deviceID
            },
            Comments: comments
        }
        console.log(manualControlObject)
        // Turn off manual if time is 0 or invalid
        if (timeVal <= 0) {
            manualControlObject.Time = 0.1;
        }

        setCountdown(timeVal);
        $.ajax({
            type: "POST",
            url: "/api/ControlAPI/ToggleManualPumpControl",
            data: JSON.stringify(manualControlObject),
            contentType: 'application/json'
        });
    }

    const SubmitCalibration = () => {
        let tuneObject = {};
        if (data.NiceName.includes("Positive")) {
            tuneObject = {
                UpPumpID: data.PumpID,
                UpPumpGradient: gradient,
                UpPumpIntercept: intercept,
                UpPumpEngMinimum: engMin,
                UpPumpEngMaximum: engMax,
                UpPumpRawMinimum: rawMin,
                UpPumpRawMaximum: rawMax
            }
        }
        else {
            tuneObject = {
                DownPumpID: data.PumpID,
                DownPumpGradient: gradient,
                DownPumpIntercept: intercept,
                DownPumpEngMinimum: engMin,
                DownPumpEngMaximum: engMax,
                DownPumpRawMinimum: rawMin,
                DownPumpRawMaximum: rawMax
            }
        }
        console.log(tuneObject);
        $.ajax({
            type: "POST",
            url: "/api/ControlAPI/SubmitPumpCalibration",
            data: JSON.stringify({ ControlTuneModel: tuneObject, DeviceID: deviceID }),
            contentType: 'application/json',
            error: () => {
                alert("Calibration Failed.");
            }
        });
    }

    const CalibrationLogToggle = () => {
        setCalibrationLogOpen(!calibrationlogopen);
        setManualControlLogOpen(false);
        setTimeout(() => {
            if ($grid) {
                try {
                    $grid.layout();
                }
                catch (exception) {
                }
            }
        }, 1000);
    };

    const ManualControlLogToggle = () => {
        setManualControlLogOpen(!manualControlLogOpen);
        setCalibrationLogOpen(false);
        setTimeout(() => {
            if ($grid) {
                try {
                    $grid.layout();
                }
                catch (exception) {
                }
            }
        }, 1000);
    };

    return (
        <div className="grid-item col-lg-4 col-md-6 p-0 flex">
            <Card className="m-2 shadow-sm">
                <Card.Header>
                    <h5>{data.NiceName}</h5>
                </Card.Header>
                <Card.Body className="p-2">
                    <Line
                        data={chartState}
                        options={chartOptions}
                    />

                    <div>
                        <RangeSlider
                            value={sliderVal}
                            onChange={e => setSliderVal(e.target.value)}
                            tooltipLabel={currentValue => `${currentValue}%`}
                            tooltipPlacement='top'
                            tooltip='auto' />
                        <InputGroup>
                            <InputGroup.Text>{countdown + "s"}</InputGroup.Text>
                            <Form.Control
                                type='number'
                                step={60}
                                defaultValue={timeVal}
                                onChange={e => setTimeVal(e.target.value)}
                                style={{ width: '30px' }} />
                            <InputGroup.Text>seconds</InputGroup.Text>
                            <OverlayTrigger
                                overlay={<Tooltip>Press to override automatic pump operation for the chosen time</Tooltip>}
                            >
                                <Button className="btn-primary" onClick={ToggleManualPumpControl} >Activate</Button>
                            </OverlayTrigger>
                        </InputGroup>
                    </div>
                    <Form.Control
                        className='mt-2'
                        as="textarea"
                        rows={1}
                        placeholder="Input any notes of the manual operation here."
                        onChange={e => setComments(e.target.value)} />
                    <hr />
                    <Row>
                        <div className="form-group col-md-6">
                            <label className="control-label">Gradient</label>
                            <input className="form-control" value={gradient} type="number" onChange={e => setGradient(e.target.value)} />
                        </div>
                        <div className="form-group col-md-6">
                            <label className="control-label">Intercept</label>
                            <input className="form-control" value={intercept} type="number" onChange={e => setIntercept(e.target.value)} />
                        </div>

                        <div className="form-group col-md-6">
                            <div className="d-flex justify-content-between">
                                <label className="control-label me-2">Minimum</label>
                                <HelpTooltip helptext="Operational pump output in volume per unit time e.g. L/hr" />
                            </div>
                            <input className="form-control" type="number" value={engMin} onChange={e => setEngMin(e.target.value)} />
                        </div>

                        <div className="form-group col-md-6">
                            <label className="control-label me-2">Maximum</label>
                            <input className="form-control" type="number" value={engMax} onChange={e => setEngMax(e.target.value)} />
                        </div>

                        <div className="form-group col-md-6">
                            <div className="d-flex justify-content-between">
                                <label className="control-label me-2">Raw Minimum</label>
                                <HelpTooltip helptext="Corresponding nominal value written to pump register e.g. 0 - 32767 for a 0 - 100 L/hr pump" />
                            </div>
                            <input className="form-control" type="number" value={rawMin} onChange={e => setRawMin(e.target.value)} />
                        </div>

                        <div className="form-group col-md-6">
                            <label className="control-label me-2">Raw Maximum</label>
                            <input className="form-control" type="number" value={rawMax} onChange={e => setRawMax(e.target.value)} />
                        </div>
                    </Row>

                    <Row>
                        <Col>
                            <ButtonGroup>
                                <Button
                                    className="btn btn-primary"
                                    onClick={SubmitCalibration}>Submit
                                </Button>
                                <Button
                                    variant='secondary'
                                    className="btn"
                                    onClick={CalibrationLogToggle}
                                    aria-controls={data.PumpID + "-calibrationlog"}
                                    aria-expanded={calibrationlogopen}>
                                    Calibration Log <i class="fas fa-caret-down"></i>
                                </Button>
                                <Button
                                    variant='secondary'
                                    className="btn"
                                    onClick={ManualControlLogToggle}
                                    aria-controls={data.PumpID + "-manualControlLog"}
                                    aria-expanded={manualControlLogOpen}>
                                    Manual Log <i class="fas fa-caret-down"></i>
                                </Button>
                            </ButtonGroup>

                            {/* Calibration Log */}
                            <Collapse in={calibrationlogopen}>
                                {calibrationlog.length > 0 ?
                                    <div className='mt-2' id={data.PumpID + "-calibrationlog"}>
                                        {calibrationlog.map(item => <PumpCalibLogCard
                                            values={item.Values}
                                            time={item.Calibration.CalibrationTime} />)}
                                    </div>
                                    : <div className="border mt-2 p-2">
                                        No calibrations to date.
                                    </div>
                                }
                            </Collapse>

                            {/* Manual Control Log */}
                            <Collapse in={manualControlLogOpen}>
                                {manualControlLog.length > 0 ?
                                    <div className='mt-2' id={data.PumpID + "-manualControlLog"}>
                                        {manualControlLog.map(item => <PumpManualLogCard data={item} />)}
                                    </div>
                                    : <div className="border mt-2 p-2">
                                        No manual toggles to date.
                                    </div>
                                }
                            </Collapse>
                        </Col>
                    </Row>

                </Card.Body>
            </Card>
        </div>);
}

export default PumpCard;