import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Gauge as CanvasGauge, Donut } from 'gaugeJS/dist/gauge.min';

/**
 * React wrapper for GaugeJS.
 * @param {*} props
 * @return {Object} React element
 */
function Gauge(props) {
    const canvas = useRef();
    const span = useRef();
    const gauge = useRef();

    useEffect(() => {
        // Observe the span node
        const config = {
            characterData: true,
            attributes: true,
            childList: true,
            subtree: true,
        };
        const observer = new MutationObserver((mutationsList, observer) => {
            props.textChangeHandler(span.current.innerText);
        });
        observer.observe(span.current, config);

        return () => {
            observer.disconnect();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        gauge.current = (
            props.donut ? new Donut(canvas.current) : new CanvasGauge(canvas.current)
        );
        gauge.current.setTextField(span.current);
        gauge.current.setOptions(props.options);
        gauge.current.maxValue = props.maxValue;
        gauge.current.setMinValue(props.minValue);
        gauge.current.animationSpeed = props.animationSpeed;
        gauge.current.set(50);
    }, [props.donut]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.hidelabel) {
            span.current.hidden = true;
            span.current.style.fontSize = '0px';
        }
    }, [props.hidelabel]);

    useEffect(() => {
        gauge.current.setOptions(props.options);
    }, [props.options]);

    useEffect(() => {
        gauge.current.maxValue = props.maxValue;
    }, [props.maxValue]);

    useEffect(() => {
        gauge.current.setMinValue(props.minValue);
    }, [props.minValue]);

    useEffect(() => {
        gauge.current.animationSpeed = props.animationSpeed;
    }, [props.animationSpeed]);

    useEffect(() => {
        if (props.value == 0) {
            gauge.current.set(0.2)
        } else if (props.value >= 100) {
            gauge.current.set(99.9)
        } else {
            gauge.current.set(props.value)
        }
    }, [props.value]);

    /* eslint-disable no-unused-vars */
    const {
        maxValue,
        minValue,
        animationSpeed,
        options,
        donut,
        value,
        textChangeHandler,
        ...passThroughProps
    } = props;
    /* eslint-enable no-unused-vars */

    return (
        <div style={{ zIndex: 1 }}>
            <canvas ref={canvas} {...passThroughProps}></canvas>
            <span ref={span} class="d-flex justify-content-center mt-n4" style={{ display: 'none' }}></span>
        </div>
    );
}

Gauge.defaultProps = {
    maxValue: 100,
    minValue: 0,
    animationSpeed: 32,
    options: {
        angle: 0, // The span of the gauge arc
        lineWidth: 0.29, // The line thickness
        radiusScale: 0.82, // Relative radius
        pointer: {
            length: 0.4, // // Relative to gauge radius
            strokeWidth: 0.02, // The thickness
            color: '#000000' // Fill color
        },
        limitMax: true,     // If false, max value increases automatically if value > maxValue
        limitMin: true,     // If true, the min value of the gauge will be fixed
        generateGradient: true,
        highDpiSupport: true,     // High resolution support
    },
    donut: false,
    textChangeHandler: () => { },
};

Gauge.propTypes = {
    maxValue: PropTypes.number.isRequired,
    minValue: PropTypes.number.isRequired,
    animationSpeed: PropTypes.number.isRequired,
    options: PropTypes.object.isRequired,
    donut: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
    textChangeHandler: PropTypes.func.isRequired,
};

export default Gauge;