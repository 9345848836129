import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Script from 'react-load-script'
import NoAccessPage from './NoAccessPage';

export function Billing(props) {
    const { id } = useParams();
    const [signals, setSignals] = useState([])
    const [siteAddress, setSiteAddress] = useState("Site address")
    const [frequency, setFrequency] = useState(1800)

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id) ,[id]);

    useEffect(() => {
        $.ajax({
            url: '/api/DownloadsAPI/GetSignals',
            type: 'get',
            data: { SiteGUID: id },
            success: function (response) {
                response = JSON.parse(response)
                setSignals(response)
            }
        })

        $.ajax({
            url: '/api/DownloadsAPI/GetSiteAddress',
            type: 'get',
            data: { SiteGUID: id },
            type: 'get',
            success: function (response) {
                setSiteAddress(response)
            }
        })
    }, [id]);

    useEffect(() => {

    }, []);
    return (
        props.auth !== undefined ?
            props.auth > 0 ?
                <div>
                    <h1>Billing</h1>
                    <div class="row d-flex align-items-center">
                        <div class="col-md-6">
                            <div class="card mb-3">
                                <div class="card-body p-0">
                                    <div class="input-group">
                                        <div class="d-none d-lg-block d-xl-block float-left p-3 " style={{ backgroundColor: '#002c4e' }}>
                                            <i class="text-light fas fa-2x fa-map-marker-alt"></i>
                                        </div>
                                        <div class="p-2 d-flex align-items-center">
                                            <div id="siteAddress" class="h5 text-dark">{siteAddress}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 ms-auto">
                            <div id="insightDateRangePickerContainer" class="card mb-3">
                                <div class="card-body p-0">
                                    <div class="input-group">
                                        <div class="d-none d-lg-block d-xl-block p-3 " style={{ backgroundColor: '#002c4e' }}><i class="far fa-2x fa-calendar-alt" style={{ "color": "white" }}></i></div>
                                        <input type="text" class="m-3 form-control" name="daterange" value="01/01/2018 - 01/15/2018" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <Script url="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js" />
                    <Script url="https://cdn.jsdelivr.net/momentjs/latest/moment.min.js" />
                    <Script url="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js" />
                    <Script url="/lib/components/datetimerangepickertrial.js" />
                </div>
                :
                <NoAccessPage />
        : <></>
    );
};
