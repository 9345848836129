import React from 'react';
import { Button, ButtonGroup, Card, ListGroup, ListGroupItem } from 'react-bootstrap';

const FailSafeCard = props => {
    return (
        <Card style={{ width: '18rem', margin: 10, padding: 10 }}>
            <Card.Body>
                <Card.Title>{props.data.signalName}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Affects {props.data.terminalName}</Card.Subtitle>
                <ListGroup className="list-group-flush" style={{ paddingBottom: 10 }}>
                    <ListGroupItem>{props.data.triggerName}</ListGroupItem>
                    <ListGroupItem>{props.data.failSafeName}</ListGroupItem>
                </ListGroup>
                <ButtonGroup>
                    <Button variant='primary' className="fas fa-pencil-alt" onClick={() => props.editSelf(props.data)}></Button>
                    <Button variant='danger' className="far fa-trash-alt" onClick={() => props.deleteSelf(props.data.FailSafeGUID, props.data.DeviceID)}></Button>
                </ButtonGroup>
            </Card.Body>
        </Card>
    );
}

export default FailSafeCard;