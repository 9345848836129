import React, { useState, useContext } from "react";
import BarChartClickableSignalSelect from "./BarChartClickableSignalSelect";
import {
  InputGroup,
  FormControl,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Modal,
  Form,
} from "react-bootstrap";
import { Container, Col, Row } from "react-bootstrap";
import { ConfigContext } from "./BarChart";
import HelpTooltip from "../HelpTooltip";



function DeleteBarChartModal(props) {
  const handleDelete = (barid) => {
    var url = "/api/AnalyticsAPI/RemoveBarChart/"; // TODO
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(barid),
    })
      .then((response) => response.status)
      .then((status) => {
        props.onremove(barid);
        props.onHide();
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Are you sure you want to delete this bar chart?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Deleting a bar chart cannot be undone.</p>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col>
              <Button variant='primary' onClick={props.onHide}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                className='float-end'
                variant='danger'
                onClick={() => handleDelete(props.barid)}
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}

function SaveBarChartModal(props) {
  const handleSave = (config) => {
    props.setLoading(true);
    var newConfig = JSON.parse(JSON.stringify(config));

    if (newConfig.InsightBarChartInputs) {
      // If no ID it is a new LCI, set to 0 so API accepts payload
      newConfig.InsightBarChartInputs.forEach((l) => {
        !l.InsightBarChartInputID
          ? (l.InsightBarChartInputID = 0)
          : (l.InsightBarChartInputID = l.InsightBarChartInputID);
      });
    } else {
      newConfig.InsightBarChartInputs = [];
    }

    var url = "/api/AnalyticsAPI/SaveBarChartSettings/";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newConfig),
    })
      .then((response) => response.json())
      .then((data) => {
        props.updateconfig(data);
        props.onHide();
      });
  };

  return (
    <Modal
      backdrop='static'
      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Are you sure you want to save changes?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>This will overwrite previous settings and cannot be undone.</p>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col>
              <Button variant='primary' onClick={props.onHide}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                className='float-end'
                variant='success'
                onClick={() => handleSave(props.config)}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}

function BarChartSettings(props) {
  const {
    config,
    updateBarChartName,
    updateYAxisTitle,
    updateEquationMode,
    updateConfig,
    setLoading,
  } = useContext(ConfigContext);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [saveModalShow, setSaveModalShow] = useState(false);

  return config ? (
    <>
      <Form style={{ minWidth: "500px" }}>
        <Row>
          <Col>
            <InputGroup className='justify-content-center mb-2'>
              <InputGroup.Text>Bar chart name: </InputGroup.Text>
              <FormControl
                aria-describedby='basic-addon3'
                defaultValue={config.BarChartName}
                onChange={(e) => {
                  updateBarChartName(e.target.value);
                }}
              ></FormControl>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <InputGroup className='justify-content-center mb-2'>
              <InputGroup.Text>Y-Axis Title: </InputGroup.Text>
              <FormControl
                aria-describedby='basic-addon3'
                defaultValue={config.YAxisTitle}
                onChange={(e) => {
                  updateYAxisTitle(e.target.value);
                }}
              ></FormControl>
            </InputGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <InputGroup className='mb-2'>
              <InputGroup.Text>Equation Mode: </InputGroup.Text>
              <ToggleButtonGroup
                type='radio'
                name='equation-mode-options'
                value={String(config?.EquationMode)}
                onChange={(e) => updateEquationMode(e)}
                className='flex-grow-1'
              >
                {Object.keys(props.validEquationModes).map(
                  (eqModeKey) => (
                    <ToggleButton
                      id={`radio-eqMode-${eqModeKey}`}
                      variant='outline-secondary'
                      value={eqModeKey}
                    >
                      {
                        props.validEquationModes[eqModeKey]
                          .displayText
                      }
                    </ToggleButton>
                  )
                )}
              </ToggleButtonGroup>
            </InputGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <InputGroup className='mb-2'>
              <InputGroup.Text id='inputGroup-sizing-default'>
                <HelpTooltip
                  placement='bottom'
                  helptext='Press + to add more signals, or click on a signal to change its colour and style. Click the x to remove a signal. Drag and drop signals to rearrange.'
                />
                Signals:
              </InputGroup.Text>
              <div className='flex-grow-1'>
                <BarChartClickableSignalSelect
                  options={props.signals}
                  value={props.signals.filter((signal) => signal.isSelected)}
                />
              </div>
            </InputGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button variant='danger' onClick={() => setDeleteModalShow(true)}>
              Delete bar chart <i className='fas fa-trash-alt'></i>
            </Button>
            <DeleteBarChartModal
              show={deleteModalShow}
              onHide={() => {
                setDeleteModalShow(false);
                props.hideSettings();
              }}
              onremove={props.onremove}
              barid={config.InsightBarChartID}
            />
          </Col>
          <Col>
            <Button
              className='float-end'
              variant='primary'
              onClick={() => setSaveModalShow(true)}
            >
              Save settings <i className='fas fa-save'></i>
            </Button>
            <SaveBarChartModal
              show={saveModalShow}
              onHide={() => {
                setSaveModalShow(false);
                props.hideSettings();
              }}
              config={config}
              updateconfig={updateConfig}
              setLoading={setLoading}
            />
          </Col>
        </Row>
      </Form>
    </>
  ) : (
    "Loading..."
  );
}

export default BarChartSettings;
