import {
  WaterApplication,
  SensorLocation,
  ConfigurationWarning,
} from "./types";

export const APPLICATION_OPTIONS: WaterApplication[] = [
  { value: "DAF", label: "DAF - Dissolved Air Flotation" },
  { value: "AIMS", label: "AIMS - Automated Inventory Management System" },
  { value: "TW_GM", label: "Trade waste or generic monitoring" },
  { value: "G_SENS", label: "Generic sensors" },
  { value: "W_SENS", label: "Wireless water quality sensor system" },
];

export const LOCATION_NUMBER_OPTIONS = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
];

export const LOCATION_NUMBER_OPTIONS_WIRELESS = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
];

export const DEFAULT_LOCATION: SensorLocation = {
  name: "",
  sensors: [],
};

export const WARNINGS: ConfigurationWarning[] = [
  { description: "Cable lengths missing", critical: true, fix: () => console.log('FIX') },
  { description: "Edge device power supply missing", critical: false },
  { description: "Air line lengths mising", critical: true },
];
