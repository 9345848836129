import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Chart, registerables } from 'chart.js';
import { GaugeComponent } from './reactbenchmarkgauge';
import Masonry from 'masonry-layout';
import { Button, Modal } from 'react-bootstrap';
import Cookies from 'cookies-js';
import NoAccessPage from './NoAccessPage';
import HelpButton from './HelpButton';
import YouTubeVideo from './YouTubeVideo';

Chart.register(...registerables);
var $grid;

//Show info modal if updated since last visit
var CostBreakdownTabVersion = Cookies.get('CostBreakdownTabVersion');
var CurrentCostBreakdownTabVersion = 1;

var Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function ComplianceCard(props) {
    const [opexBar, setOpexBar] = useState(null)
    const [dataavailable, setDataAvailable] = useState(false)
    const [CostPerkL, setCostPerkL] = useState(0)

    useEffect(() => {
        var data = [];
        var volumedata = [];
        var labels = [];

        for (var i = 0; i < props.sitecharges.length; i++) {
            var cost = props.sitecharges[i].ManualWastewaterVolumeCost;
            if (cost != 0) {
                if (i == 0) {
                    labels.push("2020");
                }
                else if (i == 1) {
                    labels.push("2021");
                }
                else {
                    labels.push(Months[props.sitecharges[i].Month - 1] + ' ' + props.sitecharges[i].Year);
                }
                data.push(1.0 * cost / props.sitecharges[i].ManualWastewaterVolume);
                volumedata.push(props.sitecharges[i].ManualWastewaterVolume);
            }
            if ((props.sitecharges[i].ManualWastewaterVolumeCost) != 0) {

                if (props.sitecharges[i].ManualWastewaterVolume != 0) {
                    setDataAvailable(true);
                    setCostPerkL(1.0 * (props.sitecharges[i].ManualWastewaterVolumeCost) / props.sitecharges[i].ManualWastewaterVolume);
                }
            }
        }

        var barChartData = {
            labels: labels,
            datasets: [{
                yAxisID: 'left-y-axis',
                backgroundColor: 'rgba(20, 168, 222, 0.8)',//lightblue
                borderColor: 'rgba(0, 44, 79, 0.8)',//darkblue
                borderWidth: 1,
                label: 'Wastewater Volume Treated (kL)',
                data: volumedata,
                order: 1
            }, {
                yAxisID: 'right-y-axis',
                backgroundColor: 'rgba(244, 121, 32, 1)',//orange
                borderColor: 'rgba(0, 44, 79, 1)',//darkblue
                label: 'Tradewaste Cost per kL ($/kL)',
                borderWidth: 2,
                data: data,
                type: 'line',
                fill: false,
                order: 0
            }]

        };

        var ctx = document.getElementById('compliancecanvas').getContext('2d');
        if (opexBar) {
            opexBar.destroy();
        }
        setOpexBar(new Chart(ctx, {
            type: 'bar',
            data: barChartData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    position: 'top',
                    labels: {
                        usePointStyle: true
                    }
                },
                scales: {
                    'left-y-axis': {
                        beginAtZero: true,
                        position: 'left'
                    },
                    'right-y-axis': {
                        beginAtZero: true,
                        position: 'right'
                    }
                }
            }
        }))
    }, [props.sitecharges]);

    return (
        <div class="grid-item col-md-6 p-0">

            <div class="m-2 shadow-sm card" >
                <div class="card-header">
                    Tradewaste Cost
                </div>
                <div class="card-body">
                    {!dataavailable ? <div class="text-muted text-center nodataverticalalign">
                        <i class="fas fa-info-circle"></i>
                        <div>No Data</div>
                    </div> : ""}
                    <div class={dataavailable ? "d-flex justify-content-center" : "d-flex justify-content-center no-data-blur-effect"}>
                        <div class="col-6 verticalalign" style={{ height: "300px" }}>
                            <canvas style={{ height: "100%" }} id="compliancecanvas"></canvas>
                        </div>
                        <div class="verticalline"></div>
                        <div class="col-6 verticalalign">
                            <GaugeComponent id="compliancecurrentgauge" value={CostPerkL} target={props.sitedetails.ComplianceCostPerkLGoal} benchmark={props.sitedetails.ComplianceCostPerkLBenchmark} />
                            <div class="text-center">Your Latest Tradewaste Expenditure</div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

function ChemicalsCard(props) {
    const [BarChart, setBarChart] = useState(null)
    const [dataavailable, setDataAvailable] = useState(false)
    const [CostPerkL, setCostPerkL] = useState(0)

    useEffect(() => {
        var data = [];
        var volumedata = [];
        var labels = [];
        for (var i = 0; i < props.sitecharges.length; i++) {
            var cost = props.sitecharges[i].ChemicalCost;
            if (cost != 0) {
                if (i == 0) {
                    labels.push("2020");
                }
                else if (i == 1) {
                    labels.push("2021");
                }
                else {
                    labels.push(Months[props.sitecharges[i].Month - 1] + ' ' + props.sitecharges[i].Year);
                }
                data.push(cost / props.sitecharges[i].ManualWastewaterVolume);
                volumedata.push(props.sitecharges[i].ManualWastewaterVolume);
            }

            if (props.sitecharges[i].ChemicalCost != 0) {
                setDataAvailable(true);
                if (props.sitecharges[i].ManualWastewaterVolume != 0) {
                    setCostPerkL(1.0 * props.sitecharges[i].ChemicalCost / props.sitecharges[i].ManualWastewaterVolume);
                }
            }
        }
        var barChartData = {
            labels: labels,
            datasets: [{
                yAxisID: 'left-y-axis',
                backgroundColor: 'rgba(20, 168, 222, 0.8)',//lightblue
                borderColor: 'rgba(0, 44, 79, 0.8)',//darkblue
                borderWidth: 1,
                label: 'Wastewater Volume Treated (kL)',
                data: volumedata,
                order: 1
            }, {
                yAxisID: 'right-y-axis',
                backgroundColor: 'rgba(244, 121, 32, 1)',//orange
                borderColor: 'rgba(0, 44, 79, 1)',//darkblue
                label: 'Chemical Cost per kL ($/kL)',
                borderWidth: 2,
                data: data,
                type: 'line',
                fill: false,
                order: 0
            }]

        };

        var ctx = document.getElementById('chemicalcanvas').getContext('2d');
        if (BarChart) {
            BarChart.destroy();
        }
        setBarChart(new Chart(ctx, {
            type: 'bar',
            data: barChartData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    position: 'top',
                    labels: {
                        usePointStyle: true
                    }
                },
                scales: {
                    'left-y-axis': {
                        beginAtZero: true,
                        position: 'left'
                    },
                    'right-y-axis': {
                        beginAtZero: true,
                        position: 'right'
                    }
                }
            }
        }))
    }, [props.sitecharges]);

    return (
        <div class="grid-item col-md-6 p-0">
            <div class="m-2 shadow-sm card" >
                <div class="card-header">
                    Chemical Cost
                </div>
                <div class="card-body">
                    {!dataavailable ? <div class="text-muted text-center nodataverticalalign">
                        <i class="fas fa-info-circle"></i>
                        <div>No Data</div>
                    </div> : ""}
                    <div class={dataavailable ? "d-flex justify-content-center" : "d-flex justify-content-center no-data-blur-effect"}>
                        <div class="col-6 verticalalign" style={{ height: "300px" }}>
                            <canvas style={{ height: "100%" }} id="chemicalcanvas"></canvas>
                        </div>
                        <div class="verticalline"></div>
                        <div class="col-6 verticalalign">
                            <GaugeComponent id="chemicalcurrentgauge" value={CostPerkL} target={props.sitedetails.ChemicalCostPerkLGoal} benchmark={props.sitedetails.ChemicalCostPerkLBenchmark} />
                            <div class="text-center">Your Latest Chemical Expenditure</div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

function EnergyCard(props) {
    const [BarChart, setBarChart] = useState(null)
    const [dataavailable, setDataAvailable] = useState(false)
    const [CostPerkL, setCostPerkL] = useState(0)

    useEffect(() => {
        var data = [];
        var volumedata = [];
        var labels = [];
        for (var i = 0; i < props.sitecharges.length; i++) {
            labels.push(Months[props.sitecharges[i].Month - 1] + ' ' + props.sitecharges[i].Year);
            data.push(props.sitecharges[i].EnergyCost / props.sitecharges[i].ManualWastewaterVolume);
            volumedata.push(props.sitecharges[i].ManualWastewaterVolume);

            if (props.sitecharges[i].EnergyCost != 0) {
                setDataAvailable(true);
                if (props.sitecharges[i].ManualWastewaterVolume != 0) {
                    setCostPerkL(1.0 * props.sitecharges[i].EnergyCost / props.sitecharges[i].ManualWastewaterVolume);
                }
            }
        }
        var barChartData = {
            labels: labels,
            datasets: [{
                yAxisID: 'left-y-axis',
                backgroundColor: 'rgba(20, 168, 222, 0.8)',//lightblue
                borderColor: 'rgba(0, 44, 79, 0.8)',//darkblue
                borderWidth: 1,
                label: 'Wastewater Volume Treated (kL)',
                data: volumedata,
                order: 1
            }, {
                yAxisID: 'right-y-axis',
                backgroundColor: 'rgba(244, 121, 32, 1)',//orange
                borderColor: 'rgba(0, 44, 79, 1)',//darkblue
                label: 'Energy Cost per kL ($/kL)',
                borderWidth: 2,
                data: data,
                type: 'line',
                fill: false,
                order: 0
            }]

        };

        var ctx = document.getElementById('energycanvas').getContext('2d');
        if (BarChart) {
            BarChart.destroy();
        }
        setBarChart(new Chart(ctx, {
            type: 'bar',
            data: barChartData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    position: 'top',
                    labels: {
                        usePointStyle: true
                    }
                },
                scales: {
                    'left-y-axis': {
                        beginAtZero: true,
                        position: 'left'
                    },
                    'right-y-axis': {
                        beginAtZero: true,
                        position: 'right'
                    }
                }
            }
        }));
    }, [props.sitecharges]);

    return (
        <div class="grid-item col-md-6 p-0">
            <div class="m-2 shadow-sm card" >
                <div class="card-header">
                    Energy Cost
                </div>
                <div class="card-body">
                    {!dataavailable ? <div class="text-muted text-center nodataverticalalign">
                        <i class="fas fa-info-circle"></i>
                        <div>No Data</div>
                    </div> : ""}
                    <div class={dataavailable ? "d-flex justify-content-center" : "d-flex justify-content-center no-data-blur-effect"}>
                        <div class="col-6 verticalalign" style={{ height: "300px" }} >
                            <canvas style={{ height: "100%" }} id="energycanvas"></canvas>
                        </div>
                        <div class="verticalline"></div>
                        <div class="col-6 verticalalign">
                            <GaugeComponent id="energycurrentgauge" value={CostPerkL} target={props.sitedetails.EnergyCostPerkLGoal} benchmark={props.sitedetails.EnergyCostPerkLBenchmark} />
                            <div class="text-center">Your Current Energy Expenditure</div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

function AssetManagementCard(props) {
    const [BarChart, setBarChart] = useState(null)
    const [dataavailable, setDataAvailable] = useState(false)
    const [CostPerkL, setCostPerkL] = useState(0)

    useEffect(() => {
        var data = [];
        var volumedata = [];
        var labels = [];
        for (var i = 0; i < props.sitecharges.length; i++) {
            labels.push(Months[props.sitecharges[i].Month - 1] + ' ' + props.sitecharges[i].Year);
            data.push(props.sitecharges[i].AssetCost / props.sitecharges[i].ManualWastewaterVolume);
            volumedata.push(props.sitecharges[i].ManualWastewaterVolume);

            if (props.sitecharges[i].AssetCost != 0) {
                setDataAvailable(true);
                if (props.sitecharges[i].ManualWastewaterVolume != 0) {
                    setCostPerkL(1.0 * props.sitecharges[i].AssetCost / props.sitecharges[i].ManualWastewaterVolume);
                }
            }
        }
        var barChartData = {
            labels: labels,
            datasets: [{
                yAxisID: 'left-y-axis',
                backgroundColor: 'rgba(20, 168, 222, 0.8)',//lightblue
                borderColor: 'rgba(0, 44, 79, 0.8)',//darkblue
                borderWidth: 1,
                label: 'Wastewater Volume Treated (kL)',
                data: volumedata,
                order: 1
            }, {
                yAxisID: 'right-y-axis',
                backgroundColor: 'rgba(244, 121, 32, 1)',//orange
                borderColor: 'rgba(0, 44, 79, 1)',//darkblue
                label: 'Asset Management Cost per kL ($/kL)',
                borderWidth: 2,
                data: data,
                type: 'line',
                fill: false,
                order: 0
            }]

        };

        var ctx = document.getElementById('assetcanvas').getContext('2d');
        if (BarChart) {
            BarChart.destroy();
        }
        setBarChart(new Chart(ctx, {
            type: 'bar',
            data: barChartData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    position: 'top',
                    labels: {
                        usePointStyle: true
                    }
                },
                scales: {
                    'left-y-axis': {
                        beginAtZero: true,
                        position: 'left'
                    },
                    'right-y-axis': {
                        beginAtZero: true,
                        position: 'right'
                    }
                }
            }
        }));
    }, [props.sitecharges]);

    return (
        <div class="grid-item col-md-6 p-0">

            <div class="m-2 shadow-sm card" >
                <div class="card-header">
                    Asset Management Cost
                </div>
                <div class="card-body">
                    {!dataavailable ? <div class="text-muted text-center nodataverticalalign">
                        <i class="fas fa-info-circle"></i>
                        <div>No Data</div>
                    </div> : ""}
                    <div class={dataavailable ? "d-flex justify-content-center" : "d-flex justify-content-center no-data-blur-effect"}>
                        <div class="col-6 verticalalign" style={{ height: "300px" }}>
                            <canvas style={{ height: "100%" }} id="assetcanvas"></canvas>
                        </div>
                        <div class="verticalline"></div>
                        <div class="col-6 verticalalign">
                            <GaugeComponent id="assetcurrentgauge" value={CostPerkL} target={props.sitedetails.AssetCostPerkLGoal} benchmark={props.sitedetails.AssetCostPerkLBenchmark} />
                            <div class="text-center">Your Current Asset Management Expenditure</div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export function CostBreakdown(props) {
    const { id } = useParams();
    const [sitecharges, setSiteCharges] = useState([])
    const [showversioncontrolmodal, setShowVersionControlModal] = useState(false)
    const [sitedetails, setSiteDetails] = useState({
        AssetCostPerkLBenchmark: 0,
        ChemicalCostPerkLBenchmark: 0,
        ComplianceCostPerkLBenchmark: 0,
        EnergyCostPerkLBenchmark: 0,
        AssetCostPerkLGoal: 0,
        ChemicalCostPerkLGoal: 0,
        ComplianceCostPerkLGoal: 0,
        EnergyCostPerkLGoal: 0,
    })
    let history = useHistory();

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id), [id]);

    function handleClose() {
        setShowVersionControlModal(false)
    }

    function CheckVersion() {
        if (CostBreakdownTabVersion < CurrentCostBreakdownTabVersion) {
            //Show modal and update cost breakdown tab version for user so they don't see the message on other devices
            var fd = {
                "CostBreakdownTabVersion": CurrentCostBreakdownTabVersion
            };
            $.ajax({
                url: '/api/VersionControlAPI/SetCostBreakdownTabVersion',
                data: fd,
                type: 'get',
                success: function (response) {
                    CostBreakdownTabVersion = CurrentCostBreakdownTabVersion;
                    Cookies.set('CostBreakdownTabVersion', CurrentCostBreakdownTabVersion, { expires: 7999 });

                }
            });
            setShowVersionControlModal(true)
        }
    }

    useEffect(() => {
        // Version control modal
        CheckVersion()
        //First run
        if (CostBreakdownTabVersion == undefined || CostBreakdownTabVersion < CurrentCostBreakdownTabVersion) {
            //Get version from server
            $.ajax({
                url: '/api/VersionControlAPI',
                type: 'get',
                success: function (response) {
                    console.log(response);

                    CostBreakdownTabVersion = response.costBreakdownTabVersion;
                    Cookies.set('HomeTabVersion', response.homeTabVersion, { expires: 7999 });
                    Cookies.set('AnalyticsTabVersion', response.analyticsTabVersion, { expires: 7999 });
                    Cookies.set('HealthSummaryTabVersion', response.healthSummaryTabVersion, { expires: 7999 });
                    Cookies.set('CostBreakdownTabVersion', response.costBreakdownTabVersion, { expires: 7999 });
                    Cookies.set('DeepDiveTabVersion', response.deepDiveTabVersion, { expires: 7999 });
                    Cookies.set('MaintenanceTabVersion', response.maintenanceTabVersion, { expires: 7999 });
                    Cookies.set('BillingTabVersion', response.billingTabVersion, { expires: 7999 });
                    Cookies.set('AlarmsTabVersion', response.alarmsTabVersion, { expires: 7999 });
                    Cookies.set('ControlTabVersion', response.controlTabVersion, { expires: 7999 });
                    Cookies.set('CalibrationsTabVersion', response.calibrationsTabVersion, { expires: 7999 });
                    Cookies.set('AirCleaningTabVersion', response.airCleaningTabVersion, { expires: 7999 });
                    Cookies.set('ReportsTabVersion', response.reportsTabVersion, { expires: 7999 });
                    Cookies.set('SiteDetailsTabVersion', response.siteDetailsTabVersion, { expires: 7999 });
                    Cookies.set('DownloadsTabVersion', response.downloadsTabVersion, { expires: 7999 });

                    CheckVersion();
                }
            });
        }

        InitialiseMasonry();
    }, []);

    useEffect(() => {
        InitialiseMasonry();
        $.ajax({
            url: '/api/DigitalTwinAPI/GetSiteDetails/' + id,
            type: 'get',
            success: function (response) {
                response = JSON.parse(response);

                setSiteDetails(response)
                InitialiseMasonry();
            }
        });

        $.ajax({
            url: '/api/DigitalTwinAPI/GetSiteCharges',
            type: 'get',
            data: { 'SiteGUID': id },
            success: function (response) {
                response = JSON.parse(response);
                console.log(response);
                setSiteCharges(response)
                InitialiseMasonry();
            }
        });

    }, [id]);

    function InitialiseMasonry() {
        try {
            var masonryOptions = {
                itemSelector: '.grid-item',
                animate: true,
                stagger: 50,
                columnWidth: '.grid-sizer',
                percentPosition: true
            };

            var elem = document.querySelector('#masonrycards');
            $grid = new Masonry(elem, masonryOptions);

            $grid.reloadItems();
            $grid.layout();
        }
        catch (exception) {
            console.log(exception)
            setTimeout(function () {
                InitialiseMasonry();
            }, 500)
        }
    }

    return (
        props.auth !== undefined ?
            props.auth > 0 ?
                <>
                    <Link onClick={history.goBack} className="align-items-center"><div className="m-2"><i class="fad fa-arrow-left"></i> Back</div></Link>
                    <HelpButton showHelpModal={setShowVersionControlModal} />
                    <div id="masonrycards" class="container-fluid p-0" style={{ marginTop: '10px' }}>
                        <div class="grid-sizer col-md-1"></div>
                        <ComplianceCard sitecharges={sitecharges} sitedetails={sitedetails} />
                        <ChemicalsCard sitecharges={sitecharges} sitedetails={sitedetails} />
                        {/*<AssetManagementCard sitecharges={sitecharges} sitedetails={sitedetails} />
                        <EnergyCard sitecharges={sitecharges} sitedetails={sitedetails} />
*/}
                        <Modal show={showversioncontrolmodal} onHide={handleClose} size="lg">
                            <Modal.Header closeButton>
                                <h5 class="modal-title" id="exampleModalLongTitle">What's New?</h5>
                            </Modal.Header>
                            <Modal.Body className="text-center">
                                <YouTubeVideo VideoTag="CostBreakdown" />
                                <p className="text-start">The new "Cost Breakdown" tab shows the components of the OPEX calculation described in the "Health Summary". The total OPEX is divided into four components: compliance, chemicals, asset management and energy. For each component, a brief trend and graphic is displayed.</p>
                                <p class="d-flex justify-content-center"><img class="w-75 card shadow p-3" src="/images/HowTos/healthsummary3.png" alt="OPEX Trend" /></p>
                                <p class="d-flex justify-content-center"><img class="w-75 card shadow p-3" src="/images/HowTos/healthsummary4.png" alt="OPEX Journey" /></p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
                :
                <NoAccessPage />
            :
            <></>);
};
