import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import * as signalR from "@microsoft/signalr";
import Masonry from 'masonry-layout';
import AirCleaningCard from './AirCleaningCard';
import PumpCard from './PumpCard';

var $grid;
// Device component wrapper to store cards and data
const DeviceTab = ({ deviceID, active }) => {
    var connection = new signalR.HubConnectionBuilder()
        .withUrl("/messageHub")
        .withAutomaticReconnect()
        .build();

    const [loadingsignals, setLoadingSignals] = useState(true)
    const [aircleaners, setAirCleaners] = useState([]);
    const [pumps, setPumps] = useState([]);
    const [latestData, setLatestData] = useState({});

    // Populate cards to selected device
    useEffect(() => {
        let tag = "";
        if (active) {
            console.log(deviceID + " active")
            // Begin signal R
            connection.start().then(() => {
                tag = "DoSomething" + connection.connectionId;
                $.ajax({
                    url: "/api/SharedCommandsAPI/ToggleSignalR",
                    type: "get",
                    data: { deviceID: deviceID, listenerTag: tag, state: true }
                });

                connection.on(tag, message => {
                    setLatestData(JSON.parse(message));
                });
            }).catch(e => console.log('Connection failed: ', e));

            // Air cleaning solenoids
            $.ajax({
                url: '/api/ControlAPI/GetAirCleaning',
                type: 'get',
                data: { deviceID: deviceID },
                success: (response) => {
                    response = JSON.parse(response);
                    setAirCleaners(response);
                    setLoadingSignals(false);
                },
                error: () => setLoadingSignals(false)
            })
            // Pumps
            $.ajax({
                url: '/api/ControlAPI/GetPumps',
                type: 'get',
                data: { deviceID: deviceID },
                success: (response) => {
                    response = JSON.parse(response);
                    setPumps(response)
                    setLoadingSignals(false);

                    var masonryOptions = {
                        itemSelector: '.grid-item',
                        animate: true,
                        stagger: 50,
                        columnWidth: '.grid-sizer',
                        percentPosition: true
                    };

                    var elem = document.querySelector('#' + deviceID + '-actuatorcards');
                    $grid = new Masonry(elem, masonryOptions);

                    setTimeout(function () {
                        try {
                            if (response.length > 0) {
                                $grid.reloadItems();
                            }
                            $grid.layout();
                        }
                        catch (exception) {
                        }
                    }, 1000);
                },
                error: () => setLoadingSignals(false)
            });
        }
        // Stop signal streaming on unmount
        return () => {
            $.ajax({
                url: "/api/SharedCommandsAPI/ToggleSignalR",
                type: "get",
                data: { deviceID: deviceID, listenerTag: tag, state: false }
            });
        }
    }, [active]);

    return (
        <div className="p-0 d-flex flex-wrap container-fluid" id={deviceID + "-actuatorcards"}>
            <div class="grid-sizer col-md-1"></div>
            {loadingsignals ?
                <div className="grid-item col-12 p-0">
                    <div className="m-2 shadow p-0 card" >
                        <div className="card-header">Fetching signal information...</div>
                        <div className="card-body p-3">
                            Fetching signal information...
                            <img style={{ height: '24px', width: '24px' }} src="/lib/ajax-loader.gif" />
                        </div>
                    </div>
                </div> : ((aircleaners.length == 0 && pumps.length == 0) ?
                    <div className="grid-item col-12 p-0">
                        <div className="m-2 shadow p-0 card" >
                            <div className="card-header">No signals available</div>
                            <div className="card-body p-3">
                                No signals available.
                            </div>
                        </div>
                    </div> :
                    [
                        ...aircleaners.map(item =>
                            <AirCleaningCard
                                key={item.SolenoidID}
                                data={item}
                                $grid={$grid}
                                deviceID={deviceID}
                                newData={latestData} />),
                        ...pumps.map(item =>
                            <PumpCard $grid={$grid}
                                key={item.PumpID}
                                data={item}
                                deviceID={deviceID}
                                newData={latestData} />)
                    ]
                )}
        </div>
    );
}

export default DeviceTab;