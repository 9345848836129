import { StreamwiseNode } from "../types";
import "./styles.scss";
import EdgeDevice from "./EdgeDevice";
import AirCleaning from "./Expansion Modules/AirCleaning";
import WirelessSensor from "./WirelessSensor";
import WiredSensor from "./WiredSensor";
import TwoSplitter from "./Splitters/TwoSplitter";
import TwoSplitterB from "./Splitters/TwoSplitterB";
import TwoSplitterC from "./Splitters/TwoSplitterC";
import LocationGroup from "./LocationGroup";
import AirTee from "./Splitters/AirTee";
import AirTeeB from "./Splitters/AirTeeB";
import AirTeeC from "./Splitters/AirTeeC";
import AnalogInput from "./Expansion Modules/AnalogInput";
import AnalogOutput from "./Expansion Modules/AnalogOutput";
import WirelessModule from "./Expansion Modules/WirelessModule";
import DigitalIO from "./Expansion Modules/DigitalIO";

export const nodeTypes = {
  edgeDevice: EdgeDevice,
  airCleaning: AirCleaning,
  phSensor: WiredSensor,
  tssSensorLR: WiredSensor,
  tssSensorHR: WiredSensor,
  conductivitySensorIL: WiredSensor,
  conductivitySensorIS: WiredSensor,
  wirelessPhSensor: WirelessSensor,
  wirelessTssSensorLR: WirelessSensor,
  wirelessTssSensorHR: WirelessSensor,
  wirelessConductivitySensor: WirelessSensor,
  wirelessLevelSensor: WirelessSensor,
  wirelessIOModule: WirelessSensor,
  twoSplitter: TwoSplitter,
  twoSplitterB: TwoSplitterB,
  twoSplitterC: TwoSplitterC,
  locationGroup: LocationGroup,
  airSplitter: AirTee,
  airSplitterB: AirTeeB,
  airSplitterC: AirTeeC,
  analogInputModule: AnalogInput,
  analogOutputModule: AnalogOutput,
  digitalIOModule: DigitalIO,
  wirelessModule: WirelessModule,
};

export const SW_NODE_LIST: StreamwiseNode[] = [
  {
    id: "edgeDevice",
    position: { x: 240, y: 0 },
    width: 100,
    type: "edgeDevice",
    data: {
      name: "Edge device", 
      sku: "SWDI-EDGE-07", 
      moduleFamily: "root" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "airCleaning",
    data: {
      name: "Smart air module",
      sku: "ADDON-AIR-01",
      moduleFamily: "expansion",
      width: 100,
    },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "twoSplitter",
    extent: "parent",
    data: { 
      name: "Cable splitter",
      shortName: 'Splitter', 
      sku: "SWDI-SPLIT-1", 
      moduleFamily: "cableSplitter" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "twoSplitterB",
    extent: "parent",
    data: { 
      name: "Cable splitter", 
      shortName: 'Splitter', 
      sku: "SWDI-SPLIT-1", 
      moduleFamily: "cableSplitter" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "twoSplitterC",
    extent: "parent",
    data: { 
      name: "Cable splitter", 
      shortName: 'Splitter', 
      sku: "SWDI-SPLIT-1", 
      moduleFamily: "cableSplitter" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "airSplitter",
    extent: "parent",
    data: { 
      name: "Air splitter", 
      shortName: 'Splitter', 
      sku: "SWDI-SPLIT-2", 
      moduleFamily: "airSplitter" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "airSplitterB",
    extent: "parent",
    data: { 
      name: "Air splitter", 
      shortName: 'Splitter', 
      sku: "SWDI-SPLIT-2", 
      moduleFamily: "airSplitter" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "airSplitterC",
    extent: "parent",
    data: { 
      name: "Air splitter", 
      shortName: 'Splitter', 
      sku: "SWDI-SPLIT-2", 
      moduleFamily: "airSplitter" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "analogInputModule",
    data: {
      name: "Analog input module",
      sku: "ADDON-ANIN-01",
      moduleFamily: "expansion",
      width: 150,
    },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "analogOutputModule",
    data: {
      name: "Analog output module",
      sku: "ADDON-ANOU-01",
      moduleFamily: "expansion",
      width: 150,
    },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "digitalIOModule",
    data: {
      name: "Digital I/O module",
      sku: "ADDON-DIIO-01",
      moduleFamily: "expansion",
      width: 150,
    },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "wirelessModule",
    data: {
      name: "Wireless receiver module",
      shortName: 'Wireless module', 
      sku: "ADDON-LORA-01",
      moduleFamily: "expansion",
      width: 150,
    },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "phSensor",
    extent: "parent",
    data: { 
      name: "pH/temperature sensor", 
      shortName: 'pH Sensor', 
      sku: "PROBE-PH-03", 
      moduleFamily: "sensor" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "tssSensorLR",
    extent: "parent",
    data: { 
      name: "Low Range TSS/Turbidity sensor", 
      shortName: 'LR TSS sensor', 
      sku: "PROBE-TURB-03", 
      moduleFamily: "sensor" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "tssSensorHR",
    extent: "parent",
    data: { 
      name: "High Range TSS/Turbidity sensor", 
      shortName: 'HR TSS sensor', 
      sku: "PROBE-TSS-03", 
      moduleFamily: "sensor" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "conductivitySensorIL",
    extent: "parent",
    data: { 
      name: "Inline conductivity sensor", 
      shortName: 'IL cond. sensor', 
      sku: "PROBE-COND-01", 
      moduleFamily: "sensor" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "conductivitySensorIS",
    extent: "parent",
    data: { 
      name: "In situ conductivity sensor", 
      shortName: 'IS cond. sensor', 
      sku: "PROBE-COND-02", 
      moduleFamily: "sensor" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "wirelessPhSensor",
    extent: "parent",
    data: { 
      name: "Wireless pH sensor", 
      shortName: 'W/L pH sensor', 
      sku: "WLSS-PH-01", 
      moduleFamily: "wirelessSensor" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "wirelessTssSensorLR",
    extent: "parent",
    data: { 
      name: "Wireless low range TSS sensor", 
      shortName: 'W/L LR TSS sensor', 
      sku: "WLSS-TURB-01", 
      moduleFamily: "wirelessSensor" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "wirelessTssSensorHR",
    extent: "parent",
    data: { 
      name: "Wireless high range TSS sensor", 
      shortName: 'W/L HR TSS sensor', 
      sku: "WLSS-TSS-01", 
      moduleFamily: "wirelessSensor" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "wirelessConductivitySensor",
    extent: "parent",
    data: { 
      name: "Wireless conductivity sensor", 
      shortName: 'W/L cond sensor', 
      sku: "WLSS-COND-01", 
      moduleFamily: "wirelessSensor" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "wirelessLevelSensor",
    extent: "parent",
    data: { 
      name: "Wireless level sensor", 
      shortName: 'W/L level sensor', 
      sku: "WLSS-LVL-01", 
      moduleFamily: "wirelessSensor" },
  },
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "wirelessIOModule",
    extent: "parent",
    data: { 
      name: "Wireless I/O module", 
      shortName: 'W/L I/O module', 
      sku: "WLSS-NODE-01", 
      moduleFamily: "wirelessSensor" },
  },
  {
    id: "",
    type: "locationGroup",
    data: {
      name: "Sensor location",
      sku: "",
      label: "",
      moduleFamily: "location",
    },
    position: { x: 0, y: 0 },
    style: {
      width: 120,
      height: 190,
    },
  },
];
