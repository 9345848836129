import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { Link, useParams } from 'react-router-dom';
import GaugeComponent from './reactbenchmarkgauge';
import { Chart, registerables } from 'chart.js';
import Masonry from 'masonry-layout';
import { Button, Modal } from 'react-bootstrap';
import Cookies from 'cookies-js';
import NoAccessPage from './NoAccessPage';
import HelpButton from './HelpButton';
import YouTubeVideo from './YouTubeVideo';

Chart.register(...registerables);

var Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

var $grid;

//Show info modal if updated since last visit
var HealthSummaryTabVersion = Cookies.get('DownloadsTabVersion');
var CurrentHealthSummaryTabVersion = 1;

function StateCard(props) {
    const [opexBar, setOpexBar] = useState(null)
    const [dataavailable, setDataAvailable] = useState(false)
    const [CostPerkL, setCostPerkL] = useState(0)

    useEffect(() => {
        var data = [];
        var volumedata = [];
        var labels = [];
        var i;
        for (i = 0; i < props.sitecharges.length; i++) {
            var cost = (props.sitecharges[i].TSSCost + props.sitecharges[i].pHCost + props.sitecharges[i].BODCost +
                props.sitecharges[i].SolubleBODCost + props.sitecharges[i].CODCost + props.sitecharges[i].FOGCost +
                props.sitecharges[i].NitrogenCost + props.sitecharges[i].PhosphorusCost +
                props.sitecharges[i].TemperatureCost + props.sitecharges[i].EnergyCost +
                props.sitecharges[i].AssetCost + props.sitecharges[i].ChemicalCost + props.sitecharges[i].ManualWastewaterVolumeCost);
            if (cost != 0) {
                if (i == 0) {
                    labels.push("2020");
                }
                else if (i == 1) {
                    labels.push("2021");
                }
                else {
                    labels.push(Months[props.sitecharges[i].Month - 1] + ' ' + props.sitecharges[i].Year);
                }
                data.push(1.0 * cost / props.sitecharges[i].ManualWastewaterVolume);
                volumedata.push(props.sitecharges[i].ManualWastewaterVolume);
            }

            if ((1.0 * (props.sitecharges[i].TSSCost + props.sitecharges[i].pHCost + props.sitecharges[i].BODCost +
                props.sitecharges[i].SolubleBODCost + props.sitecharges[i].CODCost + props.sitecharges[i].FOGCost +
                props.sitecharges[i].NitrogenCost + props.sitecharges[i].PhosphorusCost +
                props.sitecharges[i].TemperatureCost + props.sitecharges[i].EnergyCost +
                props.sitecharges[i].AssetCost + props.sitecharges[i].ChemicalCost + props.sitecharges[i].ManualWastewaterVolumeCost)) != 0) {


                if (props.sitecharges[i].ManualWastewaterVolume != 0) {
                    setDataAvailable(true);
                    setCostPerkL(1.0 * (props.sitecharges[i].TSSCost + props.sitecharges[i].pHCost + props.sitecharges[i].BODCost +
                        props.sitecharges[i].SolubleBODCost + props.sitecharges[i].CODCost + props.sitecharges[i].FOGCost +
                        props.sitecharges[i].NitrogenCost + props.sitecharges[i].PhosphorusCost +
                        props.sitecharges[i].TemperatureCost + props.sitecharges[i].EnergyCost +
                        props.sitecharges[i].AssetCost + props.sitecharges[i].ChemicalCost + props.sitecharges[i].ManualWastewaterVolumeCost) / props.sitecharges[i].ManualWastewaterVolume);
                }
            }
        }
        var barChartData = {
            labels: labels,
            datasets: [{
                backgroundColor: 'rgba(20, 168, 222, 0.8)',//lightblue
                borderColor: 'rgba(0, 44, 79, 0.8)',//darkblue
                borderWidth: 1,
                label: 'Wastewater Volume Treated (kL)',
                data: volumedata,
                order: 1
            }, {
                yAxisID: 'right-y-axis',
                backgroundColor: 'rgba(244, 121, 32, 1)',//orange
                borderColor: 'rgba(0, 44, 79, 1)',//darkblue
                label: 'OPEX Costs per kL ($/kL)',
                borderWidth: 2,
                data: data,
                type: 'line',
                fill: false,
                order: 0
            }]

        };

        var ctx = document.getElementById('opexcanvas').getContext('2d');
        if (opexBar) {
            opexBar.destroy();
        }
        setOpexBar(new Chart(ctx, {
            type: 'bar',
            data: barChartData,
            options: {
                responsive: true,
                legend: {
                    position: 'top',
                    labels: {
                        usePointStyle: true
                    }
                },
                scales: {
                    'left-y-axis': {
                        beginAtZero: true,
                        position: 'left'
                    },
                    'right-y-axis': {
                        beginAtZero: true,
                        position: 'right'
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            var label = data.datasets[tooltipItem.datasetIndex].label || '';

                            if (label) {
                                label += ': ';
                            }
                            label += Math.round(tooltipItem.yLabel * 100) / 100;
                            return label;
                        }
                    }
                }
            }
        }));
    }, [props.sitecharges]);

    return (
        <div class="grid-item col-md-12 p-0">
            <div class="m-2 shadow-sm card" >
                <div class="card-header">
                    Your OPEX History
                </div>
                <div class="card-body p-3">
                    {!dataavailable ? <div class="text-muted text-center nodataverticalalign">
                        <i class="fas fa-info-circle"></i>
                        <div>No Data</div>
                    </div> : ""}
                    <div class={dataavailable ? "d-flex justify-content-center" : "d-flex justify-content-center no-data-blur-effect"}>
                        <div class="col-6 verticalalign" >
                            <canvas className="sitedetailsbargraph" style={{ maxHeight: "300px" }} id="opexcanvas"></canvas>
                        </div>
                        <div class="verticalline"></div>
                        <div class="col-6 verticalalign">
                            <GaugeComponent id="complianceopexgauge" value={CostPerkL} target={props.target} benchmark={props.benchmark} />
                            <div class="text-center">Your Latest OPEX</div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export function HealthSummary(props) {
    const { id } = useParams();
    const [sitecharges, setSiteCharges] = useState([])
    const [showversioncontrolmodal, setShowVersionControlModal] = useState(false)
    const [sitedetails, setSiteDetails] = useState({
        AssetCostPerkLBenchmark: 0,
        ChemicalCostPerkLBenchmark: 0,
        ComplianceCostPerkLBenchmark: 0,
        EnergyCostPerkLBenchmark: 0,
        AssetCostPerkLGoal: 0,
        ChemicalCostPerkLGoal: 0,
        ComplianceCostPerkLGoal: 0,
        EnergyCostPerkLGoal: 0,
    })

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id), [id]);


    function handleClose() {
        setShowVersionControlModal(false)
    }

    function CheckVersion() {
        if (HealthSummaryTabVersion < CurrentHealthSummaryTabVersion) {
            //Show modal and update home tab version for user so they don't see the message on other devices
            var fd = {
                "HealthSummaryTabVersion": CurrentHealthSummaryTabVersion
            };
            $.ajax({
                url: '/api/VersionControlAPI/SetHealthSummaryTabVersion',
                data: fd,
                type: 'get',
                success: function (response) {
                    HealthSummaryTabVersion = CurrentHealthSummaryTabVersion;
                    Cookies.set('HealthSummaryTabVersion', CurrentHealthSummaryTabVersion, { expires: 7999 });

                }
            });
            setShowVersionControlModal(true)
        }
    }

    useEffect(() => {
        // Version control modal
        CheckVersion()
        //First run
        if (HealthSummaryTabVersion == undefined || HealthSummaryTabVersion < CurrentHealthSummaryTabVersion) {
            //Get version from server
            $.ajax({
                url: '/api/VersionControlAPI',
                type: 'get',
                success: function (response) {
                    console.log(response);

                    HealthSummaryTabVersion = response.healthSummaryTabVersion;
                    Cookies.set('HomeTabVersion', response.homeTabVersion, { expires: 7999 });
                    Cookies.set('AnalyticsTabVersion', response.analyticsTabVersion, { expires: 7999 });
                    Cookies.set('HealthSummaryTabVersion', response.healthSummaryTabVersion, { expires: 7999 });
                    Cookies.set('CostBreakdownTabVersion', response.costBreakdownTabVersion, { expires: 7999 });
                    Cookies.set('DeepDiveTabVersion', response.deepDiveTabVersion, { expires: 7999 });
                    Cookies.set('MaintenanceTabVersion', response.maintenanceTabVersion, { expires: 7999 });
                    Cookies.set('BillingTabVersion', response.billingTabVersion, { expires: 7999 });
                    Cookies.set('AlarmsTabVersion', response.alarmsTabVersion, { expires: 7999 });
                    Cookies.set('ControlTabVersion', response.controlTabVersion, { expires: 7999 });
                    Cookies.set('CalibrationsTabVersion', response.calibrationsTabVersion, { expires: 7999 });
                    Cookies.set('AirCleaningTabVersion', response.airCleaningTabVersion, { expires: 7999 });
                    Cookies.set('ReportsTabVersion', response.reportsTabVersion, { expires: 7999 });
                    Cookies.set('SiteDetailsTabVersion', response.siteDetailsTabVersion, { expires: 7999 });
                    Cookies.set('DownloadsTabVersion', response.downloadsTabVersion, { expires: 7999 });

                    CheckVersion();
                }
            });
        }
    }, []);

    useEffect(() => {

        $.ajax({
            url: '/api/DigitalTwinAPI/GetSiteDetails/' + id,
            type: 'get',
            success: function (response) {
                response = JSON.parse(response);

                setSiteDetails(response)
            }
        });

        $.ajax({
            url: '/api/DigitalTwinAPI/GetSiteCharges',
            type: 'get',
            data: { 'SiteGUID': id },
            success: function (response) {
                response = JSON.parse(response);
                setSiteCharges(response)

            }
        });

        //Masonry
        var masonryOptions = {
            itemSelector: '.grid-item',
            animate: true,
            stagger: 50,
            columnWidth: '.grid-sizer',
            percentPosition: true
        };

        var elem = document.querySelector('#masonrycards');
        $grid = new Masonry(elem, masonryOptions);
        try {
            $grid.reloadItems();
            $grid.layout();
        }
        catch (err) {
            console.log(err);
        }
    }, [id]);

    var benchmark = sitedetails.AssetCostPerkLBenchmark + sitedetails.ChemicalCostPerkLBenchmark +
        sitedetails.ComplianceCostPerkLBenchmark + sitedetails.EnergyCostPerkLBenchmark;
    var target = sitedetails.AssetCostPerkLGoal + sitedetails.ChemicalCostPerkLGoal +
        sitedetails.ComplianceCostPerkLGoal + sitedetails.EnergyCostPerkLGoal;

    return (
        props.auth !== undefined ?
            props.auth > 0 ?
                <>
                    <HelpButton showHelpModal={setShowVersionControlModal} />
                    <div>
                        <div id="masonrycards" class="container-fluid p-0" style={{ marginTop: '40px' }}>
                            <div class="grid-sizer col-md-1"></div>
                            <StateCard sitecharges={sitecharges} target={target} benchmark={benchmark} />
                            <div class="grid-item col-12 p-2 text-right">
                                <Link to={"/CostBreakdown/" + id} class="btn btn-outline-primary">Cost Breakdown <i className="fad fa-chevron-double-right"></i></Link>
                            </div>
                        </div>

                        <Modal show={showversioncontrolmodal} onHide={handleClose} size="lg">
                            <Modal.Header closeButton>
                                <h5 class="modal-title" id="exampleModalLongTitle">What's New?</h5>
                            </Modal.Header>
                            <Modal.Body className="text-center">
                                <YouTubeVideo VideoTag="HealthSummary" />
                                <p className="text-start">The new "Health Summary" tab shows your progress in reducing operating costs (OPEX) since Streamwise D.I. optimization was initiated. Plus, you can track where you are in the process and which steps remain. For details on each step, simply hover over each box.</p>
                                <p class="text-center"><img class="w-75" src="/images/HowTos/healthsummary1.png" alt="Streamwise D.I. Journey" /></p>
                                <p className="text-start">This tile describes the amount currently owing.</p>
                                <p class="text-center"><img class="w-25" src="/images/HowTos/healthsummary2.png" alt="Your Balance" /></p>
                                <p className="text-start">This graph shows the trend of OPEX for up to 3 months.</p>
                                <p class="text-center"><img class="w-75" src="/images/HowTos/healthsummary3.png" alt="OPEX Trend" /></p>
                                <p className="text-start">This graphic describes the change in OPEX since the commencement of Streamwise D.I. optimisation. The OPEX before Streamwise D.I. optimisation is on the right, and the target OPEX calculated by Streamwise D.I. and agreed by site is on the left.</p>
                                <p class="text-center"><img class="w-75" src="/images/HowTos/healthsummary4.png" alt="OPEX Journey" /></p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
                :
                <NoAccessPage />
            :
            <></>
    );
};
