import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import { StreamwiseNodeData } from '../types';
import './styles.scss'
import { Button } from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
//import {faCircleTrash, faCircleInfo} from '@fortawesome/pro-solid-svg-icons'

const PORT_POSITIONS = [20, 40]

export default memo(
  ({
    id,
    data,
  }: {
    id: string;
    data: StreamwiseNodeData;
  }) => {
    return (
      <>
      <div className="nodeText">{data.name}</div>
      <Handle
        type="target"
        position="left"
        id="INPUT"
        style={{ top: PORT_POSITIONS[1], background: "#5dc7f5" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={false}
      />
    </>
    );
  }
);