import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { ChartSignalObject, ChartDataObject } from './types';

type ControlChartContainerProps = {
    chart: JSX.Element,
    inputData: ChartSignalObject[],
    outputData: ChartSignalObject[],
    setpoint?: number
}

export const ControlChartContainer = ({ chart, inputData, outputData, setpoint }: ControlChartContainerProps) => {
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (inputData.length > 0 || outputData.length > 0) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [inputData, outputData]);

    const latestValue = (dataset: ChartDataObject[]) => {
        var i = dataset.length;
        while (i > 0) {
            if (dataset[i - 1] !== undefined) {
                return Math.round(dataset[i - 1].y * 100) / 100;
            }
            i = i - 1;
        }
        return null
    }
    
    return (
        <div style={ContainerStyle}>
            {loading ?
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Spinner animation="border" />
                    <br />
                    <div>Control chart loading...</div>
                </div> :
                <>
                    {chart}
                    <br />
                    <div style={{ display: 'flex', justifyContent: "space-around", width: '100%' }}>
                        {inputData.map(inSig =>
                            <h5 key={inSig.signalID}>
                                {inSig.signalName}: {latestValue(inSig.dataset)}
                            </h5>
                        )}
                        {setpoint !== undefined && <h5>Setpoint: {setpoint}</h5>}
                        {outputData.map(outSig =>
                            <h5 key={outSig.signalID}>
                                {outSig.signalName}: {latestValue(outSig.dataset)}
                            </h5>
                        )}
                    </div>
                </>
            }
    </div>);
}

// Styles
const ContainerStyle: React.CSSProperties = {
    border: '1px solid #8b9ead',
    borderRadius: '10px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
};