import React, {useState} from "react";
import { Button } from "react-bootstrap";
import { SW_NODE_LIST } from "./Nodes";
import { Modal, FormControl} from "react-bootstrap";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { StreamwiseNode, StreamwiseEdge} from "./types";

function LocationSelectModal(props) {
  const [location, setLocation] = useState('');
  return(
    <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Choose location
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Select the location this sensor should be added to or create a new one by typing in a new name
        </p>
        <CreatableSelect
            options={props.locations}
            onChange={(e) => setLocation(e.value)}
            onCreateOption={(e: string) => {
              props.addLocation(e);
              props.triggerUpdateAllElements();
            }}
            placeholder="Select location..."
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
          if (props.newSensorFamily === 'sensor') {
            props.addSensor(location, props.newSensorType);
          } else { // probably a wireless sensor
            props.addWirelessSensor(location, props.newSensorType);
          }
          props.triggerUpdateAllElements();
          props.onHide();
        }}>Continue</Button>
      </Modal.Footer>
    </Modal>
  )
}

export function ModuleInventory({
  elements,
  addExpansionModule,
  addEdgeDevice,
  addLocation,
  addSensor,
  addWirelessSensor,
  locations,
  addAirCleaning,
  triggerUpdateAllElements,
} : {
  elements: {nodes: StreamwiseNode[]; edges: StreamwiseEdge[];}
  addExpansionModule: (expansionType: string) => void,
  addEdgeDevice: () => void,
  addLocation: (locationName: string) => void,
  addSensor: (locationId: string, sensorType: string) => void,
  addWirelessSensor: (locationId: string, sensorType: string) => void,
  locations: StreamwiseNode[],
  addAirCleaning: () => void,
  triggerUpdateAllElements: () => void,
}) {
  const [showLocationSelectModal, setShowLocationSelectModal] = useState(false);
  const [newSensorType, setNewSensorType] = useState('');
  const [newSensorFamily, setNewSensorFamily] = useState('');

  return (
    <>
      <LocationSelectModal 
        show={showLocationSelectModal}
        onHide={(() => setShowLocationSelectModal(false))}
        addSensor={addSensor}
        addWirelessSensor={addWirelessSensor}
        newSensorType={newSensorType}
        newSensorFamily={newSensorFamily}
        locations={locations.map(l => ({label: l.data.label, value: l.id}))}
        triggerUpdateAllElements={triggerUpdateAllElements}
        addLocation={addLocation}
      />
      <div
        style={{
          height: "100%",
          border: "2px solid black",
          borderRadius: "5px",
        }}
      >
        <h5 style={{ textAlign: "center", paddingTop: "5px" }}>
          ADD MORE MODULES
        </h5>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            overflowX: "scroll",
          }}
        >
          {SW_NODE_LIST.filter(n => {
            let nSensors = elements.nodes.filter((en) => en.data.moduleFamily === "sensor").length;
            let nWirelessSensors = elements.nodes.filter((en) => en.data.moduleFamily === "wirelessSensor").length;
            let nExpansions = elements.nodes.filter((en) => en.data.moduleFamily === "expansion").length;
            let nLocations = elements.nodes.filter((en) => en.data.moduleFamily === "location").length;

            if (n.data.moduleFamily === "sensor") {
              // Max 8 Sensors + A location needs to have been added
              return nSensors < 8 && nLocations > 0;
            } else if (n.data.moduleFamily === 'wirelessSensor') {
              // Max 16 wireless sensors allowed AND wireless module and > 0 locations need to exist
              return (
                nWirelessSensors < 16 &&
                !!elements.nodes.find((en) => en.type === "wirelessModule") &&
                nLocations > 0
              );
            // } else if (n.data.moduleFamily === "location") {
            //   // Max 4 Locations
            //   return (
            //     nLocations< 4
            //   );
            } else if (
              n.type === "edgeDevice" ||
              n.type === "airCleaning" ||
              n.type === "wirelessModule"
            ) {
              // Only one of these types allowed AND max 8 expansions allowd
              return (
                !elements.nodes.find((en) => en.type === n.type) &&
                nExpansions < 8
              );
            } else if (n.data.moduleFamily === 'expansion') {
              // Max 8 expansions allowed
              return nExpansions < 8;
            }
            // Don't include any other node type
            return false;
          }).map((m) => (
            <Button
              className="m-1"
              variant="info"
              style={{ minWidth: "110px", height: "70px", fontSize: '12px'}}
              onClick={() => {
                if (m.data.moduleFamily === 'expansion') {
                  if (m.type === 'airCleaning') {
                    addAirCleaning();
                    triggerUpdateAllElements();
                  } else {
                    addExpansionModule(m.type!!);
                    triggerUpdateAllElements();
                  }
                } else if (m.data.moduleFamily === 'root') {
                  addEdgeDevice();
                  triggerUpdateAllElements();
                } else if (m.data.moduleFamily === 'sensor') {
                  setNewSensorType(m.type!!);
                  setNewSensorFamily('sensor');
                  setShowLocationSelectModal(true);
                } else if (m.data.moduleFamily === 'wirelessSensor') {
                  setNewSensorType(m.type!!);
                  setNewSensorFamily('wirelessSensor');
                  setShowLocationSelectModal(true);
                }
              }}
            >
              {m.data.name}
            </Button>
          ))}
        </div>
      </div>
    </>
  );
}
