import React, {useState, useEffect} from 'react';
import { Form, Modal, Container, Row, Col, Button } from 'react-bootstrap';

function MaintenanceNotificationTableItem(props){

    return(
        <tr>
            <td style={{verticalAlign:"middle"}}>
                <Form.Control plaintext readOnly defaultValue={props.config.User.Name}/>
            </td>
            <td style={{verticalAlign:"middle"}}>
                <Form.Check aria-label="Get maintenance reminder notifications" checked={props.config.Set==1} onChange={e => props.onchangeset(e.target.checked, props.config.UserID)}/>
            </td>
            <td style={{verticalAlign:"middle"}}>
                <Form.Check aria-label="Get maintenance completed notifications" checked={props.config.Reset==1} onChange={e => props.onchangereset(e.target.checked, props.config.UserID)}/>
            </td>
            <td style={{verticalAlign:"middle"}}>
                <Form.Check aria-label="Get email notifications" checked={props.config.Email==1} onChange={e => props.onchangeemail(e.target.checked, props.config.UserID)}/>
            </td>
            <td style={{verticalAlign:"middle"}}>
                <Form.Check aria-label="Get SMS notifications" checked={props.config.SMS==1} onChange={e => props.onchangesms(e.target.checked, props.config.UserID)}/>
            </td>
            <td style={{verticalAlign:"middle"}}>
                <Form.Check aria-label="Get push notifications" checked={props.config.PushNotification==1} onChange={e => props.onchangeapp(e.target.checked, props.config.UserID)}/>
            </td>
            <td style={{verticalAlign:"middle"}}>
                <i type="button" className="fas fa-trash-alt" style={{color:"#dc3545"}} onClick={()=>{props.onremove(props.config.UserID)}}/>
            </td>
        </tr>
    )
}

export default MaintenanceNotificationTableItem;