import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import ReactFlow, {
  ReactFlowProvider,
  Controls,
  Background,
  useReactFlow,
} from "react-flow-renderer";
import { Button, Col, Row, Container, Modal} from "react-bootstrap";
import NoAccessPage from "../NoAccessPage";
// import { nodes as initialNodes, edges as initialEdges } from './initial-elements';
import { nodeTypes } from "./Nodes";
import { edgeTypes } from "./Edges";
import { Questionnaire } from "./Questionnaire";
import { BillOfMaterials } from "./BillOfMaterials";
import { ConfigurationWarnings } from "./ConfigurationWarnings";
import { ModuleInventory } from "./ModuleInventory";
import { useConfigurator } from "./useConfigurator";
import { ConfigurationData, ConfigurationWarning, IConfiguration, IConfigurationPayload } from "./types";

function SaveConfirmModal(props) {
  return(
    <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to save this configuration?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
          props.submitConfiguration()
          props.onHide();
        }}>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}

function ConfiguratorContent(props) {
  const { SiteGUID, ConfigID } = useParams();
  const [showQuestionnaire, setShowQuestionnaire] = useState(true);
  const [showSaveConfirmModal, setShowConfirmModal] = useState(false);

  const [loading, setLoading] = useState(true);
  const {
    loadConfig,
    getConfigData,
    elements,
    application,
    setApplication,
    name, 
    setName,
    onNodesChange,
    onEdgesChange,
    undo,
    canUndo,
    redo,
    canRedo,
    addEdgeDevice,
    addExpansionModule,
    addLocation,
    addSensor,
    addWirelessSensor,
    addAirCleaning,
    triggerUpdateAllElements,
    warnings,
  } = useConfigurator();

  //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
  useEffect(() => props.SiteGUIDSetter(SiteGUID), [SiteGUID]);

  const { fitView } = useReactFlow();

  useEffect(() => {
    fitView({ duration: 800 });
  }, [elements]);

  // load in configuration
  useEffect(() => {
      // Get existing configurations for this site
      fetch("/api/ConfigurationsAPI/GetConfiguration/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({SiteGUID: SiteGUID, ConfigID: ConfigID}),
      })
      .then((response) => response.json())
      .then((config: {configInfo: IConfiguration, configData: string}) => {
        let configDataParsed: ConfigurationData = JSON.parse(config.configData);
        setApplication(config.configInfo.application || '');
        setName(config.configInfo.name);
        if (configDataParsed.edges.length > 0 || configDataParsed.nodes.length > 0) {
          loadConfig(configDataParsed);
          setShowQuestionnaire(false)
        }
        setLoading(false);
      })
  }, []);

  const submitConfiguration = () => {
     // Save configuration 
     fetch("/api/ConfigurationsAPI/SaveConfiguration/", {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       },
       body: JSON.stringify({
         SiteGUID: SiteGUID,
         ConfigID: ConfigID,
         name: name,
         application: application,
         configData: getConfigData(),
       }),
     });
  };

  console.log({ elements });
  return props.auth !== undefined ? (
    props.auth > 0 ? (
      !loading ? 
        (<>
          {showQuestionnaire ? (
            <Questionnaire
              setShowQuestionnaire={setShowQuestionnaire}
              application={application}
              setApplication={setApplication}
              name={name}
              setName={setName}
              addLocation={addLocation}
              addSensor={addSensor}
              addWirelessSensor={addWirelessSensor}
              addAirCleaning={addAirCleaning}
              addExpansionModule={addExpansionModule}
              triggerUpdateAllElements={triggerUpdateAllElements}
            />
          ) : (
            <>
              <SaveConfirmModal 
                show={showSaveConfirmModal}
                onHide={(() => setShowConfirmModal(false))}
                submitConfiguration={submitConfiguration}
              />
              <div>
                <Container
                  style={{
                    height: "65vh",
                    marginTop: "10px",
                    maxWidth: "1400px",
                    width: "90vw",
                  }}
                >
                  <Row className="mb-3" style={{height: '85%'}}>
                    <Col xs={8} >
                      <div
                        style={{
                          height: "100%",
                          border: "2px solid black",
                          borderRadius: "5px",
                        }}
                      >
                        <h5 style={{ textAlign: "center", paddingTop: "5px" }}>
                          {name.toUpperCase()} [{application.toUpperCase()}] - CONFIGURATION
                        </h5>
                        <div style={{ height: "calc(100% - 40px)" }}>
                          <ReactFlow
                            nodes={elements?.nodes}
                            edges={elements?.edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            fitView
                            nodeTypes={nodeTypes}
                            edgeTypes={edgeTypes}
                            elementsSelectable={true}
                            nodesConnectable={false}
                            nodesDraggable={false}
                            attributionPosition="bottom-right"
                          >
                            <Controls />
                            <Background color="#aaa" gap={16} />
                          </ReactFlow>
                        </div>
                      </div>
                    </Col>
                    <Col style={{
                      height: '100%', 
                      flexDirection: 'column', 
                      display: 'flex',
                      justifyContent: 'space-between'}}
                    >
                      <Row style={{ height: "65%" }}>
                        <BillOfMaterials
                          nodes={elements?.nodes}
                          edges={elements?.edges}
                        />
                      </Row>
                      <Row style={{ height: "32.5%" }}>
                        <ConfigurationWarnings warnings={warnings} />
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ height: "15%" }}>
                    <Col xs={8}>
                      <ModuleInventory
                        elements={elements}
                        addEdgeDevice={addEdgeDevice}
                        addExpansionModule={addExpansionModule}
                        addLocation={addLocation}
                        addSensor={addSensor}
                        addWirelessSensor={addWirelessSensor}
                        locations={elements.nodes.filter(
                          (n) => n.type === "locationGroup"
                        )}
                        addAirCleaning={addAirCleaning}
                        triggerUpdateAllElements={triggerUpdateAllElements}
                      />
                    </Col>
                    <Col>
                      <Row>
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "5px",
                          }}
                        >
                          {/* <Button
                              variant="outline-danger"
                              className="mb-2"
                              style={{ width: "100%" }}
                              onClick={() => undo()}
                              disabled={!canUndo}
                            >
                              UNDO
                            </Button>
                            <Button
                              variant="outline-primary"
                              className="mb-2"
                              style={{ width: "100%" }}
                              onClick={() => redo()}
                              disabled={!canRedo}
                            >
                              REDO
                            </Button> */}
                          <Button
                            variant="success"
                            style={{ width: "100%" }}
                            onClick={() => setShowConfirmModal(true)}
                          >
                             SAVE CONFIGURATION
                          </Button>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </div>
            </>
          )}
        </>)
        :
      (<div>Loading...</div>)
    ) : (
      <NoAccessPage />
    )
  ) : (
    <></>
  );
}

export function Configurator(props) {
  const [finishStatus, setfinishStatus] = useState(false);
  const { SiteGUID } = useParams();

  const history = useHistory();

  const onBackButtonEvent = (e) => {
      e.preventDefault();
      if (!finishStatus) {
          if (window.confirm("Any unsaved changes will be lost, are you sure you want to go back?")) {
              setfinishStatus(true)
              // your logic
              history.push("/Configurator/index/" + SiteGUID);
          } else {
              window.history.pushState(null, null, window.location.pathname);
              setfinishStatus(false)
          }
      }
  }

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
  }, []);

  return (
    
    <ReactFlowProvider>
      <ConfiguratorContent {...props} />
    </ReactFlowProvider>
  );
}
