import { useEffect, useState, useRef } from 'react';
import { Modal, Row, Col, InputGroup, Button, Form, Table, Spinner } from 'react-bootstrap';
import * as signalR from '@microsoft/signalr';

const TekdisAORow = ({ i, slaveID, deviceID, params }) => {
    const [inputType, setInputType] = useState(params.inputType);
    const [newVal, setNewVal] = useState(0);

    const changeInputRange = () => {
        fetch("/api/EdgeDiagnosticsAPI/ChangeInputTypeTekdis/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                DeviceID: deviceID,
                SlaveID: slaveID,
                Address: 256 + i,
                Value: inputType
            })
        }).then(res => {
            if (res.ok) {
                alert("Changed Input Type!");
            }
            else {
                alert("Failed to change Input Type.");
            }
        })
    }

    const writeToAO = () => {
        fetch("/api/EdgeDiagnosticsAPI/WriteTekdisAnalogOutput/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                DeviceID: deviceID,
                SlaveID: slaveID,
                Address: i,
                Value: newVal
            })
        }).then(res => {
            if (res.ok) {
                alert("Write to IO successful");
            }
            else {
                alert("Write to IO failed");
            }
        });
    }

    return (
        <tr>
            <td>{i+1}</td>
            <td>
                <Form.Select
                    value={inputType}
                    onChange={e => setInputType(e.target.value)}>
                    <option>Select</option>
                    <option value={0}>0 ~ 20mA</option>
                    <option value={1}>4 ~ 20mA</option>
                    <option value={2}>0 ~ 10V</option>
                    <option value={3}>+/- 10V</option>
                    <option value={4}>0 ~ 5V</option>
                    <option value={5}>+/- 5V</option>
                </Form.Select>
            </td>
            <td>
                <Form.Select disabled>
                    <option>Coming Soon</option>
                </Form.Select>
            </td>
            <td><Button onClick={changeInputRange}>Set</Button></td>
            <td>{params.value}</td>
            <td>0~10000</td>
            <td><Form.Control value={newVal} onChange={e => setNewVal(e.target.value)} /></td>
            <td><Button onClick={writeToAO}>Write</Button></td>
        </tr>);
}

const TekdisAODetails = ({ deviceID, slaveID, show, setShow }) => {
    const [newSlave, setNewSlave] = useState(slaveID);
    const [sensorParams, setSensorParams] = useState([])
    const [modProtocol, setModProtocol] = useState(true);
    const paramRef = useRef(sensorParams);

    var connection = new signalR.HubConnectionBuilder()
        .withUrl("/messageHub")
        .withAutomaticReconnect()
        .build();

    useEffect(() => {
        if (show) {
            // Retrieve details
            let tag = "";
            connection.stop();
            // Begin signal R
            connection.start().then(() => {
                tag = "ModalStream" + connection.connectionId;
                console.log(tag);

                // Start live data stream
                fetch("/api/EdgeDiagnosticsAPI/GetTekdisAODetails/?deviceID=" + deviceID
                    + "&slaveID=" + slaveID + "&signalRTag=" + tag)
                    .then(res => res.json())
                    .then(payload => {
                        console.log(payload);
                        setModProtocol(payload.mode);
                        setSensorParams(payload.sensorParams);
                        paramRef.current = payload.sensorParams;
                    }).catch();


                connection.on(tag, msg => {
                    const entry = JSON.parse(msg);
                    let newParams = [...paramRef.current];
                    console.log(entry);
                    for (var i = 0; i < entry.length; i++) {
                        newParams[i].value = entry[i];
                    }
                    setSensorParams(newParams);
                });
            }).catch(e => console.log('Scan connection failed: ', e));
        }
    }, [show]);

    useEffect(() => {
        setNewSlave(slaveID);
    }, [slaveID]);

    const changeModuleProtocol = () => {
        // to be do later no use now
    }

    const changeSlaveID = () => {
        fetch("/api/EdgeDiagnosticsAPI/ChangeSlaveIDTekdis/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: { DeviceID: deviceID, CurSlaveID: slaveID, NewSlaveID: newSlave }
        }).then(res => {
            if (res.ok) {
                slaveID = newSlave;
                alert("Slave ID Changed!");
            }
            else {
                alert("Failed to change slave ID :(");
            }
        })
    }

    const _handleModalClose = () => {
        fetch("/api/EdgeDiagnosticsAPI/StopSignalStreamTekdis/?deviceID=" + deviceID)
            .then(res => {
                if (res.ok) {
                    setShow(false);
                }
            });
    }

    return (
        <Modal show={show} onHide={_handleModalClose} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Tekdis Analog Module</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">
                <Row>
                    <Col>
                        <InputGroup>
                            <InputGroup.Text>SlaveID: </InputGroup.Text>
                            <Form.Control
                                type="number"
                                value={newSlave}
                                onChange={e => setNewSlave(e.target.value)}
                                style={{ width: "60px" }} />
                            <Button onClick={changeSlaveID}>
                                <i className="fas fa-check"></i>
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup>
                            <InputGroup.Text>Protocol: </InputGroup.Text>
                            <Form.Select
                                disabled
                                value={modProtocol}
                                onChange={e => setModProtocol(e.target.value)}>
                                <option value={false}>DCON</option>
                                <option value={true}>Modbus RTU</option>
                            </Form.Select>
                        </InputGroup>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Address</th>
                                    <th>Input Range</th>
                                    <th>Slew Rate</th>
                                    <th></th>
                                    <th>Readback Value</th>
                                    <th>Raw Range</th>
                                    <th>New Value</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {sensorParams.length > 0 ?
                                    sensorParams.map((entry, i) =>
                                        <TekdisAORow key={"analog-" + i}
                                            i={i}
                                            slaveID={slaveID}
                                            deviceID={deviceID}
                                            params={entry} />)                        
                                    : <tr>
                                        <td colSpan={8} style={{ textAlign: "center" }}>
                                            <Spinner animation="border" variant="primary" />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default TekdisAODetails;