import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Button, Modal } from 'react-bootstrap';
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import DeviceTab from './ControlComponents/DeviceTab';
import Cookies from 'cookies-js';
import NoAccessPage from './NoAccessPage';
import HelpButton from './HelpButton';
import YouTubeVideo from './YouTubeVideo';

//Show info modal if updated since last visit
var ControlTabVersion = Cookies.get('ControlTabVersion');
var CurrentControlTabVersion = 1;

export const Control = (props) => {
    const { id } = useParams();
    const [showversioncontrolmodal, setShowVersionControlModal] = useState(false)
    const [curDeviceID, setCurDeviceID] = useState("");
    const [devices, setDevices] = useState([]);

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id), [id]);

    function handleClose() {
        setShowVersionControlModal(false)
    }

    function CheckVersion() {
        if (ControlTabVersion < CurrentControlTabVersion) {
            //Show modal and update cost breakdown tab version for user so they don't see the message on other devices
            var fd = {
                "ControlTabVersion": CurrentControlTabVersion
            };
            $.ajax({
                url: '/api/VersionControlAPI/SetControlTabVersion',
                data: fd,
                type: 'get',
                success: function (response) {
                    ControlTabVersion = CurrentControlTabVersion;
                    Cookies.set('ControlTabVersion', CurrentControlTabVersion, { expires: 7999 });

                }
            });
            setShowVersionControlModal(true)
        }
    }

    useEffect(() => {
        // Version control modal
        CheckVersion()
        //First run
        if (ControlTabVersion == undefined || ControlTabVersion < CurrentControlTabVersion) {
            //Get version from server
            $.ajax({
                url: '/api/VersionControlAPI',
                type: 'get',
                success: function (response) {
                    console.log(response);

                    ControlTabVersion = response.controlTabVersion;
                    Cookies.set('HomeTabVersion', response.homeTabVersion, { expires: 7999 });
                    Cookies.set('AnalyticsTabVersion', response.analyticsTabVersion, { expires: 7999 });
                    Cookies.set('HealthSummaryTabVersion', response.healthSummaryTabVersion, { expires: 7999 });
                    Cookies.set('CostBreakdownTabVersion', response.costBreakdownTabVersion, { expires: 7999 });
                    Cookies.set('DeepDiveTabVersion', response.deepDiveTabVersion, { expires: 7999 });
                    Cookies.set('MaintenanceTabVersion', response.maintenanceTabVersion, { expires: 7999 });
                    Cookies.set('BillingTabVersion', response.billingTabVersion, { expires: 7999 });
                    Cookies.set('AlarmsTabVersion', response.alarmsTabVersion, { expires: 7999 });
                    Cookies.set('ControlTabVersion', response.controlTabVersion, { expires: 7999 });
                    Cookies.set('CalibrationsTabVersion', response.calibrationsTabVersion, { expires: 7999 });
                    Cookies.set('AirCleaningTabVersion', response.airCleaningTabVersion, { expires: 7999 });
                    Cookies.set('ReportsTabVersion', response.reportsTabVersion, { expires: 7999 });
                    Cookies.set('SiteDetailsTabVersion', response.siteDetailsTabVersion, { expires: 7999 });
                    Cookies.set('DownloadsTabVersion', response.downloadsTabVersion, { expires: 7999 });

                    CheckVersion();
                }
            });
        }
    }, []);

    useEffect(() => {
        // Get devices
        $.ajax({
            url: '/api/SharedCommandsAPI/GetDevices',
            type: 'get',
            data: { siteGUID: id },
            success: (response) => {
                response = JSON.parse(response);
                setDevices(response);
                // Load cards for first device
                setCurDeviceID(response[0].DeviceID);
            }
        })
    }, [id]);

    return (
        props.auth !== undefined ?
            props.auth > 0 ?
                <div>
                    <HelpButton showHelpModal={setShowVersionControlModal} />
                    <h1>Equipment Control</h1>

                    {/*Tab control for device selection*/}
                    <Tabs
                        activeKey={curDeviceID}
                        fill justify
                        onSelect={k => setCurDeviceID(k)}>
                        {devices.map(item =>
                            <Tab eventKey={item.DeviceID} title={item.NiceName} style={{ backgroundColor: "white" }}>
                                <DeviceTab deviceID={item.DeviceID} active={item.DeviceID == curDeviceID} />
                            </Tab>)}
                    </Tabs>
                    <Modal show={showversioncontrolmodal} onHide={handleClose} size="lg">
                        <Modal.Header closeButton>
                            <h5 class="modal-title" id="exampleModalLongTitle">What's New?</h5>
                        </Modal.Header>
                        <Modal.Body className="text-center">
                            <YouTubeVideo VideoTag="Control" />
                            <p className="text-start">Now, you have manual control of the pumping rate. This overrides any automatic pump operation.</p>
                            <p><img className="w-100" src="/images/HowTos/control1.png" alt="Document Upload" /></p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                :
                <NoAccessPage />
            : <></>);
};
