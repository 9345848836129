import React, {useState, useRef} from "react";
import useDeepCompareEffect from 'use-deep-compare-effect'; 

export function usePointsOnPath(lengths: number[], pathRef: any, edgePath: string) {
  // edgePath is curve path & lengths is array points (0<t<1) on curve.

  const [points, setPoints] = useState<(DOMPoint | undefined)[]>([]);
//   const pathRef = useRef<SVGPathElement>(null);
  useDeepCompareEffect(() => {
    const pathEl = pathRef.current;
    if (pathEl) {
      const totalLength = pathEl.getTotalLength();
      setPoints(
        lengths.map((length) => pathEl.getPointAtLength(totalLength * length))
      );
    }
  }, [pathRef, lengths, edgePath]);
  return { points };
}
