import React, { useEffect, useState, useContext} from 'react';
import useInterval from '../../hooks/useInterval';
import { sortableHandle } from 'react-sortable-hoc';
import ChartSettingsContainer from "./ChartSettingsContainer"
import AnnotationsContainer from "./AnnotationsContainer"
import TrendChart from '../Trends/trend-chart'
import InsightChart from '../Insights/trend-chart'
import { LineChartTypeContext } from './LineChartContainer';
import { AuthContext } from './LineChartContainer';
import moment from 'moment';
import { Spinner } from 'react-bootstrap'

const ConfigContext = React.createContext();
const colorPickerColours = ['#14a8de', '#a0512d', '#944ba3', '#069416', '#002b4f', '#f47920', '#101820'];

const DragHandle = sortableHandle(() =>
    <div className="btn d-flex align-items-center btn-group-vertical ms-auto" style={{ height: "100%", border: "1px solid rgb(230 230 230)" }}>
        <i className="fal fa-angle-up" style={{ color: '#002c4e78', display: 'block' }}></i>
        <i className="fal fa-grip-lines" style={{ display: 'block' }}></i>
        <i className="fal fa-angle-down" style={{ color: '#002c4e78', display: 'block' }}></i>
    </div>
);

const defaultLCI = {
    "LineChartInputID": null,
    "LineChartID": null,
    "InputType": 1,
    "InputSignalID": null,
    "Order": 1,
    "ColorID": 0,
    "Signal": null,
    "AxisSide": 0,
    "ShowNonCompliance":false
}

const defaultSelectedSignal = {
    "label": "",
    "value": "",
    "filterSignalID": null,
    "isSelected": false,
    "selectedColour": "#14a8de",
    "Order": 0,
    "i": 0
}

function LineChart(props) {
    const [config, setConfig] = useState(null)
    const [signals, setSignals] = useState([]);
    const [canEdit, setCanEdit] = useState(false);
    const { LineChartType } = useContext(LineChartTypeContext);
    const { auth } = useContext(AuthContext)
    const [latestTimes, setLatestTimes] = useState();
    const [timeSinceLatest, setTimeSinceLatest] = useState();
    const [loading, setLoading] = useState(true);

    const REFRESH_INTERVAL_MS = 300000;
    const REFRESH_INTERVAL_MS_LATEST_TIMES = 15000;
    // const REFRESH_INTERVAL_MS = 60000;

    useEffect(() => {
        setLoading(true);

        // Fetch telemetry data
        if (LineChartType === 0 || LineChartType === 2) {
            const interval = setInterval(() => {
                fetch(`/api/LineChartAPI/GetChart?id=${props.chartID}&lineChartType=${LineChartType}`)
                    .then(response => response.json())
                    .then(data => updateConfig(data));
            }, REFRESH_INTERVAL_MS);
    
            return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.    
        }
    }, [])

    useInterval(
        () => {
            getTimeSinceLatestValues(latestTimes); 
        },
        // Delay in milliseconds
        REFRESH_INTERVAL_MS_LATEST_TIMES,
    )

    useEffect(() => {
        var url = '/api/LineChartAPI/GetChart?id=' + props.chartID + '&lineChartType=' + LineChartType;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                updateConfig(data)
                //console.log(data);
                var acID = data.LineChart.AccessControlID;
                var url2 = '/api/AuthorizationAPI/GetAccessLevel?accessControlID='+acID;

                fetch(url2)
                    .then(response => response.json())
                    .then(data => setCanEdit(data===2));
            });
    }, [props.chartID]);

    const getSignals = (config) => {
        var url = '/api/LineChartAPI/GetSiteSignals/';
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(config.SiteGUID)
        })
            .then(response => response.json())
            .then(data => {
                var siteSignals = data.value;
                // Iterate through all ChartInputs to figure out which signals are configured
                var i = 0;
                siteSignals.forEach((ss) => {
                    ss.isSelected = false;
                    ss.selectedColour = colorPickerColours[0];
                    ss.Order = 9999;
                    ss.i = 0;
                    i++;
                })

                var inputs = config.LineChartInputs
                if (inputs) {
                    inputs.forEach(l => {
                        for (var i = 0; i < siteSignals.length; i++) {
                            if (siteSignals[i].value == l.InputSignalID) {
                                var thiscol = colorPickerColours[l.ColorID]
                                siteSignals[i].selectedColour = thiscol
                                siteSignals[i].isSelected = true
                                siteSignals[i].Order = l.Order
                            }
                        }
                    })
                }

                var orderedSignals = reorderSignals(siteSignals);
                console.log(orderedSignals)
                setSignals(orderedSignals);
                // console.table(orderedSignals);
            });
    }

    function reorderSignals(signals) {
        signals.sort((a, b) => (a.Order > b.Order) ? 1 : ((b.Order > a.Order) ? -1 : 0))
        return signals
    }

    function updateColour(newColour, signalID) {
        var newConfig = JSON.parse(JSON.stringify(config))
        var lineChartInput = newConfig.LineChartInputs.filter(lci => lci.InputSignalID === signalID)[0]
        lineChartInput.ColorID = colorPickerColours.indexOf(newColour)
        setConfig(newConfig)
        getSignals(newConfig)
    }

    function updateAxisSide(newAxisSide, signalID) {
        var newConfig = JSON.parse(JSON.stringify(config))
        var lineChartInput = newConfig.LineChartInputs.filter(lci => lci.InputSignalID === signalID)[0]
        lineChartInput.AxisSide = newAxisSide
        setConfig(newConfig)
    }

    function updateShowNonCompliance(newShowNonCompliance, signalID) {
        var newConfig = JSON.parse(JSON.stringify(config))
        var lineChartInput = newConfig.LineChartInputs.filter(lci => lci.InputSignalID === signalID)[0]
        lineChartInput.ShowNonCompliance = newShowNonCompliance
        setConfig(newConfig)
    }

    function updateInputType(newInputType, signalID) {
        var newConfig = JSON.parse(JSON.stringify(config))
        var lineChartInput = newConfig.LineChartInputs.filter(lci => lci.InputSignalID === signalID)[0]
        lineChartInput.InputType = newInputType
        setConfig(newConfig)
    }

    function updateLeftYAxisLabel(newLabel) {
        var newConfig = JSON.parse(JSON.stringify(config))
        newConfig.YAxisTitle = newLabel
        setConfig(newConfig)
    }

    function updateRightYAxisLabel(newLabel) {
        var newConfig = JSON.parse(JSON.stringify(config))
        newConfig.RightYAxisTitle = newLabel
        setConfig(newConfig)
    }

    function updateYRangeLow(newVal) {
        let newConfig = JSON.parse(JSON.stringify(config));
        if (isNaN(parseFloat(newVal))) {
            newConfig.YRangeLow = null;
        } else {
            newConfig.YRangeLow = newVal;
        }
        setConfig(newConfig);
    }

    function updateYRangeHigh(newVal) {
        let newConfig = JSON.parse(JSON.stringify(config));
        if (isNaN(parseFloat(newVal))) {
            newConfig.YRangeHigh = null;
        } else {
            newConfig.YRangeHigh = newVal;
        }
        setConfig(newConfig);
    }

    function updateHomeChecked(checked) {
        var newConfig = JSON.parse(JSON.stringify(config))
        newConfig.HomePage = checked
        setConfig(newConfig)
    }

    function updateAnalyticsChecked(checked) {
        var newConfig = JSON.parse(JSON.stringify(config))
        newConfig.AnalyticsPage = checked
        setConfig(newConfig)
    }

    function updateReportsChecked(checked) {
        var newConfig = JSON.parse(JSON.stringify(config))
        newConfig.Reports = checked
        setConfig(newConfig)
    }

    function updateDailyDataLogsChecked(checked) {
        var newConfig = JSON.parse(JSON.stringify(config))
        newConfig.DailyDataLogs = checked
        setConfig(newConfig)
    }

    function updateSelectedSignals(selectedSignals) {
        var newSelectedSignals = JSON.parse(JSON.stringify(selectedSignals));
        var newConfig = JSON.parse(JSON.stringify(config));
        var LCIs = newConfig.LineChartInputs; 
        var filters = [];
        
        // Function for returning an LCI for a filter signal when auto add filter signal is selected
        const getFilterLCI = (filterSignalID, idx) => {
            // LCI
            var filterLCI = JSON.parse(JSON.stringify(defaultLCI));
            filterLCI.LineChartID = newConfig.LineChartID
            filterLCI.InputSignalID = filterSignalID;
            filterLCI.Order = idx;
            filterLCI.InputType = 2; // default filter signal to backdrop
            filterLCI.AxisSide = 1; // Default filter signal to right hand axis

            // // NSS (New selected signal)
            // var filterNSS = JSON.parse(JSON.stringify(defaultSelectedSignal));

            // filterNSS.value = filterSignalID;
            // filterNSS.isSelected = true;
            // newSelectedSignals.push(filterNSS)
            return filterLCI
        }

        // TODO there is probably a more algorithmically efficient way to do everything below

        // Iterate through all of the selected signals to add any new signals to LCIs
        if (newSelectedSignals) {
            newSelectedSignals.forEach((nss, idx) => {
                // Iterate through all of the config signals to determine if selected signal needs to be added
                if (LCIs && LCIs.length > 0) { // Only check if there are existing line chart inputs
                    for (var i = 0; i < LCIs.length; i++) {
                        if (LCIs[i].InputSignalID === nss.value) {
                            // Selected signal already in list
                            LCIs[i].Order = idx // Set order to array index 

                            break; // don't need to check anymore
                        } else if (i === LCIs.length - 1) {
                            // Selected signal not in list, need to add
                            var newCI = JSON.parse(JSON.stringify(defaultLCI));
                            newCI.LineChartID = newConfig.LineChartID; 
                            newCI.InputSignalID = nss.value;
                            newCI.Order = idx; // Set order to array index            
                            LCIs.push(newCI)
                            // Check a filter signal is defined
                            if (nss.filterSignalID) {
                                // Check filter signal isn't already selected
                                if (LCIs.findIndex(ci => ci.InputSignalID === nss.filterSignalID) === -1 && newSelectedSignals.findIndex(nss => nss.value === nss.filterSignalID) === -1) {
                                    var newFilterLC = getFilterLCI(nss.filterSignalID, idx + 1);
                                    filters.push(newFilterLC);
                                }
                            }
                            break;
                        } else {
                            // Keep looking
                        }
                    }
                } else { // Need to add the selected signal
                    LCIs = [];
                    var newCI = JSON.parse(JSON.stringify(defaultLCI));;
                    newCI.LineChartID = newConfig.LineChartID;
                    newCI.InputSignalID = nss.value;
                    newCI.Order = nss.Order; // Set order to array index            
                    LCIs.push(newCI)
                    // Check if a filter signal is defined
                    if (nss.filterSignalID) {
                        // Check filter signal isn't already selected
                        if (LCIs.findIndex(lci => lci.InputSignalID === nss.filterSignalID) === -1 && newSelectedSignals.findIndex(nss => nss.value === nss.filterSignalID) === -1) {
                            var newFilterLC = getFilterLCI(nss.filterSignalID, idx + 1);
                            filters.push(newFilterLC);
                        }
                    }
                }
            })
        }

        // Iterate through all of the existing LCIs to see if anything needs to be removed
        if (LCIs) { // only need to check if there are existing LCIs defined
            LCIs.forEach((ci, idx, arr) => {
                // Iterate through all of the new signals
                if (newSelectedSignals && newSelectedSignals.length > 0) { // Only need to try to find the LCI in selected signals if selected signals are defined
                    for (var i = 0; i < newSelectedSignals.length; i++) {
                        if (ci.InputSignalID === newSelectedSignals[i].value) {
                            // Signal found in new list
                            ci.Order = i; // Set order to array index 
                            break; // don't need to check anymore
                        } else if (i === newSelectedSignals.length - 1) {
                            // Signal not found, need to remove
                            arr.splice(idx, 1);
                            break;
                        } else {
                            // Keep looking
                        }
                    }
                } else { // No signals selected, so need to remove LCI
                    arr.splice(idx, 1);
                }
            })
        }

        // For the list of all signals, switch the isSelected property to true for all selected signals and set the order
        var newSignals = JSON.parse(JSON.stringify(signals))
        newSignals.forEach((ns) => { ns.isSelected = false }) // Reset all isSelected by setting to false
        if (newSelectedSignals) { // Only need to do this if there are any selected signals
            newSelectedSignals.forEach((nss, idx) => {
                newSignals[newSignals.findIndex(ns => ns.value === nss.value)].isSelected = true;
                newSignals[newSignals.findIndex(ns => ns.value === nss.value)].Order = idx
            })
        }

        // Reorder signals by Order property so they show up in the select box in the right order
        var orderedSignals = reorderSignals(newSignals);
        setSignals(orderedSignals)
        newConfig.LineChartInputs = LCIs;
        // console.log({ LCIs: LCIs })
        // console.log({ orderedSignals })
        setConfig(newConfig)

        return filters
    }

    function addFilterLCI(filterLCI){
        var newConfig = JSON.parse(JSON.stringify(config));
        var LCIs = newConfig.LineChartInputs; 
        // Add filter signal to LCIs
        LCIs.push(filterLCI);
        // Switch isSelected property to true for this signal
        var newSignals = JSON.parse(JSON.stringify(signals))
        newSignals.forEach(ns => {
            if (ns.value === filterLCI.InputSignalID){
                ns.isSelected = true;
            }
        })

        setSignals(newSignals)
        setConfig(newConfig)
    }

    function getTimeSinceLatestValues(latestTimes){
        if (latestTimes && latestTimes.length > 0){
            let minAgo = moment().utc().diff(latestTimes[0], 'minutes');
            for (let i = 1; i<latestTimes.length; i++){
                if (moment().utc().diff(latestTimes[i], 'minutes') !== minAgo){
                    setTimeSinceLatest(undefined);
                    return
                }
            }
            setTimeSinceLatest(minAgo);
        } else {
            setTimeSinceLatest(undefined);
        }
    }

    function getLatestTimes(chartLines){
        // Get array of latest datetimes, filter out empty datasets and backdrops
        var chartLinesCopy = JSON.parse(JSON.stringify(chartLines));
        let validDataSets = chartLinesCopy.filter(c => c.chartDataPoints && c.chartDataPoints.length > 0 && c.inputType == 1);
        var _latestTimes = validDataSets.map(c => moment.utc(c.chartDataPoints[c.chartDataPoints.length-1].t))
        setLatestTimes(_latestTimes);
        return _latestTimes;
    }

    function updateConfig(newData) {
        console.log(newData)
        setConfig(newData.LineChart);
        getSignals(newData.LineChart);
        if (LineChartType === 1) {
            InsightChart.init(newData.LineChart.LineChartID, props.start, props.end, () => setLoading(false))
        }
        else if (LineChartType === 0 || LineChartType === 2) {
            TrendChart.init(newData, () => setLoading(false))
            let _latestTimes = getLatestTimes(newData.chartLines);
            getTimeSinceLatestValues(_latestTimes); 
        }
    }

    return (
        <ConfigContext.Provider
            value={{
                config,
                updateColour,
                updateAxisSide,
                updateInputType,
                updateShowNonCompliance,
                updateLeftYAxisLabel,
                updateRightYAxisLabel,
                updateYRangeLow,
                updateYRangeHigh,
                updateHomeChecked,
                updateAnalyticsChecked,
                updateReportsChecked,
                updateDailyDataLogsChecked,
                updateSelectedSignals,
                updateConfig,
                addFilterLCI
            }}>
            <div className="mt-3">
                <div className="card border-primary mb-3">
                    <div className="card-body p-0 d-flex">
                        {auth == 2 &&
                            <div className="btn-group-vertical ms-auto">
                                <div style={{ height: "100%", cursor: 'grab' }}>
                                    <DragHandle />
                                </div>
                            </div>
                        }

                        {/* Display chart values on left for Home and Analytics charts */}
                        {(LineChartType === 0 || LineChartType === 1) && 
                            <div className="card border-bottom-0 border-left-0 border-top-0 me-auto">
                                <div className="card-header bg-white p-3 text-center" title="Values">
                                    <i className="far fa-2x fa-chart-bar"></i>
                                    {LineChartType === 0 ?
                                        <>
                                            <div>Latest</div>
                                            <div style={{fontSize:"12px"}}>
                                                {timeSinceLatest !== undefined ? timeSinceLatest+"m ago" : ""}
                                            </div>
                                        </>
                                        : LineChartType === 1 && <div>Averages</div>
                                    }
                                    
                                </div>
                                <div className="card-body d-flex flex-column justify-content-center" id={props.chartID + "-Averages"}>
                                </div>
                            </div>
                        }

                        {/* Graph canvas */}
                        <div id={props.chartID} className="col row">
                            {loading && <Spinner
                                animation="border"
                                role="status"
                                className="position-absolute"
                                style={{ top: '50%', left: '50%' }} />
                            }
                            <canvas id={props.chartID + "-Chart"} className="col" style={{ minHeight: "240px", maxHeight: "240px", visibility: loading ? 'hidden' : 'visible'}} height={240}></canvas>
                            <div style={{ width: "40px", zIndex: "100", top: "0px", position: "absolute" }} className="h-100"></div>
                        </div>
                        {canEdit &&
                            <div className="btn-group-vertical ms-auto">
                                <div id={"configuresignalsroot-" + props.chartID} style={{ height: "100%" }}>
                                    <ChartSettingsContainer 
                                        chartModelId={props.chartID} 
                                        ready={config} 
                                        signals={signals} 
                                        onremove={props.onremove}/>
                                </div>
                                {LineChartType === 1 && config &&
                                    <div id={"annotations-" + props.chartID} style={{ height: "100%" }}>
                                        <AnnotationsContainer 
                                            start={props.start}
                                            end={props.end} 
                                            setLoading={setLoading}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div id={props.chartID + "-NoData"} className="mx-auto no-data-text" hidden>
                        <div className="text-muted text-center">
                            <i className="fas fa-info-circle"></i>
                            <div>No Data</div>
                        </div>
                    </div>
                </div>
            </div>
        </ConfigContext.Provider>
    )
}

export { ConfigContext };
export default LineChart