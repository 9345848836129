import { useState, useEffect, JSXElementConstructor, Key, ReactElement, ReactFragment, ReactPortal } from 'react';
import { Form, Modal, Button, ButtonGroup } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import HelpTooltip from '../HelpTooltip';
import { Settings, Actuator, TRIGGERS, FAILSAFETYPE } from './types';


interface AlarmSettingsModalProps {
    alarmID: number;
    display: boolean;
    closeModal: () => void;
}
export const AlarmSettingsModal = ({ alarmID, display, closeModal }: AlarmSettingsModalProps) => {
    const [returnObject, setReturnObject] = useState<Settings>();

    // failsafe variables
    const [failsafe, setFailsafe] = useState<boolean>(false);
    const [selFailSafe, setSelFailSafe] = useState<number>();
    const [selTerminal, setSelTerminal] = useState<string>();
    const [actuators, setActuators] = useState<ReactElement[]>([]);
    
    const [checkScaled, setCheckScaled] = useState<boolean>(false);
    const [constVal, setConstVal] = useState<number>(0);

    // trigger variables
    const [selTrigger, setSelTrigger] = useState<number>();
    const [setTime, setSetTime] = useState<number>(0);
    const [resetTime, setResetTime] = useState<number>(0);
    const [upperThreshold, setUpperThreshold] = useState<number>(0);
    const [lowerThreshold, setLowerThreshold] = useState<number>(0);

    const [instVal, setInstVal] = useState<number>(0);

    // update values when editing new alarm
    useEffect(() => {
        setReturnObject(undefined);
        setSelTrigger(undefined);
        setFailsafe(false);
        setSetTime(0); 
        setResetTime(0);
        setUpperThreshold(0); 
        setLowerThreshold(0); 
        setInstVal(0); 
        setSelFailSafe(undefined); 
        setSelTerminal(undefined); 
        setConstVal(0); 
        setCheckScaled(false); 
        setActuators([]);
        if (display){
            fetch("/api/EdgeAlarmsAPI/GetCurrentSettings/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ AlarmID: alarmID }),
            })
                .then((response) => response.json())
                .then((result: Settings) => {
                    setReturnObject(result);
                    setSelTrigger(result.edgeAlarm.failSafeTriggerID);
                    setFailsafe(result.edgeAlarm.trigger);
                    
                    if (result.edgeAlarmThreshold !== undefined && result.edgeAlarmThreshold !== null){
                        setSetTime(result.edgeAlarmThreshold.setTime); 
                        setResetTime(result.edgeAlarmThreshold.resetTime);
                        setUpperThreshold(result.edgeAlarmThreshold.upperThreshold); 
                        setLowerThreshold(result.edgeAlarmThreshold.lowerThreshold); 
                    }
                    else {
                        setSetTime(0); 
                        setResetTime(0);
                        setUpperThreshold(0); 
                        setLowerThreshold(0); 
                    }
                    if (result.edgeAlarm.trigger && result.failSafe !== undefined && result.failSafe !== null){
                        setSelFailSafe(result.failSafe.failSafeID); 
                        setSelTerminal(result.failSafe.terminalID); 
                    }
                    else {
                        setSelFailSafe(undefined); 
                        setSelTerminal(undefined); 
                    }
                    if (result.edgeAlarmInstability !== undefined && result.edgeAlarmInstability !== null){
                        setInstVal(result.edgeAlarmInstability.frequency); 
                    }
                    else {
                        setInstVal(0); 
                    }
                    if (result.failSafeConstant !== undefined && result.failSafeConstant !== null){
                        setConstVal(result.failSafeConstant.constantValue); 
                        setCheckScaled(result.failSafeConstant.scale); 
                    }
                    else {
                        setConstVal(0); 
                        setCheckScaled(false); 
                    }
                    if (result.actuators !== undefined){
                        setActuators(result.actuators.map((item: Actuator) =>
                        <option key={item.signalID} value={item.signalID}>{item.niceName}</option>)); 
                    }
                    else {
                        setActuators([]);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    throw 'Error getting alarm.';
                });
        }
    }, [display,alarmID]);

    // Triggers the post request to save edits and creates
    const _handleChanges = () => {
        if (returnObject){
            let settings = returnObject;
            settings.edgeAlarm.failSafeTriggerID = selTrigger;
            settings.edgeAlarm.trigger = failsafe;
            if (selTrigger == TRIGGERS.Threshold){
                if (settings.edgeAlarmThreshold){
                    settings.edgeAlarmThreshold.setTime = setTime; 
                    settings.edgeAlarmThreshold.resetTime = resetTime;
                    settings.edgeAlarmThreshold.upperThreshold = upperThreshold; 
                    settings.edgeAlarmThreshold.lowerThreshold = lowerThreshold;
                }
                else{
                    settings.edgeAlarmThreshold = {
                        edgeAlarmID : settings.edgeAlarm.edgeAlarmID,
                        setTime : setTime,
                        resetTime : resetTime,
                        upperThreshold : upperThreshold,
                        lowerThreshold : lowerThreshold
                    }
                }
            }
            if (selTrigger == TRIGGERS.Instability){
                if (settings.edgeAlarmInstability){
                    settings.edgeAlarmInstability.frequency = instVal; 
                }
                else{
                    settings.edgeAlarmInstability = {
                        edgeAlarmID : settings.edgeAlarm.edgeAlarmID,
                        frequency : instVal
                    }
                }
            }
            if (selTrigger == TRIGGERS.NoData){
                if (settings.edgeAlarmThreshold){
                    settings.edgeAlarmThreshold.setTime = setTime;
                }
                else{
                    settings.edgeAlarmThreshold = {
                        edgeAlarmID : settings.edgeAlarm.edgeAlarmID,
                        setTime : setTime,
                        resetTime : resetTime,
                        upperThreshold : upperThreshold,
                        lowerThreshold : lowerThreshold
                    }
                }
            }
            if (failsafe){
                if (selFailSafe && selTerminal){
                    if (settings.failSafe){
                        settings.failSafe.failSafeID = selFailSafe; 
                        settings.failSafe.terminalID = selTerminal;
                    }
                    else{
                        settings.failSafe = {
                            failSafeID : selFailSafe,
                            terminalID : selTerminal
                        }
                    }
                }
                if (selFailSafe == 1){
                    if (settings.failSafeConstant){
                        settings.failSafeConstant.constantValue = constVal; 
                        settings.failSafeConstant.scale = checkScaled;
                    }
                    else{
                        settings.failSafeConstant = {
                            constantValue : constVal,
                            scale : checkScaled
                        }
                    }
                }
            }

            fetch("/api/EdgeAlarmsAPI/SaveSettings/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(settings),
            })
                .then(() => closeModal())
                .catch((error) => {
                    console.error(error);
                    throw 'Error saving alarm.';
                });
        }
    
    }
    
    return (
            <Modal show={display} onHide={closeModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>Alarm Settings</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Group className="mb-2">
                    <Form.Label>Alarm Type</Form.Label>
                    <Form.Control as="select" value={selTrigger} onChange={e => setSelTrigger(Number(e.target.value))}>
                        <option>Select</option>
                        <option value={0} disabled={!returnObject?.isEdgeAlarm} title="Values are static for over 15 seconds">Frozen signal</option>
                        <option value={1} title="Values outside the Lower/Upper Threshold">Threshold-based alarm</option>
                        <option value={2} disabled={!returnObject?.isEdgeAlarm} title="Values oscillate with a higher frequency than a set point">Unstable signal</option>
                        <option value={4} title="No Data is being sent to the cloud">No data alarm</option>
                    </Form.Control>
                </Form.Group>

                {Number(selTrigger) === TRIGGERS.Threshold && <>
                    <InputGroup className="justify-content-center mb-2">
                        <InputGroup.Text>
                            <HelpTooltip placement="top" helptext="The amount of time the signal value needs to be outside the thresholds before the alarm is triggered." />
                            &nbsp;
                            Set Time (s):
                        </InputGroup.Text>
                        <Form.Control
                            aria-describedby="basic-addon3"
                            value={setTime}
                            type="number"
                            onChange={e => setSetTime(Number(e.target.value))}>
                        </Form.Control>
                    </InputGroup>
                    <InputGroup className="justify-content-center mb-2">
                        <InputGroup.Text>
                            <HelpTooltip placement="top" helptext="The amount of time the signal value needs to be within the thresholds before an active alarm is reset." />
                            &nbsp;
                            Reset Time (s):
                        </InputGroup.Text>
                        <Form.Control
                            aria-describedby="basic-addon3"
                            value={resetTime}
                            type="number"
                            onChange={e => setResetTime(Number(e.target.value))}>
                        </Form.Control>
                    </InputGroup>
                    <InputGroup className="justify-content-center mb-2">
                        <InputGroup.Text>Upper threshold: </InputGroup.Text>
                        <Form.Control
                            aria-describedby="basic-addon3"
                            value={upperThreshold}
                            type="number"
                            onChange={e => setUpperThreshold(Number(e.target.value))}>
                        </Form.Control>
                    </InputGroup>
                    <InputGroup className="justify-content-center mb-2">
                        <InputGroup.Text>Lower threshold: </InputGroup.Text>
                        <Form.Control
                            aria-describedby="basic-addon3"
                            value={lowerThreshold}
                            type="number"
                            onChange={e => setLowerThreshold(Number(e.target.value))}>
                        </Form.Control>
                    </InputGroup>
                    </>}
                
                {Number(selTrigger) === TRIGGERS.Instability &&
                    <InputGroup className="justify-content-center mb-2">
                        <InputGroup.Text>Oscillation Freq (Hz)</InputGroup.Text>
                        <Form.Control
                            type='number'
                            defaultValue={instVal}
                            onChange={e => setInstVal(Number(e.target.value))}
                            style={{ width: 70, marginRight: 20 }} />
                    </InputGroup>}

                {Number(selTrigger) === TRIGGERS.NoData && <>
                    <InputGroup className="justify-content-center mb-2">
                        <InputGroup.Text>
                            <HelpTooltip placement="top" helptext="The amount of time without data being to the cloud before the alarm is triggered." />
                            &nbsp;
                            Set Time (s):
                        </InputGroup.Text>
                        <Form.Control
                            aria-describedby="basic-addon3"
                            value={setTime}
                            type="number"
                            onChange={e => setSetTime(Number(e.target.value))}>
                        </Form.Control>
                    </InputGroup>
                    </>}
                <hr/>
                {returnObject?.isEdgeAlarm && <>
                    <Form.Group className="mb-2">
                    <Form.Check
                        inline
                        label="Only Notifications"
                        checked={!failsafe}
                        onChange={(e) => setFailsafe(!e.target.checked)}
                        type={'radio'}
                        id={`inline-1`}
                    />
                    <Form.Check
                        inline
                        label="Alarms Output"
                        checked={failsafe}
                        onChange={(e) => setFailsafe(e.target.checked)}
                        type={'radio'}
                        id={`inline-2`}
                    />
                    </Form.Group> 

                    {failsafe && 
                        <><Form.Group className="mb-2">
                            <Form.Label>Alarms Output Type</Form.Label>
                            <Form.Control as='select' value={selFailSafe} onChange={e => setSelFailSafe(Number(e.target.value))}>
                                <option>Select</option>
                                <option value={0}>Turn off</option>
                                <option value={1}>Constant value</option>
                                <option value={2}>Moving Average (past hour)</option>
                                <option value={3} title="Uses the value as at the time from last week">Intelligent (AI)</option>
                            </Form.Control>
                        </Form.Group>

                        {Number(selFailSafe) === FAILSAFETYPE.Constant && <Form.Group className="mb-2">
                            <InputGroup>
                                <InputGroup.Text>Constant Rate (%)</InputGroup.Text>
                                <Form.Control
                                    type='number'
                                    defaultValue={constVal}
                                    style={{ width: 70, marginRight: 20 }}
                                onChange={e => setConstVal(Number(e.target.value))} />
                            </InputGroup>
                            <HelpTooltip helptext="Adjust pump value based on the scaler signal set during creation" />
                            <Form.Check defaultChecked={checkScaled} type="checkbox"
                                label="Scale value"
                                onChange={() => setCheckScaled(!checkScaled)} />
                        </Form.Group>}

                        <Form.Group>
                            <Form.Label className="control-label">Actuator to apply alarms output</Form.Label>
                            <Form.Control as='select' value={selTerminal} onChange={e => setSelTerminal(e.target.value)} >
                                <option>Select</option>
                                {actuators}
                            </Form.Control>
                        </Form.Group></>
                    }</>
                }
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button variant="primary" onClick={_handleChanges}>Save</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
}