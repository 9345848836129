import { useState, useEffect } from 'react';
import {
    Card,
    Table,
    Row,
    Col,
    Modal,
    Button,
    Container,
} from "react-bootstrap";
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Script from 'react-load-script'
import LineChartModal from './ReportComponents/LineChartModal';
import WidgetModal from './ReportComponents/WidgetModal';
import GaugeModal from './ReportComponents/GaugeModal';
import RecipientModal from './ReportComponents/RecipientModal';
import { ReportContext } from './ReportComponents/ReportContext';
import Cookies from 'cookies-js';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import TextareaAutosize from 'react-textarea-autosize';
import NoAccessPage from './NoAccessPage'
import HelpButton from './HelpButton';

//Show info modal if updated since last visit
var ReportsTabVersion = Cookies.get('ReportsTabVersion');
var CurrentReportsTabVersion = 2;

const ReportRow = ({ data, deleteReport }) => {
    const [comment, setComment] = useState(data.Comments);
    const [editState, setEditState] = useState(false);
    const [editIcon, setEditIcon] = useState("fas fa-pencil-alt");

    const toggleCommentEdit = () => {
        let newState = !editState;
        setEditState(newState);
        // Change edit icon
        if (newState) {
            setEditIcon("fas fa-save");
        }
        else {
            setEditIcon("fas fa-pencil-alt");
            // Save the comment
            $.ajax({
                url: "/api/ReportsAPI/UpdateComment",
                type: "post",
                data: JSON.stringify({ ReportID: data.ReportID, Comments: comment }),
                contentType: 'application/json'
            })
        }
    }

    const downloadReport = (filename) => {
        $.ajax({
            url: "/api/ReportsAPI/DownloadReport",
            type: "get",
            data: { filename: filename },
            xhrFields: { responseType: 'blob' },
            success: function (data) {
                var file = new Blob([data], { type: "application/pdf" });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(file);
                link.download = filename + ".pdf";
                link.click();
            }
        })
    }

    return (
        <tr>
            <td style={{ width: '350px' }}>
                {data.StartDate}
            </td>
            <td style={{ width: '350px' }}>
                {data.EndDate}
            </td>
            <td>
                {editState ?
                    <TextareaAutosize
                        className="p-2 form-control"
                        value={comment}
                        onChange={e => setComment(e.target.value)} /> :
                    <div>{comment}</div>}
            </td>
            <td style={{ width: '50px' }}>
                <a className={"btn btn-outline-primary fas fa-2x " + editIcon} onClick={toggleCommentEdit}></a>
            </td>
            <td style={{ width: '50px' }}>
                <a className="btn btn-outline-primary fas fa-file-download fa-2x" onClick={() => downloadReport(data.FileName)}></a>
            </td>
            <td style={{ width: '50px' }}>
                <a className="btn btn-outline-danger far fa-trash-alt fa-2x" onClick={() => deleteReport(data.ReportID)} ></a>
            </td>
        </tr>);
}


export function Reports(props) {
    const { id } = useParams();
    const [signals, setSignals] = useState([])

    const [configWidgetShow, setConfigWidgetShow] = useState(false);
    const [configGaugeShow, setConfigGaugeShow] = useState(false);
    const [configLineShow, setConfigLineShow] = useState(false);
    const [configRecipientShow, setConfigRecipientShow] = useState(false);

    const [showversioncontrolmodal, setShowVersionControlModal] = useState(false)
    const [HelpVideoID, setHelpVideoID] = useState("")
    const [reportEntries, setReportEntries] = useState([]);
    const [showGenerateReportModal, setShowGenerateReportModal] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    function handleClose() {
        setShowVersionControlModal(false)
    }

    function CheckVersion() {
        if (ReportsTabVersion < CurrentReportsTabVersion) {
            //Show modal and update cost breakdown tab version for user so they don't see the message on other devices
            var fd = {
                "ReportsTabVersion": CurrentReportsTabVersion
            };
            $.ajax({
                url: '/api/VersionControlAPI/SetReportsTabVersion',
                data: fd,
                type: 'get',
                success: function (response) {
                    ReportsTabVersion = CurrentReportsTabVersion;
                    Cookies.set('ReportsTabVersion', CurrentReportsTabVersion, { expires: 7999 });

                }
            });
            setShowVersionControlModal(true)
        }
    }

    useEffect(() => {
        // Version control modal
        CheckVersion()
        //First run
        if (ReportsTabVersion == undefined || ReportsTabVersion < CurrentReportsTabVersion) {
            //Get version from server
            $.ajax({
                url: '/api/VersionControlAPI',
                type: 'get',
                success: function (response) {
                    console.log(response);

                    ReportsTabVersion = response.reportsTabVersion;
                    Cookies.set('HomeTabVersion', response.homeTabVersion, { expires: 7999 });
                    Cookies.set('AnalyticsTabVersion', response.analyticsTabVersion, { expires: 7999 });
                    Cookies.set('HealthSummaryTabVersion', response.healthSummaryTabVersion, { expires: 7999 });
                    Cookies.set('CostBreakdownTabVersion', response.costBreakdownTabVersion, { expires: 7999 });
                    Cookies.set('DeepDiveTabVersion', response.deepDiveTabVersion, { expires: 7999 });
                    Cookies.set('MaintenanceTabVersion', response.maintenanceTabVersion, { expires: 7999 });
                    Cookies.set('BillingTabVersion', response.billingTabVersion, { expires: 7999 });
                    Cookies.set('AlarmsTabVersion', response.alarmsTabVersion, { expires: 7999 });
                    Cookies.set('ControlTabVersion', response.controlTabVersion, { expires: 7999 });
                    Cookies.set('CalibrationsTabVersion', response.calibrationsTabVersion, { expires: 7999 });
                    Cookies.set('AirCleaningTabVersion', response.airCleaningTabVersion, { expires: 7999 });
                    Cookies.set('ReportsTabVersion', response.reportsTabVersion, { expires: 7999 });
                    Cookies.set('SiteDetailsTabVersion', response.siteDetailsTabVersion, { expires: 7999 });
                    Cookies.set('DownloadsTabVersion', response.downloadsTabVersion, { expires: 7999 });

                    CheckVersion();
                }
            });
        }

        fetch(
            "/api/VideoLibraryAPI/GetYouTubeID", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ VideoTag: "Reports" })
        })
            .then((response) => response.text())
            .then((data) => {
                console.log(data)
                setHelpVideoID(data);
            });
    }, []);

    const getReports = (id) => {
        $.ajax({
            url: "/api/ReportsAPI/GetReports",
            type: "GET",
            data: { SiteGUID: id },
            success: (response) => {
                response = JSON.parse(response);
                let rows = response.map(item => <ReportRow key={item.ReportID} data={item} deleteReport={deleteReport} />)
                setReportEntries(rows);
            }
        })
    }

    // Tasks to reload when changing sites
    useEffect(() => {
        //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
        props.SiteGUIDSetter(id);

        getReports(id);

        $.ajax({
            url: '/api/DownloadsAPI/GetSignals',
            type: 'get',
            data: { SiteGUID: id },
            type: 'get',
            success: function (response) {
                response = JSON.parse(response)
                setSignals(response)
            }
        })

        fetch(`/api/AuthorizationAPI/CheckIfAdmin/?siteGUID=${id}`)
            .then(res => res.json())
            .then(admin => setIsAdmin(admin));
    }, [id]);

    const deleteReport = reportID => {
        $.ajax({
            url: "/api/ReportsAPI/DeleteReport",
            type: "POST",
            data: JSON.stringify({ ReportID: reportID }),
            contentType: 'application/json',
            success: () => { getReports(id) }
        })
    }

    return (props.auth > 0 ?
        <div>
            <HelpButton showHelpModal={setShowVersionControlModal} />
            <h1>Reports</h1>
            <hr></hr>
            <div className="mb-3">
                <Button onClick={() => setShowGenerateReportModal(true)}>
                    Create report with custom timeframe{" "}
                    <i className="fas fa-file-chart-line"></i>
                </Button>
                <GenerateReportModal
                    show={showGenerateReportModal}
                    onHide={() => {
                        setShowGenerateReportModal(false);
                    }}
                    id={id}
                    {...props}
                />
            </div>
            <h2>Report Configuration</h2>
            <Row className="mb-3">
                <Col>
                    <Card
                        className={"btn p-0 " + (configWidgetShow ? "" : "shadow")}
                        onClick={() => {
                            if (configWidgetShow) {
                                setConfigWidgetShow(false);
                            } else {
                                setConfigWidgetShow(true);
                                setConfigLineShow(false);
                                setConfigGaugeShow(false);
                                setConfigRecipientShow(false);
                            }
                        }}
                    >
                        <div className="btn-primary w-100 p-3 text-light far fa-rectangle-landscape fa-7x"></div>
                        <Card.Footer>Configure Widgets</Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card
                        className={"btn p-0 " + (configGaugeShow ? "" : "shadow")}
                        onClick={() => {
                            if (configGaugeShow) {
                                setConfigGaugeShow(false);
                            } else {
                                setConfigGaugeShow(true);
                                setConfigLineShow(false);
                                setConfigWidgetShow(false);
                                setConfigRecipientShow(false);
                            }
                        }}
                    >
                        <div className="btn-primary w-100 p-3 text-light far fa-chart-pie fa-7x"></div>
                        <Card.Footer>Configure Gauges</Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card
                        className={"btn p-0 " + (configLineShow ? "" : "shadow")}
                        onClick={() => {
                            if (configLineShow) {
                                setConfigLineShow(false);
                            } else {
                                setConfigLineShow(true);
                                setConfigWidgetShow(false);
                                setConfigGaugeShow(false);
                                setConfigRecipientShow(false);
                            }
                        }}
                    >
                        <div className="btn-primary w-100 p-3 text-light fal fa-chart-line fa-7x"></div>
                        <Card.Footer>
                            Configure Line Charts
                        </Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card className={"btn p-0 " + (configRecipientShow ? "" : "shadow")}
                        onClick={() => {
                            if (configRecipientShow) { setConfigRecipientShow(false); }
                            else {
                                setConfigRecipientShow(true);
                                setConfigLineShow(false);
                                setConfigWidgetShow(false);
                                setConfigGaugeShow(false);
                            }
                        }}
                    >
                        <div className={"bg-primary w-100 p-3 text-light fal fa-user-circle fa-7x"}></div>
                        <Card.Footer>
                            Configure Recipients
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>

            {/* Edit modals */}
            <ReportContext.Provider value={{ signals: signals }}>
                <LineChartModal show={configLineShow} siteGUID={id} />
                <WidgetModal show={configWidgetShow} siteGUID={id} auth={props.auth} />
                <GaugeModal show={configGaugeShow} siteGUID={id} auth={props.auth} />
                <RecipientModal show={configRecipientShow} siteGUID={id} auth={props.auth} />
            </ReportContext.Provider>

            <div>
                <h2>Generated Reports</h2>
            </div>
            <Table striped border>
                <thead>
                    <tr>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Comments</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>{reportEntries}</tbody>
            </Table>

            <Script url="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js" />
            <Script url="https://cdn.jsdelivr.net/momentjs/latest/moment.min.js" />
            <Script url="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js" />
            <Script url="/lib/components/datetimerangepickertrial.js" />
            <Modal show={showversioncontrolmodal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLongTitle">
                        What's New?
                    </h5>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {/* <p className="text-start">
                        Now you can request a report for any timeframe.
                    </p>
                    <p>
                        <img
                            class="w-100"
                            src="https://wwxreportgenerator.blob.core.windows.net/how-tos/Manual-reports.png"
                            alt="ManualReports"
                        />
                    </p> */}
                    <p>
                        <img
                            className="w-100"
                            src="/images/HowTos/reports-v2.png"
                            alt="Document Upload"
                        />
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> :
        <NoAccessPage />
    );
};

function GenerateReportModal(props) {
    const [start, setStart] = useState();
    const [end, setEnd] = useState();

    const handleDatetimerangeUpdate = (event, picker) => {
        setStart(picker.startDate.utc().format())
        setEnd(picker.endDate.utc().format())
    }

    const generateReport = () => {
        try {
            $.ajax({
                url: "/api/ReportsAPI/GenerateManualReport",
                type: "GET",
                data: { siteGUID: props.id, start: start, end: end },
                success: () => {
                    props.onHide();
                    alert("Request done! Please wait a few minutes and refresh the page to see your report.");

                }
            });
        }
        catch (err) {
            alert("Failed please try again");
        }
    }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            onHide={props.onHide}
            className="unique-class"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create report with custom timeframe
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ minHeight: "100px" }}>
                Use the date range selector below to select the timeframe that you
                want the report to be generated for. After you click the generate
                button, your request will be submitted and the report will be
                available in the Reports page within a few minutes.
                <div id="ManualReportDateRangePickerContainer" className="card mb-3 mt-3">
                    <div className="card-body p-0">
                        <div className="input-group">
                            <div
                                className="d-none d-lg-block d-xl-block p-3 "
                                style={{ backgroundColor: "#002c4e" }}
                            >
                                <i
                                    className="far fa-2x fa-calendar-alt"
                                    style={{ color: "white" }}
                                ></i>
                            </div>
                            <DateRangePicker
                                onApply={handleDatetimerangeUpdate}
                                initialSettings={{
                                    parentEl: ".unique-class",
                                    timePicker: true,
                                    ranges: {
                                        "Last 24 hours": [moment().subtract(1, "days"), moment()],
                                        Yesterday: [
                                            moment().subtract(1, "days").startOf("day"),
                                            moment().subtract(1, "days").endOf("day"),
                                        ],
                                        "Last 7 Days": [moment().subtract(7, "days"), moment()],
                                        "Last 30 Days": [moment().subtract(30, "days"), moment()],
                                        "This Month": [
                                            moment().startOf("month"),
                                            moment().endOf("month"),
                                        ],
                                        "Last Month": [
                                            moment().subtract(1, "month").startOf("month"),
                                            moment().subtract(1, "month").endOf("month"),
                                        ],
                                    },
                                    alwaysShowCalendars: true,
                                    maxDate: moment(),
                                    startDate: moment().subtract(7, "days"),
                                    endDate: moment(),
                                    opens: "center",
                                    locale: {
                                        format: "DD/MM/YYYY",
                                    },
                                }}
                            >
                                <input
                                    type="text"
                                    className="m-3 form-control"
                                    name="daterange"
                                />
                            </DateRangePicker>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col>
                            <Button
                                className="float-right"
                                variant="success"
                                onClick={generateReport}
                                disabled={!start || !end}
                            >
                                Generate report
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

