import Select from 'react-select';
import React from 'react'; 
import chroma from 'chroma-js';

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    multiValue: (styles, { data }) => {
        const colorTemp = data.selectedColour;
        const color = chroma(colorTemp);
        return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.selectedColour,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.selectedColour,
        ':hover': {
            backgroundColor: data.selectedColour,
            color: 'white',
        },
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

class SignalSelect extends React.Component {
    constructor(props) {
        super(props);
    }

    handleChange(e) {
        this.props.onChange(e);
    }

    render() {
        return (
            <Select
                options={this.props.options}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={this.handleChange.bind(this)}
                isSearchable={true}
                styles={colourStyles}
                value={this.props.value}
                placeholder="Select signal(s)..."
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
            />
        );
    }
}

export default SignalSelect;