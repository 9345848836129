import { Line } from 'react-chartjs-2';
import { ChartSignalObject } from './types';
import {
    Chart as ChartJS,
    // CategoryScale,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    // CategoryScale,
    TimeScale, //Register timescale instead of category for X axis
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

type LineChartProps = {
    inputData: ChartSignalObject[],
    inputBounds?: number[],
    outputData: ChartSignalObject[],
    outputBounds?: number[]
    setpoint?: number
}

const PADDING = 0.1;
const MINS_OF_HISTORIC_DATA = 10;
const MINS_OF_FUTURE_DATA = 2;

export const LineChart = ({
    inputData,
    inputBounds,
    outputData,
    outputBounds,
    setpoint,
}: LineChartProps) => {
    const options = {
        scales: {
            x: {
                type: "time",
                min: (new Date()).setMinutes((new Date()).getMinutes() - MINS_OF_HISTORIC_DATA),
                max: (new Date()).setMinutes((new Date()).getMinutes() + MINS_OF_FUTURE_DATA),
                time: {
                    unit: "minute"
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ...(inputBounds !== undefined && {
                    suggestedMin: inputBounds[0] - Math.abs(inputBounds[0]) * PADDING,
                    suggestedMax: inputBounds[1] + Math.abs(inputBounds[1]) * PADDING
                }),
                title: {
                    display: true,
                    text: inputData.length > 0 ? inputData[0].signalName : '-'
                },
                grid: {
                    drawBorder: false, // <-- this removes y-axis line
                }
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                ...(outputBounds !== undefined && {
                    suggestedMin: outputBounds[0] - Math.abs(outputBounds[0]) * PADDING,
                    suggestedMax: outputBounds[1] + Math.abs(outputBounds[1]) * PADDING
                }),
                title: {
                    display: true,
                    text: outputData.length > 0 ? outputData[0].signalName : '-'
                },
                // grid line settings
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                    drawBorder: false, // <-- this removes y-axis line
                },
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            //   title: {
            //     display: true,
            //     text: 'Control chart',
            //   },
            annotation: {
                annotations: (setpoint !== undefined) ? {
                    line1: {
                        type: 'line',
                        yMin: setpoint,
                        yMax: setpoint,
                        borderColor: 'rgb(255, 99, 132)',
                        borderWidth: 2,
                        borderDash: [5, 5],
                        yAxisID: 'y',
                        label: {
                            enabled: true,
                            content: outputData.length > 0 ? outputData[0].signalName + ' Setpoint' : "SetPoint",
                            yAdjust: 0,
                            position: 'start',
                        },
                    }
                } : {}
            }
        },
    };

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const data = {
        labels,
        datasets: [
            ...inputData.map(signal => (
                {
                    label: signal.signalName,
                    data: signal.dataset,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    yAxisID: 'y',
                    // cubicInterpolationMode: 'monotone',
                    pointRadius: 0.5,
                    pointHitRadius: 3,
                    borderWidth: 2.5
                }
            )),
            ...outputData.map(signal => (
                {
                    label: signal.signalName,
                    data: signal.dataset,
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    yAxisID: 'y1',
                    // cubicInterpolationMode: 'monotone',
                    pointRadius: 0.5,
                    pointHitRadius: 3,
                    borderWidth: 2.5,
                }
            ))
        ]
    };

    return (
        <Line options={options} data={data} />
    )
}

type AreaChartProps = {
    inputData: ChartSignalObject[],
    outputData: ChartSignalObject[],
    //inputBounds: number[],
}

export const AreaChart = ({
    inputData,
    outputData
    //inputBounds,
}: AreaChartProps) => {
    const options = {
        scales: {
            x: {
                type: "time",
                min: (new Date()).setMinutes((new Date()).getMinutes() - MINS_OF_HISTORIC_DATA),
                max: (new Date()).setMinutes((new Date()).getMinutes() + MINS_OF_FUTURE_DATA),
                time: {
                    unit: "minute"
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: inputData.length > 0 ? inputData[0].signalName : '-'
                },
                grid: {
                    drawBorder: false, // <-- this removes y-axis line
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            }
        },
    };

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    // red, yellow, green, purple
    const colors = ['rgb(255, 99, 132)', 'rgb(250,250,210)', 'rgb(152,251,152)', 'rgb(221,160,221)'];
    const backgroundColors = ['rgba(255, 99, 132, 0.3)', 'rgba(250, 250, 210, 0.3)', 'rgb(152,251,152, 0.3)', 'rgba(221,160,221,0.3)'];
     
    const data = {
        labels,
        datasets: [
            ...inputData.map((signal, i) => (
                {
                    fill: true,
                    label: signal.signalName,
                    data: signal.dataset,
                    borderColor: colors[i%colors.length],
                    backgroundColor: backgroundColors[i%backgroundColors.length],
                    yAxisID: 'y',
                    pointRadius: 0.5,
                    pointHitRadius: 3,
                    borderWidth: 2.5
                }
            )),
            ...outputData.map(signal => (
                {
                    fill: true,
                    label: signal.signalName,
                    data: signal.dataset,
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    yAxisID: 'y',
                    pointRadius: 0.5,
                    pointHitRadius: 3,
                    borderWidth: 2.5
                }
            ))
        ]
    };

    return (
        <Line options={options} data={data} />
    )
}