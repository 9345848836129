import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { useParams, useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import Masonry from 'masonry-layout';
import YouTubeVideo from './YouTubeVideo';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import DatePicker from 'react-date-picker';
import VideoJS from './VideoJS'
import { Button, Modal } from 'react-bootstrap';
import Cookies from 'cookies-js';
import NoAccessPage from './NoAccessPage';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css'
import HelpButton from './HelpButton';
import MaintenanceAlarmSetup from './Maintenance/MaintenanceAlarmSetup'
import LogMaintenance from './Maintenance/LogMaintenance'

Chart.register(...registerables);
Chart.register(annotationPlugin);

var SiteAdmin = true;
//Show info modal if updated since last visit
var MaintenanceTabVersion = Cookies.get('MaintenanceTabVersion');
var CurrentMaintenanceTabVersion = 1;

var $grid;

class MaintenanceCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            MaintenanceEvents: [],
            Date: new Date()
        }
    }

    componentDidMount() {
        var thiscard = this;

        $.ajax({
            url: '/api/MaintenanceAPI/GetEquipmentMaintenance/' + this.props.EquipmentID,
            type: 'get',
            success: function (response) {
                response = JSON.parse(response);
                console.log(response);

                thiscard.setState({
                    MaintenanceEvents: response
                });
            }
        });
    }

    dateChangeHandler = (event) => {
        this.setState({ Date: event })
    }

    LogMaintenance = () => {
        var thiscard = this;

        var fd = {
            "EquipmentID": this.props.equipment.EquipmentID,
            "MaintenanceDate": moment(this.state.Date).format()
        };

        $.ajax({
            url: '/api/MaintenanceAPI/SubmitEquipmentMaintenance',
            type: 'get',
            data: fd,
            success: function (response) {
                response = JSON.parse(response);
                console.log(response);

                thiscard.setState({
                    MaintenanceEvents: response
                });
            }
        });
    }

    render() {
        return (<div class="grid-item col-md-12 p-0">
            <div class="m-2 shadow-sm card" >
                <div class="card-header d-flex align-items-center">
                    {this.props.equipment.NiceName}
                    <DatePicker className="ms-auto"
                        maxDetail={'month'}
                        onChange={this.dateChangeHandler}
                        value={this.state.Date}
                    />
                    <div class="ms-2"><a class="btn btn-primary text-light" onClick={this.LogMaintenance}>Log Maintenance</a></div>
                </div>
                <div class="card-body p-3">
                    <p>Number of maintenance events recorded: {this.state.MaintenanceEvents.length}</p>
                </div>
            </div>
        </div>);
    }
}

function ImageSearchModal(props) {

    const selectImage = (ImageID) => {
        console.log("clicked " + ImageID);
        console.log("clicked " + props.label);
        props.SetImageID(ImageID);
    }

    return (
        <Modal
            {...props}
            id={props.label}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton onHide={() => props.hideModal()}>
                <h3 class="modal-title" >Select Image</h3>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    {props.images.map(i => {
                        return (
                            <Button
                                className="col-4"
                                variant="primary"
                                onClick={() => selectImage(i.ImageID)}
                            >
                                {moment.utc(i.time_stamp).local().format('LLLL')}
                            </Button>)
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>);
}

class PumpCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            MaintenanceEvents: [],
            DaysTillNextMaintenance: null,
            Date: new Date()
        };
    }

    componentDidMount() {
        var thiscard = this;
        $.ajax({
            url: '/api/MaintenanceAPI/GetPumpMaintenance/' + this.props.pump.SitePumpID,
            type: 'get',
            success: function (response) {
                response = JSON.parse(response);
                console.log(response);

                thiscard.setState({
                    MaintenanceEvents: response
                });
            }
        });

    }

    dateChangeHandler = (event) => {
        this.setState({ Date: event })
    }


    LogMaintenance = () => {
        var thiscard = this;

        var fd = {
            "PumpID": this.props.pump.SitePumpID,
            "MaintenanceDate": moment(this.state.Date).format()
        };

        $.ajax({
            url: '/api/MaintenanceAPI/SubmitPumpMaintenance',
            type: 'get',
            data: fd,
            success: function (response) {
                response = JSON.parse(response);
                console.log(response);

                thiscard.setState({
                    MaintenanceEvents: response
                });
            }
        });
    }

    render() {
        var maintenance;
        if (this.state.DaysTillNextMaintenance != null && this.state.DaysTillNextMaintenance > 1) {
            maintenance = <p>Next refurbishment recommended in {this.state.DaysTillNextMaintenance} days.</p>
        }
        else if (this.state.DaysTillNextMaintenance != null && this.state.DaysTillNextMaintenance <= 1) {
            maintenance = <p>Please refurbish this pump as soon as possible</p>
        }
        else {
            maintenance = <p><i>Insufficient data available to recommend the next maintenance date.</i></p>
        }
        return (
            <div class="grid-item col-md-6 p-0">
                <div class="m-2 shadow-sm card" >
                    <div class="card-header d-flex align-items-center">
                        <i class="fas me-2 fa-2x fa-fan"></i>{this.props.pump.NiceName}
                        <DatePicker className="ms-auto"
                            maxDetail={'month'}
                            onChange={this.dateChangeHandler}
                            value={this.state.Date}
                        />
                        <div class="ms-2"><a class="btn btn-primary text-light" onClick={this.LogMaintenance}>Log Maintenance</a></div>
                    </div>
                    <div class="card-body p-3">
                        <p>Number of maintenance events recorded: {this.state.MaintenanceEvents.length}</p>
                        {maintenance}
                    </div>
                </div>
            </div>
        );
    }
}

class CameraFeedStatic extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedimageID: null,
            randomstring: "aoiwfajoiwfaowfjaiowef",
            images: [],
            image: null,
            showImageSelectModal: false,
        };
    }

    componentDidUpdate() {
        var thiscard = this;
        if (this.state.selectedimageID != null && this.state.selectedimageID != this.state.image.ImageID) {
            $.ajax({
                url: '/api/ImageAPI/GetSelectedImageDetails',
                type: 'get',
                data: {
                    ImageID: this.state.selectedimageID
                },
                success: function (response) {
                    $grid.layout();
                    if (thiscard.state.image.ImageID != response.ImageID) {
                        thiscard.setState({ image: response })
                    }
                }
            });

        }
    }

    componentDidMount() {
        var thiscard = this;

        if (this.state.selectedimageID == null) {
            $.ajax({
                url: '/api/ImageAPI/GetLatestImageDetails',
                type: 'get',
                data: {
                    SensorID: thiscard.props.sensor.SensorID
                },
                success: function (response) {
                    setTimeout(function () {
                        if ($grid) {
                            try {
                                $grid.layout();
                            }
                            catch (exception) {
                            }
                        }
                    }, 1000);
                    thiscard.setState({ image: response });
                }
            });
        }

        setTimeout(function () {
            if ($grid) {
                try {
                    $grid.layout();
                }
                catch (exception) {
                }
            }
        }, 4000);
    }

    SetImageID = (ImageID) => {
        this.setState({ selectedimageID: ImageID, showImageSelectModal: false });
    }

    handleDatetimerangeUpdate = (start, end, label) => {
        console.log("handleDatetimerangeUpdate triggered");
        var thiscard = this;

        start = start.utc().format();
        end = end.utc().format();
        console.log('New date range selected: ' + start + ' to ' + end + ' (predefined range: ' + label + ')');

        $.ajax({
            url: '/api/ImageAPI/GetImages',
            type: 'get',
            data: {
                SensorID: thiscard.props.sensor.SensorID,
                StartTime: start,
                EndTime: end
            },
            success: function (response) {
                response = JSON.parse(response);
                $grid.layout();
                thiscard.setState({ images: response, showImageSelectModal: true })
            }
        });
    }

    hideModal = () => {
        var thiscard = this;
        thiscard.setState({ showImageSelectModal: false })
    }

    render() {
        return (
            <div hidden={this.props.live}>
                <div class="input-group">
                    <div class="d-none d-lg-block d-xl-block bg-primary p-3 text-light"><i class="far fa-2x fa-calendar-alt"></i></div>
                    <DateRangePicker
                        onCallback={this.handleDatetimerangeUpdate}
                        initialSettings={{
                            autoApply: true,
                            ranges: {
                                'Last 24 hours': [moment().subtract(1, 'days'), moment()],
                                'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                                'Last 7 Days': [moment().subtract(7, 'days'), moment()],
                                'Last 30 Days': [moment().subtract(30, 'days'), moment()],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                            },
                            alwaysShowCalendars: true,
                            maxDate: moment(),
                            startDate: moment().subtract(7, 'days'),
                            endDate: moment(),
                            opens: "center"
                        }}
                    >
                        <input type="text" className="m-3 form-control" name="daterange" />
                    </DateRangePicker>
                    {this.state.image != null ? <label class="verticalalign">Current Image: {moment.utc(this.state.image.time_stamp).local().format('LLL')}</label> : ""}
                </div>
                <hr />
                <img class="w-100" src={this.state.selectedimageID == null ? ("/api/ImageAPI/GetLatestImage/" + this.props.sensor.SensorID) : ("/api/ImageAPI/GetImageByID/" + this.state.selectedimageID)} alt={this.props.sensor.NiceName}></img>
                <ImageSearchModal images={this.state.images} SetImageID={this.SetImageID} show={this.state.showImageSelectModal} hideModal={this.hideModal} />
            </div>);
    }
}

function MediaPlayer(props) {
    const playerRef = React.useRef(null);

    useEffect(() => {
        if (props.src !== "") {
            changeSrc(props.src);
        }

        setTimeout(function () {
            if ($grid) {
                try {
                    $grid.layout();
                }
                catch (exception) {
                }
            }
        }, 10000);

    }, [props.src])

    const videoJsOptions = { // lookup the options in the docs for more options
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        errorDisplay: false,
        muted: true,
        width: "100%"
    }

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // you can handle player events here
        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });
    };

    const changeSrc = (src) => {
        // you can update the player through the Video.js player instance
        if (!playerRef.current) {
            return;
        }
        // [update player through instance's api]
        playerRef.current.src([{ src: src, type: 'application/x-mpegURL' }]);
    };

    return (
        <>
            <div style={{ width: '100%', height: '350px' }} hidden={!props.live || props.noStream}>
                <iframe src={props.src} style={{ width: '100%', height: '100%', resize: 'both' }} allowFullScreen ></iframe>
            </div>
            <div hidden={!props.live || !props.noStream}>
                <img class="w-100" src="/images/StreamUnavailable.png"></img>
            </div>
        </>
    )
}

class SensorCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            Calibrations: [],
            SensorReplacements: [],
            ReplacementProbability: null,
            live: false,
            src: ''
        };
    }

    componentDidMount() {
        var thiscard = this;
        if (this.props.sensor.SensorTypeID == 1 || this.props.sensor.SensorTypeID == 8) {
            $.ajax({
                url: '/api/MaintenanceAPI/GetSensorMaintenance/' + this.props.sensor.SensorID,
                type: 'get',
                success: function (response) {
                    response = JSON.parse(response);
                    console.log(response);

                    thiscard.setState({
                        Calibrations: response.CalibrationHistory,
                        SensorReplacements: response.ReplacementHistory,
                        ReplacementProbability: response.ReplacementProbability
                    });
                }
            });
        }
        if (this.props.sensor.SensorTypeID == 7) {
            // Request the stream to start and get stream URL
            fetch('api/IPCameraStreamingAPI/StartStream/', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.props.sensor.SensorID)
              })
                .then((response) => response.json())
                .then((data) => {
                  if (data === "NULL") {
                    thiscard.setState({noStream: true})
                  } else { 
                    thiscard.setState({src: data})
                  }
                })
                .catch(() => thiscard.setState({noStream: true}));
            // Send heartbeat every minute
            this.interval = setInterval(this.sendHeartbeat, 1 * 60 * 1000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    sendHeartbeat = () => {
        fetch('api/IPCameraStreamingAPI/StreamHeartbeat/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.props.sensor.SensorID)
          })
            .then(() => console.log("Heartbeat sent"))
    }

    liveMode = () => {
        this.setState({ live: true })
    }

    staticMode = () => {
        this.setState({ live: false })
    }

    render() {
        if (this.props.sensor.SensorTypeID == 7) {
            return (<div className="grid-item col-md-6 p-0">
                <div className="m-2 shadow-sm card" >
                    <div className="card-header d-flex align-items-center">
                        <i className="fas fa-2x fa-video"></i>&nbsp;{this.props.sensor.NiceName}
                        <div className="ms-auto">
                            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                <label className={"btn btn-outline-primary " + (this.state.live ? "" : "active")} onClick={this.staticMode} >
                                    Static View
                                </label>
                                <label className={"btn btn-outline-primary " + (this.state.live ? "active" : "")} onClick={this.liveMode} >
                                    Live View
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className="card-body p-3">
                        <CameraFeedStatic live={this.state.live} sensor={this.props.sensor} />
                        <ErrorBoundary>
                            <MediaPlayer live={this.state.live} src={this.state.src} noStream={this.state.noStream} sensorID={this.props.sensor.SensorID} />
                        </ErrorBoundary>
                    </div>
                </div>
            </div>);
        }
        else {
            var replacement;
            if (this.state.ReplacementProbability != null && this.state.ReplacementProbability < 0.9) {
                replacement = <p>There is a {Math.round(this.state.ReplacementProbability * 100)}% chance that this sensor needs to be replaced.</p>
            }
            else if (this.state.ReplacementProbability != null && this.state.ReplacementProbability > 0.9) {
                replacement = <p>Please replace this sensor as soon as possible</p>
            }
            else if (this.props.sensor.SensorTypeID == 1) {
                replacement = <p><i>Insufficient data available to recommend the next replacement date.</i></p>
            }
            return (<div class="grid-item col-md-6 p-0">
                <div class="m-2 shadow-sm card" >
                    <div class="card-header d-flex align-items-center">
                        <i class="fas me-2 fa-2x fa-thermometer-half"></i>{this.props.sensor.NiceName}
                    </div>
                    <div class="card-body p-3">
                        <p>Number of calibrations recorded: {this.state.Calibrations.length}</p>
                        <p>Number of replacements recorded: {this.state.SensorReplacements.length}</p>
                        {replacement}
                        <div class="mb-2">
                            <LogMaintenance MaintenanceType={0} AssetID={this.props.sensor.SensorID} />
                            &nbsp;
                            <MaintenanceAlarmSetup MaintenanceType={0} AssetID={this.props.sensor.SensorID} />
                        </div>
                        <div>
                            <LogMaintenance MaintenanceType={1} AssetID={this.props.sensor.SensorID} />
                            &nbsp;
                            <MaintenanceAlarmSetup MaintenanceType={1} AssetID={this.props.sensor.SensorID} />
                        </div>
                    </div>
                </div>
            </div>);
        }
    }
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        console.log("Error caught: " + error);
        console.log("Info: " + info);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <img class="w-100" src="/images/StreamUnavailable.png"></img>;
        }
        return this.props.children;
    }
}

class ChemicalTankCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fillups: [],
            chemicalorders: [],
            predictions: [],
            graph: null
        };
    }

    createAnnotations = function (tankfills, orders, historicrefills) {
        var annotations = [];
        var i, j, k;
        for (i = 0; i < tankfills.length; i++) {
            annotations.push({
                drawTime: "afterDatasetsDraw",
                type: "line",
                mode: "vertical",
                scaleID: "x-axis-0",
                value: tankfills[i].FillUpDate,
                borderColor: "rgba(0,0,0,1)",
                borderWidth: 1,
                label: {
                    backgroundColor: "rgba(20, 168, 222, 0.6)",
                    content: "Refill",
                    font: {
                        size: 14,
                        family: "sans-serif",
                        color: "rgba(0,0,0,0.5)"
                    },
                    enabled: true,
                    position: "start",
                    xAdjust: 24,
                    yAdjust: 1
                },
                onClick: function (e) {
                    // The annotation is is bound to the `this` variable
                    console.log("Annotation", e.type, this);
                }
            });
        }
        for (j = 0; j < orders.length; j++) {
            annotations.push({
                drawTime: "afterDatasetsDraw",
                type: "line",
                scaleID: "x-axis-0",
                value: orders[j].OrderDate,
                borderColor: "rgba(0,0,0,1)",
                borderWidth: 1,
                label: {
                    backgroundColor: "rgba(20, 168, 222, 0.6)",
                    content: "Place Order",
                    enabled: true,
                    font: {
                        size: 14,
                        family: "sans-serif",
                        color: "rgba(0,0,0,0.5)"
                    },
                    position: "start",
                    xAdjust: 46,
                    yAdjust: 25
                },
                onClick: function (e) {
                    // The annotation is is bound to the `this` variable
                    console.log("Annotation", e.type, this);
                }
            });
            annotations.push({
                drawTime: "afterDatasetsDraw",
                type: "line",
                scaleID: "x-axis-0",
                value: orders[j].ReceiveDate,
                borderColor: "rgba(0,0,0,1)",
                borderWidth: 1,
                label: {
                    backgroundColor: "rgba(20, 168, 222, 0.6)",
                    content: "Receive Order and Refill",
                    enabled: true,
                    font: {
                        size: 14,
                        family: "sans-serif",
                        color: "rgba(0,0,0,0.5)"
                    },
                    position: "start",
                    xAdjust: 87,
                    yAdjust: 49
                },
                onClick: function (e) {
                    // The annotation is is bound to the `this` variable
                    console.log("Annotation", e.type, this);
                }
            });
        }
        for (i = 0; i < historicrefills.length; i++) {
            annotations.push({
                drawTime: "afterDatasetsDraw",
                type: "line",
                scaleID: "x-axis-0",
                value: historicrefills[i].RefillDate,
                borderColor: "rgba(0,0,0,1)",
                borderWidth: 1,
                borderDash: [5, 5],
                label: {
                    backgroundColor: "rgba(20, 168, 222, 0.6)",
                    content: "Refilled",
                    enabled: true,
                    font: {
                        size: 14,
                        family: "sans-serif",
                        color: "rgba(0,0,0,0.5)"
                    },
                    position: "start",
                    xAdjust: 32,
                    yAdjust: 1
                },
                onClick: function (e) {
                    // The annotation is is bound to the `this` variable
                    console.log("Annotation", e.type, this);
                }
            });
        }
        return annotations;
    }

    componentDidMount() {
        var thiscard = this;

        var fd = {
            "ChemicalTankID": this.props.chemicaltank.ChemicalTankID,
        };

        if (this.props.chemicaltank.LevelSignalID != null) {

            $.ajax({
                url: '/api/MaintenanceAPI/GetSignalData/',
                type: 'get',
                data: { SignalID: thiscard.props.chemicaltank.LevelSignalID },
                success: function (response) {

                    $.ajax({
                        url: '/api/MaintenanceAPI/GetChemicalTankMaintenance',
                        type: 'get',
                        data: fd,
                        success: function (tankmaintenanceresponse) {
                            console.log(tankmaintenanceresponse);
                            tankmaintenanceresponse = JSON.parse(tankmaintenanceresponse);

                            $.ajax({
                                url: '/api/MaintenanceAPI/GetGetHistoricTankRefills',
                                type: 'get',
                                data: fd,
                                success: function (historicrefillsresponse) {
                                    console.log(historicrefillsresponse);
                                    historicrefillsresponse = JSON.parse(historicrefillsresponse);

                                    var predictions = tankmaintenanceresponse.Predictions;
                                    var fillups = tankmaintenanceresponse.TankFillUps;
                                    var orders = tankmaintenanceresponse.ChemicalOrders;

                                    var data = [];
                                    var prediction = [];
                                    var i, j;
                                    for (i = 0; i < response.length; i++) {
                                        data.push({ x: response[i].t, y: response[i].v });
                                    }
                                    console.log(predictions)
                                    if (predictions.length > 0) {

                                        for (j = 0; j < predictions.length; j++) {
                                            prediction.push({ x: predictions[j].t, y: predictions[j].v });
                                        }
                                    }
                                    var lineChartData = {
                                        datasets: [{
                                            backgroundColor: 'rgba(244, 121, 32, 1)', //orange
                                            borderColor: 'rgba(0, 44, 79, 0)', //darkblue
                                            label: 'Chemical Level (L)',
                                            borderWidth: 1,
                                            data: data,
                                            fill: false
                                        }, {
                                            backgroundColor: 'rgba(244, 121, 32, 0.5)', //orange
                                            borderColor: 'rgba(0, 44, 79, 0.5)', //darkblue
                                            label: 'Chemical Level Prediction (L)',
                                            borderWidth: 1,
                                            data: prediction,
                                            fill: false,
                                            borderDash: [5, 5],
                                            lineTension: 0
                                        }]

                                    };

                                    var ctx = document.getElementById("levelgraph" + thiscard.props.chemicaltank.ChemicalTankID).getContext('2d');
                                    thiscard.setState({
                                        graph: new Chart(ctx, {
                                            type: 'line',
                                            data: lineChartData,
                                            options: {
                                                responsive: true,
                                                legend: {
                                                    position: 'top',
                                                    labels: {
                                                        usePointStyle: true
                                                    }
                                                },
                                                tooltips: {
                                                    mode: 'nearest',
                                                    intersect: false,
                                                    callbacks: {
                                                        title: function (items, item) {
                                                            return moment(items[0].xLabel).format('LLL');
                                                        },
                                                        label: function (tooltipItem, data) {
                                                            const tooltip = data.datasets[tooltipItem.datasetIndex];
                                                            const value = Math.round(tooltipItem.value * 100) / 100;
                                                            return value === 0 ? null : tooltip.label + ': ' + value;
                                                        }
                                                    }
                                                },
                                                scales: {
                                                    'x-axis-0': {
                                                        position: 'bottom',
                                                        type: 'time',
                                                        time: {
                                                            unit: 'day'
                                                        }
                                                    },
                                                    yAxis: {
                                                        position: 'left',
                                                        beginAtZero: true,
                                                        min: 0
                                                    }
                                                },
                                                plugins: {
                                                    annotation: {
                                                        events: ["click"],
                                                        annotations: { ...thiscard.createAnnotations(fillups, orders, historicrefillsresponse) }
                                                    }
                                                }
                                            }
                                        })
                                    });

                                    setTimeout(function () {
                                        $grid.layout();

                                    }, 2000);

                                    thiscard.setState({
                                        fillups: fillups,
                                        predictions: predictions,
                                        chemicalorders: orders
                                    })
                                }
                            });
                        }
                    });
                }
            });

        }
    }

    render() {
        if (this.props.sitedetails.ProductCategoryID < 15 && !SiteAdmin) {
            return (<div class="grid-item col-md-6 p-0">
                <div class="m-2 shadow-sm card" >
                    <div class="card-header d-flex align-items-center">
                        <i class="fas me-2 fa-2x fa-flask"></i>{this.props.chemicaltank.ChemicalName}
                    </div>
                    <div class="card-body p-3">
                        <p><i>Please upgrade to Streamwise D.I. Advanced for chemical insights.</i></p>
                    </div>
                </div>
            </div>);
        }
        else {
            var fillups = [];
            var i;
            for (i = 0; i < this.state.fillups.length; i++) {
                if (fillups.length == 0) {
                    if (moment(this.state.fillups[0].FillUpDate) > moment().add(1, 'days')) {
                        fillups.push(<p>Next fill up from reserve recommended in {moment.utc(this.state.fillups[0].FillUpDate).diff(moment(), 'days') + 1} days.</p>)
                    }
                    else if (moment(this.state.fillups[0].FillUpDate) <= moment().add(1, 'days')) {
                        fillups.push(<p>Please fill up this tank as soon as possible</p>)
                    }
                }
                else {
                    fillups.push(<p>Fill up from reserve in {moment.utc(this.state.fillups[i].FillUpDate).diff(moment(), 'days') + 1} days</p>)
                }
            }

            var orders = [];
            var j;
            for (j = 0; j < this.state.chemicalorders.length; j++) {
                if (orders.length == 0) {
                    if (moment(this.state.chemicalorders[0].OrderDate) > moment().add(1, 'days')) {
                        orders.push(<p>Next order recommended in {moment.utc(this.state.chemicalorders[0].OrderDate).diff(moment(), 'days') + 1} days for chemicals needed by {moment.utc(this.state.chemicalorders[0].ReceiveDate).local().format('LL')}.</p>)
                    }
                    else if (moment(this.state.chemicalorders[0].OrderDate) <= moment().add(1, 'days')) {
                        orders.push(<p>Please order chemical inventory as soon as possible for chemicals needed by {moment.utc(this.state.chemicalorders[0].ReceiveDate).local().format('LL')}.</p>)
                    }
                }
                else {
                    if ((moment.utc(this.state.chemicalorders[j].OrderDate).diff(moment(), 'days') + 1) < 2) {
                        orders.push(<p>Please order chemical inventory as soon as possible for chemicals needed by {moment.utc(this.state.chemicalorders[j].ReceiveDate).local().format('LL')}.</p>)
                    }
                    else {
                        orders.push(<p>Order in {moment.utc(this.state.chemicalorders[j].OrderDate).diff(moment(), 'days') + 1} days for chemicals needed by {moment.utc(this.state.chemicalorders[j].ReceiveDate).local().format('LL')}.</p>)
                    }
                }
            }

            if (fillups.length == 0 && orders.length == 0) {
                fillups.push(<p><i>No need to refill the tank in the next 30 days.</i></p>)
            }
            if (orders.length == 0) {
                orders.push(<p><i>No need to order chemical chemical inventory in the next 30 days.</i></p>)
            }
            return (<div class="grid-item col-md-6 p-0">
                <div class="m-2 shadow-sm card" >
                    <div class="card-header d-flex align-items-center">
                        <i class="fas me-2 fa-2x fa-flask"></i>{this.props.chemicaltank.ChemicalName}
                    </div>
                    <div class="card-body p-3">
                        {this.props.chemicaltank.ReserveCapacity > 0 ? <p>Volume in reserve: {this.props.chemicaltank.VolumeInReserve}L</p> : ""}
                        {this.state.graph == null ? "Loading data..." : fillups}
                        {this.state.graph == null ? <div className="text-center"><img style={{ height: '24px', width: '24px' }} src="/lib/ajax-loader.gif" /></div> : orders}
                    </div>
                    {this.props.chemicaltank.LevelSignalID != null ?
                        (<div><hr class="m-0" />
                            <canvas class="sitedetailsbargraph" height="300px" id={"levelgraph" + this.props.chemicaltank.ChemicalTankID}></canvas></div>)
                        : ""}
                </div>
            </div>);
        }

    }
}

export function Maintenance(props) {
    const { id } = useParams();
    const [sitedetails, setSiteDetails] = useState([])
    const [equipment, setEquipment] = useState({ SitePumps: [], SiteSensors: [] })
    const [showversioncontrolmodal, setShowVersionControlModal] = useState(false)
    let history = useHistory();

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id), [id]);


    function handleClose() {
        setShowVersionControlModal(false)
    }

    function CheckVersion() {
        if (MaintenanceTabVersion < CurrentMaintenanceTabVersion) {
            //Show modal and update cost breakdown tab version for user so they don't see the message on other devices
            var fd = {
                "MaintenanceTabVersion": CurrentMaintenanceTabVersion
            };
            $.ajax({
                url: '/api/VersionControlAPI/SetMaintenanceTabVersion',
                data: fd,
                type: 'get',
                success: function (response) {
                    MaintenanceTabVersion = CurrentMaintenanceTabVersion;
                    Cookies.set('MaintenanceTabVersion', CurrentMaintenanceTabVersion, { expires: 7999 });

                }
            });
            setShowVersionControlModal(true)
        }
    }

    useEffect(() => {
        // Version control modal
        CheckVersion()
        //First run
        if (MaintenanceTabVersion == undefined || MaintenanceTabVersion < CurrentMaintenanceTabVersion) {
            //Get version from server
            $.ajax({
                url: '/api/VersionControlAPI',
                type: 'get',
                success: function (response) {
                    console.log(response);

                    MaintenanceTabVersion = response.maintenanceTabVersion;
                    Cookies.set('HomeTabVersion', response.homeTabVersion, { expires: 7999 });
                    Cookies.set('AnalyticsTabVersion', response.analyticsTabVersion, { expires: 7999 });
                    Cookies.set('HealthSummaryTabVersion', response.healthSummaryTabVersion, { expires: 7999 });
                    Cookies.set('CostBreakdownTabVersion', response.costBreakdownTabVersion, { expires: 7999 });
                    Cookies.set('DeepDiveTabVersion', response.deepDiveTabVersion, { expires: 7999 });
                    Cookies.set('MaintenanceTabVersion', response.maintenanceTabVersion, { expires: 7999 });
                    Cookies.set('BillingTabVersion', response.billingTabVersion, { expires: 7999 });
                    Cookies.set('AlarmsTabVersion', response.alarmsTabVersion, { expires: 7999 });
                    Cookies.set('ControlTabVersion', response.controlTabVersion, { expires: 7999 });
                    Cookies.set('CalibrationsTabVersion', response.calibrationsTabVersion, { expires: 7999 });
                    Cookies.set('AirCleaningTabVersion', response.airCleaningTabVersion, { expires: 7999 });
                    Cookies.set('ReportsTabVersion', response.reportsTabVersion, { expires: 7999 });
                    Cookies.set('SiteDetailsTabVersion', response.siteDetailsTabVersion, { expires: 7999 });
                    Cookies.set('DownloadsTabVersion', response.downloadsTabVersion, { expires: 7999 });

                    CheckVersion();
                }
            });
        }
    }, []);

    useEffect(() => {
        $.ajax({
            url: '/api/DigitalTwinAPI/GetSiteEquipmentDetails/' + id,
            type: 'get',
            success: function (response) {
                response = JSON.parse(response);
                console.log(response);

                setSiteDetails(response.SiteDetails);
                setEquipment(response.Equipment)

                var masonryOptions = {
                    itemSelector: '.grid-item',
                    animate: true,
                    stagger: 50,
                    columnWidth: '.grid-sizer',
                    percentPosition: true
                };

                var elem = document.querySelector('#masonrycards');
                $grid = new Masonry(elem, masonryOptions);

                setTimeout(function () {
                    try {
                        $grid.reloadItems();
                        $grid.layout();
                    } catch (exception) {
                        elem = document.querySelector('#masonrycards');
                        $grid = new Masonry(elem, masonryOptions);

                        $grid.reloadItems();
                        $grid.layout();
                    }
                }, 1000);
            }
        });
    }, [id]);

    var pumps = [];
    var sensors = [];
    var chemicaltanks = [];
    for (var i = 0; i < equipment.SitePumps.length; i++) {
        pumps.push(<PumpCard key={"pump" + equipment.SitePumps[i].PumpID} pump={equipment.SitePumps[i]} />);

        var found = false;
        for (var k = 0; k < chemicaltanks.length; k++) {
            if (chemicaltanks[k].props.chemicaltank.ChemicalTankID == equipment.SitePumps[i].ChemicalTankID) {
                found = true;
            }
        }
        if (!found) {
            console.log(found);
            chemicaltanks.push(<ChemicalTankCard chemicaltank={equipment.SitePumps[i].ChemicalTank} sitedetails={sitedetails} />);
        }
    }
    for (var j = 0; j < equipment.SiteSensors.length; j++) {
        if (equipment.SiteSensors[j].SensorTypeID == 7) {
            sensors.push(<SensorCard sensor={equipment.SiteSensors[j]} />);
        }
    }
    for (var l = 0; l < equipment.SiteSensors.length; l++) {
        if (equipment.SiteSensors[l].SensorTypeID != 7) {
            sensors.push(<SensorCard sensor={equipment.SiteSensors[l]} />);
        }
    }
    return (
        props.auth !== undefined ?
            props.auth > 0 ?
                <>
                    <HelpButton showHelpModal={setShowVersionControlModal} />
                    <h1>{equipment.NiceName} Maintenance</h1>
                    <Link onClick={history.goBack} className="align-items-center"><div className="m-2"><i class="fad fa-arrow-left"></i> Back</div></Link>
                    <div id="masonrycards" class="container-fluid p-0">
                        <div class="grid-sizer col-md-1"></div>
                        <MaintenanceCard equipment={equipment} />
                        {pumps}
                        {sensors}
                        {chemicaltanks}
                        <Modal show={showversioncontrolmodal} onHide={handleClose} size="lg">
                            <Modal.Header closeButton>
                                <h5 class="modal-title" id="exampleModalLongTitle">What's New?</h5>
                            </Modal.Header>
                            <Modal.Body className="text-center">
                                <YouTubeVideo VideoTag="Maintenance" />
                                <p className="text-start">The new "Maintenance" tab lets you see detailed insights on each piece of equipment at your wastewater treatment plant.</p>
                                <p class="d-flex justify-content-center"><img class="w-75 card shadow p-3" src="/images/HowTos/maintenance1.png" alt="Equipment Maintenance" /></p>
                                <p class="d-flex justify-content-center"><img class="w-75 card shadow p-3" src="/images/HowTos/maintenance2.png" alt="Pump Maintenance" /></p>
                                <p class="d-flex justify-content-center"><img class="w-75 card shadow p-3" src="/images/HowTos/maintenance3.png" alt="Sensor Maintenance" /></p>
                                <p class="d-flex justify-content-center"><img class="w-75 card shadow p-3" src="/images/HowTos/maintenance4.png" alt="Chemical Management" /></p>
                                <p class="d-flex justify-content-center"><img class="w-75 card shadow p-3" src="/images/HowTos/maintenance5.png" alt="Cameras" /></p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
                :
                <NoAccessPage />
            : <></>);
};
