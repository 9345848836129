import React, { useState, useEffect } from 'react';
import {
    Tabs,
    Tab,
    Button,
    ButtonGroup,
    Modal,
    Collapse,
} from "react-bootstrap";
import $ from 'jquery';
import Cookies from 'cookies-js';
import { useParams } from 'react-router-dom';
import Masonry from 'masonry-layout';
import moment from 'moment';
import * as signalR from '@microsoft/signalr'
import RangeSlider from 'react-bootstrap-range-slider';
import { Chart, registerables } from 'chart.js';
import YouTubeVideo from './YouTubeVideo';
import 'chartjs-adapter-moment';
import NoAccessPage from './NoAccessPage';
import HelpButton from './HelpButton';
import CompositeCard from './ControlComponents/CompositeCard';
import TotaliserCard from './ControlComponents/TotaliserCard';

Chart.register(...registerables);

//Chart JS Code for live Charts
var $grid;
var phDict = {};

export function SignalCard(props) {
    const [pHMode, setpHMode] = useState(false)
    const [calibrationlog, setCalibrationLog] = useState([])
    const [calibrationtooltipvalue, setCalibrationTooltipValue] = useState(7)
    const [calibrationlogopen, setCalibrationLogOpen] = useState(false);
    const [signaldata, setSignalData] = useState([])
    const [newpreloaddata, setNewPreloadData] = useState([])
    const [frozen, setFreeze] = useState(props.signal.freeze)
    const [chart, setChart] = useState()
    const [gradient, setGradient] = useState(props.signal.fineGradient)
    const [intercept, setIntercept] = useState(props.signal.fineIntercept)
    const [min, setMin] = useState(props.signal.fineMin)
    const [max, setMax] = useState(props.signal.fineMax)
    const [chartState, setChartState] = useState({
        labels: [],
        datasets: [{
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: [],
        }]
    });
    const [key, setKey] = useState('calibration');
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [showTotaliserModal, setShowTotaliserModal] = useState(false);
    const [modalStage, setModalStage] = useState(0);
    const [submittedCalibrationID, setSubmittedCalibrationID] = useState();
    const [isDirect, setIsDirect] = useState(true);
    const [visibleItemCount, setVisibleItemCount] = useState(5)

    useEffect(() => {
        console.log(props)
        var ctx = document.getElementById(props.signal.signalID + "-chart").getContext('2d');
        if (chart) {
            chart.destroy();
        }
        setChart(new Chart(ctx, {
            type: 'line',
            data: chartState,
            options: {
                scales: {
                    x: {
                        type: 'time'
                    }
                },
                plugins: { legend: { display: false } },
                title: { display: false }
            }
        }));

        setTimeout(function () {
            if ($grid) {
                try {
                    $grid.layout();
                }
                catch (exception) {
                }
            }
        }, 1000);

        // Preloaded data looks messy - removed for now but might need later
        //$.ajax({
        //    url: '/api/CalibrationsAPI/GetCalibrationData/',
        //    type: 'get',
        //    data: { SignalID: props.signal.signalID },
        //    success: function (response) {
        //        setNewPreloadData(response)
        //    }
        //});
    }, [])

    useEffect(() => {
        if (chart && chart != null && props.latestvalues) {
            chart.data.datasets.forEach(dataset => {
                dataset.data = signaldata
            });

            // Get times
            var time = new Date()

            // Time range of xaxis in minutes
            var dt = (time - signaldata[0]) / 1000 / 60;
            // Delete datapoints more than 5 minutes old
            if (dt >= 5) {
                setSignalData([
                    ...signaldata.slice(0, 0),
                    ...signaldata.slice(1)
                ])
            }

            chart.update()

            if ($grid) {
                try {
                    $grid.layout();
                }
                catch (exception) {
                }
            }

            setSignalData([...signaldata, { x: time, y: props.latestvalues[props.signal.signalID] }])
        }
    }, [props.latestvalues])

    // useEffect(() => {
    //     for (var i = newpreloaddata.length - 1; i >= 0; i--) {
    //         setSignalData([{ x: new Date(newpreloaddata[i].t), y: newpreloaddata[i].v }, ...signaldata])
    //     }
    // }, [newpreloaddata])

    useEffect(() => {
        // Check if this is a pH sensor
        console.log(props);
        var url = '/api/CalibrationsAPI/IsPHSignal?';
        fetch(
            url +
            new URLSearchParams({
                SignalID: props.signal.signalID,
            }),
        ).then(response => response.json())
            .then(data => {
                setpHMode(data)
            });
    }, [])

    // Load calibration log
    useEffect(() => {
        var sig = props.signal;
        phDict[sig.signalID] = {};
        phDict[sig.signalID]["xvals"] = [];
        phDict[sig.signalID]["yvals"] = [];

        setTimeout(function () {
            try {
                $grid.layout();
            }
            catch (exception) {
            }

        }, 1000);
        //chartsStorage[sig.signalID + "-chart"] = newChart;

        $.ajax({
            url: '/api/CalibrationsAPI/GetCalibrationLog/',
            type: 'get',
            data: { SignalID: props.signal.signalID },
            success: function (response) {
                response = JSON.parse(response);
                console.log(response);
                try {
                    $grid.layout();
                }
                catch (exception) {
                }
                setCalibrationLog(response)

            }
        });

    }, [props.signal.signalID])

    // Save gradient and intercept values for signal to edge
    function submitCalibration() {
        setModalStage(1);
        var tuneModel = {
            SignalID: props.signal.signalID,
            Gradient: document.getElementById(props.signal.signalID + "-gradient").value,
            Intercept: document.getElementById(props.signal.signalID + "-intercept").value,
            Min: document.getElementById(props.signal.signalID + "-min").value,
            Max: document.getElementById(props.signal.signalID + "-max").value,
            XValues: phDict[props.signal.signalID]["xvals"],
            YValues: phDict[props.signal.signalID]["yvals"],
            DeviceID: props.deviceId
        };

        $.ajax({
            type: "POST",
            url: "/api/CalibrationsAPI/SubmitCalibration",
            data: JSON.stringify(tuneModel),
            contentType: 'application/json',
            success: function (response) {
                setModalStage(2);
                setSubmittedCalibrationID(response);
                // clear the points added
                phDict[props.signal.signalID]["xvals"] = [];
                phDict[props.signal.signalID]["yvals"] = [];
            }
        });
    }

    // Calculate gradient and intercept
    function calcCalibration(signalID) {
        var n = phDict[signalID]["xvals"].length;
        var x_ = calcMean(phDict[signalID]["xvals"]);
        var y_ = calcMean(phDict[signalID]["yvals"]);
        var xi2 = calcSumMulti(phDict[signalID]["xvals"], phDict[signalID]["xvals"]);
        var xiyi = calcSumMulti(phDict[signalID]["xvals"], phDict[signalID]["yvals"]);

        // intercept
        var a = (y_ * xi2 - x_ * xiyi) / (xi2 - n * x_ * x_);
        // gradient
        var b = (xiyi - n * x_ * y_) / (xi2 - n * x_ * x_);

        // Apply to previous calibration
        if (calibrationlog.length != 0) {
            let prevCalibEntry = calibrationlog[0].Calibration;
            a = b * prevCalibEntry.Intercept + a;
            b = prevCalibEntry.Gradient * b;
        }

        // push to html
        setGradient(b)
        setIntercept(a)
    }

    function calcMean(arrayVals) {
        var sum = 0;
        for (var i = 0; i < arrayVals.length; i++)
            sum += parseFloat(arrayVals[i]);
        return sum / arrayVals.length;
    }

    function calcSumMulti(array1, array2) {
        var sum = 0;
        for (var i = 0; i < array1.length; i++)
            sum += parseFloat(array1[i]) * parseFloat(array2[i]);
        return sum
    }

    function CalibrationLogToggle() {
        setCalibrationLogOpen(!calibrationlogopen)
        setTimeout(function () {
            if ($grid) {
                try {
                    $grid.layout();
                }
                catch (exception) {
                    console.log(exception);
                }
            }
        }, 3000);
    };


    function addPoint() {
        // Real value is pH value
        var realVal = calibrationtooltipvalue;
        var measVal = document.getElementById(props.signal.signalID + "-value").innerHTML;

        var newRow = document.getElementById(props.signal.signalID + "-table").insertRow();
        newRow.innerHTML = `<td>${realVal}</td><td>${measVal}</td>`;

        // push values to array
        phDict[props.signal.signalID]["xvals"].push(measVal);
        phDict[props.signal.signalID]["yvals"].push(realVal);
        calcCalibration(props.signal.signalID);
    }


    function submitReplacement() {
        // Get checkbox
        var checkVal = document.getElementById(props.signal.signalID + "-manualReplacement").checked;
        $.ajax({
            type: "GET",
            url: "/api/SensorReplacement/" + checkVal,
            data: {
                signalID: props.signal.signalID
            },
            error: function () {
                alert("No sensor ID found");
            }
        });
    }

    function freezeLogging() {
        $.ajax({
            type: "GET",
            url: "/api/CalibrationsAPI/FreezeSignal",
            data: {
                deviceID: props.deviceId,
                signalID: props.signal.signalID,
                freezeCmd: !frozen
            },
            success: function () {
                // Change button value to show that freeze was successful
                setFreeze(!frozen)
            }
        })
    }

    function setValue(value) {
        setCalibrationTooltipValue(value)
    }


    var items = [];
    for (var i = 0; i < calibrationlog.length; i++) {
        var points = [];
        for (var j = 0; j < calibrationlog[i].Calibration.CalibrationPoints.length; j++) {
            points.push(
                <div class="row" key={j + "calibration" + i + props.signal.signalID}>
                    <div class="col">Reference: {Math.round(calibrationlog[i].Calibration.CalibrationPoints[j].XValue * 1000) / 1000}</div>
                    <div class="col">Measured: {Math.round(calibrationlog[i].Calibration.CalibrationPoints[j].YValue * 1000) / 1000}</div>
                </div>
            )
        }

        items.push(
            <div key={props.signal.signalID + "calibrationlog" + i} class="border p-2">
                <div class="row">
                    <div class="col">Gradient: {Math.round(calibrationlog[i].Calibration.Gradient * 1000) / 1000}</div>
                    <div class="col">Intercept: {Math.round(calibrationlog[i].Calibration.Intercept * 1000) / 1000}</div>
                </div>
                {calibrationlog[i].Calibration.CalibrationPoints.length > 0 ? <div class="mt-2">Points used:</div> : <div>This was a manual calibration</div>}
                {points}

                <div className="d-flex justify-content-between">
                    <div className="text-secondary text-left m-1" style={{ fontSize: '12px' }}>
                        {calibrationlog[i].Calibration.UserID}
                    </div>
                    <div class="text-secondary text-end m-1" style={{ fontSize: '12px' }}>
                        {moment.utc(calibrationlog[i].Calibration.CalibrationTime).local().format('LLL')}
                    </div>
                </div>

                {calibrationlog[i].CertificateAvailable &&
                    <div>
                        <a href={"/api/CalibrationsAPI/GetCalibrationCertificate?id=" + calibrationlog[i].Calibration.CalibrationID}>
                            Download certificate <i className="fas fa-file-certificate"></i>
                        </a>
                    </div>
                }
            </div>
        )
    }
    return (
        <div class="m-2 shadow-sm card">
            <div class="card-header">
                <h4 style={{ "display": "inline-block" }}>{props.signal.niceName}</h4>
                <p class="m-0" id={props.signal.signalID + "-value"}>{props.latestvalues ? Math.round(props.latestvalues[props.signal.signalID] * 1000) / 1000 : ""}</p>
            </div>
            <div class="card-body p-3">
                <canvas id={props.signal.signalID + "-chart"}></canvas>
                <hr />
                <Tabs
                    activeKey={key}
                    onSelect={k => setKey(k)}
                    className="mb-3"
                    fill
                >
                    <Tab eventKey="calibration" title="Calibration" className="bg-white">
                        {pHMode &&
                            <>
                                <div id={props.signal.signalID + "-ph"}>
                                    <div>Set the buffer value and click "Add Point" once stable:</div>
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }} className="mt-2">
                                        <div style={{ width: '60%' }}>
                                            <RangeSlider
                                                value={calibrationtooltipvalue}
                                                onChange={changeEvent => setValue(changeEvent.target.value)}
                                                min={1}
                                                max={14}
                                                step={1}
                                                tooltip={'on'}
                                                class="w-100"
                                            />
                                        </div>
                                        <Button
                                            id={props.signal.signalID + "-ph-button"}
                                            onClick={addPoint}
                                            style={{ display: "inline-flex" }}>
                                            Add Point
                                        </Button>
                                    </div>

                                    <table id={props.signal.signalID + "-table"} class="w-100 mt-5">
                                        <tr>
                                            <th>Real Value</th>
                                            <th>Measured Value</th>
                                        </tr>
                                    </table>
                                    {(phDict[props.signal.signalID] && phDict[props.signal.signalID]["xvals"].length === 0) && <div>No points have been added yet</div>}
                                </div>
                                <hr />
                            </>
                        }
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="control-label">Gradient</label>
                                <input class="form-control" id={props.signal.signalID + "-gradient"} type="number" value={Math.round(gradient * 10000) / 10000} onChange={(e) => setGradient(e.target.value)} />
                            </div>

                            <div class="form-group col-md-6">
                                <label class="control-label">Intercept</label>
                                <input class="form-control" id={props.signal.signalID + "-intercept"} type="number" value={Math.round(intercept * 10000) / 10000} onChange={(e) => setIntercept(e.target.value)} />
                            </div>

                            <div class="form-group col-md-6">
                                <label class="control-label">Minimum</label>
                                <input class="form-control" id={props.signal.signalID + "-min"} type="number" value={min} onChange={(e) => setMin(e.target.value)} />
                            </div>

                            <div class="form-group col-md-6">
                                <label class="control-label">Maximum</label>
                                <input class="form-control" id={props.signal.signalID + "-max"} type="number" value={max} onChange={(e) => setMax(e.target.value)} />
                            </div>

                            <div className="mt-2">
                                <Button onClick={() => setShowSubmitModal(true)}>
                                    Submit calibration
                                </Button>
                                {calibrationlog.length > 0 ?
                                    <Button variant="secondary" className="ms-1 fas fa-caret-down"
                                        onClick={CalibrationLogToggle}
                                        aria-controls={props.signal.signalID + "calibrationlog"}
                                        aria-expanded={calibrationlogopen}> 
                                    </Button> : <div className="ms-1">No calibrations recorded</div>
                                }
                                <SubmitModal
                                    show={showSubmitModal}
                                    hide={() => {
                                        setShowSubmitModal(false);
                                        setModalStage(0);
                                    }}
                                    gradient={Math.round(gradient * 10000) / 10000}
                                    intercept={Math.round(intercept * 10000) / 10000}
                                    min={min}
                                    max={max}
                                    submitCalibration={submitCalibration}
                                    pHMode={pHMode}
                                    stage={modalStage}
                                    submittedCalibrationID={submittedCalibrationID}
                                />
                            </div>
                        </div> 

                        {calibrationlog.length > 0 &&
                            <Collapse in={calibrationlogopen}>
                                <div id={props.signal.signalID + "calibrationlog"} className="mt-1">
                                    {items.map((item, index) => (
                                        <div key={index} style={{ display: index < visibleItemCount ? "block" : "none" }}>
                                            {item}
                                        </div>
                                    ))}
                                    {visibleItemCount < items.length && (
                                        <Button onClick={() => setVisibleItemCount(visibleItemCount + 1)} style={{ marginTop: "10px" }} >Load More</Button>
                                    )}
                                </div>
                            </Collapse>
                        }

                    </Tab>
                    <Tab eventKey="replacement" title="Replacement" className="bg-white">
                        <div>
                            <input className="ml-2" type="checkbox" id={props.signal.signalID + "-manualReplacement"} />
                            <label for={props.signal.signalID + "-manualReplacement"}>Non-routine replacement</label>
                        </div>
                        <ButtonGroup>
                            <Button variant='secondary' onClick={submitReplacement}>
                                Log a replacement <i class="fas fa-tools"></i>
                            </Button>
                        </ButtonGroup>
                    </Tab>
                    <Tab eventKey="other" title="Other" className="bg-white">
                        <div class="mb-2">
                            <ButtonGroup>
                                <Button
                                    id={props.signal.signalID + "-freeze"}
                                    onClick={freezeLogging}>
                                    {frozen ? "Unfreeze signal" : "Freeze signal"}
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

function SubmitModal(props) {
    return (
        <Modal show={props.show} onHide={props.hide}>
            <Modal.Header closeButton>
                <Modal.Title>Submit Calibration</Modal.Title>
            </Modal.Header>
            {props.stage === 0 &&
                <Modal.Body>
                    Are you sure want to submit the following calibration:
                    <div class="row mb-2">
                        <div class="form-group col-md-6">
                            <label class="control-label"><strong>Gradient:</strong> {props.gradient} </label>
                        </div>

                        <div class="form-group col-md-6">
                            <label class="control-label"><strong>Intercept:</strong> {props.intercept}</label>
                        </div>

                        <div class="form-group col-md-6">
                            <label class="control-label"><strong>Minimum:</strong> {props.min}</label>
                        </div>

                        <div class="form-group col-md-6">
                            <label class="control-label"><strong>Maximum:</strong> {props.max}</label>
                        </div>
                    </div>
                </Modal.Body>
            }
            {props.stage === 1 &&
                <Modal.Body>
                    Submitting...
                    <img style={{ height: '24px', width: '24px', marginLeft: '10px' }} src="/lib/ajax-loader.gif" />
                </Modal.Body>
            }
            {props.stage === 2 &&
                <Modal.Body>
                    <div>
                        Calibration successfully submitted!
                    </div>
                    {props.pHMode && props.submittedCalibrationID &&
                        <a href={"/api/CalibrationsAPI/GetCalibrationCertificate?id=" + props.submittedCalibrationID}>
                            Download certificate <i className="fas fa-file-certificate"></i>
                        </a>
                    }
                </Modal.Body>
            }
            <Modal.Footer>
                <Button variant="secondary" onClick={props.hide}>
                    Close
                </Button>
                {props.stage === 0 &&
                    <Button
                        variant="primary"
                        onClick={props.submitCalibration}>
                        Submit
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    )
}

function DeviceSignals(props) {
    const [signals, setSignals] = useState([])
    const [loadingsignals, setLoadingSignals] = useState(true)
    const [latestvalues, setLatestValues] = useState()

    useEffect(() => {
        var masonryOptions = {
            itemSelector: '.grid-item',
            animate: true,
            stagger: 50,
            columnWidth: '.grid-sizer',
            percentPosition: true
        };

        var elem = document.querySelector('#' + props.deviceID + 'calibrationcards');
        $grid = new Masonry(elem, masonryOptions);
        setTimeout(function () {
            try {
                $grid.reloadItems();
                $grid.layout();
            }
            catch (exception) {
            }
        }, 1000);
    }, [signals, props.deviceID, props.active])

    useEffect(() => {
        if (props.active) {
            console.log("Starting connection")
            console.log(connection)
            if (connection.connectionState == "Connected") {
                connection.stop().then(function () {
                    connection.start().then(function () {
                        listenertag = "DoSomething" + connection.connectionId;
                        // Send connection listener tag to edge via API call
                        console.log("Sending streaming command to device " + props.deviceID)
                        $.ajax({
                            url: "/api/SharedCommandsAPI/ToggleSignalR",
                            type: "get",
                            data: { deviceID: props.deviceID, listenerTag: listenertag, state: true }
                        });

                        connection.on(listenertag, function (message) {
                            setLatestValues(JSON.parse(message))
                        });
                    }).catch(function (err) {
                        console.error(err);

                    }).finally(function () {
                        console.log("Couldn't handle signalR connection")
                    });
                })
            }
            else {
                connection.start().then(function () {
                    listenertag = "DoSomething" + connection.connectionId;
                    // Send connection listener tag to edge via API call
                    console.log("Sending streaming command to device " + props.deviceID)
                    $.ajax({
                        url: "/api/SharedCommandsAPI/ToggleSignalR",
                        type: "get",
                        data: { deviceID: props.deviceID, listenerTag: listenertag, state: true }
                    });

                    connection.on(listenertag, function (message) {
                        setLatestValues(JSON.parse(message))
                    });
                }).catch(function (err) {
                    console.error("444: " + err.toString());

                }).finally(function () {
                    console.log("Couldn't handle signalR connection")
                });
            }

        }
        else {
            // Stop signal streaming on unmount
            $.ajax({
                url: "/api/SharedCommandsAPI/ToggleSignalR",
                type: "get",
                data: { deviceID: props.deviceID, listenerTag: listenertag, state: false }
            });
        }
    }, [props.active])

    useEffect(() => {
        try {
            setSignals([])
        }
        catch (exception) {
        }
        setLoadingSignals(true)

        $.ajax({
            url: '/api/CalibrationsAPI/GetSignals/',
            type: 'get',
            data: { DeviceID: props.deviceID },
            success: function (response) {
                try {
                    console.log(response);
                    setSignals(response)
                }
                catch (exception) {
                }
                setLoadingSignals(false)

                var masonryOptions = {
                    itemSelector: '.grid-item',
                    animate: true,
                    stagger: 50,
                    columnWidth: '.grid-sizer',
                    percentPosition: true
                };

                var elem = document.querySelector('#' + props.deviceID + 'calibrationcards');
                $grid = new Masonry(elem, masonryOptions);

                setTimeout(function () {
                    try {
                        if (response.length > 0) {
                            $grid.reloadItems();
                        }
                        $grid.layout();
                    }
                    catch (exception) {
                    }
                }, 1000);
            }
        });
    }, [props.deviceID, props.active])

    var cards = [];
    var compositeCards = [];
    var totaliserCards = [];
    for (var i = 0; i < signals.length; i++) {
        if (signals[i].isTotaliser) {
            totaliserCards.push(
                <TotaliserCard
                    key={signals[i].signalID}
                    data={signals[i]}
                    deviceID={props.deviceID}
                    latestValues={latestvalues}
                    $grid={$grid}
                />
            );
        }
        else if (signals[i].protocol == -1) {
            compositeCards.push(
                <CompositeCard
                    key={signals[i].signalID}
                    data={signals[i]}
                    deviceID={props.deviceID}
                    latestValues ={latestvalues}
                    $grid={$grid} />
            );
        }
        else {
            cards.push(
                <div class="grid-item col-lg-4 col-md-6 p-0" key={signals[i].signalID + "signalcard"}>
                    <SignalCard
                        latestvalues={latestvalues}
                        deviceId={props.deviceID}
                        signal={signals[i]}
                    />
                </div>)
        }
    }

    return (<div id={props.deviceID + "calibrationcards"} class="container-fluid p-0">
        <div class="grid-sizer col-md-1"></div>
        {loadingsignals ? <div className="grid-item col-12 p-0">
            <div className="m-2 shadow p-0 card" >
                <div className="card-header">Fetching signal information...</div>
                <div className="card-body p-3">
                    <img style={{ height: '24px', width: '24px', marginLeft: '10px' }} src="/lib/ajax-loader.gif" />
                </div>
            </div>
        </div> : ((cards.length + compositeCards.length) > 0 ? [...cards, ...compositeCards, ...totaliserCards] : <div className="grid-item col-12 p-0">
            <div className="m-2 shadow p-0 card" >
                <div className="card-header">No signals available</div>
                <div className="card-body p-3">
                    No signals available.
                </div>
            </div>
        </div>)}
    </div>);
}

//Show info modal if updated since last visit
var CalibrationsTabVersion = Cookies.get('CalibrationsTabVersion');
var CurrentCalibrationsTabVersion = 4;


export function Calibration(props) {
    const { id } = useParams();
    const [devices, setDevices] = useState([])
    const [showversioncontrolmodal, setShowVersionControlModal] = useState(false)
    const [currentDeviceID, setCurrentDeviceID] = useState("");

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id), [id]);


    const _handleDeviceSelect = (deviceID) => {
        setCurrentDeviceID(deviceID);
    }

    useEffect(() => {
        // Get devices
        $.ajax({
            url: '/api/SharedCommandsAPI/GetDevices',
            type: 'get',
            data: { siteGUID: id },
            success: response => {
                response = JSON.parse(response);
                setDevices(response);
                if (response.length > 0) {
                    setCurrentDeviceID(response[0].DeviceID);
                }
            }
        })
    }, [id]);

    function handleClose() {
        setShowVersionControlModal(false)
    }

    function CheckVersion() {
        if (CalibrationsTabVersion < CurrentCalibrationsTabVersion) {
            //Show modal and update calibrations tab version for user so they don't see the message on other devices
            var fd = {
                "CalibrationsTabVersion": CurrentCalibrationsTabVersion
            };
            $.ajax({
                url: '/api/VersionControlAPI/SetCalibrationsTabVersion',
                data: fd,
                type: 'get',
                success: function (response) {
                    CalibrationsTabVersion = CurrentCalibrationsTabVersion;
                    Cookies.set('CalibrationsTabVersion', CurrentCalibrationsTabVersion, { expires: 7999 });

                }
            });
            setShowVersionControlModal(true)
        }
    }

    useEffect(() => {
        // Version control modal
        CheckVersion()
        //First run
        if (CalibrationsTabVersion == undefined || CalibrationsTabVersion < CurrentCalibrationsTabVersion) {
            //Get version from server
            $.ajax({
                url: '/api/VersionControlAPI',
                type: 'get',
                success: function (response) {
                    console.log(response);

                    CalibrationsTabVersion = response.calibrationsTabVersion;
                    Cookies.set('HomeTabVersion', response.homeTabVersion, { expires: 7999 });
                    Cookies.set('AnalyticsTabVersion', response.analyticsTabVersion, { expires: 7999 });
                    Cookies.set('HealthSummaryTabVersion', response.healthSummaryTabVersion, { expires: 7999 });
                    Cookies.set('CostBreakdownTabVersion', response.costBreakdownTabVersion, { expires: 7999 });
                    Cookies.set('DeepDiveTabVersion', response.deepDiveTabVersion, { expires: 7999 });
                    Cookies.set('MaintenanceTabVersion', response.maintenanceTabVersion, { expires: 7999 });
                    Cookies.set('BillingTabVersion', response.billingTabVersion, { expires: 7999 });
                    Cookies.set('AlarmsTabVersion', response.alarmsTabVersion, { expires: 7999 });
                    Cookies.set('ControlTabVersion', response.controlTabVersion, { expires: 7999 });
                    Cookies.set('CalibrationsTabVersion', response.calibrationsTabVersion, { expires: 7999 });
                    Cookies.set('AirCleaningTabVersion', response.airCleaningTabVersion, { expires: 7999 });
                    Cookies.set('ReportsTabVersion', response.reportsTabVersion, { expires: 7999 });
                    Cookies.set('SiteDetailsTabVersion', response.siteDetailsTabVersion, { expires: 7999 });
                    Cookies.set('DownloadsTabVersion', response.downloadsTabVersion, { expires: 7999 });

                    CheckVersion();
                }
            });
        }
    }, []);

    return (
        props.auth !== undefined ?
            props.auth > 0 ?
                <>
                    <HelpButton showHelpModal={setShowVersionControlModal} />
                    <h1>Calibrations</h1>
                    {devices.length > 0 ?
                        <Tabs
                            activeKey={currentDeviceID}
                            fill justify
                            onSelect={k => _handleDeviceSelect(k)}
                            className="container-fluid p-0">
                            {devices.map(item =>
                                <Tab eventKey={item.DeviceID} title={item.NiceName} key={item.DeviceID + "-calibrationtab"}
                                    className="justify-content-evenly p-0 m-0 bg-light border">
                                    <DeviceSignals key={item.DeviceID + "-signals"} active={item.DeviceID == currentDeviceID} deviceID={item.DeviceID} />
                                </Tab>)}
                        </Tabs> : "No devices found"}
                    <Modal show={showversioncontrolmodal} onHide={handleClose} size="lg">
                        <Modal.Header closeButton>
                            <h5 class="modal-title" id="exampleModalLongTitle">What's New?</h5>
                        </Modal.Header>
                        <Modal.Body className="text-center">
                            {/* <YouTubeVideo VideoTag="Calibration" />
                            <p className="text-start">Now you can calibrate your sensors using live data.</p>
                            <p className="text-start">Pause sensor logging during calibration.</p>
                            <p class="text-center"><img class="w-50" src="https://wwxreportgenerator.blob.core.windows.net/how-tos/how-calib-1.PNG" alt="calibhow1" /></p>
                            <p className="text-start">Expand additional window for pH calibration interface.</p>
                            <p class="text-center"><img class="w-50" src="https://wwxreportgenerator.blob.core.windows.net/how-tos/how-calib-2.PNG" alt="calibhow2" /></p> */}
                            <p><img className="w-100" src="/images/HowTos/calibrations-v4.png" alt="Document Upload" /></p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
                :
                <NoAccessPage />
            : <></>);
};


//Signal R live data streaming code
var connection = new signalR.HubConnectionBuilder().withUrl("/messageHub").build();
var listenertag;
