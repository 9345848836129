import React from 'react';
import {Alert} from 'react-bootstrap';

class StreamwiseErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    logErrorToMyService = (error) => {
        var errorPayload = {
            message: error.message,
            stack: error.stack
        }
        var url = '/api/ErrorHandlingAPI/LogError/';
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(errorPayload)
        });
    }

    componentDidCatch(error) {
      // You can also log the error to an error reporting service
      this.logErrorToMyService(error);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return( 
          <>
            <div className="d-flex justify-content-center">
              <img className="p-0" width="25%" style={{minWidth:'250px'}}src="/images/Streamwise-Corporate-Identity-Horizontal-Colour-SVG.svg" />
            </div>
            <Alert variant='danger' className="m-3 d-flex justify-content-center">
                Sorry, something went wrong! Try refreshing the page or try again later.
            </Alert>
          </>
        );
      }
  
      return this.props.children; 
    }
  }

export default StreamwiseErrorBoundary;