import React, { useEffect, useState, useContext } from "react";
import { sortableHandle } from "react-sortable-hoc";
import BarSettingsContainer from "./BarSettingsContainer";
import { Button, Spinner } from "react-bootstrap";
import InsightBarChart from "./bar-chart";
import { AuthContext } from "../Analytics";

const ConfigContext = React.createContext();
const colorPickerColours = [
  "#14a8de",
  "#a0512d",
  "#944ba3",
  "#069416",
  "#002b4f",
  "#f47920",
  "#101820",
];
const DEFAULT_EQUATION_MODE = 1;
const VALID_EQUATION_MODES = {
  1: { displayText: "Flow Rate Summation" },
  6: { displayText: "Daily Value Delta" },
};

const DragHandle = sortableHandle(() => (
  <div
    className='btn d-flex align-items-center btn-group-vertical ml-auto'
    style={{ height: "100%", border: "1px solid rgb(230 230 230)" }}
  >
    <i
      className='fal fa-angle-up'
      style={{ color: "#002c4e78", display: "block" }}
    />
    <i className='fal fa-grip-lines' style={{ display: "block" }} />
    <i
      className='fal fa-angle-down'
      style={{ color: "#002c4e78", display: "block" }}
    />
  </div>
));

const defaultBCI = {
  InsightBarChartInputID: null,
  InsightBarChartID: null,
  SignalID: null,
  OutputName: "",
  Order: 1,
  ColorID: 0,
  CorrectionFactor: 1,
  EquationMode: 1
};

function BarChart(props) {
  const [config, setConfig] = useState(null);
  const [signals, setSignals] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const [loading, setLoading] = useState(true);

  const { auth } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    var url = "/api/AnalyticsAPI/GetBarChart?id=" + props.barID;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        updateConfig(data);
        var acID = data.AccessControlID;
        var url2 =
          "/api/AuthorizationAPI/GetAccessLevel?accessControlID=" + acID;
        fetch(url2)
          .then((response) => response.json())
          .then((data) => {
            setCanEdit(data === 2);
          });
      });
  }, [props.barID]);

  const getSignals = (config) => {
    var url = "/api/LineChartAPI/GetSiteSignals/";

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(config.SiteGUID),
    })
      .then(response => response.json())
        .then(data => {
        var siteSignals = data.value;

        // Iterate through all ChartInputs to figure out which signals are configured
        var i = 0;
        siteSignals.forEach((ss) => {
          ss.isSelected = false;
          ss.selectedColour = colorPickerColours[0];
          ss.Order = 9999;
          ss.i = 0;
          i++;
        });

        var inputs = config.InsightBarChartInputs;
        if (inputs) {
          inputs.forEach((l) => {
            for (var i = 0; i < siteSignals.length; i++) {
              if (siteSignals[i].value == l.SignalID) {
                siteSignals[i].selectedColour = colorPickerColours[l.ColorID];
                siteSignals[i].isSelected = true;
                siteSignals[i].Order = l.Order;
              }
            }
          });
        }

        var orderedSignals = reorderSignals(siteSignals);
        setSignals(orderedSignals);
      });
  };

  function reorderSignals(signals) {
    signals.sort((a, b) =>
      a.Order > b.Order ? 1 : b.Order > a.Order ? -1 : 0
    );
    return signals;
  }

  function updateColour(newColour, signalID) {
    var newConfig = JSON.parse(JSON.stringify(config));
    var barChartInput = newConfig.InsightBarChartInputs.find(
      (lci) => lci.SignalID === signalID
    );
    barChartInput.ColorID = colorPickerColours.indexOf(newColour);
    setConfig(newConfig);
    getSignals(newConfig);
  }

  function updateOutputName(newOutputName, signalID) {
    var newConfig = JSON.parse(JSON.stringify(config));
    var barChartInput = newConfig.InsightBarChartInputs.find(
      (lci) => lci.SignalID === signalID
    );
    barChartInput.OutputName = newOutputName;
    setConfig(newConfig);
  }

  function updateCorrectionFactor(newCorrectionFactor, signalID) {
    var newConfig = JSON.parse(JSON.stringify(config));
    var barChartInput = newConfig.InsightBarChartInputs.find(
      (lci) => lci.SignalID === signalID
    );
    barChartInput.CorrectionFactor = newCorrectionFactor;
    setConfig(newConfig);
  }

  function updateBarChartName(newName) {
    var newConfig = JSON.parse(JSON.stringify(config));
    newConfig.BarChartName = newName;
    setConfig(newConfig);
  }

  function updateYAxisTitle(newYAxisTitle) {
    var newConfig = JSON.parse(JSON.stringify(config));
    newConfig.YAxisTitle = newYAxisTitle;
    setConfig(newConfig);
  }

  function updateEquationMode(newEquationMode) {
    const newConfig = JSON.parse(JSON.stringify(config));
    // String input, need to parse to int before sending to server
    const newEqModeInt = parseInt(newEquationMode);
    newConfig.EquationMode = VALID_EQUATION_MODES.hasOwnProperty(newEqModeInt)
      ? newEqModeInt
      : DEFAULT_EQUATION_MODE;
    setConfig(newConfig);
  }

  function updateSelectedSignals(selectedSignals) {
    var newSelectedSignals = JSON.parse(JSON.stringify(selectedSignals));
    var newConfig = JSON.parse(JSON.stringify(config));
    var BCIs = newConfig.InsightBarChartInputs;

    // TODO there is probably a more algorithmically efficient way to do everything below

    // Iterate through all of the selected signals to add any new signals to barChartInputs
    if (newSelectedSignals) {
      newSelectedSignals.forEach((nss, idx) => {
        // Iterate through all of the config signals to determine if selected signal needs to be added
        if (BCIs && BCIs.length > 0) {
          // Only check if there are existing bar chart inputs
          for (var i = 0; i < BCIs.length; i++) {
            if (BCIs[i].SignalID === nss.value) {
              // Selected signal already in list
              BCIs[i].Order = idx; // Set order to array index
              break; // don't need to check anymore
            } else if (i === BCIs.length - 1) {
              // Selected signal not in list, need to add
              var newBCI = JSON.parse(JSON.stringify(defaultBCI));
              newBCI.InsightBarChartID = newConfig.InsightBarChartID;
              newBCI.SignalID = nss.value;
              newBCI.Order = idx; // Set order to array index
              BCIs.push(newBCI);
              break;
            } else {
              // Keep looking
            }
          }
        } else {
          // Need to add the selected signal because the list is empty right now
          BCIs = [];
          var newBCI = JSON.parse(JSON.stringify(defaultBCI));
          newBCI.InsightBarChartID = newConfig.InsightBarChartID;
          newBCI.SignalID = nss.value;
          newBCI.Order = nss.Order; // Set order to array index
          BCIs.push(newBCI);
        }
      });
    }

    // Iterate through all of the existing BCIs to see if anything needs to be removed
    if (BCIs) {
      // only need to check if there are existing BCIs defined
      BCIs.forEach((ci, idx, arr) => {
        // Iterate through all of the new signals
        if (newSelectedSignals && newSelectedSignals.length > 0) {
          // Only need to try to find the BCI in selected signals if selected signals are defined
          for (var i = 0; i < newSelectedSignals.length; i++) {
            if (ci.SignalID === newSelectedSignals[i].value) {
              // Signal found in new list
              ci.Order = i; // Set order to array index
              break; // don't need to check anymore
            } else if (i === newSelectedSignals.length - 1) {
              // Signal not found, need to remove
              arr.splice(idx, 1);
              break;
            } else {
              // Keep looking
            }
          }
        } else {
          // No signals selected, so need to remove LCI
          arr.splice(idx, 1);
        }
      });
    }

    // For the list of all signals, switch the isSelected property to true for all selected signals and set the order
    var newSignals = JSON.parse(JSON.stringify(signals));
    newSignals.forEach((ns) => {
      ns.isSelected = false;
    }); // Reset all isSelected by setting to false
    if (newSelectedSignals) {
      // Only need to do this if there are any selected signals
      newSelectedSignals.forEach((nss, idx) => {
        newSignals[
          newSignals.findIndex((ns) => ns.value === nss.value)
        ].isSelected = true;
        newSignals[newSignals.findIndex((ns) => ns.value === nss.value)].Order =
          idx;
      });
    }

    // Reorder signals by Order property so they show up in the select box in the right order
    var orderedSignals = reorderSignals(newSignals);
    setSignals(orderedSignals);
    newConfig.InsightBarChartInputs = BCIs;
    setConfig(newConfig);
  }

  function updateConfig(data) {
    setConfig(data);
    getSignals(data);
    InsightBarChart.init(props.barID, props.start, props.end, () =>
      setLoading(false)
    );
  }

  //Function to download CSV file
  function DownloadCSV() {
    var d = new Date();
    var n = d.getTimezoneOffset();

    window.location =
      "/../api/InsightBarChartDownload/" +
      props.barID +
      "&" +
      encodeURIComponent(props.start) +
      "&" +
      encodeURIComponent(props.end) +
      "&" +
      n;
  }

  return (
    <ConfigContext.Provider
      value={{
        config,
        updateColour,
        updateCorrectionFactor,
        updateOutputName,
        updateBarChartName,
        updateYAxisTitle,
        updateSelectedSignals,
        updateConfig,
        updateEquationMode,
        setLoading,
      }}
    >
      <div className='card border-primary mb-3'>
        <div className='card-body p-0 d-flex'>
          {auth === 2 ? (
            <div className='btn-group-vertical ml-auto'>
              <div style={{ height: "100%", cursor: "grab" }}>
                <DragHandle />
              </div>
            </div>
          ) : (
            <></>
          )}
          <div
            id={props.barID + "-Bar"}
            class='col row'
            style={{ height: "350px" }}
          >
            {loading ? (
              <Spinner
                animation='border'
                role='status'
                className='position-absolute'
                style={{ top: "50%", left: "50%" }}
              />
            ) : (
              <></>
            )}
            <canvas
              id={props.barID + "-BarCanvas"}
              class='col'
              height={350}
              style={{ visibility: loading ? "hidden" : "visible" }}
            />
          </div>
          <div className='btn-group-vertical ml-auto'>
            {canEdit ? (
              <div
                id={"barchartsettingsroot-" + props.barID}
                style={{ height: "100%" }}
              >
                <BarSettingsContainer
                  chartModelId={props.barID}
                  ready={config}
                  signals={signals}
                  onremove={props.onremove}
                  validEquationModes={VALID_EQUATION_MODES}
                />
              </div>
            ) : (
              <></>
            )}
            <div id={"download-" + props.barID} style={{ height: "100%" }}>
              <Button
                title='Download CSV'
                onClick={() => DownloadCSV(props.barID)}
                className='btn btn-primary d-flex align-items-center'
                style={{ height: "100%" }}
              >
                <i
                  style={{ color: "rgb(255, 255, 255)", width: "16px" }}
                  className='fas fa-file-download'
                ></i>
              </Button>
            </div>
          </div>
        </div>
        <div
          id={props.barID + "-BarNoData"}
          class='mx-auto no-data-text'
          hidden
        >
          <div className='text-muted text-center'>
            <i className='fas fa-info-circle'></i>
            <div>No Data</div>
          </div>
        </div>
      </div>
    </ConfigContext.Provider>
  );
}

export { ConfigContext };
export default BarChart;
