import React, { useState } from "react";
import {
  Container,
  Button,
  FormControl,
} from "react-bootstrap";
import {
  APPLICATION_OPTIONS,
  LOCATION_NUMBER_OPTIONS,
  LOCATION_NUMBER_OPTIONS_WIRELESS,
  DEFAULT_LOCATION,
} from "./StaticData";
import { SW_NODE_LIST } from "./Nodes";

import Select from "react-select";
import { WaterApplication, SensorLocation, Sensor} from "./types";

const rearrangeLocations = (locations: SensorLocation[]) => {
    //                                   0  1  2  3  4  5  6  7 

    // 1    [0] ->                      [0] 1,-1, 2,-2, 3,-3, 4]
    // 2    [0, 1] ->                   [0, 1]-1, 2,-2, 3,-3, 4]
    // 3    [0, 1, 2] ->                [1, 2, 0] 3,-1, 4,-2, 5]
    // 4    [0, 1, 2, 3] ->             [1, 2, 0, 3]-1, 4,-2, 5]
    // 5    [0, 1, 2, 3, 4] ->          [2, 3, 1, 4, 0],5,-1, 6]
    // 6    [0, 1, 2, 3, 4, 5] ->       [2, 3, 1, 4, 0, 5]-1, 6]
    // 7    [0, 1, 2, 3, 4, 5, 6] ->    [3, 4, 2, 5, 1, 6, 0],7]
    // 8    [0, 1, 2, 3, 4, 5, 6, 7] -> [3, 4, 2, 5, 1, 6, 0, 7]

    let locationsRearranged = Array(locations.length) as SensorLocation[];
    for (var i=0; i<locations.length; i++) {
      let indexToGet = Math.floor((locations.length+1) / 2) - 1 +
      (i % 2 === 1 ? Math.floor((i+1) / 2) : -Math.floor((i+1) / 2)); 
      console.log({indexToGet, i, length: locations.length});
      locationsRearranged[i] =
        locations[indexToGet];
    }
    return locationsRearranged;
}

function DAFQuestionnaire({
  setShowQuestionnaire,
  addAirCleaning,
  addLocation,
  addSensor,
  addExpansionModule,
  triggerUpdateAllElements,
}: {
  setShowQuestionnaire: (state: boolean) => void;
  addAirCleaning: () => void;
  addLocation: (name: string) => string; 
  addSensor: (locationId: string, sensorType: string) => void;
  addExpansionModule: (expansionType: string) => void;
  triggerUpdateAllElements: () => void;
}) {
  const [locations, setLocations] = useState<SensorLocation[]>([]); // Array of locations, where each location has a name and an array of sensors (string of sensor type)
  const [analogIns, setAnalogIns] = useState<number>(); 

  const go = () => {
    let locationsRearranged = rearrangeLocations(locations);
    locationsRearranged.forEach((l, idx) => {
      // Add edge device and air cleaning
      addAirCleaning();
      // Add location
      let locationId = addLocation(l.name);
      l.sensors.forEach(s => {
        addSensor(locationId, s);
      })
    })
    for (let i=0; i<Math.ceil(analogIns!!/8); i++) {
      addExpansionModule('analogInputModule')
    }
    triggerUpdateAllElements();
    // Hide questionnaire (shows flow chart etc)
    setShowQuestionnaire(false);
  }

  let sensorOptions = SW_NODE_LIST.filter(n => n.data.moduleFamily === 'sensor').map(s => ({value: s.type, label: s.data.name}))
  console.log({sensorOptions});
  return(
    <>
      <>
        <p className="mt-4">
          3) How many sampling/sensor locations do you need?
        </p>
        <Select
          options={LOCATION_NUMBER_OPTIONS}
          placeholder="Select number of locations..."
          onChange={(e) => {
            let tempLocations = JSON.parse(JSON.stringify(locations));
            // Add new locations
            for (var i = locations.length; i < e.value; i++) {
              let newLocation = JSON.parse(
                JSON.stringify(DEFAULT_LOCATION)
              );
              tempLocations.push(newLocation);
            }
            // Remove any locations (if number of locations was dropped)
            tempLocations.splice(
              e.value - 1,
              tempLocations.length - e.value
            );
            setLocations(tempLocations);
          }}
        />
      </>
    {locations &&
      locations.map((l, idx) => (
        <>
          <p className="mt-4">
            {
              idx * 2 +
                4 /* 2 is the number of steps per location, 3 is the number of previous steps */
            }
            ) Location {idx + 1} name:
          </p>
          <FormControl
            placeholder="Location name..."
            aria-label="Location name"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              let tempLocations = JSON.parse(JSON.stringify(locations));
              tempLocations[idx].name = e.target.value;
              setLocations(tempLocations);
            }}
          />
          <p className="mt-4">
            {
              idx * 2 +
                5 /* 2 is the number of steps per location, 4 is the number of previous steps */
            }
            ) Location {idx + 1} sensors:
          </p>
          <Select
            isMulti
            options={sensorOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select sensors..."
            onChange={(e) => {
              let tempLocations = JSON.parse(JSON.stringify(locations));
              tempLocations[idx].sensors = e.map((o) => o.value);
              setLocations(tempLocations);
            }}
          />
        </>
      ))}
      {(locations.length > 0 &&
          !locations.reduce(
            (prev, curr) =>
              prev || curr.name === "" || curr.sensors.length === 0,
            false
          )) &&
        <>
          <p className="mt-4">
            {4 + locations.length*2}) How many analog inputs do you need to read?
          </p>
          <FormControl
              placeholder="Number of analog inputs..."
              aria-label="Analog inputs"
              aria-describedby="basic-addon1"
              onChange={(e) => {
                if (e.target.value < 0) {
                  setAnalogIns(0);
                } else if (e.target.value > 56)
                  setAnalogIns(56);
                else {
                  setAnalogIns(e.target.value);
                }
              }}
              value={analogIns}
              type="number"
              min={0}
              max={56} // 7 AI modules, 8 Inputs per module
          />
        </>
      }

    <div className="d-flex justify-content-center">
      <Button
        variant="primary"
        className="mt-4"
        style={{ width: "100px" }}
        onClick={go}
        disabled={
          !analogIns ||
          locations.length === 0 ||
          locations.reduce(
            (prev, curr) =>
              prev || curr.name === "" || curr.sensors.length === 0,
            false
          )
        }
      >
        Go!
      </Button>
    </div>
    </>
  )
}

function GSENSQuestionnaire({
  setShowQuestionnaire,
  addAirCleaning,
  addLocation,
  addSensor,
  triggerUpdateAllElements,
}: {
  setShowQuestionnaire: (state: boolean) => void;
  addAirCleaning: () => void;
  addLocation: (name: string) => string; 
  addSensor: (locationId: string, sensorType: string) => void;
  triggerUpdateAllElements: () => void;
}) {
  const [locations, setLocations] = useState<SensorLocation[]>([]); // Array of locations, where each location has a name and an array of sensors (string of sensor type)

  const go = () => {
    let locationsRearranged = rearrangeLocations(locations);
    locationsRearranged.forEach((l, idx) => {
      // Add edge device and air cleaning
      addAirCleaning();
      // Add location
      let locationId = addLocation(l.name);
      l.sensors.forEach(s => {
        addSensor(locationId, s);
      })
    })
    triggerUpdateAllElements();
    // Hide questionnaire (shows flow chart etc)
    setShowQuestionnaire(false);
  }

  let sensorOptions = SW_NODE_LIST.filter(n => n.data.moduleFamily === 'sensor').map(s => ({value: s.type, label: s.data.name}))
  console.log({sensorOptions});
  return(
    <>
      <>
        <p className="mt-4">
          3) How many sampling/sensor locations do you need?
        </p>
        <Select
          options={LOCATION_NUMBER_OPTIONS}
          placeholder="Select number of locations..."
          onChange={(e) => {
            let tempLocations = JSON.parse(JSON.stringify(locations));
            // Add new locations
            for (var i = locations.length; i < e.value; i++) {
              let newLocation = JSON.parse(
                JSON.stringify(DEFAULT_LOCATION)
              );
              tempLocations.push(newLocation);
            }
            // Remove any locations (if number of locations was dropped)
            tempLocations.splice(
              e.value - 1,
              tempLocations.length - e.value
            );
            setLocations(tempLocations);
          }}
        />
      </>
    {locations &&
      locations.map((l, idx) => (
        <>
          <p className="mt-4">
            {
              idx * 2 +
                4 /* 2 is the number of steps per location, 3 is the number of previous steps */
            }
            ) Location {idx + 1} name:
          </p>
          <FormControl
            placeholder="Location name..."
            aria-label="Location name"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              let tempLocations = JSON.parse(JSON.stringify(locations));
              tempLocations[idx].name = e.target.value;
              setLocations(tempLocations);
            }}
          />
          <p className="mt-4">
            {
              idx * 2 +
                5 /* 2 is the number of steps per location, 4 is the number of previous steps */
            }
            ) Location {idx + 1} sensors:
          </p>
          <Select
            isMulti
            options={sensorOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select sensors..."
            onChange={(e) => {
              let tempLocations = JSON.parse(JSON.stringify(locations));
              tempLocations[idx].sensors = e.map((o) => o.value);
              setLocations(tempLocations);
            }}
          />
        </>
      ))}

    <div className="d-flex justify-content-center">
      <Button
        variant="primary"
        className="mt-4"
        style={{ width: "100px" }}
        onClick={go}
        disabled={
          locations.length === 0 ||
          locations.reduce(
            (prev, curr) =>
              prev || curr.name === "" || curr.sensors.length === 0,
            false
          )
        }
      >
        Go!
      </Button>
    </div>
    </>
  )
}

function WSENSQuestionnaire({
  setShowQuestionnaire,
  addLocation,
  addWirelessSensor,
  addExpansionModule,
  triggerUpdateAllElements,
}: {
  setShowQuestionnaire: (state: boolean) => void;
  addLocation: (name: string) => string; 
  addWirelessSensor: (locationId: string, sensorType: string) => void;
  addExpansionModule: (expansionType: string) => void;
  triggerUpdateAllElements: () => void;
}) {
  const [locations, setLocations] = useState<SensorLocation[]>([]); // Array of locations, where each location has a name and an array of sensors (string of sensor type)

  const go = () => {
    // Add wireless module
    addExpansionModule('wirelessModule');
    let locationsRearranged = rearrangeLocations(locations);
    locationsRearranged.forEach((l, idx) => {
      // Add location
      let locationId = addLocation(l.name);
      l.sensors.forEach(s => {
        addWirelessSensor(locationId, s);
      })
    })
    triggerUpdateAllElements();
    // Hide questionnaire (shows flow chart etc)
    setShowQuestionnaire(false);
  }

  let sensorOptions = SW_NODE_LIST.filter(n => n.data.moduleFamily === 'wirelessSensor').map(s => ({value: s.type, label: s.data.name}))
  return(
    <>
      <>
        <p className="mt-4">
          3) How many sampling/sensor locations do you need?
        </p>
        <Select
          options={LOCATION_NUMBER_OPTIONS_WIRELESS}
          placeholder="Select number of locations..."
          onChange={(e) => {
            let tempLocations = JSON.parse(JSON.stringify(locations));
            // Add new locations
            for (var i = locations.length; i < e.value; i++) {
              let newLocation = JSON.parse(
                JSON.stringify(DEFAULT_LOCATION)
              );
              tempLocations.push(newLocation);
            }
            // Remove any locations (if number of locations was dropped)
            tempLocations.splice(
              e.value - 1,
              tempLocations.length - e.value
            );
            setLocations(tempLocations);
          }}
        />
      </>
    {locations &&
      locations.map((l, idx) => (
        <>
          <p className="mt-4">
            {
              idx * 2 +
                4 /* 2 is the number of steps per location, 3 is the number of previous steps */
            }
            ) Location {idx + 1} name:
          </p>
          <FormControl
            placeholder="Location name..."
            aria-label="Location name"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              let tempLocations = JSON.parse(JSON.stringify(locations));
              tempLocations[idx].name = e.target.value;
              setLocations(tempLocations);
            }}
          />
          <p className="mt-4">
            {
              idx * 2 +
                5 /* 2 is the number of steps per location, 4 is the number of previous steps */
            }
            ) Location {idx + 1} sensors:
          </p>
          <Select
            isMulti
            options={sensorOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select sensors..."
            onChange={(e) => {
              let tempLocations = JSON.parse(JSON.stringify(locations));
              tempLocations[idx].sensors = e.map((o) => o.value);
              setLocations(tempLocations);
            }}
          />
        </>
      ))}

    <div className="d-flex justify-content-center">
      <Button
        variant="primary"
        className="mt-4"
        style={{ width: "100px" }}
        onClick={go}
        disabled={
          locations.length === 0 ||
          locations.reduce(
            (prev, curr) =>
              prev || curr.name === "" || curr.sensors.length === 0,
            false
          )
        }
      >
        Go!
      </Button>
    </div>
    </>
  )
}

function AIMSQuestionnaire({
  setShowQuestionnaire,
  addLocation,
  addWirelessSensor,
  addExpansionModule,
  triggerUpdateAllElements,
}: {
  setShowQuestionnaire: (state: boolean) => void;
  addLocation: (name: string) => string; 
  addWirelessSensor: (locationId: string, sensorType: string) => void;
  addExpansionModule: (expansionType: string) => void;
  triggerUpdateAllElements: () => void;
}) {
  const [locations, setLocations] = useState<SensorLocation[]>([]); // Array of locations, where each location has a name and an array of sensors (string of sensor type)

  const go = () => {
    // Add edge device and air cleaning
    addExpansionModule('wirelessModule');
    let locationsRearranged = rearrangeLocations(locations);
    locationsRearranged.forEach((l, idx) => {
      // Add location
      let locationId = addLocation(l.name);
      addWirelessSensor(locationId, 'wirelessLevelSensor');
    })
    triggerUpdateAllElements();
    // Hide questionnaire (shows flow chart etc)
    setShowQuestionnaire(false);
  }

  return(
    <>
      <>
        <p className="mt-4">
          3) How many chemical tanks do you need to monitor?
        </p>
        <Select
          options={LOCATION_NUMBER_OPTIONS_WIRELESS}
          placeholder="Select number of chemical tanks..."
          onChange={(e) => {
            let tempLocations = JSON.parse(JSON.stringify(locations));
            // Add new locations
            for (var i = locations.length; i < e.value; i++) {
              let newLocation = JSON.parse(
                JSON.stringify(DEFAULT_LOCATION)
              );
              tempLocations.push(newLocation);
            }
            // Remove any locations (if number of locations was dropped)
            tempLocations.splice(
              e.value - 1,
              tempLocations.length - e.value
            );
            setLocations(tempLocations);
          }}
        />
      </>
    {locations &&
      locations.map((l, idx) => (
        <>
          <p className="mt-4">
            {
              idx + 4 /* 3 is the number of previous steps */
            }
            ) Tank {idx + 1} name:
          </p>
          <FormControl
            placeholder="Location name..."
            aria-label="Location name"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              let tempLocations = JSON.parse(JSON.stringify(locations));
              tempLocations[idx].name = e.target.value;
              setLocations(tempLocations);
            }}
          />
        </>
      ))}

    <div className="d-flex justify-content-center">
      <Button
        variant="primary"
        className="mt-4"
        style={{ width: "100px" }}
        onClick={go}
        disabled={
          locations.length === 0 ||
          locations.reduce(
            (prev, curr) =>
              prev || curr.name === "",
            false
          )
        }
      >
        Go!
      </Button>
    </div>
    </>
  )
}

function TWGMQuestionnaire({
  setShowQuestionnaire,
  addExpansionModule,
  triggerUpdateAllElements,
}: {
  setShowQuestionnaire: (state: boolean) => void;
  addExpansionModule: (expansionType: string) => void;
  triggerUpdateAllElements: () => void;
}) {
  const [analogIns, setAnalogIns] = useState<number>(0); 

  const go = () => {
    for (let i=0; i<Math.ceil(analogIns/8); i++) {
      addExpansionModule('analogInputModule')
    }
    triggerUpdateAllElements();
    // Hide questionnaire (shows flow chart etc)
    setShowQuestionnaire(false);
  }

  return(
    <>
      <>
        <p className="mt-4">
          3) How many analog inputs do you need to read?
        </p>
        <FormControl
            placeholder="Number of analog inputs..."
            aria-label="Analog inputs"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              if (e.target.value < 0) {
                setAnalogIns(0);
              } else if (e.target.value > 56)
                setAnalogIns(56);
              else {
                setAnalogIns(e.target.value);
              }
            }}
            value={analogIns}
            type="number"
            min={0}
            max={56} // 7 AI modules, 8 Inputs per module
        />
      </>

      <div className="d-flex justify-content-center">
        <Button
          variant="primary"
          className="mt-4"
          style={{ width: "100px" }}
          onClick={go}
          disabled={analogIns === 0}
        >
          Go!
        </Button>
      </div>
    </>
  )
}

export function Questionnaire({
  setShowQuestionnaire,
  application,
  setApplication,
  name,
  setName,
  addAirCleaning,
  addLocation,
  addSensor,
  addWirelessSensor,
  addExpansionModule,
  triggerUpdateAllElements,
}: {
  setShowQuestionnaire: (show: boolean) => void;
  application: string;
  setApplication: (application: string) => void;
  name: string;
  setName: (name: string) => void;
  addAirCleaning: () => void;
  addLocation: (name: string) => string; 
  addSensor: (locationId: string, sensorType: string) => void;
  addWirelessSensor: (locationId: string, sensorType: string) => void;
  addExpansionModule: (expansionType: string) => void;
  triggerUpdateAllElements: () => void;
}) {
  return (
    <div
      style={{
        minHeight: "80vh",
        borderColor: "black",
        marginTop: "50px",
        borderWidth: "1px",
      }}
    >
      <div
        className="jumbotron"
        style={{
          backgroundColor: "#6c757d0f",
          borderRadius: "12px",
          padding: '50px',
        }}
      >
        <Container>
          <h1>Hardware configurator</h1>
          <h4>Answer the following questions to get started.</h4>
          <p className="mt-4">1) What is your application?</p>
          <Select
            options={APPLICATION_OPTIONS}
            onChange={(e) => setApplication(e.value)}
            placeholder="Select application..."
          />
          <p className="mt-4">2) Give your new configuration a name: </p>
          <FormControl
            placeholder="Configuration name..."
            aria-label="Configuration name"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          {application && name && name !== '' && application === "DAF" && (
            <DAFQuestionnaire
              setShowQuestionnaire={setShowQuestionnaire}
              addAirCleaning={addAirCleaning}
              addLocation={addLocation}
              addSensor={addSensor}
              addExpansionModule={addExpansionModule}
              triggerUpdateAllElements={triggerUpdateAllElements}
            />
          )}
          {application && name && name !== '' && application === "G_SENS" && (
            <GSENSQuestionnaire
              setShowQuestionnaire={setShowQuestionnaire}
              addAirCleaning={addAirCleaning}
              addLocation={addLocation}
              addSensor={addSensor}
              triggerUpdateAllElements={triggerUpdateAllElements}
            />
          )}
          {application && name && name !== '' && application === "W_SENS" && (
            <WSENSQuestionnaire
              setShowQuestionnaire={setShowQuestionnaire}
              addLocation={addLocation}
              addWirelessSensor={addWirelessSensor}
              addExpansionModule={addExpansionModule}
              triggerUpdateAllElements={triggerUpdateAllElements}
            />
          )}
          {application && name && name !== '' && application === "AIMS" && (
            <AIMSQuestionnaire
              setShowQuestionnaire={setShowQuestionnaire}
              addLocation={addLocation}
              addWirelessSensor={addWirelessSensor}
              addExpansionModule={addExpansionModule}
              triggerUpdateAllElements={triggerUpdateAllElements}
            />
          )}
          {application && name && name !== '' && application === "TW_GM" && (
            <TWGMQuestionnaire
              setShowQuestionnaire={setShowQuestionnaire}
              addExpansionModule={addExpansionModule}
              triggerUpdateAllElements={triggerUpdateAllElements}
            />
          )}
        </Container>
      </div>
    </div>
  );
}


