import { useEffect, useState } from 'react';
import {
    Button,
    Modal,
    Table,
    Form,
    Fade,
    Container,
    Row,
    Col,
    Spinner,
} from "react-bootstrap";

function PermissionsModal(props) {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Get roles for this site and their existing privileges
        // Access levels
        // 2 -> Edit
        // 1 -> view
        // 0 -> none
        if (props.show) {
            setLoading(true);
            fetch('api/AuthorizationAPI/GetRoleClaims?' + new URLSearchParams({
                siteGUID: props.siteGUID,
                accessControlID: props.accessControlID
            }))
                .then(response => response.json())
                .then(data => {
                    setRoles(data);
                    setLoading(false);
                });
        }
        console.log(props)
    }, [props.show])

    const handleSave = (newRoles) => {
        var url = 'api/AuthorizationAPI/AddClaimtoRoles/';
        var payload = { siteGUID: props.siteGUID, accessControlID: props.accessControlID, claims: newRoles };

        setLoading(true);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        }).then(response => {
            if (response.ok) {
                console.log("Claims added successfully");
            }
            props.onHide();
        })
    }

    const changePermission = (e, Id, level) => {
        var newRoles = JSON.parse(JSON.stringify(roles))
        if (e.target.checked) {
            newRoles.find(r => r.Role.Id == Id).AccessLevel = level;
        } else {
            newRoles.find(r => r.Role.Id == Id).AccessLevel = 0;
        }
        setRoles(newRoles);
    }

    return (
        <Modal {...props} centered size="lg" onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Permissions settings</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ minHeight: '100px' }}>
                {!loading ?
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Role Name</th>
                                <th>View</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {roles.map(r =>
                            (r && (r.Role.IsHidden === false || (r.Role.IsHidden === true && props.isStreamwise)) &&
                                    <tr key={'roles-' + r.Role.Id}>
                                        <td>{r.Role.ClassificationName}</td>
                                        <td>
                                            <Form.Check aria-label="View Permission" checked={r.AccessLevel == 1} onChange={e => changePermission(e, r.Role.Id, 1)} />
                                        </td>
                                        <td>
                                            <Form.Check aria-label="Edit Permission" checked={r.AccessLevel == 2} onChange={e => changePermission(e, r.Role.Id, 2)} />
                                        </td>
                                    </tr>
                            ))
                                }
                        </tbody>
                    </Table>
                    :
                    <Spinner
                        animation="border"
                        role="status"
                        className="position-absolute"
                        style={{
                            top: "calc(25% + 1rem)",
                            left: "calc(50% - 1rem)",
                        }}
                    />
                }
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col>
                            {!loading && <Button className="float-right" variant="success" onClick={() => handleSave(roles)}>Confirm</Button>}
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}


function PermissionsSettings(props) {
    const [showPermissionsSettings, setShowPermissionsSettings] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [streamwise, setStreamwise] = useState(false);

    useEffect(() => {
        fetch("/api/AuthorizationAPI/CheckIfStreamwise/")
            .then(response => response.json())
            .then(data => setStreamwise(data));

        fetch("/api/AuthorizationAPI/CheckIfAdmin?siteGUID=" + props.siteGUID)
            .then(response => response.json())
            .then(data => setAdmin(data));
    }, [])

    return (admin ?
        <>
            <Fade appear={true} in={admin}>
                <Button variant="primary" size="sm" style={{ float: 'right' }} onClick={() => setShowPermissionsSettings(true)}>
                    Permissions <i className="fas fa-key"></i>
                </Button>
            </Fade>
            <PermissionsModal {...props}
                show={showPermissionsSettings}
                onHide={() => setShowPermissionsSettings(false)}
                isStreamwise={streamwise} />
        </>: <></>
    )
}

export default PermissionsSettings;

