'use strict';
import $ from 'jquery';
import StreamwiseTrendChart from '../LineCharts/waterwerx-trend-chart';

const InsightChart = (function () {
    const createChartModel = function (data) {
        var chartModel = data.chartDataPoints.map(function (t) {
            return {
                t: new Date(t.t),
                s: t.s,
                c: t.c
            };
        });

        chartModel.inputType = data.inputType;
        chartModel.signalNiceName = data.signalNiceName;
        chartModel.weightedAverage = data.weightedAverage;
        chartModel.colorID = data.colorID;
        chartModel.axisSide = data.axisSide;

        return chartModel;
    };

    const createChartConfig = function (data) {
        console.log(data);
        const chartID = data.ChartID;
        // var chartFeedFlowDataset = {
        //     yAxisID: 'dafFeedFlowAxis',
        //     label: '',
        //     data: [],
        //     type: 'line',
        //     pointRadius: 0,
        //     pointHoverRadius: 0,
        //     steppedLine: 'middle',
        //     backgroundColor: 'rgba(124, 210, 233, 0.3)',
        //     borderColor: 'rgba(0, 0, 0, 0)',
        //     fill: true
        // };
        // var filterSignalNiceName = '';

        var labels = [];
        var chartDatasets = [];
        const bgcolorsarray = ['rgba(124, 210, 233, 0.3)', 'rgba(160,82,45, 0.3)', 'rgba(148, 75, 163, 0.3)', 'rgba(6, 148, 22, 0.3)', 'rgba(0, 44, 79, 0.3)', 'rgba(244, 121, 32, 0.3)', 'rgba(16, 24, 32, 0.3)'];
        const colorsarray = ['rgba(20, 168, 222, 0.8)', 'rgba(160,82,45, 0.8)', 'rgba(148, 75, 163, 0.8)', 'rgba(6, 148, 22, 0.8)', 'rgba(0, 44, 79, 0.8)', 'rgba(244, 121, 32, 0.8)', 'rgba(16, 24, 32, 0.8)'];
        
        //6, 148, 22
        var averageslist = document.getElementById(chartID + '-Averages');
        if (averageslist !== null){
            averageslist.innerHTML = "";
        }

        for (let i = 0; i < data.chartLines.length; i++) {
            const chartModel = createChartModel(data.chartLines[i]);

            labels = chartModel.map(function (e) {
                return e.t;
            });

            const plotData = chartModel.map(function (e) {
                return e.s;
            });

            const inCompliance = chartModel.map(function (e) {
                return e.c
            })

            var upperLimit = data.chartLines[i].UpperValue;
            var lowerLimit = data.chartLines[i].LowerValue;
            var reference = data.chartLines[i].Reference;

            if (averageslist !== null){
                if (data.chartLines[i].inputType == 1) {
                    var div = document.createElement("div");   // Create a <div> element
                    div.className = "p-1 d-flex justify-content-center";
                    div.style.color = colorsarray[chartModel.colorID];
                    div.style.fontWeight = 'bold';
                    div.innerHTML = data.chartLines[i].weightedAverage;
                    averageslist.appendChild(div);
                }
            }

            ///////////////////////////////////////////////

            var yAxisID = 'left';
            if (chartModel.axisSide == 1){ // Assign to right axis
                yAxisID = 'right';
            } else { // Assign to left axis
                yAxisID = 'left';
            }

            const signalNiceName = chartModel.signalNiceName;

            var PointColors = [];
            var k;
            for (k = 0; k < plotData.length; k++) {
                PointColors.push(colorsarray[chartModel.colorID]);
            }

            if (chartModel.inputType == 1){ // Line Chart
                chartDatasets.push({
                    yAxisID: yAxisID,
                    label: signalNiceName,
                    data: plotData,
                    pointRadius: inCompliance.map(c => (c ? 1 : 3)),
                    pointHoverRadius: 1.2,
                    pointHitRadius: 5,
                    fill: false,
                    borderWidth: 1,
                    backgroundColor: bgcolorsarray[chartModel.colorID],
                    borderColor: colorsarray[chartModel.colorID],
                    pointBorderColor: PointColors,
                    weightedAverage: chartModel.weightedAverage,
                    pointBorderColor: inCompliance.map(c => (c ? colorsarray[chartModel.colorID] : "rgba(255, 0, 0, 1)")),
                    pointStyle: inCompliance.map(c => (c ? 'circle' : 'crossRot')),
                    pointBorderWidth: 0.5,
                });
            } else { // Backdrop
                chartDatasets.push({
                    yAxisID: yAxisID,
                    label: signalNiceName,
                    data: plotData,
                    type: 'line',
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    steppedLine: 'middle',
                    backgroundColor: bgcolorsarray[chartModel.colorID],
                    borderColor: 'rgba(0, 0, 0, 0)',
                    fill: true
                });
            }

            if (upperLimit != null) {
                var upperPlotData = [];
                var j;
                for (j = 0; j < plotData.length; j++) {
                    upperPlotData.push(upperLimit);
                }
                chartDatasets.push({
                    yAxisID: yAxisID,
                    label: signalNiceName + ' Upper Limit',
                    data: upperPlotData,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    fill: false,
                    borderWidth: 1,
                    backgroundColor: bgcolorsarray[chartModel.colorID],
                    borderColor: colorsarray[chartModel.colorID],
                    weightedAverage: chartModel.weightedAverage,
                    borderDash: [5, 3]
                });
            }

            if (lowerLimit != null) {
                var lowerPlotData = [];
                var j;
                for (j = 0; j < plotData.length; j++) {
                    lowerPlotData.push(lowerLimit);
                }

                chartDatasets.push({
                    yAxisID: yAxisID,
                    label: signalNiceName + ' Lower Limit',
                    data: lowerPlotData,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    fill: false,
                    borderWidth: 1,
                    backgroundColor: bgcolorsarray[chartModel.colorID],
                    borderColor: colorsarray[chartModel.colorID],
                    weightedAverage: chartModel.weightedAverage,
                    borderDash: [5, 3]
                });
            }

            if (reference != null) {
                var referencePlotData = [];
                var j;
                for (j = 0; j < plotData.length; j++) {
                    referencePlotData.push(reference);
                }

                chartDatasets.push({
                    yAxisID: yAxisID,
                    label: signalNiceName + ' Setpoint',
                    data: referencePlotData,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    fill: false,
                    borderWidth: 1,
                    backgroundColor: bgcolorsarray[chartModel.colorID],
                    borderColor: colorsarray[chartModel.colorID],
                    weightedAverage: chartModel.weightedAverage,
                    borderDash: [1, 5]
                });
            }
            ///////////////////////////////////////////////
        }
        
        const chartConfig = {
            chartId: chartID,
            chartLabels: labels,
            chartDataSets: chartDatasets,
            chartYAxisIdOverride: data.YAxisTitle,
            chartRightYAxisIdOverride: data.RightYAxisTitle,
            annotations: data.Annotations,
            ChartIDs: data.ChartIDs
        };
        return chartConfig;
    };

    const initChart = function (InsightChartID, start, end, onLoad) {
        $.ajax({
            url: '/../api/LineChartAPI/GetAnalyticsChartData/',
            type: 'get',
            data: {LineChartID: InsightChartID, StartTime_str: start, EndTime_str: end},
            success: function (response) {
                var canvasElement = document.getElementById(InsightChartID + '-Chart')
                if (canvasElement !== null){
                    const ctx = canvasElement.getContext('2d');
                    ctx.canvas.className="col" // add back column styles
                }
                response = JSON.parse(response);
                console.log(response);
                const noData = !response || response.chartLines.length === 0;
                const chartConfig = createChartConfig(response);

                StreamwiseTrendChart.create(chartConfig, noData);
                onLoad();
            }
        });
    };

    return {
        init: initChart
    };
}());

export default InsightChart;