import { useState, useContext } from 'react';
import { OverlayTrigger, Popover, Modal, Button } from 'react-bootstrap';
import ChartSettings from "./ChartSettings";
import PermissionsSettings from "../PermissionsSettings";
import { ConfigContext } from './LineChart';
import YouTubeVideo from '../YouTubeVideo';

function ChartSettingsContainer(props) {
    const [showSettings, setShowSettings] = useState(false);
    const [showHelpmodal, setHelpModal] = useState(false)
    const { config } = useContext(ConfigContext)

    function handleClose() {
        setHelpModal(false)
    }
    return (
        props.ready ?
            <>
                <OverlayTrigger
                    trigger="click"
                    placement="left"
                    show={showSettings}
                    overlay={
                        <Popover style={{ "maxWidth": "85vw", zIndex: 1055 }}>
                            <Popover.Header as="h3" style={{ minHeight: '50px' }}>
                                <span>
                                    Chart Settings
                                </span>
                                <Button variant="secondary" size="sm" className="ms-2" style={{ float: 'right' }} onClick={() => setHelpModal(true)}>
                                    Help <i className="fas fa-question-circle"></i>
                                </Button>
                                <PermissionsSettings accessControlID={config.AccessControlID} siteGUID={config.SiteGUID} />
                            </Popover.Header>
                            <Popover.Body>
                                <div style={{
                                    minHeight: "150px",
                                    display: "flex",
                                    "justifyContent": "center",
                                    "alignItems": "center",
                                    "flexDirection": "column"
                                }}>
                                    <ChartSettings {...props} hideSettings={() => setShowSettings(false)} />
                                </div>
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <Button
                        title="Chart Settings"
                        onClick={() => setShowSettings(!showSettings)}
                        className="btn btn-primary d-flex align-items-center"
                        style={{ height: "100%", maxWidth: '42px' }}
                    >
                        <i style={{ color: "rgb(255, 255, 255)" }} className="fal fa-cog"></i>
                    </Button>
                </OverlayTrigger>
                <Modal show={showHelpmodal} onHide={handleClose} size="lg" centered>
                    <Modal.Header closeButton>
                        <h5 className="modal-title" id="exampleModalLongTitle">Chart Settings</h5>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <YouTubeVideo VideoTag="LiveCharts" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            :
            <></>
    );
}

export default ChartSettingsContainer;