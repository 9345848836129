import { useEffect, useState } from 'react';
import { Collapse, Table, Form, Spinner, Row, Col } from 'react-bootstrap';

const RecipientModal = ({ show, siteGUID }) => {
    const [loading, setLoading] = useState(true);
    const [recipients, setRecipients] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (show) {
            fetch('api/ReportsAPI/GetRecipients/?siteGUID=' + siteGUID)
                .then(payload => payload.json())
                .then(res => {
                    console.log(res);
                    setRecipients(res.recipients);
                    setIsAdmin(res.isAdmin);
                    setLoading(false);
                });
        }
    }, [show])

    const _handleWeeklyReport = (userID, receiveTrue) => {
        // New state of recipients
        const index = recipients.findIndex(item => item.UserID === userID);
        let newRecipients = [...recipients];
        newRecipients[index] = { ...recipients[index], ReceiveWeeklyReport: receiveTrue }

        // Check for last user being removed
        const numReceives = newRecipients
            .filter(user => user.ReceiveWeeklyReport === true)
            .length;

        if (numReceives === 0) {
            alert("You are removing the LAST person from the mailing list. No one will receive emails.")
        }
            setRecipientsPOST(newRecipients, index);
    }

    const _handleDailyDatalogs = (userID, receiveTrue) => {
        // New state of recipients
        const index = recipients.findIndex(item => item.UserID === userID);
        let newRecipients = [...recipients];
        newRecipients[index] = { ...recipients[index], ReceiveDailyDataLog: receiveTrue }
        console.log(newRecipients);

        // Check for last user being removed
        const numReceives = newRecipients
            .filter(user => user.ReceiveDailyDataLog === true)
            .length;

        if (numReceives === 0) {
            alert("You are removing the last person from the mailing list. At least one person should receive emails.")
        }
        setRecipientsPOST(newRecipients, index);
    }

    const setRecipientsPOST = (newState, index) => {
        fetch('api/ReportsAPI/SetRecipients/', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newState[index])
        }).then(res => {
            if (res.ok) {
                console.log('setting new state')
                setRecipients(newState);
            }
        });
    }

    return (
        <Collapse in={show}>
            <div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Receives Weekly Reports</th>
                            <th>Receives Daily Data Logs</th>
                        </tr>
                    </thead>

                    <tbody>
                        {recipients.length > 0 || !loading ?
                            recipients.map(user =>
                                <tr key={user.UserID + "-entry"}>
                                    <td>{user.Name}</td>
                                    <td><Form.Check 
                                        disabled={!isAdmin}
                                        checked={user.ReceiveWeeklyReport}
                                        onChange={e => _handleWeeklyReport(user.UserID, e.target.checked)} /></td>
                                    <td><Form.Check
                                        disabled={!isAdmin}
                                        checked={user.ReceiveDailyDataLog}
                                        onChange={e => _handleDailyDatalogs(user.UserID, e.target.checked)} /></td>
                                </tr>)
                            : <tr><td style={{ textAlign: 'center' }} colSpan={3}><Spinner animation="border" /></td></tr>
                        }
                    </tbody>
                </Table>
            </div>
        </Collapse>
        );
}

export default RecipientModal;