import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { Accordion, Card, Button, Modal, Table, Form, Container, Row, Col } from 'react-bootstrap'

function RolePermissionsModal(props) {
    const [role, setRole] = useState({});
    const [currentUserPermissions, setCurrentUserPermissions] = useState([])


    useEffect(() => {
        // Get roles for this site and their existing privileges
        // Access levels
        // 2 -> Edit
        // 1 -> view
        // 0 -> none
        setRole(props.role);
        $.ajax({
            url: '/api/AuthorizationAPI/GetPermissions/',
            type: 'get',
            data: {
                siteGUID: props.role.SiteGUID
            },
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
                setCurrentUserPermissions(response);
            }.bind(this)
        });


    }, [])

    const handleSave = () => {
        $.ajax({
            url: '/api/RoleManagementAPI/SavePermissions/',
            type: 'post',
            data: JSON.stringify({
                ClassificationName: role.ClassificationName,
                Id: role.Id,
                DisplayHomeTab: role.DisplayHomeTab,
                DisplayAnalyticsTab: role.DisplayAnalyticsTab,
                DisplayReportsTab: role.DisplayReportsTab,
                DisplayBillingTab: role.DisplayBillingTab,
                DisplayCostBreakdownTab: role.DisplayCostBreakdownTab,
                DisplayDeepDiveTab: role.DisplayDeepDiveTab,
                DisplayEdgeTabs: role.DisplayEdgeTabs,
                DisplayControlChainsTab: role.DisplayControlChainsTab,
                DisplayHealthSummaryTab: role.DisplayHealthSummaryTab,
                DisplaySiteDetailsTab: role.DisplaySiteDetailsTab,
                DisplayAlarmsTab: role.DisplayAlarmsTab,
                SignalSetup: role.SignalSetup,
                MetricHistory: role.MetricHistory,
                ComplianceDetails: role.ComplianceDetails,
                ReceivesWeeklyReports: role.ReceivesWeeklyReports,
                ReceivesDailyDataLogs: role.ReceivesDailyDataLogs
            }),
            contentType: "application/json; charset=utf-8",
            success: function () {

            }.bind(this)
        });
    }

    const changePermissionForMetricHistory = (e, level) => {
        var newRole = JSON.parse(JSON.stringify(role));
        if (e.target.checked) {
            newRole.MetricHistory = level;
        } else {
            newRole.MetricHistory = 0;
        }
        setRole(newRole);
    }

    const changePermissionForComplianceDetails = (e, level) => {
        var newRole = JSON.parse(JSON.stringify(role));
        if (e.target.checked) {
            newRole.ComplianceDetails = level;
        } else {
            newRole.ComplianceDetails = 0;
        }
        setRole(newRole);
    }

    const changePermissionForSignalSetup = (e, level) => {
        var newRole = JSON.parse(JSON.stringify(role));
        if (e.target.checked) {
            newRole.SignalSetup = level;
        } else {
            newRole.SignalSetup = 0;
        }
        setRole(newRole);
    }

    const changePermissionForHomeTab = (e, level) => {
        var newRole = JSON.parse(JSON.stringify(role));
        if (e.target.checked) {
            newRole.DisplayHomeTab = level;
        } else {
            newRole.DisplayHomeTab = 0;
        }
        setRole(newRole);
    }

    const changePermissionForAnalyticsTab = (e, level) => {
        var newRole = JSON.parse(JSON.stringify(role))
        if (e.target.checked) {
            newRole.DisplayAnalyticsTab = level;
        } else {
            newRole.DisplayAnalyticsTab = 0;
        }
        setRole(newRole);
    }

    const changePermissionForReportsTab = (e, level) => {
        var newRole = JSON.parse(JSON.stringify(role));
        if (e.target.checked) {
            newRole.DisplayReportsTab = level;
        } else {
            newRole.DisplayReportsTab = 0;
        }
        setRole(newRole);
    }

    const changePermissionForAlarmsTab = (e, level) => {
        var newRole = JSON.parse(JSON.stringify(role))
        if (e.target.checked) {
            newRole.DisplayAlarmsTab = level;
        } else {
            newRole.DisplayAlarmsTab = 0;
        }
        setRole(newRole);
    }

    const changePermissionForHealthSummaryTab = (e, level) => {
        var newRole = JSON.parse(JSON.stringify(role))
        if (e.target.checked) {
            newRole.DisplayHealthSummaryTab = level;
        } else {
            newRole.DisplayHealthSummaryTab = 0;
        }
        setRole(newRole);
    }

    const changePermissionForCostBreakdownTab = (e, level) => {
        var newRole = JSON.parse(JSON.stringify(role))
        if (e.target.checked) {
            newRole.DisplayCostBreakdownTab = level;
        } else {
            newRole.DisplayCostBreakdownTab = 0;
        }
        setRole(newRole);
    }

    const changePermissionForDeepDiveTab = (e, level) => {
        var newRole = JSON.parse(JSON.stringify(role))
        if (e.target.checked) {
            newRole.DisplayDeepDiveTab = level;
        } else {
            newRole.DisplayDeepDiveTab = 0;
        }
        setRole(newRole);
    }

    const changePermissionForBillingTab = (e, level) => {
        var newRole = JSON.parse(JSON.stringify(role))
        if (e.target.checked) {
            newRole.DisplayBillingTab = level;
        } else {
            newRole.DisplayBillingTab = 0;
        }
        setRole(newRole);
    }

    const changePermissionForSiteDetailsTab = (e, level) => {
        var newRole = JSON.parse(JSON.stringify(role))
        if (e.target.checked) {
            newRole.DisplaySiteDetailsTab = level;
        } else {
            newRole.DisplaySiteDetailsTab = 0;
        }
        setRole(newRole);
    }

    const changePermissionForEdgeTabs = (e, level) => {
        var newRole = JSON.parse(JSON.stringify(role))
        if (e.target.checked) {
            newRole.DisplayEdgeTabs = level;
        } else {
            newRole.DisplayEdgeTabs = 0;
        }
        setRole(newRole);
    }

    const changePermissionForControlChains = (e, level) => {
        var newRole = JSON.parse(JSON.stringify(role))
        if (e.target.checked) {
            newRole.DisplayControlChainsTab = level;
        } else {
            newRole.DisplayControlChainsTab = 0;
        }
        setRole(newRole);
    }

    // Spec permissions

    const changeWeeklyReportPermission = (e, level) => {
        var newRole = JSON.parse(JSON.stringify(role))
        console.log(newRole);
        if (e.target.checked) {
            newRole.ReceivesWeeklyReports = level;
        } else {
            newRole.ReceivesWeeklyReports = 0;
        }
        setRole(newRole);
    }
    const changeDailyDataLogPermission = (e, level) => {
        var newRole = JSON.parse(JSON.stringify(role))
        if (e.target.checked) {
            newRole.ReceivesDailyDataLogs = level;
        } else {
            newRole.ReceivesDailyDataLogs = 0;
        }
        setRole(newRole);
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            aria-labelledby="contained-modal-title-vcenter"

            centered
            backdrop="static"
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Role Permissions
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item className="m-2" eventKey="0">
                        <Accordion.Header>
                            Tab Permissions
                        </Accordion.Header>
                        <Accordion.Body>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>View</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Home Tab
                                        </td>
                                        {currentUserPermissions.DisplayHomeTab > 0 ?
                                            <td>
                                                <Form.Check aria-label="View Permission" checked={role.DisplayHomeTab == 1} onChange={e => changePermissionForHomeTab(e, 1)} />
                                            </td> : <td></td>}
                                        {currentUserPermissions.DisplayHomeTab > 1 ?
                                            <td>
                                                <Form.Check aria-label="Edit Permission" checked={role.DisplayHomeTab == 2} onChange={e => changePermissionForHomeTab(e, 2)} />
                                            </td> : <td></td>}
                                    </tr>
                                    <tr>
                                        <td>
                                            Analytics Tab
                                        </td>
                                        {currentUserPermissions.DisplayAnalyticsTab > 0 ?
                                            <td>
                                                <Form.Check aria-label="View Permission" checked={role.DisplayAnalyticsTab == 1} onChange={e => changePermissionForAnalyticsTab(e, 1)} />
                                            </td> : <td></td>}
                                        {currentUserPermissions.DisplayAnalyticsTab > 1 ?
                                            <td>
                                                <Form.Check aria-label="Edit Permission" checked={role.DisplayAnalyticsTab == 2} onChange={e => changePermissionForAnalyticsTab(e, 2)} />
                                            </td> : <td></td>}
                                    </tr>
                                    <tr>
                                        <td>
                                            Reports Tab
                                        </td>
                                        {currentUserPermissions.DisplayReportsTab > 0 ?
                                            <td>
                                                <Form.Check aria-label="View Permission" checked={role.DisplayReportsTab == 1} onChange={e => changePermissionForReportsTab(e, 1)} />
                                            </td> : <td></td>}
                                        {currentUserPermissions.DisplayReportsTab > 1 ?
                                            <td>
                                                <Form.Check aria-label="Edit Permission" checked={role.DisplayReportsTab == 2} onChange={e => changePermissionForReportsTab(e, 2)} />
                                            </td> : <td></td>}
                                    </tr>
                                    <tr>
                                        <td>
                                            Alarms Tab
                                        </td>
                                        {currentUserPermissions.DisplayAlarmsTab > 0 ?
                                            <td>
                                                <Form.Check aria-label="View Permission" checked={role.DisplayAlarmsTab == 1} onChange={e => changePermissionForAlarmsTab(e, 1)} />
                                            </td> : <td></td>}
                                        {currentUserPermissions.DisplayAlarmsTab > 1 ?
                                            <td>
                                                <Form.Check aria-label="Edit Permission" checked={role.DisplayAlarmsTab == 2} onChange={e => changePermissionForAlarmsTab(e, 2)} />
                                            </td> : <td></td>}
                                    </tr>
                                    <tr>
                                        <td>
                                            Health Summary Tab
                                        </td>
                                        {currentUserPermissions.DisplayHealthSummaryTab > 0 ?
                                            <td>
                                                <Form.Check aria-label="View Permission" checked={role.DisplayHealthSummaryTab == 1} onChange={e => changePermissionForHealthSummaryTab(e, 1)} />
                                            </td> : <td></td>}
                                        {currentUserPermissions.DisplayHealthSummaryTab > 1 ?
                                            <td>
                                                <Form.Check aria-label="Edit Permission" checked={role.DisplayHealthSummaryTab == 2} onChange={e => changePermissionForHealthSummaryTab(e, 2)} />
                                            </td> : <td></td>}
                                    </tr>
                                    <tr>
                                        <td>
                                            Cost Breakdown Tab
                                        </td>
                                        {currentUserPermissions.DisplayCostBreakdownTab > 0 ?
                                            <td>
                                                <Form.Check aria-label="View Permission" checked={role.DisplayCostBreakdownTab == 1} onChange={e => changePermissionForCostBreakdownTab(e, 1)} />
                                            </td> : <td></td>}
                                        {currentUserPermissions.DisplayCostBreakdownTab > 1 ?
                                            <td>
                                                <Form.Check aria-label="Edit Permission" checked={role.DisplayCostBreakdownTab == 2} onChange={e => changePermissionForCostBreakdownTab(e, 2)} />
                                            </td> : <td></td>}
                                    </tr>
                                    <tr>
                                        <td>
                                            Deep Dive Tab
                                        </td>
                                        {currentUserPermissions.DisplayDeepDiveTab > 0 ?
                                            <td>
                                                <Form.Check aria-label="View Permission" checked={role.DisplayDeepDiveTab == 1} onChange={e => changePermissionForDeepDiveTab(e, 1)} />
                                            </td> : <td></td>}
                                        {currentUserPermissions.DisplayDeepDiveTab > 1 ?
                                            <td>
                                                <Form.Check aria-label="Edit Permission" checked={role.DisplayDeepDiveTab == 2} onChange={e => changePermissionForDeepDiveTab(e, 2)} />
                                            </td> : <td></td>}
                                    </tr>
                                    <tr>
                                        <td>
                                            Billing Tab
                                        </td>
                                        {currentUserPermissions.DisplayBillingTab > 0 ?
                                            <td>
                                                <Form.Check aria-label="View Permission" checked={role.DisplayBillingTab == 1} onChange={e => changePermissionForBillingTab(e, 1)} />
                                            </td> : <td></td>}
                                        {currentUserPermissions.DisplayBillingTab > 1 ?
                                            <td>
                                                <Form.Check aria-label="Edit Permission" checked={role.DisplayBillingTab == 2} onChange={e => changePermissionForBillingTab(e, 2)} />
                                            </td> : <td></td>}
                                    </tr>
                                    <tr>
                                        <td>
                                            Site Details Tab
                                        </td>
                                        {currentUserPermissions.DisplaySiteDetailsTab > 0 ?
                                            <td>
                                                <Form.Check aria-label="View Permission" checked={role.DisplaySiteDetailsTab == 1} onChange={e => changePermissionForSiteDetailsTab(e, 1)} />
                                            </td> : <td></td>}
                                        {currentUserPermissions.DisplaySiteDetailsTab > 1 ?
                                            <td>
                                                <Form.Check aria-label="Edit Permission" checked={role.DisplaySiteDetailsTab == 2} onChange={e => changePermissionForSiteDetailsTab(e, 2)} />
                                            </td> : <td></td>}
                                    </tr>
                                    <tr>
                                        <td>
                                            Edge Tabs (Control, Calibration & Failsafes)
                                        </td>
                                        {currentUserPermissions.DisplayEdgeTabs > 0 ?
                                            <td>
                                                <Form.Check aria-label="View Permission" checked={role.DisplayEdgeTabs == 1} onChange={e => changePermissionForEdgeTabs(e, 1)} />
                                            </td> : <td></td>}
                                        {currentUserPermissions.DisplayEdgeTabs > 1 ?
                                            <td>
                                                <Form.Check aria-label="Edit Permission" checked={role.DisplayEdgeTabs == 2} onChange={e => changePermissionForEdgeTabs(e, 2)} />
                                            </td> : <td></td>}
                                    </tr>
                                    <tr>
                                        <td>
                                            Signal Setup
                                        </td>
                                        {currentUserPermissions.SignalSetup > 0 ?
                                            <td>
                                                <Form.Check aria-label="View Permission" checked={role.SignalSetup == 1} onChange={e => changePermissionForSignalSetup(e, 1)} />
                                            </td> : <td></td>}
                                        {currentUserPermissions.SignalSetup > 1 ?
                                            <td>
                                                <Form.Check aria-label="Edit Permission" checked={role.SignalSetup == 2} onChange={e => changePermissionForSignalSetup(e, 2)} />
                                            </td> : <td></td>}
                                    </tr>
                                    <tr>
                                        <td>
                                            Control Chains Tab
                                        </td>
                                        {currentUserPermissions.DisplayControlChainsTab > 0 ?
                                            <td>
                                                <Form.Check aria-label="View Permission" checked={role.DisplayControlChainsTab == 1} onChange={e => changePermissionForControlChains(e, 1)} />
                                            </td> : <td></td>}
                                        {currentUserPermissions.DisplayControlChainsTab > 1 ?
                                            <td>
                                                <Form.Check aria-label="Edit Permission" checked={role.DisplayControlChainsTab == 2} onChange={e => changePermissionForControlChains(e, 2)} />
                                            </td> : <td></td>}
                                    </tr>
                                    <tr>
                                        <td>
                                            Compliance Details
                                        </td>
                                        {currentUserPermissions.ComplianceDetails > 0 ?
                                            <td>
                                                <Form.Check aria-label="View Permission" checked={role.ComplianceDetails == 1} onChange={e => changePermissionForComplianceDetails(e, 1)} />
                                            </td> : <td></td>}
                                        {currentUserPermissions.ComplianceDetails > 1 ?
                                            <td>
                                                <Form.Check aria-label="Edit Permission" checked={role.ComplianceDetails == 2} onChange={e => changePermissionForComplianceDetails(e, 2)} />
                                            </td> : <td></td>}
                                    </tr>
                                    <tr>
                                        <td>
                                            Metric History
                                        </td>
                                        {currentUserPermissions.MetricHistory > 0 ?
                                            <td>
                                                <Form.Check aria-label="View Permission" checked={role.MetricHistory == 1} onChange={e => changePermissionForMetricHistory(e, 1)} />
                                            </td> : <td></td>}
                                        {currentUserPermissions.MetricHistory > 1 ?
                                            <td>
                                                <Form.Check aria-label="Edit Permission" checked={role.MetricHistory == 2} onChange={e => changePermissionForMetricHistory(e, 2)} />
                                            </td> : <td></td>}
                                    </tr>
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item className="m-2" eventKey="1">
                        <Accordion.Header>
                            Special Permissions
                        </Accordion.Header>
                        <Accordion.Body eventKey="1">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>View</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Receives Weekly Reports</td>
                                        {currentUserPermissions.ReceivesWeeklyReports > 0 ?
                                            <td>
                                                <Form.Check
                                                    aria-label="View Permission"
                                                    checked={role.ReceivesWeeklyReports == 1}
                                                    onChange={e => changeWeeklyReportPermission(e, 1)} />
                                            </td> : <td></td>}
                                        {currentUserPermissions.ReceivesWeeklyReports > 1 ?
                                            <td>
                                                <Form.Check
                                                    aria-label="Edit Permission"
                                                    checked={role.ReceivesWeeklyReports == 2}
                                                    onChange={e => changeWeeklyReportPermission(e, 2)} />
                                            </td> : <td></td>}
                                    </tr>
                                    <tr>
                                        <td>Receives Daily Data Logs</td>
                                        {currentUserPermissions.ReceivesDailyDataLogs > 0 ?
                                            <td>
                                                <Form.Check
                                                    aria-label="View Permission"
                                                    checked={role.ReceivesDailyDataLogs == 1}
                                                    onChange={e => changeDailyDataLogPermission(e, 1)} />
                                            </td> : <td></td>}
                                        {currentUserPermissions.ReceivesDailyDataLogs > 1 ?
                                            <td>
                                                <Form.Check
                                                    aria-label="Edit Permission"
                                                    checked={role.ReceivesDailyDataLogs == 2}
                                                    onChange={e => changeDailyDataLogPermission(e, 2)} />
                                            </td> : <td></td>}
                                    </tr>
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col>
                            <Button className="float-end" variant="primary" onClick={() => { handleSave(); props.onHide(); }}>Save</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}


function RolePermissions(props) {
    const [showRolePermissions, setShowRolePermissions] = useState(false);

    return (
        <>
            <Button variant="primary" style={{ float: 'center' }} onClick={() => setShowRolePermissions(true)}>
                Role Permissions
            </Button>
            <RolePermissionsModal
                show={showRolePermissions}
                onHide={() => {
                    setShowRolePermissions(false);
                }}
                {...props}
            />
        </>
    )
}

export default RolePermissions;

