import React, { useState, useRef, useEffect, useContext } from 'react';
import Select, { components } from "react-select";
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import chroma from 'chroma-js';
import { Overlay, Popover, InputGroup, ToggleButtonGroup, ToggleButton, Modal, Container, Row, Col, Button, Form } from 'react-bootstrap';
import SWColourPicker from "../sw-colourpicker";
import { ConfigContext } from "./LineChart"

function FilterSignalModal(props) {

    const handleAdd = (filterSignalLCI) => {
        props.onaddfilter(filterSignalLCI)
        props.onHide()
    }

    // Get nicename of filter signal
    if (props.filterLCI) {
        var signalID = props.filterLCI.InputSignalID;
        var nicename = props.signals[props.signals.findIndex(f => f.value === signalID)].label;

        console.log({ nicename })
    } else {
        var nicename = '';
    }


    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add context signal?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <strong>{nicename}</strong> was found as a context signal but has not already been included. Would you like to add it?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={props.onHide}>No</Button>
                        </Col>
                        <Col>
                            <Button className="float-right" variant="success" onClick={() => handleAdd(props.filterLCI)}>Yes</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

const SortableSelect = SortableContainer(Select);

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    multiValue: (styles, { data }) => {
        const colorTemp = data.selectedColour;
        const color = chroma(colorTemp);
        return {
            ...styles,
            backgroundColor: color.alpha(0.2).css(),
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.selectedColour,
        ':hover': {
            backgroundColor: data.selectedColour,
            color: 'white',
        },
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.selectedColour,
        ':hover': {
            backgroundColor: data.selectedColour,
            color: 'white',
        },
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const CaretDownIcon = () => {
    return <i className="far fa-plus"></i>;
};

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon />
        </components.DropdownIndicator>
    );
};

const IconOption = props => (
    <components.Option {...props}>
        <i className="fas fa-cog"></i>
        {props.data.label}
    </components.Option>
);


function MultiValueLabel(props) {
    const [showOverlay, setShowOverlay] = useState(false);
    const [colour, setColour] = useState();
    const [axisSide, setAxisSide] = useState(0); // 0 - Left, 1 - Right
    const [inputType, setInputType] = useState(1); // 1 - Line, 2 - Backdrop
    const [showNonCompliance, setShowNonCompliance] = useState(false);
    const { config, updateColour, updateAxisSide, updateInputType, updateShowNonCompliance } = useContext(ConfigContext);

    useEffect(() => {
        if (config.LineChartInputs && config.LineChartInputs.length > 0) {
            var thisLineInputConfig = config.LineChartInputs.filter(input => input.InputSignalID === props.data.value)[0]
            setColour(props.selectProps.colorPickerColours[thisLineInputConfig.ColorID])
            setInputType(thisLineInputConfig.InputType)
            setAxisSide(thisLineInputConfig.AxisSide || 0)
            setShowNonCompliance(thisLineInputConfig.ShowNonCompliance)
        }
    }, [props.data.value, config])

    const target = useRef(null);

    return (
        <>
            <components.MultiValueLabel
                {...props}
                innerProps={{
                    ...props.innerProps,
                    onClick: e => {
                        setShowOverlay(!showOverlay)
                    },
                    ref: target
                }}
            >
                <i className="fas fa-cog"></i>
                &nbsp;
                {props.children}
            </components.MultiValueLabel>
            <Overlay
                target={target.current}
                show={showOverlay}
                placement="bottom"
                rootClose={true}
                onHide={() => { setShowOverlay(false) }}
            >
                <Popover style={{ "maxWidth": "none" }}>
                    <Popover.Header as="h3">Signal Settings</Popover.Header>
                    <Popover.Body>
                        <SWColourPicker
                            colorPickerColours={props.selectProps.colorPickerColours}
                            color={colour}
                            handleColourChange={(colour) => { updateColour(colour.hex, props.data.value) }}
                        />

                        <InputGroup className="justify-content-center mb-2">
                            <InputGroup.Text id="inputGroup-sizing-default">Draw signal as: </InputGroup.Text>
                            <ToggleButtonGroup type="radio" aria-label="Basic example" name="TypeSelect" className="btn-block" value={inputType}>
                                <ToggleButton value={1} variant="outline-secondary" onClick={() => { updateInputType(1, props.data.value) }}>Line</ToggleButton>
                                <ToggleButton value={2} variant="outline-secondary" onClick={() => { updateInputType(2, props.data.value) }}>Backdrop</ToggleButton>
                            </ToggleButtonGroup>
                        </InputGroup>
                        <InputGroup className="justify-content-center mb-2">
                            <InputGroup.Text id="inputGroup-sizing-default">Choose axis side: </InputGroup.Text>
                            <ToggleButtonGroup type="radio" aria-label="Basic example" name="SideSelect" className="btn-block" value={axisSide}>
                                <ToggleButton value={0} variant="outline-secondary" onClick={() => { updateAxisSide(0, props.data.value) }}>Left</ToggleButton>
                                <ToggleButton value={1} variant="outline-secondary" onClick={() => { updateAxisSide(1, props.data.value) }}>Right</ToggleButton>
                            </ToggleButtonGroup>
                        </InputGroup>
                        <InputGroup className="justify-content-center mb-2">
                            <InputGroup.Text id="inputGroup-sizing-default">Show non-compliance: </InputGroup.Text>
                            <ToggleButtonGroup type="radio" aria-label="Basic example" name="PositionSelect" className="btn-block" value={showNonCompliance}>
                                <ToggleButton value={true} variant="outline-secondary" onClick={() => { updateShowNonCompliance(true, props.data.value) }}>Yes</ToggleButton>
                                <ToggleButton value={false} variant="outline-secondary" onClick={() => { updateShowNonCompliance(false, props.data.value) }}>No</ToggleButton>
                            </ToggleButtonGroup>
                        </InputGroup>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </>
    );
};

const SortableMultiValue = SortableElement(props => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const innerProps = { ...props.innerProps, onMouseDown };
    const style = { zIndex: 99999999 };
    return <components.MultiValue {...props} innerProps={innerProps} style={style} />;
});

const SortableMultiValueLabel = sortableHandle(props => (
    <MultiValueLabel {...props} />
));

function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
}

function ClickableSignalSelect(props) {
    const { updateSelectedSignals, addFilterLCI } = useContext(ConfigContext);
    const [showContextModal, setShowContextModal] = useState(false);
    const [filterLCI, setFilterLCI] = useState();

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newValue = arrayMove(props.value, oldIndex, newIndex);
        var _ = updateSelectedSignals(newValue, props.addFilter);
    };

    return (
        <>
            <SortableSelect
                useDragHandle
                // react-sortable-hoc props:
                axis="xy"
                onSortEnd={onSortEnd}
                distance={4}
                helperClass='sortableHelper'
                // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                // react-select props:
                options={props.options}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                isSearchable={true}
                styles={colourStyles}
                value={props.value}
                placeholder="Select signal(s)..."
                components={{
                    MultiValue: SortableMultiValue,
                    MultiValueLabel: SortableMultiValueLabel,
                    DropdownIndicator,
                }}
                openMenuOnClick={false}
                colorPickerColours={props.colorPickerColours}
                isClearable={false}
                onChange={(signals) => {
                    var filters = updateSelectedSignals(signals, props.addFilter);
                    if (filters.length > 0) { // A filter signal was found 
                        setShowContextModal(true)
                        setFilterLCI(filters[0])
                    }
                }}
            />
            <FilterSignalModal
                show={showContextModal}
                onHide={() => {
                    setShowContextModal(false);
                }}
                onaddfilter={addFilterLCI}
                filterLCI={filterLCI}
                signals={props.options}
            />
        </>
    );
}

export default ClickableSignalSelect;