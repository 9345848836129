import React, { useState, useEffect } from "react";
import { TicketDetailsProps } from "./types";
import { CardGroup, Card, ListGroup, Spinner } from 'react-bootstrap';
import moment from "moment";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { StatusBadge, PriorityBadge } from "./Badges";
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';   
import 'primereact/resources/primereact.css';                       
import 'primeicons/primeicons.css';                                 

import 'react-vertical-timeline-component/style.min.css';
import './ticketStyles.scss'

export const TicketDetails: React.FC<TicketDetailsProps> = ({
    ticketData,
    editTicket,
    addComment,
    setSelectedTicket,
    addAttachment,
}) => {
    const [commentBubbles, setCommentBubbles] = useState<Element[]>([]);
    const [attachments, setAttachments] = useState<Element[]>([]);
    const [newComment, setNewComment] = useState<string>("");
    const [name, setName] = useState<string>(ticketData.name);
    const [description, setDescription] = useState<string>(ticketData.description);
    const [backArrowColour, setBackArrowColour] = useState("#14a4dd");
    const [loading, setLoading] = useState(false);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        console.log(windowSize.height);
    }, [windowSize.height]);

    useEffect(() => {
        const bubbles: Element[] = ticketData.comments.map(comment => (
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                    background: (comment.user !== undefined && comment.user.isSWDI) ? "#F47920" : "#002c4e",
                    color: (comment.user !== undefined && comment.user.isSWDI) ? "#000" : "#fff",
                }}
                position={ (comment.user !== undefined && comment.user.isSWDI) ? "left" : "right"}
                contentArrowStyle={{
                    borderRight: (comment.user !== undefined && comment.user.isSWDI) ? "7px solid #F47920" : "7px solid #002c4e",
                }}
                date={moment(comment.createDate).format("MMM DD") + " at " + moment(comment.createDate).format('h:mma')}
                iconStyle={{ background: "#14a4dd", color: "#fff" }}  
                icon={<i className="fas fa-comment fa-lg timeline-icon"></i>}
            >
                <p>{comment.text}</p>
            </VerticalTimelineElement>
        )).slice(0).reverse();

        setCommentBubbles(bubbles);
    }, [ticketData.comments]);

    useEffect(() => {
        const files = ticketData.attachments.map((attachment) =>
            <><ListGroup.Item>
                <a href={attachment.url} target='_blank'><p>{attachment.fileName}</p></a>
            </ListGroup.Item><br /></>
        );

        setAttachments(files);
        setLoading(false)
    }, [ticketData.attachments]);

    const _handleCommentSave = (key: string) => {
        if (key === 'Enter') {
            addComment(ticketData.id, newComment, name, ticketData.siteGUID);
            setNewComment("");
        }
    }

    const _handleFinishTextEdit = () => {
        let newData = { ...ticketData }
        newData.name = name;
        newData.description = description;

        editTicket(newData);
    }

    const onUpload = (e:FileUploadHandlerEvent) => {
        setLoading(true);
        addAttachment(ticketData.id,e.files);
        e.options.clear();
    }

    return (
        <CardGroup>
            <Card border="primary" style={{ flex: 1 }}>
                <Card.Header>
                    <div className="m-1 d-flex align-items-center justify-content-between">
                        <i
                            className="fas fa-chevron-left"
                            style={{ cursor: "pointer", color: backArrowColour }}
                            onClick={() => setSelectedTicket(undefined)}
                            onMouseEnter={() => setBackArrowColour("#F47920")}
                            onMouseLeave={() => setBackArrowColour("#14a4dd")}
                        ></i>
                        <Card.Text className="m-0">Ticket ID: {ticketData.id}</Card.Text>
                        <StatusBadge data={ticketData} editTicket={editTicket} />
                        <PriorityBadge data={ticketData} editTicket={editTicket} />
                    </div>
                </Card.Header>

                <Card.Body className="m-3" style={{ padding: "0px" }}>
                    {ticketData.user !== undefined && <>
                    <Card.Text className="m-0">Created By: {ticketData.user.name} ({ticketData.user.email})</Card.Text>
                    <hr/></>}
                    <textarea
                        className="p-2"
                        style={{ width: '100%', resize: 'none' }}
                        rows={1}
                        contentEditable={true}
                        onChange={e => setName(e.target.value)}
                        onBlur={_handleFinishTextEdit}
                        value={name} />
                    <textarea
                        className="p-2"
                        style={{ width: '100%' }}
                        contentEditable={true}
                        onChange={e => setDescription(e.target.value)}
                        onBlur={_handleFinishTextEdit}
                        value={description} />

                    <hr/>
                    <div  className="m-1 d-flex align-items-center justify-content-between" style={{textAlign: "center"}}>
                        <FileUpload mode="basic" customUpload name="file" maxFileSize={10000000} uploadHandler={onUpload} auto chooseLabel="Add Attachment" />
                        {loading && <Spinner animation="border" role="status" variant="primary" />}
                    </div>
                    <hr/>
                    {ticketData.attachments && <ListGroup>{attachments}</ListGroup>}
                </Card.Body>
            </Card>

            <Card style={{ flex: 2 }}>
                <Card.Body>
                    <div style={{ overflowY: 'scroll', height:'450px' }}>
                        <VerticalTimeline>
                            {commentBubbles}
                        </VerticalTimeline>
                    </div>
                </Card.Body>
                <Card.Footer className="p-0">
                    <textarea
                        className="p-2"
                        style={{ width: '100%', resize:'none' }}
                        contentEditable={true}
                        value={newComment}
                        onChange={e => setNewComment(e.target.value)}
                        onKeyDown={e => _handleCommentSave(e.key)}
                        placeholder="Enter a new comment here..."
                    />
                </Card.Footer>
            </Card>
        </CardGroup>
    );
};

