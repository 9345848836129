import { ReactNode, useEffect, useState } from 'react';
import { StreamwiseControlLinkData, BlendedControlParameters, ChartSignalObject, BlendedLogs } from '../types';
import './styles.scss'
import { Button, Modal, InputGroup, FormControl, Accordion, Table } from 'react-bootstrap';
import { ControlChartContainer } from '../ControlChartContainer';
import { AreaChart } from '../ControlCharts';
import { useFetchTelemetry } from '../../CustomHooks/useFetchTelemetry';

export function BlendedControlModal(props) {
    const [controlLinkData, setControlLinkData] = useState<StreamwiseControlLinkData>();
    const [tuneParameters, setTuneParameters] = useState<BlendedControlParameters[]>([]);
    const [blendedControlData, setBlendedControlData] = useState<ReactNode>(<></>);

    // Chart States
    const [inputIDs, setInputIDs] = useState<string[]>([]);
    const [outputIDs, setOutputIDs] = useState<string[]>([]);
    const [inputData, inMin, inMax] = useFetchTelemetry(inputIDs, 1, props.show, props.deviceID);
    const [outputData, outMin, outMax] = useFetchTelemetry(outputIDs, 1, props.show, props.deviceID);
    const [modData, setModData] = useState<ChartSignalObject[]>([]);
    const [logsArray, setLogs] = useState<BlendedLogs[]>([]);

    useEffect(() => {
        if (props.show) {
            props.getData(props.id).then(data => {
                setControlLinkData(data.controlLinkData);
                setTuneParameters([...data.blended]);
                setInputIDs([...data.blended.map(s => s.InputSignalID)]);
                setOutputIDs([data.controlLinkData.signalID]);

                var d = new Date();
                var offset = (d.getTimezoneOffset() * -1) / 60;
                fetch("/api/ControlChainsAPI/GetLogDataBlended/", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ offset: offset, id: data.controlLinkData.signalID }),
                })
                    .then((response) => response.json())
                    .then((logs: BlendedLogs[]) => {
                        console.log(logs);
                        setLogs(logs);
                    });

            });
        }
    }, [props.show]);

    useEffect(() => { updateData(); }, [tuneParameters])

   

    // Apply the coefficients to data
    useEffect(() => {
        let newModData = [...inputData];
        for (let i = 0; i < newModData.length; i++) {
            const entry = tuneParameters.filter(d => d.InputSignalID === newModData[i].signalID);
            if (entry.length > 0) {
                const m = entry[0].Gradient !== undefined ? entry[0].Gradient : 0;
                for (let j = 0; j < newModData[i].dataset.length; j++) {
                    newModData[i].dataset[j].y = m * newModData[i].dataset[j].y;
                }
            }
        }
        setModData(newModData);
    }, [inputData]);

    const _handleGradientChange = (value: string, index: number) => {
        let blendedParams = tuneParameters;
        blendedParams[index] = {
            ...blendedParams[index],
            Gradient: parseFloat(value)
        };
        setTuneParameters(blendedParams);
        updateData();
    }

    const updateData = () => {
        setBlendedControlData(
            <>{tuneParameters?.map((d,index) => <InputGroup key={d.InputSignalID + "key"} className="mb-3">
        <InputGroup.Text>{d.NiceName} Scaling Factor: </InputGroup.Text>
        <FormControl
            aria-describedby="basic-addon3"
            value={d.Gradient}
            type="number"
            onChange={v => _handleGradientChange(v.target.value, index)}>
        </FormControl>
    </InputGroup>)}</>);
    }

    const saveControlLinkSettings = () => {
        props.getData(props.id).then(data => {
            const newData = {
                ...data,
                controlLinkData: controlLinkData,
                blended: tuneParameters
            }
            props.saveSettings(props.id, newData);
            props.submitControlLink(newData);
            props.onHide();
        });
    }

    const _handleAddComponent = () => {
        if (tuneParameters != undefined) {
            let blendedParams = tuneParameters;
            blendedParams.push({ NiceName: "", Gradient: 0 });
            setTuneParameters(blendedParams);
            updateData();
        }
    }
    const _handleDeleteComponent = () => {
        let blendedParams = tuneParameters;
        blendedParams?.pop();
        setTuneParameters(blendedParams);
        updateData();
    }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-blended"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-blended">
                    Blended Control Link
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {blendedControlData}
                <br />
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Blended control Logs</Accordion.Header>
                        <Accordion.Body>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>User Name</th>
                                        <th>Signal</th>
                                        <th>Gradient</th>
                                        <th>Time</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {logsArray.map((item) => (
                                        <tr key={item.UserID}>
                                            {Object.values(item).map((val) => (
                                                <td>{val}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>

                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div style={{ height: '100%', minHeight: '400px', width: '100%' }}>
                    {controlLinkData?.inputBlock !== undefined
                        &&
                        controlLinkData?.signalID !== undefined
                        &&
                        controlLinkData?.inputBlock.length > 0
                        &&
                        controlLinkData?.signalID.length > 0
                        &&
                        props.deviceID !== undefined
                        &&
                        <ControlChartContainer
                        chart={<AreaChart inputData={modData} outputData={outputData} />}
                        inputData={modData}
                        outputData={outputData}
                        />
                    }
                </div>  
            </Modal.Body>
            <Modal.Footer>
                {(tuneParameters != undefined && tuneParameters.length < 4) &&
                    <Button onClick={_handleAddComponent}>Add Signal</Button>
                }
                {(tuneParameters != undefined && tuneParameters.length > 2) &&
                    <Button className='btn-danger' onClick={_handleDeleteComponent}>Delete Signal</Button>
                }
                    <Button onClick={saveControlLinkSettings}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}