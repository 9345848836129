import React, { useState, useEffect, useContext } from 'react';
import { Accordion, Button, Card, Modal, InputGroup, Form, Row, Col, FormControl } from 'react-bootstrap';
import { Collapse } from 'react-bootstrap';
import $ from 'jquery';
import { ReportContext } from './ReportContext';

const GaugeCard = ({ data, equations, handleDelete, auth }) => {
    const context = useContext(ReportContext);
    const [name, setName] = useState(data.EquationName);
    const [eqnID, setEqnID] = useState(data.EquationID);
    const [inputs, setInputs] = useState([{}, {}]);
    const [redMax, setRedMax] = useState(data.RedMax);
    const [yellowMax, setYellowMax] = useState(data.YellowMax);
    const [order, setOrder] = useState(data.Order);

    // Retrieve the inputs
    useEffect(() => {
        $.ajax({
            url: '/api/ReportsAPI/GetGaugeInputs',
            type: 'get',
            data: { gaugeID: data.GaugeID },
            success: (res) => {
                let input = JSON.parse(res);
                if (input.length == 1) {
                    input = [...input, {}]
                }
                setInputs(input);
            }
        });
    }, []);

    const _handleEdit = () => {
        $.ajax({
            url: '/api/ReportsAPI/EditGauges',
            type: 'post',
            data: JSON.stringify({
                Gauge: {
                    GaugeID: data.GaugeID,
                    EquationID: eqnID,
                    EquationName: name,
                    Order: order,
                    RedMax: redMax,
                    YellowMax: yellowMax
                },
                Inputs: inputs
            }),
            contentType: 'application/json'
        });
    }

    const _handleSignalEdit = (inputID, signalID) => {
        let newInputs = [...inputs];
        for (var i = 0; i < newInputs.length; i++) {
            if (newInputs[i].GaugeInputsID == inputID) {
                newInputs[i].SignalID = signalID;
            }
        }
        setInputs(newInputs);
    }

    return (
        <Card className="m-2" style={{ width: "450px" }}>
            <Card.Header>{name}</Card.Header>
            <Card.Body className="p-3">
                <Row>
                    <Col>
                        <InputGroup className="justify-content-center mb-2">
                            <InputGroup.Text>Gauge name: </InputGroup.Text>
                            <FormControl
                                aria-describedby="basic-addon3"
                                value={name}
                                onChange={e => setName(e.target.value)} >
                            </FormControl>
                        </InputGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <InputGroup className="justify-content-center mb-2">
                            <InputGroup.Text>Red Max: </InputGroup.Text>
                            <FormControl
                                type="number"
                                step="1"
                                min="0"
                                max="100"
                                aria-describedby="basic-addon3"
                                value={redMax}
                                onChange={e => setRedMax(e.target.value)}>
                            </FormControl>
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="justify-content-center mb-2">
                            <InputGroup.Text>Yellow Max: </InputGroup.Text>
                            <FormControl
                                type="number"
                                step="1"
                                min="0"
                                max="100"
                                aria-describedby="basic-addon3"
                                value={yellowMax}
                                onChange={e => setYellowMax(e.target.value)}>
                            </FormControl>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Range
                            custom
                            step="1"
                            min="0"
                            max="100"
                            aria-describedby="basic-addon3"
                            value={redMax}
                            onChange={e => setRedMax(e.target.value)}
                        />
                    </Col>
                    <Col>
                        <Form.Range
                            custom
                            step="1"
                            min="0"
                            max="100"
                            aria-describedby="basic-addon3"
                            value={yellowMax}
                            onChange={e => setYellowMax(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup className="justify-content-center mb-2">
                            <InputGroup.Text>Metric: </InputGroup.Text>
                            <FormControl as="select"
                                aria-describedby="basic-addon3"
                                value={eqnID}
                                onChange={e => setEqnID(e.target.value)}>
                                {equations}
                            </FormControl>
                        </InputGroup>
                    </Col>
                </Row>

                {(eqnID != 4) ?
                    <Row>
                        <Col>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>Signal of interest: </InputGroup.Text>
                                <FormControl as="select"
                                    aria-describedby="basic-addon3"
                                    value={inputs[0].SignalID}
                                    onChange={e => _handleSignalEdit(inputs[0].GaugeInputsID, e.target.value)}>
                                    {context.signals.map(signal => <option key={signal.SignalID} value={signal.SignalID}>{signal.NiceName}</option>)}
                                </FormControl>
                            </InputGroup>
                        </Col>
                    </Row> :
                    <div>
                        <Row>
                            <Col>
                                <InputGroup className="justify-content-center mb-2">
                                    <InputGroup.Text>Inlet signal: </InputGroup.Text>
                                    <FormControl as="select"
                                        aria-describedby="basic-addon3"
                                        value={inputs[0].SignalID}
                                        onChange={e => _handleSignalEdit(inputs[0].GaugeInputsID, e.target.value)}>
                                        {context.signals.map(signal => <option key={signal.SignalID} value={signal.SignalID}>{signal.NiceName}</option>)}
                                    </FormControl>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup className="justify-content-center mb-2">
                                    <InputGroup.Text>Outlet signal: </InputGroup.Text>
                                    <FormControl as="select"
                                        aria-describedby="basic-addon3"
                                        value={inputs[1].SignalID}
                                        onChange={e => _handleSignalEdit(inputs[1].GaugeInputsID, e.target.value)}>
                                        {context.signals.map(signal => <option key={signal.SignalID} value={signal.SignalID}>{signal.NiceName}</option>)}
                                    </FormControl>
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                }

                <InputGroup className="justify-content-center mb-3">
                    <InputGroup.Text>Order</InputGroup.Text>
                    <FormControl
                        type="number"
                        aria-describedby="basic-addon3"
                        onChange={e => setOrder(e.target.value)}
                        value={order}>
                    </FormControl>
                </InputGroup>

                {auth > 1 &&
                    <Row>
                        <Col>
                            <Button variant="danger" onClick={() => handleDelete(data)}>
                                Delete Gauge  <i className="fas fa-trash-alt"></i>
                            </Button>
                        </Col>
                        <Col>
                            <Button className="float-right" variant="primary" onClick={_handleEdit}>
                                Save settings  <i className="fas fa-save"></i>
                            </Button>
                        </Col>
                    </Row>
                }
            </Card.Body>
        </Card>
    );

}

const GaugeModal = ({ show, siteGUID, auth }) => {
    const context = useContext(ReportContext);
    const [charts, setCharts] = useState([]);
    const [equations, setEquations] = useState([]);

    // New chart states
    const [name, setName] = useState("");
    const [eqnID, setEqnID] = useState("");
    const [signalID1, setSignalID1] = useState("");
    const [signalID2, setSignalID2] = useState("");
    const [redMax, setRedMax] = useState(50);
    const [yellowMax, setYellowMax] = useState(50);
    const [order, setOrder] = useState(0);

    useEffect(() => {
        console.log("Get equations - Gauge");
        $.ajax({
            url: '/api/ReportsAPI/GetEquationList',
            type: 'get',
            success: (res) => {
                let eqnList = JSON.parse(res);
                setEquations(eqnList.map(item => <option key={item.EquationID} value={item.EquationID}>{item.EquationDescription}</option>))
            }
        });
    }, []);

    const retrieveCharts = () => {
        $.ajax({
            url: '/api/ReportsAPI/GetGauges',
            type: 'get',
            data: { siteGUID: siteGUID },
            success: (res) => {
                let chartSettings = JSON.parse(res);
                setCharts([...chartSettings.map(chart => <GaugeCard key={chart.GaugeID} data={chart} equations={equations} handleDelete={_handleChartDelete} auth={auth} />)]);
            }
        });
    }

    useEffect(() => {
        if (show) {
            retrieveCharts();
        }
    }, [show]);

    const _handleChartDelete = (chart) => {
        $.ajax({
            url: '/api/ReportsAPI/DeleteGauges',
            type: 'post',
            data: JSON.stringify({ GaugeID: chart.GaugeID }),
            contentType: 'application/json',
            success: () => { retrieveCharts() }
        });
    }

    const _handleChartCreate = () => {
        $.ajax({
            url: '/api/ReportsAPI/CreateGauges',
            type: 'post',
            data: JSON.stringify({
                Gauge: {
                    siteGUID: siteGUID,
                    equationID: eqnID,
                    equationName: name,
                    order: order,
                    redMax: redMax,
                    yellowMax: yellowMax
                },
                Inputs: [{ SignalID: signalID1 }, { SignalID: signalID2 }]
            }),
            contentType: 'application/json',
            success: () => {
                retrieveCharts();
                // Clear filled fields
                setName("");
                setEqnID("");
                setSignalID1("");
                setSignalID2("");
                setRedMax(50);
                setYellowMax(50);
                setOrder(0);
            }
        });
    }

    return (
        <Collapse
            in={show}
        >
            <div>
                <div className="d-flex flex-wrap">
                    <Accordion>
                        <Accordion.Item className="m-2" eventKey="0" style={{ width: "450px" }}>
                            <Accordion.Header>
                                Add New Gauge
                            </Accordion.Header>
                            <Accordion.Body className="p-3">
                                <Row>
                                    <Col>
                                        <InputGroup className="justify-content-center mb-2">
                                            <InputGroup.Text>Gauge name: </InputGroup.Text>
                                            <Form.Control
                                                aria-describedby="basic-addon3"
                                                value={name}
                                                onChange={e => setName(e.target.value)} >
                                            </Form.Control>
                                        </InputGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <InputGroup className="justify-content-center mb-2">
                                            <InputGroup.Text>Red Max: </InputGroup.Text>
                                            <Form.Control
                                                type="number"
                                                step="1"
                                                min="0"
                                                max="100"
                                                aria-describedby="basic-addon3"
                                                value={redMax}
                                                onChange={e => setRedMax(e.target.value)}>
                                            </Form.Control>
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup className="justify-content-center mb-2">
                                            <InputGroup.Text>Yellow Max: </InputGroup.Text>
                                            <Form.Control
                                                type="number"
                                                step="1"
                                                min="0"
                                                max="100"
                                                aria-describedby="basic-addon3"
                                                value={yellowMax}
                                                onChange={e => setYellowMax(e.target.value)}>
                                            </Form.Control>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Range
                                            custom
                                            step="1"
                                            min="0"
                                            max="100"
                                            aria-describedby="basic-addon3"
                                            value={redMax}
                                            onChange={e => setRedMax(e.target.value)}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Range
                                            custom
                                            step="1"
                                            min="0"
                                            max="100"
                                            aria-describedby="basic-addon3"
                                            value={yellowMax}
                                            onChange={e => setYellowMax(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputGroup className="justify-content-center mb-2">
                                            <InputGroup.Text>Metric: </InputGroup.Text>
                                            <Form.Control as="select"
                                                aria-describedby="basic-addon3"
                                                value={eqnID}
                                                onChange={e => setEqnID(e.target.value)}>
                                                <option></option>
                                                {equations}
                                            </Form.Control>
                                        </InputGroup>
                                    </Col>
                                </Row>

                                {(eqnID != 4) ?
                                    <Row>
                                        <Col>
                                            <InputGroup className="justify-content-center mb-2">
                                                <InputGroup.Text>Signal of interest: </InputGroup.Text>
                                                <Form.Control as="select"
                                                    aria-describedby="basic-addon3"
                                                    value={signalID1}
                                                    onChange={e => setSignalID1(e.target.value)}>
                                                    {context.signals.map(signal => <option key={signal.SignalID} value={signal.SignalID}>{signal.NiceName}</option>)}
                                                </Form.Control>
                                            </InputGroup>
                                        </Col>
                                    </Row> :
                                    <div>
                                        <Row>
                                            <Col>
                                                <InputGroup className="justify-content-center mb-2">
                                                    <InputGroup.Text>Inlet signal: </InputGroup.Text>
                                                    <Form.Control as="select"
                                                        aria-describedby="basic-addon3"
                                                        value={signalID1}
                                                        onChange={e => setSignalID1(e.target.value)}>
                                                        {context.signals.map(signal => <option key={signal.SignalID} value={signal.SignalID}>{signal.NiceName}</option>)}
                                                    </Form.Control>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <InputGroup className="justify-content-center mb-2">
                                                    <InputGroup.Text>Outlet signal: </InputGroup.Text>
                                                    <Form.Control as="select"
                                                        aria-describedby="basic-addon3"
                                                        value={signalID2}
                                                        onChange={e => setSignalID2(e.target.value)}>
                                                        {context.signals.map(signal => <option key={signal.SignalID} value={signal.SignalID}>{signal.NiceName}</option>)}
                                                    </Form.Control>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                }

                                <InputGroup className="justify-content-center mb-3">
                                    <InputGroup.Text>Order</InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        aria-describedby="basic-addon3"
                                        value={order}
                                        onChange={e => setOrder(e.target.value)}>
                                    </Form.Control>
                                </InputGroup>

                                {auth > 1 && <Button variant="primary" onClick={_handleChartCreate}>Create</Button>}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    {/* Cards */}
                    {charts}
                </div>
            </div>
        </Collapse>

    )
}

export default GaugeModal;