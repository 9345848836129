import { Chart, registerables} from 'chart.js';
import 'chartjs-adapter-moment';

Chart.register(...registerables);

const BarChart = (function () {

    const defaultYAxisId = 'y';
    const defaultAxisFontSize = 12;
    const defaultMaxTicksLimit = 5;
    var bar_chart = [];

    const defaultYAxis = {
        id: defaultYAxisId,
        type: 'linear',
        ticks: {
            fontSize: defaultAxisFontSize,
            maxTicksLimit: defaultMaxTicksLimit,
            beginAtZero: true
        },
        title: {
            display: true,
            fontSize: defaultAxisFontSize,
            padding: 4,
            text: ''
        }
    };


    const assignYAxes = function (datasets, axisOverride) {
        if (axisOverride) {
            datasets.forEach(function (dataset) {
                dataset.yAxisID = defaultYAxisId;
            });
        }

        return datasets;
    };

    const deepCopy = function (objectToGoDeepOn) {
        return JSON.parse(JSON.stringify(objectToGoDeepOn));
    };

    const createAxes = function (chartConfig) {
        const axes = {};
        if (chartConfig.chartYAxisIdOverride) {
            var singleYAxis = deepCopy(defaultYAxis);
            singleYAxis.title.text = chartConfig.chartYAxisIdOverride;
            axes[defaultYAxisId] = (singleYAxis);
        } else {
            chartConfig.chartDataSets.forEach(function (dataset) {
                var newYAxis = deepCopy(defaultYAxis);
                newYAxis.id = dataset.yAxisID;
                newYAxis.title.text = dataset.label;
                axes[dataset.yAxisID] = newYAxis;
            });
        }
        axes['x'] = {
                    gridLines: {
                        offsetGridLines: true
                    },
                    offset: true,
                    type: 'time',
                    time: {
                        unit: 'day'
                    },
                    ticks: {
                        autoSkip: false
                    }
                }

        return axes;
    };


    const createChartOptions = function (chartConfig) {
        const chartAxes = createAxes(chartConfig);

        return {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: true,
                labels: {
                    boxWidth: 20,
                    fontSize: 9,
                }
            },
            tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    title: function (tooltipItem, data) {
                        return '';
                    },
                    label: function (tooltipItem, data) {
                        const tooltip = data.datasets[tooltipItem.datasetIndex];
                        const value = Math.round(tooltip.data[tooltipItem.index].y * 100) / 100;
                        return value === 0 ? null : tooltip.label + ': ' + value;
                    }
                }
            },
            scales: {
                ...chartAxes,
            }
        };
    }

    const createChartData = function (chartConfig) {
        var datasets = chartConfig.chartDataSets;
        datasets = assignYAxes(datasets, chartConfig.chartYAxisIdOverride);

        return {
            datasets: datasets,
            labels: chartConfig.chartLabels,
        };
    };

    const initChartNoData = function (elementId, noData) {
        if (noData) {
            const chart = document.getElementById(elementId + '-Bar');
            if (chart !== null){
                chart.setAttribute('class', 'col row no-data-blur-effect');
            }

            const noDataLabel = document.getElementById(elementId + '-BarNoData');
            if (noDataLabel !== null){
                noDataLabel.removeAttribute('hidden');
            }
        } else {
            const chart = document.getElementById(elementId + '-Bar');
            if (chart !== null){
                chart.setAttribute('class', 'col row');
            }

            const noDataLabel = document.getElementById(elementId + '-BarNoData');
            if (noDataLabel !== null){
                noDataLabel.setAttribute('hidden', '');
            }
        }
    };

    const createChart = function (chartConfig, noData) {
        initChartNoData(chartConfig.chartId, noData);

        const canvasElement = document.getElementById(chartConfig.chartId + '-BarCanvas')
        if (canvasElement !== null){
            const ctx = canvasElement.getContext('2d');
            ctx.canvas.className = "col" // add back column styles
            if (bar_chart[chartConfig.chartId]) {
                bar_chart[chartConfig.chartId].destroy();
            }
    
            var chartData = createChartData(chartConfig);
            var chartOptions = createChartOptions(chartConfig); 
            bar_chart[chartConfig.chartId] = new Chart(ctx,
                {
                    type: 'bar',
                    data: chartData,
                    options: chartOptions
                });
    
            return bar_chart[chartConfig.chartId];
        }
            return {};
    };


    return {
        create: createChart
    };
}());

export default BarChart;