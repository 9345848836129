import { useState, useEffect } from 'react';
import { Container, Row, Col, Collapse, OverlayTrigger, Popover, Fade, Dropdown } from 'react-bootstrap';
import { NavMenu } from './NavMenu';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import { NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Chats } from './Chats';
import { NPSHandler } from './NPSHandler';

const StreamwiseTab = ({ tabsCollapsed, SiteGUID, tabDetails, subHeadingDetails }) => {
    const [showExtraTabs, setShowExtraTabs] = useState(false);
    const [showPopover, setShowPopover] = useState(false);
    const [arrowDir, setArrowDir] = useState("fas fa-angle-down");

    const _disableNav = (visibility) => {
        if (visibility) { return ""; }
        else { return " nav-disabled"; }
    }

    const _handleSubHeadingExpand = () => {
        let newShowVal = !showExtraTabs;
        if (newShowVal) { setArrowDir("fas fa-angle-up"); }
        else { setArrowDir("fas fa-angle-down"); }
        setShowExtraTabs(newShowVal);
    }

    return (
        SiteGUID ?
            <div>
                <NavItem>
                    <Fade appear={true} in={true}>
                        <div className="align-items-center"
                            onMouseEnter={() => setShowPopover(true)}
                            onMouseLeave={() => setShowPopover(false)}>

                            <OverlayTrigger
                                placement="right"
                                show={showPopover}
                                overlay={(tabsCollapsed && !showExtraTabs && subHeadingDetails) ?
                                    <Fade appear={true} in={true} className="bg-white rounded border border-left-0 border-dark">
                                        <div className="d-inline-flex">
                                            {tabDetails.map(({ tabVisible, relativeURL, title, icon }) => tabVisible ?
                                                <NavLink key={title + relativeURL} tag={Link} style={{ textDecoration: 'none' }}
                                                    className="nav-icon-container" to={relativeURL + SiteGUID}>
                                                    <div className="nav-icon">
                                                        <i className={"nav-link mh-2 " + icon} title={title} ></i>
                                                    </div>
                                                </NavLink> :

                                                <div key={title + relativeURL} style={{ textDecoration: 'none' }} className="nav-icon-container">
                                                    <div className="nav-icon nav-disabled">
                                                        <i className={"nav-link mh-2 " + icon} title="Contact your administrator for access to this feature."></i>
                                                    </div>
                                                </div>)}
                                        </div>
                                    </Fade> : <div></div>}
                            >
                                {subHeadingDetails ?
                                    // Suspend nav on click of subheading
                                    <div style={{ textDecoration: 'none', margin: 0, padding: 0 }} className="btn nav-icon-container">
                                        <div className="nav-icon">
                                            <i className={"nav-link ms-2 " + subHeadingDetails.icon} title={subHeadingDetails.title}></i>
                                        </div>
                                        <div className="nav-text">
                                            <Collapse in={!tabsCollapsed} dimension="width">
                                                <div onClick={_handleSubHeadingExpand}>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div >{subHeadingDetails.title}</div>
                                                        <i className={arrowDir} style={{ height: "15px" }}></i>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </div> :
                                    // Use first tab as navigation icon if no subheading specified
                                    (tabDetails[0].tabVisible ?
                                        <NavLink tag={Link} style={{ textDecoration: 'none' }}
                                            className="nav-icon-container" to={tabDetails[0].relativeURL + SiteGUID}>
                                            <div className="nav-icon">
                                                <i className={"nav-link ms-2 " + tabDetails[0].icon} title={tabDetails[0].title}></i>
                                            </div>
                                            <div className="nav-text">
                                                <Collapse in={!tabsCollapsed} dimension="width">
                                                    <div id={tabDetails[0].title + "-collapse-text"} >
                                                        {tabDetails[0].title}
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </NavLink> :

                                        <div style={{ textDecoration: 'none' }} className="nav-icon-container">
                                            <div className="nav-icon nav-disabled">
                                                <i className={"nav-link ms-2 " + tabDetails[0].icon} title="Contact your administrator for access to this feature."></i>
                                            </div>
                                            <div className="nav-text nav-disabled">
                                                <Collapse in={!tabsCollapsed} dimension="width">
                                                    <div id={tabDetails[0].title + "-collapse-text"} >
                                                        {tabDetails[0].title}
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>)
                                }
                            </OverlayTrigger>

                            {/* All tabs on sidebar expand */}
                            <Collapse in={showExtraTabs}>
                                <div>
                                    {tabDetails.map(({ tabVisible, relativeURL, title, icon }) => tabVisible ?
                                        <NavLink key={title + relativeURL} tag={Link} style={{ textDecoration: 'none' }}
                                            className="nav-icon-container" to={relativeURL + SiteGUID}>
                                            <div className="nav-icon">
                                                <i className={"nav-link ms-2 " + icon} title={title} ></i>
                                            </div>
                                            <div className="nav-text">
                                                <Collapse in={!tabsCollapsed} dimension="width">
                                                    <div id={title + "-collapse-text"}>
                                                        {title}
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </NavLink> :

                                        <div key={title + relativeURL} style={{ textDecoration: 'none' }} className="nav-icon-container">
                                            <div className="nav-icon nav-disabled">
                                                <i className={"nav-link ms-2 " + icon} title="Contact your administrator for access to this feature."></i>
                                            </div>
                                            <div className="nav-text nav-disabled">
                                                <Collapse in={!tabsCollapsed} dimension="width">
                                                    <div id={title + "-collapse-text"}>
                                                        {title}
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>)}
                                </div>
                            </Collapse>
                        </div>
                    </Fade>
                </NavItem>
            </div>
            :
            <></>

    )
}

export function Layout(props) {
    const [HomeTabVisible, setHomeTabVisible] = useState(false)
    const [AnalyticsTabVisible, setAnalyticsTabVisible] = useState(false)
    const [HealthSummaryTabVisible, setHealthSummaryTabVisible] = useState(false)
    const [HelpTabVisible, setHelpTabVisible] = useState(false)
    const [CostBreakdownTabVisible, setCostBreakdownTabVisible] = useState(false)
    const [DeepDiveTabVisible, setDeepDiveTabVisible] = useState(false)
    const [BillingTabVisible, setBillingTabVisible] = useState(false)
    const [AlarmsTabVisible, setAlarmsTabVisible] = useState(false)
    const [ReportsTabVisible, setReportsTabVisible] = useState(false)
    const [SignalsTabVisible, setSignalsTabVisible] = useState(false)
    const [DownloadsTabVisible, setDownloadsTabVisible] = useState(false)
    const [CalibrationsTabVisible, setCalibrationsTabVisible] = useState(false)
    const [ControlTabVisible, setControlTabVisible] = useState(false)
    const [ControlChainsTabVisible, setControlChainsTabVisible] = useState(false)
    const [FailSafeTabVisible, setFailSafeTabVisible] = useState(false)
    const [RoleManagementTabVisible, setRoleManagementTabVisible] = useState(false)
    const [SiteDetailsTabVisible, setSiteDetailsTabVisible] = useState(false)
    const [EdgeDiagnosticsVisible, setEdgeDiagnosticsVisible] = useState(true);
    const [SupportTicketsTabVisible, setSupportTicketsTabVisible] = useState(true);

    const [AlarmsPresent, setAlarmsPresent] = useState(false)
    const [mobileCollapsed, setMobileCollapsed] = useState(true)
    const [contacttext, setContactText] = useState('');
    const [messagesubmitted, setMessageSubmitted] = useState(false);
    const [tabsCollapsed, setTabsCollapsed] = useState(true)

    //Fetch tabs
    useEffect(() => {
        if (props.SiteGUID) {
            $.ajax({
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                type: 'POST',
                data: JSON.stringify({ "SiteGUID": props.SiteGUID }),
                url: '/api/NavBarAPI/Post',
                success: function (data) {
                    setHomeTabVisible(data.homeTabActive)
                    setAnalyticsTabVisible(data.analyticsTabActive)
                    setReportsTabVisible(data.reportsTabActive)
                    setSignalsTabVisible(data.signalSetupTabActive)
                    setCalibrationsTabVisible(data.calibrationTabActive)
                    setControlTabVisible(data.controlTabActive)
                    setControlChainsTabVisible(data.controlChainsTabActive) 
                    setFailSafeTabVisible(data.failSafesTabActive);
                    setRoleManagementTabVisible(true);
                    setAlarmsTabVisible(data.alarmsTabActive)
                    setDownloadsTabVisible(data.downloadsTabActive)
                    setSiteDetailsTabVisible(data.siteTabActive)
                    setCostBreakdownTabVisible(data.costBreakdownTabActive)
                    setDeepDiveTabVisible(data.deepDiveTabActive)
                    setBillingTabVisible(data.billingTabActive)
                    setHealthSummaryTabVisible(data.healthSummaryTabActive)
                    setHelpTabVisible(data.helpTabActive)
                    setAlarmsPresent(data.alarmsPresent)
                }
            });
        }
    }, [props.SiteGUID]);

    function SubmitMessage() {
        $.ajax({
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            type: 'POST',
            data: JSON.stringify({ "message": contacttext }),
            url: '/api/ContactAPI/ContactUs',
            success: function (response) {
                setMessageSubmitted(true)
            }
        });
    }

    function toggleTabsCollapsed() {
        setTabsCollapsed(!tabsCollapsed);
    }

    function toggleMobileCollapsed() {
        setMobileCollapsed(!mobileCollapsed);
        setTabsCollapsed(!mobileCollapsed);
    }

    return (
      <div style={{ position: "relative", minHeight: "100vh" }}>
        <div style={{ paddingBottom: "64px" }}>
          <NavMenu
            SiteGUID={props.SiteGUID}
            setSiteGUID={props.SiteGUIDSetter}
            mobileCollapse={toggleMobileCollapsed}
          />
          <Container fluid>
            <Row className="flex-nowrap" style={{ minHeight: "100vh" }}>
              <Col
                lg="auto"
                md="auto"
                xl="auto"
                sm="auto"
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <Collapse
                  className="d-sm-inline-flex navbar-light nav-vertical"
                  in={!mobileCollapsed}
                  navbar
                >
                  <ul
                    className="sticky-top navbar-nav flex-grow"
                    style={{ top: "105px", height: '100%' }}
                  >
                    {props.SiteGUID ? (
                      <NavItem>
                        <Fade appear={true} in={true}>
                          <div
                            style={{
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            className="nav-icon-container"
                            onClick={toggleTabsCollapsed}
                            title={tabsCollapsed ? "Open menu" : "Close menu"}
                          >
                            <div
                              className={
                                "nav-icon tab-expand-button" +
                                (tabsCollapsed ? "" : "-close")
                              }
                              style={{ paddingRight: "10px" }}
                            >
                              <span className="nav-link fa-stack fa-1x">
                                <i className="fas fa-square fa-stack-2x"></i>
                                <i className="fas fa-chevron-right fa-stack-1x fa-inverse"></i>
                              </span>
                            </div>
                          </div>
                        </Fade>
                      </NavItem>
                    ) : (
                      <></>
                    )}
                    <StreamwiseTab
                      SiteGUID={props.SiteGUID}
                      tabsCollapsed={tabsCollapsed}
                      tabDetails={[
                        {
                          tabVisible: HomeTabVisible,
                          relativeURL: "/",
                          title: "Home",
                          icon: "fa-2x fad fa-home fa-swap-opacity",
                        },
                      ]}
                    />
                    <StreamwiseTab
                      SiteGUID={props.SiteGUID}
                      tabsCollapsed={tabsCollapsed}
                      tabDetails={[
                        {
                          tabVisible: AnalyticsTabVisible,
                          relativeURL: "/Analytics/",
                          title: "Analytics",
                          icon: "fa-2x fad fa-tachometer-alt fa-swap-opacity",
                        },
                      ]}
                    />

                    <StreamwiseTab
                      SiteGUID={props.SiteGUID}
                      tabsCollapsed={tabsCollapsed}
                      tabDetails={[
                        {
                          tabVisible: ReportsTabVisible,
                          relativeURL: "/Reports/",
                          title: "Reports",
                          icon: "fa-2x fad fa-swap-opacity fa-file-invoice",
                        },
                      ]}
                    />

                    <StreamwiseTab
                      SiteGUID={props.SiteGUID}
                      tabsCollapsed={tabsCollapsed}
                      tabDetails={[
                        {
                          tabVisible: DownloadsTabVisible,
                          relativeURL: "/Downloads/",
                          title: "Data Download",
                          icon: "fa-2x fas fa-download",
                        },
                      ]}
                    />

                    <StreamwiseTab
                      SiteGUID={props.SiteGUID}
                      tabsCollapsed={tabsCollapsed}
                      tabDetails={[
                        {
                          tabVisible: CalibrationsTabVisible,
                          relativeURL: "/Calibration/",
                          title: "Calibration",
                          icon: "fa-2x fad fa-thermometer-half fa-swap-opacity",
                        },
                        {
                          tabVisible: ControlTabVisible,
                          relativeURL: "/Control/",
                          title: "Equipment Control",
                          icon: "fa-2x fas fa-sort-circle-down",
                        },
                        {
                          tabVisible: ControlChainsTabVisible,
                          relativeURL: "/ControlChains/index/",
                          title: "Control Chains",
                          icon: "fa-2x fas fa-link",
                        },
                      ]}
                      subHeadingDetails={{
                        tabVisible: true,
                        title: "Configuration",
                        icon: "fa-2x fal fa-sliders-h",
                      }}
                    />

                    <StreamwiseTab
                      SiteGUID={props.SiteGUID}
                      tabsCollapsed={tabsCollapsed}
                      tabDetails={[
                        {
                          tabVisible: DeepDiveTabVisible,
                          relativeURL: "/DeepDive/",
                          title: "Deep Dive",
                          icon: "fa-2x fad fa-microscope",
                        },
                        {
                          tabVisible: HealthSummaryTabVisible,
                          relativeURL: "/HealthSummary/",
                          title: "Health Summary",
                          icon: "fa-2x fad fa-heartbeat fa-swap-opacity",
                        },
                        {
                          tabVisible: SiteDetailsTabVisible,
                          relativeURL: "/SiteDetails/",
                          title: "Site Details",
                          icon: "fa-2x fas fa-fingerprint",
                        },
                      ]}
                      subHeadingDetails={{
                        tabVisible: true,
                        title: "Site",
                        icon: "fa-2x fal fa-search-location",
                      }}
                    />

                    <StreamwiseTab
                      SiteGUID={props.SiteGUID}
                      tabsCollapsed={tabsCollapsed}
                      tabDetails={[
                        {
                          tabVisible: SignalsTabVisible,
                          relativeURL: "/Signals/",
                          title: "Signal Setup",
                          icon: "fa-2x fas fa-wave-square",
                        },
                        {
                          tabVisible: RoleManagementTabVisible,
                          relativeURL: "/RoleManagement/",
                          title: "Role Management",
                          icon: "fa-2x fas fa-users",
                        },
                      ]}
                      subHeadingDetails={{
                        tabVisible: true,
                        title: "Settings",
                        icon: "fa-2x fal fa-cog",
                      }}
                    />

                                    <StreamwiseTab
                                        SiteGUID={props.SiteGUID}
                                        tabsCollapsed={tabsCollapsed}
                                        tabDetails={[
                                            {
                                                tabVisible: SupportTicketsTabVisible,
                                                relativeURL: "/SupportTickets/",
                                                title: "Support Tickets",
                                                icon: "fa-2x fas fa-headset"
                                            }
                                        ]}
                                    />

                    <StreamwiseTab
                      SiteGUID={props.SiteGUID}
                      tabsCollapsed={tabsCollapsed}
                      tabDetails={[
                        {
                          tabVisible: HelpTabVisible,
                          relativeURL: "/Help/",
                          title: "Help",
                          icon: "fa-2x fas fa-question-circle",
                        },
                      ]}
                    />

                    {/* <StreamwiseTab
                                        SiteGUID={props.SiteGUID}
                                        tabsCollapsed={tabsCollapsed}
                                        tabDetails={[
                                            { tabVisible: EdgeDiagnosticsVisible, relativeURL: "/EdgeDiagnostics/", title: "Edge Diagnostics", icon: "fa-2x fad fa-stethoscope" }
                                        ]}
                                    /> */}

                    {/*<StreamwiseTab
                                        tabVisible={BillingTabVisible} 
                                        SiteGUID={props.SiteGUID} 
                                        relativeURL={"/Billing/"}
                                        tabsCollapsed={tabsCollapsed}
                                        title="Billing"
                                        icon="fa-2x fad fa-swap-opacity fa-file-invoice-dollar"
                                    />*/}
                    {/*<StreamwiseTab
                                        tabVisible={AlarmsTabVisible}
                                        SiteGUID={props.SiteGUID}
                                        relativeURL={"/Alarms/"}
                                        tabsCollapsed={tabsCollapsed}
                                        title="Alarms"
                                        icon={"fa-2x fad fa-swap-opacity fa-bell" + (AlarmsPresent ? " text-warning" : "")}
                                    />*/}
                  </ul>
                </Collapse>
              </Col>
              <Col>
                <Container className="themed-container" fluid={true}>
                  {props.children}
                </Container>
              </Col>
            </Row>
          </Container>
        </div>
        <Chats SiteGUID={props.SiteGUID} />
        <footer className="footer border" style={{ marginTop: "64px" }}>
          <div className="container-fluid d-flex">
                &copy; 2022 - Streamwise D.I. -{" "}
                Portal version: {process.env.REACT_APP_VERSION} -{" "}
                <a href="\files\Privacy Policy Streamwisedi Pty Ltd.pdf">
                Privacy Policy
                </a>{" "}
                -{" "}
                <a href="\files\Streamwise D.I. 2020 Terms and Conditions.pdf">
                Terms & Conditions
                </a>{" "}
                -{" "}
                <OverlayTrigger
                trigger="click"
                placement="top"
                overlay={
                    <Popover className="m-3" style={{ maxWidth: "85vw" }}>
                    <div className="p-3">
                        <div className="form-group" style={{ width: "360px" }}>
                        <textarea
                            class="form-control"
                            placeholder="Enter message"
                            rows="4"
                            onChange={(e) => setContactText(e.target.value)}
                            value={contacttext}
                        ></textarea>
                        </div>
                        <div className="form-group">
                        {messagesubmitted ? (
                            "Your message has been received."
                        ) : (
                            <div
                            className="btn btn-primary text-light"
                            onClick={SubmitMessage}
                            >
                            Submit
                            </div>
                        )}
                        </div>
                    </div>
                    </Popover>
                }
                >
                <Link>Contact Us With Feedback/Requests</Link>
                </OverlayTrigger>
          </div>
        </footer>
        <NPSHandler />
      </div>
    );
}
