import { useState, useEffect, useRef } from 'react';
import { Button, Card, Form, InputGroup, Row, Col, OverlayTrigger, Tooltip, ButtonGroup, Collapse} from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import { Line } from 'react-chartjs-2'
import $ from 'jquery';
import moment from 'moment';

const AirCleaningManualLogCard = ({ data }) => {
    const convertToText = () => {
        if (data.Value == -1) {
            return "Off";
        }
        else if (data.Value == 1) {
            return "On";
        }
        else {
            return "Auto";
        }
    }

    return (
        <div className="border p-2">
            <Row>
                <Col>Duration (s): {data.Duration}</Col>
                <Col>Mode: {convertToText(data.Value)}</Col>
            </Row>
            <Row>
                <Col>Annotation: {data.Comments}</Col>
            </Row>

            <div className="d-flex justify-content-between">
                <div className="text-secondary text-left m-1" style={{ fontSize: '12px' }}>
                    {data.UserID}
                </div>
                <div className="text-secondary text-end m-1" style={{ fontSize: '12px' }}>
                    {moment.utc(data.ManualControlTime).local().format('LLL')}
                </div>
            </div>
        </div>)
};

const AirCleaningCard = ({ data, deviceID, $grid, newData }) => {
    const [comments, setComments] = useState("");
    const [sliderVal, setSliderVal] = useState(data.ManualOn);
    const [sliderColour, setSliderColour] = useState("primary");
    const [btnColour, setBtnColour] = useState("white");
    const [countdown, setCountdown] = useState(data.Time);
    const countRef = useRef(countdown);  // Need this as timeout doesnt use the current countdown state
    countRef.current = countdown;
    const [timeVal, setTimeVal] = useState(0);

    const [signalListOpen, setSignalListOpen] = useState(false);
    const [manualControlLog, setManualControlLog] = useState([]);
    const [manualControlLogOpen, setManualControlLogOpen] = useState(false);

    // Card expansion
    const [sidePanelShow, setSidePanelShow] = useState("d-none");
    const [cardExpand, setCardExpand] = useState("col-lg-4 col-md-6");
    const [arrowDir, setArrowDir] = useState("right");
    const [signalOptions, setSignalOptions] = useState(data.SignalObjects.map(item =>
        <option key={item.SignalID} value={item.SignalID}>{item.NiceName}</option>));

    // Chart data
    const [allTimestamps, setAllTimestamps] = useState([]);
    const [allData, setAllData] = useState({});
    const [selID1, setSelID1] = useState("");
    const [selID2, setSelID2] = useState("");
    const [selID3, setSelID3] = useState("");
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);

    const chartState1 = {
        labels: allTimestamps,
        datasets: [{
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: data1
        }]
    }
    const chartState2 = {
        labels: allTimestamps,
        datasets: [{
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: data2
        }]
    }
    const chartState3 = {
        labels: allTimestamps,
        datasets: [{
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: data3
        }]
    }
    const chartOptions = {
        scales: {
            x: {
                type: 'time'
            }
        },
        plugins: { legend: { display: false } },
        title: { display: false }
    }

    // Update chart data
    useEffect(() => {
        let curTime = new Date();
        let newDatapoints = { ...allData };
        let newTimestamps = [...allTimestamps];
        // Remove data points > 2 min
        var dt = (curTime - allTimestamps[0]) / 1000 / 60;
        if (dt >= 2) {
            for (var key in newDatapoints) {
                newDatapoints[key].slice(1);
            }
            newTimestamps.slice(1);
        }
        // Add new points
        for (var key in newData) {
            if (key in newDatapoints) {
                newDatapoints[key].push(newData[key]);
            } else {
                newDatapoints[key] = [newData[key]];
            }
        }
        newTimestamps.push(curTime);
        setAllData(newDatapoints);
        setAllTimestamps(newTimestamps);
        // Update the individual chart data
        setData1(newDatapoints[selID1]);
        setData2(newDatapoints[selID2]);
        setData3(newDatapoints[selID3]);
    }, [newData]);

    // Initialisation block for air cleaning already in progress and manual control logs
    useEffect(() => {
        // Init tile colour and timer if manual is already on
        console.log(data)
        _handleBtnClick(data.ManualOn);
        _handleSliderChange(data.ManualOn);
        setCountdown(data.Time);

        // Retrieve air cleaning control log
        $.ajax({
            type: "GET",
            url: "/api/ControlAPI/GetAirCleaningManualControl",
            data: { solenoidID: data.SolenoidID },
            success: response => {
                response = JSON.parse(response);
                console.log(response);
                setManualControlLog(response)
            }
        });

        setTimeout(function () {
            try {
                $grid.reloadItems();
                $grid.layout();
            }
            catch (exception) {
            }
        }, 1000);
    }, []);

    // Handle countdown timer
    useEffect(() => {
        if (countRef.current > 0) {
            const timer = setTimeout(() => setCountdown(countRef.current - 1), 1000);
            return () => { clearTimeout(timer) }
        } else {
            // Reset all toggle buttons
            setCountdown(0);
            _handleBtnClick(0);
            _handleSliderChange(0);
        }
    }, [countdown]);

    const toggleManualCleaning = () => {
        let dataObject = {
            deviceID: deviceID,
            solenoidID: data.SolenoidID,
            toggleCommand: sliderVal,
            time: timeVal * 1000,  // convert to ms for edge
            comments: comments
        };

        // Turn of if time is 0 or invalid
        if (timeVal <= 0) {
            dataObject.time = 100;
            dataObject.toggleCommand = 0;
        }

        setCountdown(dataObject.time / 1000);
        // Send command to edge device
        $.ajax({
            type: "GET",
            url: "/api/ControlAPI/ToggleManualAirCleaning",
            data: dataObject,
            success: (response) => {
                // Change visuals if toggling worked
                if (response == "success") {
                    _handleBtnClick(data.toggleCommand);
                }
            }
        });
    }

    const SignalListToggle = () => {
        setSignalListOpen(!signalListOpen);
        setManualControlLogOpen(false);
        callMasonary();
    };
    const ManualControlLogToggle = () => {
        setManualControlLogOpen(!manualControlLogOpen);
        setSignalListOpen(false);
        callMasonary();
    };

    const _handleBtnClick = (value) => {
        if (value < 0) { setBtnColour("red"); }
        else if (value > 0) { setBtnColour("lawngreen"); }
        else { setBtnColour("white"); }
    }

    const _handleSliderChange = (value) => {
        // Update colour
        if (value < 0) { setSliderColour('danger') }
        else if (value > 0) { setSliderColour('success') }
        else { setSliderColour('primary') }
        setSliderVal(value);
    }

    const _expandPanel = () => {
        if (sidePanelShow === "d-none") {
            setSidePanelShow("");
            setCardExpand("col-lg-8 col-md-12");
            setArrowDir("left");
        } else {
            setSidePanelShow("d-none");
            setCardExpand("col-lg-4 col-md-6");
            setArrowDir("right");
        }
        callMasonary();
    }

    const generateLabel = value => {
        if (value == -1) { return "Off"; }
        else if (value == 1) { return "On"; }
        else { return "Auto"; }
    }

    const callMasonary = () => {
        setTimeout(function () {
            try {
                $grid.layout();
            } catch (exception) { }
        }, 1000);
    }

    return (
        <div className={"grid-item p-0 flex " + cardExpand}>
            <Card className="m-2 shadow-sm" >
                {/* Solenoid Name and expand button */}
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <h5>{data.NiceName}</h5>
                    <i onClick={_expandPanel}
                        className={"fas fa-2x fa-angle-" + arrowDir}
                        style={{ cursor: 'pointer' }}></i>
                </Card.Header>
                <Card.Body className="p-2">
                    <Row>
                        <Col>
                            <div className="mb-2">
                                <Form.Control as='select' onChange={e => { setSelID1(e.target.value); callMasonary(); }} >
                                    <option value="">Select Affected Signal</option>
                                    {signalOptions}
                                </Form.Control>
                                {selID1 ? <Line data={chartState1} options={chartOptions} /> : <></>}
                            </div>

                            <div className="d-flex">
                                <InputGroup className="mt-2 justify-content-center align-items-center">
                                    <InputGroup.Text>{countdown + "s"}</InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        step={60}
                                        defaultValue={timeVal}
                                        onChange={e => setTimeVal(e.target.value)}></Form.Control>
                                    <InputGroup.Text>seconds</InputGroup.Text>
                                </InputGroup>

                                <div className="d-flex align-items-center">
                                    <RangeSlider
                                        value={sliderVal}
                                        min={-1}
                                        max={1}
                                        onChange={e => _handleSliderChange(e.target.value)}
                                        tooltipLabel={currentValue => generateLabel(currentValue)}
                                        tooltipPlacement='top'
                                        variant={sliderColour}
                                        className="ms-2 w-75" />

                                    <OverlayTrigger
                                        overlay={<Tooltip>Press to override automatic air cleaning regime for the chosen time</Tooltip>}>
                                        <Button
                                            onClick={toggleManualCleaning}>
                                            <div className="fas fa-2x fa-wind" style={{ color: btnColour }}></div>
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <Form.Control
                                className='mt-2'
                                as="textarea"
                                rows={1}
                                placeholder="Input any notes of the manual operation here."
                                onChange={e => setComments(e.target.value)} />
                            <hr />
                            <Row>
                                <Col>
                                    <ButtonGroup>
                                        <Button
                                            variant='secondary'
                                            className="btn"
                                            onClick={SignalListToggle}
                                            aria-controls={data.SolenoidID + "-signalList"}
                                            aria-expanded={signalListOpen}>
                                            Signals Cleaned <i class="fas fa-caret-down"></i>
                                        </Button>
                                        {/* Signals cleaned by solenoid - expandable */}
                                        <Button
                                            variant='secondary'
                                            className="btn"
                                            onClick={ManualControlLogToggle}
                                            aria-controls={data.SolenoidID + "-manualControlLog"}
                                            aria-expanded={manualControlLogOpen}>
                                            Manual Log <i class="fas fa-caret-down"></i>
                                        </Button>
                                    </ButtonGroup>
                                    {/* Manual Control Log */}
                                    <Collapse in={signalListOpen}>
                                        {data.SignalObjects.length > 0 ?
                                            <div className='mt-2' id={data.SolenoidID + "-signalList"}>
                                                {data.SignalObjects.map(item =>
                                                    <div className="border p-2">
                                                        <Row>
                                                            <Col>{item.NiceName}</Col>
                                                        </Row>
                                                    </div>)}
                                            </div>
                                            : <div className="border mt-2 p-2">
                                                No manual toggles to date.
                                            </div>
                                        }
                                    </Collapse>


                                    {/* Manual Control Log */}
                                    <Collapse in={manualControlLogOpen}>
                                        {manualControlLog.length > 0 ?
                                            <div className='mt-2' id={data.SolenoidID + "-manualControlLog"}>
                                                {manualControlLog.map(item => <AirCleaningManualLogCard data={item} />)}
                                            </div>
                                            : <div className="border mt-2 p-2">
                                                No manual toggles to date.
                                            </div>
                                        }
                                    </Collapse>
                                </Col>
                            </Row>
                        </Col>

                        {/* Extra graphs */}
                        <Col className={sidePanelShow} >
                            <div className="mb-2">
                                <Form.Control as='select' onChange={e => { setSelID2(e.target.value); callMasonary(); }}>
                                    <option value="">Select Affected Signal</option>
                                    {signalOptions}
                                </Form.Control>
                                {selID2 ? <Line data={chartState2} options={chartOptions} /> : <></>}
                            </div>
                            <div>
                                <Form.Control as='select' onChange={e => { setSelID3(e.target.value); callMasonary(); }}>
                                    <option value="">Select Affected Signal</option>
                                    {signalOptions}
                                </Form.Control>
                                {selID3 ? <Line data={chartState3} options={chartOptions} /> : <></>}
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
}

export default AirCleaningCard;