import React, { useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import WidgetSettingsContainer from "./WidgetSettingsContainer"
import $ from "jquery";
import { Spinner } from 'react-bootstrap'
import { AuthContext } from '../Analytics';

const ConfigContext = React.createContext();

const DragHandle = sortableHandle(() =>
    <div className="btn d-flex align-items-center btn-group-vertical ml-auto" style={{ height: "100%", border: "1px solid rgb(230 230 230)" }}>
        <i className="fal fa-angle-up" style={{ color: '#002c4e78', display: 'block' }}></i>
        <i className="fal fa-grip-lines" style={{ display: 'block' }}></i>
        <i className="fal fa-angle-down" style={{ color: '#002c4e78', display: 'block' }}></i>
    </div>
);

const defaultWidgetInput = {
    "InsightWidgetInputID": null,
    "InsightWidgetID": null,
    "InputSignalID": null,
    "InputPosition": null,
}

function Widget(props) {
    const [config, setConfig] = useState(null);
    const [signals, setSignals] = useState([]);
    const [value, setValue] = useState("-");
    const [noData, setNoData] = useState(true);
    const [loading, setLoading] = useState(true);
    const [canEdit, setCanEdit] = useState(false);
    const { auth } = useContext(AuthContext);

    useEffect(() => {
        var url = '/api/AnalyticsAPI/GetWidget?id=' + props.widgetID;
        fetch(url).then(response => response.json())
            .then(data => {
                getSignals(data.SiteGUID)
                updateConfig(data)
                var acID = data.AccessControlID;
                var url2 = '/api/AuthorizationAPI/GetAccessLevel?accessControlID=' + acID;
                fetch(url2).then(response => response.json())
                    .then(data => {
                        setCanEdit(data == 2);
                    });
            });

    }, [props.widgetID]);

    const getSignals = (SiteGUID) => {
        var url = '/api/LineChartAPI/GetSiteSignals/'

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(SiteGUID)
        }).then(response => response.json())
            .then(data => {
                var siteSignals = data.value;
                setSignals(data.value);
            });
    }

    function updateWidgetName(newWidgetName) {
        var newConfig = JSON.parse(JSON.stringify(config));
        newConfig.InsightWidgetName = newWidgetName;
        setConfig(newConfig);
    }

    function updateUnits(newUnits) {
        var newConfig = JSON.parse(JSON.stringify(config));
        newConfig.Units = newUnits;
        setConfig(newConfig);
    }

    function updateMetric(newMetric) {
        var newConfig = JSON.parse(JSON.stringify(config));
        newConfig.EquationID = newMetric;
        setConfig(newConfig);
    }

    function updateSignal0(selectedSignalID) {
        var newConfig = JSON.parse(JSON.stringify(config));
        // Replace old input where InputPosition == 0 with new signal 
        if (newConfig.InsightWidgetInputs.length > 0) {
            for (var i = 0; i < newConfig.InsightWidgetInputs.length; i++) {
                if (newConfig.InsightWidgetInputs[i].InputPosition == 0) {
                    newConfig.InsightWidgetInputs[i].InputSignalID = selectedSignalID;
                    newConfig.InsightWidgetInputs[i].InsightWidgetInputID = 0;
                    setConfig(newConfig)
                    return
                }
            }
        }

        // Only reachable if an input with InputPosition == 0 doesn't exist, push new
        var newInsightWidgetInput = {}
        newInsightWidgetInput.InputPosition = 0;
        newInsightWidgetInput.InputSignalID = selectedSignalID;
        newInsightWidgetInput.InsightWidgetInputID = 0;
        newInsightWidgetInput.InsightWidgetID = newConfig.InsightWidgetID;
        newConfig.InsightWidgetInputs.push(newInsightWidgetInput);
        setConfig(newConfig);
    }

    function updateSignal1(selectedSignalID) {
        var newConfig = JSON.parse(JSON.stringify(config));
        // Replace old input where InputPosition == 1 with new signal 
        if (newConfig.InsightWidgetInputs.length > 0) {
            for (var i = 0; i < newConfig.InsightWidgetInputs.length; i++) {
                if (newConfig.InsightWidgetInputs[i].InputPosition == 1) {
                    newConfig.InsightWidgetInputs[i].InputSignalID = selectedSignalID;
                    newConfig.InsightWidgetInputs[i].InsightWidgetInputID = 0;
                    setConfig(newConfig)
                    return
                }
            }
        }

        // Only reachable if an input with InputPosition == 1 doesn't exist, push new
        var newInsightWidgetInput = {}
        newInsightWidgetInput.InputPosition = 1;
        newInsightWidgetInput.InputSignalID = selectedSignalID;
        newInsightWidgetInput.InsightWidgetInputID = 0;
        newInsightWidgetInput.InsightWidgetID = newConfig.InsightWidgetID;
        newConfig.InsightWidgetInputs.push(newInsightWidgetInput);
        setConfig(newConfig);

    }

    function updateSignal2(selectedSignalID) {
        var newConfig = JSON.parse(JSON.stringify(config));
        // Replace old input where InputPosition == 2 with new signal 
        if (newConfig.InsightWidgetInputs.length > 0) {
            for (var i = 0; i < newConfig.InsightWidgetInputs.length; i++) {
                if (newConfig.InsightWidgetInputs[i].InputPosition == 2) {
                    newConfig.InsightWidgetInputs[i].InputSignalID = selectedSignalID;
                    newConfig.InsightWidgetInputs[i].InsightWidgetInputID = 0;
                    setConfig(newConfig)
                    return
                }
            }
        }

        // Only reachable if an input with InputPosition == 2 doesn't exist, push new
        var newInsightWidgetInput = {}
        newInsightWidgetInput.InputPosition = 2;
        newInsightWidgetInput.InputSignalID = selectedSignalID;
        newInsightWidgetInput.InsightWidgetInputID = 0;
        newInsightWidgetInput.InsightWidgetID = newConfig.InsightWidgetID;
        newConfig.InsightWidgetInputs.push(newInsightWidgetInput);
        setConfig(newConfig);

    }

    function updateConfig(data) {
        setConfig(data);
        $.ajax({
            url: '/../api/InsightWidgetValueAPI/' + props.widgetID + '&' + encodeURIComponent(props.start) + '&' + encodeURIComponent(props.end),
            type: 'get',
            success: function (response) {
                response = JSON.parse(response);
                var nodata = (response === null);
                if (nodata) {
                    setValue("-");
                    setNoData(true);
                } else {
                    setValue(response.Value);
                    setNoData(false);
                }
                setLoading(false);
            },
            error: function (){
                setValue("--");
                setNoData(false);
                setLoading(false);
            }
        });
    }

    return (
      <ConfigContext.Provider
        value={{
          config,
          updateWidgetName,
          updateUnits,
          updateMetric,
          updateSignal0,
          updateSignal1,
          updateSignal2,
          updateConfig,
          setLoading,
        }}
      >
        <div style={{ width: "360px", display: "inline-block" }}>
          <div class="card border-primary text-center m-3">
            {loading ? (
              <>
                <div
                  class="card-header p-0 text-blue text-center h5 widget-header"
                  title=" "
                >
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    {props.widgetName}
                  </div>
                </div>
                <div class="card-body text-dark text-center p-1 d-flex widget-body">
                  <Spinner
                    animation="border"
                    role="status"
                    className="position-absolute"
                    style={{
                      top: "calc(50% + 1rem)",
                      left: "calc(50% - 1rem)",
                    }}
                  />
                </div>
              </>
            ) : noData ? (
              <>
                <div
                  class="card-header p-0 text-blue text-center h5 widget-header"
                  title=" "
                >
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    {config.InsightWidgetName}
                  </div>
                </div>
                <div class="card-body text-dark text-center p-1 d-flex widget-body">
                  <div class="mx-auto no-data-text mt-3" />
                </div>
              </>
            ) : (
              <>
                <div
                  class="card-header p-0 text-blue text-center h5 widget-header"
                  title={config.InsightWidgetName}
                  style={{ display: "flex" }}
                >
                  {auth == 2 ? (
                    <div
                      className="btn-group-vertical"
                      style={{ marginRight: "auto" }}
                    >
                      <div style={{ height: "100%", cursor: "grab" }}>
                        <DragHandle />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    {config.InsightWidgetName}
                  </div>
                  {canEdit ? (
                    <div
                      className="btn-group-vertical"
                      style={{ marginLeft: "auto" }}
                    >
                      <WidgetSettingsContainer
                        widgetID={config.InsightWidgetID}
                        ready={config !== null}
                        signals={signals.map((s) => (
                          <option value={s.value}>{s.label}</option>
                        ))}
                        onremove={props.onremove}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div class="card-body text-dark text-center p-0 d-flex widget-body">
                  <div
                    id={config.InsightWidgetID + "-Widget"}
                    class="col mr-auto"
                  >
                    <div class="d-flex flex-row d-flex justify-content-center">
                      <div class="pt-3 h5 d-flex justify-content-center">
                        <span
                          id={config.InsightWidgetID + "-Label"}
                          class="pb-2"
                        >
                          {value}
                        </span>
                        <span
                          id={config.InsightWidgetID + "-Unit"}
                          class="h6 pt-1"
                        >
                          {config.Units}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </ConfigContext.Provider>
    );
}

export { ConfigContext };
export default Widget