import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { InputGroup, FormControl, Button, Accordion } from "react-bootstrap";
import $ from 'jquery';

export function Help(props) {
    const { id } = useParams();
    const [usecasevideos, setUseCaseVideos] = useState([])
    useEffect(() => props.SiteGUIDSetter(id), [id]);

    useEffect(() => {
        $.ajax({
            url: '/api/HelpAPI/GetUseCaseVideos',
            type: 'post',
            success: function (response) {
                setUseCaseVideos(response);
            }
        });
    }, []);

    return (
        <Accordion>
            {usecasevideos.map((item) =>
                <Accordion.Item eventKey={item.useCaseVideoID}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body>
                        <iframe width="100%" height="500px" src={item.youTubeLink} title={item.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Accordion.Body>
                </Accordion.Item>
            )}
            {props.auth > 1 ? <Accordion.Item eventKey="0">
                <Accordion.Header>Add Support Video</Accordion.Header>
                <Accordion.Body>
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder="YouTube embed link"
                            aria-label="YouTube embed link"
                            aria-describedby="basic-addon2"
                        />
                        <Button variant="outline-secondary" id="button-addon2">
                            Submit
                        </Button>
                    </InputGroup>
                </Accordion.Body>
            </Accordion.Item> :
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Request Support Video</Accordion.Header>
                    <Accordion.Body>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Support details"
                                aria-label="Support details"
                                aria-describedby="basic-addon2"
                            />
                            <Button variant="outline-secondary" id="button-addon2">
                                Submit
                            </Button>
                        </InputGroup>
                    </Accordion.Body>
                </Accordion.Item>}
        </Accordion>);
};
