import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import $ from 'jquery';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export function SitePicker(props) {
    const [dropdownOpen, setdropdownOpen] = useState(false)
    const [sites, setSites] = useState([])
    const [CurrentSiteName, setCurrentSiteName] = useState("Select site")
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        $.ajax({
            url: '/api/AuthorizationAPI/GetSiteNames',
            type: 'get',
            success: function (response) {
                setSites(response)
                props.setNSites(response.length)

                if (response.length == 1) {
                    props.SiteChangeHandler(response[0].siteGUID)

                    if (!location.pathname.endsWith(response[0].siteGUID) && !Number(props.SiteGUID)) {
                        history.push("/" + response[0].siteGUID);
                    }
                }
            }
        });
    }, [])

    useEffect(() => {
        if (!Number(props.SiteGUID)) {
            if (sites.length > 0 && props.SiteGUID !== undefined && props.SiteGUID !== null && props.SiteGUID !== "null" && props.SiteGUID !== "undefined") {
                try {
                    setCurrentSiteName(sites.filter(obj => {
                        return obj.siteGUID === props.SiteGUID

                    })[0].siteName)
                }
                catch (exception) {
                    console.error(exception)
                }
            }
        }
    }, [props.SiteGUID, sites]);

    function SiteChange(event) {
        if (!Number(event.target.value)) {
            props.SiteChangeHandler(event.target.value)
            setCurrentSiteName(sites.filter(obj => {
                return obj.siteGUID === event.target.value

            })[0].siteName)
        }
    }

    if (sites.length == 0) {
        return (
            <div>
            </div>
        );
    }
    else if (sites.length == 1) {
        return (
            <div className="me-3">
                {CurrentSiteName}
            </div>
        );
    }
    else {
        var rendersites = [];
        for (var i = 0; i < sites.length; i++) {
            rendersites.push(<DropdownItem key={sites[i].siteGUID} onClick={SiteChange} value={sites[i].siteGUID}>{sites[i].siteName}</DropdownItem>)
        }
        return (
            <Dropdown isOpen={dropdownOpen} toggle={() => setdropdownOpen(!dropdownOpen)} className="me-3">
                <DropdownToggle caret outline>
                    {CurrentSiteName !== undefined ? CurrentSiteName : "Select site"}
                </DropdownToggle>
                <DropdownMenu>
                    {rendersites}
                </DropdownMenu>
            </Dropdown>
        );
    }
}