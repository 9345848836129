import { useEffect, useState } from "react";
import { Line } from 'react-chartjs-2';
import { Row, Col, Button, ButtonGroup, Card, Form, Modal, Collapse } from "react-bootstrap";
import moment from 'moment';

const SubmitTotaliserModal = ({ show, hide, intercept, stage, submitCalibration }) => {
    return (
        <Modal show={show} onHide={hide}>
            <Modal.Header closeButton>
                <Modal.Title>Submit Totaliser Calibration</Modal.Title>
            </Modal.Header>
            {stage === 0 &&
                <Modal.Body>
                    Are you sure want to submit the following totaliser calibration:
                    <Row class="mb-2">
                        <Form.Group className="col-md-6">
                            <Form.Label><strong>Intercept:</strong> {intercept}</Form.Label>
                        </Form.Group>
                    </Row>
                </Modal.Body>
            }
            {stage === 1 &&
                <Modal.Body>
                    Submitting...
                    <img style={{ height: '24px', width: '24px', marginLeft: '10px' }} src="/lib/ajax-loader.gif" />
                </Modal.Body>
            }
            {stage === 2 &&
                <Modal.Body>
                    <div>Calibration successfully submitted!</div>
                </Modal.Body>
            }
            <Modal.Footer>
                <Button variant="secondary" onClick={hide}>
                    Close
                </Button>
                {stage === 0 &&
                    <Button variant="primary" onClick={submitCalibration}>
                        Submit
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

const TotaliserCard = ({ data, deviceID, latestValues, $grid }) => {
    const [datapoints, setDatapoints] = useState([]);
    const [intercept, setIntercept] = useState();
    const [logs, setLogs] = useState([]);
    const [showLogs, setShowLogs] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalStage, setModalStage] = useState(0);
    const [visibleItemCount, setVisibleItemCount] = useState(5);

    const chartState = {
        //labels: timestamps,
        datasets: [{
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: datapoints
        }]
    };
    const chartOptions = {
        scales: {
            x: { type: 'time' },
            y: {
                title: {
                    display: true
                }
            }
        },
        plugins: { legend: { display: false } },
        title: { display: false }
    }

    const _handleSubmit = () => {
        setModalStage(1);
        fetch("/api/CalibrationsAPI/SubmitTotaliserCalibration/", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                signalID: data.signalID,
                deviceID: deviceID,
                intercept: Number(intercept)
            })
        }).then(res => {
            if (res.ok) { setModalStage(2) }
        })
    }

    useEffect(() => {
        fetch(`/api/CalibrationsAPI/GetCalibrationLogTotaliser/?signalID=${data.signalID}`)
            .then(res => res.json())
            .then(logEntries => {
                setLogs(logEntries)

                if ($grid) {
                    try {
                        $grid.layout();
                    }
                    catch (exception) {
                        console.log(exception);
                    }
                }
            })
    }, [])

    useEffect(() => {
        if (latestValues) {
            let curTime = new Date();
            let newDatapoints = [...datapoints];
            // Remove datapoints > 2 min
            var dt = (curTime - datapoints[0]) / 1000 / 60;
            if (dt >= 2) {
                newDatapoints = newDatapoints.slice(1);
            }
            newDatapoints.push({ x: curTime, y: latestValues[data.signalID] });
            setDatapoints(newDatapoints);
        }
    }, [latestValues]);

    return (
        <div className="grid-item col-lg-4 col-md-6 p-0 flex">
            <Card className="m-2 shadow-sm">
                <Card.Header>
                    <h5>{data.niceName}</h5>
                    <p className="m-0" id={`${data.signalID}-value`}>
                        {latestValues && data.signalID in latestValues ? latestValues[data.signalID] : "-"}
                    </p>
                </Card.Header>

                <Card.Body className="p-3">
                    <Line data={chartState} options={chartOptions} />
                    <hr />
                    <Row>
                        <Form.Group className="col-md-6">
                            <Form.Label>New Totalised Flow</Form.Label>
                            <Form.Control as='input' type='number' value={intercept} onChange={e => setIntercept(e.target.value)} />
                        </Form.Group>

                        <div className="mt-2">
                            <Button onClick={() => setShowModal(true)}>
                                Submit calibration
                            </Button>

                            {logs.length > 0 ?
                                <Button variant="secondary" className="ms-1 fas fa-caret-down" onClick={() => setShowLogs(!showLogs)}></Button> :
                                <div className="ms-1">No calibrations recorded</div>
                            }
                            <SubmitTotaliserModal
                                show={showModal}
                                hide={() => {
                                    setShowModal(false);
                                    setModalStage(0);
                                }}
                                intercept={intercept}
                                stage={modalStage}
                                submitCalibration={_handleSubmit}
                            />
                        </div>
                    </Row>

                    {logs.length > 0 &&
                        <Collapse in={showLogs}>
                            <div className="mt-1">
                                {logs.map((item, index) => (
                                    <div key={"totaliser-calib-" + index} style={{ display: index < visibleItemCount ? "block" : "none" }}>
                                        <div key={"totaliserLog-" + index} className="border p-2">
                                            <Row>
                                                <Col>New Totalised Flow: {item.TotaliserValue.Flow}</Col>
                                            </Row>

                                            <div className="d-flex justify-content-between">
                                                <div className="text-secondary text-left m-1" style={{ fontSize: '12px' }}>
                                                    {item.Calibration.UserID}
                                                </div>
                                                <div class="text-secondary text-end m-1" style={{ fontSize: '12px' }}>
                                                    {moment.utc(item.Calibration.CalibrationTime).local().format('LLL')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {visibleItemCount < logs.length && (
                                    <Button onClick={() => setVisibleItemCount(visibleItemCount + 1)} style={{ marginTop: "10px" }}>
                                        Load More
                                    </Button>
                                )}
                            </div>
                        </Collapse>
                    }
                </Card.Body>
            </Card>
        </div>
    );
}

export default TotaliserCard;