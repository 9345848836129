import React, { memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Handle } from "react-flow-renderer";
import "../styles.scss";
import { StreamwiseNodeData } from "../../types";

const PORT_POSITIONS = [17.5, 132.5];
const PORT_LABELS = ["COM1", "COM2"];

export default memo(
  ({ id, data }: { id: string; data: StreamwiseNodeData }) => {
    return (
      <div style={{ width: data.width, height: data.height }}>
        <div style={{ height: "20px" }} className="expansionButtonContainer">
          <div className="infoButton">
            <FontAwesomeIcon icon={faCircleInfo} />
          </div>
          <div
            className="trashButton"
            onClick={() => {
              if (data.onDelete) data.onDelete(id);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        </div>
        <div>Digital I/O module</div>
        <div className="handleLabel" style={{ left: PORT_POSITIONS[0] }}>
          {PORT_LABELS[0]}
        </div>
        <div className="handleLabel" style={{ left: PORT_POSITIONS[1] }}>
          {PORT_LABELS[1]}
        </div>
        <Handle
          type="target"
          position="left"
          id={PORT_LABELS[0]}
          style={{ top: 43, background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={false}
        />
        <Handle
          type="source"
          position="right"
          id={PORT_LABELS[1]}
          style={{ top: 43, background: "#555" }}
          isConnectable={false}
        />
      </div>
    );
  }
);
