
import BarChart from "../waterwerx-bar-chart";
import $ from 'jquery';
import moment from 'moment';

const InsightBarChart = (function () {
    var charts = new Array();

    const createChartModel = function (data) {
        var chartModel = {};

        chartModel.signalNiceName = data.OutputName;
        chartModel.ColorID = data.ColorID;

        return chartModel;
    };

    const createChartConfig = function (data) {
        const chartID = data.ChartID;
        var labels = [];
        //20, 168, 222
        var chartDatasets = [];
        var i = 0;
        const bgcolorsarray = ['rgba(20, 168, 222, 0.3)', 'rgba(160,82,45, 0.3)', 'rgba(148, 75, 163, 0.3)', 'rgba(6, 148, 22, 0.3)', 'rgba(0, 44, 79, 0.3)', 'rgba(244, 121, 32, 0.3)', 'rgba(16, 24, 32, 0.3)'];
        const colorsarray = ['rgba(20, 168, 222, 0.8)', 'rgba(160,82,45, 0.8)', 'rgba(148, 75, 163, 0.8)', 'rgba(6, 148, 22, 0.8)', 'rgba(0, 44, 79, 0.8)', 'rgba(244, 121, 32, 0.8)', 'rgba(16, 24, 32, 0.8)'];

        for (i = 0; i < data.barChartInputs.length; i++) {
            const chartModel = createChartModel(data.barChartInputs[i]);


            var plotData = [];
            var j;
            for (j = 0; j < data.barChartInputs[i].values.length; j++) {
                plotData.push(data.barChartInputs[i].values[j]);
                labels.push(moment(moment.utc(data.barChartInputs[i].dates[j]).toDate()).local().format('YYYY-MM-DD'));
            }

            const signalNiceName = chartModel.signalNiceName;

            var PointColors = [];
            var k;
            for (k = 0; k < plotData.length; k++) {
                PointColors.push(colorsarray[chartModel.ColorID]);

            }

            chartDatasets.push({
                label: signalNiceName,
                data: plotData,
                fill: false,
                borderWidth: 1,
                backgroundColor: bgcolorsarray[chartModel.ColorID],
                borderColor: colorsarray[chartModel.ColorID],
                pointBorderColor: PointColors,
                weightedAverage: chartModel.weightedAverage
            });
        }

        const chartConfig = {
            chartId: chartID,
            chartLabels: labels,
            chartDataSets: chartDatasets,
            chartYAxisIdOverride: data.YAxisTitle
        };
        return chartConfig;
    };

    const clearChart = function () {
        var i;
        for (i = 0; i < charts.length; i++) {
            if (charts[i]) {
                charts[i].destroy();
            }
        }
    }

    const initChart = function (ChartID, start, end, onLoad) {
        $.ajax({
            url: '/../api/InsightBarChartAPI/',
            data: {BarChartID: ChartID, StartTime: start, EndTime: end, TimeZoneOffsetMinutes: -moment().utcOffset()},
            type: 'get',
            success: function (response) {
                console.log("getting")
                console.log(response);
                response = JSON.parse(response);


                const noData = !response || response.barChartInputs.length === 0;
                const chartConfig = createChartConfig(response);

                charts.push(BarChart.create(chartConfig, noData));
                onLoad();
            }
        });
    };

    return {
        init: initChart,
        clear: clearChart
    };
}());

export default InsightBarChart;