import { useEffect, useState } from 'react';
import { StreamwiseControlLinkData, LEQControlParameters, LEQLogs } from '../types';
import './styles.scss'
import { Button, Modal, Accordion, InputGroup, FormControl, Form, Spinner, Table} from 'react-bootstrap';
import { ControlChartContainer } from '../ControlChartContainer';
import { LineChart } from '../ControlCharts';
import { useFetchTelemetry } from '../../CustomHooks/useFetchTelemetry';

export function LEQModal(props) {
    const [controlLinkData, setControlLinkData] = useState<StreamwiseControlLinkData>();
    const [tuneParameters, setTuneParameters] = useState<LEQControlParameters>();
    const [saving, setSaving] = useState(false);
    const [logsArray, setLogs] = useState<LEQLogs[]>([]);

   
    // Control chart data
    const [inputSignalIDs, setInputSignalIDs] = useState<string[]>([]);
    const [outputSignalIDs, setOutputSignalIDs] = useState<string[]>([]);
    const [inputData, inputMin, inputMax] = useFetchTelemetry(inputSignalIDs, 1, props.show, props.deviceID);
    const [outputData, outputMin, outputMax] = useFetchTelemetry(outputSignalIDs, 1, props.show, props.deviceID);



    const saveControlLinkSettings = () => {
        props.getData(props.id).then(data => {
            const newData = { ...data, controlLinkData: controlLinkData, leq: tuneParameters }
            props.saveSettings(props.id, newData);
            props.submitControlLink(newData);
            props.onHide();
        })
    }

    const updateVals = (nums: string) => {
        if (nums.length > 0) {
            if (nums.length > 1 && nums[0] == '-') {
                return parseFloat(nums);
            }
            else if (nums[0] != '-') {
                return parseFloat(nums);
            }
        }

    }
    
    // Handle Modal open and close
    useEffect(() => {
        if (props.show) {
            props.getData(props.id).then(data => {
                setControlLinkData(data.controlLinkData);
                setTuneParameters(data.leq);
                setInputSignalIDs([data.controlLinkData.inputBlock]);
                setOutputSignalIDs([data.controlLinkData.signalID]);

                var d = new Date();
                var offset = (d.getTimezoneOffset() * -1) / 60;
                //  Get Calibration cost
                fetch("/api/ControlChainsAPI/GetLogDataLEQ/", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({offset: offset, id:data.controlLinkData.signalID}),
                })
                .then((response) => response.json())
                .then((logs: LEQLogs[]) => {
                    setLogs(logs);
                });
            });
        }
    }, [props.show]);

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-leq"
            centered
            size='lg'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-leq">
                    Linear Equation Control Link
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Control Link Information</Accordion.Header>
                        <Accordion.Body>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Filter Signal: </InputGroup.Text>
                                <Form.Select value={controlLinkData?.filterSignal} onChange={(v) => { setControlLinkData({ ...controlLinkData, filterSignal: v.target.value }); }}>
                                    <option value="0">Select Signal</option>
                                    {props.signals.map(d => <option key={d.id + "key"} value={d.id}>{d.nicename}</option>)}
                                </Form.Select>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Lower Filter Threshold: </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={controlLinkData?.lowerFilterThreshold}
                                    type="number"
                                    onChange={(v) => { setControlLinkData({ ...controlLinkData, lowerFilterThreshold: updateVals(v.target.value) }); }}>
                                </FormControl>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Upper Filter Threshold: </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={controlLinkData?.upperFilterThreshold}
                                    type="number"
                                    onChange={(v) => { setControlLinkData({ ...controlLinkData, upperFilterThreshold: updateVals(v.target.value) }); }}>
                                </FormControl>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Scaler Signal: </InputGroup.Text>
                                <Form.Select value={controlLinkData?.scalerSignal} onChange={(v) => { setControlLinkData({ ...controlLinkData, scalerSignal: v.target.value }); }}>
                                    <option value="0">Select Signal</option>
                                    {props.signals.map(d => <option key={d.id + "key"} value={d.id}>{d.nicename}</option>)}
                                </Form.Select>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Update Frequency (s): </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={controlLinkData?.updateFrequency}
                                    type="number"
                                    onChange={(v) => { setControlLinkData({ ...controlLinkData, updateFrequency: parseInt(v.target.value) }); }}>
                                </FormControl>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Control Link Name: </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={controlLinkData?.nicename}
                                    onChange={(v) => { setControlLinkData({ ...controlLinkData, nicename: v.target.value }); }}>
                                </FormControl>
                            </InputGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Tune Control Link</Accordion.Header>
                        <Accordion.Body>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Gradient: </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={tuneParameters?.Gradient}
                                    type="number"
                                    onChange={(v) => { setTuneParameters({ ...tuneParameters, Gradient: updateVals(v.target.value) }); }}>
                                </FormControl>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Intercept: </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={tuneParameters?.Intercept}
                                    type="number"
                                    onChange={(v) => { setTuneParameters({ ...tuneParameters, Intercept: updateVals(v.target.value) }); }}>
                                </FormControl>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Minimum: </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={tuneParameters?.Minimum}
                                    type="number"
                                    onChange={(v) => { setTuneParameters({ ...tuneParameters, Minimum: updateVals(v.target.value) }); }}>
                                </FormControl>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Maximum: </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={tuneParameters?.Maximum}
                                    type="number"
                                    onChange={(v) => { setTuneParameters({ ...tuneParameters, Maximum: updateVals(v.target.value) }); }}>
                                </FormControl>
                            </InputGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Linear Equation control Logs</Accordion.Header>
                        <Accordion.Body>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>User Name</th>
                                        <th>Gradient</th>
                                        <th>Intercept</th>
                                        <th>Min</th>
                                        <th>Max</th>
                                        <th>Time</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {logsArray.map((item) => (
                                        <tr key={item.UserID}>
                                            {Object.values(item).map((val) => (
                                                <td>{val}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br />
                <div style={{ height: '100%', minHeight: '400px', width: '100%' }}>
                    {controlLinkData?.inputBlock !== undefined
                        &&
                        controlLinkData?.signalID !== undefined
                        &&
                        controlLinkData?.inputBlock.length > 0
                        &&
                        controlLinkData?.signalID.length > 0
                        &&
                        props.deviceID !== undefined
                        &&
                        <ControlChartContainer
                        chart={<LineChart
                            inputData={inputData}
                            outputData={outputData} />}
                        inputData={inputData}
                        outputData={outputData} />
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
              {saving ? 
                <Button variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {' '}
                  <span>Saving...</span>
                </Button>
                : 
                <Button onClick={() => {
                    setSaving(true);
                    saveControlLinkSettings();
                }}>Save</Button>
              }
            </Modal.Footer>
        </Modal>
    )
}