import React, { useState, useEffect } from 'react';
import { Collapse } from 'react-bootstrap';
import LineChartContainer from '../LineCharts/LineChartContainer';

const LineChartModal = ({ show, siteGUID }) => {
    return (
        <Collapse
            in={show}
        >
            <div >
                <div className="d-flex flex-wrap">
                    <div style={{width: "100%"}}>
                        <LineChartContainer
                            siteGUID={siteGUID}
                            linecharttype={2}
                            auth={2}
                        />
                    </div>
                </div>
            </div>
        </Collapse>
    )
}

export default LineChartModal;