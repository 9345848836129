import React, { useState, useEffect } from 'react';
import { Form, Modal, Button, ButtonGroup } from 'react-bootstrap';
import $ from 'jquery'
import HelpTooltip from '../HelpTooltip';

const CreateFailsafeModal = props => {
    // Type constants
    const [sensors, setSensors] = useState([]);
    const [actuators, setActuators] = useState([]);
    const [devices, setDevices] = useState([]);

    // Selected states
    const [selDevice, setSelDevice] = useState(props.selected.DeviceID);
    const [selSignal, setSelSignal] = useState(props.selected.SignalID);
    const [selTrigger, setSelTrigger] = useState(props.selected.TriggerID);
    const [selFailSafe, setSelFailSafe] = useState(props.selected.FailSafeID);
    const [selTerminal, setSelTerminal] = useState(props.selected.TerminalID);
    const [checkScaled, setCheckScaled] = useState(props.selected.ConstScale);
    const [constVal, setConstVal] = useState(props.selected.ConstVal);
    const [instVal, setInstVal] = useState(props.selected.InstVal);

    // Block visible variables
    const [constBlock, setConstBlock] = useState("none");
    const [instBlock, setInstBlock] = useState("none");

    // Get device ids
    useEffect(() => {
        $.ajax({
            url: "/api/SharedCommandsAPI/GetDevices",
            type: "GET",
            data: { siteGUID: props.siteGUID },
            success: res => {
                let result = JSON.parse(res);
                setDevices(result.map(item => <option key={item.DeviceID} value={item.DeviceID}>{item.NiceName}</option>));
            }
        });
    }, []);

    // Trigger on prop update
    useEffect(() => {
        setSelDevice(props.selected.DeviceID);
        setSelSignal(props.selected.SignalID);
        setSelTrigger(props.selected.TriggerID);
        setSelFailSafe(props.selected.FailSafeID);
        setSelTerminal(props.selected.TerminalID);
        setCheckScaled(props.selected.ConstScale);
        setConstVal(props.selected.ConstVal);
        setInstVal(props.selected.InstVal);
    }, [props.selected]);

    // Get device ids
    useEffect(() => {
        $.ajax({
            url: "/api/FailSafesAPI/GetRawSignals",
            type: "GET",
            data: { deviceID: selDevice },
            success: res => {
                let result = JSON.parse(res);
                setSensors(result.Sensors.map(item =>
                    <option key={item.SignalID} value={item.SignalID}>{item.NiceName}</option>));
                setActuators(result.Actuators.map(item =>
                    <option key={item.SignalID} value={item.SignalID}>{item.NiceName}</option>));
            }
        })
    }, [selDevice]);
    // Update view blocks
    useEffect(() => {
        let constView = selFailSafe != 1 ? "none" : "inline-flex";
        setConstBlock(constView);
        let instView = selTrigger != 2 ? "none" : "inline-flex";
        setInstBlock(instView);
    }, [selFailSafe, selTrigger]);

    // Triggers the post request to save edits and creates
    const _handleChanges = () => {
        let settings = {
            FailSafeID: props.selected.FailSafeGUID,
            DeviceID: selDevice,
            SignalID: selSignal,
            TriggerType: Number(selTrigger),
            FailsafeType: Number(selFailSafe),
            TerminalID: selTerminal,
            ConstValue: Number(constVal),
            ConstScale: checkScaled,
            InstValue: Number(instVal)
        }
        console.log(settings);
        $.ajax({
            url: '/api/FailSafesAPI/SaveFailSafe',
            type: 'POST',
            data: JSON.stringify({
                "FailSafeID": props.selected.FailSafeGUID,
                "DeviceID": selDevice,
                "SignalID": selSignal,
                "TriggerType": selTrigger,
                "FailsafeType": selFailSafe,
                "TerminalID": selTerminal,
                "ConstValue": constVal,
                "ConstScale": checkScaled,
                "InstValue": instVal
            }),
            contentType: "application/json",
            success: props.create
        });
    }

    return (
        <Modal show={props.showState} onHide={() => props.closeSelf(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Create new fail safe</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Group>
                    <Form.Label>Device</Form.Label>
                    <Form.Control
                        as="select"
                        value={selDevice}
                        onChange={e => setSelDevice(e.target.value)}>
                        <option>Select</option>
                        {devices}
                    </Form.Control>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Signal to monitor</Form.Label>
                    <Form.Control as="select" value={selSignal} onChange={e => setSelSignal(e.target.value)}>
                        <option>Select</option>
                        {sensors}
                    </Form.Control>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Fail-safe trigger</Form.Label>
                    <Form.Control as="select" value={selTrigger} onChange={e => setSelTrigger(e.target.value)}>
                        <option>Select</option>
                        <option value={0} title="Values are static for over 15 seconds">Values frozen</option>
                        <option value={1} title="Values outside the min/max bound set in Calibrations">Threshold Violation</option>
                        <option value={2} title="Values oscillate with a higher frequency than a set point">Unstable signal</option>
                        <option value={3} title="Pump operates at a high intensity">Pump Anomaly</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group style={{ display: instBlock }}>
                    <Form.Label style={{ marginRight: 10 }}>Oscillation Freq (Hz)</Form.Label>
                    <Form.Control
                        type='number'
                        defaultValue={instVal}
                        onChange={e => setInstVal(e.target.value)}
                        style={{ width: 70, marginRight: 20 }} />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Fail-safe type</Form.Label>
                    <Form.Control as='select' value={selFailSafe} onChange={e => setSelFailSafe(e.target.value)}>
                        <option>Select</option>
                        <option value={0}>Turn off</option>
                        <option value={1}>Constant value</option>
                        <option value={2}>Moving Average (past hour)</option>
                        <option value={3} title="Uses the value as at the time from last week">Intelligent (AI)</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group style={{ display: constBlock }}>
                        <Form.Label style={{ marginRight: 10 }}>Constant Rate (%)</Form.Label>
                        <Form.Control
                            type='number'
                            defaultValue={constVal}
                            style={{ width: 70, marginRight: 20 }}
                        onChange={e => setConstVal(e.target.value)} />
                        <HelpTooltip helptext="Adjust pump value based on the scaler signal set during creation" />
                        <Form.Check defaultChecked={checkScaled} type="checkbox"
                            label="Scale value"
                            onChange={() => setCheckScaled(!checkScaled)} />
                </Form.Group>

                <Form.Group>
                    <Form.Label className="control-label">Actuator to apply fail-safe</Form.Label>
                    <Form.Control as='select' value={selTerminal} onChange={e => setSelTerminal(e.target.value)} >
                        <option>Select</option>
                        {actuators}
                    </Form.Control>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button variant="secondary" onClick={() => props.closeSelf(false)}>Close</Button>
                    <Button variant="primary" onClick={_handleChanges}>Save</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
}

export default CreateFailsafeModal;