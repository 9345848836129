import { Button, Modal } from "react-bootstrap";
import { PIDModal } from "./Nodes/PIDModal";
import { LEQModal } from "./Nodes/LEQModal";
import { SourceModal } from "./Nodes/SourceModal";
import { PumpModal } from "./Nodes/PumpModal";
import { BlendedControlModal } from "./Nodes/BlendedControlModal";



function SaveConfirmModal(props) {
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirmation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Are you sure you want to save this configuration?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    props.submitControlChain()
                    props.onHide();
                }}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

function AddControlLinkModal(props) {
    const addNewLink = (controlType: string) => {
        console.log(props.controlChainID);
        props.addControlLink(props.sourceId, props.targetId, controlType, props.controlChainID);
        props.onHide();
    }
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Select Control Type
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Button onClick={() => {
                    addNewLink('linearEquation');
                }}>Linear Equation</Button>
                <Button onClick={() => {
                    addNewLink('pid');
                }}>PID</Button>
                <Button onClick={() => {
                    addNewLink('blended');
                }}>Blended Control</Button>
            </Modal.Body>
        </Modal>
    )
}

export function ModalManager(props) {
    return (
        <>
            <SaveConfirmModal
                show={props.modal === 'save-modal'}
                onHide={props.close}
                submitControlChain={props.submitControlChain}
            />
            <AddControlLinkModal
                show={props.modal === 'addLink-modal'}
                onHide={props.close}
                addControlLink={props.addControlLink}
                sourceId={props.sourceID}
                targetId={props.targetID}
                controlChainID={props.controlChainID}
            />
            <SourceModal
                show={props.modal === 'source-modal'}
                onHide={props.close}
                saveSettings={props.saveSettings}
                getData={props.getData}
                signals={props.signals}
                id={props.nodeID}
            />
            <PumpModal
                show={props.modal === 'pump-modal'}
                onHide={props.close}
                saveSettings={props.saveSettings}
                getData={props.getData}
                signals={props.signals}
                id={props.nodeID}
                deviceID={props.deviceID}
                submitControlLink={props.submitControlLink}
            />
            <PIDModal
                show={props.modal === 'pid-modal'}
                onHide={props.close}
                saveSettings={props.saveSettings}
                getData={props.getData}
                signals={props.signals}
                id={props.nodeID}
                deviceID={props.deviceID}
                submitControlLink={props.submitControlLink}
            />
            <LEQModal
                show={props.modal === 'leq-modal'}
                onHide={props.close}
                saveSettings={props.saveSettings}
                submitControlChain={props.submitControlChain}
                submitControlLink={props.submitControlLink}
                getData={props.getData}
                signals={props.signals}
                id={props.nodeID}
                deviceID={props.deviceID}
            />
            <BlendedControlModal
                show={props.modal === 'blended-modal'}
                onHide={props.close}
                saveSettings={props.saveSettings}
                deleteLastBlendedBranch={props.deleteLastBlendedBranch}
                getData={props.getData}
                signals={props.signals}
                id={props.nodeID}
                deviceID={props.deviceID}
                submitControlLink={props.submitControlLink}
            />
        </>
    )
}


