import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import { StreamwiseNodeData } from '../types';
import './styles.scss'
import { Button } from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash, faEllipsis} from '@fortawesome/free-solid-svg-icons'

const PORT_POSITIONS = [20, 60]
const PORT_LABELS = ['DATA', 'AIR']

export default memo(
  ({
    id,
    data,
  }: {
    id: string;
    data: StreamwiseNodeData;
  }) => {
    return (
      <>
        <div className="nodeText">{data.name}</div>
        <Handle
          type="target"
          position="left"
          id="INPUT"
          style={{ top: 35, background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={false}
        />
        <Handle
          type="source"
          position="right"
          id="OUTPUT"
          style={{ top: 35, background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={false}
        />
        <div style={{height: '20px'}}>
        <div className="infoButton" onClick={() => {if (data.onEdit) data.onEdit(id)}}>
            <FontAwesomeIcon icon={faEllipsis} />
          </div>
          <div className="trashButton" onClick={() => {
            if (data.onDelete) {
              data.onDelete(id)
            } 
          }}>
            <FontAwesomeIcon icon={faTrash} />
          </div>
        </div>
      </>
    );
  }
);