import { useState, useEffect } from 'react';
import { Button, Modal, Row, Col, Container, InputGroup, FormControl, Table, Form, Alert } from 'react-bootstrap';
import MaintenanceNotificationTableItem from './MaintenanceNotificationTableItem'

function MaintenanceAlarmSetupModal(props) {
    const [maintenanceConfig, setMaintenanceConfig] = useState(undefined);
    const [notificationsConfig, setNotificationsConfig] = useState([]);
    const [users, setUsers] = useState([]);
    const [isError, setisError] = useState(false);

    useEffect(() => {
        // Get existing settings
        var assetPayload = {
            MaintenanceType: props.MaintenanceType,
            AssetID: props.AssetID
        }
        console.log(assetPayload);
        fetch('/api/MaintenanceAPI/GetMaintenanceReminders/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(assetPayload)
        }).then(response => response.json())
            .then(data => {
                console.log({ data });
                setMaintenanceConfig(data.maintenanceConfig);
                setNotificationsConfig(data.notificationsConfig);
            });

        fetch('/api/MaintenanceAPI/GetUsers/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(assetPayload)
        })
            .then(response => {
                if (response.ok) { return response.json() }
            }).then(data => {
                if (data) { setUsers(data) }
            });
    }, []);

    const handleConfirm = (maintenanceRemindersPayload) => {
        var url = '/api/MaintenanceAPI/SetMaintenanceReminders/';
        console.log({ maintenanceRemindersPayload })
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(maintenanceRemindersPayload)
        }).then(response => response.status)
            .then(status => {
                props.onHide()
            });
    }

    const payload = {
        maintenanceConfig: maintenanceConfig,
        notificationsConfig: notificationsConfig,
    }

    const handleChangeFreq = (days) => {
        var newMaintenanceConfig = JSON.parse(JSON.stringify(maintenanceConfig));
        newMaintenanceConfig.MaintenanceFrequency = days;
        setMaintenanceConfig(newMaintenanceConfig);
    }

    const handleChangeReminderMessage = (msg) => {
        var newMaintenanceConfig = JSON.parse(JSON.stringify(maintenanceConfig));
        newMaintenanceConfig.MessageString = msg;
        setMaintenanceConfig(newMaintenanceConfig);
    }

    const handleChangeCompletedMessage = (msg) => {
        var newMaintenanceConfig = JSON.parse(JSON.stringify(maintenanceConfig));
        newMaintenanceConfig.ClearedString = msg;
        setMaintenanceConfig(newMaintenanceConfig);
    }

    const handleAddUser = (userID) => {
        var newNotification = {
            MaintenanceNotificationID: 0,
            MaintenanceConfigID: maintenanceConfig.MaintenanceConfigID,
            UserID: userID,
            User: users.find(u => u.Id == userID),
            SMS: false,
            Email: false,
            Set: false,
            Reset: false,
            PushNotification: false,
        }

        var newNotifications = JSON.parse(JSON.stringify(notificationsConfig));
        newNotifications.push(newNotification);
        setNotificationsConfig(newNotifications);
    }

    const handleRemoveUser = (userID) => {
        var newNotifications = JSON.parse(JSON.stringify(notificationsConfig));
        newNotifications.splice(newNotifications.findIndex(n => n.UserID == userID), 1);
        setNotificationsConfig(newNotifications);
    }

    const handleChangeSet = (checked, userID) => {
        var newNotifications = JSON.parse(JSON.stringify(notificationsConfig));
        newNotifications.find(n => n.UserID == userID).Set = checked;
        setNotificationsConfig(newNotifications);
    }

    const handleChangeReset = (checked, userID) => {
        var newNotifications = JSON.parse(JSON.stringify(notificationsConfig));
        newNotifications.find(n => n.UserID == userID).Reset = checked;
        setNotificationsConfig(newNotifications);
    }

    const handleChangeEmail = (checked, userID) => {
        var newNotifications = JSON.parse(JSON.stringify(notificationsConfig));
        newNotifications.find(n => n.UserID == userID).Email = checked;
        setNotificationsConfig(newNotifications);
    }

    const handleChangeSMS = (checked, userID) => {
        var newNotifications = JSON.parse(JSON.stringify(notificationsConfig));
        newNotifications.find(n => n.UserID == userID).SMS = checked;
        setNotificationsConfig(newNotifications);
    }

    const handleChangePush = (checked, userID) => {
        var newNotifications = JSON.parse(JSON.stringify(notificationsConfig));
        newNotifications.find(n => n.UserID == userID).PushNotification = checked;
        setNotificationsConfig(newNotifications);
    }

    const handleEmailHelper = (maintenanceRemindersPayload) => {
        // if length is 0 then delete all notification
        if (maintenanceRemindersPayload.notificationsConfig.length == 0) {
            handleConfirm(maintenanceRemindersPayload);
        }
        else {
            // Error handling if no option is selected
            if (maintenanceRemindersPayload.notificationsConfig[0].Email || maintenanceRemindersPayload.notificationsConfig[0].SMS || maintenanceRemindersPayload.notificationsConfig[0].PushNotification) {
                if (maintenanceRemindersPayload.notificationsConfig[0].Set || maintenanceRemindersPayload.notificationsConfig[0].Reset) {
                    setisError(false);
                    handleConfirm(maintenanceRemindersPayload);
                }
            }
            else { setisError(true) }
        }
    }


    var modalHeader = "Maintenance reminder setup";
    if (props.MaintenanceType == 0) {
        modalHeader = "Sensor replacement reminder setup";
    } else if (props.MaintenanceType == 1) {
        modalHeader = "Sensor calibration reminder setup";
    }

    var maintenanceTypeText = "maintenance event";
    if (props.MaintenanceType == 0) {
        maintenanceTypeText = "sensor replacement";
    } else if (props.MaintenanceType == 1) {
        maintenanceTypeText = "sensor calibration";
    }


    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {modalHeader}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant='primary'>
                    <b>Reminder</b> notifications are sent out 2 weeks, 1 week and 1 day before the {maintenanceTypeText} is due. From the due date, reminders will continue to be sent on a daily basis until the maintenance is logged. A <b>Completed</b> notification is sent out when the maintenance has been logged.
                </Alert>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Number of days between {maintenanceTypeText}s: </InputGroup.Text>
                    {maintenanceConfig &&
                        <FormControl defaultValue={maintenanceConfig.MaintenanceFrequency || 0}
                            type='number'
                            aria-label={"Number of days between" + maintenanceTypeText + "s"}
                            onChange={(e) => handleChangeFreq(e.target.value)}
                            min="0"
                        />
                    }
                    <InputGroup.Text>days</InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Reminder message: </InputGroup.Text>
                    {maintenanceConfig &&
                        <FormControl defaultValue={maintenanceConfig.MessageString || ""}
                            placeholder="Maintenance reminder message"
                            aria-label="Reminder message"
                            onChange={(e) => handleChangeReminderMessage(e.target.value)}
                        />
                    }
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Completed message: </InputGroup.Text>
                    {maintenanceConfig &&
                        <FormControl defaultValue={maintenanceConfig.ClearedString || ""}
                            placeholder="Maintenance complete message"
                            aria-label="Completed message"
                            onChange={(e) => handleChangeCompletedMessage(e.target.value)}
                        />
                    }
                </InputGroup>
                {notificationsConfig != undefined &&
                    <Table striped bordered hover size="sm" style={{ textAlign: "center" }}>
                        <thead>
                            <tr>
                                <th></th>
                                <th colSpan="2" style={{color: isError ? 'red' : 'black' }}>Notification type</th>
                                <th colSpan="3" style={{ color: isError ? 'red' : 'black' }}>Communication method</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>User</th>
                                <th>Reminders</th>
                                <th>Completed</th>
                                <th>Email</th>
                                <th>SMS</th>
                                <th>App</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {notificationsConfig.map(c =>
                                <MaintenanceNotificationTableItem
                                    key={`note-${c.MaintenanceNotificationID}`}
                                    config={c}
                                    onchangeset={handleChangeSet}
                                    onchangereset={handleChangeReset}
                                    onchangeemail={handleChangeEmail}
                                    onchangesms={handleChangeSMS}
                                    onchangeapp={handleChangePush}
                                    onremove={handleRemoveUser}
                                />)
                            }
                            <tr>
                                <th colSpan="1">
                                    Add a user:
                                </th>
                                <th colSpan="6">
                                    <Form.Control as="select" size="sm" custom onChange={(e) => handleAddUser(e.target.value)}>
                                        <option disabled selected value> -- Select a user to add -- </option>
                                        {users.map(u =>
                                            <option key={u.Id} value={u.Id} disabled={notificationsConfig.some(n => n.UserID == u.Id)}>
                                                {u.Name}
                                            </option>)
                                        }
                                    </Form.Control>
                                </th>
                            </tr>
                        </tbody>
                    </Table>
                }
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={props.onHide}>Cancel</Button>
                        </Col>
                        <Col>
                            <Button className="float-right" variant="success" onClick={() => handleEmailHelper(payload)}>Confirm</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}


function MaintenanceAlarmSetup(props) {
    const [btnText, setBtnText] = useState("");
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (props.MaintenanceType == 0) {
            setBtnText("Sensor replacement reminders");
        } else if (props.MaintenanceType == 1) {
            setBtnText("Sensor calibration reminders");
        } else {
            setBtnText("Maintenance reminders");
        }
    }, [props.type])

    return (
        <>
            <Button onClick={() => setShowModal(true)} className={props.className}>{btnText}</Button>
            <MaintenanceAlarmSetupModal
                {...props}
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                }}
            />
        </>

    )
}

export default MaintenanceAlarmSetup;