import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Masonry from 'masonry-layout';
import { Link } from 'react-router-dom';
import Gauge from './GaugeWrapper';
import { Button, Modal, Spinner } from 'react-bootstrap';
import Cookies from 'cookies-js';
import NoAccessPage from './NoAccessPage';
//import '@brainhubeu/react-carousel/lib/style.css'
import YouTubeVideo from './YouTubeVideo';
import HelpButton from './HelpButton';

//Show info modal if updated since last visit
var DeepDiveTabVersion = Cookies.get('DeepDiveTabVersion');
var CurrentDeepDiveTabVersion = 1;

class MixingTankCard extends React.Component {
    constructor(props) {
        super(props);

        if (this.props.equipment.MixingTank != null) {
            this.state = {
                MixingFactor: props.equipment.MixingTank.MixingFactor,
                TankVolume: props.equipment.MixingTank.TankVolume,
                MixingTank: props.equipment.MixingTank,
                SiteSensors: props.equipment.SiteSensors,
                issues: []
            };
        }
        else {
            this.state = {
                MixingTank: props.equipment.MixingTank
            };
        }
    }


    render() {

        var actionableitems = [];
        var i;
        for (i = 0; i < this.props.equipment.Sweeps.length; i++) {
            var j;
            for (j = 0; j < this.props.equipment.Sweeps[i].SweepActions.length; j++) {
                if (moment.utc(this.props.equipment.Sweeps[i].SweepActions[j].actionDate).isBefore(moment().add(1, 'month'))) {
                    actionableitems.push(<li>{this.props.equipment.Sweeps[i].SweepActions[j].ActionSummary}</li>);
                }
            }
        }

        if (this.props.equipment.MixingTank == null) {
            return (
                <div className="card-body card-body row m-0 p-3">
                    {this.props.equipment.NiceName}
                </div>
            );
        }
        else {
            return (
                <div className="card-body col p-3">
                    {this.props.SAPConnected ? <IssuesSection equipment={this.props.equipment} /> : ""}
                    <ul className="w-100 m-2">
                        <li>Avoidable issues in the last 30 days: {this.state.issues.length}</li>
                        {actionableitems.length > 0 ? <li>Other actionable items</li> : ''}
                        <ul>
                            {actionableitems}
                        </ul>
                    </ul>
                </div>
            );
        }
    }
}

class DAFCard extends React.Component {
    constructor(props) {
        super(props);

        if (this.props.equipment.DAFChamber != null) {
            this.state = {
                DAFChamber: props.equipment.DAFChamber,
                lastmonthTSSefficiency: undefined,
                monthtodateTSSefficiency: undefined,
                nextmonthTSSefficiency: undefined,
                issues: [],
                carouselindex: 0
            };
        }
        else {
            this.state = {
                DAFChamber: props.equipment.DAFChamber
            };
        }
    }


    componentDidMount() {
        var thiscard = this;

        var fd = {
            "DAFChamberID": this.state.DAFChamber.DAFChamberID,
            "Case": 0
        };

        $.ajax({
            url: '/api/DigitalTwinAPI/GetTSSRemovalEfficiency/',
            type: 'get',
            data: fd,
            success: function (response) {
                response = JSON.parse(response);
                console.log({ lastmonthTSSefficiency: response });
                thiscard.setState({ lastmonthTSSefficiency: response });
            }
        });

        fd = {
            "DAFChamberID": this.state.DAFChamber.DAFChamberID,
            "Case": 1
        };

        $.ajax({
            url: '/api/DigitalTwinAPI/GetTSSRemovalEfficiency/',
            type: 'get',
            data: fd,
            success: function (response) {
                response = JSON.parse(response);
                console.log({ monthtodateTSSefficiency: response });
                thiscard.setState({ monthtodateTSSefficiency: response });
            }
        });

        fd = {
            "DAFChamberID": this.state.DAFChamber.DAFChamberID,
            "Case": 2
        };

        $.ajax({
            url: '/api/DigitalTwinAPI/GetTSSRemovalEfficiency/',
            type: 'get',
            data: fd,
            success: function (response) {
                response = JSON.parse(response);
                console.log({ nextmonthTSSefficiency: response });
                thiscard.setState({ nextmonthTSSefficiency: response });
            }
        });
    }

    setCarouselIndex = (event) => {
        this.setState({ carouselindex: event })

        this.GridUpdateHandler();
    }

    GridUpdateHandler = () => {
        setTimeout(function () {
            $grid.layout();

        }, 1000);
    }

    render() {
        var actionableitems = [];
        var i, j;
        for (i = 0; i < this.props.equipment.Sweeps.length; i++) {
            var j;
            for (j = 0; j < this.props.equipment.Sweeps[i].SweepActions.length; j++) {
                if (moment.utc(this.props.equipment.Sweeps[i].SweepActions[j].actionDate).isBefore(moment().add(1, 'month'))) {
                    actionableitems.push(<li key={j + "action" + this.state.DAFChamber.DAFChamberID}>{this.props.equipment.Sweeps[i].SweepActions[j].ActionSummary}</li>);
                }
            }
        }

        return (
            <div className="card-body row d-flex justify-content-center">
                <div className="m-2 p-2 text-center">{this.state.DAFChamber.ContextualNote}</div>
                <div className="container m-1 row">
                    <div className="container" align="center">TSS Removal Efficiency</div>
                    <div className="col-6">
                        <div className="d-flex justify-content-center">Last Month</div>
                        {this.state.monthtodateTSSefficiency == undefined || this.state.lastmonthTSSefficiency == undefined ?
                            <div className="d-flex justify-content-center">
                                <Spinner
                                    animation="border"
                                    role="status"
                                    className="position-absolute"
                                    style={{
                                        top: "calc(50% + 1rem)",
                                        left: "calc(50% - 1rem)",
                                    }}
                                />
                            </div>
                            :
                            <Gauge
                                value={this.state.lastmonthTSSefficiency}
                                minValue={0}
                                maxValue={100}
                                animationSpeed={32}
                                options={{
                                    angle: -0.2, // The span of the gauge arc
                                    lineWidth: 0.2, // The line thickness
                                    radiusScale: 1, // Relative radius
                                    pointer: {
                                        length: 0.6, // // Relative to gauge radius
                                        strokeWidth: 0.035, // The thickness
                                    },
                                    limitMax: false,     // If false, max value increases automatically if value > maxValue
                                    limitMin: false,     // If true, the min value of the gauge will be fixed
                                    colorStart: '#6FADCF',   // Colors
                                    colorStop: '#8FC0DA',    // just experiment with them
                                    strokeColor: '#E0E0E0',  // to see which ones work best for you
                                    generateGradient: true,
                                    highDpiSupport: true,     // High resolution support

                                }}

                                // any other props are passed through to the canvas element
                                className='deltagaugecanvas'
                            />
                        }
                    </div>
                    <div className="col-6">
                        <div className="d-flex justify-content-center">Month To Date</div>
                        {this.state.monthtodateTSSefficiency == undefined || this.state.lastmonthTSSefficiency == undefined ?
                            <div className="d-flex justify-content-center">
                                <Spinner
                                    animation="border"
                                    role="status"
                                    className="position-absolute"
                                    style={{
                                        top: "calc(50% + 1rem)",
                                        left: "calc(50% - 1rem)",
                                    }}
                                />
                            </div>
                            :
                            <Gauge
                                value={this.state.monthtodateTSSefficiency}
                                minValue={0}
                                maxValue={100}
                                animationSpeed={32}
                                options={{
                                    angle: -0.2, // The span of the gauge arc
                                    lineWidth: 0.2, // The line thickness
                                    radiusScale: 1, // Relative radius
                                    pointer: {
                                        length: 0.6, // // Relative to gauge radius
                                        strokeWidth: 0.035, // The thickness
                                    },
                                    limitMax: false,     // If false, max value increases automatically if value > maxValue
                                    limitMin: false,     // If true, the min value of the gauge will be fixed
                                    colorStart: '#6FADCF',   // Colors
                                    colorStop: '#8FC0DA',    // just experiment with them
                                    strokeColor: '#E0E0E0',  // to see which ones work best for you
                                    generateGradient: true,
                                    highDpiSupport: true,     // High resolution support

                                }}

                                // any other props are passed through to the canvas element
                                className='deltagaugecanvas'
                            />
                        }
                    </div>
                </div>
            </div>
        );

    }
}

class Issue extends React.Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            issues: [],
            workordermodal: false,
            workorderdetails: '',
            workorderpriority: '10',
            workordertype: '1',
            workorderstartdate: moment().format('YYYY-MM-DD'),
            workorderenddate: moment().add(1, 'days').format('YYYY-MM-DD'),
        }
    }

    WorkOrderModalHandler = () => {
        this.setState({ workordermodal: true });
    }

    WorkOrderDetailsChangeHandler = (event) => {
        this.setState({ workorderdetails: event.target.value });
    }
    WorkOrderStartDateChangeHandler = (event) => {
        this.setState({ workorderstartdate: event.target.value });
    }
    WorkOrderEndDateChangeHandler = (event) => {
        this.setState({ workorderenddate: event.target.value });
    }
    WorkOrderPriorityChangeHandler = (event) => {
        console.log(event.target.value)
        this.setState({ workorderpriority: event.target.value });
    }
    WorkOrderTypeChangeHandler = (event) => {
        this.setState({ workordertype: event.target.value });
    }

    handleClose = () => {
        this.setState({ workordermodal: false });
    }

    WorkOrderSubmissionHandler = () => {
        this.setState({ workordermodal: false });

        $.ajax({
            url: '/api/SAPAPI/SubmitWorkOrder',
            type: 'post',
            data: JSON.stringify({
                AlarmTriggerID: this.props.issue.AlarmTriggerID,
                WorkOrderDetails: this.state.workorderdetails,
                WorkOrderStartDate: this.state.workorderstartdate,
                WorkOrderEndDate: this.state.workorderenddate,
                WorkOrderPriority: this.state.workorderpriority,
                WorkOrderType: this.state.workordertype,
            }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
                console.log(response);

                $grid.masonry('layout');
            }.bind(this),
        });
    }


    render() {
        return (<div class="row m-1">
            <div>
                <div>{this.props.issue.ManualAlarm != null ? this.props.issue.ManualAlarm.AlarmName : this.props.issue.AutomaticAlarm.Alarm.MessageString}</div>
                <div class="text-secondary" style={{ 'font-size': '12px' }}>{moment(this.props.issue.Time).local().format('LLL')}</div>
            </div>
            <div class="ms-auto btn btn-primary" onClick={this.WorkOrderModalHandler}>Create work order</div>
            <Modal
                show={this.state.workordermodal}
                onHide={this.handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create work order
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="form-group">
                        Please enter work order description: <textarea class="form-control" onChange={this.WorkOrderDetailsChangeHandler} value={this.state.workorderdetails}></textarea>
                    </div>
                    <div class="form-group">
                        Start date: <input type="date" class="form-control" onChange={this.WorkOrderStartDateChangeHandler} value={this.state.workorderstartdate} />
                    </div>
                    <div class="form-group">
                        End date: <input type="date" class="form-control" onChange={this.WorkOrderEndDateChangeHandler} value={this.state.workorderenddate} />
                    </div>
                    <div class="form-group">
                        Priority: <select class="form-control" onChange={this.WorkOrderPriorityChangeHandler} value={this.state.workorderpriority}>
                            <option value={5}>Low</option>
                            <option value={10}>Medium</option>
                            <option value={15}>High</option>
                            <option value={20}>Very high</option>
                            <option value={25}>Emergency</option>
                        </select>
                    </div>
                    <div class="form-group">
                        Type: <select class="form-control" onChange={this.WorkOrderTypeChangeHandler} value={this.state.workordertype}>
                            <option value={1}>Breakdown</option>
                            <option value={2}>Inspections</option>
                            <option value={3}>Installation</option>
                            <option value={4}>Planned</option>
                            <option value={5}>Disposal</option>
                            <option value={6}>Operations</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <a class="btn btn-primary text-light" onClick={this.WorkOrderSubmissionHandler}>Submit</a>
                    </div>
                </Modal.Body>
            </Modal>
        </div>)
    }
}

class IssuesSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = { issues: [] }
    }


    componentDidMount() {
        var thiscard = this;

        var fd = {
            "EquipmentID": Number(this.props.equipment.EquipmentID)
        };

        $.ajax({
            url: '/api/DigitalTwinAPI/GetIssues/',
            type: 'get',
            data: fd,
            success: function (response) {
                console.log(response);
                response = JSON.parse(response);
                thiscard.setState({ issues: response });
                //$grid.masonry('layout');
            }
        });

    }

    componentDidUpdate() {
        //$grid.masonry('layout');
    }

    render() {
        var issues = [];
        var i;
        for (i = 0; i < this.state.issues.length; i++) {
            issues.push(<Issue issue={this.state.issues[i]} />)
        }
        return (
            <div>
                <div>Number of issues in the past month: {this.state.issues.length}</div>
                {issues}
            </div>)
    }
}

class PhCorrectionTankCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = { issues: [] };
    }

    render() {
        return (<></>);
        if (this.props.equipment.PhCorrectionTank == null) {
            return (
                <div className="card-body card-body row m-0 p-3">
                    {this.props.equipment.NiceName}
                    {this.props.SAPConnected ? <IssuesSection equipment={this.props.equipment} /> : ""}
                </div>
            );
        }
        else {
            return (
                <div className="card-body col p-3">
                    {this.props.equipment.NiceName}
                    {this.props.SAPConnected ? <IssuesSection equipment={this.props.equipment} /> : ""}
                </div>
            );
        }
    }
}

class SludgeDecanterCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = { issues: [] };
    }

    render() {

        return (<></>);

        if (this.props.equipment.SludgeDecanter == null) {
            return (
                <div className="card-body card-body row m-0 p-3">
                    {this.props.equipment.NiceName}
                    {this.props.SAPConnected ? <IssuesSection equipment={this.props.equipment} /> : ""}
                </div>
            );
        }
        else {
            return (
                <div className="card-body col p-3">
                    {this.props.equipment.NiceName}
                    {this.props.SAPConnected ? <IssuesSection equipment={this.props.equipment} /> : ""}
                </div>
            );
        }
    }
}

class SAPCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showconnectionmodal: false,
            SAPConnected: props.SAPConnected,
            applicationurl: '',
            tenantID: '',
            clientsecret: ''
        };
    }


    static getDerivedStateFromProps(props, state) {
        if (props.SAPConnected != state.SAPConnected) {
            return {
                SAPConnected: props.SAPConnected,
            };
        }

        // Return null if the state hasn't changed
        return null;
    }


    componentDidMount() {
        //Check SAP connection
        $.ajax({
            url: '/api/SAPAPI/CheckSAPConnection/',
            type: 'get',
            success: function (response) {
                response = JSON.parse(response);
                console.log(response);
                if (response.SAPConnected) {
                    this.props.SAPConnect();
                }
            }.bind(this)
        });

    }

    ConnectHandler = () => {
        console.log('Connect SAP');

        this.setState({ showconnectionmodal: true });
    }

    handleClose = () => {
        this.setState({ showconnectionmodal: false });
    }

    SAPDetailsSubmissionHandler = () => {
        this.setState({ showconnectionmodal: false });

        $.ajax({
            url: '/api/SAPAPI/SubmitSAPTenant',
            type: 'post',
            data: JSON.stringify({
                ApplicationURL: this.state.applicationurl,
                ClientID: this.state.tenantID,
                ClientSecret: this.state.clientsecret
            }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
                console.log(response);

                $grid.masonry('layout');
                this.props.SAPConnect();
            }.bind(this),
        });
    }

    ApplicationURLChangeHandler = (event) => {
        this.setState({ applicationurl: event.target.value });
    }

    TenantIDChangeHandler = (event) => {
        this.setState({ tenantID: event.target.value });
    }

    ClientSecretChangeHandler = (event) => {
        this.setState({ clientsecret: event.target.value });
    }

    render() {

        return (
            <div class={"grid-item " + (this.props.ncards > 1 ? "col-md-6" : "col") + " p-0"}>

                <div class="m-2 shadow-sm card" >
                    <div class="card-header d-flex align-items-center">
                        <div><img height="30px" src="/images/sap-logo-svg.svg"></img></div>
                    </div>
                    {this.props.SAPConnected ? <div class="card-body col p-3 d-flex align-items-center">Manage your SAP AIN connection
                        <a class="btn btn-primary text-light ms-auto" onClick={this.ConnectHandler}>Manage</a></div> :
                        <div class="card-body col p-3 d-flex align-items-center">Connect with SAP to receive notifications and see your data in AIN.
                            <a class="btn btn-primary text-light ms-auto" onClick={this.ConnectHandler}>Connect</a></div>
                    }
                </div>
                <Modal
                    show={this.state.showconnectionmodal}
                    onHide={this.handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Connection Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="form-group">
                            Please enter your application URL: <input class="form-control" value={this.state.applicationurl} onChange={this.ApplicationURLChangeHandler} />
                        </div>
                        <div class="form-group">
                            Please enter your tenant ID: <input class="form-control" value={this.state.tenantID} onChange={this.TenantIDChangeHandler} />
                        </div>
                        <div class="form-group">
                            Please enter your client secret: <input class="form-control" value={this.state.clientsecret} onChange={this.ClientSecretChangeHandler} />
                        </div>
                        <div class="form-group">
                            <a class="btn btn-primary text-light" onClick={this.SAPDetailsSubmissionHandler}>Submit</a>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

function EquipmentCard(props) {
    var equipmentcard = <></>;
    if (props.equipment.EquipmentTypeID == 0) {
        equipmentcard = <MixingTankCard equipment={props.equipment} SAPConnected={props.SAPConnected} />

    }
    else if (props.equipment.EquipmentTypeID == 1) {
        equipmentcard = <DAFCard equipment={props.equipment} SAPConnected={props.SAPConnected} />

    }
    else if (props.equipment.EquipmentTypeID == 3) {
        equipmentcard = <PhCorrectionTankCard equipment={props.equipment} SAPConnected={props.SAPConnected} />

    }
    else if (props.equipment.EquipmentTypeID == 4) {
        equipmentcard = <SludgeDecanterCard equipment={props.equipment} SAPConnected={props.SAPConnected} />

    }
    else {
        equipmentcard = <div className="card-body col p-3">Equipment ID {props.equipment.EquipmentID} with equipment type ID {props.equipment.EquipmentTypeID} is invalid. Please delete and create again.</div>
    }

    return (
        <div className={"grid-item " + (props.ncards > 1 ? "col-md-6" : "col") + " p-0"}>
            <div className="m-2 shadow-sm card" >
                <div className="card-header d-flex align-items-center">
                    <div>{props.equipment.NiceName}</div>
                    <div className="ms-auto">
                        <Link className="btn btn-primary" to={"/DeepDive/Maintenance/" + props.equipment.EquipmentID}>Explore</Link>
                        {props.SAPConnected ? <div class="ms-1"><a class="btn btn-primary text-light">Map to SAP AIN</a></div> : ""}
                    </div>
                </div>
                {equipmentcard}
            </div>
        </div>
    );
}
var $grid;

export function DeepDive(props) {
    const { id } = useParams();
    const [equipment, setEquipment] = useState([])
    const [showversioncontrolmodal, setShowVersionControlModal] = useState(false)
    const [SAPConnected, setSAPConnected] = useState(false)

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id), [id]);

    function handleClose() {
        setShowVersionControlModal(false)
    }

    function SAPConnect() {
        console.log("SAP Connected");
        setSAPConnected(true)
    }


    function CheckVersion() {
        if (DeepDiveTabVersion < CurrentDeepDiveTabVersion) {
            //Show modal and update cost breakdown tab version for user so they don't see the message on other devices
            var fd = {
                "DeepDiveTabVersion": CurrentDeepDiveTabVersion
            };
            $.ajax({
                url: '/api/VersionControlAPI/SetDeepDiveTabVersion',
                data: fd,
                type: 'get',
                success: function (response) {
                    DeepDiveTabVersion = CurrentDeepDiveTabVersion;
                    Cookies.set('DeepDiveTabVersion', CurrentDeepDiveTabVersion, { expires: 7999 });

                }
            });
            setShowVersionControlModal(true)
        }
    }

    useEffect(() => {
        // Version control modal
        CheckVersion()
        //First run
        if (DeepDiveTabVersion == undefined || DeepDiveTabVersion < CurrentDeepDiveTabVersion) {
            //Get version from server
            $.ajax({
                url: '/api/VersionControlAPI',
                type: 'get',
                success: function (response) {
                    console.log(response);

                    DeepDiveTabVersion = response.deepDiveTabVersion;
                    Cookies.set('HomeTabVersion', response.homeTabVersion, { expires: 7999 });
                    Cookies.set('AnalyticsTabVersion', response.analyticsTabVersion, { expires: 7999 });
                    Cookies.set('HealthSummaryTabVersion', response.healthSummaryTabVersion, { expires: 7999 });
                    Cookies.set('CostBreakdownTabVersion', response.costBreakdownTabVersion, { expires: 7999 });
                    Cookies.set('DeepDiveTabVersion', response.deepDiveTabVersion, { expires: 7999 });
                    Cookies.set('MaintenanceTabVersion', response.maintenanceTabVersion, { expires: 7999 });
                    Cookies.set('BillingTabVersion', response.billingTabVersion, { expires: 7999 });
                    Cookies.set('AlarmsTabVersion', response.alarmsTabVersion, { expires: 7999 });
                    Cookies.set('ControlTabVersion', response.controlTabVersion, { expires: 7999 });
                    Cookies.set('CalibrationsTabVersion', response.calibrationsTabVersion, { expires: 7999 });
                    Cookies.set('AirCleaningTabVersion', response.airCleaningTabVersion, { expires: 7999 });
                    Cookies.set('ReportsTabVersion', response.reportsTabVersion, { expires: 7999 });
                    Cookies.set('SiteDetailsTabVersion', response.siteDetailsTabVersion, { expires: 7999 });
                    Cookies.set('DownloadsTabVersion', response.downloadsTabVersion, { expires: 7999 });

                    CheckVersion();
                }
            });
        }
    }, []);

    useEffect(() => {
        $.ajax({
            url: '/api/DigitalTwinAPI/GetSiteEquipment/',
            type: 'get',
            data: { SiteGUID: id },
            success: function (response) {
                response = JSON.parse(response);
                console.log(response);

                setEquipment(response)

                InitialiseMasonry()
            }
        });
    }, [id]);

    function InitialiseMasonry() {
        var masonryOptions = {
            itemSelector: '.grid-item',
            animate: true,
            stagger: 50,
            columnWidth: '.grid-sizer',
            percentPosition: true
        };

        var elem = document.querySelector('#masonrycards');
        $grid = new Masonry(elem, masonryOptions);

        setTimeout(function () {
            try {
                $grid.layout();
            }
            catch (exception) {
                InitialiseMasonry()
            }
        }, 500);
    }

    var items = [];
    for (var i = 0; i < equipment.length; i++) {
        items.push(
            <EquipmentCard key={"equipment" + i} equipment={equipment[i]} ncards={equipment.length} SAPConnected={SAPConnected} />
        )
    }
    //<SAPCard SAPConnect={SAPConnect} SAPConnected={SAPConnected} />

    return (
        props.auth !== undefined ?
            props.auth > 0 ?
                <>
                    <HelpButton showHelpModal={setShowVersionControlModal} />
                    <div id="masonrycards" className="container-fluid p-0" style={{ marginTop: '40px' }}>
                        <div className="grid-sizer col-md-1"></div>
                        {items}
                    </div>
                    <Modal show={showversioncontrolmodal} onHide={handleClose} size="lg">
                        <Modal.Header closeButton>
                            <h5 className="modal-title" id="exampleModalLongTitle">What's New?</h5>
                        </Modal.Header>
                        <Modal.Body className="text-center">
                            <YouTubeVideo VideoTag="DeepDive" />
                            <p className="text-start">The new "Deep Dive" tab lets you see brief insights on each piece of equipment at your wastewater treatment plant.</p>
                            <p className="text-center"><img className="w-75" src="/images/HowTos/deepdive1.png" alt="Deep Dive Card" /></p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
                :
                <NoAccessPage />
            :
            <></>
    );
};
