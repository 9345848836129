import { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Card,
  Collapse,
} from "react-bootstrap";
import { Line } from 'react-chartjs-2';
import $ from 'jquery';
import moment from 'moment';
import Masonry from 'masonry-layout';

const CompositeCard = ({ data, deviceID, latestValues, $grid }) => {
    const [components, setComponents] = useState(data.components);
    const [datapoints, setDatapoints] = useState([]);
    const [calibrationlog, setCalibrationLog] = useState([])
    const [calibrationlogopen, setCalibrationLogOpen] = useState(false);

    const chartState = {
        //labels: timestamps,
        datasets: [{
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: datapoints
        }]
    };
    const chartOptions = {
        scales: {
            x: { type: 'time' },
            y: {
                title: {
                    display: true
                }
            }
        },
        plugins: { legend: { display: false } },
        title: { display: false }
    }

    // Get calibrations
    useEffect(() => {
        var masonryOptions = {
            itemSelector: '.grid-item',
            animate: true,
            stagger: 50,
            columnWidth: '.grid-sizer',
            percentPosition: true
        };

        var elem = document.querySelector('#' + deviceID + 'calibrationcards');
        $grid = new Masonry(elem, masonryOptions);
        setTimeout(function () {
            try {
                $grid.reloadItems();
                $grid.layout();
            }
            catch (exception) {
            }
        }, 1000);

        $.ajax({
            type: "get",
            url: "/api/CalibrationsAPI/GetCalibrationCompositeLog",
            data: { SignalID: data.signalID },
            success: reply => {
                let logHistory = JSON.parse(reply);

                // Map calibration data into elements
                let historyEntries = logHistory.map((item, i) =>
                    <div key={data.signalID + "calibrationlog" + i} className="border p-2">
                        <Container fluid className="">
                            <Row>
                                {item.map(compSignals => {
                                // This return is needed for nested maps
                                    return (
                                        <Col>{compSignals.InputSignal.NiceName}: {compSignals.Gradient}</Col>
                                    )
                                })}
                            </Row>
                        </Container>
                        
                        <div className="text-secondary text-right m-1" style={{ fontSize: '12px' }}>
                            {moment.utc(item[0].CalibrationTime).local().format('LLL')}
                        </div >
                    </div >)
                setCalibrationLog(historyEntries);
            }
        });
    }, []);

    // Update chart data
    useEffect(() => {
        if (latestValues) {
            let curTime = new Date();
            let newDatapoints = [...datapoints];
            // Remove datapoints > 2 min
            var dt = (curTime - datapoints[0]) / 1000 / 60;
            if (dt >= 2) {
                newDatapoints = newDatapoints.slice(1);
            }
            newDatapoints.push({ x: curTime, y: latestValues[data.signalID] });
            setDatapoints(newDatapoints);
        }
    }, [latestValues]);

    const editGradient = (value, compSignalID) => {
        let newComponents = components;
        let index = newComponents.findIndex(x => x.inputSignalID === compSignalID);
        newComponents[index].gradient = value;
        setComponents(newComponents);
    }

    const saveChanges = () => {
        $.ajax({
            type: "POST",
            url: "/api/CalibrationsAPI/SubmitCompositeChanges",
            data: JSON.stringify({ SignalID: data.signalID, Components: components, DeviceID: deviceID }),
            contentType: 'application/json',
            error: () => {
                alert("Calibration Failed.");
            }
        });
    }

    const CalibrationLogToggle = () => {
        setCalibrationLogOpen(!calibrationlogopen)
        if ($grid) {
            try {
                $grid.layout();
            }
            catch (exception) {
            }
        }
    };

    return (
        <div className="grid-item col-lg-4 col-md-6 p-0 flex">
            <Card className="m-2 shadow-sm">
                <Card.Header><h5>{data.niceName}</h5></Card.Header>
                <Card.Body className="p-2">
                    <Line data={chartState} options={chartOptions} />
                    <Table>
                        <thead>
                            <tr>
                                <th>Component</th>
                                <th>Scaling Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {components.map(com =>
                                <tr key={com.inputSignalID + "-entry"} className="form-group col-md-6">
                                    <td>{com.niceName}</td>
                                    <td style={{width:"150px"}}>
                                        <input className="form-control"
                                            defaultValue={com.gradient}
                                            type="number"
                                            onChange={e => editGradient(e.target.value, com.inputSignalID)} />
                                    </td>
                                </tr>)}
                        </tbody>
                    </Table>
                    <Button onClick={saveChanges}>Submit</Button>
                    <br/>
                    {calibrationlog.length > 0 ?
                        <Button variant="secondary" className="mt-1"
                            onClick={CalibrationLogToggle}
                            aria-controls={data.signalID + "calibrationlog"}
                            aria-expanded={calibrationlogopen}>
                            Toggle calibration log <i class="fas fa-caret-down"></i>
                        </Button> : <div>No calibrations recorded</div>
                    }

                    <Collapse in={calibrationlogopen}>
                        <div>
                            {calibrationlog}
                        </div>
                    </Collapse>
                </Card.Body>
            </Card>
        </div>
        );
}

export default CompositeCard;