import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import "./web.config";
import registerServiceWorker from './registerServiceWorker';
import StreamwiseErrorBoundary from './components/StreamwiseErrorBoundary';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <StreamwiseErrorBoundary>
      <App />
    </StreamwiseErrorBoundary>

  </BrowserRouter>,
  rootElement);

//registerServiceWorker();

