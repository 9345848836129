import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import {
    Table, Form, InputGroup, Modal, Button, OverlayTrigger, Tooltip, Carousel,Badge
} from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { LogIn } from './DeviceSetup/LogIn';
import { CompanySetup } from './DeviceSetup/CompanySetup';
import { SiteSetup } from './DeviceSetup/SiteSetup';
import { ReviewDeviceSetup } from './DeviceSetup/CompleteSetup';
import NoAccessPage from './NoAccessPage';


export function DeviceSetup(props) {
    const { id } = useParams();

    const [percent, setPercent] = useState(0);
    const [carouselstate, setCarouselState] = useState(0);
    const [deviceexists, setDeviceExists] = useState(false);
    const [deviceregistered, setDeviceRegistered] = useState(false);
    const [device, setDevice] = useState(null);
    const [company, setCompany] = useState(null);
    const [site, setSite] = useState(null);
    const [sitelist, setSiteList] = useState([]);

    useEffect(() => {
        $.ajax({
            url: '/api/DeviceSetupAPI/FilterDevice/',
            type: 'get',
            data: {
                DeviceID: id
            },
            success: function (response) {
                if (response > 0) {
                    setDeviceExists(true);
                }
                if (response == 1) {
                    setDeviceRegistered(true);
                    setCarouselState(4);
                }
            }
        });

        $.ajax({
            url: '/api/DeviceSetupAPI/GetInitialData/',
            type: 'get',
            data: {
                DeviceID: id
            },
            success: function (response) {
                response = JSON.parse(response);
                setCompany(response.Company);
                setSiteList(response.Sites);
                setDevice(response.Device);
                setCarouselState(1);
            }
        });
    }, []);


    useEffect(() => {
        onSlideHandler();
        setPercent(carouselstate * 100 / 4);
        if (deviceregistered) {
            setCarouselState(4);
        }
    }, [carouselstate]);

    function onSlideHandler() {
        
    }

    function NextStep() {
        setCarouselState(carouselstate + 1);
    }

    function Login() {
        $.ajax({
            url: '/api/DeviceSetupAPI/Login/',
            type: 'post',
            data: {
                DeviceID: id
            },
            success: function (response) {
                $.ajax({
                    url: '/api/DeviceSetupAPI/GetInitialData/',
                    type: 'get',
                    data: {
                        DeviceID: id
                    },
                    success: function (response) {
                        setCompany(response.Company);
                        setSiteList(response.Sites);
                        setDevice(response.Device);
                    }
                });
                setCarouselState(1);
            }
        });
    }

    function SearchACN(acn) {
        $.ajax({
            url: '/api/DeviceSetupAPI/SearchACN/',
            type: 'get',
            data: {
                ACN: acn
            },
            success: function (response) {
                response = JSON.parse(response);
                if (response.Company !== null) {
                    setCompany(response.Company);
                    setSiteList(response.Sites);
                }
            }
        });
    }

    function CompleteDeviceSetup() {
        $.ajax({
            url: '/api/DeviceSetupAPI/CompleteSetup/',
            type: 'post',
            data: JSON.stringify({
                Device: device,
                Company: company,
                Site: site
            }),
            contentType: "application/json; charset=utf-8",
            success: function (response) {
                response = JSON.parse(response);
                window.location.replace("/" + response.SiteGUID)
            }
        });
    }

    return (
        <div>
            <br/><br/>
            <div className="card-body p-3 ">
                <ProgressBar
                    height={30}
                percent={percent}
                fillBackground="linear-gradient(to right, #fefb72, #f0bb31)"
            >
                    <Step transition="scale" position={0}>
                        {({ carouselstate }) => (
                            <h5 className='ml-5 mt-1'><Badge pill bg="secondary">Login</Badge></h5>
                    )}
                </Step>
                    <Step transition="scale" position={25}>
                        {({ carouselstate }) => (
                            <h5 className='mt-1' onClick={() => setCarouselState(1)}><Badge pill bg="secondary" >Company Setup</Badge></h5>
                    )}
                </Step>
                    <Step transition="scale" position={50}>
                    {({ carouselstate }) => (
                            <h5 className='mt-1' onClick={() => setCarouselState(2)}><Badge pill bg="secondary">Site Setup</Badge></h5>
                    )}
                    
                </Step>
                <Step transition="scale" position={75}>
                        {({ carouselstate }) => (
                            <h5 className='mt-1' onClick={() => setCarouselState(3)}><Badge pill bg="secondary">Review and Confirm</Badge></h5>
                    )}
                    
                </Step>
                <Step transition="scale" position={100}>
                        {({ carouselstate }) => (
                            <h5 className='mr-5 mt-1'><Badge pill bg="secondary">Complete</Badge></h5>
                    )}
                    
                </Step>
                </ProgressBar></div>
            <div className="row m-1 align-items-center" style={{ float: 'center' }}>
                <div className="card-body p-3 ">
                    {carouselstate == 0 ? <div>
                        {deviceexists ?
                            deviceregistered ? <div>Device has already been registered.</div> : <LogIn login={Login} />
                    : <div>Device does not exist.</div>}
                    </div> : <div></div>}
                {carouselstate == 1 ? <div>{deviceexists ?
                        deviceregistered ? <div>Device has already been registered.</div> : <CompanySetup company={company} setCompany={setCompany} searchACN={SearchACN} next={NextStep} />
                        : <div>Device does not exist.</div>}</div> : <div></div>}
                    {carouselstate == 2 ? <div>{deviceexists ?
                        deviceregistered ? <div>Device has already been registered.</div> : <SiteSetup sitelist={sitelist} setSite={setSite} next={NextStep} />
                        : <div>Device does not exist.</div>}</div> : <div></div>}
                    {carouselstate == 3 ? <div>{deviceexists ?
                        deviceregistered ? <div>Device has already been registered.</div> : <ReviewDeviceSetup device={device} setDevice={setDevice} company={company} site={site} completeSetup={CompleteDeviceSetup} />
                        : <div>Device does not exist.</div>} </div> : <div></div>}
                    {carouselstate == 4 ? <div>{deviceexists ?
                        deviceregistered ? <div>Device setup is complete.</div> : <div>Device Setup is Complete</div>
                        : <div>Device does not exist.</div>} </div>: <div></div>}
              
                </div >
                </div>
        </div>);
};
