import React, { useEffect, useState, useContext } from 'react';
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import $ from 'jquery';
import { Fade, Button } from 'react-bootstrap'
import ScatterChart from './ScatterChart'
import { AuthContext } from '../Analytics';

const SortableItem = sortableElement(({ index, value, onremove, start, end, i, valueslabel }) => {
    return <ScatterChart
        index={i}
        scatterID={value}
        onremove={onremove}
        start={start}
        end={end}
        valueslabel={valueslabel}
    />
});

const SortableContainer = sortableContainer(({ children }) => {
    return <div>{children}</div>;
});

function ScatterChartsContainer(props) {
    const [scatterCharts, setScatterCharts] = useState([]);
    const [ready, setReady] = useState(false);
    const [showHelpmodal, setHelpModal] = useState(false);
    const { auth } = useContext(AuthContext);

    useEffect(() => {
        // Scatter Charts
        var url = '/api/AnalyticsAPI/GetScatterCharts/';
        $.ajax({
            url: url,
            type: 'get',
            data: { SiteGUID: props.siteGUID },
            error: function () {
                console.log("Get scatter charts api call failed")
            },
            success: function (response) {
                var data = JSON.parse(response)
                var newScatterCharts = []
                data.forEach(d => newScatterCharts.push({ id: d.InsightScatterChartID, idx: d.Order }));
                newScatterCharts.sort((a, b) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0));
                setScatterCharts(newScatterCharts);
                setReady(true);

                //InsightScatterChart.init(d.InsightScatterChartID, start, end)
                //InsightScatterCharts.push({ Id: d.InsightScatterChartID });

            }
        });
    }, [props.siteGUID])

    const onSortStart = () => (document.body.style.cursor = 'grabbing')

    const onSortEnd = ({ oldIndex, newIndex }) => {
        document.body.style.cursor = 'default';
        var newArray = arrayMoveImmutable(scatterCharts, oldIndex, newIndex);
        setScatterCharts(newArray)
        var url = '/api/AnalyticsAPI/SetScatterOrder/';
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newArray.map(i => i.id))
        })
    };

    const addScatter = () => {
        var url = '/api/AnalyticsAPI/AddScatterChart/';
        $.ajax({
            url: url,
            type: 'get',
            data: { SiteGUID: props.siteGUID },
            error: function () {
                console.log("Add scatter api call failed")
            },
            success: function (response) {
                var data = JSON.parse(response)
                var newScatterCharts = []
                data.forEach(d => newScatterCharts.push({ id: d.InsightScatterChartID, idx: d.Order }));
                newScatterCharts.sort((a, b) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0));
                setScatterCharts(newScatterCharts);
            }
        });
    }

    const removeScatter = (chartID) => {
        var newScatterCharts = JSON.parse(JSON.stringify(scatterCharts));
        newScatterCharts.splice(newScatterCharts.findIndex(lc => lc.id == chartID), 1)
        setScatterCharts(newScatterCharts);
    }

    return (
        <>
            <Fade appear={true} in={ready}>
                <div>
                    {auth == 2 ?
                        <Button className="btn-primary mb-3" onClick={addScatter}>
                            Add scatter chart
                        </Button>
                        :
                        <></>
                    }
                    <SortableContainer onSortEnd={onSortEnd} onSortStart={onSortStart} useDragHandle>
                        <div id="insightScatterCharts" class="w-100">
                            {scatterCharts.map((value, index) =>
                            (
                                <SortableItem
                                    key={`ScatterChart-${value.id}`}
                                    index={index}
                                    value={value.id}
                                    onremove={removeScatter}
                                    start={props.start}
                                    end={props.end}
                                    i={index}
                                />
                            ))}
                        </div>
                    </SortableContainer>
                </div>
            </Fade>
        </>)
}

export default ScatterChartsContainer;