import { ReactNode, useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { SignalModel } from "./types";
import {
    TwincatSignalRow,
    ModbusSignalRow,
    LoraSignalRow,
    EthernetSignalRow,
    OtherSignalRow,
    OtherEdgeSignalRow,
    AdvantechSignalRow,
} from "./SignalProtocolRows";
import { Button } from "react-bootstrap";
import { SettingsModal } from "./SettingsModals";

interface EdgeSignalsProps {
    signals: SignalModel[];
    siteGUID: string;
}

const EDGE_ACCORDION_DISPLAY = [
    {
        protocolID: 0,
        protocolName: "Twincat",
        hasSparkChart: true,
        columnNames: ["Device", "Variable Name", "Variable Location", "Advanced"],
    },
    {
        protocolID: 1,
        protocolName: "Modbus",
        hasSparkChart: true,
        columnNames: ["Device", "Slave ID", "Address", "Variable Type", "Aqualabo?", "Advanced"],
    },
    {
        protocolID: 2,
        protocolName: "LoRa",
        hasSparkChart: true,
        columnNames: ["Device", "Device EUI", "Channel Number", "Advanced"],
    },
    {
        protocolID: 4,
        protocolName: "Ethernet",
        hasSparkChart: true,
        columnNames: ["Device", "Tag", "VariableName", "Advanced"],
    },
];

type SignalTableProps = {
    hasSparkChart?: boolean;
    columnNames: string[];
    children: ReactNode;
};

const SignalTable = ({ hasSparkChart, columnNames, children }: SignalTableProps) => {
    return (
        <table className="w-100" style={{ tableLayout: "auto" }}>
            <thead>
                <tr>
                    <th>Signal</th>
                    {hasSparkChart && <th className="d-none d-lg-block" />}
                    {columnNames.map((colName: string) => (
                        <th key={`header-${colName}`} className="text-center">{colName}</th>
                    ))}
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </table>
    );
};

export const EdgeSignals = ({ signals, siteGUID }: EdgeSignalsProps) => {
    const {
        loading,
        devices,
        signals: edgeSignals,
    } = useSelector((state: RootState) => state.edgeSignal);
    const [showSignalSettingsModal, setShowSignalSettingsModal] = useState(false);
    const [settingsModalSignal, setSettingsModalSignal] = useState<SignalModel | undefined>(undefined);
    const [settingsModalProtocolID, setSettingsModalProtocolID] = useState<number>(0)

    const handleShowSettingsModal = (signal: SignalModel | undefined, protocolID: number) => {
        setSettingsModalSignal(signal);
        setSettingsModalProtocolID(protocolID);
        setShowSignalSettingsModal(true);
    };

    const createAccordionSignalRow = (protocolID: number) => {
        switch (protocolID) {
            case 0:
                return (
                    <>
                        {edgeSignals.TwincatSignals.map((item) => (
                            <TwincatSignalRow
                                key={item.Signal.SignalID + "-twincat"}
                                signal={item.Signal}
                                edgeSignal={item.EdgeSignal}
                                protocol={item.Protocol}
                                devices={devices}
                                signals={signals}
                                siteGUID={siteGUID}
                                handleShowSettingsModal={handleShowSettingsModal}
                                protocolID={protocolID}
                            />
                        ))}
                    </>
                );
            case 1:
                return (
                    <>
                        {edgeSignals.ModbusSignals.map((item) => (
                            <ModbusSignalRow
                                key={item.Signal.SignalID + "-modbus"}
                                signal={item.Signal}
                                edgeSignal={item.EdgeSignal}
                                protocol={item.Protocol}
                                signals={signals}
                                devices={devices}
                                siteGUID={siteGUID}
                                handleShowSettingsModal={handleShowSettingsModal}
                                protocolID={protocolID}
                            />
                        ))}
                    </>
                );
            case 2:
                return (
                    <>
                        {edgeSignals.LoraSignals.map((item) => (
                            <LoraSignalRow
                                key={item.Signal.SignalID + "-lora"}
                                signal={item.Signal}
                                edgeSignal={item.EdgeSignal}
                                protocol={item.Protocol}
                                signals={signals}
                                devices={devices}
                                siteGUID={siteGUID}
                                handleShowSettingsModal={handleShowSettingsModal}
                                protocolID={protocolID}
                            />
                        ))}
                    </>
                );
            case 4:
                return (
                    <>
                        {edgeSignals.EthernetSignals.map((item) => (
                            <EthernetSignalRow
                                key={item.Signal.SignalID + "-ethernet"}
                                signal={item.Signal}
                                edgeSignal={item.EdgeSignal}
                                protocol={item.Protocol}
                                signals={signals}
                                devices={devices}
                                siteGUID={siteGUID}
                                handleShowSettingsModal={handleShowSettingsModal}
                                protocolID={protocolID}
                            />
                        ))}
                    </>
                );
            default:
                return <></>;
        }
    };

    return (loading ?
        <div className="grid-item col-12 p-0">
            <div className="m-2 shadow p-0 card">
                <div className="card-header">Fetching signal information...</div>
                <div className="card-body p-3">
                    Fetching signal information...
                    <img style={{ height: "24px", width: "24px" }} src="/lib/ajax-loader.gif" />
                </div>
            </div>
        </div> :
        <Accordion defaultActiveKey="0">
            {EDGE_ACCORDION_DISPLAY.map(({ protocolID, protocolName, ...signalTableProps }) => (
                <Accordion.Item key={`protocol-${protocolName}`} eventKey={`${protocolID}`}>
                    <Accordion.Header>{`${protocolName} signals`}</Accordion.Header>
                    <Accordion.Body>
                        <SignalTable {...signalTableProps}>{createAccordionSignalRow(protocolID)}</SignalTable>
                        <Button
                            onClick={() => handleShowSettingsModal(undefined, protocolID)}
                        >
                            + Create
                        </Button>
                    </Accordion.Body>
                </Accordion.Item>
            ))}

            {edgeSignals.OtherSignals.length > 0 && (
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Other signals</Accordion.Header>
                    <Accordion.Body>
                        <SignalTable columnNames={["Device"]}>
                            {edgeSignals.OtherSignals.map((item) => (
                                <OtherEdgeSignalRow
                                    key={item.SignalID + "-otherEdge"}
                                    signalID={item.SignalID}
                                    niceName={item.NiceName}
                                    deviceID={item.DeviceID}
                                    devices={devices}
                                />
                            ))}
                        </SignalTable>
                    </Accordion.Body>
                </Accordion.Item>)
            }

            <SettingsModal
                show={showSignalSettingsModal}
                setShow={setShowSignalSettingsModal}
                signal={settingsModalSignal}
                devices={devices}
                protocolID={settingsModalProtocolID}
                siteGUID={siteGUID}
                signals={signals}
            />
        </Accordion>
    );
};

interface AdvantechSignalsProps {
    signals: SignalModel[];
    siteGUID: string;
}
export const AdvantechSignals = ({ signals, siteGUID }: AdvantechSignalsProps) => {
    const advSignals = useSelector((state: RootState) => state.advSignal);

    const [showSignalSettingsModal, setShowSignalSettingsModal] = useState(false);
    const [settingsModalSignal, setSettingsModalSignal] = useState<SignalModel | undefined>(undefined)

    const columnNames = ["Gradient", "Intercept", "Minimum", "Maximum"];

    const handleShowSettingsModal = (signal: SignalModel | undefined) => {
        setSettingsModalSignal(signal);
        setShowSignalSettingsModal(true);
    };

    return advSignals.loading ? (
        <div className="grid-item col-12 p-0">
            <div className="m-2 shadow p-0 card">
                <div className="card-header">Fetching signal information...</div>
                <div className="card-body p-3">
                    Fetching signal information...
                    <img style={{ height: "24px", width: "24px" }} src="/lib/ajax-loader.gif" />
                </div>
            </div>
        </div>
    ) : (
        <>
            <SignalTable hasSparkChart columnNames={columnNames}>
                {advSignals.signals.map(adv =>
                    <AdvantechSignalRow
                        signal={adv.Signal}
                        advSignal={adv.Advantech}
                        signals={signals}
                        siteGUID={siteGUID}
                        handleShowSettingsModal={handleShowSettingsModal}
                    />
                )}
            </SignalTable>
        </>
    );
};

export const OtherSignals = ({ signals }: { signals: SignalModel[] }) => {
    return (
        <table className="w-100" style={{ tableLayout: "auto", width: "100%" }}>
            <thead>
                <tr>
                    <th>Signal</th>
                    <th className="d-none d-lg-block"></th>
                    <th className="text-center">Advanced</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {signals.map(item => (
                    <OtherSignalRow key={item.SignalID + "-othersignal"} signal={item} />
                ))}
            </tbody>
        </table>
    );
};
