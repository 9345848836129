import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import $ from 'jquery';
import { Button, Modal, Accordion } from 'react-bootstrap';
import Cookies from 'cookies-js';
import 'chartjs-adapter-moment';
import NoAccessPage from '../NoAccessPage';
import HelpButton from '../HelpButton';
import 'react-toastify/dist/ReactToastify.css';
import { AdvantechSignals, EdgeSignals, OtherSignals } from './SignalAccordions';

import { Provider } from 'react-redux';
import { AppDispatch, store, useAppDispatch } from '../../redux';
import { fetchEdgeSignals } from '../../redux/EdgeSignalReducer';
import { fetchAdvSignals } from '../../redux/AdvantechSignalReducer';

import { SignalModel } from './types';

// Show info modal if updated since last visit
var SignalSetupTabVersion = Number(Cookies.get('SignalSetupTabVersion'));
var CurrentSignalSetupTabVersion = 3;

interface SignalsListProps {
    auth: any;
    siteGUID: string;
}
const SignalsList = ({ auth, siteGUID }: SignalsListProps) => {
    const [signals, setSignals] = useState<SignalModel[]>([]);
    const [otherSignals, setOtherSignals] = useState<SignalModel[]>([]);
    const dispatch: AppDispatch = useAppDispatch();

    // populate empty arrays with signals from edge devices
    useEffect(() => {
        dispatch(fetchEdgeSignals(siteGUID));
        dispatch(fetchAdvSignals(siteGUID));

        // Retrieve all signals (cloud) for the site
        fetch(`/api/SignalSetupAPI/GetSignalsAndAccess/?SiteGUID=${siteGUID}`)
            .then(res => res.json())
            .then(data => {
                setSignals(data.accessableSignals);
                setOtherSignals(data.uselessSignals);
            });
    }, [dispatch]);

    return (
        <div>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Signals through Streamwise  D.I. edge device</Accordion.Header>
                    <Accordion.Body>
                        <EdgeSignals signals={signals} siteGUID={siteGUID} />
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                    <Accordion.Header>Advantech signals</Accordion.Header>
                    <Accordion.Body>
                        <AdvantechSignals signals={signals} siteGUID={siteGUID} />
                    </Accordion.Body>
                </Accordion.Item>

                {otherSignals.length > 0 &&
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Other signals</Accordion.Header>
                        <Accordion.Body>
                            <OtherSignals signals={otherSignals} />
                        </Accordion.Body>
                    </Accordion.Item>
                }
            </Accordion>
        </div>
    );
}

export const Signals = (props) => {
    const { id } = useParams();
    const [showversioncontrolmodal, setShowVersionControlModal] = useState(false);

    useEffect(() => props.SiteGUIDSetter(id), [id]);

    const handleClose = () => {
        setShowVersionControlModal(false)
    }

    const CheckVersion = () => {
        if (SignalSetupTabVersion < CurrentSignalSetupTabVersion) {
            //Show modal and update downloads tab version for user so they don't see the message on other devices
            var fd = {
                "SignalSetupTabVersion": CurrentSignalSetupTabVersion
            };
            $.ajax({
                url: '/api/VersionControlAPI/SetSignalSetupTabVersion',
                data: fd,
                type: 'get',
                success: () => {
                    SignalSetupTabVersion = CurrentSignalSetupTabVersion;
                    Cookies.set('DownloadsTabVersion', CurrentSignalSetupTabVersion, { expires: 7999 });
                }
            });
            setShowVersionControlModal(true)
        }
    }

    useEffect(() => {
        // Version control modal
        CheckVersion()
        //First run
        if (SignalSetupTabVersion == undefined || SignalSetupTabVersion < CurrentSignalSetupTabVersion) {
            //Get version from server
            $.ajax({
                url: '/api/VersionControlAPI',
                type: 'get',
                success: response => {
                    SignalSetupTabVersion = response.signalSetupTabVersion;
                    Cookies.set('HomeTabVersion', response.homeTabVersion, { expires: 7999 });
                    Cookies.set('AnalyticsTabVersion', response.analyticsTabVersion, { expires: 7999 });
                    Cookies.set('HealthSummaryTabVersion', response.healthSummaryTabVersion, { expires: 7999 });
                    Cookies.set('CostBreakdownTabVersion', response.costBreakdownTabVersion, { expires: 7999 });
                    Cookies.set('DeepDiveTabVersion', response.deepDiveTabVersion, { expires: 7999 });
                    Cookies.set('MaintenanceTabVersion', response.maintenanceTabVersion, { expires: 7999 });
                    Cookies.set('BillingTabVersion', response.billingTabVersion, { expires: 7999 });
                    Cookies.set('AlarmsTabVersion', response.alarmsTabVersion, { expires: 7999 });
                    Cookies.set('ControlTabVersion', response.controlTabVersion, { expires: 7999 });
                    Cookies.set('CalibrationsTabVersion', response.calibrationsTabVersion, { expires: 7999 });
                    Cookies.set('AirCleaningTabVersion', response.airCleaningTabVersion, { expires: 7999 });
                    Cookies.set('ReportsTabVersion', response.reportsTabVersion, { expires: 7999 });
                    Cookies.set('SiteDetailsTabVersion', response.siteDetailsTabVersion, { expires: 7999 });
                    Cookies.set('SignalSetupTabVersion', response.siteDetailsTabVersion, { expires: 7999 });
                    Cookies.set('DownloadsTabVersion', response.downloadsTabVersion, { expires: 7999 });
                    CheckVersion();
                }
            });
        }

        $(document).on("wheel", "input[type=number]", function (e) {
            $(this).blur();
        });
    }, []);

    return ((props.auth !== undefined && props.auth > 0) ?
        <>
            <HelpButton showHelpModal={setShowVersionControlModal} />
            <div id="cards" className="container-fluid p-0" style={{ marginTop: '40px' }}>
                <div className="grid-item col-1"></div>
                <Provider store={store}>
                    <SignalsList auth={props.auth} siteGUID={id} />
                </Provider>

                <Modal show={showversioncontrolmodal} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <h5 className="modal-title" id="exampleModalLongTitle">What's New?</h5>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        {/* <p className="text-start">You can configure signals here.</p>
                            <YouTubeVideo VideoTag="SignalSetup" /> */}
                        <p><img className="w-100" src="/images/HowTos/signalsetup-v3.png" alt="Document Upload" /></p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </> :
        <NoAccessPage />
    );
}
