import { StreamwiseNode } from "../types";
import "./styles.scss";
import Pump from "./Pump";
import LinearEquation from "./LinearEquation";
import PID from "./PID";
import Source from "./Source";
import Target from "./Target";
import Init from "./Init";
import BlendedControl from "./BlendedControl";

export const nodeTypes = {
  pump: Pump,
  pid: PID,
  linearEquation: LinearEquation,
  source: Source,
  target: Target,
  init: Init,
  blended: BlendedControl
};

export const SW_NODE_LIST: StreamwiseNode[] = [
  {
    id: "",
    position: { x: 0, y: 0 },
    type: "source",
    data: { 
      name: "Source",
      controlType: 0,
      controlLinkData: {
        signalID: "",
        inputBlock: ""
      } },
  },
  {
    id: "",
    position: { x: 0, y: -10 },
    type: "linearEquation",
    data: { 
      name: "Linear Dosing",
      controlType: 2, 
      controlLinkData: {
        signalID: "",
        inputBlock: ""
      } },
  },
  {
    id: "",
    position: { x: 0, y: -10 },
    type: "blended",
    data: { 
      name: "Blended Control",
      controlType: -1, 
      controlLinkData: {
        signalID: "",
        inputBlock: ""
      },
      blended: [] },
  },
  {
    id: "",
    position: { x: 0, y: -10 },
    type: "pid",
    data: { 
      name: "PID",
      controlType: 1,
      controlLinkData: {
        signalID: "",
        inputBlock: ""
      }  },
  },
  {
    id: "",
    position: { x: 0, y: -10},
    type: "pump",
    data: { 
      name: "Pump",
      controlType: 3,
      controlLinkData: {
        signalID: "",
        inputBlock: ""
      },
      pump: {
        UpPumpNeedMeasureCmd: 0,
        DownPumpNeedMeasureCmd: 0,
      }  },
  },
];
