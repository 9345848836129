import React, { useEffect, useState } from 'react';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import LineChart from './LineChart';
import { arrayMoveImmutable } from 'array-move';
import $ from 'jquery';
import { Fade } from 'react-bootstrap';

const LineChartTypeContext = React.createContext();
const AuthContext = React.createContext();

const SortableItem = sortableElement(({ index, value, onremove, start, end, i, valueslabel }) => {
    return <LineChart
        index={i}
        chartID={value}
        onremove={onremove}
        start={start}
        end={end}
        valueslabel={valueslabel}
    />
});

const SortableContainer = sortableContainer(({ children }) => {
    return <div>{children}</div>;
});

function LineChartContainer({siteGUID, linecharttype, auth, start, end}) {
    const [LineChartType, setLineChartType] = useState(0)
    const [lineCharts, setLineCharts] = useState([]);
    const [ready, setReady] = useState(false);
    const [empty, setEmpty] = useState(true);

    useEffect(() => {
        setLineChartType(linecharttype);
        var url = '/api/LineChartAPI/GetCharts/';
        $.ajax({
            url: url,
            type: 'get',
            data: { SiteGUID: siteGUID, LineChartType: linecharttype },
            error: function () {
                console.log("Get charts api call failed")
            },
            success: function (response) {
                var data = JSON.parse(response)
                var newLineCharts = []
                data.forEach(d => newLineCharts.push({ id: d.LineChartID, idx: d.Order }));
                newLineCharts.sort((a, b) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0));
                setLineCharts(newLineCharts);
                setReady(true);
                console.log(data);
            }
        });
    }, [siteGUID])

    //add useEffect to check if the charts array is empty 

    useEffect(() => {
        if (lineCharts.length) {
            setEmpty(false);
        }
        else {
            setEmpty(true);
        }
    }, [lineCharts.length])

    const onSortStart = () => (document.body.style.cursor = 'grabbing')

    const onSortEnd = ({ oldIndex, newIndex }) => {
        document.body.style.cursor = 'default';
        var newArray = arrayMoveImmutable(lineCharts, oldIndex, newIndex);
        setLineCharts(newArray)
        var url = '/api/LineChartAPI/SetChartOrder/';
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newArray.map(i => i.id))
        })
    };

    const addChart = () => {
        var url = '/api/LineChartAPI/AddChart/';
        $.ajax({
            url: url,
            type: 'get',
            data: { SiteGUID: siteGUID, LineChartType: LineChartType },
            error: function () {
                console.log("Add chart api call failed")
            },
            success: function (response) {
                var data = JSON.parse(response)
                var newLineCharts = []
                data.forEach(d => newLineCharts.push({ id: d.LineChartID, idx: d.Order }));
                newLineCharts.sort((a, b) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0));
                setLineCharts(newLineCharts);
            }
        });
    }

    //
    const autoChart = () => {
        setEmpty(false);
        var url = '/api/LineChartAPI/AutoChart/';
        console.log("AUTO");
        $.ajax({
            url: url,
            type: 'get',
            data: { SiteGUID: siteGUID, LineChartType: LineChartType },
            error: function () {
                console.log("Add chart api call failed")
            },
            success: function (response) {
                var data = JSON.parse(response)
                var newLineCharts = []
                data.forEach(d => newLineCharts.push({ id: d.LineChartID, idx: d.Order }));
                newLineCharts.sort((a, b) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0));
                setLineCharts(newLineCharts);
                
            }
        });
    }
    //

    const removeChart = (chartID) => {
        var newLineCharts = JSON.parse(JSON.stringify(lineCharts));
        newLineCharts.splice(newLineCharts.findIndex(lc => lc.id == chartID), 1)
        setLineCharts(newLineCharts);
    }

    return (
        <>
            <Fade appear={true} in={ready}>
                <div>
                    <AuthContext.Provider value={{ auth: auth }}>
                        <LineChartTypeContext.Provider value={{ LineChartType }}>
                            <SortableContainer onSortEnd={onSortEnd} onSortStart={onSortStart} useDragHandle>
                                {lineCharts.map((value, index) =>
                                (
                                    <SortableItem
                                        key={`LineChart-${value.id}`}
                                        index={index}
                                        value={value.id}
                                        onremove={removeChart}
                                        start={start}
                                        end={end}
                                        i={index}
                                    />
                                ))}
                            </SortableContainer>
                            {empty && auth == 2 ?
                                <div className="card mb-3 shadow">
                                    <div className="autochart-body" onClick={autoChart} style={{ cursor: 'pointer' }}>
                                        <div id="AutoChart" className="justify-content-center  align-items-center  ">
                                            <div className="text-muted text-center">
                                                <i className="fas fa-bolt" style={{ color: "blue" }}></i>
                                                <div>Get started by clicking here to auto generate charts</div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                :
                                <></>
                            }
                            {ready && auth == 2 ?
                                <div className="card mb-3">
                                    <div className="card-body" onClick={addChart} style={{ cursor: 'pointer' }}>
                                        <div id="AddChart" className="justify-content-center  align-items-center">
                                            <div className="text-muted text-center">
                                                <i className="fas fa-plus"></i>
                                                <div>Add Empty Chart</div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                :
                                <></>
                            }
                        </LineChartTypeContext.Provider>
                    </AuthContext.Provider>
                </div>
            </Fade>
        </>
    );
}

export { LineChartTypeContext };
export { AuthContext };
export default LineChartContainer;
