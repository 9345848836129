import React, { useState, useEffect } from "react";
import { Card, InputGroup, FormControl, Modal, Button, CardGroup, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { Device, IControlChain, IControlChainPayload } from "./types";
import ControlChartContainer from './ControlChart';
import NoAccessPage from "../NoAccessPage";

function ChainDetails(props) {
    const [promptdeleteconfirmation, setpromptdeleteconfirmation] = useState(false)

    const DeleteConfirmation = () => {
        fetch("/api/ControlChainsAPI/DeleteControlChain/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(props.id),
        })
            .then((response) => {
                if (response.ok) {
                    setpromptdeleteconfirmation(false);
                    props.updateChains();
                }
            })
            .catch((error) => {
                console.error(error);
                throw 'Error deleting control chain';
            });
    }

    return (
        <Card
            border="primary"
            className="text-center"
            style={{ width: "22rem" }}
        >
            <Card.Header><div className="row m-1 align-items-center">
                {props.name}
                {props.superadmin ? <OverlayTrigger
                    placement="top"
                    show={promptdeleteconfirmation}
                    overlay={
                        <Tooltip>
                            <div className="align-items-center">Confirm delete: <div className="btn btn-danger" onClick={DeleteConfirmation}><i className="fad fa-trash"></i></div></div>
                        </Tooltip>
                    }>
                    <div className="btn btn-outline-danger col-auto ms-auto" onClick={() => setpromptdeleteconfirmation(true)}><i className="fad fa-trash"></i></div>
                </OverlayTrigger> : <></>} </div>
            </Card.Header>
            <Card.Body className="m-2" style={{ padding: '0px' }}>
                <Card.Text>
                    Device Name: {props.deviceName}
                </Card.Text>
                <Button
                    onClick={() => props.navigate(props.SiteGUID, props.id)}
                >
                    Go to Control Chain
                </Button>
            </Card.Body>
        </Card>
    );
}

export function ControlChainsSplashPage(props) {
    const { id } = useParams();
    const history = useHistory();
    const [controlChains, setControlChains] = useState<IControlChain[]>([])
    const [devices, setDevices] = useState<Device[]>([])
    const [newChainModal, setNewChainModal] = useState(false)
    const [newChain, setNewChain] = useState<IControlChainPayload>({ siteGUID: id })
    const [superadmin, setSuperAdmin] = useState(false);

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id), [id]);

    useEffect(() => {
        getControlChains();
        fetch("/api/ControlChainsAPI/GetDevices/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(id),
        })
            .then((response) => response.json())
            .then((deviceList: Device[]) => {
                setDevices(deviceList);
            });
        fetch("/api/AuthorizationAPI/IsSuperAdmin", {
            method: "GET",
        })
            .then((response) => response.json())
            .then((isSuperAdmin: boolean) => {
                setSuperAdmin(isSuperAdmin);
            });
    }, [id])

    const getControlChains = () => {
        fetch("/api/ControlChainsAPI/GetControlChainsList/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(id),
        })
            .then((response) => response.json())
            .then((controlChains: IControlChain[]) => {
                setControlChains(controlChains);
            });
    }

    const createControlChain = () => {
        fetch("/api/ControlChainsAPI/CreateControlChain/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(newChain),
        })
            .then((response) => response.json())
            .then((chainID: string) => {
                setNewChainModal(false);
                history.push(
                    "/ControlChains/index/" +
                    id +
                    "/" + chainID
                );
            });
        setNewChainModal(false);
    }

    const navigateToNewControlChain = (SiteGUID: string, ChainID: string) => {
        history.push(
            "/ControlChains/index/" + SiteGUID + "/" + ChainID
        );
    }

    return props.auth !== undefined ? (
        props.auth > 0 ? (
            <>
                <Modal
                    show={newChainModal}
                    onHide={(() => setNewChainModal(false))}
                    aria-labelledby="contained-modal-title-newchain"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-newchain">
                            Create New Control Chain
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Name: </InputGroup.Text>
                            <FormControl
                                aria-describedby="basic-addon3"
                                value={newChain?.name}
                                onChange={(v) => { setNewChain({ ...newChain, name: v.target.value }); }}>
                            </FormControl>
                        </InputGroup>
                        {devices.length > 0 ?
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Device: </InputGroup.Text>
                                <select value={newChain?.deviceID} onChange={(v) => { setNewChain({ ...newChain, deviceID: v.target.value }); }}>
                                    <option id="0" value="0">Select Device</option>
                                    {devices.map(d => <option key={d.deviceID} id={d.DeviceID} value={d.DeviceID}>{d.NiceName}</option>)}
                                </select>
                            </InputGroup> : <></>}
                    </Modal.Body>
                    <Modal.Footer>
                        {superadmin ?
                            <Button onClick={() => {
                                createControlChain();
                            }}>Create</Button> : <></>}
                    </Modal.Footer>
                </Modal>

                {/* Main Elements */}
                <div className="mt-4">
                    <Row md={1} lg={2} xl={3} xxl={4}>
                        {controlChains.map(c => (
                            <Col className='m-2' key={c.id + "chainkey"}>
                                <ChainDetails superadmin={superadmin} navigate={navigateToNewControlChain} updateChains={getControlChains} name={c.name} SiteGUID={c.SiteGUID} deviceName={c.deviceName} id={c.id} />
                            </Col>))}

                        <Col>
                            <Card
                                border="primary"
                                className="text-center m-2"
                                style={{ width: "22rem" }}
                            >
                                <Card.Body
                                    onClick={() => setNewChainModal(true)}
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: '28px'
                                    }}
                                >
                                    <div className="mx-auto p-3">
                                        <div className="text-muted text-center">
                                            <i className="fas fa-plus"></i>
                                            <div>Add new</div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            <br />
                        </Col>
                    </Row>
                </div>
            </>
        ) : (
            <NoAccessPage />
        )
    ) : (
        <></>
    );
}