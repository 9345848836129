import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { ConfigurationWarning } from "./types";
import { WARNINGS } from './StaticData';

export function ConfigurationWarnings({
  warnings
}: {
  warnings: ConfigurationWarning[],
}) {
  return (
    <div
      className="overflow-auto"
      style={{
        height: "100%",
        width: "100%",
        border: "2px solid black",
        borderRadius: "5px",
      }}
    >
      <h5 style={{ textAlign: "center", paddingTop: "5px" }}>
        CONFIGURATION WARNINGS
      </h5>
      <Table bordered hover style={{fontSize: '0.7rem'}}>
        <tbody>
          {warnings.map((w) => (
            <tr>
              <td style={{ color: w.critical ? "red" : "orange" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {w.description}
                  {w.fix && <Button onClick={w.fix}>FIX</Button>}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
