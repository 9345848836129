import Select from 'react-select';
import React, { useState } from 'react';
import {
  InputGroup,
  Card,
  ToggleButton,
  FormControl,
  ToggleButtonGroup,
  Button,
  Modal,
  Accordion,
} from "react-bootstrap";
import { Container, Row, Col } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SignalSelect from "./SignalSelect";
import SWColourPicker from "../sw-colourpicker";
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import $ from 'jquery';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css'

Chart.register(...registerables);
Chart.register(zoomPlugin);
Chart.register(annotationPlugin);

const optionsDP = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
];

const colorPickerColours = ['#14a8de', '#a0512d', '#944ba3', '#069416', '#002b4f', '#f47920', '#101820'];

var defaultAxisInfo = { id: 1, label: "", position: "left", ttDP: 3, options: [], selectedColourIdx: 0 };

var chart = {}

const selectStyles = {
    menuPortal: base => ({ ...base, zIndex: 9998 }),
    menu: provided => ({ ...provided, zIndex: "9998 !important" })
};

class CustomGraphModal extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            stage: 0,
            start: moment().subtract(7, 'days'),
            end: moment(),
            signals: [],
            datasets: [],
            i: 0,
            axisInfo: [],
            accordionActiveKey: "1",
            siteGUID: props.siteGUID,
            chart: {},
        };

        this.daterange = React.createRef();
    }

    componentDidMount() {
        $.ajax(
            {
                url: '/api/DataExplorationAPI/GetSiteSignals/',
                type: 'get',
                data: { site: this.state.siteGUID },
                success: function (response) {
                    // set default options list 
                    var options = response.value;
                    options.map(option => option.isDisabled = 0);
                    options.map(option => option.isSelected = 0);
                    options.map(option => option.selectedColour = 'rgba(20, 168, 222, 0.8)');
                    defaultAxisInfo.options = options;

                    // Update axis 1 with default options
                    var curAxisInfo = this.state.axisInfo;
                    var newAxisInfo = JSON.parse(JSON.stringify(defaultAxisInfo));
                    curAxisInfo.push(newAxisInfo);

                    this.setState({ axisInfo: curAxisInfo });
                }.bind(this)
            }
        );

    }

    componentDidUpdate() {
        let eles = document.querySelectorAll('[role="dialog"]');
        eles.forEach(function (el) {
            el.removeAttribute('tabindex')
        });
        if (this.state.stage === 0) {
            //$(this.daterange.current).daterangepicker(
            //    function (start, end, label) {
            //        this.setState({ start: start, end: end });
            //        var printStart = start.format();
            //        var printEnd = end.format();
            //        console.log('New date range selected: ' + printStart + ' to ' + printEnd + ' (predefined range: ' + label + ')');
            //    }.bind(this)
            //);

        } else if (this.state.stage === 1) {

        } else if (this.state.stage == 2) {
            var data = {};
            // Remove empty datasets (SignalData requests that failed)
            data.datasets = this.state.datasets.filter(function (dataset) {
                return dataset != null;
            });

            // Generate y axes from user selections
            var yAxes = {};
            for (var i = 0; i < this.state.axisInfo.length; i++) {
                var id = 'y' + this.state.axisInfo[i].id;
                var yAxis = {
                    id: this.state.axisInfo[i].id,
                    type: 'linear',
                    display: true,
                    position: this.state.axisInfo[i].position,
                    title: {
                        display: true,
                        text: this.state.axisInfo[i].label
                    },
                };
                yAxes[id] = yAxis;
            }

            const dateTimeFormatLong = 'DD/MM/YY h:mm a';
            const dateTimeFormatShort = 'DD/MM/YY';
            var config = {
                type: 'line',
                data: data,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    tooltips: {
                        mode: 'nearest',
                        intersect: false,
                        position: 'average',
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var label = tooltipItem.xLabel || '';
                                var axisID = data.datasets[tooltipItem.datasetIndex].yAxisID;
                                var idx = this.getAxisIndex(axisID, this.state.axisInfo);

                                var nDecimalPlaces = this.state.axisInfo[idx].ttDP;
                                var dpFactor = Math.pow(10, nDecimalPlaces);


                                if (label) {
                                    label += ' | ';
                                }

                                label += data.datasets[tooltipItem.datasetIndex].label + ": " + (Math.round(tooltipItem.yLabel * dpFactor) / dpFactor).toFixed(nDecimalPlaces);
                                return label;
                            }.bind(this)
                        }
                    },
                    hover: {
                        mode: 'nearest',
                        intersect: false
                    },
                    scales: {
                        xAxis: {
                            position: 'bottom',
                            type: 'time',
                            ticks: {
                                maxTicksLimit: 20
                            },
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Time'
                            },
                            time: {
                                displayFormats: {
                                    'millisecond': dateTimeFormatLong,
                                    'second': dateTimeFormatLong,
                                    'minute': dateTimeFormatLong,
                                    'hour': dateTimeFormatLong,
                                    'day': dateTimeFormatShort,
                                    'week': dateTimeFormatShort,
                                    'month': dateTimeFormatShort,
                                    'quarter': dateTimeFormatShort,
                                    'year': dateTimeFormatShort,
                                },
                                tooltipFormat: dateTimeFormatLong
                            }
                        },
                        ...yAxes
                    },
                    plugins: {
                        zoom: {
                            pan: {
                                enabled: true,
                                modifierKey: 'ctrl'
                            },
                            zoom: {
                                mode: 'x',
                                wheel: {
                                    enabled: true
                                },
                                drag: {
                                    enabled: true
                                }
                            },
                        }
                    },
                    animation: {
                        onComplete: this.saveImage
                    },
                }
            };

            var ctx = document.getElementById('customgraphcanvas').getContext('2d');
            chart = new Chart(ctx, config);
            this.setState({ stage: 3 });
        } else if (this.state.stage === 3) {
            // Wait for click of back button
        }
    }

    resetZoom = () => {
        chart.resetZoom();
    };

    saveImage = () => {
        if (!$.isEmptyObject(chart)) {
            this.setState({ imageURL: chart.toBase64Image() });
        }
    }

    backClickHander = () => {
        this.setState({ stage: 0, i: 0 });
    }

    createClickHandler = () => {
        function updatedataset(dataset, i) {
            var currentdatasets = this.state.datasets;
            currentdatasets[i] = dataset;
            this.setState({ datasets: currentdatasets });
        }

        var updateDataSetBound = updatedataset.bind(this);

        for (var i = 0; i < this.state.signals.length; i++) {
            (function (i) {
                $.ajax({
                    url: '/api/DataExplorationAPI/GetSignalData/',
                    type: 'get',
                    data: { SignalID: this.state.signals[i]["value"], startTime: moment.utc(this.state.start).format(), endTime: moment.utc(this.state.end).format() },
                    success: function (response) {
                        var data = [];
                        var j;
                        for (j = 0; j < response.length; j++) {
                            var newData = { x: moment(response[j].t), y: response[j].v };
                            data.push(newData);
                        }

                        var dataset = {
                            label: this.state.signals[i]["label"],
                            borderColor: this.state.signals[i].colour,
                            data: data,
                            type: 'line',
                            pointRadius: 1.75,
                            fill: false,
                            tension: 0.1,
                            yAxisID: 'y' + this.state.signals[i].axisID
                        };

                        updateDataSetBound(dataset, i);

                        if (this.state.i === this.state.signals.length - 1) { // Only progress to stage 2 once all ajax requests complete
                            this.setState({ stage: 2 });
                        } else {
                            this.setState({ i: this.state.i + 1 });
                        };

                    }.bind(this),
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.log("Error Receiving Signal Data");
                        console.log(textStatus);
                        console.log(errorThrown);

                        // Insert empty dataset
                        updateDataSetBound(undefined, i);

                        if (this.state.i === this.state.signals.length - 1) { // Only progress to stage 2 once all ajax requests complete
                            this.setState({ stage: 2 });
                        } else {
                            this.setState({ i: this.state.i + 1 });
                        };
                    }.bind(this)
                });
            }.bind(this))(i);
        }
    }

    addAxisClickHandler = () => {
        var newAxisInfo;
        if (this.state.axisInfo.length > 0) {
            newAxisInfo = JSON.parse(JSON.stringify(this.state.axisInfo[this.state.axisInfo.length - 1])); // Initialise axis info to last added axis
            newAxisInfo.id = this.state.axisInfo[this.state.axisInfo.length - 1].id + 1; // New axis should have ID + 1 of last axis
        } else {
            newAxisInfo = JSON.parse(JSON.stringify(defaultAxisInfo));
        }
        // Reset all options to not selected.
        for (var i = 0; i < newAxisInfo.options.length; i++) {
            newAxisInfo.options[i].isSelected = 0;
        }

        var curAxisInfo = Object.assign([], this.state.axisInfo);
        curAxisInfo.push(newAxisInfo);
        this.setState({ axisInfo: curAxisInfo, accordionActiveKey: newAxisInfo.id.toString() })
    }

    getAxisIndex(axisID, axisInfo) {
        var idx;
        for (var j = 0; j < axisInfo.length; j++) {
            if (axisInfo[j].id === axisID) {
                idx = j;
                break;
            }
            idx = -1;
        };
        return idx;
    }


    handleChange(axisID, e) {
        // Get axis info index from id
        var idx = this.getAxisIndex(axisID, this.state.axisInfo);
        var curAxisInfo = Object.assign([], this.state.axisInfo);

        // For each option for this axis
        // if in selectList & isSelected = 0: set isSelected = 1 for this option in this axis, set selectedColour for this option as current selected colour
        // ,set isDisabled = 1 for this option in all axes'
        // if in selectList & isSelected = 1: set isDisabled = 1 for this option in all axes'
        // if not in selectList & isSelected = 1: set isSelected = 0 for this option in this axis, isDisabled = 0 for this option in all axes'
        // if not in selectList & isSelected = 0: do nothing

        if (e && e.length > 0) { // There are selected options to check
            loop1:
            for (var i = 0; i < curAxisInfo[idx].options.length; i++) {
                loop2:
                for (var j = 0; j < e.length; j++) {
                    if (e[j]["value"] === curAxisInfo[idx].options[i]["value"]) { // option is in selectList
                        if (!curAxisInfo[idx].options[i].isSelected) { // This option was not previously selected
                            curAxisInfo[idx].options[i].selectedColour = colorPickerColours[this.state.axisInfo[idx].selectedColourIdx];
                        }
                        // set isSelected = 1 for this option in this axis,
                        curAxisInfo[idx].options[i].isSelected = 1;
                        //  set isDisabled = 1 for this option in all axes'
                        for (var k = 0; k < curAxisInfo.length; k++) {
                            curAxisInfo[k].options[i].isDisabled = 1;
                        }
                        continue loop1; // don't need to check any other options
                    }
                }
                // option is NOT in selectList
                if (curAxisInfo[idx].options[i].isSelected) { // option was previously selected
                    // set isSelected = 0 for this option in this axis, 
                    curAxisInfo[idx].options[i].isSelected = 0;
                    // set isDisabled = 0 for this option in all axes'
                    for (var k = 0; k < curAxisInfo.length; k++) {
                        curAxisInfo[k].options[i].isDisabled = 0;
                    }
                } else { // option was NOT previously selected
                    // do nothing
                }
            }
        } else { // no selected options (user just cleared select list)
            for (var i = 0; i < curAxisInfo[idx].options.length; i++) {
                if (curAxisInfo[idx].options[i].isSelected) { // option was previously selected
                    // set isSelected = 0 for this option in this axis, 
                    curAxisInfo[idx].options[i].isSelected = 0;
                    // set isDisabled = 0 for this option in all axes'
                    for (var k = 0; k < curAxisInfo.length; k++) {
                        curAxisInfo[k].options[i].isDisabled = 0;
                    }
                } else { // option was NOT previously selected
                    // do nothing
                }
            }
        }

        var newSignals = [];

        // Update signal list (used for retrieving data)
        for (var i = 0; i < curAxisInfo.length; i++) {
            for (var j = 0; j < curAxisInfo[i].options.length; j++) {
                if (curAxisInfo[i].options[j].isSelected) {
                    var signal = {
                        label: curAxisInfo[i].options[j].label,
                        value: curAxisInfo[i].options[j].value,
                        axisID: curAxisInfo[i].id,
                        colour: curAxisInfo[i].options[j].selectedColour
                    };
                    newSignals.push(signal);
                }
            }
        }

        // Update axis info state
        this.setState({ axisInfo: curAxisInfo });
        this.forceUpdate();

        if (this.state.axisInfo[idx].selectedColourIdx < colorPickerColours.length - 1) {
            curAxisInfo[idx].selectedColourIdx++;
            this.setState({ signals: newSignals, axisInfo: curAxisInfo });
        } else {
            curAxisInfo[idx].selectedColourIdx = 0;
            this.setState({ signals: newSignals, axisInfo: curAxisInfo });
        }
    }

    handleDPchange(axisID, e) {
        var idx = this.getAxisIndex(axisID, this.state.axisInfo);
        var curAxisInfo = Object.assign([], this.state.axisInfo);
        curAxisInfo[idx].ttDP = e["value"];
        this.setState({ axisInfo: curAxisInfo });
    }

    leftAxisClickHandle(axisID, e) {
        var idx = this.getAxisIndex(axisID, this.state.axisInfo);
        var curAxisInfo = Object.assign([], this.state.axisInfo);
        curAxisInfo[idx].position = "left";
        this.setState({ axisInfo: curAxisInfo });
    };

    rightAxisClickHandle(axisID, e) {
        var idx = this.getAxisIndex(axisID, this.state.axisInfo);
        var curAxisInfo = Object.assign([], this.state.axisInfo);
        curAxisInfo[idx].position = "right";
        this.setState({ axisInfo: curAxisInfo });
    }

    handleYAxisLabelChange(axisID, e) {
        var idx = this.getAxisIndex(axisID, this.state.axisInfo);
        var curAxisInfo = Object.assign([], this.state.axisInfo);
        curAxisInfo[idx].label = e.target.value;
        this.setState({ axisInfo: curAxisInfo });
    }

    removeAxisClickHandler(axisID) {
        var idx = this.getAxisIndex(axisID, this.state.axisInfo);
        var curAxisInfo = Object.assign([], this.state.axisInfo);
        // In all of the other axes, re-enable the options that were selected in this axis.
        for (var i = 0; i < curAxisInfo[idx].options.length; i++) {
            if (curAxisInfo[idx].options[i].isSelected) {
                for (var j = 0; j < curAxisInfo.length; j++) {
                    curAxisInfo[j].options[i].isDisabled = 0;
                }
            }
        }
        curAxisInfo.splice(idx, 1);
        // Set accordion active key to last axis
        var newActiveID = this.state.accordionActiveKey;
        if (curAxisInfo.length > 0) {
            newActiveID = curAxisInfo[curAxisInfo.length - 1].id.toString();
        }
        // Remove signals that were assigned to this axis
        var curSignals = Object.assign([], this.state.signals);
        var filteredSignals = curSignals.filter(signal => signal.axisID !== axisID);
        console.log(filteredSignals);
        this.setState({ signals: filteredSignals, axisInfo: curAxisInfo, accordionActiveKey: newActiveID });
    }

    handleColourChange(axisID, color) {
        var idx = this.getAxisIndex(axisID, this.state.axisInfo);
        var curAxisInfo = Object.assign([], this.state.axisInfo);
        for (var i = 0; i < colorPickerColours.length; i++) {
            if (color.hex === colorPickerColours[i]) {
                curAxisInfo[idx].selectedColourIdx = i;
                this.setState({ axisInfo: curAxisInfo });
                break;
            }
        }
    }

    resetClickHandler = () => {
        var freshAxisInfo = [];
        freshAxisInfo.push(JSON.parse(JSON.stringify(defaultAxisInfo)));
        this.setState({ stage: 0, signals: [], start: moment().subtract(7, 'days'), end: moment(), i: 0, axisInfo: freshAxisInfo, accordionActiveKey: "1" });
    }

    renderAxisCard(axisID) {
        var idx = this.getAxisIndex(axisID, this.state.axisInfo);
        var thisEventKey = axisID.toString();
        return (
            <Accordion.Item key={axisID} eventKey={thisEventKey} >
                <Accordion.Header style={{ cursor: 'pointer' }} onClick={() => this.setState({ accordionActiveKey: thisEventKey })}>
                    <div style={{width: "100%", display: 'flex', justifyContent: 'space-between', paddingRight: '15px'}}>
                        <h4 className="align-items-center verticalalign"> Axis {[axisID]} </h4>
                        <div className="float-right">
                            <Button className="pull-right" variant="danger" size="sm" onClick={() => this.removeAxisClickHandler(axisID)}>
                                <i className="justify-content-end" className="fas fa-times"></i>
                            </Button>
                        </div>
                    </div>
                </Accordion.Header>

                <Accordion.Body eventKey={thisEventKey}>
                    <div className="p-2">Choose a line colour using the selections below, and then add a signal from the selection box. Multiple signals can be selected.</div>
                    
                    <SWColourPicker
                        colorPickerColours={colorPickerColours}
                        color={colorPickerColours[this.state.axisInfo[idx].selectedColourIdx]}
                        handleColourChange={this.handleColourChange.bind(this, axisID)}
                    />

                    <InputGroup className="p-2">
                        <InputGroup.Text id="inputGroup-sizing-default">Signal(s)</InputGroup.Text>
                        <div style={{ width: 'calc(100% - 98px)' }}>
                            <SignalSelect
                                options={this.state.axisInfo[idx].options}
                                onChange={this.handleChange.bind(this, axisID)}
                                value={this.state.axisInfo[idx].options.filter(option => option.isSelected == 1)}
                            />
                        </div>
                    </InputGroup>

                    <InputGroup className="p-2">
                        <InputGroup.Text id="inputGroup-sizing-default">Y-axis label</InputGroup.Text>
                        <FormControl
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            onChange={this.handleYAxisLabelChange.bind(this, axisID)}
                            value={this.state.axisInfo[idx].label}
                            placeholder="Enter label"
                        />
                    </InputGroup>

                    <Container className="p-2">
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="inputGroup-sizing-default">Decimal places when hovering</InputGroup.Text>
                                    <div style={{ width: 'calc(100% - 273px)' }}>
                                        <Select
                                            options={optionsDP}
                                            value={optionsDP[optionsDP.map(function (e) { return e.value }).indexOf(this.state.axisInfo[idx].ttDP)]}
                                            className="basic-single"
                                            classNamePrefix="select"
                                            name="decimalPlaces"
                                            onChange={this.handleDPchange.bind(this, axisID)}
                                            menuPortalTarget={document.querySelector('body')}
                                            styles={selectStyles}
                                        />
                                    </div>
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="inputGroup-sizing-default">Axis label side</InputGroup.Text>
                                    <ToggleButtonGroup type="radio" aria-label="Basic example" name="PositionSelect" className="btn-block" value={this.state.axisInfo[idx].position}>
                                        <ToggleButton value="left" variant="secondary" onClick={this.leftAxisClickHandle.bind(this, axisID)}>Left</ToggleButton>
                                        <ToggleButton value="right" variant="secondary" onClick={this.rightAxisClickHandle.bind(this, axisID)}>Right</ToggleButton>
                                    </ToggleButtonGroup>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Container>
                </Accordion.Body>
            </Accordion.Item>
        );
    }

    renderTooltip = (props) => (
        <Tooltip id="button-tooltip"  {...props} >
            <div>This tool allows you to create multi-axis graphs for a custom date range. Use the date picker at the top to select your date range and use the 'Add axis' button at the bottom to allocate a new graph axis. For each axis, add the signals that you want displayed (note: you can only view signals that your administrator has given you access to).</div>
        </Tooltip>
    );

    handleDatetimerangeUpdate = (start, end, label) => {
        this.setState({ start: start, end: end });
        var printStart = start.format();
        var printEnd = end.format();
        console.log('New date range selected: ' + printStart + ' to ' + printEnd + ' (predefined range: ' + label + ')');
    }

    render() {

        return (
            <Modal
                {...this.props}
                centered
                id={this.props.label}
                role="dialog"
                aria-labelledby={this.props.label}
                aria-hidden="true"
                dialogClassName="modal-90w"
            >
                {this.state.stage == 0 ?
                    <>
                        <Modal.Header>
                            <Modal.Title style={{ display: 'flex', width: "100%" }}>
                                <h3 className="p-2 align-items-center verticalalign">Custom Graph</h3>
                                <div className="p-2" style={{ 'marginLeft': 'auto' }}>
                                    <Button className="btn btn-primary text-light" onClick={this.resetClickHandler}><i className="fas fa-redo"></i>  Reset All</Button>
                                    <>   </>
                                    <OverlayTrigger
                                        placement="bottom-end"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={this.renderTooltip}
                                    >
                                        <Button variant="primary"><i className="fas fa-question"></i></Button>
                                    </OverlayTrigger>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div id="dataExplorationDateRangePicker" className="card m-2">
                                    <div className="card-body p-0">
                                        <div className="input-group">
                                            <div className="d-none d-lg-block d-xl-block bg-primary p-3 text-light"><i className="far fa-2x fa-calendar-alt"></i></div>
                                            <DateRangePicker
                                                onCallback={this.handleDatetimerangeUpdate}
                                                initialSettings={{
                                                    timePicker: true,
                                                    autoApply: true,
                                                    ranges: {
                                                        'Last 24 hours': [moment().subtract(1, 'days'), moment()],
                                                        'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                                                        'Last 7 Days': [moment().subtract(7, 'days'), moment()],
                                                        'Last 30 Days': [moment().subtract(30, 'days'), moment()],
                                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                                    },
                                                    alwaysShowCalendars: true,
                                                    maxDate: moment(),
                                                    startDate: this.state.start,
                                                    endDate: this.state.end,
                                                    opens: "center",
                                                    locale: {
                                                        format: 'DD/MM/YYYY hh:mm a'
                                                    }
                                                }}
                                            >
                                                <input type="text" className="m-3 form-control" name="daterange" />
                                            </DateRangePicker>

                                        </div>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <Accordion activeKey={this.state.accordionActiveKey} >
                                        {this.state.axisInfo.map((info) => this.renderAxisCard(info.id))}
                                        <Card></Card>
                                    </Accordion>
                                </div>

                                <div className="p-2 d-flex justify-content-between">
                                    <Button className="btn btn-primary text-light" block onClick={this.addAxisClickHandler}><i className="fas fa-plus"></i>  Add Axis</Button>
                                    <Button className="btn btn-primary text-light" block onClick={this.createClickHandler} disabled={this.state.signals.length == 0 || this.state.axisInfo.length == 0}><i className="fas fa-chart-area"></i>  Create Graph</Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </>
                    :
                    <>
                        <Modal.Header>
                            <h3 className="p-2 align-items-center verticalalign">Custom Graph</h3>
                            <div className="p-2">
                                <OverlayTrigger
                                    placement="bottom-end"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={this.renderTooltip}
                                >
                                    <Button variant="primary"><i className="fas fa-question"></i></Button>
                                </OverlayTrigger>
                            </div>
                        </Modal.Header>

                        <Modal.Body>
                            <div height="450px">
                                <div className="p-2 d-flex justify-content-between">
                                    <a className="btn btn-primary text-light" onClick={this.backClickHander}><i className="fas fa-chevron-left"></i> Back</a>
                                    <div>
                                        <a className="btn btn-primary text-light" onClick={this.resetZoom}> <i className="fas fa-search-plus"></i> Reset zoom</a>
                                        <>   </>
                                        <a className="btn btn-primary text-light" href={this.state.imageURL} download> <i className="fas fa-download"></i> Save as image</a>
                                    </div>
                                </div>
                                <div class="chart-container" style={{ position: "relative", height: "60vh", width: "65vw" }}>
                                    <canvas id="customgraphcanvas"></canvas>
                                </div>
                            </div>
                        </Modal.Body>
                    </>
                }

            </Modal>
        );
    }
}

function CustomGraphRootElement(props) {
    const [customGraphModalShow, setCustomGraphModalShow] = useState(false);

    return (
        <div>
            <Button variant="primary" onClick={() => setCustomGraphModalShow(true)}>
                Create custom graph
            </Button >
            <CustomGraphModal
                show={customGraphModalShow}
                onHide={() => setCustomGraphModalShow(false)}
                key={props.siteGUID}
                siteGUID={props.siteGUID}
            />
        </div>
    )
}


export default CustomGraphRootElement;
