import React, { useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import GaugeSettingsContainer from "./GaugeSettingsContainer"
import { Gauge as CanvasGauge} from "../reactbenchmarkgauge";
import $ from "jquery";
import { Spinner } from 'react-bootstrap'
import { AuthContext } from '../Analytics';

const ConfigContext = React.createContext();

const DragHandle = sortableHandle(() =>
    <div className="btn d-flex align-items-center btn-group-vertical ms-auto" style={{ height: "100%", border: "1px solid rgb(230 230 230)" }}>
        <i className="fal fa-angle-up" style={{ color: '#002c4e78', display: 'block' }}></i>
        <i className="fal fa-grip-lines" style={{ display: 'block' }}></i>
        <i className="fal fa-angle-down" style={{ color: '#002c4e78', display: 'block' }}></i>
    </div>
);

const defaultGaugeInput = {
    "InsightGaugeInputID": null,
    "InsightGaugeID": null,
    "InputSignalID": null,
    "InputPosition": null,
}

const defaultGaugeOptions = {
    angle: -0.2, // The span of the gauge arc
    lineWidth: 0.3, // The line thickness
    radiusScale: 0.9, // Relative radius
    pointer: {
        length: 0.6, // // Relative to gauge radius
        strokeWidth: 0.035, // The thickness
        color: '#000000' // Fill color
    },
    staticZones: [
        { strokeStyle: 'rgba(255, 153, 51, 0.5)', min: 0, max: 70 },
        { strokeStyle: 'rgba(255,255,0, 0.5)', min: 70, max: 90 },
        { strokeStyle: 'rgba(0, 255, 48, 0.6)', min: 90, max: 100 }
    ],
    staticLabels: {
        font: '10px sans-serif', // Specifies font
        labels: [0, 70, 90, 100], // Print labels at these values
        color: '#000000', // Optional: Label text color
        fractionDigits: 0 // Optional: Numerical precision. 0=round off.
    },
    limitMax: true,
    limitMin: true,
    strokeColor: '#EEEEEE', // to see which ones work best for you
    generateGradient: true,
    highDpiSupport: true // High resolution support
};

function Gauge(props) {
    const [config, setConfig] = useState(null);
    const [signals, setSignals] = useState([]);
    const [value, setValue] = useState("-");
    const [options, setOptions] = useState(defaultGaugeOptions);
    const [noData, setNoData] = useState(true);
    const [loading, setLoading] = useState(true);
    const [canEdit, setCanEdit] = useState(false);
    const { auth } = useContext(AuthContext);

    useEffect(() => {
        var url = '/api/AnalyticsAPI/GetGauge?id=' + props.gaugeID;
        fetch(url).then(response => response.json())
            .then(data => {
                getSignals(data.SiteGUID)
                updateConfig(data)
                var acID = data.AccessControlID;
                var url2 = '/api/AuthorizationAPI/GetAccessLevel?accessControlID='+acID;
                fetch(url2).then(response => response.json())
                    .then(data => {
                        setCanEdit(data==2);
                    });
            });

    }, [props.gaugeID]);

    const getSignals = (SiteGUID) => {
        var url = '/api/LineChartAPI/GetSiteSignals/'

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(SiteGUID)
        }).then(response => response.json())
            .then(data => {
                var siteSignals = data.value;
                setSignals(data.value);
            });
    }

    function updateGaugeName(newGaugeName) {
        var newConfig = JSON.parse(JSON.stringify(config));
        newConfig.GaugeName = newGaugeName;
        setConfig(newConfig);
    }

    function updateMetric(newMetric) {
        var newConfig = JSON.parse(JSON.stringify(config));
        newConfig.EquationID = newMetric;
        setConfig(newConfig);
    }

    function updateRedMax(newRedMax) {
        var newConfig = JSON.parse(JSON.stringify(config));
        newConfig.RedMax = newRedMax;
        setConfig(newConfig)
    }

    function updateYellowMax(newYellowMax) {
        var newConfig = JSON.parse(JSON.stringify(config));
        newConfig.YellowMax = newYellowMax;
        setConfig(newConfig)
    }

    function updateSignal0(selectedSignalID) {
        var newConfig = JSON.parse(JSON.stringify(config));
        // Replace old input where InputPosition == 0 with new signal 
        if (newConfig.InsightGaugeInputs.length > 0){
            for (var i = 0; i<newConfig.InsightGaugeInputs.length; i++){
                if (newConfig.InsightGaugeInputs[i].InputPosition == 0){
                    newConfig.InsightGaugeInputs[i].InputSignalID = selectedSignalID;
                    newConfig.InsightGaugeInputs[i].InsightGaugeInputID = 0;
                    setConfig(newConfig)
                    return
                }
            }
        }

        // Only reachable if an input with InputPosition == 0 doesn't exist, push new
        var newInsightGaugeInput = {}
        newInsightGaugeInput.InputPosition = 0;
        newInsightGaugeInput.InputSignalID = selectedSignalID;
        newInsightGaugeInput.InsightGaugeInputID = 0;
        newInsightGaugeInput.InsightGaugeID = newConfig.GaugeID;
        newConfig.InsightGaugeInputs.push(newInsightGaugeInput);
        setConfig(newConfig);
    }

    function updateSignal1(selectedSignalID) {
        var newConfig = JSON.parse(JSON.stringify(config));
        // Replace old input where InputPosition == 1 with new signal 
        if (newConfig.InsightGaugeInputs.length > 0){
            for (var i = 0; i<newConfig.InsightGaugeInputs.length; i++){
                if (newConfig.InsightGaugeInputs[i].InputPosition == 1){
                    newConfig.InsightGaugeInputs[i].InputSignalID = selectedSignalID;
                    newConfig.InsightGaugeInputs[i].InsightGaugeInputID = 0;
                    setConfig(newConfig)
                    return
                }
            }
        }

        // Only reachable if an input with InputPosition == 1 doesn't exist, push new
        var newInsightGaugeInput = {}
        newInsightGaugeInput.InputPosition = 1;
        newInsightGaugeInput.InputSignalID = selectedSignalID;
        newInsightGaugeInput.InsightGaugeInputID = 0;
        newInsightGaugeInput.InsightGaugeID = newConfig.GaugeID;
        newConfig.InsightGaugeInputs.push(newInsightGaugeInput);
        setConfig(newConfig);

    }

    function updateSignal2(selectedSignalID) {
        var newConfig = JSON.parse(JSON.stringify(config));
        // Replace old input where InputPosition == 2 with new signal 
        if (newConfig.InsightGaugeInputs.length > 0){
            for (var i = 0; i<newConfig.InsightGaugeInputs.length; i++){
                if (newConfig.InsightGaugeInputs[i].InputPosition == 2){
                    newConfig.InsightGaugeInputs[i].InputSignalID = selectedSignalID;
                    newConfig.InsightGaugeInputs[i].InsightGaugeInputID = 0;
                    setConfig(newConfig)
                    return
                }
            }
        }

        // Only reachable if an input with InputPosition == 2 doesn't exist, push new
        var newInsightGaugeInput = {}
        newInsightGaugeInput.InputPosition = 2;
        newInsightGaugeInput.InputSignalID = selectedSignalID;
        newInsightGaugeInput.InsightGaugeInputID = 0;
        newInsightGaugeInput.InsightGaugeID = newConfig.GaugeID;
        newConfig.InsightGaugeInputs.push(newInsightGaugeInput);
        setConfig(newConfig);

    }

    function updateConfig(data) {
        setConfig(data);

        var gaugeOptions = JSON.parse(JSON.stringify(defaultGaugeOptions))
        $.ajax({
            url: '/../api/InsightGaugeValueAPI/' + props.gaugeID + '&' + encodeURIComponent(props.start) + '&' + encodeURIComponent(props.end),
            type: 'get',
            success: function (response) {
                //console.log(response);
                response = JSON.parse(response);
                var nodata = (response === null);
                if (nodata) {
                    setValue("-");
                    setNoData(true);
                } else {
                    setValue(response.Value);
                    setNoData(false);
                }

                gaugeOptions.staticZones = [
                    { strokeStyle: 'rgba(255, 153, 51, 0.5)', min: 0, max: data.RedMax },
                    { strokeStyle: 'rgba(255,255,0, 0.5)', min: data.RedMax, max: data.YellowMax },
                    { strokeStyle: 'rgba(0, 255, 48, 0.6)', min: data.YellowMax, max: 100 }
                ];

                gaugeOptions.staticLabels.labels = [0, data.RedMax, data.YellowMax, 100];

                setOptions(gaugeOptions);
                setLoading(false);
                //StreamwiseGauge.init(data.GaugeID, response.Value, gaugeOptions, noData);
            }
        });
    }

    return (
      <ConfigContext.Provider
        value={{
          config,
          updateGaugeName,
          updateMetric,
          updateRedMax,
          updateYellowMax,
          updateSignal0,
          updateSignal1,
          updateSignal2,
          updateConfig,
          setLoading,
        }}
      >
        <div style={{ minWidth: "400px", display: "inline-block" }}>
          <div class="card border-primary text-center m-3">
            {loading ? (
              <>
                <div
                  class="card-header p-0 text-blue text-truncate h5 gauge-header"
                  title=""
                >
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    {props.gaugeName}
                  </div>
                </div>
                <div class="card-body p-0 d-flex">
                    <div class="flex-grow-1 container justify-content-center gauge-body">
                        <Spinner
                        animation="border"
                        role="status"
                        className="position-absolute"
                        style={{
                            top: "calc(50% + 1rem)",
                            left: "calc(50% - 1rem)",
                        }}
                        />
                    <div class="mt-n5 text-info text-center"></div>
                  </div>
                </div>
              </>
            ) : noData ? (
              <>
                <div
                  class="card-header p-0 text-blue text-truncate h5 gauge-header"
                  title=""
                >
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    {props.gaugeName}
                  </div>
                </div>
                <div class="card-body p-0 d-flex">
                  <div class="no-data-blur-effect flex-grow-1 container justify-content-center gauge-body">
                    <CanvasGauge
                      value={0}
                      minValue={0}
                      maxValue={100}
                      animationSpeed={32}
                      options={options}
                      // any other props are passed through to the canvas element
                      className="mb-2"
                    />
                    <div class="mt-n5 text-info text-center">
                      <span class="h5">{0}</span>
                      <span class="small ml-n1">%</span>
                    </div>
                  </div>
                </div>
                <div class="mx-auto no-data-text mt-3">
                  <div class="text-muted text-center">
                    <i class="fas fa-info-circle"></i>
                    <div>No Data</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  class="card-header p-0 text-blue text-truncate h5 gauge-header"
                  title={config.GaugeName}
                >
                  {auth == 2 ? (
                    <div
                      className="btn-group-vertical"
                      style={{ marginRight: "auto" }}
                    >
                      <div style={{ height: "100%", cursor: "grab" }}>
                        <DragHandle />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    {config.GaugeName}
                  </div>
                  {canEdit ? (
                    <div
                      className="btn-group-vertical"
                      style={{ marginLeft: "auto" }}
                    >
                      <GaugeSettingsContainer
                        gaugeID={config.GaugeID}
                        ready={config !== null}
                        signals={signals.map((s) => (
                          <option value={s.value}>{s.label}</option>
                        ))}
                        onremove={props.onremove}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div class="card-body p-0 d-flex">
                  <div
                    id={config.GaugeID + "-Gauge"}
                    class="flex-grow-1 container justify-content-center gauge-body"
                  >
                    <CanvasGauge
                      value={value}
                      minValue={0}
                      maxValue={100}
                      animationSpeed={32}
                      options={options}
                      // any other props are passed through to the canvas element
                      className="mb-2"
                    />
                    <div class="mt-n5 text-info text-center">
                      <span id={config.GaugeID + "-Label"} class="h5">
                        {value}
                      </span>
                      <span id={config.GaugeID + "-Unit"} class="small ml-n1">
                        {" "}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </ConfigContext.Provider>
    );
}

export { ConfigContext };
export default Gauge