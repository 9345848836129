import React, { memo } from 'react';

import { Handle } from 'react-flow-renderer';
import '../styles.scss'

const PORT_POSITIONS = [10, 10, 13]

export default memo(({ data, isConnectable }) => {
  return (
    <>
      <div>
        Splitter
      </div>
      <Handle
          type="target"
          position="top"
          id='IN'
          style={{ left: PORT_POSITIONS[0], background: '#555' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
      />
      <Handle
          type="source"
          position="bottom"
          id='OUT1'
          style={{ left: PORT_POSITIONS[1], background: '#555' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
      />
      <Handle
          type="source"
          position="right"
          id='OUT2'
          style={{ top: PORT_POSITIONS[2], background: '#555' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
      />
    </>
  );
});