import { useState, useEffect, useCallback, useRef } from "react";
import { addEdge, applyNodeChanges, isNode } from "react-flow-renderer";
import { StreamwiseNode, StreamwiseEdge, ConfigurationWarning, ConfigurationData } from "./types";
import useUndoable from "use-undoable";
import {
  nodes as initialNodes,
  edges as initialEdges,
  nodes,
} from "./example-configuration";
import { v4 as uuid } from 'uuid';

import {
  EXPANSION_SPACING,
  EXPANSION_Y,
  LOCATION_SPACING,
  LOCATION_Y,
  LOCATION_X,
  LOCATION_WIDTH,
  SENSOR_WIDTH,
  ACTUAL_SENSOR_WIDTH,
  SENSOR_SPACING,
  SENSOR_Y,
  SPLITTER_Y,
  SPLITTER_SPACING,
  PORT_SPLITTER_X_A,
  PORT_SPLITTER_X_B,
  PORT_SPLITTER_Y,
  SPLITTER_X,
  AIR_SPLITTER_X,
  AIR_SPLITTER_Y,
  AIR_SPLITTER_SPACING,
  PRIMARY_AIR_SPLITTER_X,
  PRIMARY_AIR_SPLITTER_X_C,
  AIR_PORT_SPLITTER_Y,
  ACTUAL_SENSOR_WIDTH2,
} from "./flowLayoutConstants";
import { SW_EDGE_LIST } from "./Edges";
import { SW_NODE_LIST } from "./Nodes";

// General things to do
// Maintain state of elements (nodes + edges)
// Define methods for interacting with configuration
// Check for any misconfiguration and generate the appropriate warnings

// Methods to define:
// Set application -> initialises elements (nodes + edges)
// Get elements
// Add expansion
// Remove expansion
// Set edge length
// Add sensor location
// Remove sensor location (and all sensors within location)
// Add sensor to location (add required splitters, expansion modules)
// Remove sensor
// Undo
// getCanUndo
// Redo
// getCanRedo
// Get warnings

function useConfigurator() {
  const [elements, setElements, { undo, redo, reset, canUndo, canRedo }] =
    useUndoable({
      nodes: [] as StreamwiseNode[],
      edges: [] as StreamwiseEdge[],
      //   nodes: initialNodes,
      //   edges: initialEdges,
    });

  const elementsRef = useRef({
    nodes: [] as StreamwiseNode[],
    edges: [] as StreamwiseEdge[],
  })

  const [application, setApplication] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [warnings, setWarnings] = useState<ConfigurationWarning[]>([]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadConfig = async (configData: ConfigurationData) => {
    elementsRef.current.edges = configData.edges;
    // Inject in onLengthChange for edges
    elementsRef.current.edges.forEach(e => e.data.onLengthChange = updateLength);

    elementsRef.current.nodes = configData.nodes;
    // Inject in onDelete for nodes
    elementsRef.current.nodes.forEach(n => {
      if (n.data.moduleFamily === 'sensor' || n.data.moduleFamily === 'wirelessSensor') {
        n.data.onDelete = deleteSensor;
      } else if (n.data.moduleFamily === 'location') {
        n.data.onDelete = deleteLocation;
      } else if (n.data.moduleFamily === 'expansion') {
        n.data.onDelete = deleteExpansion;
      }
    })

    triggerUpdateAllElements2();
  }

  const getConfigData = (): string => {
    return JSON.stringify(elements);
  }

  const triggerUpdate = useCallback(
    (t, v) => {
      // To prevent a mismatch of state updates,
      // we'll use the value passed into this
      // function instead of the state directly.
      setElements((e) => ({
        nodes: t === "nodes" ? v : e.nodes,
        edges: t === "edges" ? v : e.edges,
      }));
      if (t === "nodes") {
        elementsRef.current.nodes = v;
      } else if (t === 'edges') {
        elementsRef.current.edges = v;
      }
    },
    [setElements]
  );

  // Clears elements before rendering
  const triggerUpdateAllElements = useCallback(
    () => {
      setElements({
        nodes: [],
        edges: [],
      });
      setTimeout(() => {
        setElements({
          nodes: elementsRef.current.nodes, 
          edges: elementsRef.current.edges,
        });
      }, 0);
    },
    [setElements]
  );
  
  // Does not clear elements before rendering
  const triggerUpdateAllElements2 = useCallback(
    () => {
      setElements({
        nodes: elementsRef.current.nodes, 
        edges: elementsRef.current.edges,
      });
    },
    [setElements]
  );

 useEffect(() => {
    let newWarnings: ConfigurationWarning[] = [];
    // Check for missing cable lengths
    if (
      elementsRef.current.edges
        .filter((e) => e.type === "cable")
        .some((e) => !e.data.length)
    ) {
      newWarnings.push({
        description: "Cable lengths missing",
        critical: true,
      });
    }
    
    // Check for missing air line lengths
    if (
      elementsRef.current.edges
        .filter((e) => e.type === "airLine")
        .some((e) => !e.data.length)
    ) {
      newWarnings.push({
        description: "Air line lengths missing",
        critical: true,
      });
    }
    
    // Check for missing wireless connection lengths
    if (
      elementsRef.current.edges
        .filter((e) => e.type === "wirelessConnection")
        .some((e) => !e.data.length)
    ) {
      newWarnings.push({
        description: "Wireless connection distance missing",
        critical: true,
      });
    }

    // Check for sensors AND no air cleaning
    if (
      elementsRef.current.nodes.some((n) => n.data.moduleFamily === "sensor") &&
      !elementsRef.current.nodes.find((n) => n.type === "airCleaning")
    ) {
      newWarnings.push({
        description: "Air cleaning missing",
        critical: false,
        fix: () => {
          console.log("Adding air cleaning");
          addAirCleaning();
          triggerUpdateAllElements();
        },
      })
    }

    // Check if any wireless distance > 1000m
    if (
      elementsRef.current.edges
        .filter((e) => e.type === "wirelessConnection" && e.data.length)
        .some((e) => e.data.length!! > 1000)
    ) {
      newWarnings.push({
        description: "Wireless connection distance too high (>1000m)",
        critical: false,
      });
    }

    // Warn for poor reception

    setWarnings(newWarnings);
  }, [elements])

  const onNodesChange = useCallback(
    (changes) => {
      triggerUpdate("nodes", applyNodeChanges(changes, elements.nodes));
    },
    [triggerUpdate, elements.nodes]
  );

  const onEdgesChange = useCallback(
    (connection) => {
      triggerUpdate("edges", addEdge(connection, elements.edges));
    },
    [triggerUpdate, elements.edges]
  );

  const removeNode = (nodeId: string) => {
    let j = 0;
    elementsRef.current.nodes.forEach((e,i) => {
      if (e.id !== nodeId){
        if (i!==j) elementsRef.current.nodes[j] = e;
        j++
      }      
    })
    elementsRef.current.nodes.length = j; 
  }

  const removeEdge = (edgeId: string) => {
    let j = 0;
    elementsRef.current.edges.forEach((e,i) => {
      if (e.id !== edgeId){
        if (i!==j) elementsRef.current.edges[j] = e;
        j++
      }      
    })
    elementsRef.current.edges.length = j; 
  }

  const createEdgeDevice = () => {
    // Only create if it doesn't exist already (there can only be one)
    let new_node = JSON.parse(
      JSON.stringify(SW_NODE_LIST.find((n) => n.type === "edgeDevice"))
    ) as StreamwiseNode;
    return new_node;
  };

  const createLocation = () => {
    // Only create if it doesn't exist already (there can only be one)
    let new_node = JSON.parse(
      JSON.stringify(SW_NODE_LIST.find((n) => n.type === "locationGroup"))
    ) as StreamwiseNode;
    new_node.data.onDelete = deleteLocation;
    return new_node;
  };

  const deleteLocation = (id: string) => {
    console.log({locationToDelete: id});
    // Find all children of this location
    let children = elementsRef.current.nodes.filter(n => n.parentNode === id);
    children.forEach(n => {
      // Get all edges that are connected to this child
      let childEdges = elementsRef.current.edges.filter(
        (e) => e.source === n.id || e.target === n.id
      );
      childEdges.forEach(ce => {
        // If edge goes back to a primary splitter, remove the splitter and fix edges
        let edgeSource = elementsRef.current.nodes.find(
          (n) => n.id === ce.source && n.parentNode !== id
        );
        if (edgeSource && (edgeSource?.data.moduleFamily === 'airSplitter' || edgeSource?.data.moduleFamily === 'cableSplitter')) {
          // Edge targetting other destination of splitter needs source redefined
          let cableToOtherDestination = elementsRef.current.edges.find(e => e.source === edgeSource!!.id && e.sourceHandle !== ce.sourceHandle);
          let cableToEdgeSource = elementsRef.current.edges.find(e => e.target === edgeSource!!.id )
          if (cableToOtherDestination && cableToEdgeSource) {
            cableToOtherDestination.source = cableToEdgeSource.source;
            cableToOtherDestination.sourceHandle = cableToEdgeSource.sourceHandle;
            removeEdge(cableToEdgeSource.id);
          }
          removeNode(edgeSource.id)
        }
      })
      // Delete edges
      childEdges.map(ce => {removeEdge(ce.id)});
    })
    // Delete children
    children.map(cn => {removeNode(cn.id)});
    // Delete wireless edge if it exists
    let wirelessEdge = elementsRef.current.edges.find(e => e.source === id && e.sourceHandle === "WIRELESS_OUT");
    if (wirelessEdge) {
      removeEdge(wirelessEdge.id);
    }
    // Delete location node
    removeNode(id);
    triggerUpdateAllElements();
  }

  const createExpansion = (expansionType: string) => {
    let new_node = JSON.parse(
      JSON.stringify(SW_NODE_LIST.find((n) => n.type === expansionType))
    ) as StreamwiseNode;
    new_node.data.onDelete = deleteExpansion;
    return new_node;
  }

  const deleteExpansion = (id: string) => {
    console.log({expansionToDelete: id});
    // Get node
    let expansionModule = elementsRef.current.nodes.find(n => n.id === id);
    if (expansionModule) {
      if (expansionModule.type === "airCleaning") {
        // Remove all air lines
        elementsRef.current.edges
          .filter((e) => e.type === "airLine" || e.type === "shortAirLine")
          .forEach((e) => removeEdge(e.id));
        // Remove all air splitters
        elementsRef.current.nodes
          .filter((n) => n.data.moduleFamily === "airSplitter")
          .forEach((n) => removeNode(n.id));
      } else if (expansionModule.type === "wirelessModule") {
        // Remove all wireless connections
        elementsRef.current.edges
          .filter(
            (e) =>
              e.type === "wirelessConnection" ||
              e.type === "shortWirelessConnection"
          )
          .forEach((e) => removeEdge(e.id));
        // Remove all wireless sensors
        elementsRef.current.nodes
          .filter(
            (n) =>
              n.data.moduleFamily === "wirelessSensor"
          )
          .forEach((n) => removeNode(n.id));
      }
      // Get edge coming to this expansion
      let edgeToThisExpansion = elementsRef.current.edges.find(e => e.target === id && e.targetHandle === 'COM1');
      // Check if there is another expansion after this one
      let edgeToNextExpansion = elementsRef.current.edges.find(e => e.source === id && e.sourceHandle === 'COM2');
      if (edgeToNextExpansion && edgeToThisExpansion) {
        // Connect edge to next expansion back to the node before the current expansion
        edgeToNextExpansion.source = edgeToThisExpansion!!.source;
        edgeToNextExpansion.sourceHandle = edgeToThisExpansion!!.sourceHandle;
      } 
      // Remove edge to this expansion
      if (edgeToThisExpansion) {
        removeEdge(edgeToThisExpansion!!.id);
      }
      // Remove expansion
      removeNode(id);
      triggerUpdateAllElements();
    }
  }

  const createSensor = (sensorType: string) => {
    let new_node = JSON.parse(
      JSON.stringify(SW_NODE_LIST.find((n) => n.type === sensorType))
      ) as StreamwiseNode;
    new_node.data.onDelete = deleteSensor;
    return new_node;
  }
  
  const deleteSensor = (id: string) => {
    console.log({sensorToDelete: id});
    let sensor = elementsRef.current.nodes.find(n => n.id === id);
    if (sensor) {
      if (sensor.data.moduleFamily === 'sensor') {
        // Remove cable going to sensor node
        let cableToSensor = elementsRef.current.edges.find(
          (e) => e.target === id && e.targetHandle === "DATA"
        );
        if (cableToSensor) {
          if (cableToSensor.type === 'cable') {
            // check if source is edge device            
            if (cableToSensor.source === "edgeDevice") {
              // just remove cable
              removeEdge(cableToSensor.id);
            } else { // source must be a splitter which is no longer needed
              let splitter = elementsRef.current.nodes.find(
                (n) =>
                  n.data.moduleFamily === "cableSplitter" &&
                  n.id === cableToSensor!!.source
              );
              if (splitter) {
                // find cable to splitter
                let cableToSplitter = elementsRef.current.edges.find(
                  (n) => 
                    n.target === splitter!!.id &&
                    n.targetHandle === 'IN'
                )
                // find cable to other destination
                let cableToOtherDestination = elementsRef.current.edges.find(
                  (n) => 
                    n.source === splitter!!.id &&
                    n.target !== id
                )
                if (cableToOtherDestination && cableToSplitter) {
                  // change source of cable targetting other destination to splitter
                  cableToOtherDestination!!.source = cableToSplitter?.source;
                  cableToOtherDestination!!.sourceHandle = cableToSplitter?.sourceHandle;
                  // remove cable to splitter
                  removeEdge(cableToSplitter.id);
                }
                // remove cable to sensor
                removeEdge(cableToSensor.id);
                // remove splitter that cable is sourced from
                removeNode(splitter.id);
              }
            }
          } else if (cableToSensor.type === 'shortCable') {
            // source must be a splitter
            let splitter = elementsRef.current.nodes.find(
              (n) =>
                n.data.moduleFamily === "cableSplitter" &&
                n.id === cableToSensor!!.source
            );
            if (splitter) {
              // find cable to splitter
              let cableToSplitter = elementsRef.current.edges.find(
                (n) => 
                  n.target === splitter!!.id &&
                  n.targetHandle === 'IN'
              )
              // find cable to other destination
              let cableToOtherDestination = elementsRef.current.edges.find(
                (n) => 
                  n.source === splitter!!.id &&
                  n.target !== id
              )
              if (cableToOtherDestination) {
                // change target of cable targetting splitter to other destination
                cableToSplitter!!.target = cableToOtherDestination?.target;
                let otherTarget = elementsRef.current.nodes.find(n => n.id === cableToSplitter!!.target);
                cableToSplitter!!.targetHandle = otherTarget?.data.moduleFamily === 'sensor' ? "DATA" : "IN";
                // remove cable to other destination
                removeEdge(cableToOtherDestination.id);
              }
              // remove cable to sensor
              removeEdge(cableToSensor.id);
              // remove splitter that cable is sourced from
              removeNode(splitter.id);
            }
          }
        }

        // Remove air line going to sensor node
        let airLineToSensor = elementsRef.current.edges.find(
          (e) => e.target === id && e.targetHandle === "AIR"
        );
        if (airLineToSensor) {
          if (airLineToSensor.type === 'airLine') {
            // check if source is air cleaning module   
            let airLineSource = elementsRef.current.nodes.find(n => n.id === airLineToSensor!!.source)        
            if (airLineSource?.type === "airCleaning") {
              // just remove air line
              removeEdge(airLineToSensor.id);
            } else { // source must be a splitter which is no longer needed
              let splitter = elementsRef.current.nodes.find(
                (n) =>
                  n.data.moduleFamily === "airSplitter" &&
                  n.id === airLineToSensor!!.source
              );
              if (splitter) {
                // find air line to splitter
                let airLineToSplitter = elementsRef.current.edges.find(
                  (n) => 
                    n.target === splitter!!.id &&
                    n.targetHandle === 'IN'
                )
                // find air line to other destination
                let airLineToOtherDestination = elementsRef.current.edges.find(
                  (n) => 
                    n.source === splitter!!.id &&
                    n.target !== id
                )
                if (airLineToOtherDestination && airLineToSplitter) {
                  // change source of air line targetting other destination to splitter
                  airLineToOtherDestination!!.source = airLineToSplitter?.source;
                  airLineToOtherDestination!!.sourceHandle = airLineToSplitter?.sourceHandle;
                  // remove air line to splitter
                  removeEdge(airLineToSplitter.id);
                }
                // remove air line to sensor
                removeEdge(airLineToSensor.id);
                // remove splitter that air line is sourced from
                removeNode(splitter.id);
              }
            }
          } else if (airLineToSensor.type === 'shortAirLine') {
            // source must be a splitter
            let splitter = elementsRef.current.nodes.find(
              (n) =>
                n.data.moduleFamily === "airSplitter" &&
                n.id === airLineToSensor!!.source
            );
            if (splitter) {
              // find air line to splitter
              let airLineToSplitter = elementsRef.current.edges.find(
                (n) => 
                  n.target === splitter!!.id &&
                  n.targetHandle === 'IN'
              )
              // find air line to other destination
              let airLineToOtherDestination = elementsRef.current.edges.find(
                (n) => 
                  n.source === splitter!!.id &&
                  n.target !== id
              )
              if (airLineToOtherDestination) {
                // change target of air line targetting splitter to other destination
                airLineToSplitter!!.target = airLineToOtherDestination?.target;
                let otherTarget = elementsRef.current.nodes.find(n => n.id === airLineToSplitter!!.target);
                airLineToSplitter!!.targetHandle = otherTarget?.data.moduleFamily === 'sensor' ? "AIR" : "IN";
                // remove air line to other destination
                removeEdge(airLineToOtherDestination.id);
              }
              // remove cable to sensor
              removeEdge(airLineToSensor.id);
              // remove splitter that cable is sourced from
              removeNode(splitter.id);
            }
          }
        }

        // Remove sensor node
        removeNode(id);
      } else if (sensor.data.moduleFamily === 'wirelessSensor') {
        // Get sensor
        let wirelessSensor = elementsRef.current.nodes.find(n => n.id === id);
        if (wirelessSensor) {
          // Remove wirelessConnection to this sensor
          let wirelessConnection = elementsRef.current.edges.find(e => e.source === id);
          if (wirelessConnection) {
            removeEdge(wirelessConnection.id);
          }
          // Remove wirelessConection from this location to receiver module if there are no more wireless sensors left
          if (elementsRef.current.nodes.filter(n => n.parentNode === wirelessSensor?.parentNode).length === 1) {
            let wirelessConnectionToLoc = elementsRef.current.edges.find(e => e.source === wirelessSensor?.parentNode);
            if (wirelessConnectionToLoc) {
              removeEdge(wirelessConnectionToLoc.id);
            }
          }
          // Remove sensor node
          removeNode(id);
        }
      }
      triggerUpdateAllElements();
    }
  }
  
  const createSplitter = (splitterType: string) => {
    let new_node = JSON.parse(
      JSON.stringify(SW_NODE_LIST.find((n) => n.type === splitterType))
      ) as StreamwiseNode;
    return new_node;
  }

  const createEdge = (edgeType: string) => {
    // Init new edge
    let new_edge = JSON.parse(
      JSON.stringify(SW_EDGE_LIST.find((e) => e.type === edgeType))
    ) as StreamwiseEdge;
    new_edge.data.onLengthChange = updateLength;
    return new_edge;
  }

  const updateLength = (edgeId: string, newLength: number) => {
    let edge = elementsRef.current.edges.find(e => e.id === edgeId);
    if (edge) {
      edge.data.length = newLength;
    }
    triggerUpdateAllElements2();
  }

  const isNodePortAvailable = (nodeId: string, portId: string): boolean => {
    // Check if edge device has a free COM port
    if (elementsRef.current.edges.length > 0) {
      for (let i = 0; i < elementsRef.current.edges.length; i++) {
        if (
          (elementsRef.current.edges[i].source === nodeId &&
            elementsRef.current.edges[i].sourceHandle === portId) ||
          (elementsRef.current.edges[i].target === nodeId &&
            elementsRef.current.edges[i].targetHandle === portId)
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const addEdgeDevice = () => {
    let edgeNode = elementsRef.current.nodes.find((n) => n.type === "edgeDevice");
    if (!edgeNode) {
      edgeNode = createEdgeDevice();
      elementsRef.current.nodes = [...elementsRef.current.nodes, edgeNode];
      elementsRef.current.edges = [...elementsRef.current.edges];
    }
  };

  const addExpansionModule = (expansionType: string) => {
    let nodesToAdd = [] as StreamwiseNode[];

    // Find edge node, if can't make a new one
    let edgeNode = elementsRef.current.nodes.find((n) => n.type === "edgeDevice");
    if (!edgeNode) {
      // Add an edge device
      edgeNode = createEdgeDevice();
      nodesToAdd.push(edgeNode);
    }

    // Create new expansion module node
    let new_node = createExpansion(expansionType);
    // Append # to id for uniqueness
    new_node.id = createNodeId(new_node.type);
    // Find where to position this new node
    new_node.position.y = EXPANSION_Y;
    new_node.position.x =
      [...elementsRef.current.nodes, ...nodesToAdd]
        .filter(
          (n) =>
            n.data.moduleFamily === "expansion" ||
            n.data.moduleFamily === "root"
        )
        .reduce((prev, curr) => {
          return curr.position.x + (curr.data.width || 150) > prev
            ? curr.position.x + (curr.data.width || 150)
            : prev;
        }, 0) + EXPANSION_SPACING;

    nodesToAdd.push(new_node);

    // Init new edge
    let new_edge = createEdge('shortCable');

    // Find which port to connect to
    let edgeCOMAvailable = isNodePortAvailable(edgeNode.id, "COM");
    if (edgeCOMAvailable) {
      // Connect to edge device
      new_edge.source = edgeNode.id;
      new_edge.sourceHandle = "COM";
      new_edge.target = new_node.id;
      new_edge.targetHandle = "COM1";
      new_edge.id = createEdgeId(new_edge);
    } else {
      // Find expansion with free COM port
      // crude way to check if previous edge COM check found anything
      let expansionNodes = elementsRef.current.nodes.filter(
        (n) => n.data.moduleFamily === "expansion"
      );
      for (let i = 0; i < expansionNodes.length; i++) {
        let expansionCOMAvailable = isNodePortAvailable(
          expansionNodes[i].id,
          "COM2"
        );

        if (expansionCOMAvailable) {
          // No cable connected to COM2 port of this expansion node
          new_edge.source = expansionNodes[i].id;
          new_edge.sourceHandle = "COM2";
          new_edge.target = new_node.id;
          new_edge.targetHandle = "COM1";
          new_edge.id = createEdgeId(new_edge);
          break;
        }
      }
    }

    elementsRef.current.nodes = [...elementsRef.current.nodes, ...nodesToAdd];
    elementsRef.current.edges = [...elementsRef.current.edges, new_edge];
  };

  const addLocation = (locationName: string) => {
    // Add new react flow group
    let newLocationNode = createLocation();
    // Generate id based on number of locations
    newLocationNode.id = createNodeId('location');
    // Name set to user input that is passed in as argument
    newLocationNode.data.label = locationName;
    newLocationNode.position.y =
      LOCATION_Y +
      SPLITTER_SPACING *
        elementsRef.current.nodes.filter(
          (n) =>
            n.data.moduleFamily === "cableSplitter" &&
            n.parentNode === undefined
        ).length;
    // Position to the right of existing groups if odd location #, left if even location #
    if (
      elementsRef.current.nodes.filter((n) => n.type === "locationGroup").length === 0 ||
      elementsRef.current.nodes.filter((n) => n.type === "locationGroup").length % 2 === 1
    ) {
      // To right
      newLocationNode.position.x =
      elementsRef.current.nodes
      .filter((n) => n.type === "locationGroup")
      .reduce((prev, curr) => {
            let nSensorsInLoc = elementsRef.current.nodes.filter(
              (n) =>
                n.parentNode === curr.id &&
                (n.data.moduleFamily === "sensor" ||
                  n.data.moduleFamily === "wirelessSensor")
            ).length;
            let locationWidth = nSensorsInLoc * ACTUAL_SENSOR_WIDTH2 + SENSOR_SPACING * (nSensorsInLoc + 1);
            return curr.position.x + locationWidth > prev
              ? curr.position.x + locationWidth
              : prev;
          }, LOCATION_X) + LOCATION_SPACING;
    } else {
      // To left
      newLocationNode.position.x =
        elementsRef.current.nodes
          .filter((n) => n.type === "locationGroup")
          .reduce((prev, curr) => {
            return curr.position.x < prev ? curr.position.x : prev;
          }, LOCATION_X + LOCATION_SPACING) -
        LOCATION_SPACING -
        LOCATION_WIDTH;
    }

    elementsRef.current.nodes = [...elementsRef.current.nodes, newLocationNode];
    elementsRef.current.edges = [...elementsRef.current.edges];

    return newLocationNode.id;
  };

  const getRightMostSensorInLocation = (locationId: string): StreamwiseNode => {
    return elementsRef.current.nodes
      .filter(
        (n) =>
          n.parentNode === locationId &&
          (n.data.moduleFamily === "sensor" ||
            n.data.moduleFamily === "wirelessSensor")
      )
      .reduce(
        (prev, curr) => {
          return curr.position.x > prev.position.x ? curr : prev;
        },
        {
          position: { x: -9999, y: 0 },
          data: { name: "", sku: "", moduleFamily: "" },
          id: "",
        }
      );
  }

  const getRightMostSensorInLocation2 = (locationId: string, moduleFamily: string): StreamwiseNode => {
    return elementsRef.current.nodes
      .filter(
        (n) =>
          n.parentNode === locationId &&
          (n.data.moduleFamily === moduleFamily)
      )
      .reduce(
        (prev, curr) => {
          return curr.position.x > prev.position.x ? curr : prev;
        },
        {
          position: { x: -9999, y: 0 },
          data: { name: "", sku: "", moduleFamily: "" },
          id: "",
        }
      );
  }

  const addSensor = (locationId: string, sensorType: string) => {
    let locationNode = elementsRef.current.nodes.find((n) => n.id === locationId);
    if (locationNode !== undefined) {
      let nodesToAdd = [] as StreamwiseNode[];
      let edgesToAdd = [] as StreamwiseEdge[];

      // Find edge device node, if can't make a new one
      let edgeNode = elementsRef.current.nodes.find((n) => n.type === "edgeDevice");
      if (!edgeNode) {
        // Add an edge device
        edgeNode = createEdgeDevice();
        nodesToAdd.push(edgeNode);
      }

      // LOCATION NODE ADJUSTMENTS //
      // Make location wider by width of sensor, but only if there are sensors already in this location
      if (
        elementsRef.current.nodes.filter((n) => n.parentNode === locationId).length > 0
      ) {
        let locationWidth = locationNode.style!!.width;
        if (typeof locationWidth === "number") {
          locationWidth += SENSOR_WIDTH;
        }
        elementsRef.current.nodes.find((n) => n.id === locationId)!!.style!!.width =
          locationWidth;

        // Push locations right of this location further right by width of sensor
        elementsRef.current.nodes
          .filter(
            (n) =>
              n.position.x > locationNode!!.position.x &&
              n.data.moduleFamily === "location"
          )
          .forEach((n) => (n.position.x += SENSOR_WIDTH));
      }

      // SENSOR NODE //
      // Create new node for sensor with parent that is the location
      let newSensorNode = createSensor(sensorType);
      newSensorNode.parentNode = locationId;
      // Set id to number of sensors of this type + 1
      newSensorNode.id = createNodeId(sensorType);
      // sensor position set to right of rightmost sensor in this location
      newSensorNode.position.y = SENSOR_Y;
      newSensorNode.position.x =
        elementsRef.current.nodes
          .filter(
            (n) =>
              n.parentNode === locationId &&
              (n.data.moduleFamily === "sensor" ||
                n.data.moduleFamily === "wirelessSensor")
          )
          .reduce((prev, curr) => {
            console.log({ wid: curr.width });
            return curr.position.x + ACTUAL_SENSOR_WIDTH2 > prev
              ? curr.position.x + ACTUAL_SENSOR_WIDTH2
              : prev;
          }, 0) + SENSOR_SPACING;
      console.log({ xPos: newSensorNode.position.x });
      nodesToAdd.push(newSensorNode);

      // CABLES //
      // Check if this location already has 1 or more sensors
      let existingSensors = elementsRef.current.nodes.filter(
        (n) => n.parentNode === locationId && n.data.moduleFamily === "sensor"
      ).length > 0
      if (existingSensors) {
        // Split cable that goes to right most sensor in this location
        let rightMostSensor = getRightMostSensorInLocation2(locationId, 'sensor');

        // Create new splitter node
        let splitterTypeToUse =
          elementsRef.current.nodes.filter(
            (n) =>
              n.data.moduleFamily === "cableSplitter" &&
              n.parentNode === locationId
          ).length > 0
            ? "twoSplitterB"
            : "twoSplitter";
        console.log({ splitterTypeToUse });
        let newSplitterNode = createSplitter(splitterTypeToUse);
        newSplitterNode.id = createNodeId(splitterTypeToUse);
        newSplitterNode.parentNode = locationId;
        newSplitterNode.position.y = SPLITTER_Y;
        newSplitterNode.position.x = rightMostSensor.position.x + (splitterTypeToUse === 'twoSplitter' ? SPLITTER_X : 0);
        nodesToAdd.push(newSplitterNode);

        // Get cable that connects to the right most sensor, this is the cable to split
        let cableToSplit: StreamwiseEdge = elementsRef.current.edges.find(
          (e) => (e.type === "cable" || e.type === 'shortCable') && e.target === rightMostSensor.id
        )!!;
        // Change target of cable to split so that it connects to new splitter instead
        cableToSplit.target = newSplitterNode.id;
        cableToSplit.targetHandle = "IN";

        // Add new cable that goes from the splitter to the rightMostSensor
        let new_edge1 = createEdge('shortCable');
        new_edge1.source = newSplitterNode.id;
        new_edge1.sourceHandle = "OUT1";
        new_edge1.target = rightMostSensor.id;
        new_edge1.targetHandle = "DATA";
        new_edge1.id = createEdgeId(new_edge1);

        // Add new cable that goes from the splitter to the new sensor
        let new_edge2 = createEdge('shortCable');
        new_edge2.source = newSplitterNode.id;
        new_edge2.sourceHandle = "OUT2";
        new_edge2.target = newSensorNode.id;
        new_edge2.targetHandle = "DATA";
        new_edge2.id = createEdgeId(new_edge2);
        
        edgesToAdd.push(new_edge1);
        edgesToAdd.push(new_edge2);
      } else {
        // New location, new cable needed (or split needed) from edge device
        // Init new edge
        let new_edge = createEdge('cable');
        // Check if edgeDevice has a free sensor port (A or B)
        // Find which port to connect to
        let edgeAAvailable = isNodePortAvailable("edgeDevice", "A");
        let edgeBAvailable = isNodePortAvailable("edgeDevice", "B");

        if (edgeAAvailable) {
          // Connect to edge device port A
          new_edge.source = edgeNode.id;
          new_edge.sourceHandle = "A";
          new_edge.target = newSensorNode.id;
          new_edge.targetHandle = "DATA";
          new_edge.id = createEdgeId(new_edge);
          edgesToAdd.push(new_edge);
        } else if (edgeBAvailable) {
          // Connect to edge device port B
          new_edge.source = edgeNode.id;
          new_edge.sourceHandle = "B";
          new_edge.target = newSensorNode.id;
          new_edge.targetHandle = "DATA";
          new_edge.id = createEdgeId(new_edge);
          edgesToAdd.push(new_edge);
        } else {
          // Need to split either the cable from A or B
          // Shift all locations and existing splitters down to make space for new splitter
          elementsRef.current.nodes
            .filter(
              (n) =>
                n.type === "locationGroup" ||
                (n.data.moduleFamily === "cableSplitter" &&
                  n.parentNode === undefined)
            )
            .forEach((n) => (n.position.y += SPLITTER_SPACING));
          // Split cable from edge COM ports, port A if odd location #, port B if even location #
          let portToSplitFrom =
            elementsRef.current.nodes.filter((n) => n.type === "locationGroup").length %
              2 ===
            1
              ? "A"
              : "B";
          let splitterNodeToAdd: StreamwiseNode;
          if (portToSplitFrom === "A") {
            // Add splitter node below port to split
            splitterNodeToAdd = createSplitter('twoSplitterC');
            splitterNodeToAdd.position.x = PORT_SPLITTER_X_A;
          } else {
            // portToSplitFrom === 'B'
            // Add splitter node below port to split
            splitterNodeToAdd = createSensor('twoSplitter');
            splitterNodeToAdd.position.x = PORT_SPLITTER_X_B;
          }
          splitterNodeToAdd.id = createNodeId("cableSplitter");
          splitterNodeToAdd.position.y = PORT_SPLITTER_Y;
          nodesToAdd.push(splitterNodeToAdd);
          // Find cable that is sourced from portToSplitFrom
          let cableFromPortToSplitFrom = elementsRef.current.edges.find(
            (e) =>
              e.source === edgeNode!!.id && e.sourceHandle === portToSplitFrom
          )!!;

          // Create new cable from splitter out to current target of cable from portToSplitFrom
          let new_edge1 = createEdge('cable');
          new_edge1.source = splitterNodeToAdd.id;
          new_edge1.sourceHandle = "OUT1";
          new_edge1.target = cableFromPortToSplitFrom.target;
          new_edge1.targetHandle = cableFromPortToSplitFrom.targetHandle;
          new_edge1.id = createEdgeId(new_edge1);

          // Change target of cable from portToSplitFrom to splitter in
          cableFromPortToSplitFrom.target = splitterNodeToAdd.id;
          cableFromPortToSplitFrom.targetHandle = "IN";
          // Change type to short cable
          cableFromPortToSplitFrom.type = 'shortCable';

          // Create cable from new splitter to new sensor
          let new_edge2 = createEdge('cable');
          new_edge2.source = splitterNodeToAdd.id;
          new_edge2.sourceHandle = "OUT2";
          new_edge2.target = newSensorNode.id;
          new_edge2.targetHandle = "DATA";
          new_edge2.id = createEdgeId(new_edge2);

          edgesToAdd.push(new_edge1);
          edgesToAdd.push(new_edge2);
        }
      }

      // AIR CLEANING //
      // Only worry about air cleaning if there is an air cleaning module
      let airCleaningModule = elementsRef.current.nodes.find((n) => n.type === "airCleaning");
      if (airCleaningModule) {
        // Check if this is the only sensor in the location
        if (existingSensors) {
          let rightMostSensor = getRightMostSensorInLocation(locationId)
          let airSplitterToUse = (elementsRef.current.nodes.filter(
            (n) => n.parentNode === locationId && n.data.moduleFamily === "sensor"
          ).length === 1) ? 'airSplitter' : 'airSplitterB'
          // Create new splitter node
          let newSplitterNode = createSplitter(airSplitterToUse);
          newSplitterNode.id = createNodeId("airSplitter-");
          newSplitterNode.parentNode = locationId;
          newSplitterNode.position.y = AIR_SPLITTER_Y;
          newSplitterNode.position.x = rightMostSensor.position.x + AIR_SPLITTER_X - (elementsRef.current.nodes.filter(
            (n) => n.parentNode === locationId && n.data.moduleFamily === "sensor"
          ).length === 1 ? 0 : 10);
          nodesToAdd.push(newSplitterNode);

          // Get air line that connects to the right most sensor, this is the air line to split
          let cableToSplit: StreamwiseEdge = elementsRef.current.edges.find(
            (e) => (e.type === "airLine" || e.type === "shortAirLine") && e.target === rightMostSensor.id
          )!!;
          // Change target of cable to split so that it connects to new splitter instead
          cableToSplit.target = newSplitterNode.id;
          cableToSplit.targetHandle = "IN";

          // Add new cable that goes from the splitter to the rightMostSensor
          let new_edge1 = createEdge('shortAirLine');
          new_edge1.source = newSplitterNode.id;
          new_edge1.sourceHandle = "OUT1";
          new_edge1.target = rightMostSensor.id;
          new_edge1.targetHandle = "AIR";
          new_edge1.id = createEdgeId(new_edge1);

          // Add new cable that goes from the splitter to the new sensor
          let new_edge2 = createEdge('shortAirLine');
          new_edge2.source = newSplitterNode.id;
          new_edge2.sourceHandle = "OUT2";
          new_edge2.target = newSensorNode.id;
          new_edge2.targetHandle = "AIR";
          new_edge2.id = createEdgeId(new_edge2);

          edgesToAdd.push(new_edge1);
          edgesToAdd.push(new_edge2);
        } else {
          // Check if air cleaning module AirOut is available
          let airOutAvailable =
            isNodePortAvailable(airCleaningModule.id, "AIR OUT");
          if (airOutAvailable) {
            // Connect straight to air cleaning module
            let new_edge2 = createEdge('airLine');
            new_edge2.source = airCleaningModule.id;
            new_edge2.sourceHandle = "AIR OUT";
            new_edge2.target = newSensorNode.id;
            new_edge2.targetHandle = "AIR";
            new_edge2.id = createEdgeId(new_edge2);
            edgesToAdd.push(new_edge2);
          } else {
            // Need to split air cleaning from the source
            // Shift any other primary air cleaning splitters down 
            elementsRef.current.nodes.filter(n => n.data.moduleFamily === 'airSplitter' && n.parentNode === undefined).forEach(s => {
              s.position.y += SPLITTER_SPACING;
            })
            let splitterTypeToUse =
              locationNode.position.x + SENSOR_SPACING + SENSOR_WIDTH > airCleaningModule.position.x
                ? "airSplitter"
                : "airSplitterC";
            // add splitter below air cleaning module
            let splitter_i_primary = createSplitter(splitterTypeToUse);
            splitter_i_primary.id = createNodeId('primarySplitter');
            splitter_i_primary.position.x =
              airCleaningModule.position.x + (splitterTypeToUse === "airSplitter"
                ? PRIMARY_AIR_SPLITTER_X
                : PRIMARY_AIR_SPLITTER_X_C);
            splitter_i_primary.position.y = AIR_PORT_SPLITTER_Y;
            nodesToAdd.push(splitter_i_primary);
            
            let airLineFromAirCleaning = elementsRef.current.edges.find(
              (e) => e.source === airCleaningModule!!.id && e.sourceHandle === 'AIR OUT'
            );
            if (airLineFromAirCleaning) {
              // First need to check what type the original target was to decide which type of cable to use
              let airLineTarget = nodesToAdd.find(n => n.id === airLineFromAirCleaning!!.target);
              if (!airLineTarget) {
                airLineTarget = elementsRef.current.nodes.find(n => n.id === airLineFromAirCleaning!!.target);
              }
              let airLineTypeToUse = airLineTarget!!.parentNode !== undefined ? 'airLine' : 'shortAirLine';
              let newAirLineFromNewSplitter = createEdge(airLineTypeToUse);
              newAirLineFromNewSplitter.source = splitter_i_primary.id;
              newAirLineFromNewSplitter.sourceHandle = (splitterTypeToUse === "airSplitter"
              ? 'OUT1'
              : 'OUT2');
              newAirLineFromNewSplitter.target = airLineFromAirCleaning.target;
              newAirLineFromNewSplitter.targetHandle = airLineFromAirCleaning.targetHandle;
              newAirLineFromNewSplitter.id = createEdgeId(newAirLineFromNewSplitter);
              edgesToAdd.push(newAirLineFromNewSplitter);

              // Change target of air line that is sourced from air cleaning module to new splitter
              airLineFromAirCleaning.target = splitter_i_primary.id;
              airLineFromAirCleaning.targetHandle = 'IN';
              // Change type to shortAirLine
              airLineFromAirCleaning.type = 'shortAirLine'
            }

            // Create new air line from the new splitter to the AirIn port of the new sensor
            let newAirLineToSensor = createEdge('airLine');
            newAirLineToSensor.source = splitter_i_primary.id;
            newAirLineToSensor.sourceHandle = (splitterTypeToUse === "airSplitter"
            ? 'OUT2'
            : 'OUT1');;
            newAirLineToSensor.target = newSensorNode.id;
            newAirLineToSensor.targetHandle = 'AIR';
            newAirLineToSensor.id = createEdgeId(newAirLineToSensor);
            edgesToAdd.push(newAirLineToSensor);
          }
        }
      }

      elementsRef.current.nodes = [...elementsRef.current.nodes, ...nodesToAdd];
      elementsRef.current.edges = [...elementsRef.current.edges, ...edgesToAdd];
    }
  };

  const addWirelessSensor = (locationId: string, sensorType: string) => {
    let locationNode = elementsRef.current.nodes.find((n) => n.id === locationId);
    if (locationNode !== undefined) {
      let nodesToAdd = [] as StreamwiseNode[];
      let edgesToAdd = [] as StreamwiseEdge[];

      // Find wireless module, if can't make a new one
      let wirelessModule = elementsRef.current.nodes.find(n => n.type === 'wirelessModule');
      if (!wirelessModule) {
        addExpansionModule("wirelessModule");
        wirelessModule = elementsRef.current.nodes.find(n => n.type === 'wirelessModule');
      }

      // LOCATION NODE ADJUSTMENTS //
      // Make location wider by width of sensor, but only if there are sensors already in this location
      if (
        elementsRef.current.nodes.filter((n) => n.parentNode === locationId).length > 0
      ) {
        let locationWidth = locationNode.style!!.width;
        if (typeof locationWidth === "number") {
          locationWidth += SENSOR_WIDTH;
        }
        elementsRef.current.nodes.find((n) => n.id === locationId)!!.style!!.width =
          locationWidth;

        // Push locations right of this location further right by width of sensor
        elementsRef.current.nodes
          .filter(
            (n) =>
              n.position.x > locationNode!!.position.x &&
              n.data.moduleFamily === "location"
          )
          .forEach((n) => (n.position.x += SENSOR_WIDTH));
      }

      // WIRELESS SENSOR NODE //
      // Create new node for wireless sensor with parent that is the location
      let newSensorNode = createSensor(sensorType);
      newSensorNode.parentNode = locationId;
      // Set id to number of sensors of this type + 1
      newSensorNode.id = createNodeId(sensorType);
      // sensor position set to right of rightmost sensor in this location
      newSensorNode.position.y = SENSOR_Y;
      newSensorNode.position.x =
        elementsRef.current.nodes
          .filter(
            (n) =>
              n.parentNode === locationId &&
              (n.data.moduleFamily === "sensor" ||
                n.data.moduleFamily === "wirelessSensor")
          )
          .reduce((prev, curr) => {
            return curr.position.x + ACTUAL_SENSOR_WIDTH2 > prev
              ? curr.position.x + ACTUAL_SENSOR_WIDTH2
              : prev;
          }, 0) + SENSOR_SPACING;
      nodesToAdd.push(newSensorNode);

      // WIRELESS CONNECTION EDGE //
      // Edge to location 'aggregation' point
      let newWirelessConnection = createEdge('shortWirelessConnection');
      newWirelessConnection.source = newSensorNode.id;
      newWirelessConnection.sourceHandle = "WIRELESS";
      newWirelessConnection.target = locationId;
      newWirelessConnection.targetHandle = "WIRELESS_IN";
      newWirelessConnection.id = createEdgeId(newWirelessConnection);
      
      if (isNodePortAvailable(locationId, 'WIRELESS_OUT')) {
        // Edge to wireless module
        let newWirelessConnection = createEdge('wirelessConnection');
        newWirelessConnection.source = locationId;
        newWirelessConnection.sourceHandle = "WIRELESS_OUT";
        newWirelessConnection.target = wirelessModule!!.id;
        newWirelessConnection.targetHandle = "WIRELESS";
        newWirelessConnection.id = createEdgeId(newWirelessConnection);
        edgesToAdd.push(newWirelessConnection);
      }

      edgesToAdd.push(newWirelessConnection);

      elementsRef.current.nodes = [...elementsRef.current.nodes, ...nodesToAdd];
      elementsRef.current.edges = [...elementsRef.current.edges, ...edgesToAdd];
    }
  };

  const addAirCleaning = () => {
    // Only do so if air cleaning module isn't already available
    if (!elementsRef.current.nodes.find(n => n.type === 'airCleaning')){
      let nodesToAdd = [] as StreamwiseNode[];
      let edgesToAdd = [] as StreamwiseEdge[];

      // Find edge node, if can't make a new one
      let edgeNode = elementsRef.current.nodes.find((n) => n.type === "edgeDevice");
      if (!edgeNode) {
        // Add an edge device
        edgeNode = createEdgeDevice();
        nodesToAdd.push(edgeNode);
      }

      // Create new expansion module node
      let new_node = createExpansion('airCleaning');
      // Append # to id for uniqueness
      new_node.id = createNodeId(new_node.type);
      // Find where to position this new node
      new_node.position.y = EXPANSION_Y;
      new_node.position.x =
        [...elementsRef.current.nodes, ...nodesToAdd]
          .filter(
            (n) =>
              n.data.moduleFamily === "expansion" ||
              n.data.moduleFamily === "root"
          )
          .reduce((prev, curr) => {
            return curr.position.x + curr.width > prev
              ? curr.position.x + curr.width
              : prev;
          }, 0) + EXPANSION_SPACING;

      nodesToAdd.push(new_node);

      // Init new edge
      let expansionCable = createEdge('shortCable');
      // Find which port to connect to
      let edgeCOMAvailable = isNodePortAvailable(edgeNode.id, "COM");
      if (edgeCOMAvailable) {
        // Connect to edge device
        expansionCable.source = edgeNode.id;
        expansionCable.sourceHandle = "COM";
        expansionCable.target = new_node.id;
        expansionCable.targetHandle = "COM1";
        expansionCable.id = createEdgeId(expansionCable);

      } else {
        // Find expansion with free COM port
        // crude way to check if previous edge COM check found anything
        let expansionNodes = elementsRef.current.nodes.filter(
          (n) => n.data.moduleFamily === "expansion"
        );
        for (let i = 0; i < expansionNodes.length; i++) {
          let expansionCOMAvailable = isNodePortAvailable(
            expansionNodes[i].id,
            "COM2"
          );

          if (expansionCOMAvailable) {
            // No cable connected to COM2 port of this expansion node
            expansionCable.source = expansionNodes[i].id;
            expansionCable.sourceHandle = "COM2";
            expansionCable.target = new_node.id;
            expansionCable.targetHandle = "COM1";
            expansionCable.id = createEdgeId(expansionCable);
            break;
          }
        }
      }
      edgesToAdd.push(expansionCable);
      // Need as many primary splitters as (locations - 1)
      // Need as many secondary splitters (i.e. within location) as (sensorsInLocation - 1)

      elementsRef.current.nodes.filter(n => n.type === 'locationGroup').forEach(l => {
        let sensorsInLoc = elementsRef.current.nodes.filter(
          (n) => n.data.moduleFamily === "sensor" && n.parentNode === l.id
        );
        let airInNodeId = '';
        let airInPortId = '';
        // If no sensors, don't do anything
        if (sensorsInLoc.length === 1) {
          // If one sensor, store AirIn port to later be routed back to air cleaning module
          airInNodeId = sensorsInLoc[0].id;
          airInPortId = 'AIR';
        } else if (sensorsInLoc.length > 1) {
          // add an airSplitter above the first sensor
          let splitter1 = createSplitter('airSplitter');
          splitter1.id = createNodeId("airSplitter");
          splitter1.parentNode = l.id;
          splitter1.position.x = sensorsInLoc[0].position.x + AIR_SPLITTER_X;
          splitter1.position.y = AIR_SPLITTER_Y;
          nodesToAdd.push(splitter1);
          // Store airTee AirIn port to later be rated back to air cleaning module
          airInNodeId = splitter1.id;
          airInPortId = 'IN';

          // Connect air line to first sensor from splitter
          let newAirLine1 = createEdge('shortAirLine');
          newAirLine1.source = splitter1.id;
          newAirLine1.sourceHandle = "OUT1";
          newAirLine1.target = sensorsInLoc[0].id;
          newAirLine1.targetHandle = "AIR";
          newAirLine1.id = createEdgeId(newAirLine1);

          edgesToAdd.push(newAirLine1);

          let lastSplitterId = splitter1.id;

          for (var i = 1; i < sensorsInLoc.length - 1; i++) {
            // add an airSplitterB above every other sensor
            let splitter_i = createSplitter('airSplitterB');
            splitter_i.id = createNodeId("airSplitter");
            splitter_i.parentNode = l.id;
            splitter_i.position.x = sensorsInLoc[i].position.x + AIR_SPLITTER_X - 10;
            splitter_i.position.y = AIR_SPLITTER_Y;
            nodesToAdd.push(splitter_i);
            
            // Connect airSplitter to each previous splitter
            let newSplittersAirLine = createEdge('shortAirLine');
            newSplittersAirLine.source = lastSplitterId;
            newSplittersAirLine.sourceHandle = "OUT2";
            newSplittersAirLine.target = splitter_i.id;
            newSplittersAirLine.targetHandle = "IN";
            newSplittersAirLine.id = createEdgeId(newSplittersAirLine);

            edgesToAdd.push(newSplittersAirLine);

            // Connect air line from splitter Out1 to sensor
            let newAirLine_i = createEdge('shortAirLine');
            newAirLine_i.source = splitter_i.id;
            newAirLine_i.sourceHandle = "OUT1";
            newAirLine_i.target = sensorsInLoc[i].id;
            newAirLine_i.targetHandle = "AIR";
            newAirLine_i.id = createEdgeId(newAirLine_i);
            edgesToAdd.push(newAirLine_i);
            lastSplitterId = splitter_i.id;
          }
          // add air line from last splitter Out2 to last sensor
          let newAirLine_i = createEdge('shortAirLine');
          newAirLine_i.source = lastSplitterId;
          newAirLine_i.sourceHandle = "OUT2";
          newAirLine_i.target = sensorsInLoc[i].id;
          newAirLine_i.targetHandle = "AIR";
          newAirLine_i.id = createEdgeId(newAirLine_i);

          edgesToAdd.push(newAirLine_i);
        }
        // Check if air cleaning module AirOut is available
        let airOutAvailable =
          isNodePortAvailable(new_node.id, "AIR OUT") &&
          !edgesToAdd.some((e) => e.sourceHandle === "AIR OUT");
        
        if (airInNodeId !== '' && airInPortId !== '') {
          if (airOutAvailable) {
            // connect to AirIn port stored previously
            let newAirLine_i = createEdge('airLine');
            newAirLine_i.source = new_node.id;
            newAirLine_i.sourceHandle = 'AIR OUT';
            newAirLine_i.target = airInNodeId;
            newAirLine_i.targetHandle = airInPortId;
            newAirLine_i.id = createEdgeId(newAirLine_i);
            edgesToAdd.push(newAirLine_i);
          } else {
            // Shift any other primary air cleaning splitters down 
            elementsRef.current.nodes.filter(n => n.data.moduleFamily === 'airSplitter' && n.parentNode === undefined).forEach(s => {
              s.position.y += SPLITTER_SPACING;
            })
            nodesToAdd.filter(n => n.data.moduleFamily === 'airSplitter' && n.parentNode === undefined).forEach(s => {
              s.position.y += SPLITTER_SPACING;
            })
            let splitterTypeToUse =
              l.position.x + SENSOR_SPACING + SENSOR_WIDTH > new_node.position.x
                ? "airSplitter"
                : "airSplitterC";
            // add splitter below air cleaning module
            let splitter_i_primary = createSplitter(splitterTypeToUse);
            splitter_i_primary.id = createNodeId('primarySplitter');
            splitter_i_primary.position.x =
              new_node.position.x + (splitterTypeToUse === "airSplitter"
                ? PRIMARY_AIR_SPLITTER_X
                : PRIMARY_AIR_SPLITTER_X_C);
            splitter_i_primary.position.y = AIR_PORT_SPLITTER_Y;
            nodesToAdd.push(splitter_i_primary);
            
            let airLineFromAirCleaning = edgesToAdd.find(
              (e) => e.source === new_node.id && e.sourceHandle === 'AIR OUT'
            );
            if (airLineFromAirCleaning) {
              // Create new air line from the new splitter to the target of the original air line sourced from ACM
              // First need to check what type the original target was to decide which type of cable to use
              let airLineTarget = nodesToAdd.find(n => n.id === airLineFromAirCleaning!!.target);
              if (!airLineTarget) {
                airLineTarget = elementsRef.current.nodes.find(n => n.id === airLineFromAirCleaning!!.target);
              }
              let airLineTypeToUse = airLineTarget!!.parentNode !== undefined ? 'airLine' : 'shortAirLine';
              let newAirLineFromNewSplitter = createEdge(airLineTypeToUse);
              newAirLineFromNewSplitter.source = splitter_i_primary.id;
              newAirLineFromNewSplitter.sourceHandle = (splitterTypeToUse === "airSplitter"
              ? 'OUT1'
              : 'OUT2');
              newAirLineFromNewSplitter.target = airLineFromAirCleaning.target;
              newAirLineFromNewSplitter.targetHandle = airLineFromAirCleaning.targetHandle;
              newAirLineFromNewSplitter.id = createEdgeId(newAirLineFromNewSplitter);
              edgesToAdd.push(newAirLineFromNewSplitter);

              // Change target of air line that is sourced from air cleaning module to new splitter
              airLineFromAirCleaning.target = splitter_i_primary.id;
              airLineFromAirCleaning.targetHandle = 'IN';
              // Change type to short air line
              airLineFromAirCleaning.type = 'shortAirLine';
            }

            // Create new air line from the new splitter to the AirIn port stored previously 
            let newAirLineToSensor = createEdge('airLine');
            newAirLineToSensor.source = splitter_i_primary.id;
            newAirLineToSensor.sourceHandle = (splitterTypeToUse === "airSplitter"
            ? 'OUT2'
            : 'OUT1');;
            newAirLineToSensor.target = airInNodeId;
            newAirLineToSensor.targetHandle = airInPortId;
            newAirLineToSensor.id = createEdgeId(newAirLineToSensor);
            edgesToAdd.push(newAirLineToSensor);
          }
        }
      })
      
      elementsRef.current.nodes = [...elementsRef.current.nodes, ...nodesToAdd]; 
      elementsRef.current.edges = [...elementsRef.current.edges, ...edgesToAdd];
    }
  }

  const createEdgeId = (edge: StreamwiseEdge) => {
    return (
      edge.source +
      "-" +
      edge.target +
      "-" +
      uuid()
    );
  };

  const createNodeId = (nodeType: string | undefined) => {
    return (nodeType || 'node') + "-" + uuid();
  };

  return {
    loadConfig,
    getConfigData,
    elements,
    application,
    setApplication,
    name,
    setName,
    onNodesChange,
    onEdgesChange,
    undo,
    canUndo,
    redo,
    canRedo,
    addEdgeDevice,
    addExpansionModule,
    addLocation,
    addSensor,
    addWirelessSensor,
    addAirCleaning,
    triggerUpdateAllElements,
    warnings,
  };
}

export { useConfigurator };
