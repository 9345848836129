import { useState, useEffect } from 'react';
import { useSignalR } from './useSignalR';
import { ChartSignalObject } from '../ControlChains/types';

type DataObject = {
    i: string,
    v: number,
    t: Date
}

// Hook retrieves data from CosmosDB for the last 'x' hours. 
// Supplying deviceID and set liveData to true makes component retrieve data every 2s from edge device
export const useFetchTelemetry = (signalIDs: string[], periodHours: number, liveData: boolean = false, deviceID?: string): [ChartSignalObject[], number, number] => {
    const [data, setData] = useState<ChartSignalObject[]>([]);
    // min, max values of all datasets in 'data'
    const [min, setMin] = useState<number>(Number.MAX_VALUE);
    const [max, setMax] = useState<number>(Number.MIN_VALUE);

    const [startSignalR, stopSignalR, latestValues] = useSignalR(deviceID);

    useEffect(() => {
        let allData: ChartSignalObject[] = [];
        let allMin = Number.MAX_VALUE;
        let allMax = Number.MIN_VALUE;

        for (let i = 0; i < signalIDs.length; i++) {
            // Only continue if can retireve the signal nice name
            fetch(`/api/ControlChainsAPI/GetSignalName/?signalID=${signalIDs[i]}`)
                .then(res => {
                    if (res.ok) {
                        return res.text();
                    } else {
                        return signalIDs[i];
                    }
                })
                .then(name => {
                    fetch('/api/LineChartAPI/GetSignalData/', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            signalID: signalIDs[i],
                            hoursOfData: periodHours,
                        })
                    }).then(
                        response => response.json()
                    ).then((data: DataObject[]) => {
                        const yVals = data.map(d => d.v);
                        allMin = Math.min(allMin, ...yVals);
                        allMax = Math.max(allMax, ...yVals);

                        allData.push({
                            signalID: signalIDs[i],
                            signalName: name,
                            dataset: data.map(d => ({ x: new Date(d.t), y: d.v }))
                        });

                        // Note to self: set states must happen inside then due to async
                        setMin(allMin);
                        setMax(allMax);
                        setData(allData);
                    });
                });
        }
    }, [signalIDs, periodHours]);

    // Toggle SignalR live data stream
    useEffect(() => {
        if (deviceID !== undefined) {
            if (liveData) {
                startSignalR();
            } else {
                stopSignalR();
            }
        }
    }, [liveData, deviceID]);

    useEffect(() => {
        console.log(latestValues);
        let newData = [...data];
        for (let i = 0; i < newData.length; i++) {
            const curValue = latestValues[newData[i].signalID];
            if (curValue !== undefined) {
                newData[i].dataset.push({ x: new Date(), y: curValue });
            }
        }
        setData(newData);
    }, [latestValues]);

    return [data, min, max];
}
