import { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Table, Modal, Container, Row, Col} from 'react-bootstrap';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import AnnotationTableItem from "./AnnotationTableItem"
import moment from 'moment';
import $ from 'jquery';
import { ConfigContext } from "./LineChart" 

var chararray = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const defaultAnnotationData = {
    "CommentID": "",
    "LineChartID": 0,
    "DateTime": "",
    "Comment": ""
}

const Annotations = (SiteGUID, start, end) => {
    $.ajax({
        url: '/../api/LineChartAPI/LineChartAnnotationsAPI/',
        type: 'get',
        data: { SiteGUID: SiteGUID, StartTime_str: encodeURIComponent(start), EndTime_str: encodeURIComponent(end) },
        success: response => {
            response = JSON.parse(response);
            console.log(response);

            $('#insightchartannotationstable').attr('hidden', true);
            $('#insightchartannotationsbody').html('');

            if (response.length > 0) {
                $('#insightchartannotationstable').attr('hidden', false);
            }

            for (let i = 0; i < response.length; i++) {
                $('#insightchartannotationsbody').append('<tr><td>' + response[i].annotationID + '</td><td>' + moment(response[i].datetime).format('LLLL') + '</td><td>' + response[i].tag + '</td></tr>');
            }
        }
    });
}

function SaveAnnotationsModal(props) {
    const handleSave = (annotations) => {
        var url = '/api/LineChartAPI/SaveAnnotations/'; 

        var annotationPayload = {}
        annotationPayload.Annotations = annotations;
        annotationPayload.ChartID = props.chartid;
        annotationPayload.StartTime_str = encodeURIComponent(props.start)
        annotationPayload.EndTime_str = encodeURIComponent(props.end)

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(annotationPayload)
        }).then(response => response.json())
        .then(data => {
            // back fill annotation data
            props.onHide();
            props.onAnnotationsSave(data);
        });
    }
    
    return (
        <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Are you sure you want to save changes to annotations? 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>
                This will overwrite previous settings and cannot be undone.
            </p>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={props.onCancel}>Cancel</Button>
                        </Col>
                        <Col>
                            <Button className="float-right" variant="success" onClick={() => handleSave(props.annotations)}>Save</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}



// Actually Component
function AnnotationsContainer(props) {
    const { id } = useParams();
    const [annotations, setAnnotations] = useState([])   
    const [saveModalShow, setSaveModalShow] = useState(false);
    const [showAnnotations, setShowAnnotations] = useState(false);
    const [pastAnnotations, setPastAnnotations] = useState([]);
    const { config, updateConfig } = useContext(ConfigContext);
        
    const annotationsRef = useRef(annotations);
    const _setAnnotations = data => {
        annotationsRef.current = data;
        setAnnotations([...data]);
    }

    const handleChangeComment = (id, comment) => {
        const idx = annotationsRef.current.findIndex(a => a.toRender.key === id);
        annotationsRef.current[idx].data.Comment = comment

        // add comment to history if not available already
        if (pastAnnotations.find(a => a.label === comment) === undefined) {
            setPastAnnotations([...pastAnnotations, { value: pastAnnotations.length, label: comment }]);
        }
        _setAnnotations(annotationsRef.current);
    }

    const handleChangeDatetime = (id, dt) => {
        var newDT = dt.utc().format("YYYY-MM-DDTHH:mm:ss");

        var idx = annotationsRef.current.findIndex(a => a.toRender.key == id);
        annotationsRef.current[idx].data.DateTime = newDT;
        _setAnnotations(annotationsRef.current);
    }

    const getAnnotationLabel = (graphOrder, annotationOrder) => {
        return chararray[graphOrder] + (annotationOrder + 1)
    }

    useEffect(() => {
        $.ajax({
            url: '/../api/LineChartAPI/LineChartAnnotationsByChartAPI/',
            type: 'get',
            data: {
                ChartID: config.LineChartID,
                StartTime_str: encodeURIComponent(props.start),
                EndTime_str: encodeURIComponent(props.end)
            },
            success: annRes => {
                annRes = JSON.parse(annRes);
                // Get past annotations
                $.ajax({
                    url: '/../api/LineChartAPI/PastAnnotationsByChartAPI/',
                    type: 'get',
                    data: { ChartID: config.LineChartID },
                    success: pastAnnRes => {
                        pastAnnRes = JSON.parse(pastAnnRes);
                        const pastAnnotationsList = pastAnnRes.map((a, i) => (
                            { value: i, label: a }
                        ));
                        setPastAnnotations(pastAnnotationsList);

                        const annotations = annRes.map((d, i) => {
                            const annotation = {};
                            const id = getAnnotationLabel(config.Order, i);
                            annotation.toRender = <AnnotationTableItem
                                key={id}
                                data={d}
                                id={id}
                                onremove={(id) => {
                                    props.setLoading(true);
                                    handleRemoveAnnotation(id);
                                }}
                                oncommentchange={handleChangeComment}
                                ondatetimechange={handleChangeDatetime}
                                setShowAnnotations={setShowAnnotations}
                                pastAnnotations={pastAnnotationsList} />;

                            annotation.data = d;
                            return annotation;
                        });
                        _setAnnotations(annotations);
                    }
                });   
            }
        });


    },[])

    const handleAddAnnotation = () => {
        let newAnnotationData = defaultAnnotationData;
        const annotationOrder = annotations.length;
        const id = getAnnotationLabel(config.Order, annotationOrder);

        while (annotations.map(a => a.toRender.key).includes(id)) {
            annotationOrder++
            id = getAnnotationLabel(config.Order, annotationOrder);
        }

        newAnnotationData.CommentID = 0;
        newAnnotationData.LineChartID = config.LineChartID;
        newAnnotationData.DateTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss")

        let newAnnotation = {};
        newAnnotation.data = newAnnotationData;
        newAnnotation.toRender = <AnnotationTableItem 
            key={id}
            data={newAnnotationData}
            id={id}
            onremove={(id) => {
                props.setLoading(true);
                handleRemoveAnnotation(id);
            }}
            oncommentchange={handleChangeComment}
            ondatetimechange={handleChangeDatetime}
            setShowAnnotations={setShowAnnotations}
            pastAnnotations={pastAnnotations}/>
        _setAnnotations([...annotations, newAnnotation])
    }

    const handleSaveAnnotations = (newAnnotations) => {
        var annotations = newAnnotations.map((d, i) => {
            const annotation = {};
            var annotationId = getAnnotationLabel(config.Order, i);
            annotation.toRender = <AnnotationTableItem 
                key={annotationId} 
                data={d} 
                id={annotationId} 
                onremove={(id) => {
                    props.setLoading(true);
                    handleRemoveAnnotation(id);
                }}
                oncommentchange={handleChangeComment}
                ondatetimechange={handleChangeDatetime}
                setShowAnnotations={setShowAnnotations}
                pastAnnotations={pastAnnotations}/>;
            annotation.data = d; 
            return annotation;
        });
        
        Annotations(id, props.start, props.end);
        updateConfig({ LineChart: config });
        _setAnnotations(annotations);
    }

    const handleRemoveAnnotation = (id) => {
        var newAnnotations
        newAnnotations = annotationsRef.current.filter(a => a.toRender.key !== id)

        var url = '/api/LineChartAPI/SaveAnnotations/';

        var annotationPayload = {}
        annotationPayload.Annotations = newAnnotations.map(a => a.data);
        annotationPayload.ChartID = config.LineChartID;
        annotationPayload.StartTime_str = encodeURIComponent(props.start)
        annotationPayload.EndTime_str = encodeURIComponent(props.end)

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(annotationPayload)
        }).then(response => response.json())
        .then(data => {
            // back fill annotation data
            handleSaveAnnotations(data);
        });
    }

    return (
        <>
            <OverlayTrigger
                trigger="click"
                placement="left"
                show={showAnnotations}
                overlay={
                    <Popover style={{ "maxWidth": "85vw", "minWidth":"50vw", zIndex: 1020 }}>
                        <Popover.Header as="h3">
                            Annotations
                        </Popover.Header>
                        <Popover.Body>
                            <div style={{
                                minHeight: "150px",
                                display: "flex",
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column"
                            }}>
                                <Table striped bordered hover size="sm" style={{textAlign:"center"}}>
                                    <thead>
                                    <tr>
                                        <th>Annotation</th>
                                        <th>Comment</th>
                                        <th>Time</th>
                                        <th>Remove</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {annotations.map(a => a.toRender)}
                                    <tr onClick={() => {handleAddAnnotation()}}>
                                        <th colSpan="4" className="btn btn-outline-primary" style={{display:"table-cell", cursor:"pointer"}}> 
                                            Add a new annotation
                                        </th>
                                    </tr>
                                    </tbody>
                                </Table>
                                <Button className="float-right" variant="primary" onClick={() => setSaveModalShow(true)}>Save changes  <i className="fas fa-save"></i></Button>
                                <SaveAnnotationsModal
                                    show={saveModalShow}
                                    onHide={() => {
                                        setSaveModalShow(false);
                                        setShowAnnotations(false);
                                    }}
                                    onCancel={() => {
                                        setSaveModalShow(false);
                                    }}
                                    annotations={annotations.map(a=>a.data)}
                                    onAnnotationsSave={(data) => {
                                        props.setLoading(true);
                                        handleSaveAnnotations(data);
                                    }}
                                    chartid={config.LineChartID}
                                    start={props.start}
                                    end={props.end}
                                />
                            </div>
                        </Popover.Body>
                    </Popover>
                }
            >
                <Button 
                    title="Annotations" 
                    onClick={() => setShowAnnotations(!showAnnotations)}
                    className="btn btn-primary d-flex align-items-center" 
                    style={{ height: "100%"}}
                >
                    <i style={{ color: "rgb(255, 255, 255)", width: "16px"}} className="fal fa-edit"></i>
                </Button>
            </OverlayTrigger>
        </>
    );
}

export default AnnotationsContainer;