import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AirSolenoidModel, DeviceModel, EdgeAirCleaningModel, EdgeEthernetModel, EdgeLoraModel, EdgeModbusModel, EdgeSignalModel, EdgeTotaliserModel, EdgeTwincatModel, SignalModel } from '../components/SignalSetup/types';

export interface TwincatSignal {
    Signal: SignalModel;
    EdgeSignal?: EdgeSignalModel;
    EdgeSolenoid?: EdgeAirCleaningModel;
    EdgeTotaliser?: EdgeTotaliserModel;
    Protocol: EdgeTwincatModel;
}

export interface ModbusSignal {
    Signal: SignalModel;
    EdgeSignal?: EdgeSignalModel;
    EdgeSolenoid?: EdgeAirCleaningModel;
    Protocol: EdgeModbusModel;
}

export interface LoraSignal {
    Signal: SignalModel;
    EdgeSignal?: EdgeSignalModel;
    EdgeSolenoid?: EdgeAirCleaningModel;
    Protocol: EdgeLoraModel;
}

export interface EthernetSignal {
    Signal: SignalModel;
    EdgeSignal?: EdgeSignalModel;
    EdgeSolenoid?: EdgeAirCleaningModel;
    Protocol: EdgeEthernetModel;
}

export interface OtherEdgeSignal {
    SignalID: string;
    NiceName: string;
    DeviceID: string;
}

export interface EdgeSignalsModel {
    TwincatSignals: TwincatSignal[];
    ModbusSignals: ModbusSignal[];
    LoraSignals: LoraSignal[];
    EthernetSignals: EthernetSignal[];
    OtherSignals: OtherEdgeSignal[];
}

export interface EdgeSignalState {
    loading: boolean;
    signals: EdgeSignalsModel;
    devices: DeviceModel[];
    length: number;
    error: string | null;
}

const initialState: EdgeSignalState = {
    loading: true,
    signals: {
        TwincatSignals: [],
        ModbusSignals: [],
        LoraSignals: [],
        EthernetSignals: [],
        OtherSignals: []
    },
    devices: [],
    length: 0,
    error: ""
}

export const fetchEdgeSignals = createAsyncThunk<EdgeSignalState, string, { rejectValue: string }>(
    "edgeSignal/fetchSignals",
    async (siteGUID, thunkAPI) => {
        try {
            const response = await fetch(`/api/SignalSetupAPI/FetchEdgeSignals/?siteGUID=${siteGUID}`);
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                return data;
            }
            else {
                return thunkAPI.rejectWithValue("Failed to fetch edge signals.");
            }
        } catch (error) {
            return thunkAPI.rejectWithValue("Failed to fetch edge signals.");
        }
    }
);

export const edgeSignalSlice = createSlice({
    name: "edgeSignals",
    initialState,
    reducers: {
        addTwincat: (state, action: PayloadAction<TwincatSignal>) => {
            state.signals.TwincatSignals = [...state.signals.TwincatSignals, action.payload]
        },
        addModbus: (state, action: PayloadAction<ModbusSignal>) => {
            state.signals.ModbusSignals = [...state.signals.ModbusSignals, action.payload]
        },
        addEthernet: (state, action: PayloadAction<EthernetSignal>) => {
            state.signals.EthernetSignals = [...state.signals.EthernetSignals, action.payload]
        },
        addLora: (state, action: PayloadAction<LoraSignal>) => {
            state.signals.LoraSignals = [...state.signals.LoraSignals, action.payload]
        },
        addOtherEdge: (state, action: PayloadAction<OtherEdgeSignal>) => {
            state.signals.OtherSignals = [...state.signals.OtherSignals, action.payload]
        },

        editTwincat: (state, action: PayloadAction<TwincatSignal>) => {
            state.signals.TwincatSignals = state.signals.TwincatSignals
                .map(s => s.Signal.SignalID === action.payload.Signal.SignalID ? action.payload : s)
        },
        editModbus: (state, action: PayloadAction<ModbusSignal>) => {
            state.signals.ModbusSignals = state.signals.ModbusSignals
                .map(s => s.Signal.SignalID === action.payload.Signal.SignalID ? action.payload : s)
        },
        editLora: (state, action: PayloadAction<LoraSignal>) => {
            state.signals.LoraSignals = state.signals.LoraSignals
                .map(s => s.Signal.SignalID === action.payload.Signal.SignalID ? action.payload : s)
        },
        editEthernet: (state, action: PayloadAction<EthernetSignal>) => {
            state.signals.EthernetSignals = state.signals.EthernetSignals
                .map(s => s.Signal.SignalID === action.payload.Signal.SignalID ? action.payload : s)
        },
        editOtherEdge: (state, action: PayloadAction<OtherEdgeSignal>) => {
            state.signals.OtherSignals = state.signals.OtherSignals
                .map(s => s.SignalID === action.payload.SignalID ? action.payload : s)
        },

        deleteTwincat: (state, action: PayloadAction<string>) => {
            state.signals.TwincatSignals = state.signals.TwincatSignals
                .filter(s => s.Signal.SignalID !== action.payload)
        },
        deleteModbus: (state, action: PayloadAction<string>) => {
            state.signals.ModbusSignals = state.signals.ModbusSignals
                .filter(s => s.Signal.SignalID !== action.payload)
        },
        deleteLora: (state, action: PayloadAction<string>) => {
            state.signals.LoraSignals = state.signals.LoraSignals
                .filter(s => s.Signal.SignalID !== action.payload)
        },
        deleteEthernet: (state, action: PayloadAction<string>) => {
            state.signals.EthernetSignals = state.signals.EthernetSignals
                .filter(s => s.Signal.SignalID !== action.payload)
        },
        deleteOtherEdge: (state, action: PayloadAction<string>) => {
            state.signals.OtherSignals = state.signals.OtherSignals
                .filter(s => s.SignalID !== action.payload)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEdgeSignals.pending, (state) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(fetchEdgeSignals.fulfilled, (state, action) => {
                state.loading = false;
                state.signals = action.payload.signals;
                state.devices = action.payload.devices;
                state.length = action.payload.length;
            })

            .addCase(fetchEdgeSignals.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Something went wrong";
            })
    }
});

export const {
    addTwincat,
    addModbus,
    addLora,
    addEthernet,
    addOtherEdge,
    editTwincat,
    editModbus,
    editLora,
    editEthernet,
    editOtherEdge,
    deleteTwincat,
    deleteModbus,
    deleteLora,
    deleteEthernet,
    deleteOtherEdge } = edgeSignalSlice.actions
export default edgeSignalSlice.reducer
