import React, { memo } from 'react';

import { Handle } from 'react-flow-renderer';
import '../Nodes/styles.scss'
import { StreamwiseNodeData } from '../types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEllipsis} from '@fortawesome/free-solid-svg-icons'

const PORT_POSITIONS = [17.5, 40, 60, 82.5]
const PORT_LABELS = ['PWR', 'A', 'B', 'COM']

export default memo(
    ({
      id,
      data,
    }: {
      id: string;
      data: StreamwiseNodeData;
    }) => {
      return (
        <>
          <div className="handleLabelTop" style={{ left: 40 }}>
          </div>
          <Handle
            type="target"
            position="left"
            id="INPUT"
            style={{ top: 35, background: "#555" }}
            onConnect={(params) => console.log("handle onConnect", params)}
            isConnectable={false}
          />
          <div className="nodeText">{data.name}</div>
          <div style={{height: '20px'}}>
            <div className="infoButton" onClick={() => {if (data.onEdit) data.onEdit(id)}}>
              <FontAwesomeIcon icon={faEllipsis} />
            </div>
          </div>
        </>
      );
    }
  );