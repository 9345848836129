'use strict';
import { Chart, registerables } from 'chart.js';
import moment from 'moment';
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import {each} from 'chart.js/helpers'

Chart.register(...registerables);
Chart.register(zoomPlugin);
Chart.register(annotationPlugin);

const tooltipPlugin = Chart.registry.getPlugin('tooltip');
tooltipPlugin.positioners.custom = function(elements, eventPosition){

    return {
        x: eventPosition.x,
        y: eventPosition.y
    }
}


const StreamwiseTrendChart = (function () {
    // const defaultYAxisId = 'DefaultYAxisId';
    const defaultAxisFontSize = 12;
    const defaultMaxTicksLimit = 5;
    var line_chart = {};

    const defaultYAxis = {
        type: 'linear',
        display: true,
        position: 'left',
        beginAtZero: true,
        ticks: {
            font: {
                size: defaultAxisFontSize,
            },
            maxTicksLimit: defaultMaxTicksLimit,
        },
        title: {
            display: true,
            font: {
                size: defaultAxisFontSize,
            },
            padding: 4,
            labelString: ''
        }
    };

    const deepCopy = function (objectToGoDeepOn) {
        return JSON.parse(JSON.stringify(objectToGoDeepOn));
    };

    const createYAxes = function (chartConfig) {
        const yAxes = [];
        // Create left hand side Y axis
        var leftYAxis = deepCopy(defaultYAxis);
        leftYAxis.id = 'left'
        if (chartConfig.chartYAxisIdOverride && chartConfig.chartYAxisIdOverride != '') {
            leftYAxis.title.text = chartConfig.chartYAxisIdOverride;
        } else {
            leftYAxis.title.text = '';
            // Use first LHS signal nice name for label
            if (chartConfig.chartDataSets && chartConfig.chartDataSets.length > 0) {
                chartConfig.chartDataSets.forEach(ds => {
                    if (ds.yAxisID == 'left') {
                        leftYAxis.title.text = ds.label; // Just set it to the first signals nice name, don't need to go through others
                    }
                })
            }
        }

        yAxes.push(leftYAxis);

        // Create right hand side Y axis
        var rightYAxis = deepCopy(defaultYAxis);
        rightYAxis.gridLines = { display: false };
        rightYAxis.ticks.min = 0;
        rightYAxis.ticks.maxTicksLimit = 2;
        rightYAxis.id = 'right';
        rightYAxis.position = 'right';
        if (chartConfig.chartRightYAxisIdOverride && chartConfig.chartRightYAxisIdOverride) {
            rightYAxis.title.text = chartConfig.chartRightYAxisIdOverride;
            yAxes.push(rightYAxis);
        } else {
            rightYAxis.title.text = '';
            // Use first RHS signal nice name for label
            if (chartConfig.chartDataSets && chartConfig.chartDataSets.length > 0) {
                for (var i = 0; i<chartConfig.chartDataSets.length; i++){
                    if (chartConfig.chartDataSets[i].yAxisID == 'right') {
                        rightYAxis.title.text = chartConfig.chartDataSets[i].label;
                        yAxes.push(rightYAxis); // Just set it to the first signals nice name, don't need to go through others
                    } else if (i == chartConfig.chartDataSets.length - 1) // no right axis signals found, hide it
                    {
                        rightYAxis.ticks.display = false;
                    }
                }
            } else { // no RHS axis, don't push right axis
            }
        }

        return {leftYAxis, rightYAxis};
    };

    const createAnnotations = function (chartConfig) {
        console.log(chartConfig);
        var annotations = {};
        if (chartConfig.annotations == null) {
            return annotations;
        }
        
        for (let i = 0; i < chartConfig.annotations.length; i++) {
            annotations[i] = {
                drawTime: "afterDatasetsDraw",
                type: "line",
                mode: "vertical",
                scaleID: "x-axis-0",
                value: chartConfig.annotations[i].datetime,
                borderColor: "rgba(0,0,0,1)",
                borderWidth: 1,
                label: {
                    backgroundColor: "rgba(20, 168, 222, 0.6)",
                    content: chartConfig.annotations[i].annotationID,
                    font: {
                        size: 14,
                        family: "sans-serif",
                        color: "rgba(0,0,0,0.5)"
                    },
                    enabled: true,
                    position: "start",
                    xAdjust: 16,
                    yAdjust: 2
                },
                onClick: function (e) {
                    // The annotation is is bound to the `this` variable
                    // console.log("Annotation", e.type, this);
                }
            };
        }
        return annotations;
    }

    const getChartXAxisTicksUnit = function (chartConfig) {
        const dayRangeDiff = moment(chartConfig.chartLabels[chartConfig.chartLabels.length - 1]).diff(moment(chartConfig.chartLabels[0]), 'days');

        var xAxesUnit = 'day';
        if (dayRangeDiff <= 2) {
            xAxesUnit = 'hour';
        }

        return xAxesUnit;
    };

    const createChartOptions = function (chartConfig) {
        const chartYAxes = createYAxes(chartConfig);
        const annotations = createAnnotations(chartConfig);
        return {
            responsive: true,
            resizeDelay: 600,
            maintainAspectRatio: false,
            legend: {
                display: true,
                labels: {
                    boxWidth: 20,
                    font:{
                        size: 9
                    }
                }
            },
            scales: {
                left: chartYAxes.leftYAxis,
                right: chartYAxes.rightYAxis,
                'x-axis-0': {
                    type: 'time',
                    time: {
                        unit: getChartXAxisTicksUnit(chartConfig)
                    },
                    ticks: {
                        minRotation: 0,
                        maxRotation: 0,
                        maxTicksLimit: 12
                    },
                }
            },
            plugins: {
                zoom: {
                    limits:{
                        'x-axis-0' : 
                            {min:null,
                            max:null},
                        left : 
                            {min:null,
                            max:null},
                        right : 
                            {min:null,
                            max:null}
                    },
                    pan: {
                        enabled: false,
                        mode: 'x',
                        threshold: 10,
                        modifierKey: 'ctrl',

                        onPan: function (chart) {
                            const internalchart = chart.chart;
                            each(Chart.instances, function (instance) {
                                if (instance.id != internalchart.id && instance.config.type == 'line') {
                                    instance.options.scales.xAxes[0].min = internalchart.scales['x-axis-0'].min;
                                    instance.options.scales.xAxes[0].max = internalchart.scales['x-axis-0'].max;
                                    instance.update();
                                }
                            })
                        }
                    },
                    zoom: {
                        threshold:50,
                        mode: 'x',
                        speed: 0.5,

                        onZoom: function (chart) {
                            const internalchart = chart.chart;
                            each(Chart.instances, function (instance) {
                                if (instance.id != internalchart.id && instance.config.type == 'line') {
                                    instance.options.scales['x-axis-0'].min = internalchart.scales['x-axis-0'].min;
                                    instance.options.scales['x-axis-0'].max = internalchart.scales['x-axis-0'].max;
                                    instance.update();

                                }
                            })
                            // console.log(chartConfig.ChartIDs + 'I was zoomed!!!');
                        },
                        wheel: {
                            enabled: true,
                            modifierKey: 'ctrl',
                        },
                        drag: {
                            enabled: true,
                            backgroundColor: 'rgba(225,225,225,0.7)'
                        },
                    }
                },
                tooltip: {
                    position: 'nearest',
                    intersect: false,
                    callbacks: {
                        title: function (items, item) {
                            return moment(items[items.length-1].label).format('LLL');
                        },
                        label: function (context) {
                            const label = context.dataset.label;
                            const value = Math.round(context.parsed.y * 100) / 100;
                            return label + ': ' + value;
                            // return value === 0 ? null : label + ': ' + value;
                        }
                    },
                    filter(tooltipItem, index, tooltipItems){
                        // Get labels of all the items in this tooltip
                        var tooltipLabels = tooltipItems.map(ti => ti.dataset.label)
                        // Count how many times this tooltipItem label shows up in tooltip items
                        let labelCount = 0;
                        for (let i=0; i<tooltipLabels.length; i++){
                            if (tooltipItem.dataset.label == tooltipLabels[i]){
                                labelCount++;
                            }
                        }

                        // tooltipItem can't be filtered out if its the only one in the list - (couldn't think of an elegant way to do this)
                        if (labelCount == 1){
                            return true;
                        }
                        
                        let halfCount = Math.floor(labelCount/2); // number of labels that need to be counted to get to the MIDDLE instance of the label
                        let middleIndex = 0;
                        labelCount = 0;
                        // Count up through the tooltipItems until halfCount and middleIndex match
                        for (let i=0; i<tooltipLabels.length; i++){
                            if(labelCount !== halfCount){
                                if (tooltipItem.dataset.label == tooltipLabels[i]){
                                    labelCount++;
                                }
                                middleIndex++; 
                            } else {
                                break; 
                            }
                        }
                        // return true if this index is the MIDDLE instance of this label in the items list
                        return (middleIndex == index);
                    }
                },
                annotation: {
                    events: ["click"],
                    annotations: {...annotations}
                }
            },
            interaction: {
                mode: 'x',
                intersect: false
            },
            onClick(e) {
                if (e.native.shiftKey) {
                    each(Chart.instances, function (instance) {
                        if (instance.config.type == 'line'){
                            instance.options.scales['x-axis-0'].min = null;
                            instance.options.scales['x-axis-0'].max = null;
                            instance.update();
                        }
                    })
                }
            },
        };
    }

    const createChartData = function (chartConfig) {
        var datasets = chartConfig.chartDataSets;
        // datasets = assignYAxes(datasets, chartConfig.chartYAxisIdOverride); // Assigns all datasets to default y axis (no longer need as explicilty doing so in trend-chart)

        // // Pushing feed flow dataset, but no longer need as feed flow datasets are no longer defined independently
        // if (typeof chartConfig.chartFeedFlowDataset != "undefined") {
        //     datasets.push(chartConfig.chartFeedFlowDataset);
        // }
        return {
            labels: chartConfig.chartLabels,
            datasets: datasets
        };
    };

    const limitZoomAndPan = function (chart) {
        chart.options.plugins.zoom.limits['x-axis-0'].min = chart.scales['x-axis-0'].min.valueOf();
        chart.options.plugins.zoom.limits['x-axis-0'].max = chart.scales['x-axis-0'].max.valueOf();

        chart.options.plugins.zoom.limits['left'].min = chart.scales['left'].min.valueOf();
        chart.options.plugins.zoom.limits['left'].max = chart.scales['left'].max.valueOf();
        
        chart.options.plugins.zoom.limits['right'].min = chart.scales['right'].min.valueOf();
        chart.options.plugins.zoom.limits['right'].max = chart.scales['right'].max.valueOf();
   };

    const initChartNoData = function (elementId, noData) {
        if (noData) {
            const chart = document.getElementById(elementId);
            if (chart !== null){
                chart.setAttribute('class', 'col row no-data-blur-effect');
                //chart.style.width = '100%';
            }

            const noDataLabel = document.getElementById(elementId + '-NoData');
            if (noDataLabel !== null){
                noDataLabel.removeAttribute('hidden');
            }
        } else {
            const chart = document.getElementById(elementId);
            if (chart !== null){
                chart.setAttribute('class', 'col row');
            }

            const noDataLabel = document.getElementById(elementId + '-NoData');
            if (noDataLabel !== null){
                noDataLabel.setAttribute('hidden', '');
            }
        }
    };

    const createChart = function (chartConfig, noData) {
        var rst_btn = document.getElementById('resetzoombutton');
        if (rst_btn !== null){
            rst_btn.onclick = () => {
                each(Chart.instances, function (instance) {
                    if (instance.config.type == 'line'){
                        instance.options.scales['x-axis-0'].min = null;
                        instance.options.scales['x-axis-0'].max = null;
                        instance.update();
                    }
                })
            }
        }

        // Will create blur effect if noData is true, otherwise hides NoData element
        initChartNoData(chartConfig.chartId, noData);

        var canvasElement = document.getElementById(chartConfig.chartId + '-Chart')
        if (canvasElement !== null){
            const ctx = canvasElement.getContext('2d');
            ctx.canvas.className="col" // add back column styles
            if (line_chart[chartConfig.chartId]) {
                line_chart[chartConfig.chartId].destroy();
            }
    
            line_chart[chartConfig.chartId] = new Chart(ctx,
                {
                    type: 'line',
                    data: createChartData(chartConfig),
                    options: createChartOptions(chartConfig)
                });
    
            limitZoomAndPan(line_chart[chartConfig.chartId]);
            return line_chart[chartConfig.chartId];
        }

        return {}
    };

    const refreshChart = function (chart, chartConfig, noData) {
        initChartNoData(chartConfig.chartId, noData);
        chart.data = createChartData(chartConfig);
        limitZoomAndPan(chart);
        chart.update(0);
    };

    return {
        create: createChart,
        refresh: refreshChart
    };
}());

export default StreamwiseTrendChart;