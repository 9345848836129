import { StreamwiseEdge } from "../types";
import "./styles.scss";
import Connector from "./Connector";

export const edgeTypes = {
  connector: Connector,
};

export const SW_EDGE_LIST: StreamwiseEdge[] = [
  {
    id: "",
    type: "connector",
    source: '',
    target: '',
    zIndex: 1003,
    data: { 
      name: "connector",
      sourceId: "",
      targetId: "" 
    },
  },
];
