import { useState, useEffect } from 'react';
import $ from 'jquery';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Chart, registerables } from 'chart.js';
import { toast, Bounce } from 'react-toastify';

import { AdvantechSignalModel, DeviceModel, EdgeEthernetModel, EdgeLoraModel, EdgeModbusModel, EdgeSignalModel, EdgeTwincatModel, SignalModel } from './types';
import { AppDispatch } from '../../redux';
import { useAppDispatch } from '../../redux/index';
import { deleteOtherEdge } from '../../redux/EdgeSignalReducer';
import { SignalTypeSelection } from './SignalComponents';

Chart.register(...registerables);

// Small graph trend for signal
const SparkLine = ({ signalID }: {signalID: string}) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [dataAvailable, setDataAvailable] = useState(false)

    useEffect(() => {
        // Get signal data for the last 4 hours
        $.ajax({
            url: '/api/SignalSetupAPI/GetSignalData/',
            type: 'get',
            data: {
                SignalID: signalID
            },
            success: response => {
                try {
                    let data = [];
                    for (let i = 0; i < response.length; i++) {
                        data.push({ x: moment(response[i].t), y: response[i].v })
                    }
                    setDataAvailable(data.length > 0)

                    const ctx = document.getElementById(signalID + "chart").getContext('2d');
                    new Chart(ctx, {
                        type: 'line',
                        data: {
                            datasets: [{
                                data: data,
                                borderWidth: 1,
                                borderColor: 'rgba(0, 44, 79, 0.6)',
                                pointRadius: 0,
                                fill: false
                            }]
                        },
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    display: false
                                },
                                tooltip: {
                                    enabled: false,
                                }
                            },
                            scales: {
                                x: {
                                    display: false,
                                    type: 'time',
                                    gridLines: {
                                        display: false
                                    },
                                    ticks: {
                                        maxTicksLimit: 1,
                                        maxRotation: 0,
                                        display: false
                                    }
                                },
                                y: {
                                    display: false,
                                    gridLines: {
                                        display: false
                                    },
                                    ticks: {
                                        maxTicksLimit: 2,
                                        display: false
                                    }
                                }
                            }
                        }
                    });
                    setDataLoaded(true);
                }
                catch (exception) {
                    console.log(exception)
                }
            }
        });
    }, []);

    return (
        <>
            {!dataLoaded &&
                <div>
                    <img style={{ height: '24px', width: '24px' }} src="/lib/ajax-loader.gif" />
                </div>
            }
            {(!dataLoaded || dataAvailable) ?
                <canvas style={{ height: '24px', width: '150px' }} id={signalID + "chart"}></canvas> :
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>No data received</Tooltip>}>
                    <div className="text-warning text-center">
                        <i className="fad fa-exclamation-triangle"></i>
                    </div>
                </OverlayTrigger>
            }
        </>
    );
}

interface IUpdateProtocol {
    SignalID: string,
    Protocol: number,
    ProtocolFields: EdgeTwincatModel | EdgeModbusModel | EdgeLoraModel | EdgeEthernetModel;
    DeviceID: string,
}

export interface TwincatSignalRowProps {
    signal?: SignalModel;
    edgeSignal?: EdgeSignalModel;
    protocol: EdgeTwincatModel;
    devices: DeviceModel[];
    siteGUID: string;
    protocolID: number | undefined
    handleShowSettingsModal: (signal: SignalModel | undefined, protocolID: number | undefined) => void;
}
export const TwincatSignalRow = ({ signal, edgeSignal, protocol, devices, siteGUID, protocolID, handleShowSettingsModal }: TwincatSignalRowProps) => {
    const dispatch: AppDispatch = useAppDispatch();

    // Display loading wheel if saving changes
    const [saving, setSaving] = useState(false);
    // Track of any real changes have been made
    const [updated, setUpdated] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    const [signalType, setSignalType] = useState(0);
    // Editable states
    const [deviceID, setDeviceID] = useState((signal && signal.SignalDevice) ? signal.SignalDevice?.DeviceID : "");
    const [niceName, setNiceName] = useState(signal ? signal.NiceName : "");
    const [variableName, setVariableName] = useState(protocol ? protocol.VariableName : "");
    const [variableLocation, setVariableLocation] = useState(protocol ? protocol.VariableLocation : "");

    function TwincatSignalSaveHandler() {
        if (signal) {
            setSaving(true)
            let signalData: IUpdateProtocol = {
                SignalID: signal.SignalID,
                Protocol: 0,
                ProtocolFields: {
                    ...protocol,
                    VariableName: variableName,
                    VariableLocation: variableLocation
                },
                DeviceID: deviceID
            }
            fetch("/api/SignalSetupAPI/UpdateProtocol", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(signalData),
            })
            .then(() => setSaving(false))
            .catch(err => {
                console.error(err);
                setSaving(false)
            });
        }
    }

    useEffect(() => {
        if (signal) {
            // Get access level
            $.ajax({
                url: '/api/AuthorizationAPI/GetAccessLevel/',
                type: 'get',
                data: {
                    accessControlID: signal.AccessControlID
                },
                success: function (response) {
                    const data = JSON.parse(response);
                    setCanEdit(data == 2);
                }
            });
        }
        else {
            setCanEdit(true)
        }
    }, []);

    return (
        <tr>
            <td>{signal ?
                signal.NiceName :
                <Form.Control value={niceName} onChange={e => setNiceName(e.target.value)} />}
            </td>
            <td className="row d-none d-lg-block" style={{ width: '150px', height: '24px' }}>
                {signal && <SparkLine signalID={signal.SignalID} />}
            </td>
            <td className="text-center">
                {(signal && signal.SignalDevice) ?
                    signal.SignalDevice.Device.NiceName :
                    <Form.Select value={deviceID} onChange={e => { setDeviceID(e.target.value); setUpdated(true) }}>
                        {devices.length > 0 && <option value="">Select Device</option>}
                        {devices.map(dev => <option key={`device-${dev.DeviceID}`} value={dev.DeviceID}>{dev.NiceName}</option>)}
                    </Form.Select>
                }
            </td>
            <td className="text-center">
                <Form.Control value={variableLocation} onChange={e => { setVariableLocation(e.target.value); setUpdated(true) }} />
            </td>
            <td className="text-center">
                <Form.Control value={variableName} onChange={e => { setVariableName(e.target.value); setUpdated(true) }} />
            </td>
            <td className="text-center" style={{ width: '100px' }}>
                {(signal === undefined && edgeSignal === undefined) ?
                    <SignalTypeSelection typeValue={signalType} setTypeValue={setSignalType} /> :
                    <Button className="far fa-cog" onClick={() => handleShowSettingsModal(signal, protocolID)} variant="outline-primary" />
                }
            </td>
            <td className="text-center" style={{ width: '100px' }}>
                {updated &&
                    <Button variant='outline-primary' onClick={TwincatSignalSaveHandler}>
                        {saving ? <img style={{ height: '24px', width: '24px' }} src="/lib/ajax-loader.gif" /> : (signal ? "Update" : "Create")}
                    </Button>
                }
            </td>
        </tr>
    );
}

export interface ModbusSignalRowProps {
    signal?: SignalModel;
    edgeSignal?: EdgeSignalModel;
    protocol: EdgeModbusModel;
    devices: DeviceModel[];
    siteGUID: string;
    protocolID: number | undefined
    handleShowSettingsModal: (signal: SignalModel | undefined, protocolID: number | undefined) => void;
}
export const ModbusSignalRow = ({ signal, edgeSignal, protocol, devices, siteGUID, protocolID, handleShowSettingsModal }: ModbusSignalRowProps) => {
    const dispatch: AppDispatch = useAppDispatch();

    const [saving, setSaving] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [updated, setUpdated] = useState(false);

    const [deviceID, setDeviceID] = useState((signal && signal.SignalDevice) && signal.SignalDevice.DeviceID);
    const [slaveID, setSlaveID] = useState(protocol && protocol.SlaveID.toString());
    const [address, setAddress] = useState(protocol && protocol.Address.toString());
    const [variableType, setVariableType] = useState(protocol && protocol.VariableType);
    const [needMeasureCmd, setNeedMeasureCmd] = useState(protocol ? protocol.NeedMeasureCmd === 1 : false);
    const [niceName, setNiceName] = useState(signal ? signal.NiceName : "");


    function ModbusSignalSaveHandler() {
        if (signal) {
            setSaving(true)
            let signalData: IUpdateProtocol = {
                SignalID: signal.SignalID,
                Protocol: 1,
                ProtocolFields: {
                    ...protocol,
                    SlaveID: Number(slaveID),
                    Address: Number(address),
                    VariableType: variableType || 'Boolean',
                    NeedMeasureCmd: needMeasureCmd ? 1 : 0,
                },
                DeviceID: deviceID || ""
            }
            fetch("/api/SignalSetupAPI/UpdateProtocol", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(signalData),
            })
            .then(() => setSaving(false))
            .catch(err => {
                console.error(err);
                setSaving(false)
            });
        }
    }

    const HandleNameChange = (newName: string) => {
        // Auto populate protocol params based on defaults
        if (address == null && variableType == null && needMeasureCmd == null && slaveID == null) {
            if (newName.toLowerCase().includes("tempe")) {
                setAddress("83")
                setVariableType("FloatingPointHolding")
                setNeedMeasureCmd(true)
                UpdateToast("Prefilled information.");
            }
            else if (newName.includes("mV")) {
                setSlaveID("20")
                setAddress("89")
                setVariableType("FloatingPointHolding")
                setNeedMeasureCmd(true)
                UpdateToast("Prefilled information.");
            }
            else if (newName.includes("pH")) {
                setSlaveID("20")
                setAddress("85")
                setVariableType("FloatingPointHolding")
                setNeedMeasureCmd(true)
                UpdateToast("Prefilled information.");
            }
            else if (newName.includes("TSS")) {
                setSlaveID("50")
                setAddress("87")
                setVariableType("FloatingPointHolding")
                setNeedMeasureCmd(true)
                UpdateToast("Prefilled information.");
            }
            else if (newName.toLowerCase().includes("condu")) {
                setSlaveID("30")
                setAddress("85")
                setVariableType("FloatingPointHolding")
                setNeedMeasureCmd(true)
                UpdateToast("Prefilled information.");
            }
            else if (newName.toLowerCase().includes("redox")) {
                setSlaveID("20")
                setAddress("87")
                setVariableType("FloatingPointHolding")
                setNeedMeasureCmd(true)
                UpdateToast("Prefilled information.");
            }
            else if (newName.toLowerCase().includes("salin")) {
                setSlaveID("30")
                setAddress("87")
                setVariableType("FloatingPointHolding")
                setNeedMeasureCmd(true)
                UpdateToast("Prefilled information.");
            }
            else if (newName.toLowerCase().includes("tds")) {
                setSlaveID("30")
                setAddress("89")
                setVariableType("FloatingPointHolding")
                setNeedMeasureCmd(true)
                UpdateToast("Prefilled information.");
            }
        }
        setNiceName(newName);
    }

    function UpdateToast(message:string) {
        toast.info(message, {
            position: "bottom-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            transition: Bounce
        });
    }

    useEffect(() => {
        if (signal) {
            //Get access level
            $.ajax({
                url: '/api/AuthorizationAPI/GetAccessLevel/',
                type: 'get',
                data: {
                    accessControlID: signal.AccessControlID
                },
                success: function (response) {
                    var data = JSON.parse(response);
                    setCanEdit(data == 2);
                }
            })
        }
        else {
            setCanEdit(true)
        }
    }, []);

    return (
        <tr>
            <td>{signal ?
                signal.NiceName :
                <Form.Control value={niceName} onChange={e => HandleNameChange(e.target.value)} />}
            </td>
            <td className="row d-none d-lg-block" style={{ width: '150px', height: '24px' }}>
                {signal && <SparkLine signalID={signal.SignalID} />}
            </td>
            <td className="text-center">
                {(signal && signal.SignalDevice) ?
                    signal.SignalDevice.Device.NiceName :
                    <Form.Select value={deviceID} onChange={e => { setDeviceID(e.target.value); setUpdated(true) }}>
                        {devices.length > 0 && <option>Select Device</option>}
                        {devices.map(device => <option key={`device-${device.DeviceID}`} value={device.DeviceID}>{device.NiceName}</option>)}
                    </Form.Select>}
            </td>
            <td className="text-center d-flex justify-content-center">
                <Form.Control style={{ width: '64px' }} value={slaveID} onChange={e => { setSlaveID(e.target.value); setUpdated(true); }} />
            </td>
            <td className="text-center">
                <Form.Control style={{ width: '64px' }} value={address} onChange={e => { setAddress(e.target.value); setUpdated(true) }} />
            </td>
            <td className="text-center">
                <Form.Select value={variableType} onChange={e => { setVariableType(e.target.value); setUpdated(true) }}>
                    <option></option>
                    <option value={"Boolean"}>Boolean</option>
                    <option value={"FloatingPointHolding"}>Floating Point Holding</option>
                    <option value={"FloatingPointInput"}>Floating Point Input</option>
                    <option value={"WordHolding"}>Word Holding</option>
                    <option value={"WordInput"}>Word Input</option>
                    <option value={"WordInputUShort"}>Word Input Unsigned Short</option>
                    <option value={"WordHoldingDoubleRegister"}>Word Holding Double Register</option>
                </Form.Select>
            </td>
            <td className="text-center d-flex justify-content-center">
                <Form.Check style={{ width: '100px' }} checked={needMeasureCmd} onChange={() => { setNeedMeasureCmd(!needMeasureCmd); setUpdated(true) }} />
            </td>
            <td className="text-center" style={{ width: '100px' }}>
                <Button className="far fa-cog" onClick={() => handleShowSettingsModal(signal, protocolID)} variant="outline-primary" />
            </td>
            <td className="text-center" style={{ width: '100px' }}>
                {updated &&
                    <Button onClick={ModbusSignalSaveHandler} variant="outline-primary">
                        {saving ? <img style={{ height: '24px', width: '24px' }} src="/lib/ajax-loader.gif" /> :
                            (signal ? "Update" : "Create")}
                    </Button>}
            </td>
        </tr>
    );
}

export interface LoraSignalRowProps {
    signal?: SignalModel;
    edgeSignal?: EdgeSignalModel;
    protocol: EdgeLoraModel;
    devices: DeviceModel[];
    siteGUID: string;
    protocolID: number | undefined
    handleShowSettingsModal: (signal: SignalModel | undefined, protocolID: number | undefined) => void;
}

export const LoraSignalRow = ({ signal, edgeSignal, protocol, devices, siteGUID, handleShowSettingsModal, protocolID }: LoraSignalRowProps) => {
    const dispatch: AppDispatch = useAppDispatch();

    // Display loading wheel if saving changes
    const [saving, setSaving] = useState(false);
    // Track of any real changes have been made
    const [updated, setUpdated] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    // Editable states
    const [deviceID, setDeviceID] = useState((signal && signal.SignalDevice) && signal.SignalDevice.DeviceID);
    const [niceName, setNiceName] = useState(signal && signal.NiceName);
    const [deviceEUI, setDeviceEUI] = useState(protocol && protocol.DeviceEUI);
    const [channelNumber, setChannelNumber] = useState(protocol && protocol.ChannelNumber);


    useEffect(() => {
        if (signal) {
            // Get access level
            $.ajax({
                url: '/api/AuthorizationAPI/GetAccessLevel/',
                type: 'get',
                data: {
                    accessControlID: signal.AccessControlID
                },
                success: function (response) {
                    const data = JSON.parse(response);
                    setCanEdit(data == 2);
                }
            });
        }
        else {
            setCanEdit(true)
        }
    }, []);

    const LoraSignalSaveHandler = () => {
        if (signal) {
            setSaving(true)
            let signalData: IUpdateProtocol = {
                SignalID: signal.SignalID,
                Protocol: 2,
                ProtocolFields: {
                    ...protocol,
                    DeviceEUI: deviceEUI,
                    ChannelNumber: channelNumber
                },
                DeviceID: deviceID || ""
            }
            fetch("/api/SignalSetupAPI/UpdateProtocol", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(signalData),
            })
            .then(() => setSaving(false))
            .catch(err => {
                console.error(err);
                setSaving(false)
            });
        }
    }

    return (
        <tr>
            <td>{signal ?
                signal.NiceName :
                <Form.Control value={niceName} onChange={e => setNiceName(e.target.value)} />}
            </td>
            <td className="row d-none d-lg-block" style={{ width: '150px', height: '24px' }}>
                {signal && <SparkLine signalID={signal.SignalID} />}
            </td>
            <td className="text-center">
                {(signal && signal.SignalDevice) ?
                    signal.SignalDevice.Device.NiceName :
                    <Form.Select value={deviceID} onChange={e => { setDeviceID(e.target.value); setUpdated(true) }}>
                        {devices.length > 0 && <option>Select Device</option>}
                        {devices.map(dev => <option key={`device-${dev.DeviceID}`} value={dev.DeviceID}>{dev.NiceName}</option>)}
                    </Form.Select>
                }
            </td>
            <td className="text-center">
                <Form.Control value={deviceEUI} onChange={e => { setDeviceEUI(e.target.value); setUpdated(true) }} />
            </td>
            <td className="text-center">
                <Form.Control value={channelNumber} onChange={e => { setChannelNumber(Number(e.target.value)); setUpdated(true) }} />
            </td>
            <td className="text-center" style={{ width: '100px' }}>
                <Button className="far fa-cog" onClick={() => handleShowSettingsModal(signal, protocolID)} variant="outline-primary" />
            </td>
            <td className="text-center" style={{ width: '100px' }}>
                {updated &&
                    <Button variant='outline-primary' onClick={LoraSignalSaveHandler}>
                        {saving ? <img style={{ height: '24px', width: '24px' }} src="/lib/ajax-loader.gif" /> : (signal ? "Update" : "Create")}
                    </Button>
                }
            </td>
        </tr>
    );
}

export interface EthernetSignalRowProps {
    signal?: SignalModel;
    edgeSignal?: EdgeSignalModel;
    protocol: EdgeEthernetModel;
    devices: DeviceModel[];
    siteGUID: string;
    protocolID: number | undefined
    handleShowSettingsModal: (signal: SignalModel | undefined, protocolID: number | undefined) => void;
}

export const EthernetSignalRow = ({ signal, edgeSignal, protocol, devices, siteGUID, protocolID, handleShowSettingsModal }: EthernetSignalRowProps) => {
    const dispatch: AppDispatch = useAppDispatch();

    const [canEdit, setCanEdit] = useState(false);
    const [saving, setSaving] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [deviceID, setDeviceID] = useState((signal && signal.SignalDevice) && signal.SignalDevice.DeviceID);
    const [tag, setTag] = useState(protocol && protocol.Tag);
    const [variableType, setVariableType] = useState(protocol && protocol.VariableType);
    const [niceName, setNiceName] = useState(signal ? signal.NiceName : "");


    function EthernetSignalSaveHandler() {
        if (signal) {
            setSaving(true)
            let signalData: IUpdateProtocol = {
                SignalID: signal.SignalID,
                Protocol: 4,
                ProtocolFields: {
                    ...protocol,
                    Tag: tag,
                    VariableType: variableType
                },
                DeviceID: deviceID || ""
            }
            fetch("/api/SignalSetupAPI/UpdateProtocol", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(signalData),
            })
            .then(() => setSaving(false))
            .catch(err => {
                console.error(err);
                setSaving(false)
            });
        }
    }

    useEffect(() => {
        if (signal) {
            //Get access level
            $.ajax({
                url: '/api/AuthorizationAPI/GetAccessLevel/',
                type: 'get',
                data: {
                    accessControlID: signal.AccessControlID
                },
                success: function (response) {
                    var data = JSON.parse(response);
                    setCanEdit(data === 2);
                }
            })
        }
        else {
            setCanEdit(true)
        }
    }, []);

    return (
        <tr>
            <td>{signal ?
                signal.NiceName :
                <Form.Control value={niceName} onChange={e => setNiceName(e.target.value)} />}
            </td>
            <td className="row d-none d-lg-block" style={{ width: '150px', height: '24px' }}>
                {signal && <SparkLine signalID={signal.SignalID} />}
            </td>

            <td className="text-center">{(signal && signal.SignalDevice) ?
                signal.SignalDevice.Device.NiceName :
                <Form.Select value={deviceID} onChange={e => { setDeviceID(e.target.value); setUpdated(true) }}>
                    {devices.length > 0 && <option>Select Device</option>}
                    {devices.map(device => <option key={`device-${device.DeviceID}`} value={device.DeviceID}>{device.NiceName}</option>)}
                </Form.Select>}
            </td>
            <td className="text-center d-flex justify-content-center">
                <Form.Control style={{ width: '84px' }} value={tag} onChange={e => { setTag(e.target.value); setUpdated(true) }} />
            </td>
            <td className="text-center">
                <Form.Select value={variableType} onChange={e => { setVariableType(Number(e.target.value)); setUpdated(true) }}>
                    <option value={undefined}></option>
                    <option value={0}>Boolean</option>
                    <option value={1}>Int 8</option>
                    <option value={2}>Int 16</option>
                    <option value={3}>Int 32</option>
                    <option value={4}>Int 64</option>
                    <option value={5}>Float</option>
                    <option value={6}>String</option>
                </Form.Select>
            </td>

            <td className="text-center" style={{ width: '100px' }}>
                <Button className="far fa-cog" onClick={() => handleShowSettingsModal(signal, protocolID)} variant="outline-primary" />
            </td>
            <td className="text-center" style={{ width: '100px' }}>{updated &&
                <Button onClick={EthernetSignalSaveHandler} variant="outline-primary">
                    {saving ? <img style={{ height: '24px', width: '24px' }} src="/lib/ajax-loader.gif" /> :
                        (signal ? "Update" : "Create")}
                </Button>}
            </td>
        </tr>
    );
}

export interface OtherEdgeSignalRowProps {
    signalID: string;
    niceName: string;
    deviceID: string;
    devices: DeviceModel[];
}
export const OtherEdgeSignalRow = ({ signalID, niceName, deviceID, devices }: OtherEdgeSignalRowProps) => {
    const dispatch: AppDispatch = useAppDispatch();

    const _handleDelete = () => {
        fetch(`/api/SignalSetupAPI/DeleteEdgeSignal/?SignalID=${signalID}&DeviceID=${deviceID}`)
            .then(res => {
                if (res.ok) {
                    dispatch(deleteOtherEdge(signalID));
                }
            })
    }
    return (
        <tr>
            <td>{signalID}</td>

            <td className="text-center">
                <Form.Select value={deviceID} disabled>
                    {devices.length > 0 && <option>Select Device</option>}
                    {devices.map(device => <option key={`device-${device.DeviceID}`} value={device.DeviceID}>{device.NiceName}</option>)}
                </Form.Select>
            </td>

            <td className="text-center" style={{ width: '100px' }}>
                <Button className="fad fa-trash" variant="danger" onClick={_handleDelete}></Button>
            </td>
        </tr>
    );
}


interface AdvantechSignalRowProps {
    signal: SignalModel;
    advSignal: AdvantechSignalModel;
    signals: SignalModel[];
    siteGUID: string;
    handleShowSettingsModal: (signal: SignalModel | undefined) => void;
}

export const AdvantechSignalRow = ({ signal, advSignal, signals, siteGUID, handleShowSettingsModal }: AdvantechSignalRowProps) => {
    const [saving, setSaving] = useState(false);
    const [updated, setUpdated] = useState(false);

    const [grad, setGrad] = useState(advSignal ? advSignal.Gradient : 1);
    const [incpt, setIncpt] = useState(advSignal ? advSignal.Intercept : 0);
    const [min, setMin] = useState(advSignal ? advSignal.Minimum : 0);
    const [max, setMax] = useState(advSignal ? advSignal.Maximum : 9999);

    const _handleSaveAdvantech = (outputSignalID: string) => {
        /// Need to implement create new at some point
        setSaving(true);

        const data: AdvantechSignalModel = {
            SignalID: advSignal.SignalID,
            OutputSignalID: outputSignalID,
            Gradient: Number(grad),
            Intercept: Number(incpt),
            Minimum: Number(min),
            Maximum: Number(max)
        }
        fetch("/api/SignalSetupAPI/UpsertAdvantechSignal/", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.ok) {
                setSaving(false);
            }
        })

    }

    return (
        <tr>
            <td>{signal ? signal.NiceName : "No Name"}
            </td>
            <td className="row d-none d-lg-block" style={{ width: '150px', height: '24px' }}>
                {signal && <SparkLine signalID={signal.SignalID} />}
            </td>
            <td>
                <Form.Control value={grad} onChange={e => { setGrad(e.target.value); setUpdated(true); }} />
            </td>
            <td>
                <Form.Control value={incpt} onChange={e => { setIncpt(e.target.value); setUpdated(true); }} />
            </td>
            <td>
                <Form.Control value={min} onChange={e => { setMin(e.target.value); setUpdated(true); }} />
            </td>
            <td>
                <Form.Control value={max} onChange={e => { setMax(e.target.value); setUpdated(true); }} />
            </td>
            <td className="text-center" style={{ width: '100px' }}>
                <Button className="far fa-cog" onClick={() => handleShowSettingsModal(signal)} variant="outline-primary" />
            </td>
            <td className="text-center" style={{ width: '100px' }}>
                {updated &&

                <Button onClick={() => _handleSaveAdvantech(advSignal.OutputSignalID)} variant="outline-primary">
                    {saving ?
                        <img style={{ height: '24px', width: '24px' }} src="/lib/ajax-loader.gif" /> :
                        (signal ? "Update" : "Create")
                    }
                </Button>}
            </td>
        </tr>
    );
}

export const OtherSignalRow = ({ signal }: { signal: SignalModel }) => {
    const [showDelete, setShowDelete] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const _handleDelete = () => {
        setDeleteLoading(true);
        fetch(`/api/SignalSetupAPI/DeleteSignal/?signalID=${signal.SignalID}`)
            .then(res => {
                if (res.ok) {
                    setDeleteLoading(false);
                    setShowDelete(false);
                }
            });
    }

    return (
        <tr>
            <td>
                {signal ? signal.NiceName : "No Name"}
            </td>
            <td className="row d-none d-lg-block" style={{ width: '150px', height: '24px' }}>
                {signal && <SparkLine signalID={signal.SignalID} />}
            </td>
            <td></td>
            <td className="text-center" style={{ width: '100px' }}>
                <OverlayTrigger
                    placement="right"
                    show={showDelete}
                    overlay={
                        <Tooltip>
                            <div className="align-items-center">
                                Confirm delete: <div className="btn btn-danger" onClick={_handleDelete}>
                                    {deleteLoading ?
                                        <img style={{ height: '24px', width: '24px' }} src="/lib/ajax-loader.gif" /> :
                                        <i className="fad fa-trash"></i>}
                                </div>
                            </div>
                        </Tooltip>
                    }>
                    <Button variant="danger" className="fad fa-trash" onClick={() => setShowDelete(!showDelete)}></Button>
                </OverlayTrigger>
            </td>
        </tr>
    )
}
