import React, { useEffect } from 'react';
import $ from 'jquery';
import { NavLink, Link } from 'react-router-dom';
import { Popover, OverlayTrigger, Modal, Button } from 'react-bootstrap';


class Alarm extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        var alarm = <div className={(this.props.lastone ? "" : "border-bottom") + " p-1 row alarmrow "}><div className={"p-1 rounded border"} style={{ fontSize: '12px', 'display': 'inline-block' }}>{this.props.alarm.Time}</div>{this.props.alarm.Name}</div>;

        return (alarm);
    }
}

export class AlarmPill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            NumberUnread: 0,
            Alarms: props.alarms,
            AlarmRenderObjects: [],
            SiteGUID: props.SiteGUID
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.SiteGUID != state.SiteGUID) {
            return {
                SiteGUID: props.SiteGUID
            };
        }

        // Return null if the state hasn't changed
        return null;
    }

    componentDidMount() {
        if (this.state.Alarms !== undefined) {
            var alarmRenderObjects = [];
            var i;
            for (i = 0; i < this.state.Alarms.length; i++) {
                alarmRenderObjects.push(<Alarm key={i + "alarm"} lastone={i == (this.state.Alarms.length - 1)} render={this.Render} alarm={this.state.Alarms[i]} />);
            }

            this.setState({
                Alarms: this.state.Alarms,
                AlarmRenderObjects: alarmRenderObjects,
                NumberUnread: this.state.Alarms.length
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.alarms !== this.props.alarms) {
            if (this.props.alarms !== undefined) {
                var alarmRenderObjects = [];
                var i;
                for (i = 0; i < this.props.alarms.length; i++) {
                    alarmRenderObjects.push(<Alarm key={i + "alarm"} lastone={i == (this.props.alarms.length - 1)} render={this.Render} alarm={this.props.alarms[i]} />);
                };

                this.setState({
                    Alarms: this.props.alarms,
                    AlarmRenderObjects: alarmRenderObjects,
                    NumberUnread: this.props.alarms.length
                });
            }
        }
    }

    Render = () => {
        this.setState({ NumberUnread: this.state.NumberUnread });
    }

    render() {
        return (<div>
            <OverlayTrigger
                trigger="click"
                placement="auto"
                rootClose={true}
                overlay={
                    <Popover>
                        <Popover.Header as="h3">
                            <div className="row">
                                <div className="col pe-0">{this.state.NumberUnread} Active Alarm{this.state.NumberUnread == 1 ? "" : "s"}</div>
                                <div className="col ps-0 text-end">
                                    <NavLink tag={Link} className="nav-icon-container" to={/Alarms/ + this.state.SiteGUID}>
                                        <div>Alarms & Setup</div>
                                    </NavLink>
                                </div>
                            </div>
                        </Popover.Header>
                        <Popover.Body style={{ overflowY: 'scroll', 'height': '400px' }}>
                            <div className="col">
                                {this.state.AlarmRenderObjects}
                            </div>
                        </Popover.Body>
                    </Popover>
                }>
                <div className="border rounded-pill p-2 d-flex justify-content-center notificationpill me-3" id="alarmspopover">
                    <i className={"fa-2x fad fa-swap-opacity fa-bell" + (this.state.NumberUnread > 0 ? " text-warning" : "") + " ms-2 mt-1"} aria-hidden="true"></i>
                    <div className="h4 mt-2 ms-2 me-1" id="AlarmNumber">{this.state.NumberUnread}</div>
                </div>
            </OverlayTrigger>
        </div >
        );
    }
}