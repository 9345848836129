import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'cookies-js';
import $ from 'jquery';
import { Button, Form, InputGroup, Row, Col } from 'react-bootstrap';
import { SplashTableView } from './SplashPageComponents/SplashTableView';
import { SplashTileView } from './SplashPageComponents/SplashTileView';

export function SplashPage(props) {
    const { id } = useParams();
    const [sites, setSites] = useState([]);
    const [metrics, setMetrics] = useState([]);
    // array of siteGUIDs
    const [hiddenSites, setHiddenSites] = useState(Cookies.get("HiddenSites") != undefined ? Cookies.get("HiddenSites") : []);
    const [showHidden, setShowHidden] = useState(false);

    const [sortOption, setSortOption] = useState(Cookies.get("SplashScreenSortOption") != undefined ? Cookies.get("SplashScreenSortOption") : 0);
    const [viewOption, setViewOption] = useState(Cookies.get("SplashScreenViewOption") != undefined ? Cookies.get("SplashScreenViewOption") : 0);
    const [randomnumber, setRandomNumber] = useState(0); // DO NOT delete used for force renders

    const sortMetrics = (siteA, siteB) => {
        try {
            const aMetrics = metrics[siteA.siteGUID];
            const bMetrics = metrics[siteB.siteGUID];

            if (aMetrics != undefined && bMetrics != undefined) {
                if (sortOption == 0) {
                    let aord = aMetrics.activeAlarms + aMetrics.openTickets;
                    let bord = bMetrics.activeAlarms + bMetrics.openTickets;
                    // Add more severe penalties
                    if (!aMetrics.online) { aord += 10 }
                    if (aMetrics.anomalousTrend) { aord += 10 }
                    if (!bMetrics.online) { bord += 10 }
                    if (bMetrics.anomalousTrend) { bord += 10 }

                    return (aord < bord) ? 1 : -1;
                }
                else if (sortOption == 1) {
                    return (siteA.siteName.toLowerCase() > siteB.siteName.toLowerCase()) ? 1 : -1;
                }
                else if (sortOption == 2) {
                    return (aMetrics.activeAlarms < bMetrics.activeAlarms) ? 1 : -1;
                }
                else if (sortOption == 3) {
                    return (aMetrics.openTickets < bMetrics.openTickets) ? 1 : -1;
                }
            }
        }
        catch (err) {
            console.error(err);
            return 1;
        }
    }

    //This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => props.SiteGUIDSetter(id), [id]);

    //Fetch sites
    useEffect(() => {
        $.ajax({
            url: '/api/AuthorizationAPI/GetSiteNames',
            type: 'get',
            success: sites => {
                setSites(sites);

                // get metrics
                for (let i = 0; i < sites.length; i++) {
                    const siteGUID = sites[i].siteGUID
                    fetch(`/api/SiteMetricsAPI/GetSiteMetrics/?siteGUID=${siteGUID}`)
                        .then(res => res.json())
                        .then(data => {
                            if (data) {
                                setMetrics(metrics => {
                                    let newMetrics = { ...metrics };
                                    newMetrics[siteGUID] = data;
                                    return newMetrics
                                });
                            }
                        });    
                }
            }
        });

        SetRandomNumber()
    }, []);

    function SetRandomNumber() {
        setTimeout(function () {
            setRandomNumber(Math.random())
            SetRandomNumber()
        }, 500)
    }

    // Trigger initial sort after all metrics has loaded
    useEffect(() => {
        let newSites = [...sites];
        newSites.sort((a, b) => sortMetrics(a, b));
        setSites(newSites);
    }, [metrics, sortOption]);


    const hideSite = (siteGUID) => {
        let expDate = new Date();
        expDate.setMonth(expDate.getMonth() + 3);
        const newHiddenSites = [...hiddenSites];

        if (newHiddenSites.includes(siteGUID)) {
            const i = newHiddenSites.indexOf(siteGUID);
            newHiddenSites.splice(i, 1);
        }
        else {
            newHiddenSites.push(siteGUID);
        }
        Cookies.set("HiddenSites", newHiddenSites, expDate);
        setHiddenSites(newHiddenSites);
    }

    const changeSorting = (sortOption) => {
        // Expires option is in seconds
        Cookies.set('SplashScreenSortOption', sortOption, { expires: 7999 });
        setSortOption(sortOption);
    }

    const changeViewOption = (viewOption) => {
        // Get date thats 3 months in future
        let expDate = new Date();
        expDate.setMonth(expDate.getMonth() + 3)
        Cookies.set("SplashScreenViewOption", viewOption, { expires: expDate });
        setViewOption(viewOption);
    }

    return (
        <div>
            <Row className="border rounded m-2 p-2">
                <Col className="justify-content-left">
                    <InputGroup>
                        <InputGroup.Text>Sort by</InputGroup.Text>
                        <Form.Select value={sortOption} onChange={e => changeSorting(e.target.value)}>
                            <option value={0}>Overall Health</option>
                            <option value={1}>Site Name</option>
                            <option value={2}>Number of Active Alarms</option>
                            <option value={3}>Number of Open Tickets</option>
                        </Form.Select>
                    </InputGroup>
                </Col>
                <Col className="align-self-center">
                    <InputGroup>
                        <Button variant="outline-light" onClick={() => changeViewOption(0)}>
                            <i className="fa-lg text-dark fas fa-th-large"></i>
                        </Button>
                        <Button variant="outline-light" onClick={() => changeViewOption(1)}>
                            <i className="fa-lg text-dark fad fa-list"></i>
                        </Button>
                        <Button className="ms-4" variant="outline-light" onClick={() => setShowHidden(!showHidden)} title={showHidden ? "Hide Hidden Sites":"Show Hidden Sites"}>
                            <i className={"fa-lg text-dark fad " + (showHidden ? "fa-eye-slash" : "fa-eye")}></i>
                        </Button>
                    </InputGroup>
                </Col>
                <Col className="text-end align-self-center">Please select a site for further insights</Col>
            </Row>

            {
                viewOption == 0 ?
                    // Tile View
                    <SplashTileView
                        sites={sites}
                        allMetrics={metrics}
                        hiddenSites={hiddenSites}
                        hideSite={hideSite}
                        showHidden={showHidden} /> :
                    // Table View
                    <SplashTableView
                        sites={sites}
                        allMetrics={metrics}
                        hiddenSites={hiddenSites}
                        hideSite={hideSite}
                        showHidden={showHidden} />
            }
        </div>
    );
};
