import React, { useState, useEffect, useRef } from 'react';
import { Route, useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { DeviceSetup } from './components/DeviceSetup';
import { Downloads } from './components/Downloads';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import './css/custom.css'
import './css/react-bootstrap-range-slider.css'
import $ from 'jquery'
import { HealthSummary } from './components/HealthSummary';
import { CostBreakdown } from './components/CostBreakdown';
import { DeepDive } from './components/DeepDive';
import { Reports } from './components/Reports';
import { Billing } from './components/Billing';
import { Alarms } from './components/Alarms';
import { AlarmSetup } from './components/AlarmSetup';
import { SplashPage } from './components/SplashPage';
import { SiteDetails } from './components/SiteDetails';
import { Calibration } from './components/Calibrations';
import { Control } from './components/Control';
import { FailSafes } from './components/FailSafes';
import { Signals } from  './components/SignalSetup/SignalSetup';
import { Maintenance } from './components/Maintenance';
import { Analytics } from './components/Analytics';
import { RoleManagement } from './components/RoleManagement';
import { Help } from './components/Help';
import { EdgeDiagnostics } from './components/EdgeDiagnostics';
import { Configurator } from './components/Configurator/Configurator.tsx';
import { ConfiguratorSplashPage } from './components/Configurator/ConfiguratorSplashPage.tsx';
import { SupportTicketsPage } from './components/SupportTickets/SupportTickets.tsx';
import { ControlChain } from './components/ControlChains/ControlChain.tsx';
import { ControlChainsSplashPage } from './components/ControlChains/ControlChainsSplashPage.tsx';

export default function App(props) {
    const [SiteGUID, setSiteGUID] = useState()
    const [HomeTabAccess, setHomeTabAccess] = useState()
    const [AnalyticsTabAccess, setAnalyticsTabAccess] = useState()
    const [HealthSummaryTabAccess, setHealthSummaryTabAccess] = useState()
    const [CostBreakdownTabAccess, setCostBreakdownTabAccess] = useState()
    const [DeepDiveTabAccess, setDeepDiveTabAccess] = useState()
    const [BillingTabAccess, setBillingTabAccess] = useState()
    const [AlarmsTabAccess, setAlarmsTabAccess] = useState()
    const [ReportsTabAccess, setReportsTabAccess] = useState()
    const [SignalsTabAccess, setSignalsTabAccess] = useState()
    const [DownloadsTabAccess, setDownloadsTabAccess] = useState()
    const [CalibrationsTabAccess, setCalibrationsTabAccess] = useState()
    const [ControlTabAccess, setControlTabAccess] = useState()
    const [ControlChainsTabAccess, setControlChainsTabAccess] = useState()
    const [FailSafeTabAccess, setFailSafeTabAccess] = useState()
    const [RoleManagementTabAccess, setRoleManagementTabAccess] = useState()
    const [SiteDetailsTabAccess, setSiteDetailsTabAccess] = useState()
    const [ComplianceDetailsAccess, setComplianceDetailsAccess] = useState()
    const [MetricHistoryAccess, setMetricHistoryAccess] = useState()
    const [EdgeDiagnosticsAccess, setEdgeDiagnosticsAccess] = useState()
    const [SupportTicketsTabAccess, setSupportTicketsTabAccess] = useState()
    const [HelpAccess, setHelpAccess] = useState()
    const [ConfiguratorTabAccess, setConfiguratorTabAccess] = useState()
    const history = useHistory();
    const prevLocation = useRef();
    const controllerRef = useRef(new AbortController());

    useEffect(() => {
        const unlisten = history.listen((newLocation) => {
            console.log({ prevLocation: prevLocation.current });
            console.log({ newLocation: newLocation.pathname });
            if (prevLocation.current !== newLocation.pathname) {
                controllerRef.current.abort();
            }
            prevLocation.current = newLocation.pathname;
            controllerRef.current = new AbortController();
        })
        return function cleanup() {
            unlisten()
        }
    }, [])



    function SetSiteGUID(newSiteGUID) {
        if (!Number(newSiteGUID)) {
            setSiteGUID(newSiteGUID)
            $.ajax({
                url: '/api/AuthorizationAPI/GetPermissions',
                type: 'get',
                data: { siteGUID: newSiteGUID },
                success: function (response) {
                    var data = JSON.parse(response);

                    setHomeTabAccess(data.DisplayHomeTab)
                    setAnalyticsTabAccess(data.DisplayAnalyticsTab)
                    setReportsTabAccess(data.DisplayReportsTab)
                    setSignalsTabAccess(data.SignalSetup)
                    setCalibrationsTabAccess(data.DisplayEdgeTabs)
                    setControlTabAccess(data.DisplayEdgeTabs)
                    setControlChainsTabAccess(data.DisplayControlChainsTab)
                    setFailSafeTabAccess(data.DisplayEdgeTabs);
                    setRoleManagementTabAccess(2); // everyone can see
                    setAlarmsTabAccess(data.DisplayAlarmsTab)
                    setDownloadsTabAccess(1) // No concept of 'edit' for downloads
                    setSiteDetailsTabAccess(data.DisplaySiteDetailsTab)
                    setComplianceDetailsAccess(data.ComplianceDetails)
                    setMetricHistoryAccess(data.MetricHistory)
                    setCostBreakdownTabAccess(data.DisplayCostBreakdownTab)
                    setDeepDiveTabAccess(data.DisplayDeepDiveTab)
                    setBillingTabAccess(data.DisplayBillingTab)
                    setHealthSummaryTabAccess(data.DisplayHealthSummaryTab)
                    setSupportTicketsTabAccess(2) // TODO : Link properly to permissions system
                    setHelpAccess(data.DisplayHelpTab)
                    setConfiguratorTabAccess(2)
                    setEdgeDiagnosticsAccess(2)
                },
                error: function () {
                    console.error("Getting permissions failed")
                }
            });
        }
        else {
            $.ajax({
                url: '/api/AuthorizationAPI/GetPermissionsFromMaintenance',
                type: 'get',
                data: { EquipmentID: newSiteGUID },
                success: function (response) {
                    var data = JSON.parse(response);

                    setHomeTabAccess(data.DisplayHomeTab)
                    setAnalyticsTabAccess(data.DisplayAnalyticsTab)
                    setReportsTabAccess(data.DisplayReportsTab)
                    setSignalsTabAccess(data.SignalSetup)
                    setCalibrationsTabAccess(data.DisplayEdgeTabs)
                    setControlTabAccess(data.DisplayEdgeTabs)
                    setFailSafeTabAccess(data.DisplayEdgeTabs);
                    setControlChainsTabAccess(data.DisplayControlChainsTab)
                    setRoleManagementTabAccess(2); // everyone can see
                    setAlarmsTabAccess(data.DisplayAlarmsTab)
                    setDownloadsTabAccess(1) // No concept of 'edit' for downloads
                    setSiteDetailsTabAccess(data.DisplaySiteDetailsTab)
                    setComplianceDetailsAccess(data.ComplianceDetails)
                    setMetricHistoryAccess(data.MetricHistory)
                    setCostBreakdownTabAccess(data.DisplayCostBreakdownTab)
                    setDeepDiveTabAccess(data.DisplayDeepDiveTab)
                    setBillingTabAccess(data.DisplayBillingTab)
                    setHealthSummaryTabAccess(data.DisplayHealthSummaryTab)
                    setHelpAccess(data.DisplayHelpTab)
                    setConfiguratorTabAccess(2)
                    setEdgeDiagnosticsAccess(2)
                    setSiteGUID(data.Id)
                },
            });
        }
    }

    return (
        <Layout SiteGUID={SiteGUID} SiteGUIDSetter={SetSiteGUID} >
            <Route exact path='/' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <SplashPage SiteGUIDSetter={SetSiteGUID} SiteGUID={SiteGUID} abortSignal={controllerRef.current.signal} />
            )} />
            <Route exact path='/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <Home SiteGUIDSetter={SetSiteGUID} auth={HomeTabAccess} />
            )} />
            <Route path='/Analytics/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <Analytics SiteGUIDSetter={SetSiteGUID} auth={AnalyticsTabAccess} />
            )} />
            <Route path='/HealthSummary/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <HealthSummary SiteGUIDSetter={SetSiteGUID} auth={HealthSummaryTabAccess} />
            )} />
            <Route path='/CostBreakdown/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <CostBreakdown SiteGUIDSetter={SetSiteGUID} auth={CostBreakdownTabAccess} />
            )} />
            <Route exact path='/DeepDive/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <DeepDive SiteGUIDSetter={SetSiteGUID} auth={DeepDiveTabAccess} />
            )} />
            <Route path='/Billing/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <Billing SiteGUIDSetter={SetSiteGUID} auth={BillingTabAccess} />
            )} />
            <Route path='/Alarms/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <Alarms SiteGUIDSetter={SetSiteGUID} auth={AlarmsTabAccess} />
            )} />
            <Route path='/AlarmSetup/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <AlarmSetup SiteGUIDSetter={SetSiteGUID} auth={AlarmsTabAccess} />
            )} />
            <Route path='/RoleManagement/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <RoleManagement SiteGUIDSetter={SetSiteGUID} auth={RoleManagementTabAccess} />
            )} />
            <Route path='/Reports/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <Reports SiteGUIDSetter={SetSiteGUID} auth={ReportsTabAccess} />
            )} />
            <Route path='/SiteDetails/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <SiteDetails SiteGUIDSetter={SetSiteGUID} auth={SiteDetailsTabAccess} MetricHistoryAccess={MetricHistoryAccess} ComplianceDetailsAccess={ComplianceDetailsAccess} />
            )} />
            <Route path='/Calibration/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <Calibration SiteGUIDSetter={SetSiteGUID} auth={CalibrationsTabAccess} />
            )} />
            <Route path='/Control/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <Control SiteGUIDSetter={SetSiteGUID} auth={ControlTabAccess} />
            )} />
            <Route path='/FailSafes/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <FailSafes SiteGUIDSetter={SetSiteGUID} auth={FailSafeTabAccess} />
            )} />
            <Route path='/Signals/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <Signals SiteGUIDSetter={SetSiteGUID} auth={SignalsTabAccess} />
            )} />
            <Route path='/DeepDive/Maintenance/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <Maintenance SiteGUIDSetter={SetSiteGUID} auth={DeepDiveTabAccess} />
            )} />
            <Route path='/Downloads/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <Downloads SiteGUIDSetter={SetSiteGUID} auth={DownloadsTabAccess} />
            )} />
            <Route path='/DeviceSetup/:id' render={(props) => (
                <DeviceSetup />
            )} />
            <Route path='/Help/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <Help SiteGUIDSetter={SetSiteGUID} auth={HelpAccess} />
            )} />
            <Route path='/EdgeDiagnostics/:id' SiteGUIDSetter={SetSiteGUID} render={(props) => (
                <EdgeDiagnostics SiteGUIDSetter={SetSiteGUID} auth={EdgeDiagnosticsAccess} />
            )} />
            <Route exact path='/Configurator/index/:id' render={(props) => (
                <ConfiguratorSplashPage SiteGUIDSetter={SetSiteGUID} />
            )} />
            <Route exact path='/Configurator/index/:SiteGUID/:ConfigID' render={(props) => (
                <Configurator SiteGUIDSetter={SetSiteGUID} auth={ConfiguratorTabAccess} />
            )} />
            <Route exact path='/SupportTickets/:id' render={(props) => (
                <SupportTicketsPage SiteGUIDSetter={SetSiteGUID} auth={SupportTicketsTabAccess} />
            )} />
            <Route exact path='/ControlChains/index/:id' render={(props) => (
                <ControlChainsSplashPage SiteGUIDSetter={SetSiteGUID} auth={ControlChainsTabAccess} />
            )} />
            <Route exact path='/ControlChains/index/:SiteGUID/:ChainID' render={(props) => (
                <ControlChain SiteGUIDSetter={SetSiteGUID} auth={ControlChainsTabAccess} />
            )} />
        </Layout>
    );
}
