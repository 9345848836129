import React, { memo, useEffect, useState } from 'react';
import { Handle } from 'react-flow-renderer';
import { StreamwiseControlLinkData, PIDControlParameters } from '../types';
import './styles.scss'
import { Button, Modal, Accordion, InputGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faCircleTrash, faCircleInfo } from '@fortawesome/pro-solid-svg-icons'


export function SourceModal(props) {
    const [controlLinkData, setControlLinkData] = useState<StreamwiseControlLinkData>();

    useEffect(() => {
        if (props.show) {
            props.getData(props.id).then(data => {
                setControlLinkData(data.controlLinkData);
            });
        }
    }, [props.show])

    const saveControlLinkSettings = () => {
        props.getData(props.id).then(data => {
            props.saveSettings(props.id, { ...data, controlLinkData: controlLinkData });
            props.onHide();
        });
    }

    // Sets the current signal selected variable also
    const _handleInputSelect = newSignalID => {
        var signal = props.signals.filter(s => s.id === newSignalID);
        setControlLinkData({ ...controlLinkData, inputBlock: newSignalID, signalID: newSignalID, nicename: signal[0].nicename });
    }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-source"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-source">
                    Select Input Signal
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Input Signal: </InputGroup.Text>
                    <Form.Select
                        value={controlLinkData?.signalID}
                        onChange={v => _handleInputSelect(v.target.value)}>
                        <option>Select Signal</option>
                        {props.signals.map(d => <option key={d.id + "key"} value={d.id}>{d.nicename}</option>)}
                    </Form.Select>
                </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    saveControlLinkSettings();
                }}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}