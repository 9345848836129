import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Accordion, Button, Table, Modal, InputGroup, Form, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import {
    SupportTicketsProps,
    Statuses,
    SupportTicketRowProps,
    NewTicketRowProps,
    ModalProps
} from "./types";
import { TicketDetails } from "./TicketDetails";
import { useTickets } from "./useTickets";
import moment from "moment";
import { PriorityBadge, StatusBadge } from "./Badges";
import "react-step-progress-bar/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import useDoubleClick from 'use-double-click';

const CloseTicketModal: React.FC<ModalProps> = ({ data, editTicket, addComment, show, onHide }) => {
    const [comments, setComments] = useState("")

    function CloseTicket() {
        addComment(data.id, comments, data.name, data.siteGUID);
        const ticket = data;
        ticket.ticketStatus = Statuses.Closed;
        editTicket(ticket);
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            size="sm"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Close Ticket
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="justify-content-center mb-3">
                    <InputGroup.Text>Comments</InputGroup.Text>
                    <Form.Control
                        rows={3}
                        as='textarea'
                        type='textarea'
                        onChange={e => setComments(e.target.value)}>
                    </Form.Control>
                </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => { CloseTicket(); onHide(); }} data-toggle="modal" data-dismiss="modal">Close Ticket</Button>
            </Modal.Footer>
        </Modal>
    );
}

const ReopenTicketModal: React.FC<ModalProps> = ({ data, editTicket, addComment, show, onHide }) => {
    const [comments, setComments] = useState("")

    function ReopenTicket() {
        addComment(data.id, comments, data.name, data.siteGUID);
        const ticket = data;
        ticket.ticketStatus = Statuses.Assigned;
        editTicket(ticket);
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            size="sm"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Reopen Ticket
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="justify-content-center mb-3">
                    <InputGroup.Text>Comments</InputGroup.Text>
                    <Form.Control
                        rows={3}
                        as='textarea'
                        type='textarea'
                        onChange={e => setComments(e.target.value)}>
                    </Form.Control>
                </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => { ReopenTicket(); onHide(); }} data-toggle="modal" data-dismiss="modal">Reopen Ticket</Button>
            </Modal.Footer>
        </Modal>
    );
}


const NewTicketRow: React.FC<NewTicketRowProps> = ({ siteGUID, createTicket, setNewRow }) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const _handleCreateNew = () => {
        createTicket(name, description, siteGUID);
        
        _handleReset();
    }

    const _handleReset = () => {
        setNewRow(false);

        // Reset fields
        setName("");
        setDescription("");
    }

    return (
        <tr className="justify-content-center">
            <td>
                <textarea
                    className="p-1"
                    style={{ resize: 'none' }}
                    rows={1}
                    contentEditable={true}
                    value={name}
                    placeholder={"Ticket title"}
                    onChange={e => setName(e.target.value)} />
            </td>
            <td>
                <textarea
                    className="p-1"
                    style={{ resize: 'none', width:"100%" }}
                    rows={1}
                    contentEditable={true}
                    value={description}
                    placeholder={"Ticket summary"}
                    onChange={e => setDescription(e.target.value)} />
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td><Button onClick={_handleCreateNew}>Save</Button></td>
            <td><Button onClick={_handleReset} variant="outline-danger" className="fad fa-trash" ></Button></td>
        </tr>)
}

const SupportTicketRow: React.FC<SupportTicketRowProps> = ({ data, editTicket, addComment, setSelectedTicket, edit }) => {
    const [editing, setEditing] = useState(false);

    const [name, setName] = useState(data.name);
    const [description, setDescription] = useState(data.description);
    const [showCloseTicket, setShowCloseTicket] = useState(false);
    const [showReopenTicket, setShowReopenTicket] = useState(false);

    const _handleKeyDown = (event) => {
        // Save if escape or enter key is pressed
        if (event.keyCode === 27 || event.keyCode === 13) {
            setEditing(false);

            // Check if content has actually changed
            if (data.name !== name || data.description !== description) {
                let newData = { ...data }
                newData.name = name;
                newData.description = description;
                editTicket(newData);
            }
        }
    }

    const nameEditRef = useRef(null);
    const descEditRef = useRef(null);
    const nameRef = useRef(null);

    useDoubleClick({
        onSingleClick: e => {
            if (!editing) {
                setSelectedTicket(e.target.id);
            }
        },
        onDoubleClick: () => setEditing(true),
        ref: nameRef,
        latency: 250
    });
    

    // Disables editing if clicks outside the editing boxes
    useEffect(() => {
        function handleClickOutside(event) {
            if ((nameEditRef.current &&
                !nameEditRef.current.contains(event.target) &&
                descEditRef.current &&
                !descEditRef.current.contains(event.target))) {
                setEditing(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        // Unbind the event listener on cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <tr className="justify-content-center">
            <OverlayTrigger
                placement="top"
                delay={{ show: 150, hide: 300 }}
                overlay={<Tooltip id="button-tooltip">Click for Ticket Details</Tooltip>}>
                <td id={data.id} ref={nameRef} style={{ verticalAlign: "middle" }}>
                    {editing ?
                        <textarea
                            ref={nameEditRef}
                            style={{ padding: '2px', resize: 'none' }}
                            contentEditable={editing}
                            value={name}
                            onChange={e => setName(e.target.value)}
                            onKeyDown={e => _handleKeyDown(e)} /> :
                        data.name
                    }
                </td>
            </OverlayTrigger>
            <OverlayTrigger 
                placement="top"
                delay={{ show: 150, hide: 300 }}
                overlay={<Tooltip id="button-tooltip">Double click to edit</Tooltip>}>
                <td onDoubleClick={() => setEditing(true)} style={{ verticalAlign: "middle" }}>
                    {editing ?
                        <textarea
                            ref={descEditRef}
                            style={{ padding: '2px', resize: 'none', width: "100%" }}
                            contentEditable={editing}
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            onKeyDown={e => _handleKeyDown(e)} /> :
                        data.description
                    }
                </td>
            </OverlayTrigger>
            <td style={{ verticalAlign: "middle" }}>{moment(data.lastUpdatedDate).fromNow()}</td>
            <td style={{ verticalAlign: "middle" }}>{moment(data.createDate).fromNow()}</td>
            <td style={{ verticalAlign: "middle" }}><StatusBadge data={data} editTicket={editTicket} /></td>
            <td style={{ verticalAlign: "middle" }}><PriorityBadge data={data} editTicket={editTicket} /></td>
            <td style={{ verticalAlign: "middle" }}>{ data.ticketStatus === Statuses.Closed ? 
            <OverlayTrigger 
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip id="button-tooltip">Reopen Ticket</Tooltip>}>
            <Button variant="outline-primary" onClick={() => setShowReopenTicket(true)}><FontAwesomeIcon icon={faRotateRight} /></Button>
            </OverlayTrigger>
             : 
             <OverlayTrigger 
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={<Tooltip id="button-tooltip">Close Ticket</Tooltip>}>
             <Button variant="outline-danger" onClick={() => setShowCloseTicket(true)}><FontAwesomeIcon icon={faXmark} /></Button>
             </OverlayTrigger>}
            </td>
            
            <CloseTicketModal
                show={showCloseTicket}
                addComment={addComment}
                editTicket={editTicket}
                data={data}
                onHide={() => {
                    setShowCloseTicket(false);
                }}
            />
            <ReopenTicketModal
                show={showReopenTicket}
                addComment={addComment}
                editTicket={editTicket}
                data={data}
                onHide={() => {
                    setShowReopenTicket(false);
                }}
            />
        </tr>
    );
}

export const SupportTicketsPage: React.FC<SupportTicketsProps> = ({
    SiteGUIDSetter,
}) => {
    const { id } = useParams<any>();
    const history = useHistory();
    const {
        loading,
        tickets,
        editTicket,
        addAttachment,
        createTicket,
        addComment,
    } = useTickets(id);

    const [selectedTicket, setSelectedTicket] = useState<string | undefined>();
    const [newRow, setNewRow] = useState<boolean>(false);

    // This needs to be here because only the routed pages have access to id. This sends the id back to the navbar.
    useEffect(() => SiteGUIDSetter(id), [id]);

    return (
        <>
            <div className="mt-4">
                {selectedTicket === undefined ? (
                    <>
                        <Accordion className="mt-3" defaultActiveKey="openTickets">
                            <Accordion.Item eventKey="openTickets">
                                <Accordion.Header>Open Tickets</Accordion.Header>
                                <Accordion.Body>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: 'auto' }}>Ticket</th>
                                                <th style={{ flexGrow: 1 }}>Description</th>
                                                <th style={{ width: 'auto' }}>Last Activity</th>
                                                <th style={{ width: 'auto' }}>Opened On</th>
                                                <th style={{ width: '10%', textAlign: "center" }}>Status</th>
                                                <th style={{ width: 'auto' }}>Priority</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ?
                                                <tr>
                                                    <td colSpan={6} style={{ textAlign: 'center', borderWidth:0 }}><Spinner style={{ marginTop: 10 }} animation="border" role="status" /></td>
                                                </tr>:
                                                tickets.map(tk => (tk.ticketStatus !== Statuses.Closed) &&
                                                    <SupportTicketRow
                                                        key={tk.id}
                                                        data={tk}
                                                        editTicket={editTicket}
                                                        addComment={addComment}
                                                        setSelectedTicket={setSelectedTicket} />)
                                            }
                                            {newRow && <NewTicketRow siteGUID={id} createTicket={createTicket} setNewRow={setNewRow} />}
                                        </tbody>
                                    </Table>
                                    <Button variant="outline-primary" size="sm" onClick={() => setNewRow(true)}>
                                        <i className="fal fa-plus me-1"></i>
                                        New Ticket
                                    </Button>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="closedTickets">
                                <Accordion.Header>Closed Tickets</Accordion.Header>
                                <Accordion.Body>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: 'auto' }}>Ticket</th>
                                                <th style={{ flexGrow: 1 }}>Description</th>
                                                <th style={{ width: 'auto' }}>Last Activity</th>
                                                <th style={{ width: 'auto' }}>Opened On</th>
                                                <th style={{ width: '10%', textAlign: "center" }}>Status</th>
                                                <th style={{ width: 'auto' }}>Priority</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ?
                                                <tr>
                                                    <td colSpan={6} style={{ textAlign: 'center', borderWidth: 0 }}><Spinner style={{ marginTop: 10 }} animation="border" role="status" /></td>
                                                </tr>:
                                                tickets.map(tk => (tk.ticketStatus === Statuses.Closed) &&
                                                    <SupportTicketRow
                                                        key={tk.id}
                                                        data={tk}
                                                        editTicket={editTicket}
                                                        addComment={addComment}
                                                        setSelectedTicket={setSelectedTicket} />)
                                            }
                                        </tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                ) : (
                        <>
                            <TicketDetails
                                ticketData={tickets.find(t => t.id === selectedTicket)!!}
                                editTicket={editTicket}
                                addComment={addComment}
                                setSelectedTicket={setSelectedTicket}
                                addAttachment={addAttachment}
                            />
                        </>
                )}
            </div>
        </>
    );
};