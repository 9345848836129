import React, { useState, useEffect, useContext } from 'react';
import { InputGroup, FormControl, Button, Modal, Form } from 'react-bootstrap';
import { Container, Col, Row } from 'react-bootstrap'
import { ConfigContext } from "./Gauge"

function DeleteGaugeModal(props) {

    const handleDelete = (gaugeid) => {
        var url = '/api/AnalyticsAPI/RemoveGauge/'; // TODO
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(gaugeid)
        }).then(response => response.status)
            .then(status => {
                props.onremove(gaugeid)
                props.onHide()
            });
    }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Are you sure you want to delete this gauge?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Deleting a gauge cannot be undone.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={props.onHide}>Cancel</Button>
                        </Col>
                        <Col>
                            <Button className="float-end" variant="danger" onClick={() => handleDelete(props.gaugeid)}>Confirm</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

function SaveGaugeModal(props) {
    const handleSave = (config) => {
        props.setLoading(true);
        var newConfig = JSON.parse(JSON.stringify(config));

        if (newConfig.InsightGaugeInputs) {
            // If no ID it is a new LCI, set to 0 so API accepts payload
            newConfig.InsightGaugeInputs.forEach(l => { !l.GaugeID ? l.GaugeID = 0 : l.GaugeID = l.GaugeID })
        } else {
            newConfig.InsightGaugeInputs = [];
        }

        var url = '/api/AnalyticsAPI/SaveGaugeSettings/'; // TODO
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newConfig)
        }).then(response => response.json())
            .then(data => {
                props.updateconfig(data)
                props.onHide()
            });
    }

    return (
        <Modal
            backdrop="static"
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Are you sure you want to save changes?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    This will overwrite previous settings and cannot be undone.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={props.onHide}>Cancel</Button>
                        </Col>
                        <Col>
                            <Button className="float-end" variant="success" onClick={() => handleSave(props.config)}>Save</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

function GaugeSettings(props) {
    const { config,
        updateGaugeName,
        updateMetric,
        updateRedMax,
        updateYellowMax,
        updateSignal0,
        updateSignal1,
        updateSignal2,
        updateConfig,
        setLoading } = useContext(ConfigContext);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [saveModalShow, setSaveModalShow] = useState(false);
    const [equationOptions, setEquationOptions] = useState([]);

    useEffect(() => {
        var url = '/api/AnalyticsAPI/GetEquations';
        fetch(url).then(response => response.json())
            .then(data => {
                var options = data.map(o => <option value={o.EquationID}>{o.EquationDescription}</option>)
                setEquationOptions(options)
            });
    }, [])

    return (
        config ?
            <>
                <Form>
                    <Row>
                        <Col>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>Gauge name: </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    defaultValue={config.GaugeName}
                                    onChange={(e) => { updateGaugeName(e.target.value) }}>
                                </FormControl>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>Red Max: </InputGroup.Text>
                                <FormControl
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="100"
                                    aria-describedby="basic-addon3"
                                    value={config.RedMax}
                                    onChange={(e) => { updateRedMax(e.target.value) }}>
                                </FormControl>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>Yellow Max: </InputGroup.Text>
                                <FormControl
                                    type="number"
                                    step="1"
                                    min="0"
                                    max="100"
                                    aria-describedby="basic-addon3"
                                    value={config.YellowMax}
                                    onChange={(e) => { updateYellowMax(e.target.value) }}>
                                </FormControl>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Range
                                custom
                                step="1"
                                min="0"
                                max="100"
                                aria-describedby="basic-addon3"
                                value={config.RedMax}
                                onChange={(e) => { updateRedMax(e.target.value) }}
                            />
                        </Col>
                        <Col>
                            <Form.Range
                                custom
                                step="1"
                                min="0"
                                max="100"
                                aria-describedby="basic-addon3"
                                value={config.YellowMax}
                                onChange={(e) => { updateYellowMax(e.target.value) }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>Metric: </InputGroup.Text>
                                <FormControl
                                    as="select"
                                    custom
                                    value={config.EquationID}
                                    onChange={(e) => { updateMetric(e.target.value) }}>
                                    {equationOptions}
                                </FormControl>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>{
                                    config.EquationID == 5 ?
                                        "Signal of interest: "
                                        : "Flow rate signal: "}</InputGroup.Text>
                                <FormControl
                                    as="select"
                                    custom
                                    value={
                                        config.InsightGaugeInputs !== null ?
                                            config.InsightGaugeInputs.find((i) => i.InputPosition == 0) !== undefined ?
                                                config.InsightGaugeInputs.find((i) => i.InputPosition == 0).InputSignalID
                                                :
                                                ""
                                            : ""
                                    }
                                    onChange={(e) => { updateSignal0(e.target.value) }}>
                                    <option value={null}>Select signal</option>
                                    {props.signals}
                                </FormControl>
                            </InputGroup>
                        </Col>
                    </Row>

                    {/*
                    EquationID == 1 -> 1 input (flow rate)
                    EquationID == 2 -> 2 inputs (flow rate, signal of interest) 
                    EquationID == 3 -> 2 input (flow rate, signal of interest)
                    EquationID == 4 -> 3 inputs (flow rate, inlet metric, outlet metric)
                    EquationID == 5 -> 1 input (signal of interest)
                    */
                        config.EquationID == 2 || config.EquationID == 3 ?
                            <Row>
                                <Col>
                                    <InputGroup className="justify-content-center mb-2">
                                        <InputGroup.Text>Signal of interest: </InputGroup.Text>
                                        <FormControl
                                            as="select"
                                            custom
                                            value={
                                                config.InsightGaugeInputs !== null ?
                                                    config.InsightGaugeInputs.find((i) => i.InputPosition == 1) !== undefined ?
                                                        config.InsightGaugeInputs.find((i) => i.InputPosition == 1).InputSignalID
                                                        :
                                                        ""
                                                    :
                                                    ""
                                            }
                                            onChange={(e) => { updateSignal1(e.target.value) }}>
                                            <option value={null}>Select signal</option>
                                            {props.signals}
                                        </FormControl>
                                    </InputGroup>
                                </Col>
                            </Row>
                            :
                            config.EquationID == 4 ?
                                <>
                                    <Row>
                                        <Col>
                                            <InputGroup className="justify-content-center mb-2">
                                                <InputGroup.Text>Inlet signal: </InputGroup.Text>
                                                <FormControl
                                                    as="select"
                                                    custom
                                                    value={
                                                        config.InsightGaugeInputs !== null ?
                                                            config.InsightGaugeInputs.find((i) => i.InputPosition == 1) !== undefined ?
                                                                config.InsightGaugeInputs.find((i) => i.InputPosition == 1).InputSignalID
                                                                :
                                                                ""
                                                            :
                                                            ""
                                                    }
                                                    onChange={(e) => { updateSignal1(e.target.value) }}>
                                                    <option value={null}>Select signal</option>
                                                    {props.signals}
                                                </FormControl>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputGroup className="justify-content-center mb-2">
                                                <InputGroup.Text>Outlet signal: </InputGroup.Text>
                                                <FormControl
                                                    as="select"
                                                    custom
                                                    value={
                                                        config.InsightGaugeInputs !== null ?
                                                            config.InsightGaugeInputs.find((i) => i.InputPosition == 2) !== undefined ?
                                                                config.InsightGaugeInputs.find((i) => i.InputPosition == 2).InputSignalID
                                                                :
                                                                ""
                                                            :
                                                            ""
                                                    }
                                                    onChange={(e) => { updateSignal2(e.target.value) }}>
                                                    <option value={null}>Select signal</option>
                                                    {props.signals}
                                                </FormControl>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </>
                                :
                                <></>
                    }
                    <Row>
                        <Col>
                            <Button variant="danger" onClick={() => setDeleteModalShow(true)}>Delete gauge  <i className="fas fa-trash-alt"></i></Button>
                            <DeleteGaugeModal
                                show={deleteModalShow}
                                onHide={() => {
                                    setDeleteModalShow(false);
                                    props.hideSettings();
                                }}
                                onremove={props.onremove}
                                gaugeid={config.GaugeID}
                            />
                        </Col>
                        <Col>
                            <Button className="float-end" variant="primary" onClick={() => setSaveModalShow(true)}>Save settings  <i className="fas fa-save"></i></Button>
                            <SaveGaugeModal
                                show={saveModalShow}
                                onHide={() => {
                                    setSaveModalShow(false);
                                    props.hideSettings();
                                }}
                                config={config}
                                updateconfig={updateConfig}
                                setLoading={setLoading}
                            />
                        </Col>
                    </Row>
                </Form>
            </>
            :
            "Loading..."
    )
}

export default GaugeSettings;