import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import { StreamwiseNodeData } from '../types';
import './styles.scss'
import { Button } from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
//import {faCircleTrash, faCircleInfo} from '@fortawesome/pro-solid-svg-icons'


export default memo(
  ({
    id,
    data,
  }: {
    id: string;
    data: StreamwiseNodeData;
  }) => {
    return (
      <>
        <div className="handleLabelTop" style={{ left: 40 }}>
        </div>
        <Handle
          type="source"
          position="top"
          id="WIRELESS"
          style={{ left: 40, background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={false}
        />
        <div className="nodeText">{data.shortName}</div>
        <div style={{height: '20px'}}>
          <div className="infoButton">
           {/* <FontAwesomeIcon icon={faCircleInfo} />*/}
          </div>
          <div className="trashButton" onClick={() => {if (data.onDelete) data.onDelete(id)}}>
            {/*<FontAwesomeIcon icon={faCircleTrash} />*/}
          </div>
        </div>
      </>
    );
  }
);