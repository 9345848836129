import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import AdvantechSignalReducer from './AdvantechSignalReducer';
import EdgeSignalReducer from './EdgeSignalReducer';

export const store = configureStore({
    reducer: {
        edgeSignal: EdgeSignalReducer,
        advSignal: AdvantechSignalReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()