import { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Masonry from "masonry-layout";

var $grid;

export const SplashTileView = ({ sites, allMetrics, hiddenSites, hideSite, showHidden }) => {
    // Set up tile animations
    const masonryOptions = {
        itemSelector: ".grid-item",
        animate: true,
        stagger: 20,
        columnWidth: ".grid-sizer",
        percentPosition: true,
    };

    useEffect(() => {
        const elem = document.querySelector("#sitecards");
        $grid = new Masonry(elem, masonryOptions);
    }, []);

    // Trigger shuffling animation upon sort
    useEffect(() => {
        setTimeout(() => {
            $grid.reloadItems();
            $grid.layout();
        }, 500);
    }, [sites, hiddenSites, showHidden]);

    return (
        <div id="sitecards" className="container-fluid p-0">
            <div className="grid-sizer col-md-1"></div>
            {sites.map(
                (s) =>
                    (!hiddenSites.includes(s.siteGUID) || showHidden) && (
                        <SiteCard
                            key={s.siteGUID + "-sitecard"}
                            site={s}
                            metrics={allMetrics[s.siteGUID]}
                            hideSite={hideSite}
                            isHidden={hiddenSites.includes(s.siteGUID)}
                        />
                    )
            )}
        </div>
    );
};

const SiteCard = ({ site, metrics, hideSite, isHidden }) => {
    const [cardHover, setCardHover] = useState("");
    const [alarmHover, setAlarmHover] = useState("");
    const [ticketHover, setTicketHover] = useState("");
    const history = useHistory();

    const NavigateToSite = () => {
        history.push("/" + site.siteGUID);
    };

    const NavigateToAlarms = () => {
        history.push(`/Alarms/${site.siteGUID}`);
    };

    const NavigateToTickets = () => {
        history.push(`/SupportTickets/${site.siteGUID}`);
    };

    return (
        <div className="grid-item col-lg-4 col-md-6 p-0" siteguid={site.siteGUID}>
            <Card className={`m-2 shadow-sm ${cardHover} ` + (isHidden ? "opacity-50" : "")}>
                {/* Site name as card header */}
                <Card.Header
                    className="align-items-center"
                    onMouseEnter={() => setCardHover("splashscreencard")}
                    onMouseLeave={() => setCardHover("")}
                >
                    <span onClick={NavigateToSite}>{site.siteName}</span>
                    <i
                        className={"float-end fas " + (isHidden ? "fa-plus" : "fa-minus")}
                        style={{ cursor: "pointer", lineHeight: "24px" }}
                        onClick={() => hideSite(site.siteGUID)}
                        title="Hide site"
                    ></i>
                </Card.Header>

                <Card.Body className="p-0">
                    {metrics ? (
                        <>
                            <div className="d-flex d-inline align-items-center">
                                {/* Edge Devices Online */}
                                <div className="p-3 w-100 text-center d-flex justify-content-center">
                                    <i
                                        className={
                                            "mt-1 fa-lg " +
                                            (metrics.online
                                                ? "fas fa-signal-alt text-success"
                                                : "fad fa-signal-alt-slash text-danger")
                                        }
                                        title={`${
                                            metrics.online
                                                ? `Site online: ${
                                                        metrics.numDevices > 1
                                                            ? `${metrics.onlineDevices} of ${metrics.numDevices} edge devices online`
                                                            : "Edge device online"
                                                    }`
                                                : `Site offline: ${
                                                        metrics.numDevices > 1
                                                            ? `All (${metrics.numDevices}) edge devices offline`
                                                            : "Edge device offline"
                                                    }`
                                        }`}
                                    ></i>
                                </div>

                                <div className="border" style={{ height: "25px" }}></div>

                                {/* Anomolous Signal Trend */}
                                <div className="p-3 w-100 text-center d-flex justify-content-center">
                                    <i
                                        className={
                                            "mt-1 fa-lg fad fa-analytics " +
                                            (metrics.anomalousTrend ? "text-danger" : "text-success")
                                        }
                                        title={metrics.anomalousTrend ? "Signal Problem Detected" : "Signals Normal"}
                                    ></i>
                                </div>

                                <div className="border" style={{ height: "25px" }}></div>

                                {/* Active Alarms */}
                                <div
                                    className={`p-3 w-100 text-center d-flex justify-content-center ${alarmHover}`}
                                    style={{ cursor: "pointer" }}
                                    onMouseEnter={() => setAlarmHover("bg-light")}
                                    onMouseLeave={() => setAlarmHover("")}
                                    onClick={NavigateToAlarms}
                                >
                                    <div>{metrics.activeAlarms}</div>
                                    <i
                                        className={
                                            "ms-2 mt-1 fa-lg fad fa-swap-opacity fa-bell " +
                                            (metrics.activeAlarms > 0 ? "text-warning" : "")
                                        }
                                        title="Active Alarms"
                                    ></i>
                                </div>

                                <div className="border" style={{ height: "25px" }}></div>

                                {/* Open Customer issue tickets */}
                                <div
                                    className={`p-3 w-100 text-center d-flex justify-content-center ${ticketHover}`}
                                    style={{ cursor: "pointer" }}
                                    onMouseEnter={() => setTicketHover("bg-light")}
                                    onMouseLeave={() => setTicketHover("")}
                                    onClick={NavigateToTickets}
                                >
                                    <div>{metrics.openTickets}</div>
                                    <i
                                        className={
                                            "ms-2 mt-1 fa-lg fad fa-swap-opacity fa-sticky-note " +
                                            (metrics.openTickets > 0 ? "text-warning" : "")
                                        }
                                        title="Tickets Open"
                                    ></i>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="text-center">
                            <img style={{ height: "24px", width: "24px" }} src="/lib/ajax-loader.gif" />
                        </div>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
};
