import React, { useState, useContext } from 'react';
import { InputGroup, FormControl, Button, Modal, Form } from 'react-bootstrap';
import { Container, Col, Row } from 'react-bootstrap'
import { ConfigContext } from "./ScatterChart"
import SWColourPicker from '../sw-colourpicker';

const colorPickerColours = ['#14a8de', '#a0512d', '#944ba3', '#069416', '#002b4f', '#f47920', '#101820'];

function DeleteScatterModal(props) {

    const handleDelete = (scatterid) => {
        var url = '/api/AnalyticsAPI/RemoveScatter/'; // TODO
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(scatterid)
        }).then(response => response.status)
            .then(status => {
                props.onremove(scatterid)
                props.onHide()
            });
    }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Are you sure you want to delete this scatter chart?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Deleting a scatter chart cannot be undone.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={props.onHide}>Cancel</Button>
                        </Col>
                        <Col>
                            <Button className="float-end" variant="danger" onClick={() => handleDelete(props.scatterid)}>Confirm</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

function SaveScatterModal(props) {
    const handleSave = (config) => {
        var newConfig = JSON.parse(JSON.stringify(config));

        var url = '/api/AnalyticsAPI/SaveScatterSettings/'; // TODO
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newConfig)
        }).then(response => response.json())
            .then(data => {
                props.updateconfig(data)
                props.onHide()
            });
    }

    return (
        <Modal
            backdrop="static"
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Are you sure you want to save changes?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    This will overwrite previous settings and cannot be undone.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={props.onHide}>Cancel</Button>
                        </Col>
                        <Col>
                            <Button className="float-right" variant="success" onClick={() => handleSave(props.config)}>Save</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

function ScatterSettings(props) {
    const { config,
        updateXSignal,
        updateXDivideSignal,
        updateYSignal,
        updateYDivideSignal,
        updateColour,
        updateConfig } = useContext(ConfigContext); // TODO, add other methods
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [saveModalShow, setSaveModalShow] = useState(false);

    return (
        config ?
            <>
                <Form>
                    <Row>
                        <Col>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>X Signal: </InputGroup.Text>
                                <FormControl
                                    as="select"
                                    custom
                                    value={config.XSignalID}
                                    onChange={(e) => { updateXSignal(e.target.value) }}>
                                    <option value={null}>Select signal</option>
                                    {props.signals}
                                </FormControl>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>X Divide Signal: </InputGroup.Text>
                                <FormControl
                                    as="select"
                                    custom
                                    value={config.XDivideSignalID}
                                    onChange={(e) => { updateXDivideSignal(e.target.value) }}>
                                    {[<option selected value=""> -- No Selection -- </option>, props.signals]}
                                </FormControl>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>Y Signal: </InputGroup.Text>
                                <FormControl
                                    as="select"
                                    custom
                                    value={config.YSignalID}
                                    onChange={(e) => { updateYSignal(e.target.value) }}>
                                    <option value={null}>Select signal</option>
                                    {props.signals}
                                </FormControl>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>Y Divide Signal: </InputGroup.Text>
                                <FormControl
                                    as="select"
                                    custom
                                    value={config.YDivideSignalID}
                                    onChange={(e) => { updateYDivideSignal(e.target.value) }}>
                                    {[<option selected value=""> -- No Selection -- </option>, props.signals]}
                                </FormControl>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>Colour: </InputGroup.Text>
                                <SWColourPicker
                                    colorPickerColours={colorPickerColours}
                                    color={colorPickerColours[config.ColorID]}
                                    handleColourChange={(colour) => { updateColour(colorPickerColours.indexOf(colour.hex)) }}
                                />
                            </InputGroup>

                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Button variant="danger" onClick={() => setDeleteModalShow(true)}>Delete scatter chart  <i className="fas fa-trash-alt"></i></Button>
                            <DeleteScatterModal
                                show={deleteModalShow}
                                onHide={() => {
                                    setDeleteModalShow(false);
                                    props.hideSettings();
                                }}
                                onremove={props.onremove}
                                scatterid={config.InsightScatterChartID}
                            />
                        </Col>
                        <Col>
                            <Button className="float-end" variant="primary" onClick={() => setSaveModalShow(true)}>Save settings  <i className="fas fa-save"></i></Button>
                            <SaveScatterModal
                                show={saveModalShow}
                                onHide={() => {
                                    setSaveModalShow(false);
                                    props.hideSettings();
                                }}
                                config={config}
                                updateconfig={updateConfig}
                            />
                        </Col>
                    </Row>


                </Form>
            </>
            :
            "Loading..."
    )
}

export default ScatterSettings;