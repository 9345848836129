import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import { StreamwiseNodeData } from '../types';
import './styles.scss'
import { Button } from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
//import {faCircleTrash, faCircleInfo} from '@fortawesome/pro-solid-svg-icons'

const PORT_POSITIONS = [20, 60]
const PORT_LABELS = ['DATA', 'AIR']

export default memo(
  ({
    id,
    data,
  }: {
    id: string;
    data: StreamwiseNodeData;
  }) => {
    return (
      <>
        <div className="handleLabelTop" style={{ left: PORT_POSITIONS[0] }}>
          {PORT_LABELS[0]}
        </div>
        <div className="handleLabelTop" style={{ left: PORT_POSITIONS[1] }}>
          {PORT_LABELS[1]}
        </div>
        <div className="nodeText">{data.shortName}</div>
        <Handle
          type="target"
          position="top"
          id={PORT_LABELS[0]}
          style={{ left: PORT_POSITIONS[0], background: "#555" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={false}
        />
        <Handle
          type="target"
          position="top"
          id={PORT_LABELS[1]}
          style={{ left: PORT_POSITIONS[1], background: "#5dc7f5" }}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={false}
        />
        <div style={{height: '20px'}}>
          <div className="infoButton">
            {/*<FontAwesomeIcon icon={faCircleInfo} />*/}
          </div>
          <div className="trashButton" onClick={() => {
            console.log("Yeet")
            if (data.onDelete) {
              data.onDelete(id)
            } 
          }}>
            {/*<FontAwesomeIcon icon={faCircleTrash} />*/}
          </div>
        </div>
      </>
    );
  }
);