import React, { useState, useRef, useEffect, useContext } from 'react';
import Select, { components } from "react-select";
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import chroma from 'chroma-js';
import { Overlay, Popover, InputGroup, Form, FormControl, Row, Col, Button, OverlayTrigger, Modal, Collapse } from 'react-bootstrap';
import SWColourPicker from "../sw-colourpicker";
import { ConfigContext } from "./BarChart"

function SignalSettingsModal(props) {
    const [colour, setColour] = useState();
    const [outputName, setOutputName] = useState("");
    const [correctionFactor, setCorrectionFactor] = useState(1);
    const { config,
        updateOutputName,
        updateCorrectionFactor,
        updateColour } = useContext(ConfigContext);

    // This use effect loads the existing settings for this particular input
    useEffect(() => {
        if (config.InsightBarChartInputs && config.InsightBarChartInputs.length > 0) {
            var thisBarChartInputConfig = config.InsightBarChartInputs.find(input => input.SignalID === props.data.value)
            setColour(colorPickerColours[thisBarChartInputConfig.ColorID])
            setOutputName(thisBarChartInputConfig.OutputName)
            setCorrectionFactor(thisBarChartInputConfig.CorrectionFactor)
        }
    }, [props.data.value, config])

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            size="sm"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Signal settings
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>Colour: </InputGroup.Text>
                                <SWColourPicker
                                    colorPickerColours={colorPickerColours}
                                    color={colour}
                                    handleColourChange={(colour) => { updateColour(colour.hex, props.data.value) }}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>Output Name: </InputGroup.Text>
                                <FormControl
                                    type="text"
                                    defaultValue={outputName}
                                    // onClick={setInputFocus}
                                    // ref={inputRef}
                                    tabIndex="0"
                                    onChange={e => updateOutputName(e.target.value, props.data.value)}>
                                </FormControl>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="justify-content-center mb-2">
                                <InputGroup.Text>Correction Factor: </InputGroup.Text>
                                <FormControl
                                    type="number"
                                    defaultValue={correctionFactor}
                                    onChange={(e) => { updateCorrectionFactor(e.target.value, props.data.value) }}>
                                </FormControl>
                            </InputGroup>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

const colorPickerColours = ['#14a8de', '#a0512d', '#944ba3', '#069416', '#002b4f', '#f47920', '#101820'];

const SortableSelect = SortableContainer(Select);

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    multiValue: (styles, { data }) => {
        const colorTemp = data.selectedColour;
        const color = chroma(colorTemp);
        return {
            ...styles,
            backgroundColor: color.alpha(0.2).css(),
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.selectedColour,
        ':hover': {
            backgroundColor: data.selectedColour,
            color: 'white',
        },
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.selectedColour,
        ':hover': {
            backgroundColor: data.selectedColour,
            color: 'white',
        },
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const CaretDownIcon = () => {
    return <i className="far fa-plus"></i>;
};

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon />
        </components.DropdownIndicator>
    );
};

function MultiValueLabel(props) {
    const [showOverlay, setShowOverlay] = useState(false);
    const [colour, setColour] = useState();
    const [outputName, setOutputName] = useState("");
    const [correctionFactor, setCorrectionFactor] = useState(1);
    const { config,
        updateOutputName,
        updateCorrectionFactor,
        updateColour } = useContext(ConfigContext);

    // This use effect loads the existing settings for this particular input
    useEffect(() => {
        if (config.InsightBarChartInputs && config.InsightBarChartInputs.length > 0) {
            var thisBarChartInputConfig = config.InsightBarChartInputs.find(input => input.SignalID === props.data.value)
            setColour(colorPickerColours[thisBarChartInputConfig.ColorID])
            setOutputName(thisBarChartInputConfig.OutputName)
            setCorrectionFactor(thisBarChartInputConfig.CorrectionFactor)
        }
    }, [props.data.value, config])

    const target = useRef(null);

    // POPOVER
    return (
        <>
            <components.MultiValueLabel
                {...props}
                innerProps={{
                    ...props.innerProps,
                    onClick: e => {
                        setShowOverlay(!showOverlay)
                    },
                    tabIndex: "0",
                    autoFocus: "true",
                    ref: target
            }}
            >
                <i className="fas fa-cog"></i>
                &nbsp;
                {props.children}
            </components.MultiValueLabel>
            <Overlay
                target={target.current}
                show={showOverlay}
                placement="bottom"
                trigger="click"
                rootClose={true}
                onHide={() => { setShowOverlay(false) }}
            >
                <Popover style={{ maxWidth: "none" }}>
                    <Popover.Header as="h3">Signal Settings</Popover.Header>
                    <Popover.Body>
                        <Form>
                            <Row>
                                <Col>
                                    <InputGroup className="justify-content-center mb-2">
                                        <InputGroup.Text>Colour: </InputGroup.Text>
                                        <SWColourPicker
                                            colorPickerColours={colorPickerColours}
                                            color={colour}
                                            handleColourChange={(colour) => { updateColour(colour.hex, props.data.value) }}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup className="justify-content-center mb-2">
                                        <InputGroup.Text>Output Name: </InputGroup.Text>
                                        <FormControl
                                            type="text"
                                            defaultValue={outputName}
                                            // onClick={setInputFocus}
                                            // ref={inputRef}
                                            tabIndex="0"
                                            onChange={(e) => { console.log("update"); updateOutputName(e.target.value, props.data.value) }}>
                                        </FormControl>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup className="justify-content-center mb-2">
                                        <InputGroup.Text>Correction Factor: </InputGroup.Text>
                                        <FormControl
                                            type="number"
                                            defaultValue={correctionFactor}
                                            onChange={(e) => { updateCorrectionFactor(e.target.value, props.data.value) }}>
                                        </FormControl>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </>
    );

};

// const useFocus = () => {
//     const htmlElRef = useRef(null)
//     const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

//     return [ htmlElRef, setFocus ] 
// }

const SortableMultiValue = SortableElement(props => {
    const onKeyDown = e => {
        e.stopPropagation();
    }
    const innerProps = { ...props.innerProps, onKeyDown };
    const style = { zIndex: 99999999 };
    return <components.MultiValue {...props} innerProps={innerProps} style={style} />;
});

const SortableMultiValueLabel = sortableHandle(props => (
    <MultiValueLabel {...props} />
));

function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
}


function BarChartClickableSignalSelect(props) {
    const { updateSelectedSignals } = useContext(ConfigContext);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newValue = arrayMove(props.value, oldIndex, newIndex);
        updateSelectedSignals(newValue, props.addFilter);
    };

    return (
        <>
            <SortableSelect
                useDragHandle
                // react-sortable-hoc props:
                axis="xy"
                onSortEnd={onSortEnd}
                distance={4}
                helperClass='sortableHelper'
                // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                // react-select props:
                options={props.options}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                isSearchable={true}
                styles={colourStyles}
                value={props.value}
                placeholder="Select signal(s)..."
                components={{
                    MultiValue: SortableMultiValue,
                    MultiValueLabel: SortableMultiValueLabel,
                    DropdownIndicator,
                }}
                openMenuOnClick={false}
                colorPickerColours={colorPickerColours}
                isClearable={false}
                onChange={(signals) => { updateSelectedSignals(signals) }}
            />
        </>
    );
}

export default BarChartClickableSignalSelect;