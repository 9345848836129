import React, { memo, useEffect, useState } from 'react';
import { Handle } from 'react-flow-renderer';
import { StreamwiseControlLinkData, PIDControlParameters, PumpControlParameters, PumpLogs } from '../types';
import './styles.scss'
import { Button, Modal, Col, Row, Accordion, InputGroup, FormControl, Form, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faCircleTrash, faCircleInfo } from '@fortawesome/pro-solid-svg-icons'


export function PumpModal(props) {
    const [controlLinkData, setControlLinkData] = useState<StreamwiseControlLinkData>();
    const [tuneParameters, setTuneParameters] = useState<PumpControlParameters>();
    const [variableTypes] = useState(["Boolean", "FloatingPointHolding", "FloatingPointInput", "WordHolding", "WordInput", "WordInputUShort", "WordHoldingDoubleRegister"]);
    const [logsArray, setLogs] = useState<PumpLogs[]>([]);

    useEffect(() => {
        if (props.show) {
            props.getData(props.id).then((data) => {
                setControlLinkData(data.controlLinkData);
                setTuneParameters(data.pump);
                console.log(data);

                var d = new Date();
                var offset = (d.getTimezoneOffset() * -1) / 60;
                fetch("/api/ControlChainsAPI/GetLogDataPump/", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ offset: offset, id: data.controlLinkData.signalID, uppumpid: data.pump.UpPumpID, downpumpid: data.pump.DownPumpID }),
                })
                    .then((response) => response.json())
                    .then((logs: PumpLogs[]) => {
                        console.log(logs);
                        setLogs(logs);
                    });

            });
        }
    }, [props.show])

    

    const saveControlLinkSettings = () => {
        props.getData(props.id).then(data => {
            const newData = {
                ...data,
                controlLinkData: controlLinkData,
                pump: tuneParameters,
                controlType: 3
            }
            console.log(newData);
            props.saveSettings(props.id, newData);
            props.submitControlLink(newData);
            props.onHide();
        });
    }
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-pid"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-pid">
                    Pump
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Control Link Information</Accordion.Header>
                        <Accordion.Body>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Filter Signal: </InputGroup.Text>
                                <Form.Select value={controlLinkData?.filterSignal} onChange={(v) => { setControlLinkData({ ...controlLinkData, filterSignal: v.target.value }); }}>
                                    <option value="0">Select Signal</option>
                                    {props.signals.map(d => <option key={d.id + "key"} value={d.id}>{d.nicename}</option>)}
                                </Form.Select>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Lower Filter Threshold: </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={controlLinkData?.lowerFilterThreshold}
                                    type="number"
                                    onChange={(v) => { setControlLinkData({ ...controlLinkData, lowerFilterThreshold: parseFloat(v.target.value) }); }}>
                                </FormControl>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Upper Filter Threshold: </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={controlLinkData?.upperFilterThreshold}
                                    type="number"
                                    onChange={(v) => { setControlLinkData({ ...controlLinkData, upperFilterThreshold: parseFloat(v.target.value) }); }}>
                                </FormControl>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Scaler Signal: </InputGroup.Text>
                                <Form.Select value={controlLinkData?.scalerSignal} onChange={(v) => { setControlLinkData({ ...controlLinkData, scalerSignal: v.target.value }); }}>
                                    <option value="0">Select Signal</option>
                                    {props.signals.map(d => <option key={d.id + "key"} value={d.id}>{d.nicename}</option>)}
                                </Form.Select>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Update Frequency (s): </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={controlLinkData?.updateFrequency}
                                    type="number"
                                    onChange={(v) => { setControlLinkData({ ...controlLinkData, updateFrequency: parseInt(v.target.value) }); }}>
                                </FormControl>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Pump Pair Name: </InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon3"
                                    value={controlLinkData?.nicename}
                                    onChange={(v) => { setControlLinkData({ ...controlLinkData, nicename: v.target.value }); }}>
                                </FormControl>
                            </InputGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Tune Control Link</Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col xs={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Up Pump Gradient: </InputGroup.Text>
                                        <FormControl
                                            aria-describedby="basic-addon3"
                                            value={tuneParameters?.UpPumpGradient}
                                            type="number"
                                            onChange={(v) => { setTuneParameters({ ...tuneParameters, UpPumpGradient: parseFloat(v.target.value) }); }}>
                                        </FormControl>
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Up Pump Intercept: </InputGroup.Text>
                                        <FormControl
                                            aria-describedby="basic-addon3"
                                            value={tuneParameters?.UpPumpIntercept}
                                            type="number"
                                            onChange={(v) => { setTuneParameters({ ...tuneParameters, UpPumpIntercept: parseFloat(v.target.value) }); }}>
                                        </FormControl>
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Up Pump Engineering Minimum: </InputGroup.Text>
                                        <FormControl
                                            aria-describedby="basic-addon3"
                                            value={tuneParameters?.UpPumpEngMinimum}
                                            type="number"
                                            onChange={(v) => { setTuneParameters({ ...tuneParameters, UpPumpEngMinimum: parseFloat(v.target.value) }); }}>
                                        </FormControl>
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Up Pump Engineering Maximum: </InputGroup.Text>
                                        <FormControl
                                            aria-describedby="basic-addon3"
                                            value={tuneParameters?.UpPumpEngMaximum}
                                            type="number"
                                            onChange={(v) => { setTuneParameters({ ...tuneParameters, UpPumpEngMaximum: parseFloat(v.target.value) }); }}>
                                        </FormControl>
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Up Pump Raw Minimum: </InputGroup.Text>
                                        <FormControl
                                            aria-describedby="basic-addon3"
                                            value={tuneParameters?.UpPumpRawMinimum}
                                            type="number"
                                            onChange={(v) => { setTuneParameters({ ...tuneParameters, UpPumpRawMinimum: parseFloat(v.target.value) }); }}>
                                        </FormControl>
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Up Pump Raw Maximum: </InputGroup.Text>
                                        <FormControl
                                            aria-describedby="basic-addon3"
                                            value={tuneParameters?.UpPumpRawMaximum}
                                            type="number"
                                            onChange={(v) => { setTuneParameters({ ...tuneParameters, UpPumpRawMaximum: parseFloat(v.target.value) }); }}>
                                        </FormControl>
                                    </InputGroup>
                                </Col>
                                <Col xs={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Down Pump Gradient: </InputGroup.Text>
                                        <FormControl
                                            aria-describedby="basic-addon3"
                                            value={tuneParameters?.DownPumpGradient}
                                            type="number"
                                            onChange={(v) => { setTuneParameters({ ...tuneParameters, DownPumpGradient: parseFloat(v.target.value) }); }}>
                                        </FormControl>
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Down Pump Intercept: </InputGroup.Text>
                                        <FormControl
                                            aria-describedby="basic-addon3"
                                            value={tuneParameters?.DownPumpIntercept}
                                            type="number"
                                            onChange={(v) => { setTuneParameters({ ...tuneParameters, DownPumpIntercept: parseFloat(v.target.value) }); }}>
                                        </FormControl>
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Down Pump Engineering Minimum: </InputGroup.Text>
                                        <FormControl
                                            aria-describedby="basic-addon3"
                                            value={tuneParameters?.DownPumpEngMinimum}
                                            type="number"
                                            onChange={(v) => { setTuneParameters({ ...tuneParameters, DownPumpEngMinimum: parseFloat(v.target.value) }); }}>
                                        </FormControl>
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Down Pump Engineering Maximum: </InputGroup.Text>
                                        <FormControl
                                            aria-describedby="basic-addon3"
                                            value={tuneParameters?.DownPumpEngMaximum}
                                            type="number"
                                            onChange={(v) => { setTuneParameters({ ...tuneParameters, DownPumpEngMaximum: parseFloat(v.target.value) }); }}>
                                        </FormControl>
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Down Pump Raw Minimum: </InputGroup.Text>
                                        <FormControl
                                            aria-describedby="basic-addon3"
                                            value={tuneParameters?.DownPumpRawMinimum}
                                            type="number"
                                            onChange={(v) => { setTuneParameters({ ...tuneParameters, DownPumpRawMinimum: parseFloat(v.target.value) }); }}>
                                        </FormControl>
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Down Pump Raw Maximum: </InputGroup.Text>
                                        <FormControl
                                            aria-describedby="basic-addon3"
                                            value={tuneParameters?.DownPumpRawMaximum}
                                            type="number"
                                            onChange={(v) => { setTuneParameters({ ...tuneParameters, DownPumpRawMaximum: parseFloat(v.target.value) }); }}>
                                        </FormControl>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Communication Protocol</Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col xs={6}>
                                    <Form className="mb-3">
                                        {[[1, 'Modbus']].map(value => (
                                            <Form.Check
                                                key={"commstype" + value[1]}
                                                inline
                                                label={value[1]}
                                                value={value[0]}
                                                name="group1"
                                                type='radio'
                                                checked={tuneParameters?.UpPumpProtocol === value[0]}
                                                id={`inline-${value[0]}`}
                                                onChange={(v) => { setTuneParameters({ ...tuneParameters, UpPumpProtocol: parseInt(v.target.value) }); }}
                                            />
                                        ))}
                                    </Form>
                                    {tuneParameters?.UpPumpProtocol === 2 || tuneParameters?.UpPumpProtocol === 1 ?
                                        <>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>Up Pump Slave ID: </InputGroup.Text>
                                                <FormControl
                                                    aria-describedby="basic-addon3"
                                                    value={tuneParameters?.UpPumpSlaveID}
                                                    type="number"
                                                    onChange={(v) => { setTuneParameters({ ...tuneParameters, UpPumpSlaveID: parseInt(v.target.value) }); }}>
                                                </FormControl>
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>Up Pump Registry Address: </InputGroup.Text>
                                                <FormControl
                                                    aria-describedby="basic-addon3"
                                                    value={tuneParameters?.UpPumpAddress}
                                                    type="number"
                                                    onChange={(v) => { setTuneParameters({ ...tuneParameters, UpPumpAddress: parseInt(v.target.value) }); }}>
                                                </FormControl>
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>Up Pump Registry Type: </InputGroup.Text>
                                                <Form.Select value={tuneParameters?.UpPumpVarType} onChange={(v) => { setTuneParameters({ ...tuneParameters, UpPumpVarType: v.target.value }); }}>
                                                    <option value="0">Select Type</option>
                                                    {variableTypes.map(d => <option key={d + "key"} value={d}>{d}</option>)}
                                                </Form.Select>
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <Form.Check
                                                    label={'Need measure command'}
                                                    type={'switch'}
                                                    checked={tuneParameters?.UpPumpNeedMeasureCmd === 1}
                                                    id={"switch-uppump"}
                                                    onChange={(v) => { setTuneParameters({ ...tuneParameters, UpPumpNeedMeasureCmd: (tuneParameters?.UpPumpNeedMeasureCmd === 1 ? 0 : 1) }); }}
                                                />
                                            </InputGroup>
                                        </> : <></>
                                    }
                                </Col>
                                <Col xs={6}>
                                    <Form className="mb-3">
                                        {[[1, 'Modbus']].map(value => (
                                            <Form.Check
                                                key={"commtype" + value[1]}
                                                inline
                                                label={value[1]}
                                                value={value[0]}
                                                name="group2"
                                                type='radio'
                                                checked={tuneParameters?.DownPumpProtocol === value[0]}
                                                id={`inline-${value[0]}-down`}
                                                onChange={(v) => { setTuneParameters({ ...tuneParameters, DownPumpProtocol: parseInt(v.target.value) }); }}
                                            />
                                        ))}
                                    </Form>
                                    {tuneParameters?.DownPumpProtocol === 2 || tuneParameters?.DownPumpProtocol === 1 ?
                                        <>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>Down Pump Slave ID: </InputGroup.Text>
                                                <FormControl
                                                    aria-describedby="basic-addon3"
                                                    value={tuneParameters?.DownPumpSlaveID}
                                                    type="number"
                                                    onChange={(v) => { setTuneParameters({ ...tuneParameters, DownPumpSlaveID: parseInt(v.target.value) }); }}>
                                                </FormControl>
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>Down Pump Registry Address: </InputGroup.Text>
                                                <FormControl
                                                    aria-describedby="basic-addon3"
                                                    value={tuneParameters?.DownPumpAddress}
                                                    type="number"
                                                    onChange={(v) => { setTuneParameters({ ...tuneParameters, DownPumpAddress: parseInt(v.target.value) }); }}>
                                                </FormControl>
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>Down Pump Registry Type: </InputGroup.Text>
                                                <Form.Select value={tuneParameters?.DownPumpVarType} onChange={(v) => { setTuneParameters({ ...tuneParameters, DownPumpVarType: v.target.value }); }}>
                                                    <option value="0">Select Type</option>
                                                    {variableTypes.map(d => <option key={d + "key"} value={d}>{d}</option>)}
                                                </Form.Select>
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <Form.Check
                                                    label={'Need measure command'}
                                                    type={'switch'}
                                                    checked={tuneParameters?.DownPumpNeedMeasureCmd === 1}
                                                    id={"switch-DownPump"}
                                                    onChange={(v) => { setTuneParameters({ ...tuneParameters, DownPumpNeedMeasureCmd: (tuneParameters?.DownPumpNeedMeasureCmd === 1 ? 0 : 1) }); }}
                                                />
                                            </InputGroup>
                                        </> : <></>
                                    }
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Pump control Logs</Accordion.Header>
                        <Accordion.Body>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>User Name</th>
                                        <th>Up/Down</th>
                                        <th>Gradient</th>
                                        <th>Intercept</th>
                                        <th>Raw Min</th>
                                        <th>Raw Max</th>
                                        <th>Min</th>
                                        <th>Max</th>
                                        <th>Time</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {logsArray.map((item) => (
                                        <tr key={item.UserID}>
                                            {Object.values(item).map((val) => (
                                                <td>{val}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    saveControlLinkSettings();
                }}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}