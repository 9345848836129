import React from 'react'
import {Button} from 'react-bootstrap'

function HelpButton(props){
    return(
        <Button variant="secondary" size="sm" className="fixed-help-button" onClick={()=>props.showHelpModal(true)}>
            Help <i className="fas fa-question-circle"></i>
        </Button>
    )
}

export default HelpButton;