import React, { useState, useRef, useEffect} from 'react';
import { Popover, ListGroup, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { castToPriority, castToStatus } from './useTickets';
import { ProgressBar, Step } from "react-step-progress-bar";

import { Statuses, StatusBadgeProps, Priorities, PriorityBadgeProps, SupportTicket } from './types';

export const StatusBadge: React.FC<StatusBadgeProps> = ({ classes, data, editTicket }) => {
     // popover control
    const target = useRef(null);  // need this for correct placement of popover
    const [showPopover, setShowPopover] = useState<boolean>(false);
    const [status, setStatus] = useState(data.ticketStatus);

    // blue, light blue, turquoise, green
    const statusColours = ["#0231E8", "#04A9F4", "#1BBC9C", "#31CD6F"];

    const currentColour = () => {
        if (status === Statuses.None) {
            return "";
        } else {
            return statusColours[Number(status)];
        }
    }

    //will be implemented later
    // const renderTooltip = (data:SupportTicket) => (
    //     <Tooltip id="button-tooltip" width={"fit-content"}>
    //             <ProgressBar
    //                 height={30}
    //                 percent={(100 * data.ticketStatus) / 4}
    //                 filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
    //             >
    //                 <Step transition="scale">
    //                 {({ accomplished }) => (
    //                     <div style={{textAlign: 'center'}}>
    //                     <div>Logged</div>
    //                   </div>
    //                 )}
    //                 </Step>
    //                 <Step transition="scale">
    //                 {({ accomplished }) => (
    //                     <div style={{textAlign: 'center'}}>
    //                     <div>Assigned</div>
    //                   </div>
    //                 )}
    //                 </Step>
    //                 <Step transition="scale">
    //                 {({ accomplished }) => (
    //                     <div style={{textAlign: 'center'}}>
    //                     <div>In Progress</div>
    //                   </div>
    //                 )}
    //                 </Step>
    //                 <Step transition="scale">
    //                 {({ accomplished }) => (
    //                     <div style={{textAlign: 'center'}}>
    //                     <div>Closed</div>
    //                   </div>
    //                 )}
    //                 </Step>
    //             </ProgressBar>

    //         </Tooltip>
    //   );

    return (
        <>
            {/* <OverlayTrigger 
            placement="left-end"
            delay={{ show: 150, hide: 300 }}
            overlay={renderTooltip(data)}> */}
                <i ref={target}
                    className={`p-3 border rounded-pill ${classes}`}
                    style={{backgroundColor: currentColour(), color: 'white', fontStyle: 'normal', textAlign: 'center', display: 'block'} }
                    onClick={() => setShowPopover(!showPopover)}>{Object.values(Statuses)[status]}</i>
            {/* </OverlayTrigger> */}
        </>
    );
}

export const PriorityBadge: React.FC<PriorityBadgeProps> = ({ classes, data, editTicket }) => {
    // Priority popover control
    const target = useRef(null);
    const [showPopover, setShowPopover] = useState<boolean>(false);
    const [priority, setPriority] = useState(data.priority);
    const priorityColours = ["danger", "warning", "info", "success"];

    const currentColour = () => {
        if (priority === Priorities.None) {
            return "";
        } else {
            return `text-${priorityColours[Number(priority) - 1]}`;
        }
    }

    const _handlePriorityChange = (newPriority: Priorities) => {
        if (data.priority !== newPriority) {
            editTicket({
                ...data,
                priority: newPriority
            });
            setPriority(newPriority);
            setShowPopover(false);
        }
    }

    const PriorityPopover = () => {
        // returns both keys and values for enum type
        const priorityArr = Object.values(Priorities);
        let priorityObject = []
        for (let i = 0; i < priorityArr.length / 2; i++) {
            priorityObject.push({
                key: priorityArr[i],
                value: Number(priorityArr[i + priorityArr.length / 2])
            })
        }

        return (
            <Popover>
                <ListGroup>
                    {priorityObject.map((priorityOpt, i) =>
                        <ListGroup.Item action
                            key={`${priorityOpt.key}-${priorityOpt.value}`}
                            onClick={() => _handlePriorityChange(castToPriority(priorityOpt.value))}
                            className={priority === priorityOpt.value ? "bg-light" : ""}
                        >
                            {priorityOpt.value === 100 ?
                                <i className="fal fa-times text-danger me-2"></i> :
                                <i className={`fad fa-flag-alt me-2 text-${priorityColours[i]}`}></i>
                            }
                            {priorityOpt.key}
                        </ListGroup.Item>
                    )}
                </ListGroup>
            </Popover>
        );
    }

    return (
        <>
            <i ref={target}
                className={`fad fa-flag-alt p-3 border rounded-circle ${currentColour()} ${classes}`}
                onClick={() => setShowPopover(!showPopover)}></i>
            <Overlay
                show={showPopover}
                target={target.current}
                placement="bottom" >
                {PriorityPopover()}
            </Overlay>
        </>
    );
}