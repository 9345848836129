import React, { useEffect, useState, useContext } from 'react';
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import $ from 'jquery';
import { Fade, Button } from 'react-bootstrap';
import Gauge from './Gauge'
import { AuthContext } from '../Analytics';

const SortableItem = sortableElement(({ index, value, onremove, start, end, i, valueslabel, name }) => {
    return <Gauge
        index={i}
        gaugeID={value}
        gaugeName={name}
        onremove={onremove}
        start={start}
        end={end}
    />
});

const SortableContainer = sortableContainer(({ children }) => {
    return <div>{children}</div>;
});

function GaugesContainer(props) {
    const [gauges, setGauges] = useState([]);
    const [ready, setReady] = useState(false);
    const { auth } = useContext(AuthContext);

    useEffect(() => {
        var url = '/api/AnalyticsAPI/GetGauges/';
        $.ajax({
            url: url,
            type: 'get',
            data: { SiteGUID: props.siteGUID },
            error: function () {
                console.log("Get gauge api call failed")
            },
            success: function (response) {
                var data = JSON.parse(response)
                var newGauges = []
                data.forEach(d => newGauges.push({ id: d.GaugeID, idx: d.Order, name: d.GaugeName}));
                newGauges.sort((a, b) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0));
                setGauges(newGauges);
                setReady(true);
            }
        });
    }, [props.siteGUID])

    const onSortStart = () => (document.body.style.cursor = 'grabbing')

    const onSortEnd = ({ oldIndex, newIndex }) => {
        document.body.style.cursor = 'default';
        var newArray = arrayMoveImmutable(gauges, oldIndex, newIndex);
        setGauges(newArray)
        var url = '/api/AnalyticsAPI/SetGaugeOrder/';
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newArray.map(i => i.id))
        })
    };

    const addGauge = () => {
        var url = '/api/AnalyticsAPI/AddGauge/';
        $.ajax({
            url: url,
            type: 'get',
            data: { SiteGUID: props.siteGUID},
            error: function () {
                console.log("Add gauge api call failed")
            },
            success: function (response) {
                var data = JSON.parse(response)
                var newGauges = []
                data.forEach(d => newGauges.push({ id: d.GaugeID, idx: d.Order, name: d.GaugeName}));
                newGauges.sort((a, b) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0));
                setGauges(newGauges);
            }
        });
    }

    const removeGauge = (gaugeID) => {
        var newGauges = JSON.parse(JSON.stringify(gauges));
        newGauges.splice(newGauges.findIndex(lc => lc.id == gaugeID), 1)
        setGauges(newGauges);
    }

    return (
        <Fade appear={true} in={ready}> 
            <div>
                {auth == 2 ?
                    <Button className="btn-primary" onClick={addGauge}>
                        Add gauge
                    </Button>
                    :
                    <></>
                }
                <SortableContainer
                    onSortEnd={onSortEnd}
                    onSortStart={onSortStart}
                    axis={"xy"}
                    useDragHandle
                >
                    <div style={{ textAlign: "center" }}>
                        {gauges.map((value, index) =>
                            (
                                <SortableItem
                                    key={`Gauge-${value.id}`}
                                    index={index}
                                    value={value.id}
                                    onremove={removeGauge}
                                    start={props.start}
                                    end={props.end}
                                    i={index}
                                    name={value.name}
                                />
                            ))}
                    </div>
                </SortableContainer>
            </div>
        </Fade>) 
}

export default GaugesContainer;