import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import {
    Table, Form, InputGroup, Modal, Button, OverlayTrigger, Tooltip, Row, Col
} from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import "react-step-progress-bar/styles.css";


export function CompanySetup(props) {
    const { id } = useParams();
    const [companyname, setCompanyName] = useState('');
    const [tradingas, setTradingAs] = useState('');

    const [acn, setACN] = useState('');

    function NewCompany() {
        if (props.company === null || props.company.CompanyID === null) {
            var company = {
                ACN: acn,
                CompanyName: companyname,
                TradingAs: tradingas
            }
            props.setCompany(company);
        }
    }

    return (
        props.company === null ?
            <>
                <Form>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={2}>
                            ACN
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control required type="text" placeholder="ACN" value={acn} onChange={(e) => setACN(e.target.value)}/>
                            <Button variant="outline-secondary" id="button-addon2" onClick={() => { props.searchACN(acn); }}>
                                Search
                            </Button>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={2}>
                            Company Name
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control required type="text" placeholder="Company Name" value={companyname} onChange={(e) => setCompanyName(e.target.value)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={2}>
                            Trading As
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control type="text" placeholder="Trading As" value={tradingas} onChange={(e) => setTradingAs(e.target.value)} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Col sm={{ span: 3, offset: 2 }}>
                            <Button type="submit" onClick={() => { NewCompany(); props.next(); }}>Continue</Button>
                        </Col>
                    </Form.Group>
                </Form>
            </>
            : <><Form> 
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                        ACN
                    </Form.Label>
                    <Col sm={3}>
                        {props.company.ACN}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" >
                    <Form.Label column sm={2}>
                        Company Name
                    </Form.Label>
                    <Col sm={3}>
                        {props.company.CompanyName}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" >
                    <Form.Label column sm={2}>
                        Trading As
                    </Form.Label>
                    <Col sm={3}>
                        {props.company.TradingAs}
                    </Col>
                </Form.Group>
                <Button type="submit" onClick={() => {props.next(); }}>Continue to Site Setup</Button>
            </Form></>);
};
