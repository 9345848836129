import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export const SplashTableView = ({ sites, allMetrics, hiddenSites, hideSite, showHidden }) => {
    return (
        <Table hover>
            <thead>
                <tr>
                    <th className="text-center">Site</th>
                    <th className="text-center">Devices Online</th>
                    <th className="text-center">Anomalous Trend</th>
                    <th className="text-center">Active Alarms</th>
                    <th className="text-center">Open Tickets</th>
                </tr>
            </thead>
            <tbody>
                {sites.map(
                    (s) =>
                        (!hiddenSites.includes(s.siteGUID) || showHidden) &&
                        (allMetrics[s.siteGUID] != undefined ? (
                            <SiteRow
                                key={s.siteGUID + "-siterow"}
                                site={s}
                                metrics={allMetrics[s.siteGUID]}
                                isHidden={hiddenSites.includes(s.siteGUID)}
                                hideSite={hideSite}
                            />
                        ) : (
                            // Loading wheel if metrics has not been fetched yet
                            <tr key={s.siteGUID + "-siterow"}>
                                <td colSpan={5} style={{ textAlign: "center" }}>
                                    <img style={{ height: "24px", width: "24px" }} src="/lib/ajax-loader.gif" />
                                </td>
                            </tr>
                        ))
                )}
            </tbody>
        </Table>
    );
};

const SiteRow = ({ site, metrics, isHidden, hideSite }) => {
    const history = useHistory();

    const NavigateToSite = (siteGUID) => {
        history.push("/" + siteGUID);
    };

    const NavigateToAlarms = (siteGUID) => {
        history.push(`/Alarms/${siteGUID}`);
    };

    const NavigateToTickets = (siteGUID) => {
        history.push(`/SupportTickets/${siteGUID}`);
    };

    // Hover states for navigation
    const [siteHover, setSiteHover] = useState(false);
    const [alarmHover, setAlarmHover] = useState(false);
    const [ticketHover, setTicketHover] = useState(false);

    useEffect(() => {
        console.log(site);
        console.log(metrics);
    }, [metrics]);

    return (
        <tr siteguid={site.siteGUID} className={isHidden ? "opacity-50" : ""}>
            <td onMouseEnter={() => setSiteHover(true)} onMouseLeave={() => setSiteHover(false)}>
                {/* Hide site button */}
                <i
                    className={"fas me-2 " + (isHidden ? "fa-plus" : "fa-minus")}
                    style={{ cursor: "pointer" }}
                    onClick={() => hideSite(site.siteGUID)}
                    title="Hide site"
                ></i>
                {site.siteName}
                {siteHover && (
                    <i
                        className="ms-2 fad fa-external-link"
                        style={{ cursor: "pointer", lineHeight: "24px" }}
                        onClick={() => NavigateToSite(site.siteGUID)}
                        title="Open site"
                    ></i>
                )}
            </td>
            <td className="text-center">
                <i
                    className={
                        "mt-1 fa-lg " +
                        (metrics.online ? "fas fa-signal-alt text-success" : "fas fa-signal-alt-slash text-danger")
                    }
                    title={`${
                        metrics.online
                            ? `Site online: ${
                                    metrics.numDevices > 1
                                        ? `${metrics.onlineDevices} of ${metrics.numDevices} edge devices online`
                                        : "Edge device online"
                                }`
                            : `Site offline: ${
                                    metrics.numDevices > 1
                                        ? `All (${metrics.numDevices}) edge devices offline`
                                        : "Edge device offline"
                                }`
                    }`}
                ></i>
            </td>
            <td className="text-center">
                <i
                    className={
                        "mt-1 fa-lg fad fa-analytics " + (metrics.anomalousTrend ? "text-danger" : "text-success")
                    }
                ></i>
            </td>
            <td
                className="text-center"
                onMouseEnter={() => setAlarmHover(true)}
                onMouseLeave={() => setAlarmHover(false)}
            >
                {metrics.activeAlarms}
                {alarmHover && (
                    // Navigate to alarms
                    <i
                        className="ms-2 fad fa-external-link"
                        style={{ cursor: "pointer" }}
                        onClick={() => NavigateToAlarms(site.siteGUID)}
                        title="Open alarms"
                    ></i>
                )}
            </td>
            <td
                className="text-center"
                onMouseEnter={() => setTicketHover(true)}
                onMouseLeave={() => setTicketHover(false)}
            >
                {metrics.openTickets}

                {ticketHover && (
                    //Navigate to ticketing system
                    <i
                        className="ms-2 fad fa-external-link"
                        style={{ cursor: "pointer" }}
                        onClick={() => NavigateToTickets(site.siteGUID)}
                        title="Open tickets"
                    ></i>
                )}
            </td>
        </tr>
    );
};
